<template>
     <BreadcrumbsProject class="d-print-none"
        :breadcrumbs="[
            {
                link : projectUrl +'/',
                title : $t('Projects'),
                isRouterLink: false
            },
            {
                link : projectUrl +'/Project/' + projectInfo._id,
                title :  (projectInfo.projectNumber || '-') +' ' + (projectInfo.projectName || ''),
                isRouterLink: false
            },
            {
                link : '/boq/' + projectInfo._id+'/quotation/list',
                title : $t('Quotations'),
                isRouterLink: true
            },
            {
                link : '/boq/' + quotationId +'/quotation/item',
                title : (quotationDataIntial.quotationNumber || '') + ' ' + (quotationDataIntial.quotationName || '')  ,
                isRouterLink: true
            },
        ]"
    ></BreadcrumbsProject>
    <div class="d-print-none border-bottom">
        <StepItem2 ></StepItem2>
    </div>
    <div class="d-print-none row mt-2" v-if="quotationDataIntial.approvalStage==ApprovalStageBoq.FOR_APPROVAL">
        <BoqProcessWorkFlow
        v-if="processItemData._id"
        :processItemData="processItemData" 
        :showApprovalDisapprove="false"
        :showGoToApproval="true"
        />
    </div>
    <div class="row my-3 ms-2 me-2 align-content-center">
        <div class="col-md-4 d-print-none">
            <span :class="'align-self-center badge ' + ApprovalStageBoqObj[quotationDataIntial.approvalStage -1].color">
                {{ApprovalStageBoqObj[quotationDataIntial.approvalStage-1].name}} <span v-if="IsPublished">on {{ReformatDate(quotationDataIntial.publishedDate)}}</span>
            </span>
        </div>
        <div class="col-md-4 d-print-none">
            <div class="d-flex justify-content-center">
                <h5>{{$t('Quotation')}}</h5>
            </div>
        </div>
        <div class="col-md-4 d-print-none">
            <div class="d-flex justify-content-end ">
                <div class="btn-group" role="group" v-show="!isEditMode">
                    <button class="btn btn-primary" @click="()=>{ isEditMode = true}" v-if="permission.indexOf(ModuleActionList.BOQ_QUOTATION_EDIT)>-1 && !IsPublished" >
                        <i class="fa-solid fa-edit"></i> {{$t('Edit')}} 
                    </button>
                    <button class="btn btn-primary" @click="ExportToPdf" >
                        <i class="fa-solid fa-print"></i> {{$t('Print')}}
                    </button>
                    <button class="btn btn-primary" @click="PublishQuotation" v-if="quotationDataIntial.approvalStage==ApprovalStageBoq.APPROVED && permission.indexOf(ModuleActionList.BOQ_QUOTATION_PUBLISH)>-1" >
                        <i class="fa-solid fa-file-circle-check"></i> {{ $t('Quotation Module.Contract Signed') }}
                    </button>
                </div>
                
                <div v-show="isEditMode">
                    <button class="btn btn-default me-1" @click="CancelEdit">
                        <i class="fa-solid fa-times"></i> {{$t('Cancel')}}
                    </button>
                    <button class="btn btn-success" @click="UpdateQuotation" :disabled="!haveChanges">
                        <i class="fa-solid fa-floppy-disk"></i> {{$t('Save Changes')}}
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-white p-3 print-content border rounded m-3">
        <fieldset :disabled="isLoading">
            
            <div class="row ">
                <div class="col-sm-12 d-none d-print-block">
                    <div class="float-end">
                        <a><img src="https://staff.mmoseronelink.com/mmoser-associates.svg"  alt="" width="300" height="40"></a>
                    </div>
                </div>
                <div class="col-sm-6 d-none d-print-block">
                    <div class="d-flex">
                        <span class="fw-bold">{{$t('To')}}:</span>
                        <div style="white-space: pre-line" >
                            {{quotationData.address}}
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 d-none d-print-inline">
                     <div class="float-end">
                        <div>
                            <span class="fw-bold">{{$t('Date')}}:</span> {{quotationData.contractDate ? ReformatDate(quotationData.contractDate) : 'N/A'}}
                        </div>
                        <div >
                            <span class="fw-bold">{{$t('Quotation Module.QTN No')}}:</span>  
                            <span > {{quotationData.quotationNumber}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-print-none">
                    <div class=" pt-1">
                        <div v-if="!isEditMode" class="d-flex">
                            <span class="fw-bold">{{$t('To')}}:</span>
                            <div style="white-space: pre-line" >
                                {{quotationData.address}}
                            </div>
                        </div>
                        <div v-else class="">
                            <span class="fw-bold">{{$t('To')}}:</span>
                            <textarea class="form-control width-50" style="height:150px" v-model="quotationData.address">
                            </textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-print-none text-end">
                    <button class="btn btn-outline-primary" v-if="quotationDataIntial.approvalStage!=ApprovalStageBoq.FOR_APPROVAL && !IsPublished && permission.indexOf(ModuleActionList.BOQ_QUOTATION_PUBLISH)>-1"
                    @click="OpenWorkFlowModal"
                    ><i class="fas fa-paper-plane"></i> {{ $t('Send For Approval') }}</button>
                </div>
                <div class="col-md-12 d-print-none">
                    <div>
                        <div v-if="!isEditMode">
                            <span class="fw-bold">{{$t('Date')}}:</span>  {{quotationData.contractDate ? ReformatDate(quotationData.contractDate) : '-'}}
                        </div>
                        <div v-else>
                            <span class="fw-bold">{{$t('Date')}}: <i class="text-warning">*</i></span>  
                            <DatePicker v-model="quotationData.contractDate" class="width-50" elemId="quotation-date-contractDate" />
                        </div>
                        <div >
                            <span class="fw-bold">{{$t('Quotation No')}} <i class="text-warning">*</i>:</span>  
                            <span v-if="!isEditMode" > {{quotationData.quotationNumber}}</span>
                            <div v-else>
                                <input type="text" class="form-control width-50"  :placeholder="$t('Quotation No')" v-model="quotationData.quotationNumber" />
                                <small class="text-warning" v-if="!quotationData.quotationNumber">{{ $t('Required') }}</small>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div class="col-md-12 col-sm-12">
                    <div class="mt-3">
                        <div class="">
                            <span class="fw-bold d-print-none">{{$t('Attention')}}:</span>  
                            <span class="fw-bold d-none d-print-inline">{{ $t('Quotation Module.Attn') }} :</span>  
                            <span v-if="!isEditMode" class="text-capitalize">{{quotationData.attentionClient.userId.fullname || '-'}}</span>
                            <div v-else>
                                <div class="width-50">
                                    <Select2 
                                    ref="select2ClientFromDirectory"
                                    :filterQuery="SearchClientFromProjectDirectory"
                                    indexValue="_id"
                                    labelName="fullname"
                                    :placeHolder="$t('Quotation Module.Search Client From Project')"
                                    :iniLabel="attentionTo.name"
                                    v-model:optionValue="attentionTo.directoryId"
                                    ></Select2>
                                    <small class="text-warning" v-if="additionalAttention[0].directoryId!=''&&!attentionTo.directoryId">{{$t('Quotation Module.Please select a client before adding additional signers')}}</small>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-sm-12">
                    <div class="mt-3 ms-3">
                        <div v-if="!isEditMode">{{quotationData.quotationName}}</div>
                        <div v-else>
                            <div>
                                <span class="fw-bold ">{{$t('Quotation Module.Quotation Name')}} <i class="text-warning">*</i></span>  
                                <input type="text" class="form-control width-50"  :placeholder="$t('Quotation Module.Quotation Name')" v-model="quotationData.quotationName" />
                                <small class="text-warning" v-if="!quotationData.quotationName">{{ $t('Required') }}</small>
                            </div>
                        </div>
                        <div v-if="!isEditMode">{{$t('Quotation Module.Based on confirmed layout dated')}} {{FormatLayoutDate}}</div>
                        <div  v-else>
                            <span class="fw-bold">{{$t('Layout Date based')}} <i class="text-warning">*</i></span> 
                            <DatePicker v-model="quotationData.layoutDate" class="width-50" elemId="quotation-date-layoutDate" />
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-sm-12">
                    <div class="mt-3">
                        <div>
                            <span class="fw-bold d-print-none">{{$t('Remark')}}:</span>  
                            <span class="fw-bold d-none d-print-inline">{{$t('Quotation Module.RE')}}:</span>  
                            <span v-if="!isEditMode">{{quotationData.remark || 'N/A'}}</span>
                            <div v-else>
                                <textarea class="form-control width-50" style="height:150px" v-model="quotationData.remark"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pt-3 print-body">
                <div class="d-none d-print-block bg-separator">
                    {{$t('Quotation Module.Summary')}}
                </div>
                <div class="d-flex justify-content-end d-print-none" v-if="isEditMode" >
                    <button class="btn btn-outline-primary me-1"
                    v-if="permission.indexOf(ModuleActionList.BOQ_QUOTATION_EDIT)>-1"
                    data-bs-toggle="modal" data-bs-target="#ModalNewQuotationAdd"
                    ><i class="fa-solid fa-plus"></i> {{$t('Quotation Module.New BoQ')}} </button>

                    <button class="btn btn-outline-primary"
                    v-if="permission.indexOf(ModuleActionList.BOQ_QUOTATION_EDIT)>-1"
                    @click="OpenAddBoqToQuotation"
                    data-bs-toggle="modal" data-bs-target="#ModalAddBoqQuotation"
                    ><i class="fa-solid fa-plus"></i> {{$t('Quotation Module.Existing BoQ')}} </button>
                </div>
                <FigureCurrencyNote :currency="projectInfo.currencyId" />
                <div v-if="isSummaryLoading">
                    <Loader />
                </div>
                <div class="table-responsive" v-else>
                    <BoqQuotationBoqSummary
                    :dataRow="summaryByBoqList"
                    :unitOfMeasure="quotationData.projectId?.unit_of_measure"
                    />
                </div>
                <!-- <div class="table-responsive" v-else>
                    <table class="table " >
                        <thead>
                            <th>Item</th>
                            <th>Description</th>
                            <th class="text-end">%</th>
                            <th class="text-end">p{{quotationData.projectId?.unit_of_measure}}</th>
                            <th class="text-end">Amount</th>
                        </thead>
                        <tbody v-if="summaryList.length==0">
                            <tr class="d-print-none">
                                <td colspan="5" class="text-center">
                                    No Data Found
                                </td>
                            </tr>
                            <tr class="d-none d-print-table-row">
                                <td  colspan="5" class="text-center ">
                                    No Data Found
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <template v-for="(item,key) in SummaryRender" :key="key" >
                                <tr class="bg-light fw-bold" v-if="item.willShowParent">
                                    <td >{{item.label}}</td>
                                    <td colspan="4 " class="fw-bold">{{item.parent.name}}</td>
                                </tr>
                                <tr>
                                    <td>{{item.index}}</td>
                                    <td>{{item.costCode.name}}</td>
                                    <td class="text-end">{{item.percentTotal}}%</td>
                                    <td class="text-end">{{item.perSize}}</td>
                                    <td class="text-end">{{item.sellTotal}}</td>
                                </tr>
                                <tr v-if="item.subTotal">
                                    <td></td>
                                    <td class="fw-bold text-end">Sub Total of Section {{item.subTotal.label}}</td>
                                    <td class="text-end fw-bold">{{item.subTotal.percentTotal}}%</td>
                                    <td class="text-end fw-bold">{{item.subTotal.perSize}}</td>
                                    <td class="text-end fw-bold">{{item.subTotal.total}}</td>
                                </tr>
                                <tr v-if="item.overAllTotal">
                                    <td></td>
                                    <td class="fw-bold text-end">Total A-{{item.overAllTotal.lastLabel}} (Exclude GST)</td>
                                    <td class="text-end fw-bold">{{item.overAllTotal.percentTotal}}%</td>
                                    <td class="text-end fw-bold">{{item.overAllTotal.perSize}}</td>
                                    <td class="text-end fw-bold">{{item.overAllTotal.total}}</td>
                                </tr>
                                <tr v-if="item.overAllTotal">
                                    <td colspan="4" class="text-end">Subject to prevailing GST rates</td>
                                    <td ></td>
                                </tr>
                            </template>
                            <tr>
                                <td colspan="4" class="text-end">
                                    Approximate Area
                                </td> 
                                <td>
                                    {{quotationData.projectId?.size || ''}}{{quotationData.projectId?.unit_of_measure || ''}}
                                </td> 
                            </tr>
                        </tbody>
                    </table>
                </div> -->
                <div class="d-print-none">
                    <router-link :to="{name : 'BoqQuotationAttachedItems', params : {id : quotationData._id}, query : {returnUrl : returnUrl}} " class="btn btn-outline-primary">
                        <i class="fa-solid fa-paperclip"></i> {{$t('View Attached Bill of Quantities')}}
                    </router-link>
                </div>
            </div>
            <div class="d-none d-print-inline pager-count">  </div>
            <div class="break-after"></div>
            <BoqQuotationHeaderReadOnly class="d-none d-print-block" :quotationData="quotationData" />
            <div class="row print-body " >
                <div class="col-md-12 col-sm-12">
                    <div class="pt-2 " >
                        <div class="d-none d-print-block bg-separator">
                            {{$t('Notes/Remark(Scope of Work)')}}
                        </div>
                        <div class="d-print-none fw-bold">{{$t('Notes/Remark(Scope of Work)')}}:</div>
                        <p v-if="!isEditMode" v-html="quotationData.scopeOfWork  || '-' "></p>
                        <RichTextEditor v-model:modelValue="quotationData.scopeOfWork" v-else :placeHolder="$t('Notes/Remark(Scope of Work)')" :height="'200'" />
                    </div>
                </div>
            </div>
            
            <div class="d-none d-print-inline pager-count">  </div>
            <div class="break-after"></div>
            <BoqQuotationHeaderReadOnly class="d-none d-print-block" :quotationData="quotationData" />
            <div class="row print-body " >
                <div class="col-md-12 col-sm-12">
                    <div class="pt-2" >
                        <div class="d-none d-print-block bg-separator">
                            {{$t('Terms & Conditions')}}
                        </div>
                        <div class="d-print-none fw-bold">{{$t('Terms & Conditions')}}</div>
                        <p v-if="!isEditMode" v-html="quotationData.termAndConditions || '-' "></p>
                        <RichTextEditor v-else v-model:modelValue="quotationData.termAndConditions" :placeHolder="$t('Terms & Conditions')" :height="'200'" />
                    </div>
                </div>
                
                <div class="col-md-12 d-print-none" >
                    <div class="fw-bold">{{$t('Attachments')}}</div>
                    <input type="file" ref="fileDatas"  accept="application/pdf" v-if="isEditMode"  multiple class="form-control" @change="OnChangeFiles" />
                </div>
                <div class="col-md-12 d-print-none" >
                    <div class="row  pt-3">
                        <div class="col-md-4" v-for="(item,key) in quotationData.attachments" :key="key">
                            <div class="input-group mb-3" v-if="item._id!=0">
                                <span class="input-group-text bg-white" ><i class="fa-solid fa-paperclip"></i></span>
                                <input type="text" role="button" @click="OnclickLink(item.sharepointLink)" 
                                data-bs-toggle="tooltip" data-bs-placement="top" :title="$t('Click to open in Sharepoint')"
                                class="form-control text-decoration-underline text-primary" readonly :value="item.attachmentName">
                                <span class="text-danger ps-2 pt-2 fw-bold" role="button" v-if="isEditMode" @click="RemoveAttachment(item._id,item.attachmentName)"><i class="fa-solid fa-times"></i> {{$t('Remove')}} </span>
                            </div>
                            <div class="input-group mb-3  " v-else>
                                <span class="input-group-text bg-white text-success" ><i class="fa-solid fa-link-slash"></i></span>
                                <input type="text" 
                                data-bs-toggle="tooltip" data-bs-placement="top" :title="$t('File to be Uploaded')"
                                class="form-control text-success" readonly :value="item.attachmentName">
                                <span class="text-danger ps-2 pt-2 fw-bold" role="button" v-if="isEditMode" @click="RemoveAttachment(item._id,item.attachmentName)"><i class="fa-solid fa-times"></i> {{$t('Remove')}} </span>
                            </div>
                            
                            <!-- <a class="text-primary fw-bold" role="button" :href="item.sharepointLink" target="_blank"> <i class="fa-solid fa-paperclip"></i> {{item.attachmentName}}</a> 
                            <span class="text-danger" v-if="isEditMode"><i class="fa-solid fa-times"></i> Remove </span> -->
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-sm-12  d-print-none">
                    <div class="pt-2" >
                        <div class="fw-bold">{{$t('Construction Schedule')}}</div>
                        <div v-if="!isEditMode">
                            {{quotationData.constructionSchedule || '-'}}
                        </div>
                        <input v-else type="text" class="form-control " :placeholder="$t('Quotation Module.Link of the construction schedule')" v-model="quotationData.constructionSchedule"  />
                    </div>
                </div>
                <div class="col-md-12 col-sm-12 d-print-none" >
                    <div class="pt-2" >
                        <div class="fw-bold">{{$t('Construction Drawings')}}</div>
                        <div v-if="!isEditMode">
                            {{quotationData.constructionDrawings || '-'}}
                        </div>
                        <input v-else type="text" class="form-control " :placeholder="$t('Quotation Module.Link of the construction drawings')"  v-model="quotationData.constructionDrawings"  />
                    </div>
                </div>
                <div class="col-md-6 pt-5 col-sm-6">
                    <div class="fw-bold">{{$t('Submitted By')}} : </div>
                    <div class="">M. Moser Associates (S) Pte Limited</div>
                    <div v-if="!isEditMode">
                        <div class=" signature-padding " v-for="(item, key) in quotationData.submittedTo" :key="key">
                            <div class="border-top width-50 d-none d-print-block"></div>
                            <div v-if="item.signatureBlob">
                                <img :src="item.signatureBlob" />
                            </div>
                            <div class="text-capitalize">
                                {{item.userId.fullname}}
                            </div>
                            <span class="small text-capitalize">{{item.roleInProject}}</span>
                        </div>
                    </div>
                    <div v-else>
                        <div class="d-flex mt-2" v-for="(item, key) in submittedTo" :key="key" >
                            <Select2 
                            ref="select2UserFromDirectory"
                            :filterQuery="SearchUserFromProjectDirectory"
                            indexValue="directoryId"
                            labelName="fullname"
                            :placeHolder="$t('Search Here')"
                            :iniLabel="item.name"
                            v-model:optionValue="item.directoryId"
                            :onOptionClick="AddNewSubmittedLine"
                            ></Select2>
                            
                            <div  class="ps-2">
                                <small role="button" class="text-danger" @click="RemoveSubmittedLine(item.directoryId)"><i class="fa-solid fa-times"></i> {{$t('Remove')}}</small>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 pt-5 col-sm-6 ">
                    <div class="fw-bold">{{$t('Submitted To')}} : </div>
                    <div>
                        <div v-if="quotationData.attentionClient.userId">
                            {{quotationData.attentionClient.userId.company || '-'}}
                        </div>

                    </div>
                    
                    <div v-if="!isEditMode">
                        <!-- <div class="signature-padding  ">
                            <div class="border-top width-50 d-none d-print-block"></div>
                            <span class="text-muted"></span>  
                            <span  class="text-capitalize">{{quotationData.attentionClient.userId.fullname || 'N/A'}}</span>
                        </div> -->
                        <div class=" signature-padding " v-for="(item, key) in quotationData.additionalAttention" :key="key">
                            <div class="border-top width-50 d-none d-print-block"></div>
                            <div class="text-capitalize">
                                {{item.userId.fullname}}
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="signature-padding  ">
                            <div class="border-top width-50 d-none d-print-block"></div>
                            <span class="text-muted"></span>  
                            <!-- <span  class="text-capitalize">{{quotationData.attentionClient.userId.fullname || 'N/A'}}</span> -->
                        </div>
                        <div class="d-flex mt-2" v-for="(item, key) in additionalAttention" :key="key" >
                            <Select2 
                            ref="select2UserFromDirectory"
                            :filterQuery="SearchClientFromProjectDirectory"
                            indexValue="directoryId"
                            labelName="fullname"
                            :placeHolder="$t('Search Here')"
                            :iniLabel="item.name"
                            v-model:optionValue="item.directoryId"
                            :onOptionClick="AddNewAttentionClient"
                            ></Select2>
                            
                            <div  class="ps-2">
                                <small role="button" class="text-danger" @click="RemoveAdditionalClientLine(item.directoryId)"><i class="fa-solid fa-times"></i> {{$t('Remove')}}</small>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-12 d-print-none">
                    <div class="d-flex justify-content-end ">
                        <div class="btn-group me-1 " role="group" v-show="!isEditMode">
                            <button class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown"  >
                                <i class="fa-solid fa-gear"></i> Actions
                            </button>
                            <ul class="dropdown-menu" >
                                <li role="button"  @click="()=>{ isEditMode = true}" v-if="permission.indexOf(ModuleActionList.BOQ_QUOTATION_EDIT)>-1">
                                    <a class="dropdown-item"  ><i class="fa-solid fa-edit"></i> Edit </a>
                                </li>
                                <li role="button" >
                                    <a class="dropdown-item" @click="PublishQuotation" 
                                    v-if="quotationDataIntial.approvalStage==ApprovalStageBoq.APPROVED && permission.indexOf(ModuleActionList.BOQ_QUOTATION_PUBLISH)>-1"
                                    >
                                        <i class="fa-solid fa-file-circle-check"></i> Contract Signed
                                    </a>
                                </li>
                                <li role="button" v-if="quotationDataIntial.approvalStage!=ApprovalStageBoq.FOR_APPROVAL && permission.indexOf(ModuleActionList.BOQ_QUOTATION_PUBLISH)>-1" >
                                    <a class="dropdown-item" @click="OpenWorkFlowModal">
                                        <i class="fa-solid fa-file-circle-check"></i> For Approval
                                    </a>
                                </li>
                                <li role="button"> 
                                    <a class="dropdown-item"  @click="ExportToPdf" ><i class="fa-solid fa-file-arrow-down"></i> Print</a>
                                </li>
                            </ul>    
                        </div>
                        
                        <div v-show="isEditMode">
                            <button class="btn btn-default me-1" @click="CancelEdit">
                                <i class="fa-solid fa-times"></i> Close
                            </button>
                            <button class="btn btn-success me-1" @click="UpdateQuotation" :disabled="!haveChanges">
                                <i class="fa-solid fa-floppy-disk"></i> Save Changes 
                            </button>
                        </div>
                    </div>
                </div> -->
                <div class="d-none d-print-inline pager-count"> </div>
                
            </div> 
        </fieldset>
        
    </div>

    <Modal idModal="ModalNewQuotationAdd" :headerTitle="$t('Add BoQ')" modalSize="modal-md">
        <div class="form-group">
            <label class="required-style">{{$t('Bill Of Quantity')}}</label>
            <input type="text" v-model="newlyCreatedBoq.name" :placeholder="$t('Bill Of Quantity')" class="form-control"  />
        </div>
        <template v-slot:actions>
            <button type="button" ref="closeModalNewQuotationAdd" class="btn btn-outline-danger" data-bs-dismiss="modal">X {{$t('Cancel')}}</button>
            <button type="button" class="btn btn-primary" @click="CreateBoqNew"><i class="fa-solid fa-save"></i> {{$t('Add BoQ')}}</button>
        </template>
    </Modal>
    <Modal idModal="ModalAddBoqQuotation" :headerTitle="$t('Quotation Module.Add Existing BoQ')" modalSize="modal-md">
        <div class="form-group">
            <label class="required-style">{{$t('Bill Of Quantity')}}</label>
            <Select2 
            :filterQuery="SearchBoqNoQuotations"
            indexValue="_id"
            labelName="name"
            :placeHolder="$t('Search Here')"
            ref="select2BoqToQuotation"
            v-model:optionValue="newBoq.boqId"
            :dataOptions="boqList"
            ></Select2>
        </div>
        <template v-slot:actions>
            <button type="button" ref="closeModalAddBoqQuotation" class="btn btn-outline-danger" data-bs-dismiss="modal">X {{ $t('Cancel') }}</button>
            <button type="button" class="btn btn-primary" @click="AddBoqToQuotation"  ><i class="fa-solid fa-save"></i> {{$t('Quotation Module.Add Existing BoQ')}}</button>
        </template>
    </Modal>
    <!-- <Modal idModal="QuotationApprovalModal" headerTitle="Send For Approval Quotation" >
      <div>
       <div class="form-group">
            <label class="required-style">Date End </label>
            <input type="date" v-model="quotationApprovalData.dateEnd" class="form-control"/> 
            <small class="text-warning" v-if="isApprovalClicked && !quotationApprovalData.dateEnd">Required</small>
        </div>
       <div class="form-group">
            <label class="required-style">Process </label>
            <Select2
                indexValue="_id"
                labelName="processName"
                placeHolder="Search Process Here"
                v-model:optionValue="quotationApprovalData.processId"
                :dataOptions="approvalProcessList"
            >
            </Select2>
            <a class="text-primary small"  :href="projectUrl +'/Project/' + projectInfo._id+'/process'">No Process on the list? Click here to Create</a>
            <small class="text-warning" v-if="isApprovalClicked && !quotationApprovalData.processId">Required</small>
        </div>
      </div>
      <template v-slot:actions>
        <button type="button" ref="closeQuotationApprovalModal" class="btn btn-outline-danger" data-bs-dismiss="modal">X Cancel</button>
        <button type="button" class="btn btn-primary" @click="ForApprovalQuotation" ><i class="fas fa-save"></i> Send For Approval</button>
      </template>
    </Modal> -->
    
</template>

<script src="@/assets/boq/BoqQuotation.js">

</script>
<style scoped>
    .width-50{
        width: 50%;
    }
    .signature-padding{
        margin-top:5px
    }
    @media print {
        .border{
            border: none !important;
        }
        .border-top {
            border-top:1px solid black !important
        }
        .print-content {
            font-size : 8px !important;
        }
        .signature-padding{
            margin-top:50px
        }
        body {
            margin-top: 0.1mm;
            margin-bottom: 0.15mm;
            margin-right: 0.30mm;
            margin-left: 0.30mm;
        }

        .table>:not(caption)>*>* {
            padding: 0.2rem 0.2rem;
        }

        .bg-light{
            print-color-adjust: exact;
            -webkit-print-color-adjust: exact;
        }

        .bg-separator{
            font-weight: 600;
            padding-top  : 15px;
            background-color: #ffc000;
            print-color-adjust: exact;
            -webkit-print-color-adjust: exact;
            border-bottom: 1px solid #000;
        }
        
    }
    
</style>