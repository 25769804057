import { GetCostCodes, UpsertBoqListItems } from '@/actions/boqActions.js'

const state ={
    costCodes : [],
    dataItems : null
}
const getters = {
    boqCostCode : state => state.costCodes,
    boqDataItems : state => state.dataItems
}

const actions = {
    async UpsertBoqListItemsRun(obj,data){
        const upsert = await UpsertBoqListItems(data)

        return upsert
    },
    async SetCostCodes({commit}, {search = "", parentsOnly = false}){
        const data = await GetCostCodes(search,parentsOnly).catch()
        commit('setBoqCostCode',data)
    }
}

const mutations ={
    setBoqCostCode : (state,data) => (state.costCodes = data),
    setBoqDataItems : (state,data) => (state.dataItems = data)
}


export default {
    state,
    getters,
    actions,
    mutations
}