<template>
<div>
    <div class="d-flex fw-bold" style="background-color: #F5F6FA;" >
        <div class=" p-2 border border-end-0" style="width: 25%;">{{ $t('Section') }}</div>
        <div class=" p-2 border border-end-0 " style="width: 15%;">{{ $t('Total Cost') }} ({{boqItem.currencyId?.sign}})</div>
        <div class=" p-2 border border-end-0" style="width: 15%;">{{ $t('Total Sell') }} ({{boqItem.currencyId?.sign}})</div>
        <div class=" p-2 border border-end-0" style="width: 15%;">{{ $t('GP Total') }} ({{boqItem.currencyId?.sign}})</div>
        <div class=" p-2 border border-end-0" style="width: 15%;">{{ $t('GP') }} %</div>
        <div class=" p-2 border " style="width: 15%;"></div>
    </div>
    <BoqSectionRowReadOnly :SectionList="rawSections.sections" :Level="1" :BoqItem="boqItem" :approvalId="approvalId" :revisionId="revisionId" :breakdownLink="breakdownLink" />
    <div class="d-flex small fw-bold bg-light-primary" >
        <div class="text-end p-2 border border-end-0 border-top-0 " style="width: 25%;">
            <span class="fw-bold text-capitalize">{{ $t('Overall Total') }}</span>
        </div>
        <div class=" p-2 border border-end-0 border-top-0 text-end" style="width: 15%;" :class="{'text-danger' : rawSections.overAllTotal.costTotal < 0}">
            {{ ReformatNumber(rawSections.overAllTotal.costTotal) }}
        </div>
        <div class="p-2 border border-end-0 border-top-0 text-end" style="width: 15%;" :class="{'text-danger' : rawSections.overAllTotal.sellTotal < 0}">
            {{ ReformatNumber(rawSections.overAllTotal.sellTotal) }}
        </div>
        <div class=" p-2 border border-end-0 border-top-0 text-end" style="width: 15%;" :class="{'text-danger' : rawSections.overAllTotal.gp < 0}">
            {{ ReformatNumber(rawSections.overAllTotal.gp) }}
        </div>
        <div class="p-2 border border-end-0 border-top-0 text-end" style="width: 15%;" :class="{'text-danger' : rawSections.overAllTotal.gpPercentage < 0}">
            {{ ReformatNumber(rawSections.overAllTotal.gpPercentage,0,2)  }}%
        </div>
        <div class=" p-2 border border-top-0" style="width: 15%;">
        </div>
    </div>
</div>
</template>

<script>
import BoqSectionRowReadOnly from '@/components/boq/BoqSectionRowReadOnly.vue'
import {ReformatNumber } from '@/helpers/utilities.js'
export default {
    name : 'BoqSectionTableReadOnly',
    props : ['boqItem','rawSections','approvalId','revisionId','breakdownLink'],
    components : {
        BoqSectionRowReadOnly
    },
    methods :{
        ReformatNumber
    }
}
</script>