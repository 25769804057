import axios from 'axios'
import { CreateToken } from '../helpers/jwtHelpers.js'
import 'dotenv/config'

const MAIN_API = process.env.VUE_APP_REST_MAIN_API
// const TOKEN_INDEX_REST = 1
const TOKEN_INDEX_MAIN = 0

axios.defaults.withCredentials = true

export const GetMyUserInfo = async () =>{
    return axios.get(`${MAIN_API}/api/user/me`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>console.log(err))
}
export const GetAccessOnProject = async (projectId) =>{
    return axios.get(`${MAIN_API}/api/user/project/directory/me`
    ,{
        params : {projectId : projectId},
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>console.log(err))
}

export const GetUserByName = async (search) =>{
    return axios.get(`${MAIN_API}/api/user/findByName?search=${search}`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>console.log(err))
}

export const CheckSession = async () =>{
    return axios.get(`${MAIN_API}/api/auth/session`)
    .then(()=>{
        return true
    }).catch(()=>{ return false })
}
export const CheckSessionMsal = async (type = 1) =>{
    return axios.get(`${MAIN_API}/api/auth/session/msal?type=${type}`)
    .then(()=>{
        return true
    }).catch(()=>{ return false })
}

export const LogOut = async () =>{
    return axios.get(`${MAIN_API}/api/auth/logout`)
    .then(()=>{
        return true
    }).catch(()=>{ return false })
}
export const UpdateMyLanguage = async (data) =>{
    return axios.put(`${MAIN_API}/api/user/me/language`,
    data
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.status)
}


//for directory search
export const GetOffices = async () =>{
    return axios.get(`${MAIN_API}/api/directory/office/search`,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>{ return [] })
}

export const GetOffice = async (id) =>{
    return axios.get(`${MAIN_API}/api/directory/office/get/${id}`,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>{ return null })
}
export const GetUserSignature = async (data) =>{
    return axios.get(`${MAIN_API}/api/user/project/${data.projectId}/signature`,{
        params : {
            userId : data.userId,
            signatureId : data.signatureId
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>{ return null })
}
export const GetUserSignatureOffice = async (data) =>{
    return axios.get(`${MAIN_API}/api/user/office/${data.officeId}/signature`,{
        params : {
            userId : data.userId,
            signatureId : data.signatureId
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>{ return null })
}




