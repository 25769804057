<template>
    <div>
    <BreadcrumbsProject
        :breadcrumbs="[
            {
                link : projectUrl +'/',
                title : $t('Projects'),
                isRouterLink: false
            },
            {
                link : projectUrl +'/Project/' + projectInfo._id,
                title :  (projectInfo.projectNumber || '-') +' ' + (projectInfo.projectName || ''),
                isRouterLink: false
            },
            {
                link : '/boq/' + projectInfo._id,
                title : $t('Bill of Quantities'),
                isRouterLink: true
            },
            {
                link : '/boq/history/' + boqItemData._id,
                title : (boqItemData.name || '') + ' - ' + $t('History'),
                isRouterLink: true
            },
        ]"
    ></BreadcrumbsProject>
  </div>
    <div class="row pt-2">
        <div class="col-md-3 ">
            <button class="btn btn-primary ms-3" v-if="boqListParams.index!=0" @click="ChangeAddIndex">
                <i class="fa-solid fa-arrow-left-long fa-2x"></i>
            </button>
        </div>
        <div class="col-md-6">
            <div class="d-flex justify-content-center">
                <h3 class="text-capitalize fw-bold">
                    {{boqItemData.name}} 
                </h3>
            </div>
        </div>
        <div class="col-md-3">
            <div class="d-flex justify-content-end" v-if="boqListParams.index < boqItemData.revisionList.length - 1" @click="ChangeMinusIndex">
                <button class="btn btn-primary me-3">
                    <i class="fa-solid fa-arrow-right-long fa-2x"></i>
                </button>
            </div>
        </div>
        <div class="col-md-12 pt-2">
            <div class="card">
                <div class="card-header bg-white d-flex justify-content-between">
                    <div>
                        <small class="text-muted">{{$t('Version')}}</small> : <span>{{boqListParams.index + 1}}</span> <br />
                        <small  class="text-muted">{{$t('Currency')}}</small> : <span>({{boqRevision.currency?.sign}}) {{boqRevision.currency?.shortName}} {{boqRevision.currency?.name}}</span>
                    </div>
                    <div class="text-capitalize">
                        <small class="text-muted">{{ $t('Published By') }}</small> : <span>{{boqRevision.createdBy?.fullname}}</span> <br />
                        <small  class="text-muted">{{$t('Date Published')}}</small> : <span>{{formatDate(boqRevision.date)}} </span>
                    </div>
                </div>
                <div class="card-body">
                    <FigureCurrencyNote :currency="boqItemData.currencyId" />
                    <div class="row">
                        <div class="col-md-12">

                            <Loader v-if="isLoaded==false"/>
                            <BoqSectionTableReadOnly v-else
                            :rawSections="rawSections"
                            :boqItem="boqItemData"
                            :revisionId="boqRevision._id"
                            />
 
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <ModalLineItemColumnFilter :UpdateSelectedColumn="ChangeSelectecColumnValue"/>
    
</template>

<script src='@/assets/boq/BoqHistory.js' />
<style scoped >
    .min-width-300{
        min-width: 300px;
    }
    .min-width-200{
        min-width: 200px;
    }
    .min-width-150{
        min-width: 150px;
    }
    .min-width-125{
        min-width: 125px;
    }
    .min-width-100{
        min-width: 100px;
    }

    .text-danger-all td {
        color: #6c757d!important;
    }
    .cursor-pointer{
        cursor: pointer;
    }

</style>
