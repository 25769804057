<template>
    <div class="row">
        <div class="col-sm-12 d-none d-print-block">
            <div class="float-end">
                <a><img src="https://staff.mmoseronelink.com/mmoser-associates.svg"  alt="" width="300" height="40"></a>
            </div>
        </div>
        <div class="col-sm-6 d-none d-print-block">
            <div >
                <span class="fw-bold">{{ $t('Office in Charge') }}:</span>  
                <span class="text-capitalize" > {{officeList.find(u=>u._id==quotationItem.officeId)?.officeName || '-'}}</span>
            </div>
        </div>
        <div class=" col-sm-6 d-none d-print-block text-end">
            <div >
                <span class="fw-bold"> {{ $t('Bidding Module.Issue Date') }}:</span>  
                <span > {{ReformatDate(quotationItem.dateIssued)}}</span>
            </div>
        </div>
        <div class="d-none d-print-block col-sm-6 ">
            <div >
                <span class="fw-bold">{{ $t('Project') }}:</span>  
                <span v-if="quotationItem.projectId"> {{ quotationItem.projectId.projectNumber}} {{quotationItem.projectId.projectName}}</span>
                <span v-else></span>
            </div>
        </div>
        <div class="d-none d-print-block text-end col-sm-6">
            <div class="d-none d-print-block text-end">
                <span class="fw-bold"> {{ $t('Bidding Module.QTN No') }}:</span>  
                <span>{{ quotationItem.quotationNumber }} - Revision {{ quotationItem.revisionCount }}</span>
            </div>
        </div>
        <div class="d-none d-print-block col-sm-6">
            <div >
                <span class="fw-bold">{{ $t('Bidding Module.Project Location') }}:</span>  
                <span class="text-capitalize" > {{officeList.find(u=>u._id==quotationItem.projectLocation)?.officeName || '-'}}</span>
            </div>
        </div>
        <div class="d-none d-print-block text-end col-sm-6">
            <div class="d-none d-print-block text-end">
                <span class="fw-bold">{{ $t('Bidding Module.Estimate Total Floor area') }}:</span>
                <span>{{ quotationItem.floorArea }} {{ quotationItem.unitOfMeasure }}</span>
            </div> 
        </div>
        <div class="d-none d-print-block col-sm-6">
            <div class="">
                <span class="fw-bold"> {{ $t('Bidding Module.High Level Estimate Name') }}:</span>
                <span>{{ quotationItem.name }}</span>
            </div> 
        </div>
        <div class="d-none d-print-block text-end col-sm-6">
            <div class="d-none d-print-block text-end">
                <span class="fw-bold"> {{ $t('Currency') }}:</span>
                <span>{{ quotationItem.currencyId?.name || '-' }}</span>
            </div> 
        </div>
    </div>
</template>

<script>
import {ReformatDate} from '@/helpers/utilities.js'
export default{
    name : 'HleHeaderReadOnly',
    props: ['officeList', 'quotationItem'],
    methods :{
        ReformatDate
    }
}

</script>