<template>
    <div class="btn-group ">
        <button type="button" class="btn btn-default text-black dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown">
            <i class="fa-solid fa-file-excel"></i> {{ $t('Template') }}
        </button>
        <ul class="dropdown-menu">
            <li><a class="dropdown-item" href="#" @click="DownloadTemplate(0)">{{ $t('System Template') }}</a></li>
            <li><a class="dropdown-item" href="#" @click="DownloadTemplate(1)">Five D {{ $t('Template') }}</a></li>
            <li><a class="dropdown-item" href="#" @click="DownloadTemplate(2)">Cost X {{ $t('Template') }}</a></li>
        </ul>
    </div>
</template>

<script>
import {  DownloadTemplate1, ExportCostXTemplate, ExportFiveDTemplate } from '@/actions/boqActions.js'
export default{
    name : 'ButtonTemplateDownloader',
    data(){
        return {
            templateType : 0
        }
    },
    methods :{
        DownloadTemplate(type){
            if(type===0)
                DownloadTemplate1()
            else if(type===1)
                ExportFiveDTemplate()
            else if(type===2)
                ExportCostXTemplate()
            else
                this.$swal('Download Template','Sorry not yet implemented','warning')
        }
    }
}
</script>