<template>
    <BreadcrumbsProject
          :breadcrumbs="[
            {
                link : '/high-level-estimates/approval/bill-of-quantities/' + approvalId,
                title : $t('Approval Module.Approval of') +' '+ (boqItem.name || '-' ) ,
                isRouterLink: true
            },
            {
                link : '/high-level-estimates/approval/bill-of-quantities/' + approvalId + '/breakdown/' + parentId,
                title :  $t('Breakdown') + ' - ' + section.name ,
                isRouterLink: true
            }
          ]"
      ></BreadcrumbsProject>
   <div class="card">
        <div class="card-header bg-white">
            <div class="d-flex justify-content-between">
                <h6 class="fw-bold text-capitalize mb-0">{{ section.name }}</h6>
            </div>
        </div>
        <div class="card-body">
            <div class="btn-group">
                <button class="btn btn-default "
                @click="()=>{ OpenFilter = !OpenFilter}" title="Filter Line Items"
                >
                    <i class="fa-solid fa-filter"></i> {{$t('Filter')}} 
                </button>
                <button class="btn btn-default" title="Filter Columns"
                data-bs-toggle="modal" data-bs-target="#BoqColumnFilter"
                >
                    <i class="fa-solid fa-eye"></i> {{$t('Columns')}} 
                </button>
            </div>
            <div class="row">
                <div class="col-md-6 pb-2" v-if="OpenFilter">
                    <div class="form-group ">
                        <label class="d-flex justify-content-between">
                            <a>{{$t('Cost Category')}} </a>
                            <span role="button" class="text-danger text-decoration-underline" @click="ClearField(2)"><i class="fa-solid fa-xmark "></i> {{$t('Clear Field')}}</span>
                        </label>
                        <Multiselect 
                        :multiple="true" :options="searchableCostCodes" 
                        :placeholder="$t('Search Here')"
                        v-model="selectedCostCode" :max-height="300"
                        label="costName" track-by="_id"/>
                    </div>
                </div>
                <div class="col-md-6 pb-2" v-if="OpenFilter">
                    <div class="form-group ">
                        <label class="d-flex justify-content-between">
                            <a>{{$t('Space ID')}}</a>
                            <span role="button" class="text-danger text-decoration-underline" @click="ClearField(1)"><i class="fa-solid fa-xmark "></i> {{$t('Clear Field')}}</span>
                        </label>
                        <Multiselect 
                        :multiple="true" :options="searchableAreas" 
                        :placeholder="$t('Search Here')"
                        v-model="selectedArea"  :max-height="300" />
                    </div>
                </div>
            </div>
            <FigureCurrencyNote :currency="boqItem.currencyId" />
            <Loader  v-if="!dataReady"/>
            <BoqItemTableBreakdownReadOnly v-else :rowDatas="lineItems" :selectedColumns="selectedColumns" />
        </div>
   </div>
    <ModalLineItemColumnFilter :UpdateSelectedColumn="ChangeSelectecColumnValue"/>
</template>


<script>
import BreadcrumbsProject  from '@/components/projectInformation/BreadcrumbsProject.vue'
import BoqItemTableBreakdownReadOnly  from '@/components/boq/BoqItemTableBreakdownReadOnly.vue'
import ModalLineItemColumnFilter from '@/components/boq/ModalLineItemColumnFilter.vue'
import {GetHleNewBoqCreatorSectionsRevisionsSection, GetHighLevelBoqApprovalLineItemsByBits, GetHighLevelBoq} from '@/actions/boqActions.js'
import {GetWorkflowItem} from '@/actions/projectActions.js'
import {LinkList,GetDistinctValues, GetDistinctValuesObject} from '@/helpers/utilities.js'
import 'dotenv/config'
import Loader  from '@/components/layout/Loader.vue'
import {mapActions,mapGetters} from 'vuex'
import FigureCurrencyNote from '@/components/layout/FigureCurrencyNote.vue'
export default {
    name : 'HleBoqItemsApprovalBreakdown',
    components : {
         Loader, BoqItemTableBreakdownReadOnly,
        ModalLineItemColumnFilter, BreadcrumbsProject,
        FigureCurrencyNote
    },
    mounted : async function(){
        const self = this
        const approvalItem = await GetWorkflowItem(self.approvalId)
        self.ChangeActiveLink(LinkList.HIGH_LEVEL_ESTIMATES)
        const boqItem = await GetHighLevelBoq(approvalItem.relatedId)
        self.boqItem = boqItem
        self.section = await GetHleNewBoqCreatorSectionsRevisionsSection(self.parentId,approvalItem.relatedId,approvalItem.relatedRevisionId)
        await self.GetData(self.boqListParams)
        const lineItemCols =  window.localStorage.getItem('lineItemColumns')
        if(lineItemCols){
            self.selectedColumns = lineItemCols.split(',').map(Number)
        }
        self.searchableAreas = GetDistinctValues(self.lineItems.map(u=>u.area).filter(value => value !== null && value !== undefined && value !== ''))
        self.searchableAreas.push(self.$i18n.t('NO SPACE ID'))
        const getLineItemCostCode = 
        self.userInfo.language =='en' ? 
            self.lineItems.map(u=>{
                return {
                    _id : u.costCodeId,
                    costName : u.costCode + ' ' +u.name
                }
            }) 
        :
            self.lineItems.map(u=>{
                return {
                    _id : u.costCodeId,
                    costName : u.costCode + ' ' +u.nameCh
                }
            }) 
        self.searchableCostCodes = GetDistinctValuesObject(getLineItemCostCode)

    },
    data(){
        return {
            lineItems : [],
            approvalId : this.$route.params.id,
            revisionId : this.$route.query.revisionId,
            parentId : this.$route.params.parent,
            section : {},
            boqItem : {},
            selectedArea : [],
            selectedCostCode : [],
            dataReady : false,
            OpenFilter : false,
            selectedColumns : [],
            boqListParams : {
                page : 1,
                parentId : this.$route.params.parent,
                revisionId : this.$route.query.revisionId,
                id :  this.$route.params.id,
                costCode : '',
                area : '',
                skip : 0,
                take : 50
            },
            timerSearch : null
        }
    },
    methods : {
        ...mapActions(['ChangeActiveLink']),
        ClearField(type){
            if(type==1){
                this.selectedArea = []
            }
            else{
                this.selectedCostCode = []
            }
        },
        async GetData(){
            const self = this
            self.lineItems = []
            let willFetch = true
            self.boqListParams.skip = 0
            self.dataReady = false
            while(willFetch){
                const bits = await GetHighLevelBoqApprovalLineItemsByBits(self.boqListParams)
                if(bits.length!=0){
                    self.lineItems.push(...bits)
                }
                if(bits.length==self.boqListParams.take){
                    self.boqListParams.skip += 50
                }else{
                    willFetch = false
                }
            }

            self.dataReady = true
        },
        ChangeSelectecColumnValue(value){
            this.selectedColumns = value
        },
        TimerSetterSearch(callback){
            const self = this
            
            if(self.timerSearch){
                clearTimeout(self.timerSearch)
                self.timerSearch = null
            }

            self.timerSearch = setTimeout(callback,700)
        },
    },
    watch :{
        selectedCostCode(val){
            const self = this
            self.boqListParams.costCode = encodeURIComponent(JSON.stringify(val.map(u=>u._id)))
            self.boqListParams.area = encodeURIComponent(JSON.stringify(self.selectedArea))
            self.TimerSetterSearch(async function(){
                await self.GetData()
            })
        },
        selectedArea(val){
            const self = this
            self.boqListParams.costCode = encodeURIComponent(JSON.stringify(self.selectedCostCode.map(u=>u._id)))
            self.boqListParams.area = encodeURIComponent(JSON.stringify(val))
            self.TimerSetterSearch(async function(){
                await self.GetData()
            })
        },
    },
    computed : {
        ...mapGetters(['userInfo'])
    }
}
</script>