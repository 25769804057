<template>
    <div>
        <div class="text-end d-none d-print-block">
            <a><img src="https://staff.mmoseronelink.com/mmoser-associates.svg" alt="" width="300" height="40"></a>
        </div>
        <div class="d-flex justify-content-between">
            <div>
                <div>
                    <span class="text-muted">{{ $t('Bidding Module.Detailed Estimate') }} : </span>
                    <span class="fw-bold text-capitalize">{{ boqItem.name }}</span>
                </div>
            </div>
            <div>
                <div>
                    <span class="text-muted">{{$t('Date')}} : </span>
                    <span class="fw-bold">{{ ReformatDate(new Date())}}</span>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-between pt-2 pb-2">
            <router-link :to="{name : 'HleBoQBuilder', params : { id : hleProjectId, boqId : boqItem._id}}" class="btn btn-default text-black d-print-none"> 
                <i class="fa-solid fa-arrow-left-long"></i> {{$t('Back To Detailed Estimate')}}
            </router-link>
            <div>
                <button class="btn btn-default text-black d-print-none me-1" @click="print"><i class="fa-solid fa-print"></i> {{$t('Print')}}</button>
                <button class="btn btn-default text-black d-print-none" :disabled="downloading" @click="downloadExcel"><i class="fa-solid fa-file-excel"></i> {{ $t('Download') }}</button>
            </div>
        </div>
        <div class="bg-white p-2">
            <BoqCreatorPrintView  :sectionsList="sectionsList" :IsOpenBook="false" :currencySign="boqItem.currencyId?.sign" />
        </div>
    </div>
</template>
<style scoped>
    @media print {
        .sticky-left {
            position: static !important; /* Overrides the sticky position */
        }
    }
</style>
<script>
import {GetHighLevelBoq, GetHighLevelBoqLineItemsByBits, GetHighLevelProject, GetHleNewBoqCreatorSections, HleBoQItemExportForClient} from '@/actions/boqActions.js'
import {ReformatDate} from '@/helpers/utilities.js'
import BoqCreatorPrintView from '@/components/boq/BoqCreatorPrintView.vue'
export default{
    name : 'HleBoqItemsPrint',
    components :{BoqCreatorPrintView},
    data(){
        return {
            hleProjectId : this.$route.params.id,
            hleProject: {},
            boqId : this.$route.params.boqId,
            lineItems : [],
            boqItem : {
                status : 1,
                currencyId  : {
                    sign : '',
                }
            },
            boqListParams : {
                id : this.$route.params.boqId,
                costCode : '',
                area : '',
                page : 1,
                take : 50,
                skip : 0
            },
            sectionsList : {
                sections : [],
                overAllTotal : {}
            },
            downloading : false
        }
    },
    mounted :async function(){
        const self = this
        const boqItem = await GetHighLevelBoq(self.boqId)
        self.boqItem = boqItem
        self.hleProject = await GetHighLevelProject(self.hleProjectId)
        self.sectionsList = await GetHleNewBoqCreatorSections(self.boqId,1)

    },
    methods : {
        ReformatDate,
        print(){
            window.print()
        },
        async downloadExcel(){
            if(this.downloading){ return }
            this.downloading = true
            await HleBoQItemExportForClient(this.boqId,this.boqItem.name)
            this.downloading = false
        },
        async GetListByBits(){
            const self = this

            self.lineItems = []
            let willFetch = true
            self.boqListParams.skip = 0
            while(willFetch){
                const bits = await GetHighLevelBoqLineItemsByBits(self.boqListParams)
                if(bits.length!=0){
                    self.lineItems.push(...bits)
                }
                if(bits.length==self.boqListParams.take){
                    self.boqListParams.skip += 50
                }else{
                    willFetch = false
                }
            }
        },
    },
}

</script>