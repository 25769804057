<template>
    <div>
        <BreadcrumbsProject
        :breadcrumbs="[
            {
                link : '/high-level-estimates',
                title : $t('Bidding'),
                isRouterLink: true
            },
            {
                link : '/high-level-estimates/' + hleProjectId + '/bill-of-quantities',
                title : (hleProject.projectName || '-' )+ ' - ' + $t('Bidding Module.Detailed Estimates'),
                isRouterLink: true
            },
            {
                link : '/high-level-estimates/' + hleProjectId + '/bill-of-quantities-builder/' + boqId,
                title :boqUrlname,
                isRouterLink: true
            },
            {
                link : '/high-level-estimates/' + hleProjectId + '/bill-of-quantities-builder/' + boqId + '/Unassigneds',
                title :$t('Unassigned Line Items'),
                isRouterLink: true
            },
        ]"
        />
   </div>
   <div class="card">
        <div class="card-header bg-white">
            <div class="d-flex justify-content-between">
                <h6 class="fw-bold text-capitalize mb-0"> {{ boqItem.name}} - {{ $t('Unassigned Line Items') }}</h6>
            </div>
        </div>
        <div class="card-body">
            <div class="d-flex justify-content-between">
                <div class="btn-group">
                    <button class="btn btn-default "
                    @click="()=>{ OpenFilter = !OpenFilter}" 
                    >
                        <i class="fa-solid fa-filter"></i>  {{$t('Filter')}}
                    </button>
                    <button class="btn btn-default"
                    data-bs-toggle="modal" data-bs-target="#BoqColumnFilter"
                    >
                        <i class="fa-solid fa-eye"></i>  {{$t('Columns')}}
                    </button>
                </div>
                <div class="btn-group" v-if="!IsQuotationPublished">
                    <button class="btn btn-primary"
                    data-bs-toggle="modal" 
                    data-bs-target="#BoqAttachModal" 
                    > <i class="fa-solid fa-link"></i> {{ $t('Attach to Section') }}</button>
                    <button class="btn btn-outline-danger"
                    data-bs-toggle="modal" 
                    data-bs-target="#BoqDeleteLineItemsModal" 
                    > <i class="fa-solid fa-trash-can"></i> {{$t('Delete Line Items')}}</button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 pb-2" v-if="OpenFilter">
                    <div class="form-group ">
                        <label class="d-flex justify-content-between">
                            <a>{{$t('Cost Category')}}</a>
                            <span role="button" class="text-danger text-decoration-underline" @click="ClearField(2)"><i class="fa-solid fa-xmark "></i> {{ $t('Clear Field') }}</span>
                        </label>
                        <Multiselect 
                        :multiple="true" :options="searchableCostCodes" 
                        :placeholder="$t('Search Here')"
                        v-model="selectedCostCode" :max-height="300"
                        label="costName" track-by="_id"/>
                    </div>
                </div>
                <div class="col-md-6 pb-2" v-if="OpenFilter">
                    <div class="form-group ">
                        <label class="d-flex justify-content-between">
                            <a>{{$t('Space ID')}}</a>
                            <span role="button" class="text-danger text-decoration-underline" @click="ClearField(1)"><i class="fa-solid fa-xmark "></i> {{ $t('Clear Field') }}</span>
                        </label>
                        <Multiselect 
                        :multiple="true" :options="searchableAreas" 
                        :placeholder="$t('Search Here')"
                        v-model="selectedArea"  :max-height="300" />
                    </div>
                </div>
            </div>
            
            <FigureCurrencyNote :currency="boqItem.currencyId" />
            <Loader  v-if="!dataReady"/>
            <div class="table-responsive" style="height:64vh" >
            <table class="table table-header-solid table-bordered" >
                <thead class="sticky-top">
                    <tr >
                        <th style="min-width:75px;" class="sticky-left text-center" valign="middle" v-if="!IsQuotationPublished">
                            <input type="checkbox" @change="OnChecked" style=" transform: scale(1.5)"  />
                        </th>
                        <th style="min-width:75px;" class="sticky-left">{{$t('S/N')}}</th>
                        <th style="min-width:600px;" class="sticky-left">{{$t('Description')}}</th>
                        <th style="min-width:200px;">{{$t('Space ID')}}</th>
                        <th>{{$t('Unit')}}</th>
                        <BoqLineItemRowThead 
                        :selectedColumns="selectedColumns" />
                        <th class="sticky-right"></th>
                    </tr>
                </thead>
                <tbody class="table-group-divider"  v-if="lineItems.length==0">
                    <tr>
                        <td 
                        colspan="25">{{ $t('No data Found') }}
                        </td>
                    </tr>
                    </tbody>
                    <tbody v-else >
                        <template v-for="(element,key) in LineItemData" :key="key">
                            <tr  >
                                <td class="text-center" valign="middle" v-if="!IsQuotationPublished">
                                    <input type="checkbox" style=" transform: scale(1.5)"  v-model="selectedLineItems" :value="element._id" />
                                </td>
                                <td class="text-center">
                                    {{element.label}}
                                </td>
                                <td class="sticky-left bg-white">
                                    <div >
                                        <div  class="d-flex justify-content-between"  >
                                            <span class="fw-bold text-capitalize" v-if="userInfo.language=='en'">{{element.name}}</span>
                                            <span class="fw-bold" v-else>{{element.nameCh || element.name}}</span>
                                            <span>
                                                <small v-if="element.remark" class="text-info me-1 bg-light px-1 rounded">{{ $t('BillOfQuantityRemark.' + element.remark) }}</small>
                                                <small v-if="element.rateOnly" class="text-danger bg-light px-1 rounded">{{$t('RATE ONLY')}}</small>
                                            </span>
                                        </div>
                                        <p class="small mb-0 description-line-item">
                                            {{element.description}}
                                        </p>
                                    </div>
                                </td>
                                <td  >{{element.area}}</td>
                                <td>{{element.unit}}</td>
                                <BoqLineItemRow 
                                :data="element" :ReformatNumber="ReformatNumber" :selectedColumns="selectedColumns" />
                                <td style="width : 50px" class="sticky-right bg-white shadow-lg"  >
                                    <div v-if="!IsQuotationPublished">
                                        <button class="btn btn-outline-primary btn-action mb-1" @click="OpenEditLineItem(element)"
                                        ref="ModalUpdateLineItemBtn"
                                        data-bs-toggle="modal" data-bs-target="#ModalUpdateLineItem"
                                        >
                                            <i class="fa-solid fa-edit"></i>
                                        </button>
                                        <button  class="btn btn-outline-warning btn-action me-1" 
                                        @click="OpenDelete(element._id)" > <i class="fa-solid fa-trash-can"></i> </button>
                                    </div> 
                                </td>
                            </tr>
                        </template>
                    </tbody>
            </table>
        </div>
        </div>
   </div>
    <ModalLineItemColumnFilter :UpdateSelectedColumn="ChangeSelectecColumnValue"/>
    <Modal idModal="BoqAttachModal" :headerTitle="$t('Attach to Section')" modalSize="modal-md" >
        <div class="form-group">
            <label>{{ $t('Main Section') }}</label>
            <select class="form-select" v-model="selectedFirstSection" @change="ChangeSection(1)">
                <option value="">{{ $t('Please Select') }}</option>
                <option :value="item._id" v-for="(item,key) in firstSections" class="text-capitalize" :key="key"> {{ item.name }}</option>
            </select>
        </div>
        <div class="form-group" v-if="secondSections.length!=0">
            <label>{{$t('Sub Section')}}</label>
            <select class="form-select" v-model="selectedSecondSection" @change="ChangeSection(2)">
                <option value="">{{ $t('Please Select') }}</option>
                <option :value="item._id" v-for="(item,key) in secondSections" class="text-capitalize" :key="key"> {{ item.name }}</option>
            </select>
        </div>
        <div class="form-group" v-if="thirdSections.length!=0">
            <label>{{ $t('Sub Section') }}</label>
            <select class="form-select" v-model="selectedThirdSection">
                <option value="">{{ $t('Please Select') }}</option>
                <option :value="item._id" v-for="(item,key) in thirdSections" class="text-capitalize" :key="key"> {{ item.name }}</option>
            </select>
        </div>
        <div class="form-group">
            <label>{{ $t('Selected Line Items To be Moved') }}</label>
            <input class="form-control-plaintext text-capitalize" readonly :value="selectedLineItems.length"/>
            <small class="text-warning" v-if="selectedLineItems.length==0">{{ $t('No Selected Line Items') }}</small>
        </div>
        <template v-slot:actions>
            <button type="button" ref="closeBoqAttachModal" class="btn btn-outline-danger" data-bs-dismiss="modal">X {{ $t('Cancel') }}</button>
            <button type="button" class="btn btn-primary" v-if="selectedLineItems.length!=0" @click="AttachToParent"><i class="fa-solid fa-link"></i> {{$t('Attach')}}</button>
        </template>
    </Modal>
    <Modal idModal="BoqDeleteLineItemsModal" :headerTitle="$t('Delete Line Items')" modalSize="modal-md" >
        
        <div class="form-group">
            <label>{{ $t('Line Items Count to be Deleted') }}</label>
            <input class="form-control-plaintext text-capitalize" readonly :value="selectedLineItems.length"/>
            <small class="text-warning" v-if="selectedLineItems.length==0">{{ $t('No Selected Line Items') }}</small>
        </div>
        <template v-slot:actions>
            <button type="button" ref="closeBoqDeleteLineItemsModal" class="btn btn-outline-danger" data-bs-dismiss="modal">X {{ $t('Cancel') }}</button>
            <button type="button" class="btn btn-primary" v-if="selectedLineItems.length!=0" @click="DeleteLineItemsPermanent"><i class="fa-solid fa-trash-can"></i> {{$t('Delete')}}</button>
        </template>
    </Modal>
    <ModalUpdateLineItem
    :selectedLineItem="updateLineItem"
    :boqId="boqId"
    :isHighLevelEstimate="true"
    :UpdateLineItemFunc="UpdateHighLevelBoqLineItem" 
    :boqList="[]" :ResetData="GetData" />
</template>


<script>
import Modal  from '@/components/layout/Modal.vue'
import BreadcrumbsProject  from '@/components/projectInformation/BreadcrumbsProject.vue'
import ModalLineItemColumnFilter from '@/components/boq/ModalLineItemColumnFilter.vue'
import ModalUpdateLineItem from '@/components/boq/ModalUpdateLineItem.vue'
import {GetHighLevelProject,GetHighLevelBoq, GetHighLevelBoqLineItemsByBits, AddHleUnassignedToNewBoqCreatorSection,
    DeleteHighLevelBoqLineItem, GetHleNewBoqCreatorSectionsRaw, DeleteHleLineItemsNewBoqCreator, UpdateHighLevelBoqLineItem} from '@/actions/boqActions.js'

import {LinkList,GetDistinctValues, GetDistinctValuesObject, ReformatNumber, ModuleActionList, LineItemRemarks } from '@/helpers/utilities.js'
import 'dotenv/config'
import {mapActions,mapGetters} from 'vuex'
import Loader  from '@/components/layout/Loader.vue'
import BoqLineItemRow from '@/components/boq/BoqLineItemRow.vue'
import BoqLineItemRowThead from '@/components/boq/BoqLineItemRowThead.vue'
import FigureCurrencyNote from '@/components/layout/FigureCurrencyNote.vue'
export default {
    name : 'HleBoqBuilderUnassigneds',
    components : {
        BreadcrumbsProject, Loader,
        ModalLineItemColumnFilter,ModalUpdateLineItem,
        BoqLineItemRow,BoqLineItemRowThead, Modal,FigureCurrencyNote
    },
    mounted : async function(){
        const self = this

        self.ChangeActiveLink(LinkList.HIGH_LEVEL_ESTIMATES)
        const boqItem = await GetHighLevelBoq(self.boqId)
        self.boqItem = boqItem
        
        self.boqUrlname = boqItem.name  + ' - (' + boqItem.currencyId.sign +') '  + boqItem.currencyId.shortName  +' '+ boqItem.currencyId.name
        self.hleProject = await GetHighLevelProject(self.hleProjectId)
        await self.GetData(self.boqListParams)
        const lineItemCols =  window.localStorage.getItem('lineItemColumns')
        if(lineItemCols){
            self.selectedColumns = lineItemCols.split(',').map(Number)
        }
        const rawSections = await GetHleNewBoqCreatorSectionsRaw(boqItem._id)
        self.rawSections = rawSections.sections
        
        self.firstSections = rawSections.sections.filter(u=>u.level==1)
        self.searchableAreas = GetDistinctValues(self.lineItems.map(u=>u.area).filter(value => value !== null && value !== undefined && value !== ''))
        self.searchableAreas.push(self.$i18n.t('NO SPACE ID'))
        const getLineItemCostCode = 
        self.userInfo.language =='en' ? 
            self.lineItems.map(u=>{
                return {
                    _id : u.costCodeId,
                    costName : u.costCode + ' ' +u.name
                }
            }) 
        :
            self.lineItems.map(u=>{
                return {
                    _id : u.costCodeId,
                    costName : u.costCode + ' ' +u.nameCh
                }
            }) 
        self.searchableCostCodes = GetDistinctValuesObject(getLineItemCostCode)

    },
    data(){
        return {
            boqUrlname : '',
            hleProject : {},
            hleProjectId : this.$route.params.id ,
            ModuleActionList,
            selectedLineItems : [],
            lineItems : [],
            LineItemData : [],
            rawSections : [],
            firstSections : [],
            secondSections : [],
            thirdSections : [],
            selectedFirstSection : '',
            selectedSecondSection : '',
            selectedThirdSection : '',
            boqId : this.$route.params.boqId,
            section : {},
            boqItem : {
                status : 1,
                currencyId  : {
                    sign : '',
                }
            },
            projectUrl : process.env.VUE_APP_PROJECT_URL,
            selectedArea : [],
            selectedCostCode : [],
            dataReady : false,
            OpenFilter : false,
            selectedColumns : [],
            boqListParams : {
                page : 1,
                id :  this.$route.params.boqId,
                costCode : '',
                area : '',
                skip : 0,
                take : 50
            },
            updateLineItem : {
                unit : '',
                parents : [
                    {
                        name : ''
                    },
                    {
                        name : ''
                    }
                ]
            },
            isOnProcess : false,
            isRunning : false,
            LineItemRemarks
        }
    },
    methods : {
        ...mapActions(['ChangeActiveLink']),
        ReformatNumber,
        UpdateHighLevelBoqLineItem,
        ChangeSection(level){
            const self = this
            if(level==1){
                const selected = self.selectedFirstSection

                self.secondSections = self.rawSections.filter(u=>u.parentId==selected)
                self.thirdSections = []

                self.selectedSecondSection = ''
                self.selectedThirdSection = ''
            }else if(level==2){
                const selected = self.selectedSecondSection

                self.thirdSections = self.rawSections.filter(u=>u.parentId==selected)

                self.selectedThirdSection = ''
            }
        },
        ClearField(type){
            if(type==1){
                this.selectedArea = []
            }
            else{
                this.selectedCostCode = []
            }
        },
        async GetData(){
            const self = this
            self.lineItems = []
            let willFetch = true
            self.boqListParams.skip = 0
            self.dataReady = false
            while(willFetch){
                const bits = await GetHighLevelBoqLineItemsByBits(self.boqListParams)
                if(bits.length!=0){
                    self.lineItems.push(...bits)
                }
                if(bits.length==self.boqListParams.take){
                    self.boqListParams.skip += 50
                }else{
                    willFetch = false
                }
            }
            self.ReformatRows()
            self.dataReady = true
        },
        ReformatRows(){
            const self = this 
            if(self.lineItems.length==0){
                return []
            }

            self.LineItemData = []
      
            let lineItemSubCount = 1
      
            self.lineItems.map((u)=>{
                const supplyAndInstallCombine = u.supplyAndInstall ? u.supplyAndInstall : (u.costRate || 0) + (u.installRate || 0)
                const  supplyAndInstallTotal = supplyAndInstallCombine * u.quantity


                u.label = lineItemSubCount++
                u.bufferCostTotal = u.costTotal - u.costTotalRaw
                u.bufferSellRate = u.sellRate - u.sellRateRaw
                u.bufferSellTotal = u.sellTotal - u.sellTotalRaw
                u.bufferGp = u.gp - u.gpRaw
                u.supplyAndInstallTotal = supplyAndInstallTotal
             
                self.LineItemData.push(u)
                return u
            })
        },
        ChangeSelectecColumnValue(value){
            this.selectedColumns = value
        },
        OnChecked(e){
            const isChecked = e.target.checked

            this.selectedLineItems  = []
            if(isChecked){
                this.selectedLineItems.push(...this.lineItems.map(u=>u._id))
            }
            
        },
        async AttachToParent(){
            const self  = this
            if(self.isOnProcess){
                return
            }  

            const selected = self.selectedThirdSection ? self.selectedThirdSection : self.selectedSecondSection
            const swalTitle = self.$i18n.t('Attach to Section')
            if(self.selectedLineItems.length==0){
                self.$swal(swalTitle, self.$i18n.t('No line item selected'), 'warning')
            }

            const result = await AddHleUnassignedToNewBoqCreatorSection({
                boqId : self.boqId,
                lineItems : self.selectedLineItems,
                parentId : selected
            })

            if(result!='OK'){
                self.$swal(swalTitle, result, 'warning')
                return
            }
            self.selectedLineItems = []

            self.$swal(swalTitle, self.$i18n.t('Updated Successfully'), 'success')
            self.$refs.closeBoqAttachModal.click()
            await self.GetData()
        },
        async DeleteLineItemsPermanent(){
            const self  = this
            if(self.isOnProcess){
                return
            }  

            const swalTitle = self.$i18n.t('Delete Line Items')

            if(self.selectedLineItems.length==0){
                self.$swal(swalTitle, self.$i18n.t('No line item selected'), 'warning')
            }

            const result = await DeleteHleLineItemsNewBoqCreator({
                boqId : self.boqId,
                lineItems : self.selectedLineItems,
            })

            if(result!='OK'){
                self.$swal(swalTitle, result, 'warning')
                return
            }

            self.$swal(swalTitle, self.$i18n.t('Deleted Successfully'), 'success')
            self.$refs.closeBoqDeleteLineItemsModal.click()
            await self.GetData()
        },
        OpenEditLineItem(data){
            this.updateLineItem = JSON.parse(JSON.stringify(data))
            console.log(data)
            this.updateLineItem.id = data._id
            this.updateLineItem.markUp = this.updateLineItem.markUp * 100
        },
        OpenDelete(id){
            const self = this
            const swalTitle = self.$i18n.t('Delete')
            self.$swal({
                title: swalTitle,
                text : self.$i18n.t('Bill of Quantity Module.Remove Item from Bill of Quantities') + '?',
                showCancelButton: true,
                icon: 'warning',
                showLoaderOnConfirm : true,
                allowOutsideClick: () => !self.$swal.isLoading(),
                preConfirm :async ()=>{
                    return await DeleteHighLevelBoqLineItem(id)
                }
            }).then((res)=>{
                if(res.isConfirmed){
                    if(res.value=='OK'){
                        const getIndex = self.lineItems.findIndex(u=>u._id==id)
                        if(getIndex!=-1){
                            self.lineItems.splice(getIndex,1)
                            self.LineItemData.splice(getIndex,1)
                        }
                        self.$swal(swalTitle, self.$i18n.t('Deleted Successfully'), 'success')
                    }else{
                        self.$swal(swalTitle,res.value,'warning')
                    }
                }
            })
        },
    },
    computed : {
        ...mapGetters(['userInfo']),
        IsQuotationPublished(){
            return false
        }
    }
}
</script>