<template>
    <Modal idModal="ModalUpdateLineItem" :headerTitle="$t('Update Line Item')" modalSize="modal-xl">
        <ul class="nav nav-tabs d-none">
            <li class="nav-item" role="button">
                <a class="nav-link active" aria-current="page" data-bs-toggle="tab" data-bs-target="#details">Line Item Details</a>
            </li>
            <!-- <li class="nav-item" role="button">
                <a class="nav-link" data-bs-toggle="tab" data-bs-target="#additional_fields">Additional Details</a>
            </li>
            <li class="nav-item" role="button">
                <a class="nav-link" data-bs-toggle="tab" data-bs-target="#child_list">Children List</a>
            </li> -->
        </ul>
        <div class="tab-content">
            <div class="tab-pane fade show active" id="details" role="tabpanel" >
                <fieldset class="row mt-2" :disabled="isLoading">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label > {{ $t('Cost Code') }} </label>
                            <input v-if="userInfo.language=='en'" type="text" class="form-control-plaintext" :value="updateLineItem.costCode + ' ' +updateLineItem.name" disabled />
                            <input v-else type="text" class="form-control-plaintext" :value="updateLineItem.costCode + ' ' +updateLineItem.nameCh" disabled />
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label> {{ $t('Sub Category') }}</label>
                            <input v-if="userInfo.language=='en'" type="text" class="form-control-plaintext" :value="updateLineItem.parents[1].name" disabled />
                            <input v-else type="text" class="form-control-plaintext" :value="updateLineItem.parents[1].nameCh" disabled />
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label> {{ $t('Main Category') }}</label>
                            <input v-if="userInfo.language=='en'" type="text" class="form-control-plaintext" disabled :value="updateLineItem.parents[0].name"/>
                            <input v-else type="text" class="form-control-plaintext" disabled :value="updateLineItem.parents[0].nameCh"/>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="required-style"> {{ $t('Description') }}</label>
                            <textarea class="form-control" placeholder="Description" v-model="updateLineItem.description" rows="4"></textarea>
                            <small class="text-warning" v-if="!updateLineItem.description && isUpdateItemClicked">{{ $t('Required') }}</small>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label >{{ $t('Bidding Module.Remarks') }} </label>
                            <select class="form-select" v-model="updateLineItem.remark" >
                                <option value="">{{ $t('Please Select') }}</option>   
                                <option v-for="(item, key) in remarkList" :key="key" :value="item.name">{{item.nameTranslated}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-6" v-if="!feeMode">
                        <div class="form-group">
                            <label class="required-style">  {{ $t('Unit') }}</label>
                            <select class="form-select" v-model="updateLineItem.unit" >
                                <option value="">{{ $t('Please Select') }}</option>
                                <option v-for="(item, key) in measurementUnits" :key="key" :value="item.unitName">{{item.unitName}}</option>
                            </select>
                            <small class="text-warning" v-if="!updateLineItem.unit && isUpdateItemClicked">{{ $t('Required') }}</small>
                        </div>
                    </div>
                    <div class="col-md-6" v-if="!feeMode">
                        <div class="form-group">
                            <label>{{ $t('Space ID') }}</label>
                            <input type="text" class="form-control"  :placeholder="$t('Space ID')"  v-model="updateLineItem.area"/>
                        </div>
                    </div>
                    <div class="col-md-12" v-if="!feeMode">
                        <div class="d-flex justify-content-end pt-3 pb-1">
                            <span data-bs-toggle="tooltip" data-bs-placement="top"  :title="$t('Tick if line item will not have quantity')" >
                                <input type="checkbox" style=" transform: scale(1.5)" id="new-line-is-rate-advance-update" v-model="updateLineItem.rateOnly" @change="OnRateOnlyChanged" /> 
                                <label for="new-line-is-rate-advance-update" class="fw-bold ms-2" role="button">  {{ $t('Rate Only') }}</label>
                                <small class="text-primary  me-3 ms-1"><i class="fa-solid fa-circle-question"></i> </small>
                            </span>
                        </div>
                        <div class="table-responsive" >
                    
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th style="width:120px"></th>
                                        <th class="text-center" colspan="2">{{ $t('Raw') }}</th>
                                        <th class="text-center" colspan="2">{{ $t('Buffer') }}</th>
                                        <th style="width:180px" class="text-center"> {{$t('Adjusted')}}</th>
                                    </tr>
                                </thead>
                                <tbody class="table-group-divider">
                                    <tr>
                                        <td>{{ $t('Quantity') }}</td>
                                        <td colspan="2">
                                            <input type="number" class="form-control text-end"   min="0"   :class="{'border-warning' : updateLineItem.quantity < 0}"
                                            :disabled="updateLineItem.rateOnly"  v-model="updateLineItem.quantity"/>
                                            <small class="text-warning" v-if="!updateLineItem.quantity && isUpdateItemClicked &&!updateLineItem.rateOnly">{{ $t('Required') }}</small>
                                        </td>
                                        <td colspan="2"><input type="number" class="form-control text-end"   min="0" :class="{'border-warning' : updateLineItem.bufferQuantity < 0}" 
                                        v-model="updateLineItem.bufferQuantity" :disabled="updateLineItem.rateOnly"  /></td>
                                        <td class="text-end bg-light" v-html="UpdateLineItemCompute.quantityTotal"></td>
                                    </tr>
                                    <tr>

                                        <td class="align-middle">{{ $t('Cost Rate') }}</td>
                                        <td  style="width:330px" colspan="2">
                                            <div class="d-flex justify-content-end">
                                                <small class="fw-bold" role="button" @click="SplitOrCombineRate"><i class="fa-solid fa-screwdriver-wrench"></i> {{SupplyAndInstallDisplay}}</small>
                                            </div>
                                            <div class="d-flex" v-if="!supplyAndInstall">
                                                <div>
                                                    <small >{{ $t('Supply Rate') }}</small>
                                                    <input type="number"  min="0" class="form-control text-end" :disabled="disableRates" 
                                                    :class="{'border-warning' : (!updateLineItem.costRate || updateLineItem.costRate < 0) && updateLineItem.installRate &&updateLineItem.bufferCostRate && isUpdateItemClicked && !this.IsDiscount}"  
                                                    v-model="updateLineItem.costRate"/>
                                                </div>
                                                <div class="ps-1">
                                                    <small> {{ $t('Install Rate') }}</small>
                                                    <input type="number" :disabled="disableRates"  min="0" class="form-control text-end" :class="{'border-warning' : updateLineItem.installRate < 0 && !this.IsDiscount}"   v-model="updateLineItem.installRate"/>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <small>{{ $t('Supply And Install Rate') }}</small>
                                                <input type="number"  min="0" :disabled="disableRates"  class="form-control text-end" :class="{'border-warning' : updateLineItem.supplyAndInstallRate < 0 && !this.IsDiscount}"   v-model="updateLineItem.supplyAndInstallRate" />
                                            </div>
                                        </td>
                                        <td style="width:330px" colspan="2">
                                            <div class="d-flex justify-content-end">
                                                <small class="fw-bold text-success" role="button" @click="SplitOrCombineBuffer"><i class="fa-solid fa-screwdriver-wrench"></i> {{SupplyAndInstallBufferDisplay}}</small>
                                            </div>
                                            <div class="d-flex" v-if="!supplyAndInstallBuffer">
                                                <div>
                                                    <small class="text-success" >{{ $t('Supply Buffer') }}</small>
                                                    <input type="number" :disabled="disableRates"  class="form-control text-end" :class="{'border-warning' : updateLineItem.bufferSupply < 0 && !this.IsDiscount}" min="0"   v-model="updateLineItem.bufferSupply"/>
                                                </div>
                                                <div class="ms-1">
                                                    <small  class="text-success" >{{ $t('Install Buffer') }}</small>
                                                    <input type="number" :disabled="disableRates"  class="form-control text-end" :class="{'border-warning' : updateLineItem.bufferInstall < 0 && !this.IsDiscount}" min="0"   v-model="updateLineItem.bufferInstall"/>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <small class="text-success">{{ $t('Supply And Install Buffer') }}</small>
                                                <input type="number" :disabled="disableRates"   min="0" class="form-control text-end" :class="{'border-warning' : updateLineItem.supplyAndInstallBuffer < 0 && !this.IsDiscount}"   v-model="updateLineItem.supplyAndInstallBuffer"/>
                                            </div>
                                        </td>
                                        <td class="align-bottom text-end bg-light" v-html="UpdateLineItemCompute.costRateTotal"></td>
                                    </tr>
                                    <tr>
                                        <td>{{$t('Cost Total')}}</td>
                                        <td class="text-end bg-light td-value-line-item" v-if="!supplyAndInstall" v-html="UpdateLineItemCompute.supplyTotal" ></td>
                                        <td class="text-end bg-light td-value-line-item" v-if="!supplyAndInstall" v-html="UpdateLineItemCompute.installTotal"></td>
                                        <td class="text-end bg-light td-value-line-item"  colspan="2" v-if="supplyAndInstall" v-html="UpdateLineItemCompute.supplyAndInstallTotal"></td>
                                        <td class="text-end bg-light" colspan="2" v-html="UpdateLineItemCompute.bufferEffectCostTotal"></td>
                                        <td class="text-end bg-light" v-html="UpdateLineItemCompute.costTotal"></td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t('Mark Up') }}</td>
                                        <td class="text-end bg-light" colspan="2">-</td>
                                        <td class="text-end bg-light" colspan="2">-</td>
                                        <td>
                                            <div class="input-group ">
                                                <input type="number" class="form-control text-end" :class="{'border-warning' : updateLineItem.markUp < 0}" 
                                                v-model="updateLineItem.markUp" max="100" min="0" :disabled="disableRates"   />
                                                <span class="input-group-text">%</span>
                                            </div>
                                        </td>
                                    </tr>
                                    
                                    <tr>
                                        <td>{{ $t('Sell Rate') }}</td>
                                        <td class="text-end bg-light" colspan="2" v-html="UpdateLineItemCompute.sellRateRaw"></td>
                                        <td class="text-end bg-light"  colspan="2" v-html="UpdateLineItemCompute.bufferEffectRate"></td>
                                        <td class="text-end bg-light" v-html="UpdateLineItemCompute.sellRate"></td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t('Sell Total') }}</td>
                                        <td class="text-end bg-light" colspan="2" v-html="UpdateLineItemCompute.sellTotalRaw"></td>
                                        <td class="text-end bg-light"  colspan="2" v-html="UpdateLineItemCompute.bufferEffectSellTotal"></td>
                                        <td class="text-end bg-light" v-html="UpdateLineItemCompute.sellTotal"></td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t('Gross Profit') }}</td>
                                        <td class="text-end bg-light" colspan="2" v-html="UpdateLineItemCompute.gpRaw"></td>
                                        <td class="text-end bg-light"  colspan="2" v-html="UpdateLineItemCompute.bufferEffectGp"></td>
                                        <td class="text-end bg-light" v-html="UpdateLineItemCompute.gp"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="col-md-6" v-if="feeMode" >
                        <div class="form-group">
                            <label>{{$t('Type')}}</label>
                            <input class="form-control-plaintext" v-model="updateLineItem.type" disabled />
                        </div>
                    </div>
                    <div class="col-md-6" v-if="feeMode">
                        <div class="form-group">
                            <label>{{ $t('Quantity') }}</label>
                            <input type="number" class="form-control" :class="{'border-warning' : updateLineItem.quantity < 0}"
                                min="0" v-model="updateLineItem.quantity" :disabled="updateLineItem.rateOnly"/>
                            <small class="text-warning" v-if="!updateLineItem.quantity && isUpdateItemClicked && !updateLineItem.rateOnly">{{ $t('Required') }}</small>
                        </div>
                    </div>
                    <div class="col-md-6" v-if="feeMode">
                        <div class="form-group">
                            <label class="required-style">{{ $t('Cost') }} </label>
                            <div class="input-group">
                                <input type="number" v-model="updateLineItem.amountFee" :disabled="disableRates"   class="form-control" />
                                <span class="input-group-text fw-bold" v-if="updateLineItem.typeId==CostCodeTypes.INTERNAL_FEE_PERCENTAGE" >% </span>
                            </div>
                            <small class="text-warning" v-if="((updateLineItem.amountFee < 0 && !this.IsDiscount)) && isUpdateItemClicked">Required</small>
                        
                        </div>
                    </div>
                    <div class="col-md-6" v-if="feeMode">
                        <div class="form-group">
                            <label>{{ $t('Mark Up') }}</label>
                            <div class="input-group ">
                                <input type="number" class="form-control"  v-model="updateLineItem.markUp" max="100" min="0"/>
                                <span class="input-group-text">%</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6" v-if="feeMode">
                        <div class="form-group">
                            <label class="required-style">{{ $t('Apply to') }} </label>
                            <select class="form-select" :disabled="!isInternal" v-model="updateLineItem.applyTo">
                                <option value="1">{{$t('Apply To All')}}</option>
                                <option value="2">{{ $t('Apply To Specific Section') }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-9" v-if="feeMode && isInternal && updateLineItem.applyTo==ApplyToTypes.SPECIFIC">
                        <div class="form-group" >
                            <label >{{ $t('Sections') }} </label>
                            <select class="form-select" v-model="selectedFirstSection" >
                                <option value="" >{{ $t('Please Select') }}</option>
                                <option :value="item.id" v-for="(item,key) in optionsSection" class="text-capitalize" :key="key"> {{ item.name }}</option>
                            </select>
                        </div>
                    </div>
                    <!-- <div class="col-md-3" v-if="feeMode && isInternal && updateLineItem.applyTo==ApplyToTypes.SPECIFIC">
                        <div class="form-group" >
                            <label >Main Section </label>
                            <select class="form-select" v-model="selectedFirstSection" @change="ChangeSection(1)" >
                                <option value="" >Please Select</option>
                                <option :value="item._id" v-for="(item,key) in firstSections" class="text-capitalize" :key="key"> {{ item.name }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3"  v-if="feeMode && isInternal && updateLineItem.applyTo==ApplyToTypes.SPECIFIC" >
                        <div class="form-group" v-if="secondSections.length != 0">
                            <label >Sub Section </label>
                            <div class="input-group">
                                <select class="form-select" v-model="selectedSecondSection" @change="ChangeSection(2)" >
                                    <option value="" >Please Select</option>
                                    <option :value="item._id" v-for="(item,key) in secondSections" class="text-capitalize" :key="key"> {{ item.name }}</option>
                                </select>
                                <span class="input-group-text text-danger bg-white border-0 pe-0 " @click="UnSelectSection(2)" role="button" > <i class="fas fa-times"></i></span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3" v-if="feeMode&& isInternal&& updateLineItem.applyTo==ApplyToTypes.SPECIFIC">
                        <div class="form-group" v-if="thirdSections.length != 0">
                            <label >Sub Section </label>
                            <div class="input-group">
                                <select class="form-select" v-model="selectedThirdSection" >
                                    <option value="" >Please Select</option>
                                    <option :value="item._id" v-for="(item,key) in thirdSections" class="text-capitalize" :key="key"> {{ item.name }}</option>
                                </select>
                                <span class="input-group-text text-danger bg-white border-0 pe-0 " @click="UnSelectSection(3)" role="button" > <i class="fas fa-times"></i></span>
                            </div>
                        </div>
                    </div> -->
                    <div class="col-md-3" v-if="feeMode && isInternal && updateLineItem.applyTo==ApplyToTypes.SPECIFIC">
                        <button class="btn btn-primary w-100 mt-4" @click="AddSection"><i class="fa-solid fa-plus"></i> Add Section</button>
                    </div>
                    <div class="col-md-12 mt-2" v-if="feeMode && isInternal && updateLineItem.applyTo==ApplyToTypes.SPECIFIC">
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th class="col-9">{{$t('Section')}}</th>
                                        <th class="col-3">{{$t('Actions')}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item,key) in specificSections" :key="key">
                                        <td class="col-9"><span class="text-capitalize" v-html="item.name"></span></td>
                                        <td class="col-3">
                                            <span class="text-danger fw-bold" role="button" @click="RemoveSection(item.id)"><i class="fa-solid fa-times"></i> {{$t('Remove')}}</span>
                                        </td>
                                    </tr>
                                    <tr v-if="specificSections.length==0">
                                        <td class="text-center" colspan="2">
                                            {{ $t('No data Found') }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    
                    <!-- <hr class="mt-2" />
                    <div class="col-md-12">
                            <div class="form-group">
                                <label >Bill of Quantity </label>
                                <Select2
                                :filterQuery="GetBoqsProjects"
                                indexValue="_id"
                                labelName="name"
                                placeHolder="Search Boq here"
                                :dataOptions="boqList"
                                v-model:optionValue="updateLineItem.boqId"
                                ref="selectBoqList"
                                >
                                </Select2>
                                <input type="text" class="form-control" disabled :value="boqNameDisplay" />
                                <small class="text-warning" v-if="!updateLineItem.boqId && isUpdateItemClicked">Required</small>
                            </div>
                    </div> -->

                    <div class="col-md-12">
                            <div class="pt-2">
                            <div class="input-group d-flex justify-content-end">
                                <button type="button" class="btn btn-primary" ref="btnItemUpdateAndClose" @click="UpdateLineItem(1)"><i class="fa-solid fa-save"></i> {{$t('Update and Close')}}</button>
                                <div class="dropdown">
                                    <button type="button" ref="btnItemUpdate" class="btn btn-primary pe-1 ps-1 rounded-0 rounded-end"  data-bs-toggle="dropdown" ><i class="fa-solid fa-angle-down"></i></button>
                                    <ul class="dropdown-menu text-small shadow">
                                        <li @click="UpdateLineItem(0)"><a class="dropdown-item">{{$t('Update')}}</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
            <div class="tab-pane fade " id="additional_fields" role="tabpanel" >
                <div>
                    <div class="alert alert-outline-primary d-flex align-items-center mt-2" role="alert">
                        <i class="fas fa-info-circle fa-2x me-2"></i>  
                        <div>
                            No Additional Details
                        </div>
                    </div>
                </div>
                <div class="row mt-2" v-if="updateLineItem.length!=0">
                    <div class="col-md-6" v-for="(item,key) in updateLineItem.additionalFields" :key="key" >
                        <div class="form-group">
                            <label class="text-capitalize" >{{item.name}}</label>
                            <input type="text" class="form-control-plaintext" disabled :value="item.value" />
                        </div>
                    </div>
                </div>
            </div> 
            <div class="tab-pane fade " id="child_list" role="tabpanel" >
                <div class="mt-2">
                    <Loader v-if="childLoading"/>
                    <div class="table-responsive" v-else>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Description</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-center" colspan="2" v-if="childs.length==0">No Data Found</td>
                                </tr>
                                <tr v-for="(item,key) in childs" :key="key">
                                    <td>
                                        <div >
                                            <span class="fw-bold text-capitalize small"> {{item.costCode}} {{item.name}} </span> <br />
                                            <span class="small">
                                                {{item.description}}
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <button class="btn btn-outline-warning btn-action me-1" @click="RemoveChild(item._id)" > 
                                            <i class="fa-solid fa-trash-can"></i> 
                                        </button> 
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
       
        <template v-slot:actions>
            <button type="button" ref="closeAddModalItemBoq" class="btn btn-outline-danger"  data-bs-dismiss="modal">X {{$t('Cancel')}}</button>
            <div>
                
            </div>
        </template>
    </Modal>
</template>

<style scoped>
    .td-value-line-item{
        width: 201.5px;
        max-width: 201.5px;
        white-space: initial;
    }
</style>

<script>
import Modal from '@/components/layout/Modal.vue'
import Loader from '@/components/layout/Loader.vue'
import { ReformatNumber,ComputeLineItem ,CostCodeTypeNames,CostCodeTypes,ApplyToTypes} from '@/helpers/utilities.js'
import { UpdateConsolidatedLineItem, SearchBoqFromProject, SearchLineItemChilds,RemoveLineItemChild,GetProjectFeeLineItem,GetNewBoqCreatorSectionsRaw,UpdateProjectFeeLineItem,
    UpdateHighLevelBoqLineItemFee, GetHighLevelBoqLineItemFee ,GetHleNewBoqCreatorSectionsRaw ,GetBoqItemRemarks} from '@/actions/boqActions.js'
import {SearchUomDirectory } from '@/actions/projectActions.js'
import {mapGetters} from 'vuex'
export default {
    name : 'ModalUpdateLineItem',
    components : {
        Modal,Loader
    },
    props : ['ResetData','boqList','selectedLineItem', 'RemoveParentData','UpdateLineItemFunc','boqId','isHighLevelEstimate'],
    async mounted() {
        const self = this
        self.measurementUnits = await SearchUomDirectory()
        const rawSections = self.isHighLevelEstimate==true ? await GetHleNewBoqCreatorSectionsRaw(self.boqId) : await GetNewBoqCreatorSectionsRaw(self.boqId)
        self.rawSections = rawSections.sections
        self.firstSections = rawSections.sections.filter(u=>u.level==1)
        self.remarkList = await GetBoqItemRemarks()

        self.optionsSection = []

        function AddToSections(parentSection,preText){
        let objSection = {
            id : parentSection._id,
            name : preText + '/' + parentSection.name 
        }
        const getChilds = rawSections.sections.filter(u=>u.parentId && u.parentId==parentSection._id)

        if(parentSection.level==1){
            objSection.name = objSection.name.slice(1)
        }

        self.optionsSection.push(objSection)
        if(getChilds.length != 0)
        {
            for(let c = 0; c < getChilds.length; c++)
            {
                AddToSections(getChilds[c],objSection.name)
            }
        }
        }

        for(let c = 0; c < self.firstSections.length; c++)
        {
            AddToSections(self.firstSections[c],'')
        }
    },
    methods :{
        ReformatNumber : ReformatNumber,
         OnRateOnlyChanged (e){
            const checked = e.target.checked
            if(checked){
                this.updateLineItem.quantity = 0
                this.updateLineItem.bufferQuantity = 0
            }
        },
        async UpdateLineItem(close){
            const self = this
            const data = self.updateLineItem
            self.isUpdateItemClicked = true
            const swalTilte = self.$i18n.t('Update Line Item')
            if(self.feeItem)
            {
                data.boqId = self.boqId
                data.sectionList = self.specificSections.map(u=>u.id)
                if(!data.description  || !data.applyTo){
                    return
                }

                if(!self.IsDiscount && data.amountFee < 0){
                    return
                }

                data.sectionId = self.parentId

                let updateFunc = UpdateProjectFeeLineItem
                if(self.isHighLevelEstimate==true){
                    updateFunc = UpdateHighLevelBoqLineItemFee
                }
                
                await updateFunc(data).then((res)=>{
                    self.$refs.btnItemUpdate.disabled = false
                    self.$refs.btnItemUpdateAndClose.disabled = false
                    if(res=="OK"){
                        self.ResetData()
                        if(close==1){
                            self.$refs.closeAddModalItemBoq.click()
                        }
                    }else{
                        self.$swal(swalTilte,res,'warning')
                    }
                })

                return

            }

            if(!data.description || (!data.quantity && !data.rateOnly) || !data.unit || !data.boqId){
                return
            }

            if(data.bufferQuantity < 0 || data.quantity < 0 || 
            data.costRate < 0|| data.installRate < 0|| data.supplyAndInstallRate < 0 || 
            data.bufferCostRate < 0|| data.bufferInstall < 0|| data.supplyAndInstallBuffer < 0)
            {
                self.$swal(swalTilte,self.$i18n.t('Values cannot be less than 0'),'warning')
                return
            }
            // if(data.typeId!=CostCodeTypes.DISCOUNT){
            //     if(data.costRate < 0|| data.installRate < 0|| data.supplyAndInstallRate < 0 || data.bufferCostRate < 0|| data.bufferInstall < 0|| data.supplyAndInstallBuffer < 0){
            //         self.$swal('Update Line Item','Values cannot be less than 0','warning')
            //         return
            //     }
            // }

            if((data.bufferCostRate || data.bufferInstall ||data.supplyAndInstallBuffer) && (!data.costRate && !data.installRate && !data.supplyAndInstallRate)){
                self.$swal(swalTilte,self.$i18n.t('Bill of Quantity Module.Supply/Install rates cannot be empty when there is buffer rate'),'warning')
                return
            }

            // if(data.bufferCostRate&&(!data.costRate && !data.installRate)){
            //     self.$swal('Update Line Item','Supply and Install rates cannot be empty when there is buffer rate','warning')
            //     return 
            // }

            self.isUpdateItemClicked = false

            self.$refs.btnItemUpdate.disabled = true
            self.$refs.btnItemUpdateAndClose.disabled = true

            let updateFunc = UpdateConsolidatedLineItem
            if(self.UpdateLineItemFunc){
                updateFunc = self.UpdateLineItemFunc
            }

            updateFunc(data).then((res)=>{
                self.$refs.btnItemUpdate.disabled = false
                self.$refs.btnItemUpdateAndClose.disabled = false
                if(res=="OK"){
                    self.ResetData()
                    if(close==1){
                        self.$refs.closeAddModalItemBoq.click()
                    }
                }else{
                    self.$swal(swalTilte,res,'warning')
                }
            })
        },
        GetBoqsProjects(search){
            return SearchBoqFromProject({search : search, id : this.projectInfo._id})
        },
        GetLineItemsBoq(search){
            return SearchLineItemChilds({search : search, id : this.updateLineItem.boqId})
        },
        RemoveChild(id){
            const self = this

            self.$swal({
                title: 'Remove Child',
                text : 'Remove Line Item Child',
                showCancelButton: true,
                icon: 'warning',
                confirmButtonText: 'Remove',
                showLoaderOnConfirm : true,
                allowOutsideClick: () => !self.$swal.isLoading(),
                preConfirm : ()=>{
                    return RemoveLineItemChild(id).then((res)=>{
                        if(res!='OK'){
                            self.$swal('Remove Child',res,'warning')
                        }else{
                            const getIndex = self.childs.findIndex(u=>u._id==id)
                            if(getIndex!=-1){
                                self.childs.splice(getIndex,1)
                                self.RemoveParentData(id)
                            }
                        }
                    }).catch()
                }
            }).then((res)=>{
                if(res.isConfirmed){
                    self.$swal('Item Removed', '', 'success')
                }
            })
        },
        SplitOrCombineRate(){
            this.updateLineItem.supplyAndInstallRate = 0
            this.updateLineItem.costRate = 0
            this.updateLineItem.installRate = 0
            this.supplyAndInstall = !this.supplyAndInstall
        },
        SplitOrCombineBuffer(){
            this.updateLineItem.supplyAndInstallBuffer = 0
            this.updateLineItem.bufferSupply = 0
            this.updateLineItem.bufferInstall = 0
            this.supplyAndInstallBuffer = !this.supplyAndInstallBuffer
        },
        
        ChangeSection(level){
            const self = this
            if(level==1){
                const selected = self.selectedFirstSection

                self.secondSections = self.rawSections.filter(u=>u.parentId==selected)
                self.thirdSections = []

                self.selectedSecondSection = ''
                self.selectedThirdSection = ''
            }else if(level==2){
                const selected = self.selectedSecondSection
                self.thirdSections = self.rawSections.filter(u=>u.parentId==selected)
                self.selectedThirdSection = ''
            }
        },
        UnSelectSection(level){
            const self = this
            if(level==2){
                self.secondSections = []
                self.selectedSecondSection  = ''

                
                self.thirdSections = []
                self.selectedThirdSection  = ''
            } 
            else if(level==3){
                self.thirdSections = []
                self.selectedThirdSection  = ''
            }
        },
        GetParents(section, sectionSelected){
            const self = this
            const getParent =  self.rawSections.find(u=>u._id ==section.parentId)
            if(!getParent) { return }
            sectionSelected.name = getParent.name + ' / ' + sectionSelected.name
            sectionSelected.parents.push(getParent._id)
            self.GetParents(getParent,sectionSelected)
        },
        AddSection(){
            const self = this
            let selected = ''
            if(self.selectedThirdSection)
            {
                selected = self.selectedThirdSection
            }
            else if(self.selectedSecondSection){
                selected = self.selectedSecondSection
            }
            else {
                selected = self.selectedFirstSection
            }

            const swalTilte = self.$i18n.t('Update Line Item')

            if(!selected){
                self.$swal(swalTilte,self.$i18n.t('Bill of Quantity Module.Please Select A Section'),'warning')
                return
            }

            const getSection = self.rawSections.find(u=>u._id ==selected)
            if(!getSection){ return }


            let sectionSelected = {
                id : selected,
                name : `<b>${getSection.name}</b>`,
                parents : []
            }
            

            self.GetParents(getSection,sectionSelected)

            //if its not the final sub section or level 3
            //check if this section already have a sub section added 
            const getIndexParent = self.specificSections.findIndex(u=>u.parents.indexOf(getSection._id) > -1)
            if(getIndexParent > -1) 
            {
                self.$swal(swalTilte,self.$i18n.t('Bill of Quantity Module.Sub Section of this section is already added, please remove it first'),'warning')
                return
            }

            if(sectionSelected.parents[1]){
                const getIndexParent = self.specificSections.findIndex(u=>u.id==sectionSelected.parents[1])
                if(getIndexParent > - 1){
                    self.$swal(swalTilte,self.$i18n.t('Bill of Quantity Module.Parent Section Already Added'),'warning')
                    return
                }
            }

            if(sectionSelected.parents[0]){
                const getIndexParent = self.specificSections.findIndex(u=>u.id==sectionSelected.parents[0])
                if(getIndexParent > - 1){
                    self.$swal(swalTilte,self.$i18n.t('Bill of Quantity Module.Main Parent Section Already Added'),'warning')
                    return
                }
            }

            const getIndex = self.specificSections.findIndex(u=>u.id==selected)
            if(getIndex > - 1){
                self.$swal(swalTilte,self.$i18n.t('Bill of Quantity Module.Section Already Added'),'warning')
                return
            }

            self.specificSections.push(sectionSelected)
            self.ClearSectionSelector(false)

        },
        ClearSectionSelector(isAll = false){
            const self = this
            self.secondSections = []
            self.thirdSections = []
            self.selectedFirstSection = ''
            self.selectedSecondSection = ''
            self.selectedThirdSection = ''
            if(isAll){
                self.specificSections = []
            }
        },
        RemoveSection(id){
            const self = this
            const getIndex = self.specificSections.findIndex(u=>u.id==id)
            if(getIndex > - 1){
                self.specificSections.splice(getIndex,1)
            }

        },
        UpdateDiscountRates(){
            const lineItem = this.updateLineItem
            if(this.IsDiscount){
                lineItem.amountFee =  Math.abs(lineItem.amountFee) * -1
                // lineItem.supplyAndInstallRate =  Math.abs(lineItem.supplyAndInstallRate) * -1
                // lineItem.costRate =  Math.abs(lineItem.costRate) * -1
                // lineItem.installRate =  Math.abs(lineItem.installRate) * -1
                // lineItem.bufferCostRate =  Math.abs(lineItem.bufferCostRate) * -1
                // lineItem.bufferInstall =  Math.abs(lineItem.bufferInstall) * -1
                // lineItem.supplyAndInstallBuffer =  Math.abs(lineItem.supplyAndInstallBuffer) * -1
            }else{
                lineItem.amountFee =  Math.abs(lineItem.amountFee)
                // lineItem.costRate =  Math.abs(lineItem.costRate)
                // lineItem.installRate =  Math.abs(lineItem.installRate) 
                // lineItem.bufferSupply =  Math.abs(lineItem.bufferSupply) 
                // lineItem.bufferInstall =  Math.abs(lineItem.bufferInstall)
                // lineItem.supplyAndInstallBuffer =  Math.abs(lineItem.supplyAndInstallBuffer) 
            }
        },
        UpdateValueBasedOnTypeId(string){
            
            const lineItem = this.updateLineItem
            const objValue = lineItem[string]
            if(this.IsDiscount && objValue > 0){
                lineItem[string] = Math.abs(objValue) * -1
            }
            else if(!this.IsDiscount && objValue < 0){
                lineItem[string] = Math.abs(objValue) 
            }
        }
    },
    watch :{
        'updateLineItem.applyTo': function () {
            this.ClearSectionSelector()
        },
        'updateLineItem.typeId' : function() {
            this.UpdateDiscountRates()
        },
        'updateLineItem.amountFee' : function() {
            this.UpdateValueBasedOnTypeId('amountFee')
        },
        'updateLineItem.remark' : function(val) {

            if(val){
                this.updateLineItem.amountFee = 0
                this.updateLineItem.markUp = 0
                this.updateLineItem.costRate = 0
                this.updateLineItem.installRate = 0
                this.updateLineItem.supplyAndInstallRate = 0
                this.updateLineItem.bufferSupply = 0
                this.updateLineItem.bufferInstall = 0
                this.updateLineItem.supplyAndInstallBuffer = 0

                this.disableRates = true
            }
            else
            {
                this.disableRates = false
            }
        },
        // 'updateLineItem.supplyAndInstallRate' : function() {
        //     this.UpdateValueBasedOnTypeId('supplyAndInstallRate')
        // },
        // 'updateLineItem.costRate' : function() {
        //     this.UpdateValueBasedOnTypeId('costRate')
        // },
        // 'updateLineItem.installRate' : function() {
        //     this.UpdateValueBasedOnTypeId('installRate')
        // },
        // 'updateLineItem.bufferSupply' : function() {
        //     this.UpdateValueBasedOnTypeId('bufferSupply')
        // },
        // 'updateLineItem.bufferInstall' : function() {
        //     this.UpdateValueBasedOnTypeId('bufferInstall')
        // },
        // 'updateLineItem.supplyAndInstallBuffer' : function() {
        //     this.UpdateValueBasedOnTypeId('supplyAndInstallBuffer')
        // },
        async selectedLineItem(val){
            const self = this
            self.updateLineItem = val
            self.updateLineItem.bufferQuantity  = val.bufferQuantity  || 0
            self.updateLineItem.markUp  = val.markUp  || 0
            self.updateLineItem.typeId =  1
            self.updateLineItem.remark = self.updateLineItem.remark || ''
            self.isLoading = true
            if(self.isHighLevelEstimate==true){
                self.feeItem = await GetHighLevelBoqLineItemFee(self.updateLineItem._id)
            }
            else{
                self.feeItem = await GetProjectFeeLineItem(self.updateLineItem._id)
            }

            self.feeMode = self.feeItem != null
            if(self.feeMode){
                self.updateLineItem.applyTo = self.feeItem.applyTo
                self.updateLineItem.amountFee = self.feeItem.amount
                self.updateLineItem.typeId = self.feeItem.lineItemId.costCodeId.type
                self.updateLineItem.type = CostCodeTypeNames[self.feeItem.lineItemId.costCodeId.type - 1]
                self.isInternal = self.feeItem.lineItemId.costCodeId.type==CostCodeTypes.INTERNAL_FEE_PERCENTAGE
                if(self.isInternal)
                {
                    self.updateLineItem.amountFee = self.feeItem.amount * 100
                }
                self.specificSections= []
                for(let x = 0; x < self.feeItem.specifiedSections.length; x++)
                {
                    const getData= self.feeItem.specifiedSections[x]
                    const getSection = self.rawSections.find(u=>u._id ==getData)
                    if(!getSection){ return }


                    let sectionSelected = {
                        id : getData,
                        name : `<b>${getSection.name}</b>`,
                        parents : []
                    }
                    //recursion function for getting the parents of the section
                    self.GetParents(getSection,sectionSelected)
                    self.specificSections.push(sectionSelected)

                }

                self.isLoading = false
                return
            }

            if(val.supplyAndInstall){
                self.supplyAndInstall = true
                self.updateLineItem.supplyAndInstallRate  = val.supplyAndInstall  || 0
            }else{
                self.supplyAndInstall = false
                self.updateLineItem.costRate  = val.costRate  || 0
                self.updateLineItem.installRate  = val.installRate  || 0
            }
            if(val.bufferCostRate){
                self.supplyAndInstallBuffer = true
                self.updateLineItem.supplyAndInstallBuffer  = val.bufferCostRate  || 0
            }else{  
                self.supplyAndInstallBuffer = false
                self.updateLineItem.bufferSupply  = val.supplyBuffer  || 0
                self.updateLineItem.bufferInstall  = val.installBuffer  || 0
            }

            if(self.updateLineItem.rateOnly==undefined){
                self.updateLineItem.rateOnly = false
            }

            let getBoqName = self.boqList.find(u=>u._id==val.boqId)
            if(getBoqName){
                self.boqNameDisplay = getBoqName
            }

            self.childs = []
            // if(self.updateLineItem.lineItemChilds){
            //     if(self.updateLineItem.lineItemChilds.length!=0){
            //         self.childLoading = true
            //         GetLineItemChilds(self.updateLineItem._id).then(res=>{
            //             self.childLoading = false
            //             self.childs = res
            //         })
            //     }
            // }
            self.isLoading = false
            
        },

        
    },
    computed :{
        ...mapGetters(['projectInfo','userInfo']),
        IsDiscount(){
            return this.updateLineItem.typeId == CostCodeTypes.DISCOUNT ||  this.updateLineItem.typeId == CostCodeTypes.EXTERNAL_DISCOUNT
        },
        UpdateLineItemCompute(){
            const self =this
            const u = self.updateLineItem

            return ComputeLineItem(u.quantity || 0, u.bufferQuantity|| 0,
            u.costRate|| 0, u.installRate|| 0, u.supplyAndInstallRate|| 0,
            u.bufferSupply|| 0, u.bufferInstall|| 0, u.supplyAndInstallBuffer|| 0,
            u.markUp|| 0)
        },
        SupplyAndInstallDisplay(){
            const self = this
            if(self.supplyAndInstall)
                return self.$i18n.t('Click to Split Supply and Install')
            else
                return self.$i18n.t('Click to Combine Supply and Install')
        },
        SupplyAndInstallBufferDisplay(){
            const self = this
            if(self.supplyAndInstallBuffer)
                return self.$i18n.t('Click to Split Supply and Install')
            else
                return self.$i18n.t('Click to Combine Supply and Install')
        }
    },
    data (){ 
        return {
            CostCodeTypes,
            ApplyToTypes,
            isCreating : false,
            isLoading : false,
            updateLineItem :  {
                unit : '',
                parents : [
                    {
                        name : ''
                    },
                    {
                        name : ''
                    }
                ],
                lineItemChilds : []
            },
            childs :[],
            childLoading : false,
            isUpdateItemClicked : false,
            supplyAndInstall : true,
            supplyAndInstallBuffer : true,
            measurementUnits : [{unitName : ''}],
            boqNameDisplay : '',
            feeMode : false,
            firstSections : [],
            secondSections : [],
            thirdSections : [],
            selectedFirstSection : '',
            selectedSecondSection : '',
            selectedThirdSection : '',
            isInternal : false,
            specificSections : [],
            feeItem : {},
            optionsSection : [],
            remarkList : [],
            disableRates: false
        }
    }
}
</script>