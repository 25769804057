<template>
    <div>
        <BreadcrumbsProject
        :breadcrumbs="[
            {
                link : projectUrl +'/',
                title : 'Projects',
                isRouterLink: false
            },
            {
                link : projectUrl +'/Project/' + projectInfo._id,
                title :  (projectInfo.projectNumber || '-') +' ' + (projectInfo.projectName || ''),
                isRouterLink: false
            },
            {
                link : '/boq/' + projectInfo._id + '/rates',
                title : 'Rate List',
                isRouterLink: true
            }
        ]"
        ></BreadcrumbsProject>
        <StepItem2></StepItem2>
        <div>
            <div class="card border-top">
                <div class="card-header bg-white">
                    <div class="d-flex justify-content-between">
                        <h6 class="fw-bold">Rate List</h6>
                        <div>
                            <button class="btn btn-primary me-1  pe-2 ps-2"
                            v-if="permission.indexOf(ModuleActionList.RATE_CREATE)>-1"
                            data-bs-toggle="modal" data-bs-target="#BoqAddRateTemplate"
                            @click="()=>{newTemplate.name = ''}"
                            title="Add Template"><i class="fa-solid fa-plus"></i> Add Template  </button>
                            <button class="btn btn-outline-primary me-1  pe-2 ps-2"
                            v-if="permission.indexOf(ModuleActionList.RATE_CREATE)>-1"
                            data-bs-toggle="modal" data-bs-target="#BoqImportRateTemplate" @click="OpenRateImport"
                            title="Import File"><i class="fa-solid fa-upload"></i>  </button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <Pagination
                    :changePage="PageChange" 
                    :paginationDetails="{totalPages : templateList.totalPages, page : templateList.page}" 
                    :havePageCount="true"
                    ></Pagination>
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Template Name</th>
                                    <th>Create At</th>
                                    <th>Status</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="isTableLoading">
                                    <td colspan="4"><Loader  /> </td>
                                </tr>
                                <template v-else>
                                    <tr v-if="templateList.docs.length==0">
                                        <td class="text-center" colspan="4">
                                            No Data Found
                                        </td>
                                    </tr>
                                    <tr v-for="(item, key) in templateList.docs" :key="key">
                                        <td>
                                            <router-link class="link-item-route text-capitalize"
                                            :to="{name : 'BoqRatesItems', params : {id : item._id}}"
                                            > {{item.name}}</router-link>
                                        </td>
                                        <td>{{ReformatDate(item.createdAt)}}</td>
                                        <td>
                                            <span class="badge bg-primary " v-if="item.status==true">Active</span>
                                            <span class="badge bg-light text-black" v-else>Inactive</span>
                                        </td>
                                        <td>
                                            <router-link class="btn btn-outline-primary me-1  btn-action"
                                            :to="{name : 'BoqRatesItems', params : {id : item._id}}"
                                            title="View template"
                                            > 
                                                <i class="fa-solid fa-eye"></i>
                                            </router-link>
                                            <button class="btn btn-outline-primary me-1  btn-action"
                                            v-if="permission.indexOf(ModuleActionList.RATE_EDIT)>-1"
                                            data-bs-toggle="modal" data-bs-target="#BoqEditRateTemplate" @click="OpenEditTemplate(item)"
                                            title="Edit Template"><i class="fa-solid fa-edit"></i>  </button>
                                            <button class="btn btn-outline-warning me-1  btn-action" 
                                            v-if="permission.indexOf(ModuleActionList.RATE_REMOVE)>-1"
                                            @click="(e)=>{DeleteTemplate(item,e)}"
                                            title="Delete Template"><i class="fa-solid fa-trash-can"></i>  </button>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>  
        </div>
        <Modal idModal="BoqAddRateTemplate" headerTitle="Add Template"  >
            <div>
                <div class="form-group">
                    <label class="required-style">Template Name </label>
                    <input type="text" v-model="newTemplate.name" placeholder="Template Name" class="form-control"/> 
                    <small class="text-warning" v-if="isNewTemplateClicked && !newTemplate.name">Required</small>
                </div>
            </div>
            <template v-slot:actions>
                <button type="button" ref="closeBoqAddRateTemplate" class="btn btn-outline-danger" data-bs-dismiss="modal" ><i class="fa-solid fa-xmark"></i> Cancel</button>
                <button type="button" class="btn btn-primary"  @click="AddTemplate"><i class="fas fa-save"></i> Add Template</button>
            </template>
        </Modal>
        <Modal idModal="BoqEditRateTemplate" headerTitle="Edit Template"  >
            <div>
                <div class="form-group">
                    <label class="required-style">Template Name </label>
                    <input type="text" v-model="updateTemplate.name" placeholder="Template Name" class="form-control"/> 
                    <small class="text-warning" v-if="isUpdateTemplateClicked && !updateTemplate.name">Required</small>
                </div>
                <div class="form-group">
                    <label class="required-style">Status </label>
                    <select class="form-select" v-model="updateTemplate.status">
                        <option value="true">Active</option>
                        <option value="false">Inactive</option>
                    </select>
                    <small class="text-warning" v-if="isUpdateTemplateClicked && !updateTemplate.status">Required</small>
                </div>
            </div>
            <template v-slot:actions>
                <button type="button" ref="closeBoqEditRateTemplate" class="btn btn-outline-danger" data-bs-dismiss="modal"><i class="fa-solid fa-xmark"></i> Cancel</button>
                <button type="button" class="btn btn-primary"  @click="EditTemplate"  ><i class="fas fa-save"></i> Save Changes</button>
            </template>
        </Modal>
        <Modal idModal="BoqImportRateTemplate" headerTitle="Import Template"  >
            <div>
                <div class="form-group">
                    <label class="required-style">Template Name </label>
                    <input type="text" v-model="uploadTemplate.name" placeholder="Template Name" class="form-control"/> 
                    <small class="text-warning" v-if="isUploadTemplateClicked && !uploadTemplate.name">Required</small>
                </div>
                <div class="form-group">
                    <label class="required-style">Template </label>
                    <select class="form-select" v-model="uploadTemplate.templateType"> 
                        <option value="">Please Select</option>
                        <option v-for="(item,key) in ExportTemplates" :key="key" :value="key">{{item}}</option>
                    </select>
                    <small class="text-warning" v-if="uploadTemplate.templateType===''&&isUploadTemplateClicked">Required</small>
                    <div class="text-end">
                        <a href="#" class="small" role="button" @click="DownloadTemplate">Download selected template</a>
                    </div>
                </div>
                <div class="form-group">
                    <label class="required-style">File</label>
                    <input type="file" class="form-control" ref="uploadFile" />
                    <small class="text-warning" v-if="!uploadTemplate.file&&isUploadTemplateClicked">Required <br /></small>
                </div>
            </div>
            <template v-slot:actions>
                <button type="button" ref="closeBoqImportRateTemplate" class="btn btn-outline-danger" data-bs-dismiss="modal" ><i class="fa-solid fa-xmark"></i> Cancel</button>
                <button type="button" class="btn btn-primary" @click="ImportTemplate" ><i class="fas fa-save"></i> Import</button>
            </template>
        </Modal>
    </div>
    
</template>

<script src="@/assets/boq/BoqRates.js" />