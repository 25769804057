<template>
    <div>
        <BreadcrumbsProject
        :breadcrumbs="[
            {
                link : '/high-level-estimates',
                title : 'Projects Estimates',
                isRouterLink: true
            },
            {
                link : '/high-level-estimates/' + hleProjectId + '/bill-of-quantities',
                title : (hleProject.projectName || '-' )+ ' - Bill of Quantities',
                isRouterLink: true
            },
            {
                link : '/high-level-estimates/' + hleProjectId + '/bill-of-quantities/' + boqId,
                title :boqName,
                isRouterLink: true
            },
            {
                link : '/high-level-estimates/' + hleProjectId + '/bill-of-quantities/' + boqId + '/summary',
                title :'Summary',
                isRouterLink: true
            },
        ]"
        />
        <div class="card">
            <div class="card-header bg-white">
                <h6>Bill of Quantity Summary</h6> 
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <BoqLineItemSummary :data="summaryData" :currencySign="currencySign" />
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import BreadcrumbsProject  from '@/components/projectInformation/BreadcrumbsProject.vue'
import BoqLineItemSummary  from '@/components/boq/BoqLineItemSummary.vue'
import {GetHighLevelBoqLineItemsSummary, GetHighLevelProject} from '@/actions/boqActions.js'
import {LinkList} from '@/helpers/utilities.js'
import {mapActions} from 'vuex' 
import 'dotenv/config'
export default {
    name : 'HleBoqItemsSummary',
    mounted : async function(){
        const self = this
        const boqItem = await GetHighLevelBoqLineItemsSummary(self.boqId)
        self.hleProject = await GetHighLevelProject(self.hleProjectId)
        self.boqItem = boqItem.boq
        self.summaryData = boqItem.summary
        self.boqName = boqItem.boq.name  + ' - (' + boqItem.boq.currencyId.sign +') '  + boqItem.boq.currencyId.shortName  +' '+ boqItem.boq.currencyId.name
        self.currencySign = boqItem.boq.currencyId.sign
        self.ChangeActiveLink(LinkList.HIGH_LEVEL_ESTIMATES)
    },
    components : {
        BreadcrumbsProject, BoqLineItemSummary
    },
    methods : {
        ...mapActions(['ChangeActiveLink']),
    },
    data(){
        return {
            hleProjectId  : this.$route.params.id, 
            hleProject : {},
            boqId : this.$route.params.boqId,
            summaryData : [],
            currencySign : '',
            boqName : '',
            projectUrl : process.env.VUE_APP_PROJECT_URL,
        }
    }
}
</script>