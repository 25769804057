export async function UploadFileToFolder(token, driveId,folderId, fileName, fileContent) {
	// TODO: check if folder with current date inside the drive exists, if not create the folder first
	//const fileResponse = (`https://graph.microsoft.com/v1.0/me/drive/items/${folderId}:/${fileName}:/content`, {
	const fileResponse = await fetch(`https://graph.microsoft.com/v1.0/me/drives/${driveId}/items/${folderId}:/${fileName}:/content`, {
		method: "PUT",
		headers: {
			"Authorization": `Bearer ${token}`,
			"Content-Type": "text/plain"
		},
		body: fileContent
	}).then(response => response.json())
		.then(data => {
			return data;
		})
		.catch((error) => { 
			console.error('Error:', error)
			return null
		})

	return await fileResponse;
}
export async function CreateFolderToDrive(accessToken, driveId, newFolderName) {
	const folderCreationRequest = {
		'name': newFolderName,
		'folder': {},
		'@microsoft.graph.conflictBehavior': 'rename'
	};

	const folderCreationResponse = await fetch(`https://graph.microsoft.com/v1.0/me/drives/${driveId}/root/children`, {
		method: 'POST',
		headers: {
			'Authorization': `Bearer ${accessToken}`,
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(folderCreationRequest)
	});
	return folderCreationResponse.json();
}
export async function CopyFileToFolder(token, driveId,folderId, fileName, fileId) {
	// TODO: check if folder with current date inside the drive exists, if not create the folder first
	//const fileResponse = (`https://graph.microsoft.com/v1.0/me/drive/items/${folderId}:/${fileName}:/content`, {
	const fileResponse = await fetch(`https://graph.microsoft.com/v1.0/me/drives/${driveId}/items/${fileId}/copy`, {
		method: "POST",
		headers: {
			"Authorization": `Bearer ${token}`,
			"Content-Type": "application/json"
		},
		body : JSON.stringify({
			name: fileName, // This will be the name of the copied file
			parentReference: {
				// This is the ID of the folder where the file will be copied to
				id: folderId 
			}
		})
	})

	if(fileResponse.ok){
		const monitorUrl = fileResponse.headers.get("Location");
		const dataResource = await new Promise((resolve)=>{
			const intervalId = setInterval( () => {
				fetch(monitorUrl)
				.then(response => response.json())
				.then(data => {
					if(data.status === "completed") {
						// Stop polling
						clearInterval(intervalId);
						resolve(data)
					} else if(data.status === "failed") {
						// Stop polling
						clearInterval(intervalId);
						resolve(null)
					}
				});
			}, 1000);
		})

		const fileInfo = await fetch(`https://graph.microsoft.com/v1.0/me/drives/${driveId}/items/${dataResource.resourceId}`, {
			headers: {
				"Authorization": `Bearer ${token}`
			}
		}).then((res)=> res.json())
		return fileInfo

	}
	else {
		console.log(fileResponse)
		return null
	}
}

export async function DeleteFileToFolder(token, driveId,fileId) {
	// TODO: check if folder with current date inside the drive exists, if not create the folder first
	//const fileResponse = (`https://graph.microsoft.com/v1.0/me/drive/items/${folderId}:/${fileName}:/content`, {
	const fileResponse = await fetch(`https://graph.microsoft.com/v1.0/me/drives/${driveId}/items/${fileId}`, {
		method: "DELETE",
		headers: {
			"Authorization": `Bearer ${token}`,
		},
	})
	return fileResponse;
}
