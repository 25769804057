<template>
    <div class="text-center pt-5">
        <h5><i class="fa-solid fa-circle-notch fa-spin"></i> Page Closing...</h5>
    </div>
    
</template>


<script>

export default {
  name : 'AuthPage',
  mounted : async function(){

    window.localStorage.setItem('z_token_share_value', 1) 
    window.close()
  },
  data(){
    return {
        token : null
    }
  }
}
</script>
