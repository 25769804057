<template>
    <template v-for="(item ,key) in sections" :key="key">
        <tr class="" >
            <td  class="col-2" >{{item.label}}</td>
            <td class="col-2 text-capitalize" :class="{'ps-4' : item.level==2,'ps-5' : item.level==3}">
                {{item.name}} 
            </td>
            <td class="col-2 text-end">
                {{ item.sum && item.sum.sellTotal  != 0 && size != 0 ? ReformatNumber(item.sum.sellTotal / size) : ''}} 
            </td>
            <td class="col-2 text-end">
                {{ item.sum  ? ReformatNumber(item.sum.sellTotal) : ''}}
            </td>
            <td class="col-4">
                <div v-if="item.sum  && !isReadOnly">
                    <small @click="OpenSectionScopeOfWork(item,boqItem)"  class="text-primary fw-bold" role="button" data-bs-toggle="modal" data-bs-target="#ModalAddScopeOfWorkSection">
                        <i class="fa-solid fa-pen-to-square"></i> {{ $t('Bidding Module.Edit Scope of Work') }}
                    </small>
                </div>
                <div v-if="item.sum">
                    <p class="description-line-item">{{ sectionScopeOfWorkList.find(u=>u.sectionId==item.id)?.scopeOfWork }}</p>
                </div>
            </td>
        </tr>
        <HleQuotationSectionRows :sectionScopeOfWorkList="sectionScopeOfWorkList" :sections="item.childs" :boqItem="boqItem" :size="size" :OpenSectionScopeOfWork="OpenSectionScopeOfWork" :isReadOnly="isReadOnly" />
    </template>
</template>


<script>
import HleQuotationSectionRows from '@/components/boq/HleQuotationSectionRows.vue'
import {ReformatNumber } from '@/helpers/utilities.js'
export default {
    name : 'HleQuotationSectionRows',
    props : ['boqItem','sections','size','OpenSectionScopeOfWork','sectionScopeOfWorkList','isReadOnly'],
    components : {
        HleQuotationSectionRows
    },
    methods :{
        ReformatNumber
    }
}
</script>