import {mapGetters, mapActions} from 'vuex'
import {ReformatDate, LinkList} from '@/helpers/utilities.js'
import ModalLineItemColumnFilter from '@/components/boq/ModalLineItemColumnFilter.vue'
import {GetBoqHistoryListByBits, GetNewBoqCreatorSectionsRevisions} from '@/actions/boqActions.js'
import 'dotenv/config'
import BreadcrumbsProject  from '@/components/projectInformation/BreadcrumbsProject.vue'
import BoqLineItemTable  from '@/components/boq/BoqLineItemTable.vue'
import Loader  from '@/components/layout/Loader.vue'
import Multiselect from 'vue-multiselect'
import {SearchAllActiveCostCodes} from '@/actions/projectActions.js'
import BoqSectionTableReadOnly  from '@/components/boq/BoqSectionTableReadOnly.vue'
import FigureCurrencyNote from '@/components/layout/FigureCurrencyNote.vue'
export default {
    name : 'BoqHistory',
    mounted : async function(){
        const self = this
        self.ChangeActiveLink(LinkList.BILL_OF_QUANTITIES)
        
        const boqItem = await self.GetBoqItemRun(self.$route.params.id)
        self.boqItemData = boqItem
        await self.GetProjectInfo(boqItem.projectId).catch()

        let lastIndex = self.revisionId ? boqItem.revisionList.findIndex(u=>u._id==self.revisionId) : boqItem.revisionList.length - 1 

        let revision = boqItem.revisionList[lastIndex]

        self.$router.push({name : 'History', params : { id : boqItem._id},query : {revisionId : revision._id}})
        self.boqRevision = {
            _id : revision._id,
            date : revision.publishDate,
            dateUtc : revision.publishDateUtc,
            currency : revision.currencyId,
            createdBy : revision.createdBy,
        }
        self.boqListParams.index = lastIndex
        // self.areaList = await SearchAreaFromProject({id : self.projectInfo._id, search : ''})
        // self.areaList.push('NO SPACE ID')


        self.rawSections = await GetNewBoqCreatorSectionsRevisions(boqItem._id,revision._id)
        
        // const historyList = await self.GetBoqHistoryListRun(self.boqListParams).catch()
        // self.boqListHistory = historyList

        // self.GetListByBites()

        // const lineItemCols =  window.localStorage.getItem('lineItemColumns')
        // if(lineItemCols){
        //     this.selectedColumns = lineItemCols.split(',').map(Number)
        // }
        // self.SearchCostCodeAll('')
        self.isLoaded = true
    },
    components :{
        BreadcrumbsProject,
        Loader,
        BoqLineItemTable,
        ModalLineItemColumnFilter,
        Multiselect,
        BoqSectionTableReadOnly,
        FigureCurrencyNote
    },
    methods : {
        ...mapActions(['GetBoqHistoryListRun','GetProjectInfo', 'GetBoqItemRun', 'ChangeActiveLink']),
        timerSetter(callback){
            const self = this
            
            if(self.timer){
                clearTimeout(self.timer)
                self.timer = null
            }

            self.timer = setTimeout(callback,700)
        },
        timerSetterSearch(callback){
            const self = this
            
            if(self.timerSearch){
                clearTimeout(self.timerSearch)
                self.timerSearch = null
            }

            self.timerSearch = setTimeout(callback,700)
        },
        formatDate(value, format = "DD-MMM-YYYY"){
            return ReformatDate(value,format)
        },
        ChangeView(val){
            this.filterView = val
        },
        SelectArea(area){
            const self = this
            const selected = self.selectedArea

            const getIndex = selected.indexOf(area)
            if(getIndex==-1){
                if(selected.length>=5){
                    return
                }
                selected.push(area)
            }else{
                selected.splice(getIndex,1)
            }
            
            self.boqListParams.page = 1

            self.boqListParams.costCode = ''
            self.selectedCostCode = []
            self.boqListParams.skip = 0
            self.boqListParams.area = encodeURIComponent(JSON.stringify(selected))
            
            self.isLoaded = false
            self.timerSetter(function(){
                self.boqListHistory = []
                self.GetListByBites()
                self.isLoaded = true
                // self.GetBoqHistoryListRun(self.boqListParams).then((res)=>{
                //     self.isLoaded = true
                //     self.boqListHistory = res
                // }).catch()
            })
        },
        SelectCostCode(costCode){
            const self = this

            
            const getIndex = self.selectedCostCode.findIndex(u=>u==costCode)
            if(getIndex==-1){
                self.selectedCostCode.push(costCode)
            }else{
                self.selectedCostCode.splice(getIndex,1)
            }

            self.boqListParams.costCode = encodeURIComponent(JSON.stringify(self.selectedCostCode))
            self.boqListParams.area = ''
            self.boqListParams.page = 1
            

            self.selectedArea = []
            self.isLoaded = false
            self.boqListParams.skip = 0
            self.timerSetter(function(){
                self.boqListHistory = []
                self.GetListByBites()
                self.isLoaded = true
                // self.GetBoqHistoryListRun(self.boqListParams).then((res)=>{
                //     self.isLoaded = true
                //     self.boqListHistory = res
                // }).catch()
            })
        },
        async ChangeIndex(index){
            const self = this

            const revision = self.boqItemData.revisionList[index]
            self.isLoaded = false
            self.rawSections = await GetNewBoqCreatorSectionsRevisions(self.boqItemData._id,revision._id)
            self.boqListParams.index = index
            self.$router.push({name : 'History', params : { id : self.boqItemData._id},query : {revisionId : revision._id}})
            // self.boqListParams.page = 1
            // self.boqListParams.area = ''

            self.boqRevision = {
                _id : revision._id,
                date : revision.publishDate,
                dateUtc : revision.publishDateUtc,
                currency : revision.currencyId,
                createdBy : revision.createdBy,
                // areaList : revision.areaList.sort(SortFunc),
                // subCategories : revision.subCategoryList.sort(LevelSort)
            }
            self.isLoaded = true

            // self.boqListParams.costCode = encodeURIComponent([])
            // self.selectedCostCode = []
            // self.selectedArea = []

            // self.isLoaded = false
            // self.boqListHistory = []
            // self.GetListByBites()
            // self.isLoaded = true

            // self.GetBoqHistoryListRun(self.boqListParams).then((res)=>{
            //     self.isLoaded = true
            //     self.boqListHistory = res
            // }).catch()

            
        },
        ChangeAddIndex(){
            const newIndex = this.boqListParams.index  - 1
            if(newIndex<0){
                return
            }
            this.boqListParams.skip = 0
            this.ChangeIndex(newIndex)
        },
        ChangeMinusIndex(){
            const newIndex = this.boqListParams.index  + 1
            if(newIndex>this.boqItemData.revisionList.length){
                return
            }
            this.boqListParams.skip = 0
            this.ChangeIndex(newIndex)
        },
        ChangeSelectecColumnValue(value){
            this.selectedColumns = value
        },
        GetListByBites(){
            const self = this
            GetBoqHistoryListByBits(self.boqListParams).then((res)=>{
              if(res.length!=0){
                self.boqListHistory.push(...res)
              }
              if(res.length == self.boqListParams.take){
                  self.boqListParams.skip += 50
                  self.GetListByBites()
              }
            })
        },
        SearchCostCodeAll(query){
            const self =  this
            self.searchCostCodeLoading = true
            self.timerSetter(function(){
                self.searchCostCodeLoading = false
                SearchAllActiveCostCodes(query).then((res)=>{
                    self.dataCostCodes = res
                })
            })
        },
        ClearField(type){
            if(type==1){
                this.selectedArea = []
            }
            else{
                this.selectedCostCode = []
            }
        }
    },
    watch: {
        selectedCostCode(val) {
            const self = this
            self.boqListParams.skip = 0
            self.boqListParams.costCode = encodeURIComponent(JSON.stringify(val.map(u=>u._id)))
            self.boqListParams.area = encodeURIComponent(JSON.stringify(self.selectedArea))
            
            self.isLoaded = false
            self.timerSetterSearch(function(){
                self.boqListHistory = []
                self.GetListByBites()
                self.isLoaded = true
            })
    
            return val.map(u=>u._id)
        },
        selectedArea(val){
            const self = this
            self.boqListParams.skip = 0
            self.boqListParams.costCode = encodeURIComponent(JSON.stringify(self.selectedCostCode))
            self.boqListParams.area = encodeURIComponent(JSON.stringify(val))
            
            self.isLoaded = false
            self.timerSetterSearch(function(){
                self.boqListHistory = []
                self.GetListByBites()
                self.isLoaded = true
            })
    
          return val
        }
    },
    data(){
        return{
            revisionId : this.$route.query.revisionId,
            rawSections : {
                sectionList : [],
                overAllTotal :{}
            },
            boqListHistory : [],
            selectedArea : [],
            areaList : [],
            selectedCostCode : [],
            dataCostCodes : [],
            boqListParams : {
                page : 1,
                id : this.$route.params.id,
                costCode : '',
                area : '',
                index : 0,
                skip : 0,
                take : 50
            },
            boqRevision : {
                createdBy : {

                },
                currency : {},
                subCategories : [],
                areaList : [],
            },
            isLoaded : false,
            clicked :[],
            projectUrl : process.env.VUE_APP_PROJECT_URL,
            page : 1,
            boqItemData : {
                revisionList : []
            },
            filterView : 1,
            timer : null,
            timerSearch : null,
            OpenFilter : false,
            selectedColumns :  [],
            searchCostCodeLoading : false
        }
    },
    computed :{ 
        ...mapGetters(['projectInfo']),
        HistoryList(){
            const self = this
            const data = self.boqListHistory.docs.map(u=>{

                const cost = (u.quantity || 0) * ( u.rate || 0)
                const gp = cost * ((u.markUp || 0) / 100)
                
                u.cost = cost
                u.gp = gp
                u.price = gp + cost
                u.priceRate = u.price / u.quantity
                return u
            })

            return data
        }
    
    }

}