<template>
    <div>
        <BreadcrumbsProject
        :breadcrumbs="[
            {
                link : '/high-level-estimates',
                title : $t('Bidding'),
                isRouterLink: true
            },
            {
                link : '/high-level-estimates/' + hleProjectId + '/quotation',
                title : (hleProject.projectName || '-' )+ ' - ' + $t('Bidding Module.High Level Estimates'),
                isRouterLink: true
            },
            {
                link : revisionId ? '/high-level-estimates/ '+ hleProjectId +'/quotation/' + quotationId + '/history?revisionId='+revisionId : '/high-level-estimates/' + hleProjectId+ '/quotation/' + quotationId + '/history',
                title : name + ' ' + $t('History'),
                isRouterLink: true
            },
        ]"
        />
        <div class="row pt-2 pb-2">
            <div class="col-md-4">
                <button class="btn btn-primary ms-3" v-if="currentIndex!=0" @click="ChangeMinusIndex"> 
                    <i class="fa-solid fa-arrow-left-long fa-2x"></i>
                </button>
            </div>
            <div class="col-md-4">
                <div class="d-flex justify-content-center" >
                    <h4 class="fw-bold">{{ $t('Revision') }} {{currentIndex}}</h4>
                </div>
            </div>
            <div class="col-md-4">
                <div class="d-flex justify-content-end" v-if="currentIndex < revisions.length - 1" @click="ChangeAddIndex">
                    <button class="btn btn-primary me-3">
                        <i class="fa-solid fa-arrow-right-long fa-2x"></i>
                    </button>
                </div>
            </div>
        </div>
        <HleQuotationReadOnly 
        :quotationItem="currentRevision" :summaryBoqData="summaryBoqData" :editedLineItems="editedLineItems"
        :quotationId="quotationId" :quotationName="name"
        />
    </div>
</template>


<script>
import {GetHighLevelQuotationRevisions} from '@/actions/boqActions.js'
import {GetHighLevelQuotationBoQDetailed, GetHighLevelProject} from '@/actions/boqActions.js'
import HleQuotationReadOnly  from '@/components/boq/HleQuotationReadOnly.vue'
import BreadcrumbsProject  from '@/components/projectInformation/BreadcrumbsProject.vue'
import {GetUserSignatureOffice} from '@/actions/userActions.js'
import {ConvertDataToBlob} from '@/helpers/utilities.js'
export default {
    name : 'HleQuotationHistory',
    data(){
        return  {
            currentRevision : {
                officeId :{},
                projectLocation : {},
                submittedBy: [{
                    roleId : {}
                }]
            },
            hleProject : {},
            hleProjectId : this.$route.params.id,
            name : '',
            quotationId : this.$route.params.qtnId,
            revisionId : this.$route.query.revisionId,
            revisions : [],
            index : 0,
            editedLineItems : [],
            summaryBoqData : [],
            currentIndex : 0
        }
    },
    components : { HleQuotationReadOnly, BreadcrumbsProject },
    mounted : async function(){
        const self = this
        const getQuotationRevisions = await GetHighLevelQuotationRevisions(self.quotationId)

        self.hleProject = await GetHighLevelProject(self.hleProjectId)

        self.revisions = getQuotationRevisions.revisionList || []

        let index = self.revisions.length - 1

        if(self.revisionId){
            index = self.revisions.findIndex(u=>u._id==self.revisionId)
        }

        self.currentRevision = self.revisions[index]
        self.editedLineItems = self.currentRevision.lineItemRenamedData || []
        self.name = getQuotationRevisions.name
        self.currentIndex = index
        self.summaryBoqData = await GetHighLevelQuotationBoQDetailed(self.quotationId,self.currentRevision._id)
        await self.GetSignatures()
    },
    methods : {
        async ChangeMinusIndex(){
            const self = this
            self.currentRevision = self.revisions[--self.currentIndex]
            self.summaryBoqData = await GetHighLevelQuotationBoQDetailed(self.quotationId,self.currentRevision._id)
            self.$router.push({name : 'HleQuotationHistory', params : {id : self.quotationId}, query : {revisionId :self.currentRevision._id }})
        },
        async ChangeAddIndex(){
            const self = this
            self.currentRevision = self.revisions[++self.currentIndex]
            self.summaryBoqData = await GetHighLevelQuotationBoQDetailed(self.quotationId,self.currentRevision._id)
            self.$router.push({name : 'HleQuotationHistory', params : {id : self.quotationId}, query : {revisionId :self.currentRevision._id }})
        },
        async GetSignatures(){
            const self = this
            for(let x= 0; x< self.currentRevision.submittedBy.length; x++){
                const getSubmitter = self.currentRevision.submittedBy[x]
                const getSignatureSubmitter = self.currentRevision.submittedBySignatures.find(u=>u.userId==getSubmitter._id)
                if(getSignatureSubmitter){
                    const getSignature = await GetUserSignatureOffice({officeId : self.hleProject.officeId, userId : getSubmitter._id, signatureId :  getSignatureSubmitter.signatureIdFromDotNet})
                    if(getSignature && getSignature.message == "OK"){
                        getSubmitter.signatureBlob = ConvertDataToBlob(getSignature.result.signature,getSignature.result.mimeType )
                        
                    }
                }
            }
        }
    },
    
    beforeUnmount() {
        for(let x= 0; x< this.currentRevision.submittedBy.length; x++){
            const blob = this.currentRevision.submittedBy[x].signatureBlob
            if(blob){
                URL.revokeObjectURL(blob);
            }
        }
    },
    
}
</script>