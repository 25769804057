<template>
    <Multiselect
      v-bind="$attrs"
      :placeholder="placeholder"
      :selectLabel="selectLabel"
      :deselectLabel="deselectLabel"
      :selectedLabel="selectedLabel"
      :deselectAllLabel="deselectAllLabel"
      :noResult="noResult"
      :noOptions="noOptions"
    >
      <slot></slot>
      <template #noResult>
        <span>{{$t('MultiSelectComponent.noResult')}}</span>
      </template>
      <template #noOptions>
        <span>{{$t('MultiSelectComponent.noOptions')}}</span>
      </template>
    </Multiselect>
  </template>
  
  <script>
  import Multiselect from 'vue-multiselect'
  export default {
    name: 'MultiselectExtended',
    components: { Multiselect },
    data() {
        const self = this
        return {
            placeholder : self.$i18n.t('MultiSelectComponent.placeholder'),
            selectLabel: self.$i18n.t('MultiSelectComponent.selectLabel'),
            deselectLabel: self.$i18n.t('MultiSelectComponent.deselectLabel'),
            selectedLabel: self.$i18n.t('MultiSelectComponent.selectedLabel'),
            deselectAllLabel: self.$i18n.t('MultiSelectComponent.deselectAllLabel'),
            noResult: self.$i18n.t('MultiSelectComponent.noResult'),
            noOptions: self.$i18n.t('MultiSelectComponent.noOptions')
        }
    }
  }
  </script>