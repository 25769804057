<template>
    <th class="line-item-100 text-center" v-for="(item, key) in ColumnNeeded" valign="middle" :key="key" v-html="item.name.replace('（','<br/>(')" >
    </th>
    <!-- <template v-if="selectedColumns.indexOf(1)>-1">
        <th class="line-item-100 ">Qty Raw</th>
        <th class="line-item-100 ">Qty Buffer</th>
        <th class="line-item-100 ">Qty Adjusted</th>
    </template>
    <th v-else class="line-item-100">Qty</th>
    <template v-if="selectedColumns.indexOf(1)>-1">
        <th class="line-item-100">Supply Rate</th>
        <th class="line-item-100">Install Rate</th>
        <th class="line-item-100">Supply And Install</th>
        <th class="line-item-100">Supply Rate Buffer</th>
        <th class="line-item-100">Install Rate Buffer</th>
        <th class="line-item-100">Supply And Install Buffer</th>
        <th class="line-item-100">Cost Rate Adjusted</th>
    </template>
    <template  v-else>
        <th class="line-item-100">Cost Rate</th>
    </template>
    
    <template v-if="selectedColumns.indexOf(2)>-1">
        <th class="line-item-100"> Cost Total</th>
        <th class="line-item-100">Cost Total Buffer</th>
        <th class="line-item-100">Cost Total Adjusted</th>
    </template>
    <th v-else class="line-item-100">Cost Total</th>
    <th class="line-item-100">Mark Up</th>
    <template v-if="selectedColumns.indexOf(3)>-1">
        <th class="line-item-100">Sell Rate Raw</th>
        <th class="line-item-100">Sell Rate Buffer</th>
        <th class="line-item-100">Sell Rate Adjusted</th>
    </template>
    <th v-else class="line-item-100">Sell Rate</th>
    <template v-if="selectedColumns.indexOf(4)>-1">
        <th class="line-item-100">Sell Total Raw</th>
        <th class="line-item-100">Sell Total Buffer</th>
        <th class="line-item-100">Sell Total Adjusted</th>
    </template>
    <th v-else class="line-item-100">Sell Total</th>
    <template v-if="selectedColumns.indexOf(5)>-1">
        <th class="line-item-100">GP Raw</th>
        <th class="line-item-100">GP Buffer</th>
        <th class="line-item-100">GP Adjusted</th>
    </template>
    <th v-else class="line-item-100">GP </th> -->
</template>

<script>
import {BoQColumnList} from '@/helpers/utilities.js'
export default {
    name : 'BoqLineItemRowThead',
    props : ['selectedColumns','ReformatNumber'],
    computed: {
        ColumnNeeded(){
            return BoQColumnList.filter(u=>this.selectedColumns.indexOf(u.value) > -1)
        }
    }
}
</script>