<template>
    <div>
        <BreadcrumbsProject
        :breadcrumbs="[
            {
                link : projectUrl +'/',
                title : $t('Projects'),
                isRouterLink: false
            },
            {
                link : projectUrl +'/Project/' + projectInfo._id,
                title : (projectInfo.projectNumber || '-') +' ' + (projectInfo.projectName || ''),
                isRouterLink: false
            },
            {
                link : '/boq/' + projectInfo._id+'/consolidated',
                title : $t('Consolidated Bill of Quantities'),
                isRouterLink: true
            },
        ]"
        >
            
        </BreadcrumbsProject>
        <StepItem2></StepItem2>
        <div style="position: absolute; top: 64px; right: 10px; z-index: 1;" class="d-flex align-items-center">
            <span  style="min-width: 90px;">{{$t('Viewing As')}}</span>
            <select class="form-select" v-model="clientView">
                <option value="1">MMoser ({{$t('Internal')}})</option>   
                <option value="2">{{$t('Client')}} ({{$t('External')}})</option>   
            </select>
        </div>

        <div >
            <div class="card border-top">
                <div class="card-body">
                    <div class="row"  >
                        <div class="col-md-3 ">
                            <!-- <div class="btn-group">
                                <button class="btn btn-default "
                                @click="()=>{ openFilter = !openFilter}" title="Filter Line Items"
                                >
                                <i class="fa-solid fa-filter"></i>  Filter
                                </button>
                                <button class="btn btn-default"
                                data-bs-toggle="modal" data-bs-target="#BoqColumnFilter"
                                >
                                <i class="fas fa-eye-slash me-1"></i>  Columns
                                </button>
                            </div> -->
                            
                        </div>
                        <div class="col-md-6 text-center">
                            <h4 class="h4 fw-bold text-center m-0" >
                                {{$t('Consolidated Bill of Quantities')}}
                            </h4>
                        </div>
                        <div class="col-md-3 d-flex ">
                            <div class="input-group justify-content-end">
                                <!-- <button class="btn btn-default"
                                v-if="permission.indexOf(ModuleActionList.BOQ_EDIT)>-1"
                                data-bs-toggle="modal" data-bs-target="#ModalImportBoq"
                                @click="OpenImportModal" 
                                ><i class="fas fa-file-import "></i> Import</button> -->
                                <button class="btn btn-default" 
                                data-bs-toggle="modal" data-bs-target="#BoqExportBoqList"
                                @click="()=>{ exportTemplateType= 0; }" 
                                ><i class="fas fa-file-export me-1"></i> {{$t('Export')}} </button>
                                
                            </div>
                        </div>
                        <!-- <div class="col-md-6" v-if="openFilter">
                            <div class="form-group ">
                                <label class="d-flex justify-content-between">
                                    <a>Cost Category</a>
                                    <span role="button" class="text-danger text-decoration-underline" @click="ClearField(2)"><i class="fa-solid fa-xmark "></i> Clear Field</span>
                                </label>
                                <Multiselect 
                                :multiple="true" :options="dataCostCodes" 
                                :internalSearch="false"
                                v-model="selectedCostCode"  @search-change="SearchCostCodeAll" :searchable="true" :loading="searchCostCodeLoading"
                                placeholder="Type Here to search by Cost Category"
                                :clear-on-select="false" :max-height="300"
                                label="costName" track-by="_id"/>
                            </div> 
                        </div>
                        <div class="col-md-6" v-if="openFilter">
                            <div class="form-group ">
                                <label class="d-flex justify-content-between">
                                    <a>Space ID</a>
                                    <span role="button" class="text-danger text-decoration-underline" @click="ClearField(1)"><i class="fa-solid fa-xmark "></i> Clear Field</span>
                                </label>
                                <Multiselect 
                                :multiple="true" :options="areaList" 
                                placeholder="Type Here to search by Space ID"
                                v-model="selectedArea"  :max-height="300" />
                            </div> 
                        </div> -->
                    </div>
                    <div >
                        <FigureCurrencyNote :currency="projectInfo.currencyId" />
                        <div  class="table-responsive m-0"   style="height:73vh"  v-if="clientView==2">
                            <table class="table  table-bordered ">
                                <thead class="sticky-top" >
                                    <tr>
                                        <th class="sticky-left">{{ $t('S/N') }}</th>
                                        <th class="sticky-left">{{$t('Description')}}</th>
                                        <th > {{$t('Unit')}}</th>
                                        <th class="text-end" > {{$t('Quantity')}}</th>
                                        <th class="text-end"> {{$t('Rate')}}</th>
                                        <th class="text-end"> {{$t('Total')}}</th>
                                    </tr>
                                </thead>
                                <tbody class="table-group-divider">
                                    <template v-for="(item, key) in ClientViewList" :key="key">
                                        <tr v-if="item.mainCateg!=null">
                                            <td class="fw-bold bg-light-primary " >
                                                {{ item.mainCateg.label }}
                                            </td>
                                            <td class="fw-bold bg-light-primary " colspan="5" >
                                                 {{item.mainCateg.name}}
                                            </td>
                                            <!-- <td class="fw-bold bg-light-primary "  ></td>
                                            <td class="fw-bold bg-light-primary text-end">{{ReformatNumber(item.mainCateg.total)}}</td> -->
                                        </tr>
                                        <tr v-if="item.subCateg!=null">
                                            <td class="fw-bold bg-light-primary " >
                                                {{ item.subCateg.label }}
                                            </td>
                                            <td class="fw-bold bg-light-primary  ps-4" colspan="5" >
                                                 {{item.subCateg.name}}
                                            </td>
                                            <!-- <td class="fw-bold bg-light-primary "  ></td>
                                            <td class="fw-bold bg-light-primary text-end">{{ReformatNumber(item.subCateg.total)}}</td> -->
                                        </tr>  
                                        <tr>
                                            <td>
                                                {{ item.lineItem.label }}
                                            </td>
                                            <td class="bg-white ps-3 " >
                                                <div class="ps-3">
                                                    <div class="">
                                                        <span class="fw-bold small">{{item.lineItem.name}}</span> <br/>
                                                        <span class="small mb-0 description-line-item">
                                                            {{item.lineItem.description}}
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{{item.lineItem.unit}}</td>
                                            <td class="text-end">{{ReformatNumber(item.lineItem.totalQuantity)}}</td>
                                            <td class="text-end">{{ReformatNumber(item.lineItem.sellRate)}}</td>
                                            <td class="text-end">{{ReformatNumber(item.lineItem.sellTotal)}}</td>
                                        </tr>
                                        <tr v-if="item.totalSub!=null">
                                            <td class="fw-bold bg-light text-end" colspan="5" >
                                                {{$t('Sub Total Of')}} {{ item.totalSub.label }}
                                            </td>
                                            <td class="fw-bold bg-light text-end ps-4"  >
                                                {{ReformatNumber(item.totalSub.total)}}
                                            </td>
                                        </tr> 
                                        <tr v-if="item.totalMain!=null">
                                            <td class="fw-bold bg-light text-end " colspan="5" >
                                                {{$t('Sub Total Of')}} {{ item.totalMain.label }}
                                            </td>
                                            <td class="fw-bold bg-light text-end ps-4"  >
                                                {{ReformatNumber(item.totalMain.total)}}
                                            </td>
                                        </tr>  
                                        
                                    </template>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive m-0"  style="height:73vh" v-else>
                            <table class="table  table-bordered  ">
                                    <thead class="sticky-top" style="font-size: 12px;" >
                                        <tr class="text-center" valign= "middle">
                                            <th>{{ $t('S/N') }}</th>
                                            <th style="min-width:400px;" class="sticky-left">{{$t('Description')}}</th>
                                            <th style="min-width:200px;">{{$t('Space ID')}}</th>
                                            <th >{{ $t('Unit') }}</th>
                                            <BoqLineItemRowThead
                                            :selectedColumns="selectedColumns"
                                            :ReformatNumber="ReformatNumber" />
                                        </tr>
                                    </thead>
                                    <tbody class="table-group-divider">
                                        <tr v-if="!isLoadingLineItems && lineItems.length==0">
                                            <td colspan="23" >
                                                No Data Found
                                            </td>
                                        </tr>
                                        <template v-for="(item, key) in CostCodeList" :key="key">
                                            <tr v-if="item.mainCateg!=null" >
                                                <td class="fw-bold bg-light-primary sticky-left">{{ item.mainCateg.label }}</td>
                                                <td class="fw-bold bg-light-primary sticky-left " style="z-index: 9999;" >
                                                    <div class="d-flex justify-content-between">
                                                        <span>
                                                            {{item.mainCateg.costCode}} {{item.mainCateg.name}}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td class="fw-bold bg-light-primary" :colspan="selectedColumns.length + 1" >

                                                </td>
                                                <td  class="fw-bold bg-light-primary sticky-right text-end" role="button" @click="ToggleCategory(item.mainCateg.costCodeId)">
                                                    <span>
                                                        <i class="fas fa-chevron-down" v-if="toggledCategories.indexOf(item.mainCateg.costCodeId)==-1"></i>
                                                        <i class="fas fa-chevron-up" v-else></i>
                                                    </span>
                                                </td>
                                                
                                            </tr>
                                            <tr v-if="item.subCateg!=null && toggledCategories.indexOf(item.lineItem.parents[0].costCodeId)>-1" class="" style="font-size: 13px;" >
                                                <td class="fw-bold bg-light-primary ">{{ item.subCateg.label }}</td>
                                                <td class="fw-bold bg-light-primary sticky-left ps-4">
                                                    {{item.subCateg.costCode}} {{item.subCateg.name}}
                                                </td>
                                                <td class="fw-bold bg-light-primary" :colspan="selectedColumns.length +1" >

                                                </td>
                                                <td  class="fw-bold bg-light-primary sticky-right text-end" role="button" @click="ToggleCategory(item.subCateg.costCodeId)">
                                                    <span>
                                                        <i class="fas fa-chevron-down" v-if="toggledCategories.indexOf(item.subCateg.costCodeId)==-1"></i>
                                                        <i class="fas fa-chevron-up" v-else></i>
                                                    </span>
                                                </td>
                                                <!-- <BoqLineItemRowHeader
                                                :data="item.subCateg.totals"
                                                :selectedColumns="selectedColumns"
                                                :ReformatNumber="ReformatNumber" /> -->
                                            </tr>  
                                            <tr style="font-size: 12px;" v-if="toggledCategories.indexOf(item.lineItem.parents[0].costCodeId)>-1 && toggledCategories.indexOf(item.lineItem.parents[1].costCodeId)>-1" >
                                                <td>{{ item.lineItem.label }}</td>
                                                <td class="sticky-left bg-white ps-3 " @click="OnClickTr(item.lineItem)">
                                                    
                                                    <div class=" ps-3">
                                                        <div class="d-flex justify-content-between">
                                                            <span class="fw-bold ">{{item.lineItem.costCode}} {{item.lineItem.name}}</span> 
                                                            <span v-if="item.lineItem.rateOnly" class="text-danger bg-light px-1 rounded">RATE ONLY</span> 
                                                        </div>
                                                        <div class="">
                                                            <p class=" mb-0 description-line-item">
                                                                {{item.lineItem.description}}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>{{item.lineItem.area}}</td>
                                                <td>{{item.lineItem.unit}}</td>
                                                <BoqLineItemRow
                                                :data="item.lineItem"
                                                :selectedColumns="selectedColumns"
                                                :ReformatNumber="ReformatNumber"
                                                
                                                 />
                                            </tr>
                                            <tr v-if="item.totalSub!=null && toggledCategories.indexOf(item.lineItem.parents[0].costCodeId)>-1" class="" style="font-size: 13px;" >
                                                <td class="fw-bold bg-light "></td>
                                                <td class="fw-bold bg-light sticky-left text-end" >
                                                    {{ $t('Sub Total Of') }} {{ item.totalSub.label }}
                                                </td>
                                                <td class="fw-bold bg-light"></td>
                                                <td class="fw-bold bg-light"></td>
                                                <BoqLineItemRowHeader 
                                                :className="'bg-light'"
                                                :data="item.totalSub"
                                                :selectedColumns="selectedColumns"
                                                :ReformatNumber="ReformatNumber" />
                                            </tr>  
                                            <tr v-if="item.totalMain!=null" class="" style="font-size: 13px;" >
                                                <td class="fw-bold bg-light "></td>
                                                <td class="fw-bold bg-light sticky-left text-end" >
                                                    {{ $t('Sub Total Of') }} {{ item.totalMain.label }} 
                                                </td>
                                                <td class="fw-bold bg-light"></td>
                                                <td class="fw-bold bg-light"></td>
                                                <BoqLineItemRowHeader
                                                :className="'bg-light'"
                                                :data="item.totalMain"
                                                :selectedColumns="selectedColumns"
                                                :ReformatNumber="ReformatNumber" />
                                            </tr>  

                                        </template>
                                        
                                        <tr v-if="isLoadingLineItems">
                                            <td colspan="23">
                                                <Loader />
                                            </td>
                                        </tr>
                                    </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <ModalNewLineItem
        v-if="permission.indexOf(ModuleActionList.BOQ_CREATE)>-1"
        ref="modalNewLineItem" 
        :boqList="boqList" :ResetData="GetLineItemList" />

        <ModalImportLineItems
        v-if="permission.indexOf(ModuleActionList.BOQ_CREATE)>-1"
        ref="modalImportLineItem" 
        :boqList="boqList" :ResetData="GetLineItemList" :projectId="lineItemsParams.id"/>

        <ModalUpdateLineItem
        v-if="permission.indexOf(ModuleActionList.BOQ_EDIT)>-1"
        :selectedLineItem="updateLineItem" :RemoveParentData="RemoveParentData" :quotationList="quotationList"
        :boqList="boqList" :ResetData="GetLineItemList" />

        <ModalLineItemColumnFilter :UpdateSelectedColumn="ChangeSelectecColumnValue"/> -->

        <Modal idModal="BoqExportBoqList" :headerTitle="$t('Export')"  >
            <div>
                <div class="form-group">
                    <label class="required-style">{{$t('Template')}} </label>
                    <select class="form-select" v-model="exportTemplateType"> 
                        <option v-for="(item,key) in ExportTemplates" :key="key" :value="key">{{item}}</option>
                    </select>
                </div>
            </div>
            <template v-slot:actions>
                <div>
                    <button type="button" class="btn btn-outline-primary" @click="ExportForClient" >
                        <i class="fa-solid fa-user-tie"></i>  {{$t('Bill of Quantity Module.Export for Client')}}
                    </button>
                </div>
                <button type="button" class="btn btn-primary" @click="ExportBoqList" >
                    <i class="fa-solid fa-file-arrow-down"></i>  {{$t('Export')}}
                </button>
            </template>
        </Modal>
        <!-- <Modal idModal="BoqGroupList" headerTitle="Select Line Item Parent" v-if="permission.indexOf(ModuleActionList.BOQ_CREATE)>-1" >
            <div>
                <table class="table">
                    <tbody class="table-group-divider">
                        <tr v-for="(item,key) in selectedLineItems" :key="key">
                            <td>
                                <input type="radio" :id="key + '-radio'" :value="item._id" v-model="selectedParent" />
                            </td>
                            <td>
                                <label class="ps-3  fw-normal  " :for="key + '-radio'" >
                                    <span class="small fw-bold d-block">
                                        {{item.costCode}} {{item.name}}
                                    </span>
                                    <span class="d-block small">
                                        {{item.description}}
                                    </span>
                                    
                                </label>
                            </td>
                        </tr>
                    </tbody>
                </table>
                
            </div>
            <template v-slot:actions>
                <div>
                    <button type="button" ref="closeGroupLineItems" class="btn btn-outline-danger"  data-bs-dismiss="modal">X Close</button>
                </div>
                <button type="button" class="btn btn-primary" @click="AddChildToParents"  >
                    <i class="fas fa-save"></i> Group
                </button>
            </template>
        </Modal> -->
    </div>
    
</template>

<style scoped >
body{
    overflow-y:hidden;
}
table {
      /* border-collapse: separate ;  */
  border-spacing: 50px;
    }
    tr{
        border: 1px solid #dee2e6;
    }
    /* table th {
    border-top: 2px solid;
    border-bottom: 2px solid;
    border-right: 2px solid;
    }

    table td {
    border-bottom: 2px solid;
    border-right: 2px solid;
    }

    table th:first-child,
    table td:first-child {
    border-left: 2px solid;
    } */
    
    td {
        overflow: auto; 
    }
    .line-item-100{
        min-width:100px;
        width: 100px;
    }
    .sn-row{
        min-width:75px;
        width: 75px;
    }
    .description-row{
        min-width:400px;
        width:400px;
    }
    .space-row{
        min-width:150px;
        width:150px;
    }
    .default-row{
        padding: 8px;
    }
    .header-table-color{
        background-color: #F5F6FA;
    }
</style>

<script src="@/assets/boq/BoqConsolidated.js" />