<template>
    <table class="table table-bordered">
        <thead>
            <tr>
                <th class="col-2">{{ $t('S/N') }}</th>
                <th class="col-2"> {{ $t('Description') }}</th>
                <th class="text-end col-2"> {{ $t('Amount') }}/{{ unitOfMeasure }} ({{currencySign}})</th>
                <th class="text-end col-2"> {{ $t('Amount Total') }}({{currencySign}})</th>
                <th class="text-center col-4"> {{ $t('Bidding Module.Scope of Work') }}</th>
            </tr>
        </thead>
    </table>
    <table class="table table-bordered" v-for="(item, key) in dataRow" :key="key" >
        <tbody class="">
            <tr class="bg-light fw-bold " >
                <td class="col-2"></td>
                <td colspan="4" class="text-capitalize "  >
                    <div class="d-flex justify-content-between">
                        <router-link :to="{name : 'HleBoQBuilder', params : {id : projectId, boqId : item._id}}" class="link-item-route" v-if="!isRevision"> 
                            <i class="fa-solid fa-arrow-up-right-from-square d-print-none"></i> {{item.name}} 
                        </router-link>
                        <span v-else>{{item.name}} </span>

                        
                        <span v-if="!isRevision" role="button" @click="OpenRemoveBoQ(item._id,item.name)" class="text-danger ">
                            <i class="fas fa-times"></i> {{$t('Remove')}} 
                        </span>
                        <span v-else> </span>
                    </div>
                    
                </td>
            </tr>
            <HleQuotationSectionRows :boqItem="item" :sections="item.data.sections" :size="projectSize" :OpenSectionScopeOfWork="OpenSectionScopeOfWork"
            :sectionScopeOfWorkList="sectionScopeOfWorkList" :isReadOnly="isReadOnly" />
            <tr  class="fw-bold text-end " v-if="item.targetBudget && (!item.data.overAllTotal?.sellTotal || item.data.overAllTotal?.sellTotal==0)  "> 
                <td class="col-2"></td>
                <td class="text-end text-capitalize col-2">{{ $t('Sub Total Of') }} {{item.name}} <span class=" fw-light">(Contract Sum)</span> </td>
                <td class="col-2 text-end">{{ ReformatNumber(item.targetBudget / projectSize) }}</td>
                <td class="col-2 text-end">{{ ReformatNumber(item.targetBudget) }}</td>
                <td class="col-4 text-end"></td>
            </tr>
            <tr  class="fw-bold text-end " v-else> 
                <td class="col-2"></td>
                <td class="text-end text-capitalize col-2">{{ $t('Sub Total Of') }} {{item.name}} </td>
                <td class="col-2 text-end">{{ projectSize != 0 ? ReformatNumber(item.data.overAllTotal?.sellTotal / projectSize) : '' }}</td>
                <td class="col-2 text-end">{{ ReformatNumber(item.data.overAllTotal?.sellTotal) }}</td>
                <td class="col-4 text-end"></td>
            </tr>
        </tbody>
    </table>
    <table class="table table-bordered">
        <tbody>
            <tr  class="fw-bold text-end bg-light-primary"> 
                <td class="col-2"></td>
                <td class="text-end col-2">{{ $t('Overall Total') }}</td>
                <td class="col-2 text-end">{{ ReformatNumber(totalAll.perSize) }}</td>
                <td class="col-2 text-end">{{ ReformatNumber(totalAll.total) }}</td>
                <td class="col-4 text-end"></td>
            </tr>
            <tr  class="fw-bold text-end"> 
                <td colspan="4" class="text-end">{{$t('Approximate Area') }} :  {{ projectSize }}{{ unitOfMeasure }}</td>
                <td class="col-4 text-end"></td>
            </tr>
        </tbody>
    </table>
</template>
<style scoped>
@media print{
    .table {
        font-size: 8px !important;
    }
}
</style>
<script>
import HleQuotationSectionRows from '@/components/boq/HleQuotationSectionRows.vue'
import {ReformatNumber} from '@/helpers/utilities.js' 
export default {
    name : 'BoqQuotationBoqSummary',
    props : {
        isRevision : {
            type : Boolean,
            default : false
        },
        isReadOnly : {
            type : Boolean,
            default : false
        },
        dataRow : {
            type : Array,
            default : ()=>[]
        },
        sectionScopeOfWorkList : {
            type : Array,
            default : ()=>[]
        },
        OpenSectionScopeOfWork : {
            type : Function,
            default : ()=>{}
        },
        OpenRemoveBoQ : {
            type : Function,
            default : ()=>{}
        },
        unitOfMeasure : {
            type : String,
            default : ''
        },
        projectSize : {
            type :Number,
            default : 0
        },
        currencySign : {
            type : String,
            default : ''
        },
        projectId : {
            type : String,
            default : ''
        }

    },
    components : {
        HleQuotationSectionRows
    },
    methods : {
        ReformatNumber
    },
    data(){
        return {
            
        }
    },
    computed : {
        totalAll(){

            const self = this
            const data = self.dataRow

            let totals =  {
                    total : 0,
                    perSize :0
                }

            if(data.length==0){
                return   totals
            }

            for(let x = 0; x< data.length;x++){
                const getData = data[x].data.overAllTotal
                if(getData.sellTotal==0 && data[x].targetBudget){
                    totals.total += data[x].targetBudget
                }
                else{
                    totals.total += getData.sellTotal
                }
            }

            if(self.projectSize){
                totals.perSize = totals.total / self.projectSize
            }
            

            return totals

        },
        ReformatBoqSummary(){
            const self = this

            if(self.dataRow.length==0){
                return []
            }


            let parentLetter = 65
            let totalOverAll = {
                amount : 0,
                amountTotal : 0
            }

            let totalPerCategory= {
                amount : 0,
                amountTotal : 0
            }

            let totalPerSub = {
                amount : 0,
                amountTotal : 0
            }

            

            let reformat = self.dataRow.map((u)=>{

                u.letter = String.fromCharCode(parentLetter++)
                let mainParentId = ''
                let mainParentCount = 1
                let subCountLabel = 1

                const rowCount = u.data.length
                let subCount = 1
                for(let x = 0; x < rowCount; x++){
                    const getData = u.data[x]
                    if(getData.parents[0].costCodeId!=mainParentId){
                        mainParentId = getData.parents[0].costCodeId
                        subCountLabel = (mainParentCount++)
                        getData.mainCateg = {
                            label : u.letter + subCountLabel,
                            name : getData.parents[0].name
                        }
                        subCount= 1
                    }

                    getData.sellRate =getData.sellTotal / (self.projectSize || 0) 

                    
                    totalPerSub.amount += getData.sellRate
                    totalPerSub.amountTotal += getData.sellTotal
                    
                    totalPerCategory.amount += getData.sellRate
                    totalPerCategory.amountTotal += getData.sellTotal


                    getData.label = u.letter +  subCountLabel + '.' + (subCount++)
                    getData.sellRate = ReformatNumber(u.data[x].sellRate)
                    getData.sellTotal = ReformatNumber(u.data[x].sellTotal)

                    if(x == rowCount - 1){
                        getData.totalCategory = {
                            label : getData.parents[0].name,
                            amount :  ReformatNumber(totalPerCategory.amount),
                            amountTotal :  ReformatNumber(totalPerCategory.amountTotal)
                        }
                        
                        totalPerCategory= {
                            amount : 0,
                            amountTotal : 0
                        }
                    }
                    else if (getData.parents[0].costCodeId!=u.data[x + 1].parents[0].costCodeId){
                        getData.totalCategory = {
                            label : getData.parents[0].name,
                            amount :  ReformatNumber(totalPerCategory.amount),
                            amountTotal :  ReformatNumber(totalPerCategory.amountTotal)
                        }
                        
                        totalPerCategory= {
                            amount : 0,
                            amountTotal : 0
                        }
                    }


                }

                
                totalOverAll.amount += totalPerSub.amount
                totalOverAll.amountTotal += totalPerSub.amountTotal

                u.subTotal = {
                    label : u.name,
                    amount :  ReformatNumber(totalPerSub.amount),
                    amountTotal :  ReformatNumber(totalPerSub.amountTotal)
                }


                totalPerSub = {
                    amount : 0,
                    amountTotal : 0
                }
                return u
            })

            reformat[self.dataRow.length - 1].totalOverAll = {
                amount : ReformatNumber(totalOverAll.amount),
                amountTotal : ReformatNumber(totalOverAll.amountTotal),
            }

            return {
                data : reformat,
                total : totalOverAll
            }
        }
    }
    
}
</script>