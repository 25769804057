<template>
    <div>
        <BreadcrumbsProject
        :breadcrumbs="[
            {
                link : '/high-level-estimates',
                title : $t('Bidding'),
                isRouterLink: true
            },
            {
                link : '/high-level-estimates/' + hleProjectId + '/bill-of-quantities',
                title : (hleProject.projectName || '-' )+ ' - ' + $t('Bidding Module.High Level Estimates'),
                isRouterLink: true
            },
            {
                link : '/high-level-estimates/' + hleProjectId + '/bill-of-quantities-builder/' + boqId,
                title :boqUrlname,
                isRouterLink: true
            },
            {
                link : '/high-level-estimates/' + hleProjectId + '/bill-of-quantities-builder/' + boqId + '/Unassigneds',
                title : parentObj.name,
                isRouterLink: true
            },
        ]"
        />
    </div>

  
    <div class="">
      <div class="card">
        <div class="card-header bg-white">
          <div class="d-flex justify-content-between mb-1">
            <div class="">
              <h6 class="fw-bold text-capitalize mb-0" >{{ parentObj.name }}</h6>
            </div>
              
            <span :class="'badge pt-2 ' + ApprovalStageBoqObj[boqItemData.status -1].color">
              {{ApprovalStageBoqObj[boqItemData.status-1].name}}
            </span>
          </div>
        </div>
        <div class="card-body" >
          
          <div class="d-flex justify-content-between  mb-2">
              <div class="btn-group">
                <button class="btn btn-default"
                @click="()=>{ OpenFilter = !OpenFilter}"
                >
                  <i class="fa-solid fa-filter"></i> {{$t('Filter')}}
                </button>
                <button class="btn btn-default "
                data-bs-toggle="modal" data-bs-target="#BoqColumnFilter"
                >
                  <i class="fas fa-eye-slash"></i>  {{ $t('Columns') }}
                </button>
              </div>
              <div >
                <div class="btn-group me-1" v-if="haveChanges" >
                  <button class=" btn btn-default text-black" @click="CancelSaveSequence"><i class="fa-solid fa-ban"></i> {{$t('Cancel')}}</button>
                  <button class=" btn btn-outline-success " @click="SaveSequences"><i class="fa-solid fa-list-ol"></i> {{ $t('Save Sequence') }}</button>
                </div>
                <div class="btn-group" v-else-if="!haveChanges && !IsQuotationPublished">
                  <button class="btn btn-primary "
                  data-bs-toggle="modal" data-bs-target="#BoqMoveLineItemsModal"
                  v-if=" selectedToBeMoved.length!=0 "
                  ><i class="fa-solid fa-link"></i> {{ $t('Move Line Items') }}</button>

                  <button class="btn btn-primary "
                  @click="OpenAddItemModal"
                  data-bs-toggle="modal" data-bs-target="#ModalNewLineItem"
                  v-if="selectedToBeMoved.length==0 "
                  ><i class="fa-solid fa-plus"></i> {{$t('Add Line Item')}}</button>

                  <ButtonTemplateDownloader />

                  <button class="btn btn-default "
                    data-bs-toggle="modal" data-bs-target="#ModalImportBoq"
                    @click="OpenImportModal"
                    title="Import File"><i class="fa-solid fa-file-import"></i> {{$t('Import')}}  </button>
                </div>
                
                
            </div>
          </div>
          
          <div class="row">
            <div class="col-md-6 pb-2" v-if="OpenFilter">
              <div class="form-group ">
                <label class="d-flex justify-content-between">
                    <a>{{ $t('Cost Category') }}</a>
                    <span role="button" class="text-danger text-decoration-underline" @click="ClearField(2)"><i class="fa-solid fa-xmark "></i> {{$t('Clear Field')}}</span>
                </label>
                <Multiselect 
                :multiple="true" :options="dataCostCodes" 
                :internalSearch="false"
                :placeholder="$t('Search Here')"
                v-model="selectedCostCode" :loading="searchCostCodeLoading" :max-height="300"
                label="costName" track-by="_id"/>
              </div>
            </div>
            <div class="col-md-6 pb-2" v-if="OpenFilter">
              <div class="form-group ">
                <label class="d-flex justify-content-between">
                    <a>{{ $t('Space ID') }}</a>
                    <span role="button" class="text-danger text-decoration-underline" @click="ClearField(1)"><i class="fa-solid fa-xmark "></i> {{$t('Clear Field')}}</span>
                </label>
                <Multiselect 
                :multiple="true" :options="areaList" 
                :placeholder="$t('Search Here')"
                v-model="selectedArea"  :max-height="300" />
              </div>
            </div>
            <div class="col-md-12 d-flex justify-content-center fw-light">
                <FigureCurrencyNote :currency="boqItemData.currencyId" />
            </div>
            <div class="col-md-12">
  
              <Loader v-if="tableLoading" />
              <div v-else-if="dataReady">
                <BoqItemTableBreakdown ref="tableBreakdown" :rowDatas="rowDatas" :reloadData="ReloadList" :stage="boqItemData.status" :customPermission="[17,18]" 
                :OpenEditModal="OpenEditLineItem" :SyncSelectedLineItem="SyncSelectedLineItem" :IsQuotationPublished="IsQuotationPublished" :DeleteItemFunc="DeleteHighLevelBoqLineItem"
                :measurementUnits="measurementUnits" :selectedColumns="selectedColumns" :updateSelected="UpdateSelected" :BoqCategoryList="BoqCategoryList" :GetChanges="GetChanges" :GetSequence="GetSequence" />
              </div>
  
            </div>
          </div>
          
         
        </div>
        
      </div>
    </div>
    <Modal idModal="BoqExportBoqList" headerTitle="Export BoQ"  >
        <div>
            <div class="form-group">
                <label class="required-style">Template </label>
                <select class="form-select" v-model="exportTemplateType"> 
                    <option v-for="(item,key) in ExportTemplatesForExport" :key="key" :value="key">{{item}}</option>
                </select>
            </div>
        </div>
        <template v-slot:actions>
            <div>
            </div>
            <button type="button" class="btn btn-primary" @click="ExportBoqList" >
                <i class="fa-solid fa-file-arrow-down"></i>  Export
            </button>
        </template>
    </Modal>
    <ModalLineItemColumnFilter :UpdateSelectedColumn="ChangeSelectecColumnValue"/>
  
    <ModalNewLineItem
    :isHighLevelEstimate="true" v-if="boqItemData._id!=1"
    ref="modalNewLineItem" :officeId="boqItemData.officeId" :AddLineItemFunc="AddHighLevelBoqLineItem"
    :boqList="[]" :ResetData="GetDataList" :boqId="boqItemData._id" :parentId="parentId" />
  
    <Modal idModal="ModalImportBoq" :headerTitle="$t('Import')"  >
        <div>
            <div class="form-group">
                <label class="required-style">{{ $t('File') }} </label>
                <input type="file" class="form-control" ref="uploadFile" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"  />
        
                <small class="text-warning" v-if="isImportClicked && !newBoqImport.file">{{ $t('Required') }}</small>   
            </div>
            <div class="form-group">
                <label class="required-style">{{ $t('Template') }} </label>
                <select class="form-select" v-model="newBoqImport.importTemplateType">  
                    <option value="0">{{ $t('Please Select') }}</option>
                    <option value="1">{{ $t('System Template') }}</option>
                </select>
                <div class="text-end">
                    <a href="#" class="small" role="button" @click="DownloadTemplate">{{ $t('Download selected template') }}</a>
                </div>
                <small class="text-warning" v-if="isImportClicked && !newBoqImport.importTemplateType">{{ $t('Required') }}</small>   
            </div>
            
        </div>
        <template v-slot:actions>
            <button type="button" ref="closeModalImportBoq" class="btn btn-outline-danger" data-bs-dismiss="modal">X {{ $t('Cancel') }}</button>
            <button type="button" class="btn btn-primary" @click="ImportBoqList" > 
                <i class="fa-solid fa-file-arrow-down"></i>  {{$t('Import')}}
            </button>
        </template>
    </Modal>
  
    <ModalUpdateLineItem
    :selectedLineItem="updateLineItem" v-if="boqItemData._id!=1"
    :UpdateLineItemFunc="UpdateHighLevelBoqLineItem"  :boqId="boqItemData._id" 
    :boqList="[]" :ResetData="GetDataList" :isHighLevelEstimate="true" />
    <Modal idModal="BoqMoveLineItemsModal" headerTitle="Move to Another Section" modalSize="modal-md" >
        <div class="form-group">
            <label>{{ $t('Main Section') }}</label>
            <select class="form-select" v-model="selectedFirstSection" @change="ChangeSection(1)">
                <option value="">{{ $t('Please Select') }}</option>
                <option :value="item._id" v-for="(item,key) in firstSections" class="text-capitalize" :key="key"> {{ item.name }}</option>
            </select>
        </div>
        <div class="form-group" v-if="secondSections.length!=0">
            <label>{{ $t('Sub Section') }}</label>
            <select class="form-select" v-model="selectedSecondSection" @change="ChangeSection(2)">
                <option value="">{{ $t('Please Select') }}</option>
                <option :value="item._id" v-for="(item,key) in secondSections" class="text-capitalize" :key="key"> {{ item.name }}</option>
            </select>
        </div>
        <div class="form-group" v-if="thirdSections.length!=0">
            <label>{{ $t('Sub Section') }}</label>
            <select class="form-select" v-model="selectedThirdSection">
                <option value="">{{ $t('Please Select') }}</option>
                <option :value="item._id" v-for="(item,key) in thirdSections" class="text-capitalize" :key="key"> {{ item.name }}</option>
            </select>
        </div>
        <div class="form-group">
            <label>{{ $t('Selected Line Items To be Moved') }}</label>
            <input class="form-control-plaintext text-capitalize" readonly :value="selectedToBeMoved.length"/>
            <small class="text-warning" v-if="selectedToBeMoved.length==0">{{ $t('No Selected Line Items') }}</small>
        </div>
        <template v-slot:actions>
            <button type="button" ref="closeBoqMoveLineItemsModal" class="btn btn-outline-danger" data-bs-dismiss="modal">X {{ $t('Cancel') }}</button>
            <button type="button" class="btn btn-primary" v-if="selectedToBeMoved.length!=0" @click="MoveToSection"><i class="fa-solid fa-link"></i> {{ $t('Move') }}</button>
        </template>
    </Modal>
  </template>
  
<script>
import Modal from '@/components/layout/Modal.vue'
import Loader from '@/components/layout/Loader.vue'
import BoqItemTableBreakdown from '@/components/boq/BoqItemTableBreakdown.vue'
import ModalUpdateLineItem from '@/components/boq/ModalUpdateLineItem.vue'
import ModalLineItemColumnFilter from '@/components/boq/ModalLineItemColumnFilter.vue'
import ButtonTemplateDownloader from '@/components/boq/ButtonTemplateDownloader.vue'
import ModalNewLineItem from '@/components/boq/ModalNewLineItem.vue'
import {NumberFormatterWidget, LinkList, ApprovalStageBoq ,ReformatDate, ExportTemplates, ApprovalStageBoqObj, GetDistinctValues, GetDistinctValuesObject} from '@/helpers/utilities.js'
import  {mapActions,mapGetters} from 'vuex'
import BreadcrumbsProject  from '@/components/projectInformation/BreadcrumbsProject.vue'
import 'dotenv/config'
import {GetCostCodes,DownloadBoqExport, GetHighLevelBoqLineItemsByBits, UpdateHleNewBoqCreatorSectionBreakdownSequence, GetHleNewBoqCreatorSectionItem,
   ExportBoqCostXLayout, ExportBoqFiveDLayout, ExportBoqRolledUpLayout, BoQItemExportForInternal, GetHleNewBoqCreatorSectionsRaw,
   AddHighLevelBoqLineItem, UpdateHighLevelBoqLineItem,DeleteHighLevelBoqLineItem, GetHighLevelBoq, GetHighLevelProject,
   AddHleUnassignedToNewBoqCreatorSection, ImportHighLevelBoqLineItems} from '@/actions/boqActions.js'
import {measurementUnits} from '@/assets/dataJson/commercial_management.json'
import {SearchAllActiveCostCodes} from '@/actions/projectActions.js'
import FigureCurrencyNote from '@/components/layout/FigureCurrencyNote.vue'
export default {
  name: 'BoqBuilderBreakdown',
  components: {
    BreadcrumbsProject,
    Modal,
    BoqItemTableBreakdown,
    Loader,
    ModalUpdateLineItem,
    ModalNewLineItem,
    ModalLineItemColumnFilter,
    ButtonTemplateDownloader,
    FigureCurrencyNote
  },
  methods:{
    ...mapActions(['ChangeActiveLink']),
    AddHighLevelBoqLineItem : AddHighLevelBoqLineItem,
    UpdateHighLevelBoqLineItem : UpdateHighLevelBoqLineItem,
    DeleteHighLevelBoqLineItem : DeleteHighLevelBoqLineItem,
    ReformatDate : ReformatDate,
    SyncSelectedLineItem(data){
      this.selectedToBeMoved = data
    },
    async MoveToSection(){
      const self  = this
      if(self.isOnProcess){
          return
      }  

      const selected = self.selectedThirdSection ? self.selectedThirdSection : self.selectedSecondSection

      const swalTitle = self.$i18n.t('Move to Section')

      if(self.selectedToBeMoved.length==0){
          self.$swal(swalTitle, self.$i18n.t('No line item selected'), 'warning')
      }

      const result = await AddHleUnassignedToNewBoqCreatorSection({
          boqId : self.boqListParams.id,
          lineItems : self.selectedToBeMoved,
          parentId : selected
      })

      if(result!='OK'){
          self.$swal(swalTitle, result, 'warning')
          return
      }
      self.selectedToBeMoved = []

      self.$swal(swalTitle, self.$i18n.t('Line Items Moved Successfully'), 'success')
      setTimeout(function(){
        location.reload()
      },700)
    },
    ChangeSection(level){
        const self = this
        if(level==1){
            const selected = self.selectedFirstSection

            self.secondSections = self.rawSections.filter(u=>u.parentId==selected)
            self.thirdSections = []

            self.selectedSecondSection = ''
            self.selectedThirdSection = ''
        }else if(level==2){
            const selected = self.selectedSecondSection

            self.thirdSections = self.rawSections.filter(u=>u.parentId==selected)

            self.selectedThirdSection = ''
        }
    },
    CancelSaveSequence(){
      window.location.reload()
    },
    SaveSequences(){
      const self = this
      self.$refs.tableBreakdown.PassData()
    },
    async GetSequence(data){

      const self = this
      self.rowDataSequence = data

      let toBeSaved = []

      for(let x =0; x<data.length;x++){
        const getData = data[x]
        if(getData.sequenceStack!=x){
          toBeSaved.push({
            id : getData._id,
            sequence : x
          })
        }
      }

      if(toBeSaved.length==0){
        return
      }

      const result = await UpdateHleNewBoqCreatorSectionBreakdownSequence({
        boqId : self.boqListParams.id,
        parentId : self.parentId,
        lineItems : toBeSaved,
      }) 
      const swalTitle = self.$i18n.t('Save Sequence')
      if(result=='OK'){
        self.$swal(swalTitle, self.$i18n.t('Sequence Saved successfully, please wait Page is Reloading'),'success')
        setTimeout(function(){ location.reload() },1000)
      }else{
        self.$swal(swalTitle, result,'warning')
      }
    },
    GetChanges(){
      this.haveChanges = true
    },
    timerSetter(callback){
        const self = this
        if(self.timer){
            clearTimeout(self.timer)
            self.timer = null
        }
        self.timer = setTimeout(callback,700)
    },
    timerSetterSearch(callback){
      const self = this
      
      if(self.timerSearch){
          clearTimeout(self.timerSearch)
          self.timerSearch = null
      }

      self.timerSearch = setTimeout(callback,700)
    },
    GetCostCodeRun(search){
      return GetCostCodes(search,this.newTabData.parent)
    },
    formatNumber(value,sign= ""){
      return NumberFormatterWidget(value,sign)
    },
    
    SendForNewWorkFlow(){
      this.$router.push({name : 'BoqReviewerSetup', query : { boqId : self.boqId} })
    },
    ChangeView(val){
      this.filterView = val
    },
    async ReloadList(){
      const self = this
      self.tableLoading = true
      self.rowDatas = []
      self.boqListParams.skip = 0
      await self.GetListByBites()
      self.tableLoading = false
      
    },
    OpenEditModal(data){
      this.updateMasterItem = Object.assign({},data)
      this.updateMasterItem.costName = data.costCode + ' ' + data.name 
      this.updateMasterItem.subCateg = data.parents[1].name
      this.updateMasterItem.mainCateg = data.parents[0].name
    },
    async GetDataList(){
      const self= this
      self.tableLoading = true
      await self.GetListByBites()
      self.tableLoading = false
    },
    async GetListByBites(){
      const self = this

      self.rowDatas = []
      let willFetch = true
      self.boqListParams.skip = 0
      self.dataReady = false
      while(willFetch){
          const bits = await GetHighLevelBoqLineItemsByBits(self.boqListParams)
          if(bits.length!=0){
              self.rowDatas.push(...bits)
          }
          if(bits.length==self.boqListParams.take){
              self.boqListParams.skip += 50
          }else{
              willFetch = false
          }
      }

      self.dataReady = true
    },
    OpenEditLineItem(data){
      this.updateLineItem = JSON.parse(JSON.stringify(data))
      this.updateLineItem.id = data._id
      this.updateLineItem.markUp = (this.updateLineItem.markUp * 100).toFixed(2)
    },
    OpenAddItemModal(){
      this.$refs.modalNewLineItem.ClearData()
    },
    ChangeSelectecColumnValue(value){
      this.selectedColumns = value
    },
    ExportBoqList(e){
      const self = this

      if(self.isExportRunning){
        return
      }

      const btn = e.target
      btn.disabled = true
      const boqId = self.boqId
      self.isExportRunning = true
      if(self.exportTemplateType===0){
        DownloadBoqExport(boqId, this.boqItemData.name +' export').then(()=>{
          btn.disabled = false
          self.isExportRunning = false
        })
      }else if(self.exportTemplateType===1){
        ExportBoqFiveDLayout(boqId, this.boqItemData.name + ' FiveD-export').then(()=>{
          btn.disabled = false
          self.isExportRunning = false
        })
      }else if(self.exportTemplateType===2){
        ExportBoqCostXLayout(boqId, this.boqItemData.name  + ' costX-export').then(()=>{
          btn.disabled = false
          self.isExportRunning = false
        })
      }else if(self.exportTemplateType===3){
        BoQItemExportForInternal(boqId, this.boqItemData.name  + ' costX-export').then(()=>{
          btn.disabled = false
          self.isExportRunning = false
        })
      }
      
      else{
          self.$swal(self.$i18n.t('Export Template'),self.$i18n.t('Sorry not yet implemented'),'warning')
          btn.disabled = false
          self.isExportRunning = false
      }
      
    },
    ExportForClient(e){
      const self = this
      const btn = e.target
      const boqId = self.$route.params.id
      btn.disabled = true
      ExportBoqRolledUpLayout(boqId, self.boqItemData.name + ' export').then(()=>{
        btn.disabled = false
      })
    },
    UpdateSelected(data){
      this.selectedLineItems = data
    },
    SearchCostCodeAll(query){
      const self =  this
      self.searchCostCodeLoading = true
      self.timerSetter(function(){
          self.searchCostCodeLoading = false
          SearchAllActiveCostCodes(query).then((res)=>{
              self.dataCostCodes = res
          })
      })
    },
    ClearField(type){
      if(type==1){
          this.selectedArea = []
      }
      else{
          this.selectedCostCode = []
      }
    },
    OpenImportModal(){
        this.newBoqImport = {
            importTemplateType : 1,
        }
        this.$refs.uploadFile.value = ''
    },
    async ImportBoqList(e){
        let data = new FormData()
        const self = this

        if(self.isImportRunning){
            return
        }

        const file = self.$refs.uploadFile.files[0]
        const newData = self.newBoqImport
        const btn = e.target

        self.isImportClicked = true
        newData.file = ''
        if(!file ){
            return
        }
        newData.file = 'new file'

        if(newData.importTemplateType===''){
            return
        }

        data.append('id',self.boqItemData._id)
        data.append('type',newData.importTemplateType)
        data.append('template',file)
        data.append('parentId',self.parentId)

        self.isImportRunning = true
        self.isImportClicked = false
        btn.disabled = true

        const swalTitle = self.$i18n.t('Import') 
        if(newData.importTemplateType==1){
            const uploaded = await ImportHighLevelBoqLineItems(data)
            self.isImportRunning = false
            btn.disabled = false
            if(uploaded=='OK'){
                await self.GetDataList()
                self.$refs.closeModalImportBoq.click()
            }
            else{
                self.$swal(swalTitle,uploaded,'warning')
            }
        }else{
            self.isImportRunning = false
            btn.disabled = false
            this.$swal(swalTitle, self.$i18n.t('Sorry not yet implemented'),'warning')
        }
    },
  },
  watch: {
    selectedCostCode(val) {
        const self = this
        self.boqListParams.costCode = encodeURIComponent(JSON.stringify(val.map(u=>u._id)))
        self.boqListParams.area = encodeURIComponent(JSON.stringify(self.selectedArea))
        self.timerSetterSearch(async function(){
          self.rowDatas = []
          self.boqListParams.skip = 0
          await self.GetListByBites()
          self.tableLoading = false
        })

        return val.map(u=>u._id)
    },
    selectedArea(val){
      const self = this
      self.boqListParams.costCode = encodeURIComponent(JSON.stringify(self.selectedCostCode))
      self.boqListParams.area = encodeURIComponent(JSON.stringify(val))
      self.timerSetterSearch(async function(){
        self.rowDatas = []
        self.boqListParams.skip = 0
        await self.GetListByBites()
        self.tableLoading = false
      })

      return val
    }
  },
  mounted : async function(){
    const self = this
    self.ChangeActiveLink(LinkList.HIGH_LEVEL_ESTIMATES)
    const boqId = self.$route.params.boqId
    const boqItem = await GetHighLevelBoq(boqId)
    self.ExportTemplatesForExport = [...ExportTemplates]
    self.ExportTemplatesForExport.push(self.$i18n.t('System Template (For Presentation)'))
    if(boqItem==401){
      self.$router.push('/unauthorized')
      return
    }
    self.parentObj = await GetHleNewBoqCreatorSectionItem({id : self.parentId,boqId : boqId})
    self.boqItemData = boqItem
    self.boqUrlname = boqItem.name  + ' - (' + boqItem.currencyId.sign +') '  + boqItem.currencyId.shortName  +' '+ boqItem.currencyId.name
    self.hleProject = await GetHighLevelProject(self.hleProjectId)
    await self.GetListByBites()
    
    const rawSections = await GetHleNewBoqCreatorSectionsRaw(boqItem._id)
    self.rawSections = rawSections.sections
    self.firstSections = rawSections.sections.filter(u=>u.level==1)

    const lineItemCols =  window.localStorage.getItem('lineItemColumns')
    if(lineItemCols){
        this.selectedColumns = lineItemCols.split(',').map(Number)
    }
    const getLineItemCostCode = 
        self.userInfo.language =='en' ? 
            self.rowDatas.map(u=>{
                return {
                    _id : u.costCodeId,
                    costName : u.costCode + ' ' +u.name
                }
            }) 
        :
            self.rowDatas.map(u=>{
                return {
                    _id : u.costCodeId,
                    costName : u.costCode + ' ' +u.nameCh
                }
            }) 
    self.dataCostCodes = GetDistinctValuesObject(getLineItemCostCode)
    self.areaList = GetDistinctValues(self.rowDatas.map(u=>u.area).filter(value => value !== null && value !== undefined && value !== ''))
    self.areaList.push(self.$i18n.t('NO SPACE ID'))
  },
  computed : {
    ...mapGetters(['userInfo']),
    IsQuotationPublished(){
      return false
    }
  },
  data(){
    return {
        isImportRunning : false,
        isImportClicked : false,
        newBoqImport: {
            importTemplateType : 0
        },
        boqId :  this.$route.params.boqId ,
        boqUrlname : '',
        hleProject : {},
        hleProjectId : this.$route.params.id ,
        selectedToBeMoved : [],
        rawSections : [],
        firstSections : [],
        secondSections : [],
        thirdSections : [],
        selectedFirstSection : '',
        selectedSecondSection : '',
        selectedThirdSection : '',
        rowDataSequence : [],
        ApprovalStageBoqObj : ApprovalStageBoqObj,
        OpenFilter : false,
        boqItemData :{
            _id : '1',
            subCategoryList : [],
            areaList : [],
            status : 1,
            reference : {}
        },
        areaList : [],
        selectedArea : [],
        selectedCostCode : [],
        dataCostCodes : [],
        parentId : this.$route.params.parent,
        parentObj : {},
        boqListParams : {
            id : this.$route.params.boqId,
            parentId : this.$route.params.parent,
            costCode : '',
            area : '',
            page : 1,
            take : 50,
            skip : 0
        },
        isExportRunning : false,
        timer : null,
        timerSearch : null,
        rowDatas:[],
        BoqCategoryList : [],
        dataReady : false,
        tableLoading : false,
        projectUrl : process.env.VUE_APP_PROJECT_URL,
        boqUrl : process.env.VUE_APP_BOQ_URL,
        filterView : 1,
        ApprovalStageBoq : ApprovalStageBoq,
        measurementUnits : measurementUnits,
        boqList : [],
        updateLineItem : {
            unit : '',
            parents : [
                {
                    name : ''
                },
                {
                    name : ''
                }
            ]
        },
        selectedColumns : [],
        ExportTemplates : ExportTemplates,
        ExportTemplatesForExport : [],
        exportTemplateType : 0,
        exportTemplateFilter : null,
        selectedLineItems : [],
        selectedParent : "",
        searchCostCodeLoading : false,
        movedLineItems : [],
        haveChanges : false,
        optionsSection : []
    }
  },
}
</script>