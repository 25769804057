import {mapGetters, mapActions} from 'vuex'
import {ReformatDate, LinkList, ProcessItemStatusList, ProcessStepType, ReformatNumber, ProcessAction, ProcessActionTypes} from '@/helpers/utilities.js'
import 'dotenv/config'
import BreadcrumbsProject  from '@/components/projectInformation/BreadcrumbsProject.vue'
import ProcessCommentList  from '@/components/projectInformation/ProcessCommentList.vue'
import BoqLineItemTable  from '@/components/boq/BoqLineItemTable.vue'
import BoqSectionTableReadOnly  from '@/components/boq/BoqSectionTableReadOnly.vue'
import Loader  from '@/components/layout/Loader.vue'
import Pagination  from '@/components/layout/Pagination.vue'
import {GetBoqReviewListByBits, GetNewBoqCreatorSectionsRevisions} from '@/actions/boqActions.js'
import { GetCommentsList, UserApproveWorkFlow, UserDisapproveWorkFlow, GetWorkflowItem} from '@/actions/projectActions.js'
import {GetAccessOnProject} from '@/actions/userActions.js'
import ModalLineItemColumnFilter from '@/components/boq/ModalLineItemColumnFilter.vue'
import Multiselect from 'vue-multiselect'
import {SearchAllActiveCostCodes} from '@/actions/projectActions.js'
import BoqProcessWorkFlow  from '@/components/projectInformation/BoqProcessWorkFlow.vue'
import Modal  from '@/components/layout/Modal.vue'
import FigureCurrencyNote from '@/components/layout/FigureCurrencyNote.vue'
export default {
    name : 'BoqHistory',
    mounted : async function(){
        const self = this
        self.ChangeActiveLink(LinkList.APPROVAL_LIST)
        // await ReviewItemMakerView(self.$route.params.id).catch()
        const processItem = await GetWorkflowItem(self.$route.params.id).catch()
        self.processItemData = processItem
        const boqItem = await self.GetBoqItemRun(processItem.relatedId)
        self.boqItemData = boqItem

        await self.GetProjectInfo(boqItem.projectId).catch()
        
        self.userAccess = await GetAccessOnProject(boqItem.projectId)
        const revision = boqItem.revisionList.find(u=>u._id==processItem.boqRevisionId)
        // self.areaList = await SearchAreaFromProject({id : self.projectInfo._id, search : ''})
        // self.areaList.push('NO SPACE ID')

        self.rawSections = await GetNewBoqCreatorSectionsRevisions(boqItem._id,processItem.boqRevisionId)
        self.boqRevision = {
            date : revision.publishDate,
            dateUtc : revision.publishDateUtc,
            currency : revision.currencyId,
            createdBy : revision.createdBy,
            // areaList : revision.areaList.sort(SortFunc),
            // subCategories : revision.subCategoryList.sort(LevelSort),
            index : boqItem.revisionList.length
        }
        
        
        // const historyList = await GetBoqReviewList(self.boqListParams).catch()
        // self.boqListHistory = historyList
        // self.GetListByBites()
        self.commentFilterParam.id = self.$route.params.id
        self.commentList = await GetCommentsList(self.commentFilterParam).catch()
        // self.SearchCostCodeAll('')
        // const lineItemCols =  window.localStorage.getItem('lineItemColumns')
        // if(lineItemCols){
        //     this.selectedColumns = lineItemCols.split(',').map(Number)
        // }
        

        self.isLoaded = true
    },
    components :{
        BreadcrumbsProject,
        Loader,
        Pagination,
        BoqProcessWorkFlow,
        BoqLineItemTable,
        ModalLineItemColumnFilter,
        ProcessCommentList,
        Multiselect,
        Modal,
        BoqSectionTableReadOnly,
        FigureCurrencyNote
    },
    methods : {
        ...mapActions(['GetProjectInfo', 'GetBoqItemRun', 'ChangeActiveLink']),
        formatNumber(value){
            return ReformatNumber(value)
        },
        timerSetter(callback){
            const self = this
            
            if(self.timer){
                clearTimeout(self.timer)
                self.timer = null
            }

            self.timer = setTimeout(callback,700)
        },
        timerSetterSearch(callback){
            const self = this
            
            if(self.timerSearch){
                clearTimeout(self.timerSearch)
                self.timerSearch = null
            }

            self.timerSearch = setTimeout(callback,700)
        },
        formatDate(value, format = "DD-MMM-YYYY"){
            if(!value){
                return ''
            }
            return ReformatDate(value,format)
        },
        GetProcessItemRun(){
            const self = this
            GetWorkflowItem(self.$route.params.id).then(res=>{
                self.processItemData = res
            })
        },
        ChangeView(val){
            this.filterView = val
        },
        SelectArea(area){
            const self = this
            const selected = self.selectedArea

            const getIndex = selected.indexOf(area)
            if(getIndex==-1){
                if(selected.length>=5){
                    return
                }
                selected.push(area)
            }else{
                selected.splice(getIndex,1)
            }
            
            self.boqListParams.page = 1

            self.boqListParams.costCode = ''
            self.selectedCostCode = []
            self.boqListParams.area = encodeURIComponent(JSON.stringify(selected))


            self.isLoaded = false
            self.timerSetter(function(){
                self.boqListParams.skip = 0
                self.GetListByBites()
                self.isLoaded = true
                // GetBoqReviewList(self.boqListParams).then((res)=>{
                //     self.isLoaded = true
                //     self.boqListHistory = res
                // }).catch()
            })
        },
        SelectCostCode(costCode){
            const self = this

            
            const getIndex = self.selectedCostCode.findIndex(u=>u==costCode)
            if(getIndex==-1){
                self.selectedCostCode.push(costCode)
            }else{
                self.selectedCostCode.splice(getIndex,1)
            }

            self.boqListParams.costCode = encodeURIComponent(JSON.stringify(self.selectedCostCode))
            self.boqListParams.area = ''
            self.boqListParams.page = 1
            

            self.selectedArea = []
            self.isLoaded = false
            self.timerSetter(function(){
                self.boqListParams.skip = 0
                self.GetListByBites()
                self.isLoaded = true
                // GetBoqReviewList(self.boqListParams).then((res)=>{
                //     self.isLoaded = true
                //     self.boqListHistory = res
                // }).catch()
            })
        },
        PageChangeComment(page){
            const self = this
            self.commentFilterParam.page = page
            GetCommentsList(self.commentFilterParam).then((res)=>{
                self.commentList = res
            }).catch()
        },
        Approve(e){
            const self = this
            const btn = e.target


            if(self.isApproveRunning){
                return
            }

            btn.disabled = true
            self.isApproveRunning = true
            UserApproveWorkFlow(self.processItemData._id,self.approveNotes)
            .then((res)=>{
                self.isApproveRunning = false
                btn.disabled = false
                if(res!="OK"){
                    self.$swal(self.$i18n.t('Approval Module.Approve'),res.value,'warning')
                }else{
                    self.$swal({
                        icon: 'success',
                        title: self.$i18n.t('Approved successfully'),
                        showConfirmButton: false,
                        timer : 1000
                    })
                    self.GetProcessItemRun()
                    GetCommentsList(self.commentFilterParam).then((res)=>{
                        self.commentList = res
                    })
                    self.$refs.closeBoqReviewApprove.click()
                }
            })
        },
        Disapprove(e){
            const self = this
            const btn = e.target

            if(self.isDisapproveRunning){
                return
            }

            if(!self.rejectNotes){
                return
            }

            self.isDisapproveRunning = true
            btn.disabled = true
            UserDisapproveWorkFlow(self.processItemData._id, self.rejectNotes)
            .then((res)=>{
                btn.disabled = false
                self.isDisapproveRunning = false
                if(res!="OK"){
                    self.$swal(self.$i18n.t('Approval Module.Ask For Revision'),res.value,'warning')
                }else{
                    self.$swal({
                        icon: 'success',
                        title: self.$i18n.t('Item marked Asked for Revision successfully'),
                        showConfirmButton: false,
                        timer : 1000
                    })
                    self.GetProcessItemRun()
                    GetCommentsList(self.commentFilterParam).then((res)=>{
                        self.commentList = res
                    })
                    self.$refs.closeBoqReviewReject.click()
                }
            })
        },
        // Approve(e){
        //     const self = this
        //     const btn = e.target

        //     self.$swal({
        //         title: 'Approve',
        //         text : 'Proceed approval? Vote cannot be changed.',
        //         showCancelButton: true,
        //         icon: 'warning',
        //         confirmButtonText: 'Approve',
        //         showLoaderOnConfirm : true,
        //         allowOutsideClick: () => !self.$swal.isLoading(),
        //         preConfirm : ()=>{
        //             btn.disabled = true
        //             UserApproveProcess(self.processItemData._id).then((res)=>{
        //                 btn.disabled = false
        //                 if(res=="OK"){
        //                     self.GetProcessItemRun()
        //                 }else{
        //                     self.$swal('Approve',res,'warning')
        //                 }
        //             })
        //         }
        //       }).then((res)=>{
        //         if(res.isConfirmed){
        //           self.$swal('Approved', '', 'success')
        //         }
        //       })

            
        // },
        // Disapprove(e){
        //     const self = this
        //     const btn = e.target


        //     self.$swal({
        //         title: 'Disapprove',
        //         text : 'Proceed Disapproval? Vote cannot be changed',
        //         showCancelButton: true,
        //         icon: 'warning',
        //         confirmButtonText: 'Disapprove',
        //         showLoaderOnConfirm : true,
        //         allowOutsideClick: () => !self.$swal.isLoading(),
        //         preConfirm : ()=>{
        //             btn.disabled = true
        //             UserDisapproveProcess(self.processItemData._id).then((res)=>{
        //                 btn.disabled = false
        //                 if(res=="OK"){
        //                     self.GetProcessItemRun()
        //                 }else{
        //                     self.$swal('Disapprove',res,'warning')
        //                 }
        //             })
        //         }
        //       }).then((res)=>{
        //         if(res.isConfirmed){
        //           self.$swal('Disapproved', '', 'success')
        //         }
        //       })
        // },
        ChangeSelectecColumnValue(value){
            this.selectedColumns = value
        },
        GetListByBites(){
            const self = this
            GetBoqReviewListByBits(self.boqListParams).then((res)=>{
              if(res.length!=0){
                self.boqListHistory.push(...res)
              }
              if(res.length == self.boqListParams.take){
                  self.boqListParams.skip += 50
                  self.GetListByBites()
              }
            })
        },
        SearchCostCodeAll(query){
            const self =  this
            self.searchCostCodeLoading = true
            self.timerSetter(function(){
                self.searchCostCodeLoading = false
                SearchAllActiveCostCodes(query).then((res)=>{
                    self.dataCostCodes = res
                })
            })
        },
        ClearField(type){
            if(type==1){
                this.selectedArea = []
            }
            else{
                this.selectedCostCode = []
            }
        }
    },
    watch: {
        selectedCostCode(val) {
            const self = this
            self.boqListParams.skip = 0
            self.boqListParams.costCode = encodeURIComponent(JSON.stringify(val.map(u=>u._id)))
            self.boqListParams.area = encodeURIComponent(JSON.stringify(self.selectedArea))
            
            self.isLoaded = false
            self.timerSetterSearch(function(){
                self.boqListHistory = []
                self.GetListByBites()
                self.isLoaded = true
            })
    
            return val.map(u=>u._id)
        },
        selectedArea(val){
            const self = this
            self.boqListParams.skip = 0
            self.boqListParams.costCode = encodeURIComponent(JSON.stringify(self.selectedCostCode))
            self.boqListParams.area = encodeURIComponent(JSON.stringify(val))
            
            self.isLoaded = false
            self.timerSetterSearch(function(){
                self.boqListHistory = []
                self.GetListByBites()
                self.isLoaded = true
            })
    
          return val
        }
    },
    data(){
        return{
            rawSections : {
                sectionList : [],
                overAllTotal :{}
            } ,
            ProcessAction :ProcessAction ,
            boqListHistory : [],
            commentList : {
                docs : [],
                hasNextPage : false
            },
            selectedArea : [],
            areaList : [],
            selectedCostCode : [],
            dataCostCodes : [],
            boqListParams : {
                page : 1,
                id : this.$route.params.id,
                costCode : '',
                area : '',
                skip : 0,
                take : 50
            },
            commentFilterParam : {
                page : 1,
                id : this.$route.params.id,
            },
            boqRevision : {
                _id : 1,
                createdBy : {

                },
                currency : {},
                subCategories : [],
                areaList : [],
            },
            isLoaded : false,
            clicked :[],
            projectUrl : process.env.VUE_APP_PROJECT_URL,
            boqUrl : process.env.VUE_APP_BOQ_URL,
            page : 1,
            boqItemData : {
                revisionList : []
            },
            processItemData : {
                status : 1,
                internalApprovers : [],
            },
            filterView : 1,
            timer : null,
            timerSearch : null,
            ProcessItemStatusList : ProcessItemStatusList,
            comment : '',
            ProcessStepType : ProcessStepType,
            currentStep : {
                action : 1,
                roleId : { _id : 1}
            },
            userAccess : {
                userId : '',
                roleId : {
                    _id : ''
                }
            },
            OpenFilter : false,
            selectedColumns : [],
            searchCostCodeLoading : false,
            rejectNotes : '',
            approveNotes : '',
            isApproveRunning : false,
            isDisapproveRunning : false,
        }
    },
    computed : {
        ...mapGetters(['projectInfo','userInfo']),
        CanApprove(){
            const self = this
            const approvers = self.processItemData.internalApprovers

            if(approvers.findIndex(u=>u.relatedUserId._id==self.userInfo._id && u.action==ProcessActionTypes.PENDING&& u.actionType==2) >-1){
                return true
            }

            return false
        },
    }

}