<template>
    <Modal idModal="ModalImportBoq" :headerTitle="$t('Import')" modalSize="modal-md">
            <div class="form-group" >
                <label class="required-style">
                    {{$t('File')}}
                </label>
                <input type="file" class="form-control" ref="uploadFile"  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"   />
                <small class="text-warning" v-if="!newBoqImport.file&&isImportClicked">Required <br /></small>
            </div>
            <div class="form-group" v-if="projectId">
                <label class="required-style">{{ $t('Template') }} </label>
                <select class="form-select" v-model="newBoqImport.templateType">
                    <option value="">{{$t('Please Select')}}</option>
                    <option v-for="(item,key) in ExportTemplates" :key="key" :value="key">{{item}}</option>
                </select>
                <small class="text-warning" v-if="newBoqImport.templateType===''&&isImportClicked">{{ $t('Required') }}</small>
                <div class="text-end">
                    <a href="#" class="small" role="button" @click="DownloadTemplate">{{$t('Download selected template')}}</a>
                </div>
            </div>
            <!-- <div class="form-group">
                <label > Rate Template </label>
                <select class="form-select text-capitalize" v-model="newBoqImport.templateRate">
                    <option value="">Please Select</option>
                    <option v-for="(item,key) in rateTemplates" :key="key" :value="item._id" >{{item.name}}</option>
                </select>
            </div> -->
            
            <div v-if="typeof boqId ==='undefined'">
                <div class="form-group">
                    <label >Existing BoQ? </label>
                    <select class="form-select" v-model="newBoqImport.existing" @change="ChangeType">
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                    </select>
                </div>
                <div v-if="newBoqImport.existing=='false'">
                    <div class="form-group">
                        <label class="required-style">Bill of Quantities Name </label>
                        <input type="text" class="form-control" v-model="newBoqImport.name" placeholder="BoQ Name" />
                        <small class="text-warning" v-if="!newBoqImport.name&&isImportClicked">{{ $t('Required') }}</small>
                    </div>
                    <div class="form-group">
                        <label class="required-style">Reference </label>
                        <Select2
                        :filterQuery="()=>{}"
                        indexValue="_id"
                        labelName="fullname"
                        placeHolder="Search Quotation here"
                        v-model:optionValue="newBoqImport.reference"
                        ref="selectQuotationList"
                        :dataOptions="quotationList"
                        :willCapitalize="false"
                        />
                        <small class="text-warning" v-if="!newBoqImport.reference&&isImportClicked">{{ $t('Required') }}</small>
                    </div>
                </div>
                <div v-else>
                    <div class="form-group">
                        <label class="required-style">Search BOQ </label>
                        <Select2
                        :filterQuery="GetBoqsProjects"
                        indexValue="_id"
                        labelName="name"
                        placeHolder="Search Boq here"
                        :dataOptions="boqList"
                        v-model:optionValue="newBoqImport.boqId"
                        ref="selectBoqList"
                        >
                        </Select2>
                        <small class="text-warning" v-if="!newBoqImport.boqId&&isImportClicked">{{ $t('Required') }}</small>
                    </div>
                
                </div>
            </div>
            
            <template v-slot:actions>
                <button type="button" ref="closeModalImportBoq" class="btn btn-outline-danger" data-bs-dismiss="modal">X {{ $t('Cancel') }}</button>
                <button type="button" class="btn btn-primary" @click="UploadTemplate1"  ><i class="fa-solid fa-save"></i> {{ $t('Import') }}</button>
            </template>
        </Modal>
</template>

<script>
import Modal from '@/components/layout/Modal.vue'
import Select2 from '@/components/layout/Select2.vue'
import { UploadTemplate1ToMasterList, SearchBoqFromProject, DownloadTemplate1, ExportCostXTemplate, ExportFiveDTemplate } from '@/actions/boqActions.js'
import {mapGetters} from 'vuex'
import {ExportTemplates} from '@/helpers/utilities.js'
import {measurementUnits} from '@/assets/dataJson/commercial_management.json'
export default {
    name : 'ModalUpdateLineItem',
    components : {
        Modal, Select2
    },
    props : ['ResetData','boqList', 'boqId','projectId','quotationList','parentId'],
    methods :{
        UploadTemplate1(e){
            let data = new FormData()
            const self = this

            if(self.isImportRunning){
                return
            }
            const file = self.$refs.uploadFile.files[0]
            const newData = self.newBoqImport
            const btn = e.target
            self.isImportClicked = true
            newData.file = ''
            if(!file ){
                return
            }
            newData.file = 'new file'

            if(newData.templateType===''){
                return
            }


            data.append('id',self.projectInfo._id)
            data.append('templateType',newData.templateType)
            data.append('templateRate',newData.templateRate)
            data.append('template1',file)
            if(self.parentId){
                data.append('sequenceParentId',self.parentId)
            }

            if(self.boqId){

                data.append('boqId',self.boqId)
            }else{
                if(newData.existing=='true'){
                    if(!newData.boqId){
                        return
                    }
                    
                    data.append('boqId',newData.boqId)
                }else{
                    if(!newData.name || !newData.reference){
                        return
                    }
                    data.append('name',newData.name)
                    data.append('reference',newData.reference)
                }
            }
            self.isImportRunning = true
            self.isImportClicked = false
            btn.disabled = true
            if(newData.templateType===0 || newData.templateType===1|| newData.templateType===2){
                UploadTemplate1ToMasterList(data).then((res)=>{
                    self.isImportRunning = false
                    btn.disabled = false
                    if(res=='OK'){
                        self.ResetData()
                        self.$refs.closeModalImportBoq.click()
                    }
                    else{
                        self.$swal('Import File',res,'warning')
                    }
                })
            }else{
                btn.disabled = false
                this.$swal('Import Template','Sorry not yet implemented','warning')
            }
            
        },
        GetBoqsProjects(search){
            return SearchBoqFromProject({search : search, id : this.projectInfo._id})
        },
        ClearData(){
            this.$refs.uploadFile.value = '',
            this.newBoqImport =  {
                templateType : '', 
                existing : 'true',
                boqId : '',
                templateRate : ''
            }
        },
        DownloadTemplate(){
            if(this.newBoqImport.templateType===0)
                DownloadTemplate1()
            else if(this.newBoqImport.templateType===1)
                ExportFiveDTemplate()
            else if(this.newBoqImport.templateType===2)
                ExportCostXTemplate()
            else
                this.$swal('Download Template','Sorry not yet implemented','warning')
        }
    },
    mounted : async function(){
        // this.rateTemplates = await GetBoqRateTemplates({id : this.projectId, isSelect : 1})
    },
    computed :{
        ...mapGetters(['projectInfo']),
    },
    data (){ 
        return {
            newBoqImport :  {},
            isImportClicked : false,
            measurementUnits : measurementUnits,
            rateTemplates : [],
            ExportTemplates : ExportTemplates,
            isImportRunning : false,
        }
    }
}
</script>