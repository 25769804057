<template>
    <td class="text-end"  v-for="(item, key) in ColumnNeeded" :key="key" >
        <span v-if="item.value==14" :class="{'text-danger' : data[item.lineItemIndex] < 0 }">
            {{ReformatNumber(data[item.lineItemIndex] * 100,item.minDecimal,item.maxDecimal)}}%
        </span>
        <span v-else :class="{'text-danger' : data[item.lineItemIndex] < 0 }">
            {{ReformatNumber(data[item.lineItemIndex],item.minDecimal,item.maxDecimal)}}
        </span>
    </td>
    <!-- <template v-if="selectedColumns.indexOf(1)>-1">
        <td class="text-end first-cells">{{ReformatNumber(data.quantity,0)}}</td>
        <td class="text-end first-cells">{{ReformatNumber(data.bufferQuantity,0)}}</td>
        <td class="text-end first-cells">{{ReformatNumber(data.totalQuantity,0)}}</td>
    </template>
    <td v-else class="text-end first-cells">{{ReformatNumber(data.totalQuantity,0)}}</td>
    <template v-if="selectedColumns.indexOf(1)>-1">
        <td class="text-end second-cells" >{{ReformatNumber(data.costRate)}}</td>
        <td class="text-end second-cells" >{{ReformatNumber(data.installRate)}}</td>
        <td class="text-end second-cells" >{{ReformatNumber(data.supplyAndInstall)}}</td>
        <td class="text-end second-cells" >{{ReformatNumber(data.supplyBuffer)}}</td>
        <td class="text-end second-cells" >{{ReformatNumber(data.installBuffer)}}</td>
        <td class="text-end second-cells" >{{ReformatNumber(data.bufferCostRate)}}</td>
        <td class="text-end second-cells">{{ReformatNumber(data.costRateTotal)}}</td>
    </template>
    <td v-else class="text-end second-cells">{{ReformatNumber(data.costRateTotal)}}</td>
    <template v-if="selectedColumns.indexOf(2)>-1">
        <td class="text-end first-cells" >{{ReformatNumber(data.supplyAndInstallTotal)}}</td>
        <td class="text-end first-cells">{{ReformatNumber(data.bufferCostTotal)}}</td>
        <td class="text-end first-cells">{{ReformatNumber(data.costTotal)}}</td>
    </template>
    <td v-else class="text-end first-cells">{{ReformatNumber(data.costTotal)}}</td>
    <td class="text-end">{{ReformatNumber(data.markUp * 100,0,2)}}%</td>
    <template v-if="selectedColumns.indexOf(3)>-1">
        <td class="text-end second-cells">{{ReformatNumber(data.sellRateRaw)}}</td>
        <td class="text-end second-cells">{{ReformatNumber(data.bufferSellRate)}}</td>
        <td class="text-end second-cells">{{ReformatNumber(data.sellRate)}}</td>
    </template>
    <td v-else class="text-end second-cells">{{ReformatNumber(data.sellRate)}}</td>
    <template v-if="selectedColumns.indexOf(4)>-1">
        <td class="text-end first-cells">{{ReformatNumber(data.sellTotalRaw)}}</td>
        <td class="text-end first-cells">{{ReformatNumber(data.bufferSellTotal)}}</td>
        <td class="text-end first-cells">{{ReformatNumber(data.sellTotal)}}</td>
    </template>
    <td v-else class="text-end first-cells">{{ReformatNumber(data.sellTotal)}}</td>
    <template v-if="selectedColumns.indexOf(5)>-1">
        <td class="text-end second-cells">{{ReformatNumber(data.gpRaw)}}</td>
        <td class="text-end second-cells">{{ReformatNumber(data.bufferGp)}}</td>
        <td class="text-end second-cells">{{ReformatNumber(data.gp)}}</td>
    </template>
    <td v-else class="text-end second-cells">{{ReformatNumber(data.gp)}}</td> -->
</template>

<script>
import {BoQColumnList} from '@/helpers/utilities.js'
export default {
    name : 'BoqLineItemRow',
    props : ['data','selectedColumns','ReformatNumber'],
    computed: {
        ColumnNeeded(){
            return BoQColumnList.filter(u=>this.selectedColumns.indexOf(u.value) > -1)
        }
    }
}
</script>