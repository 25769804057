<template>
    <div class="bg-white" >
        <!-- <BreadcrumbsProject
        :breadcrumbs="[
            {
                link : '/high-level-estimates',
                title : 'Project Estimates',
                isRouterLink: true
            },
            {
                link : '/high-level-estimates/' + hleProject._id + '/quotation',
                title : (hleProject.projectName || '-') + ' - Budget Estimates',
                isRouterLink: true
            },
            {
                link : '/high-level-estimates/' + hleProject._id + '/quotation/' + quotationId,
                title : quotationItem.name,
                isRouterLink: true
            },
            {
                link : '/high-level-estimates/' + hleProject._id + '/quotation/' + quotationId+ '/detailed-summary',
                title : 'Detailed Summary',
                isRouterLink: true
            },
        ]"
        /> -->
        <div class="row pt-2 d-print-none ">
            <div class="col-md-3 ">
                <router-link :to="backUrl" class="btn btn-default text-black  ms-3"> <i class="fa-solid fa-arrow-left-long"></i> {{ $t('Back To High Level Estimate') }}</router-link>
            </div>
            <div class="col-md-6 text-center">
                <h5 class="fw-bold text-capitalize ">{{quotationItem.name}} {{ $t('Bidding Module.Consolidated Detailed Estimates') }}</h5>
            </div>
            <div class="col-md-3 d-flex justify-content-end">
                <button @click="PrintPage"  class="btn btn-default text-black  me-3"> <i class="fa-solid fa-print"></i> {{ $t('Print') }}</button>
            </div>
        </div>
        <HleHeaderReadOnly :quotationItem="quotationItem" :officeList="officeList" />
        <div class="d-none d-print-block">
            <h6 class="bg-separator">{{ $t('Bidding Module.Consolidated Detailed Estimates') }}</h6>
        </div>
        <table class="table table-primary-bordered">
            <thead class="border-bottom-0">
                <tr class=" ">
                    <th>{{ $t('S/N') }}</th>
                    <th style="min-width:600px;width:600px;" class="">
                        {{ $t('Description') }}
                    </th>
                    <th class="" >{{ $t('Unit') }}</th>
                    <th  style="min-width:100px;width:100px;" class="">{{ $t('Space ID') }}</th>
                    <th class="text-center min-width-100" >{{ $t('Quantity') }}</th>
                    <th class="text-center min-width-100">{{ $t('Rate') }} ({{ currency.sign }})</th>
                    <th class="text-center min-width-100">{{ $t('Total') }} ({{currency.sign}})</th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="boqSections.length==0">
                    <td class="text-center" colspan="7">{{$t('No data Found')}}</td>
                </tr>
                <template v-for="(item,key) in boqSections" :key="key">
                    <tr class="bg-light fw-bold" >
                        <td></td>
                        <td class="fw-bold text-capitalize">{{ item.name }}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <BoqCreatorPrintViewRow :sections="item.data.sections" :IsOpenBook="false" />
                    <tr v-for="(lineItem,lineItemkey) in item.lineItems" :key="lineItemkey">
                        <td>
                            {{ lineItemkey + 1 }}
                        </td>
                        <td>
                            <div >
                                <div class="ps-5" >
                                    <small class="fw-bold text-capitalize"> 
                                        <span v-if="userInfo.language=='en'">{{lineItem.name}}</span>
                                        <span v-else>{{lineItem.nameCh}}</span>
                                    </small> 
                                    <br />
                                    <p class="small mb-0 description-line-item">
                                        {{lineItem.description}}
                                    </p>
                                </div>
                            </div>
                        </td>
                        <td>
                            {{lineItem.unit}}
                        </td>
                        <td>
                            {{lineItem.area}}
                        </td>
                        <td class="text-end">
                            <span v-if="!lineItem.rateOnly">
                                {{ReformatNumber(lineItem.totalQuantity,0,2)}}
                            </span>
                                <span class="text-danger" v-else>
                                {{$t('RATE ONLY')}}
                            </span>
                        </td>
                        <td class="text-end">
                                {{ReformatNumber(lineItem.sellRate)}}
                        </td>
                        <td class="text-end">
                            <span v-if="!lineItem.rateOnly">
                                {{ReformatNumber(lineItem.sellTotal)}}
                            </span>
                                <span class="text-danger" v-else>
                                -
                            </span>
                        </td>
                    </tr>
                    
                    <tr class="bg-light-primary fw-bold" v-if="item.data.sections.length==0">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td class="fw-bold text-capitalize text-end">{{ $t('Sub Total Of') }} {{ item.name }}</td>
                        <td class="text-end">{{ ReformatNumber(item.data.overAllTotal.sellTotal) }}</td>
                    </tr>
                </template>
                <tr class="bg-light-primary fw-bold" >
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="fw-bold text-capitalize text-end">{{ $t('Overall Total') }}</td>
                    <td class="text-end">{{ ReformatNumber(overAllTotal) }}</td>
                </tr>
                
            </tbody>
        </table>
    </div>
</template>
<style scoped>
    @media print {
        .bg-separator{
            font-weight: 600;
            padding-top  : 15px;
            background-color: #ffc000;
            print-color-adjust: exact;
            -webkit-print-color-adjust: exact;
            border-bottom: 1px solid #000;
        }
       
    }
    
</style>

<script>
import {GetHighLevelQuotationSummaryDetailed, GetHighLevelQuotation,GetHighLevelProject, GetHighLevelQuotationBoQDetailed} from '@/actions/boqActions.js'
// import BreadcrumbsProject  from '@/components/projectInformation/BreadcrumbsProject.vue'
import {LinkList, ReformatNumber} from '@/helpers/utilities.js'
import {GetOffices} from '@/actions/userActions.js'
import  {mapActions,mapGetters} from 'vuex'
import HleHeaderReadOnly from '@/components/boq/HleHeaderReadOnly.vue'
import BoqCreatorPrintViewRow from '@/components/boq/BoqCreatorPrintViewRow.vue'
export default {
    name : 'HleQuotationDetailedSummary',
    mounted : async function(){
        const self = this
        self.ChangeActiveLink(LinkList.HIGH_LEVEL_ESTIMATES)
        self.quotationItem = await GetHighLevelQuotation(self.quotationId)
        self.hleProject = await GetHighLevelProject(self.quotationItem.hleProjectId)
        if(!self.hleProject){
            self.$router.push('/unauthorized')
            return
        }
        self.officeList = await GetOffices()
        await self.GetListByBits()
        self.boqSections = await GetHighLevelQuotationBoQDetailed(self.quotationId,self.revisionId)

        let overAllTotal = 0
        const findTheChild = (childs, boqId) =>{
            for(let i = 0; i < childs.length; i++){
                const getChild = childs[i]
                if(getChild.subTotal?.sellTotal!=0 && getChild.level!=1){
                    getChild.lineItems = self.lineItems.filter(u=>u.boqId==boqId && u.boqSequenceParentId==getChild.id)
                }

                if(getChild.childs.length!=0){
                    findTheChild(getChild.childs,boqId)
                }
                
            }
        }
        //add the line items
        for(let x =0; x<self.boqSections.length;x++)
        {
            const boq = self.boqSections[x]
            if(boq.data.sections.length==0){
                boq.lineItems = self.lineItems.filter(u=>u.boqId==boq._id)
            }
            else{
                findTheChild(boq.data.sections,boq._id)
            }
            overAllTotal += boq.data.overAllTotal.sellTotal
        }

        self.overAllTotal = overAllTotal
        self.currency = self.quotationItem.currencyId
    },
    components : {HleHeaderReadOnly, BoqCreatorPrintViewRow},
    methods : {
        ...mapActions(['ChangeActiveLink']),
        PrintPage(){
            window.print()
        },
        ReformatNumber,
        async GetListByBits(){
            const self = this

            self.lineItems = []
            let willFetch = true
            self.boqListParams.skip = 0

            while(willFetch){
                const bits = await GetHighLevelQuotationSummaryDetailed(self.boqListParams)
                if(bits.length!=0){
                    self.lineItems.push(...bits)
                }
                if(bits.length==self.boqListParams.take){
                    self.boqListParams.skip += 50
                }else{
                    willFetch = false
                }
            }
        },
    },
    computed : {
        ...mapGetters(['userInfo'])
    },
    data(){
        return {
            boqSections : [],
            overAllTotal : 0,
            backUrl : this.$route.query.backUrl,
            officeList: [],
            hleProject : {},
            quotationItem : {},
            lineItems : [],
            quotationId : this.$route.params.qtnId,
            revisionId : this.$route.query.revisionId,
            boqListParams : {
                id : this.$route.params.qtnId,
                take : 50,
                skip : 0,
                revisionId : this.$route.query.revisionId
            },
            currency: { sign : ''}
        }
    }

}
</script>