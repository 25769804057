<template>
  <div v-if="editor" class="border">
    <div class="rich-text-box-component ps-2 pe-2 pt-1 pb-2">
        <small>{{ $t('Rich Text Module.Text Size') }} : </small>
        <select class="form-select" @change="ChangeFont" style="width:20%;padding:2px;display:initial">
            <option value="0" >{{ $t('Rich Text Module.Normal') }}</option>
            <option value="6" class="h6">{{ $t('Rich Text Module.Heading 6') }}</option>
            <option value="5" class="h5">{{ $t('Rich Text Module.Heading 5') }}</option>
            <option value="4" class="h4">{{ $t('Rich Text Module.Heading 4') }}</option>
            <option value="3" class="h3">{{ $t('Rich Text Module.Heading 3') }}</option>
            <option value="2" class="h2">{{ $t('Rich Text Module.Heading 2') }}</option>
            <option value="1" class="h1">{{ $t('Rich Text Module.Heading 1') }}</option>
        </select>
        <button class="btn btn-action" title="Bold" @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
        <i class="fa-solid fa-bold"></i>
        </button>
        <button class="btn btn-action" title="Italic" @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
            <i class="fa-solid fa-italic"></i>
        </button>
        <button class="btn btn-action" title="Strikethrough" @click="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
            <i class="fa-solid fa-strikethrough"></i>
        </button>
        <button class="btn btn-action"  title="Clear Formatting"  @click="editor.chain().focus().unsetAllMarks().run()">
            <i class="fa-solid fa-text-slash"></i>
        </button>
        <button class="btn btn-action"  title="Bullet List" @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }">
            <i class="fa-solid fa-list-ul"></i>
        </button>
        <button class="btn btn-action"  title="Numbered List" @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }">
            <i class="fa-solid fa-list-ol"></i>
        </button>
        <!-- <button  class="btn btn-action " title="Blockquote" @click="editor.chain().focus().toggleBlockquote().run()" :class="{ 'is-active': editor.isActive('blockquote') }">
            <i class="fa-solid fa-quote-left"></i>
        </button> -->
        <div class="dropdown" style="display:initial">
            <button title="Link Text" @click="OpenModalUrl" class="btn btn-action dropdown-toggle" data-bs-toggle="dropdown">
                <i class="fa-solid fa-link"></i>
            </button>
             <ul class="dropdown-menu" >
                <li class="p-2" style="width:300px">
                     <div class="from-group ">
                        <label class="required-style">{{$t('Rich Text Module.Web URL')}}</label>
                        <input type="text" class="form-control" v-model="currentUrl" />
                        <div class="d-flex justify-content-end">
                            <button class="btn btn-outline-primary btn-action mt-2" @click="SetLink" >{{$t('Rich Text Module.Set Link')}}</button>
                        </div>
                    </div>
                    <div class="pt-1 d-flex justify-content-end" >
                        
                    </div>
                </li>
            </ul>
        </div>
        <button class="btn btn-action"  @click="editor.chain().focus().unsetLink().run()" title="Unlink Text"  :disabled="!editor.isActive('link')">
            <i class="fa-solid fa-link-slash"></i>
        </button>
        <button class="btn btn-action " title="Undo"  @click="editor.chain().focus().undo().run()">
            <i class="fa-solid fa-rotate-left"></i>
        </button>
        <button class="btn btn-action " title="Redo"  @click="editor.chain().focus().redo().run()">
            <i class="fa-solid fa-rotate-right"></i>
        </button>
        
    </div>
    <hr class="mt-0 mb-0" />
    <editor-content :editor="editor" class=""  :value="modelValue"  @input="$emit('update:modelValue', $event.target.value)"  >
    </editor-content>
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
import Link from '@tiptap/extension-link'

export default {
    components: {
        EditorContent
    },
    props : { 
        modelValue : String,
        placeHolder : {
            type : String,
            default: 'Compose email here…'
        },
        height : {
            type : String,
            default: '100'
        }
    },
    data() {
        return {
            editor: null,
            currentUrl : ''
        }
    },
    emits: ['update:modelValue'],
    mounted() {
        this.editor = new Editor({
            extensions: [
                StarterKit,
                Placeholder.configure({
                    placeholder: this.placeHolder,
                }),
                Link.configure({
                    openOnClick: false,
                    autolink: false
                })
            ],
            content: this.modelValue,
            onUpdate: () => {
                // HTML
                this.$emit('update:modelValue', this.editor.getHTML())

                // JSON
                // this.$emit('update:modelValue', this.editor.getJSON())
            }
        })
    },
    watch: {
        modelValue(value) {
            // HTML
            const isSame = this.editor.getHTML() === value

            // JSON
            // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

            if (isSame) {
                return
            }

            this.editor.commands.setContent(value, false)
        },
    },
    methods : {
        ChangeFont(e){
            const value = e.target.value
            if(value==0)
                this.editor.chain().focus().setParagraph().run()
            else
                this.editor.chain().focus().toggleHeading({ level: Number(value) }).run()
        },
        OpenModalUrl(){
            this.currentUrl = this.editor.getAttributes('link').href || ''
        },
        SetLink() {
            let url =  this.currentUrl

            console.log(url)

            // cancelled
            if (url === null) {
                return
            }
            
            // empty
            if (url === '') {
                this.editor
                .chain()
                .focus()
                .extendMarkRange('link')
                .unsetLink()
                .run()

                return
            }

            // update link
            this.editor
                .chain()
                .focus()
                .extendMarkRange('link')
                .setLink({ href: url })
                .run()
        },
        
    },
    beforeUnmount() {
        this.editor.destroy()
    },
}
</script>
<style >
</style>