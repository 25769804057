<template>
    <template v-for="(item,key) in  sections" :key="key">
        <tr>
            <td class="text-center" >{{ item.label }}</td>
            <td class="fw-bold text-capitalize" :class="{'ps-3' : item.level==2,'ps-4' : item.level==3}">{{ item.name }}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td v-if="item.expectedAmount" class="text-end">{{ ReformatNumber(item.expectedAmount) }}</td>
            <td v-else></td>
        </tr>
        <BoqCreatorPrintViewRow  :sections="item.childs" :IsOpenBook="IsOpenBook" />
        <tr v-for="(lineItem,lineKey) in item.lineItems" :key="lineKey">
            <td class="text-center">{{ lineKey + 1 }}</td>
            <td class="bg-white">
                <div >
                    <div :class="{'ps-4' : item.level==2,'ps-5' : item.level==3}" >
                        <small class="fw-bold text-capitalize" v-if="userInfo.language=='en'">{{lineItem.name}}</small> 
                        <small class="fw-bold text-capitalize" v-else>{{lineItem.nameCh}}</small> 
                        <br />
                        <p class="small mb-0 description-line-item">
                            {{lineItem.description}}
                        </p>
                    </div>
                </div>
            </td>
            <td>{{lineItem.unit}}</td>
            <td  >{{lineItem.area}}</td>
            <td class="text-end">
                {{ lineItem.rateOnly ? '-' : ReformatNumber(lineItem.totalQuantity) }}
            </td>
            <td class="text-end">
                
                <span v-if="lineItem.remark == 'Included'">{{ ReformatNumber(IsOpenBook ?  lineItem.costRateTotal : lineItem.sellRate) }}</span>
                <span v-else>-</span>
            </td>
            <td class="text-end" >
                <span class="text-danger" v-if="lineItem.rateOnly">{{ $t('RATE ONLY') }}</span>
                <span class="text-info ms-1" v-else-if="lineItem.remark != 'Included'">{{ $t('BillOfQuantityRemark.' + lineItem.remark) }}</span>
                <span v-else>
                    {{ ReformatNumber(IsOpenBook ?  lineItem.costTotal : lineItem.sellTotal)}}
                </span>
            </td>
        </tr>
        <tr class="fw-bold" v-if="item.lineItems?.length!=0  && (item.subTotal?.sellTotal && item.subTotal?.sellTotal!=0) && IsOpenBook">
            <td ></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="text-end">{{ $t('Uplift of') }} {{ item.name }}</td>
            <td class="text-end">{{ ReformatNumber(item.subTotal?.sellTotal - item.subTotal?.costTotal) }}</td>
        </tr>
        <tr class="fw-bold" v-if="item.lineItems?.length!=0  && (item.subTotal?.sellTotal && item.subTotal?.sellTotal!=0)">
            <td ></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="text-end">{{$t('Sub Total Of')}} {{ item.name }} </td>
            <td class="text-end">{{ ReformatNumber(item.subTotal?.sellTotal) }}</td>
        </tr>
    </template>
</template>



<script>
import {ReformatNumber} from '@/helpers/utilities.js'
import BoqCreatorPrintViewRow from '../boq/BoqCreatorPrintViewRow.vue'
import { mapGetters } from 'vuex';
export default{
    name :'BoqCreatorPrintViewRow',
    components : {BoqCreatorPrintViewRow},
    methods : {
        ReformatNumber
    },
    props : ['IsOpenBook','sections'],
    computed : {
        ...mapGetters(['userInfo'])
    }
}
</script>