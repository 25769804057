<template>
    <table class="table">
        <thead>
            <tr>
                <th>S/N</th>
                <th>Description</th>
                <th class="text-end">Cost Total</th>
                <th class="text-end">Sell Total</th>
                <th class="text-end">Gross Profit</th>
                <th class="text-end">%</th>
            </tr>
        </thead>
        <tbody>
            <template v-for="(item,key) in SummaryItems" :key="key">
                <tr v-if="item.mainCateg!=null" class="bg-light-primary " role="button" @click="Collapse(item.mainCateg.costCode)"
                data-bs-toggle="tooltip" data-bs-placement="top" title="Click to hide/show breakdown"
                >
                    <td class="fw-bold ">
                        {{item.mainCateg.label}} 
                    </td>
                    <td class="fw-bold  " >
                        {{item.mainCateg.costCode}} {{item.mainCateg.name}}
                    </td>
                    <td class="text-end fw-bold ">{{currencySign}}{{ReformatNumber(item.mainCateg.totals.costTotal)}}</td>
                    <td class="text-end fw-bold ">{{currencySign}}{{ReformatNumber(item.mainCateg.totals.sellTotal)}}</td>
                    <td class="text-end fw-bold ">{{currencySign}}{{ReformatNumber(item.mainCateg.totals.gp)}}</td>
                    <td class="text-end fw-bold ">{{ReformatNumber(item.mainCateg.totals.percentage * 100)}}%</td>
                </tr>
                <tr v-if="item.subCateg!=null" :class="{'d-none' : rowsHidden.indexOf(item.subCateg.parentCostCode) > -1}"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="Click to hide/show breakdown" role="button"
                    @click="CollapseSubCateg(item.subCateg.costCode)">
                    <td class="fw-bold ps-4">
                        {{item.subCateg.label}} 
                    </td>
                    <td class="fw-bold " >
                        {{item.subCateg.costCode}} {{item.subCateg.name}}  
                    </td>
                    <td class="text-end fw-bold ">{{currencySign}}{{ReformatNumber(item.subCateg.totals.costTotal)}}</td>
                    <td class="text-end fw-bold ">{{currencySign}}{{ReformatNumber(item.subCateg.totals.sellTotal)}}</td>
                    <td class="text-end fw-bold ">{{currencySign}}{{ReformatNumber(item.subCateg.totals.gp)}}</td>
                    <td class="text-end fw-bold ">{{ReformatNumber(item.subCateg.totals.percentage * 100)}}%</td>
                </tr>
                <tr :class="{'d-none' : rowsHidden.indexOf(item.lineItem.parentCostCode) > -1 || rowsHiddenSub.indexOf(item.lineItem.parentSubCostCode) > -1}">
                    <td class="ps-5">{{item.lineItem.label}}</td>
                    <td>{{item.lineItem.costCode}} {{item.lineItem.name}}</td>
                    <td class="text-end">{{currencySign}}{{ReformatNumber(item.lineItem.costTotal)}}</td>
                    <td class="text-end">{{currencySign}}{{ReformatNumber(item.lineItem.sellTotal)}}</td>
                    <td class="text-end">{{currencySign}}{{ReformatNumber(item.lineItem.gp)}}</td>
                    <td class="text-end">{{ReformatNumber(item.lineItem.percentage * 100)}}%</td>
                </tr>
                    <tr class="fw-bold" v-if="item.overAllData">
                    <td></td>
                    <td class="text-end">Overall Total</td>
                    <td class="text-end">{{currencySign}}{{ReformatNumber(item.overAllData.costTotal)}}</td>
                    <td class="text-end">{{currencySign}}{{ReformatNumber(item.overAllData.sellTotal)}}</td>
                    <td class="text-end">{{currencySign}}{{ReformatNumber(item.overAllData.gp)}}</td>
                    <td class="text-end">{{ReformatNumber(item.overAllData.percentage * 100)}}%</td>
                </tr>
            </template>
            
        </tbody>
    </table>
</template>


<script>
import { ReformatNumber} from '@/helpers/utilities.js'
export default {
    name : 'BoqLineItemSummary',
    props : ['data','currencySign'],
    methods : {
        ReformatNumber : ReformatNumber,
        Collapse(id){
            const getIndex = this.rowsHidden.indexOf(id)
            if(getIndex==-1){
                this.rowsHidden.push(id)
            }else{
                this.rowsHidden.splice(getIndex,1)
            }
        },
        CollapseSubCateg(id){
            const getIndex = this.rowsHiddenSub.indexOf(id)
            if(getIndex==-1){
                this.rowsHiddenSub.push(id)
            }else{
                this.rowsHiddenSub.splice(getIndex,1)
            }
        }
    },    
    data(){
        return {
            rowsHidden : ['PRE','PRELIM'],
            rowsHiddenSub : []
        }
    },
    computed : {
        SummaryItems(){
            const self = this 
      
            if(self.data.length==0){
                return []
            }
      
            let mainCateg = { costCodeId : '' }
            let subCateg = { costCodeId : '' }

            let fieldListMain = {
                costTotal : 0,
                sellTotal : 0,
                gp : 0,
                index : 0
            }
      
            let fieldListSub = {
                costTotal : 0,
                sellTotal : 0,
                gp : 0,
                index : 0
            }

            let OverAllData = {
                costTotal : 0,
                sellTotal : 0,
                gp : 0,
                percentage : 0
            }
      
            let totalList= []
            let totalListSub= []
            let parentLetter = 65
            let subCount = 1
            let lineItemSubCount = 1
      
            const arrayLength = self.data.length
      
            let ReformatData = self.data.map((u,index)=>{
                let showMainCateg = false
                let showSubCatag = false
                const nextLineItem = self.data[index + 1]
                //main category sum 
                fieldListMain.costTotal += u.costTotal
                fieldListMain.sellTotal += u.sellTotal
                fieldListMain.gp += u.gp
            
                //sub category sum
                fieldListSub.costTotal += u.costTotal
                fieldListSub.sellTotal += u.sellTotal
                fieldListSub.gp += u.gp

                //main category sum
                OverAllData.costTotal += u.costTotal
                OverAllData.sellTotal += u.sellTotal
                OverAllData.gp += u.gp
    
    
                if(u.parents[0].costCodeId!=mainCateg.costCodeId){
                    showMainCateg = true
                    mainCateg = u.parents[0]
                    mainCateg.label = String.fromCharCode(parentLetter)
                    ++parentLetter
                    subCount = 1
                    fieldListMain.index = index
                }
            
                if(u.parents[1].costCodeId!=subCateg.costCodeId){
                    showSubCatag = true
                    subCateg = u.parents[1]
                    subCateg.parentCostCode = u.parents[0].costCode
                    subCateg.label = mainCateg.label + subCount
                    ++subCount
                    lineItemSubCount = 1
                    fieldListSub.index = index
                }

                u.label = subCateg.label +'.'+ lineItemSubCount
                u.parentCostCode = u.parents[0].costCode
                u.parentSubCostCode = u.parents[1].costCode
                ++lineItemSubCount
             
                 //main category
                if(index + 1==arrayLength){
                    totalList.push({
                        costTotal : fieldListMain.costTotal ,
                        sellTotal : fieldListMain.sellTotal  ,
                        gp : fieldListMain.gp ,
                        index : fieldListMain.index,
                    })
                }else if(u.parents[0].costCodeId!=nextLineItem.parents[0].costCodeId){
                    totalList.push({
                        costTotal : fieldListMain.costTotal ,
                        sellTotal : fieldListMain.sellTotal  ,
                        gp : fieldListMain.gp ,
                        index : fieldListMain.index,
                    })
 
                    fieldListMain = {
                        costTotal : 0 ,
                        sellTotal : 0  ,
                        gp : 0 ,
                        index : 0,
                    }
                }
                //sub category
                if(index + 1==arrayLength){
                    totalListSub.push({
                        costTotal : fieldListSub.costTotal,
                        sellTotal : fieldListSub.sellTotal,
                        gp : fieldListSub.gp ,
                        index : fieldListSub.index,
                    })

                    OverAllData.percentage = Math.abs(OverAllData.costTotal - OverAllData.sellTotal) / ((OverAllData.costTotal + OverAllData.sellTotal) / 2)
                    
                }
                else if(u.parents[1].costCodeId!=nextLineItem.parents[1].costCodeId){
                    totalListSub.push({
                        costTotal : fieldListSub.costTotal ,
                        sellTotal : fieldListSub.sellTotal  ,
                        gp : fieldListSub.gp ,
                        index : fieldListSub.index,
                    })
 
                    fieldListSub = {
                        costTotal : 0 ,
                        sellTotal : 0  ,
                        gp : 0 ,
                        index : 0,
                    }
                }

                u.percentage = Math.abs(u.costTotal - u.sellTotal) / ((u.costTotal + u.sellTotal) / 2)
                
                return {
                    lineItem : u,
                    mainCateg : showMainCateg ? mainCateg : null,
                    subCateg : showSubCatag ? subCateg : null,
                    overAllData : index + 1==arrayLength ? OverAllData : null
                }
            })
      

            //loop main totals
            for(let x=0;x<totalList.length;x++){
                const data = totalList[x]
                ReformatData[data.index].mainCateg.totals = {}
                ReformatData[data.index].mainCateg.totals.costTotal = data.costTotal 
                ReformatData[data.index].mainCateg.totals.sellTotal = data.sellTotal  
                ReformatData[data.index].mainCateg.totals.gp =data.gp 
                ReformatData[data.index].mainCateg.totals.percentage = Math.abs(data.costTotal - data.sellTotal) / ((data.costTotal + data.sellTotal) / 2)
            }
            for(let x=0;x<totalListSub.length;x++){
                const data = totalListSub[x]
                ReformatData[data.index].subCateg.totals = {}
                ReformatData[data.index].subCateg.totals.costTotal = data.costTotal 
                ReformatData[data.index].subCateg.totals.sellTotal = data.sellTotal  
                ReformatData[data.index].subCateg.totals.gp =data.gp 
                ReformatData[data.index].subCateg.totals.percentage = Math.abs(data.costTotal - data.sellTotal) / ((data.costTotal + data.sellTotal) / 2)

               
            }
            return ReformatData
        }
    }
}
</script>