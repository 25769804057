import Modal from '@/components/layout/Modal.vue'
import Loader from '@/components/layout/Loader.vue'
import Pagination from '@/components/layout/Pagination.vue'
import Select2 from '@/components/layout/Select2.vue'
import BoqItemTable from '@/components/boq/BoqItemTable.vue'
import ModalUpdateLineItem from '@/components/boq/ModalUpdateLineItem.vue'
import ModalImportLineItems from '@/components/boq/ModalImportLineItems.vue'
import ModalLineItemColumnFilter from '@/components/boq/ModalLineItemColumnFilter.vue'
import ModalNewLineItem from '@/components/boq/ModalNewLineItem.vue'
import {NumberFormatterWidget, LinkList,LevelSort, ApprovalStageBoq, SortFunc, ModuleActionList ,ReformatDate, ExportTemplates, ApprovalStageBoqObj} from '@/helpers/utilities.js'
import  {mapActions, mapGetters} from 'vuex'
import BreadcrumbsProject  from '@/components/projectInformation/BreadcrumbsProject.vue'
import 'dotenv/config'
import {GetCostCodes, ReviewBoq,PublishBoq, SearchAreaFromProject,DownloadBoqExport, GetItemsFromBoqByBits,
   ForApprovalBoq, SearchBoqFromProject, ExportBoqCostXLayout, ExportBoqFiveDLayout, ExportBoqRolledUpLayout, BoQItemExportForInternal} from '@/actions/boqActions.js'
import {SearchProjectProcessView, SearchProjectProcessApproval,GetProcessItem, GetProcessItemBoq} from '@/actions/projectActions.js'
import {measurementUnits} from '@/assets/dataJson/commercial_management.json'
import ProcessStepListView  from '@/components/projectInformation/ProcessStepListView.vue'
import ProcessStepList  from '@/components/projectInformation/ProcessStepList.vue'
import Multiselect from 'vue-multiselect'
import {SearchAllActiveCostCodes} from '@/actions/projectActions.js'
import BoqProcessWorkFlow  from '@/components/projectInformation/BoqProcessWorkFlow.vue'
export default {
  name: 'BoqItems',
  components: {
    BreadcrumbsProject,
    Modal,
    Select2,
    BoqItemTable,
    Loader,
    Pagination,
    ProcessStepListView,
    ProcessStepList,
    ModalUpdateLineItem,
    ModalImportLineItems,
    ModalNewLineItem,
    ModalLineItemColumnFilter,
    Multiselect,
    BoqProcessWorkFlow,
  },
  methods:{
    ...mapActions(['SetCostCodes','GetProjectInfo', 'GetBoqItemRun','ChangeActiveLink']),
    ReformatDate : ReformatDate,
    timerSetter(callback){
        const self = this
        if(self.timer){
            clearTimeout(self.timer)
            self.timer = null
        }
        self.timer = setTimeout(callback,700)
    },
    timerSetterSearch(callback){
      const self = this
      
      if(self.timerSearch){
          clearTimeout(self.timerSearch)
          self.timerSearch = null
      }

      self.timerSearch = setTimeout(callback,700)
    },
    GetCostCodeRun(search){
      return GetCostCodes(search,this.newTabData.parent)
    },
    SearchProcessList(search){
      return SearchProjectProcessView(this.boqItemData.projectId,search)
    },
    SearchProcessListApproval(search){
      return SearchProjectProcessApproval(this.boqItemData.projectId,search)
    },
    formatNumber(value,sign= ""){
      return NumberFormatterWidget(value,sign)
    },
    ReviewBoqRun(e){
      const self = this
      const btn = e.target
      const boqId = self.$route.params.id

      if(self.isPublishRunning){
        return
      }

      self.isPublishClicked =true

      const newData = self.boqPublishData

      if(!newData.processId || !newData.dateEnd){
        return
      }
      btn.disabled = true
      newData.boqId  = boqId

      self.isPublishClicked = false
      self.isPublishRunning = true

      ReviewBoq(newData).then((res)=>{
        self.isPublishRunning = false
        btn.disabled = false
        if(res!='OK'){
          self.$swal('Bill Of Quantites Review', res,'warning')
        }else{
            self.GetBoqItemRun(boqId).then((res)=>{
                self.boqItemData = res
                GetProcessItemBoq(boqId).then((process)=>{
                  self.processItemData = process
                  self.$refs.closeBoqPublishModal.click()
                }).catch()
            })
            
        }
      }).catch()
    },
    SendForNewWorkFlow(){
      this.$router.push({name : 'BoqReviewerSetup', query : { boqId : this.$route.params.id} })
    },
    ForApprovalBoqRun(e){
      const self = this
      const btn = e.target
      const boqId = self.$route.params.id
      if(self.isApprovalRunning){
        return
      }
      self.isApprovalClicked =true

      const newData = self.boqApprovalData

      if(!newData.processId || !newData.dateEnd){
        return
      }
      btn.disabled = true
      newData.boqId  = boqId

      self.isApprovalClicked = false
      self.isApprovalRunning = true

      ForApprovalBoq(newData).then((res)=>{
        self.isApprovalRunning = false
        btn.disabled = false
        if(res!='OK'){
          self.$swal('Bill Of Quantites Approval', res,'warning')
        }else{
            self.GetBoqItemRun(boqId).then((res)=>{
                self.boqItemData = res
                GetProcessItem(boqId,1).then((process)=>{
                  self.processItemData = process
                  self.$refs.closeBoqApprovalModal.click()
                }).catch()
            })
            
        }
      }).catch()
    },
    SelectArea(area){
      const self = this
      const selected = self.selectedArea

      const getIndex = selected.indexOf(area)
      if(getIndex==-1){
        selected.push(area)
      }else{
        selected.splice(getIndex,1)
      }

      self.selectedCostCode = []
      if(selected.length==0){
        self.boqListParams = {
          id : self.$route.params.id,
          costCode : encodeURIComponent(JSON.stringify([self.boqItemData.subCategoryList[0].costCodeId])),
          area : '',
          page : 1
        }
        self.selectedCostCode.push(self.boqItemData.subCategoryList[0].costCodeId)
      }else{
        self.boqListParams = {
          id : self.$route.params.id,
          costCode : '',
          area : encodeURIComponent(JSON.stringify(selected)),
          page : 1
        }
      }

      self.tableLoading = true
      self.timerSetter(function(){
        self.rowDatas = []
        self.boqListParams.skip = 0
        self.GetListByBites()
        self.tableLoading = false
        // GetItemsFromBoq(self.boqListParams).then((res)=>{
        //   self.tableLoading = false
        //   self.currentDataSet = JSON.parse(JSON.stringify(res))
        //   self.edited = []
        //   self.rowDatas = res
        // }).catch()
      })
    },
    SelectCostCode(costCode){
      const self = this

      const getIndex = self.selectedCostCode.findIndex(u=>u===costCode)
      if(getIndex==-1){
        self.selectedCostCode.push(costCode)
      }else{
        self.selectedCostCode.splice(getIndex,1)
      }

      self.boqListParams = {
        id : self.$route.params.id,
        costCode : encodeURIComponent(JSON.stringify(self.selectedCostCode)),
        area : '',
        page : 1
      }
      self.selectedArea = []
      self.tableLoading = true
      self.timerSetter(function(){
        self.rowDatas = []
        self.boqListParams.skip = 0
        self.GetListByBites()
        self.tableLoading = false
        // GetItemsFromBoq(self.boqListParams).then((res)=>{
        //   self.tableLoading = false
        //   self.currentDataSet = JSON.parse(JSON.stringify(res))
        //   self.edited = []  
        //   self.rowDatas = res
        // }).catch()
      })
    },
    ChangeView(val){
      this.filterView = val
    },
    ReloadList(){
      const self = this
      self.tableLoading = true
      self.rowDatas = []
      self.boqListParams.skip = 0
      self.GetListByBites()
      self.tableLoading = false
      // GetItemsFromBoq(self.boqListParams).then((data)=>{
      //   self.tableLoading = false
      //   self.rowDatas = data
      // })
      
    },
    PageChange(page){
      const self = this
      self.boqListParams.page = page
      self.tableLoading = true
      // GetItemsFromBoq(self.boqListParams).then((data)=>{
      //   self.tableLoading = false
      //   self.currentDataSet = JSON.parse(JSON.stringify(data))
      //   self.edited = []        
      //   self.rowDatas = data
      // })
    },
    OpenEditModal(data){
      this.updateMasterItem = Object.assign({},data)
      this.updateMasterItem.costName = data.costCode + ' ' + data.name 
      this.updateMasterItem.subCateg = data.parents[1].name
      this.updateMasterItem.mainCateg = data.parents[0].name
    },
    SearchArea(e){
        const self = this

        self.timerSetter(function(){
            SearchAreaFromProject({id : self.projectInfo._id, search : e.target.value}).then((res)=>{
                self.areaList = res
            })
        })
    },
    GetProcessItemRun(){
      const self = this
      const boqId = self.$route.params.id
      self.GetBoqItemRun(boqId).then((res)=>{
          self.boqItemData = res
          GetProcessItem(boqId,1).then((process)=>{
            self.processItemData = process
          }).catch()
      })
    },
    OpenApproval(){
      this.boqApprovalData ={}
    },
    OpenReviewer(){
      this.boqPublishData ={}
    },
    PublishBoqItems(e){
      const self = this
      const btn = e.target
      const boqId =  self.$route.params.id


      self.$swal({
        title: 'Publish BoQ',
        text : 'BoQ will be locked for changed, Proceed?',
        showCancelButton: true,
        icon: 'warning',
        confirmButtonText: 'Proceed',
        showLoaderOnConfirm : true,
        allowOutsideClick: () => !self.$swal.isLoading(),
        preConfirm : ()=>{
          btn.disabled = true
          return PublishBoq(boqId).then((res)=>{
            btn.disabled = false
            if(res=="OK"){
              self.GetBoqItemRun(boqId).then((res)=>{
                  self.boqItemData = res
              })
            }else{
              self.$swal('Publish BoQ',res,'warning')
            }
          })
      }
      }).then((res)=>{
        if(res.isConfirmed){
          self.$swal('Boq Published Successfully','','success')
        }
      })
    },
    GetDataList(){
      const self= this
      self.tableLoading = true
      self.GetBoqItemRun(self.$route.params.id).then((res)=>{
        self.boqItemData = res
        self.boqItemData.subCategoryList.sort(LevelSort)
        self.boqItemData.areaList.sort(SortFunc)
        self.boqListParams.skip = 0
        self.rowDatas = []
        self.GetListByBites()
        self.tableLoading = false
        // GetItemsFromBoq(self.boqListParams).then((data)=>{
        //   self.tableLoading = false
        //   self.rowDatas = data
        // })
      })
    },
    GetListByBites(){
      const self = this
      GetItemsFromBoqByBits(self.boqListParams).then((res)=>{
        if(res.length!=0){
          self.rowDatas.push(...res)
        }
        if(res.length == self.boqListParams.take){
            self.boqListParams.skip += 50
            self.GetListByBites()
        }else{
          self.dataReady = true
        }
      })
    },
    OpenEditLineItem(data){
      this.updateLineItem = JSON.parse(JSON.stringify(data))
      this.updateLineItem.id = data._id
      this.updateLineItem.markUp = this.updateLineItem.markUp * 100
      this.updateLineItem.margin = this.updateLineItem.margin * 100
    },
    OpenImportModal(){
      this.$refs.modalImportLineItem.ClearData()
    },
    OpenAddItemModal(){
      this.$refs.modalNewLineItem.ClearData()
    },
    ChangeSelectecColumnValue(value){
      this.selectedColumns = value
    },
    ExportBoqList(e){
      const self = this

      if(self.isExportRunning){
        return
      }

      const btn = e.target
      btn.disabled = true
      const boqId = this.$route.params.id
      self.isExportRunning = true
      if(self.exportTemplateType===0){
        DownloadBoqExport(boqId, this.boqItemData.name +' export').then(()=>{
          btn.disabled = false
          self.isExportRunning = false
        })
      }else if(self.exportTemplateType===1){
        ExportBoqFiveDLayout(boqId, this.boqItemData.name + ' FiveD-export').then(()=>{
          btn.disabled = false
          self.isExportRunning = false
        })
      }else if(self.exportTemplateType===2){
        ExportBoqCostXLayout(boqId, this.boqItemData.name  + ' costX-export').then(()=>{
          btn.disabled = false
          self.isExportRunning = false
        })
      }else if(self.exportTemplateType===3){
        BoQItemExportForInternal(boqId, this.boqItemData.name  + ' costX-export').then(()=>{
          btn.disabled = false
          self.isExportRunning = false
        })
      }
      
      else{
          self.$swal('Export Template','Sorry not yet implemented','warning')
          btn.disabled = false
          self.isExportRunning = false
      }
      
    },
    ExportForClient(e){
      const self = this
      const btn = e.target
      const boqId = self.$route.params.id
      btn.disabled = true
      ExportBoqRolledUpLayout(boqId, self.boqItemData.name + ' ' + self.boqItemData.reference + ' export').then(()=>{
        btn.disabled = false
      })
    },
    UpdateSelected(data){
      this.selectedLineItems = data
    },
    SearchCostCodeAll(query){
      const self =  this
      self.searchCostCodeLoading = true
      self.timerSetter(function(){
          self.searchCostCodeLoading = false
          SearchAllActiveCostCodes(query).then((res)=>{
              self.dataCostCodes = res
          })
      })
    },
    ClearField(type){
      if(type==1){
          this.selectedArea = []
      }
      else{
          this.selectedCostCode = []
      }
    },
    GetChanges(haveChange, changes){
      this.haveChanges = haveChange
      this.movedLineItems = changes
    }
  },
  watch: {
    selectedCostCode(val) {
        const self = this
        self.boqListParams.costCode = encodeURIComponent(JSON.stringify(val.map(u=>u._id)))
        self.boqListParams.area = encodeURIComponent(JSON.stringify(self.selectedArea))
        self.timerSetterSearch(function(){
          self.rowDatas = []
          self.boqListParams.skip = 0
          self.GetListByBites()
          self.tableLoading = false
        })

        return val.map(u=>u._id)
    },
    selectedArea(val){
      const self = this
      self.boqListParams.costCode = encodeURIComponent(JSON.stringify(self.selectedCostCode))
      self.boqListParams.area = encodeURIComponent(JSON.stringify(val))
      self.timerSetterSearch(function(){
        self.rowDatas = []
        self.boqListParams.skip = 0
        self.GetListByBites()
        self.tableLoading = false
      })

      return val
    }
  },
  mounted : async function(){
    const self = this
    self.ChangeActiveLink(LinkList.BILL_OF_QUANTITIES)
    const boqId = self.$route.params.id
    const boqItem = await self.GetBoqItemRun(boqId)
    self.ExportTemplatesForExport = [...ExportTemplates]
    self.ExportTemplatesForExport.push('System Template(For Presentation)')
    if(boqItem==401){
      self.$router.push('/unauthorized')
      return
    }
    self.boqItemData = boqItem
    // boqItem.subCategoryList.sort(LevelSort)
    // boqItem.areaList.sort(SortFunc)
    self.GetListByBites()
    
    if(!self.projectInfo._id){
      await self.GetProjectInfo(boqItem.projectId).catch()
    }
    if(boqItem.approvalStage==ApprovalStageBoq.FOR_APPROVAL){
      const processData = await GetProcessItemBoq(boqId).catch()
      self.processItemData = processData
    }

    // self.dataReady = false
    self.boqList = await SearchBoqFromProject({search : '', id : boqItem.projectId})
    
    const lineItemCols =  window.localStorage.getItem('lineItemColumns')
    if(lineItemCols){
        this.selectedColumns = lineItemCols.split(',').map(Number)
    }
    self.approvalProcessList = await SearchProjectProcessApproval(boqItem.projectId,'')
    self.reviewProcessList = await SearchProjectProcessView(boqItem.projectId,'')
    self.SearchCostCodeAll('')
    self.areaList = await SearchAreaFromProject({id : self.projectInfo._id, search : ''})
    self.areaList.push('NO SPACE ID')
  },
  computed : {
    ...mapGetters(['projectInfo', 'permission']),
    
  },
  data(){
    return {
      ApprovalStageBoqObj : ApprovalStageBoqObj,
      OpenFilter : false,
      ModuleActionList : ModuleActionList,
      boqItemData :{
        _id : '1',
        subCategoryList : [],
        areaList : [],
        approvalStage : 1,
      },
      areaList : [],
      selectedArea : [],
      selectedCostCode : [],
      dataCostCodes : [],
      boqListParams : {
        id : this.$route.params.id,
        costCode : '',
        area : '',
        page : 1,
        take : 50,
        skip : 0
      },
      boqPublishData : {},
      boqApprovalData : {
        boqId : this.$route.params.id
      },
      isPublishRunning : false,
      isPublishClicked : false,
      isApprovalRunning : false,
      isApprovalClicked : false,
      isExportRunning : false,
      timer : null,
      timerSearch : null,
      rowDatas:[],
      BoqCategoryList : [],
      dataReady : false,
      tableLoading : false,
      projectUrl : process.env.VUE_APP_PROJECT_URL,
      boqUrl : process.env.VUE_APP_BOQ_URL,
      filterView : 1,
      ApprovalStageBoq : ApprovalStageBoq,
      measurementUnits : measurementUnits,
      processItemData : {
        status : 1,
        internalApprovers : [],
        externalApprovers: []
      },
      boqList : [],
      updateLineItem : {
        unit : '',
        parents : [
            {
                name : ''
            },
            {
                name : ''
            }
        ]
      },
      selectedColumns : [],
      ExportTemplates : ExportTemplates,
      ExportTemplatesForExport : [],
      exportTemplateType : 0,
      exportTemplateFilter : null,
      selectedLineItems : [],
      selectedParent : "",
      approvalProcessList : [],
      reviewProcessList : [],
      searchCostCodeLoading : false,
      movedLineItems : [],
      haveChanges : false,
    }
  },
}