import {mapActions, mapGetters} from 'vuex'
import BreadcrumbsProject  from '@/components/projectInformation/BreadcrumbsProject.vue'
import StepItem2  from '@/components/layout/StepItem2.vue'
import Modal  from '@/components/layout/Modal.vue'
import { LinkList , SortFunc,LevelSort, ModuleActionList} from '@/helpers/utilities.js'
import {measurementUnits} from '@/assets/dataJson/commercial_management.json'
import {GetBoqListItems, GetCostCodeChilds, AddNewItemToMasterList, SearchAreaFromProject,
EditItemToMasterList, DeleteItemToMasterList, InsertItemsToBoq, SearchBoqFromProject, DownloadTemplate1} from '@/actions/boqActions.js'
import 'dotenv/config'
import Pagination from '@/components/layout/Pagination.vue'
import Loader from '@/components/layout/Loader.vue'
import Select2 from '@/components/layout/Select2.vue'
import {SearchAllActiveCostCodes} from '@/actions/projectActions.js'
export default {
    name : 'BoqMasterList',
    components : {
        BreadcrumbsProject,
        StepItem2,
        Modal,
        Pagination,
        Select2,
        Loader
    },
    data(){
        return {
            projectUrl : process.env.VUE_APP_PROJECT_URL,
            newMasterItem : {
                unit : ''
            },
            updateMasterItem : {
                unit : ''
            },
            masterListData : {
                docs : [],
                totalPages : 1,
                page : 1
            },
            newBoqData : {},
            pagerParams : {
                id : this.$route.params.id,
                page : 1,
                costCode : [],
                area : [],
            },
            psudoPagerParams : {
                ArrayArea : [],
                ArrayCostCode : [],
            },
            fullListArea : [],
            fullListCostCode : [],
            dataCostCodes : [],
            selectedCostCode : '',
            fullListCostCodeParents : [],
            currencies : [],
            toggleAll : false,
            lastSeenPage : 1,
            selected :[],
            isClicked: false,
            isUpdateClicked: false,
            isNewBoqClicked: false,
            timer : null,
            timerSearch : null,
            areaList : [],
            loopCostCode : '',
            measurementUnits : measurementUnits,
            loaderList : false,
            costCodeInitialList : [],
            areaInitialList : [],
            parentSelected : [],
            OpenBoqImport : true,
            importClicked : false,
            newBoqImport : {
                currencyId : '',
                templateType : 1
            },
            ModuleActionList: ModuleActionList,
            searchCostCodeLoading : false
        }
    },
    mounted : async function(){
        const self = this
        const projectId = self.$route.params.id
        self.ChangeActiveLink(LinkList.BOQ_MASTER_LIST)
        await self.GetProjectInfo(projectId).catch()
        const getList = await GetBoqListItems(self.pagerParams).catch()
        if(getList==401){
            self.$router.push('/Unauthorized')
            return
        }
        self.masterListData = getList
        // self.currencies = await GetCurrencyList().catch()
        // self.fullListArea = await SearchAreaFromProject({id : self.$route.params.id, type : 2}).catch()
        // self.fullListArea.sort(SortFunc)
        // self.areaInitialList.push(...self.fullListArea)
        // self.fullListCostCode = await GetOfficeIncrementals(self.projectInfo.officeId).catch()

        // self.fullListCostCodeParents = self.fullListCostCode.map(u=>u.parent)
        // self.SortCostCode()
        // self.costCodeInitialList.push(...self.fullListCostCode)
        self.SearchCostCodeAll('')
        self.loaderList = true
    },
    watch :{
        selectedCostCode(value){
            const self = this
            self.loaderList = false
            self.timerSetterSearch(function(){
                self.pagerParams.page = 1
                self.pagerParams.costCode = encodeURIComponent(JSON.stringify(value.map(u=>u._id)))
                GetBoqListItems(self.pagerParams).then((res)=>{
                    self.loaderList = true
                    self.masterListData = res
                }).catch()
            })
        }
    },
    methods : {
        ...mapActions(['GetProjectInfo', 'ChangeActiveLink']),
        SearchChange(){
            const self = this
            self.loaderList = false
            self.timerSetter(function(){
                self.pagerParams.costCode = self.psudoPagerParams.ArrayCostCode.length!=0 ?  encodeURIComponent(JSON.stringify(self.psudoPagerParams.ArrayCostCode)) : ''
                self.pagerParams.area = self.psudoPagerParams.ArrayArea.length!=0 ? encodeURIComponent(JSON.stringify(self.psudoPagerParams.ArrayArea)) : ''
                GetBoqListItems(self.pagerParams).then((res)=>{
                    self.loaderList = true
                    self.masterListData = res
                }).catch()
            })
        },
        DownloadTemplate1Run(){
            DownloadTemplate1().then()
        },
        timerSetter(callback){
            const self = this
            
            if(self.timer){
                clearTimeout(self.timer)
                self.timer = null
            }

            self.timer = setTimeout(callback,700)
        },
        timerSetterSearch(callback){
            const self = this
            
            if(self.timerSearch){
                clearTimeout(self.timerSearch)
                self.timerSearch = null
            }

            self.timerSearch = setTimeout(callback,700)
        },
        SearchArea(e){
            const self = this

            self.timerSetter(function(){
                SearchAreaFromProject({id : self.$route.params.id, search : e.target.value}).then((res)=>{
                    self.areaList = res
                })
            })
        },
        DeleteItem (id){
            const self = this
            self.$swal({
                title: 'Delete Item?',
                showCancelButton: true,
                icon: 'warning',
                confirmButtonText: 'Delete',
                showLoaderOnConfirm : true,
                allowOutsideClick: () => !self.$swal.isLoading(),
                preConfirm : ()=>{
                    return DeleteItemToMasterList(id).then((res)=>{
                        if(res!="OK"){
                            self.$swal('Item Deleted', res, 'warning')
                        }
                    })
                }
            }).then((res)=>{
                if(res.isConfirmed){
                    self.loaderList = false
                    GetBoqListItems(self.pagerParams).then((res)=>{
                        self.loaderList = true
                        self.masterListData = res
                    }).catch()
                    self.$swal('Item Deleted', '', 'success')
                }
            })
        },
        PageChange(page){
            const self = this
            self.pagerParams.page = page
            self.loaderList = false
            GetBoqListItems(self.pagerParams).then((res)=>{
                self.toggleAll = false
                self.masterListData = res
                self.loaderList = true
            }).catch()
        },
        OpenAddModal(){
            this.newMasterItem = {
                unit : ''
            }
        },
        OpenEditModal(data){
            this.updateMasterItem = Object.assign({},data)
            const variation = data.variationName || ''
            

            if(this.userInfo.language== 'en')
            {
                this.updateMasterItem.costName = data.costCode + ' ' + data.name + ' ' + variation
                this.updateMasterItem.subCateg = data.parents[1].name
                this.updateMasterItem.mainCateg = data.parents[0].name
            }
            else
            {
                this.updateMasterItem.costName = data.costCode + ' ' + data.nameCh
                this.updateMasterItem.subCateg = data.parents[1].nameCh
                this.updateMasterItem.mainCateg = data.parents[0].nameCh
            }

            
        },
        OpenNewBoqModal(){
            this.newBoqData =  {
                id : this.$route.params.id,
                boqId : '',
                selectedCount : this.selected.length,
                data : this.selected,
                currency : '',
                existing : 'false'

            }
        },
        OpenImportModal(){
            this.$refs.uploadFile.value = '',
            this.newBoqImport =  {
                currencyId : '',
                templateType : 1
            }

        },
        SaveNewItemOpen(e){
            this.SaveNewItem(e).then()
        },
        SaveNewItemClose(e){
            const self = this
            self.SaveNewItem(e).then((res)=>{
                if(res){
                    self.$refs.closeAddModalItemBoq.click()
                }
            })
        },
        SaveNewItem(e){
            const self = this
            const newData = self.newMasterItem
            const btn = e.target
            return new Promise((resolve)=>{
                self.isClicked = true
                newData.id = self.$route.params.id
                if(!newData.costCodeId || !newData.unit || !newData.description){
                    return 
                }


                btn.disabled = true
                self.isClicked = false

                AddNewItemToMasterList(newData).then((res)=>{
                    btn.disabled = false
                    if(res=="OK"){
                        self.newMasterItem = {
                            unit : ''
                        }
                        self.loaderList = false
                        GetBoqListItems(self.pagerParams).then((res)=>{
                            self.loaderList = true
                            self.masterListData = res
                        }).catch()
                        // self.AddToListArea(newData.spaceCode)
                        self.AddToListCostCode(newData.costCodeId, newData.subCategObj, newData.costName,newData.Itemlevel)
                        resolve(true)
                    }
                    else{
                        resolve(false)
                        self.$swal(self.$i18n.t('Add Item to Master List'),res,'warning')
                    }
                })

            })
        },
        EditNewItem(e){
            const self = this
            const newData = self.updateMasterItem
            const btn = e.target

            self.isUpdateClicked = true
            newData.id = self.$route.params.id
            if(!newData.unit  || !newData.description){
                return 
            }

            btn.disabled = true
            self.isUpdateClicked = false

            EditItemToMasterList(newData).then((res)=>{
                btn.disabled = false
                if(res=="OK"){
                    const getIndex = self.masterListData.docs.findIndex(u=>u._id==newData._id)
                    self.masterListData.docs[getIndex] = newData
                    self.AddToListArea(newData.area)
                    self.$refs.closeEditModalItemBoq.click()
                }
                else{
                    self.$swal(self.$i18n.t('Edit Item from Master List'),res,'warning')
                }
            })
        },
        AppendItemParents(data){
            this.newMasterItem.costName = data.costName
            this.newMasterItem.code = data.costCode
            this.newMasterItem.subCateg = data.parents[1].name
            this.newMasterItem.mainCateg = data.parents[0].name
            this.newMasterItem.subCategObj = data.parents[1]
            this.newMasterItem.Itemlevel = data.level
        },
        GetCostCodes(search){
            return GetCostCodeChilds(search)
        },
        GetBoqsProjects(search){
            return SearchBoqFromProject({search : search, id : this.$route.params.id})
        },
        TriggerToggle(){
            const self = this
            const ids = self.masterListData.docs.map(u=>u._id)
            if(self.toggleAll){
                const toBePushed = ids.filter(u=>!self.selected.includes(u))
                if(toBePushed.length>0){
                    self.selected.push(...toBePushed)
                }
            }
            else{
                self.selected = self.selected.filter(u=>!ids.includes(u))
            }
        },
        AddToListArea(area){
            const self = this
            if(!area){
                return
            }
            const getIndex = self.areaInitialList.findIndex(u=>u==area.trim().toLowerCase())

            if(getIndex==-1){
                self.areaInitialList.push(area)
                self.areaInitialList.sort(SortFunc)
            }
        },
        AddToListCostCode(_id, parent, name,level){
            const self = this
            const getIndex = self.costCodeInitialList.findIndex(u=>u.costCodeId==_id)
            if(getIndex==-1){

                const getIndexParent = self.fullListCostCodeParents.findIndex(u=>u.costCodeId==parent._id)
                if(getIndexParent==-1){
                    self.fullListCostCodeParents.push({
                        costCodeId : parent._id,
                        costCode : parent.costCode,
                        name : parent.name,
                        level : parent.level
                    })
                }

                self.costCodeInitialList.push({
                    costCodeId : _id,
                    name : name,
                    level :level,
                    parent : {
                        costCodeId : parent._id,
                        costCode : parent.costCode,
                        name : parent.name
                    }
                })

                self.fullListCostCode.push({
                    costCodeId : _id,
                    name : name,
                    level :level,
                    parent : {
                        costCodeId : parent._id,
                        costCode : parent.costCode,
                        name : parent.name
                    }
                })
                self.SortCostCode()
            }
        },
        SaveItemToBoq(e){
            const self = this
            const btn = e.target
            const newData = self.newBoqData
            self.isNewBoqClicked = true
            if(newData.existing==='true'){
                if(!newData.boqId){
                    return
                }
            }else{
                if(!newData.name || !newData.reference || !newData.currency){
                    return
                }
            }

            if(newData.data.length==0){
                return
            }

            btn.disabled = true

            InsertItemsToBoq(newData).then((res)=>{
                self.isNewBoqClicked = false
                btn.disabled = false
                if(res!="OK"){
                    self.$swal('Error Bill of Quantities',res,'warning')
                }else{
                    self.selected = []
                    self.$refs.closeModalCreateBoq.click()
                    self.$swal('Bill of Quantities Saved','','success')
                }
            }).catch()
        },
        ChangeBoqType(){
            this.newBoqData.id = this.$route.params.id
            this.newBoqData.boqId = ''
            this.newBoqData.selectedCount = this.selected.length
            this.newBoqData.data = this.selected
            this.newBoqData.currency = ''
            this.newBoqData.currency = ''
            this.newBoqData.name = ''
            this.newBoqData.reference = ''
        },
        OnLoopCostCode(data){
            if(data!=this.loopCostCode){
                this.loopCostCode = data
                return true
            }

            return false
        },
        SortCostCode(){
            let uniqParents = []
            for(let x = 0; x< this.fullListCostCodeParents.length;x++){
                const index = this.fullListCostCodeParents[x]
                const getIndex = uniqParents.findIndex(u=>u.costCode==index.costCode)
                if(getIndex==-1){
                    uniqParents.push(index)
                }
            }
            this.fullListCostCodeParents = uniqParents
            this.fullListCostCodeParents.sort(LevelSort)
            this.fullListCostCode.sort(LevelSort)
            this.costCodeInitialList.sort(LevelSort)
        },
        ClearCostCode(){
            if(this.psudoPagerParams.ArrayCostCode.length!=0){
                this.psudoPagerParams.ArrayCostCode = []
                this.SearchChange()
            }
            
        },
        FilterCostCode(e){
            const value = e.target.value
            const cleaned = value.trim().toLowerCase()
            if(value){
                this.fullListCostCode = this.fullListCostCode.filter(u=>{
                    
                    if(u.costCode){
                        return u.name.toLowerCase().indexOf(cleaned) > -1 || u.costCode.toLowerCase().indexOf(cleaned) > -1 
                    }
                    else{
                        return u.name.indexOf(cleaned) > -1
                    }
                })
            }else{
                this.fullListCostCode = this.costCodeInitialList
            }
        },
        SearchCostCodeAll(query){
            const self =  this
            self.searchCostCodeLoading = true
            self.timerSetter(function(){
                self.searchCostCodeLoading = false
                SearchAllActiveCostCodes(query).then((res)=>{
                    self.dataCostCodes = res
                })
            })
        }

    },
    computed : mapGetters(['projectInfo', 'permission','userInfo'])
}