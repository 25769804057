<template>
    <div>
        <BreadcrumbsProject
            :breadcrumbs="[
                {
                    link : projectUrl +'/',
                    title : 'Projects',
                    isRouterLink: false
                },
                {
                    link : projectUrl +'/Project/' + projectInfo._id,
                    title : (projectInfo.projectNumber || '-') +' ' + (projectInfo.projectName || ''),
                    isRouterLink: false
                },
                {
                    link : '/boq/' + projectInfo._id,
                    title : 'Bill of Quantities',
                    isRouterLink: true
                },
                {
                    link : '/boq/items/' + boqItem._id,
                    title :(boqItem.name || '-')  + ' - (' + (boqItem.currencyId?.sign || '') +') '  + ( boqItem.currencyId?.shortName || '')  +' '+ (boqItem.currencyId?.name || ''),
                    isRouterLink: true
                },
                {
                    link : '/boq/items/' + boqItem._id + '/summary',
                    title :'Summary',
                    isRouterLink: true
                }
            ]"
        ></BreadcrumbsProject>
        <div >
            <StepItem2></StepItem2>
        </div>

        <div class="card border-top">
            <div class="card-header bg-white">
                <h6>Summary</h6>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <BoqLineItemSummary :data="lineItemSummary" :currencySign="currencySign"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import BreadcrumbsProject  from '@/components/projectInformation/BreadcrumbsProject.vue'
import BoqLineItemSummary  from '@/components/boq/BoqLineItemSummary.vue'
import StepItem2  from '@/components/layout/StepItem2.vue'
import { LinkList, ReformatNumber} from '@/helpers/utilities.js'
import {GetBoqItemSummary} from '@/actions/boqActions.js'
import 'dotenv/config'
export default {
    name : 'BoqItemsSummary',
    components : {
        BreadcrumbsProject,
        StepItem2,
        BoqLineItemSummary
    },
    methods : {
        ...mapActions(['GetProjectInfo', 'ChangeActiveLink']),
        ReformatNumber : ReformatNumber,
    },
    computed :{
        ...mapGetters(['projectInfo']),
    },
    mounted : async function(){

        const self = this
        
        const getSummaryObj = await GetBoqItemSummary(this.$route.params.id)
        if(getSummaryObj==401){
            self.$router.push('/unauthorized')
            return
        }
        self.boqItem = getSummaryObj.boq
        self.currencySign = self.boqItem.currencyId.sign 
        self.lineItemSummary = getSummaryObj.summary

        await self.GetProjectInfo(self.boqItem.projectId).catch()
        self.ChangeActiveLink(LinkList.BILL_OF_QUANTITIES)

    },
    data(){
        return {
            projectUrl : process.env.VUE_APP_PROJECT_URL,
            lineItemSummary : [],
            currencySign : '',
            boqItem : {},
        }
    }

}
</script>