<template>
    <div class="table-responsive" style="height:75vh" >
        <table class="table table-header-solid table-bordered" >
            <thead class="sticky-top">
                <tr >
                    <th style="min-width:75px;" class="sticky-left">S/N</th>
                    <th style="min-width:600px;" class="sticky-left">Description</th>
                    <th style="min-width:200px;">Space ID</th>
                    <th>Unit</th>
                    <BoqLineItemRowThead 
                    :selectedColumns="selectedColumns" />
                    <th class="sticky-right"></th>
                </tr>
            </thead>
               <tbody class="table-group-divider">
                 <tr v-if="rowDatas.length==0">
                    <td 
                     colspan="23">No Data Found
                    </td>
                </tr>

                <template v-for="(item, key) in CostCodeList" :key="key" >
                     <tr v-if="item.mainCateg!=null">
                        <td class="fw-bold bg-light-primary text-center" >
                            {{item.mainCateg.label}}
                        </td>
                        <td class="fw-bold bg-light-primary sticky-left" >
                            {{item.mainCateg.name}} 
                        </td>
                        <td class="bg-light-primary "></td>
                        <td class="bg-light-primary "></td>
                        <BoqLineItemRowHeader
                        :data="item.mainCateg.totals"
                        :selectedColumns="selectedColumns"
                        :ReformatNumber="ReformatNumber" />
                        <td class="bg-light-primary text-end fw-bold sticky-right"></td>
                    </tr>
                    <tr v-if="item.subCateg!=null">
                        <td class="fw-bold bg-light-primary text-center" >
                            {{item.subCateg.label}}
                        </td>
                        <td class="fw-bold bg-light-primary sticky-left ps-4" >
                            {{item.subCateg.name}}
                        </td>
                        <td class="bg-light-primary "></td>
                        <td class="bg-light-primary "></td>
                        <BoqLineItemRowHeader
                        :data="item.subCateg.totals"
                        :selectedColumns="selectedColumns"
                        :ReformatNumber="ReformatNumber" />
                        <td class="bg-light-primary text-end sticky-right"></td>
                    </tr>  
                    <tr  >
                        <td class="text-center">
                            {{item.lineItem.label}}
                        </td>
                        <td class="sticky-left bg-white">
                            <div >
                                <div class="ps-5" >
                                    <small class="fw-bold ">{{item.lineItem.name}}</small> 
                                    <span v-if="item.lineItem.rateOnly" class="ms-1 badge border-danger text-danger pb-1 pt-1">RATE ONLY</span>
                                    <br />
                                    <p class="small mb-0 description-line-item">
                                        {{item.lineItem.description}}
                                    </p>
                                </div>
                            </div>
                        </td>
                        <td  >{{item.lineItem.area}}</td>
                        <td>{{item.lineItem.unit}}</td>
                        <BoqLineItemRow 
                        :data="item.lineItem" :ReformatNumber="ReformatNumber" :selectedColumns="selectedColumns" />
                        <td style="width : 50px" class="sticky-right bg-white shadow-lg"  >
                            
                            <button class="btn btn-outline-primary btn-action mb-1" @click="OpenEditModal(item.lineItem)"
                            ref="ModalUpdateLineItemBtn"
                             v-if="permissionList.indexOf(ModuleActionList.BOQ_EDIT) >-1"
                            data-bs-toggle="modal" data-bs-target="#ModalUpdateLineItem"
                            >
                                <i class="fa-solid fa-edit"></i>
                            </button>
                    
                            <button v-if="permissionList.indexOf(ModuleActionList.BOQ_ITEM_REMOVE) > -1" class="btn btn-outline-warning btn-action me-1" 
                                @click="OpenDelete(item.lineItem._id)" > <i class="fa-solid fa-trash-can"></i> </button> 
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>


<script src="@/assets/boq/components/BoqItemTable.js" />
<style scoped>
    .sticky-top{
        position : sticky;
        top:0;
        z-index: 100;
    }
    .sticky-left{
        position : sticky;
        left:0;
        z-index: 50;
    }
    .sticky-right{
        position : sticky;
        right:0;
        z-index: 50;
    }
    .line-item-100{
        min-width: 100px;
    }
    .cursor-move{
        cursor:  move;
    }
</style>
