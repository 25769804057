<template>
    <div>
    <BreadcrumbsProject
        :breadcrumbs="[
            {
                link : projectUrl +'/',
                title : $t('Projects'),
                isRouterLink: false
            },
            {
                link : projectUrl +'/Project/' + projectInfo._id,
                title :  (projectInfo.projectNumber || '-') +' ' + (projectInfo.projectName || ''),
                isRouterLink: false
            },
            {
                link : '/boq/' + projectInfo._id,
                title : $t('Bill of Quantities'),
                isRouterLink: true
            },
            // {
            //     link : projectUrl + '/project/' + projectInfo._id + '/approvals',
            //     title : 'Approval List',
            //     isRouterLink: false
            // },
            {
                link : '/boq/builder/' + processItemData.relatedId,
                title : (boqItemData.name || '-') ,
                isRouterLink: true
            },
            {
                link : '/boq/review/' + processItemData._id,
                title : $t('Approval Module.Approval') ,
                isRouterLink: true
            },
        ]"
    ></BreadcrumbsProject>
   </div>
    
    <div class="d-flex justify-content-center mt-3">
        <h3 class="text-capitalize fw-bold">
            {{boqItemData.name}} 
        </h3>
    </div>
    
    <div class="row mt-3">
        <BoqProcessWorkFlow
        v-if="processItemData._id"
        :processItemData="processItemData" 
        :showApprovalDisapprove="true"
        />
    </div>
    <div class="card mt-1">
        <div class="card-header bg-white d-flex justify-content-between">
            <div>
                <small class="text-muted">{{$t('Version')}}</small> : <span>{{boqRevision.index}} </span> <br />
                <small  class="text-muted">{{$t('Currency')}}</small> : <span>({{boqRevision.currency?.sign}}) {{boqRevision.currency?.shortName}} {{boqRevision.currency?.name}}</span>
            </div>
            <div class="text-capitalize">
                <small class="text-muted">{{ $t('Approval Module.Done by') }}</small> : <span>{{boqRevision.createdBy?.fullname}}</span> <br />
            </div>
        </div>
        <div class="card-body">
             <!-- <div class="d-flex justify-content-between mb-1" >
                <div v-if="ProcessStepType.APPROVAL==processItemData.type">
                    <div v-if="processItemData.relatedList.findIndex(u=>u.action<=ProcessAction.VIEW&&u.relatedUserId._id==userAccess.userId)!=-1 
                        && currentStep.roleId._id==userAccess.roleId._id && processItemData.status==ProcessItemStatusList.PENDING">
                        <button class="btn btn-success me-1" @click="Approve"> <i class="fa-solid fa-thumbs-up"></i> Approve</button>
                        <button class="btn btn-danger" @click="Disapprove"><i class="fa-solid fa-thumbs-down"></i> Disapprove</button>
                    </div>
                </div>
               
            </div> -->
            <FigureCurrencyNote :currency="boqItemData.currencyId" />
            <div class="row">
                <div class="col-md-12">
                    <BoqSectionTableReadOnly
                    :rawSections="rawSections"
                    :boqItem="boqItemData"
                    :approvalId="processItemData._id"
                    />
                    <!-- <Loader v-if="isLoaded==false" />
                    <BoqLineItemTable v-else :rowDatas="boqListHistory" :selectedColumns="selectedColumns"/> -->
                </div> 
            </div>
        </div>
    </div>
    <div v-if="CanApprove">
        <div class="d-flex justify-content-end fixed-bottom pb-3 pe-3">
            <button class="btn btn-success me-1" data-bs-toggle="modal" data-bs-target="#BoqReviewApprove"> <i class="fa-solid fa-thumbs-up"></i> {{$t('Approval Module.Approve')}}</button>
            <button class="btn btn-danger" data-bs-toggle="modal" data-bs-target="#BoqReviewReject"><i class="fa-solid fa-thumbs-down"></i> {{$t('Approval Module.Ask For Revision')}}</button>
        </div>
    </div>
    <ProcessCommentList 
    :PageChangeComment="PageChangeComment"
    :processItem="processItemData"
    :commentList="commentList"
    />
      <Modal idModal="BoqReviewApprove" :headerTitle="$t('Approval Module.Approve')"  >
      <div>
         <div class="form-group">
            <label>{{ $t('Approval Module.Notes (optional)') }}: </label>
            <textarea v-model="approveNotes" class="form-control"></textarea>
        </div>
          
      </div>
      <template v-slot:actions>
        <button type="button" ref="closeBoqReviewApprove" class="btn btn-outline-danger" data-bs-dismiss="modal">X {{$t('Cancel')}}</button>
        <button type="button" class="btn btn-primary" @click="Approve" >
            <i class="fas fa-thumbs-up"></i> {{$t('Approve')}}
        </button>
      </template>
    </Modal>
    <Modal idModal="BoqReviewReject" :headerTitle="$t('Approval Module.Ask For Revision')"  >
      <div>
         <div class="form-group">
            <label class="required-style">{{$t('Approval Module.Notes')}}: </label>
            <textarea v-model="rejectNotes" class="form-control"></textarea>
            <small class="text-danger" v-if="!rejectNotes">{{$t('Required')}}</small>
        </div>
          
      </div>
      <template v-slot:actions>
        <button type="button" ref="closeBoqReviewReject" class="btn btn-outline-danger" data-bs-dismiss="modal">X {{$t('Cancel')}}</button>
        <button type="button" class="btn btn-primary" @click="Disapprove" >
            <i class="fas fa-thumbs-down"></i> {{$t('Approval Module.Ask For Revision')}}
        </button>
      </template>
    </Modal>
    
</template>

<script src="@/assets/boq/BoqReview.js" />
<style scoped >
    .min-width-300{
        min-width: 300px;
    }
    .min-width-200{
        min-width: 200px;
    }
    .min-width-150{
        min-width: 150px;
    }
    .min-width-125{
        min-width: 125px;
    }
    .min-width-100{
        min-width: 100px;
    }

    .text-danger-all td {
        color: #6c757d!important;
    }
    .cursor-pointer{
        cursor: pointer;
    }

</style>
