import {mapActions, mapGetters} from 'vuex'
import { LinkList, ReformatNumber, ReformatDate, RoleListInt,ApprovalStageBoq, ApprovalStageBoqObj, ModuleActionList ,ReformatFileName } from '@/helpers/utilities.js' 
import {UploadFileToFolder ,DeleteFileToFolder} from '@/helpers/msalHelpers.js'
import StepItem2 from '@/components/layout/StepItem2.vue'
import Select2 from '@/components/layout/Select2.vue'
import Modal from '@/components/layout/Modal.vue'
import Loader from '@/components/layout/Loader.vue'
import ModalNewLineItem from '@/components/boq/ModalNewLineItem.vue'
import BreadcrumbsProject  from '@/components/projectInformation/BreadcrumbsProject.vue'
import {GetQuotationOne, SearchUserFromProjectDirectory, UpdateQuotation,
    SearchBoqNoQuotations,AddBoQtoQuotation, ForApprovalQuotation, PublishQuotation, CreateBoq, ValidateQuotationBeforeApproval, GetBoQsQuotationSummaries} from '@/actions/boqActions.js'
import {SearchProjectProcessApproval,GetProcessItemQuotation, GetTokenSharepointFromAuth, GetProjectSharepointFolders, GetSystemSettings} from '@/actions/projectActions.js'
import {GetUserSignature} from '@/actions/userActions.js'
import RichTextEditor from '@/components/layout/RichTextEditor.vue'
import BoqProcessWorkFlow  from '@/components/projectInformation/BoqProcessWorkFlow.vue'
import DatePicker from '@/components/layout/DatePicker.vue'
import BoqQuotationBoqSummary from '@/components/boq/BoqQuotationBoqSummary.vue'
import BoqQuotationHeaderReadOnly from '@/components/boq/BoqQuotationHeaderReadOnly.vue'
import FigureCurrencyNote from '@/components/layout/FigureCurrencyNote.vue'
import 'dotenv/config'
export default {
    name : 'ConsolidatedList',
    components : {
        StepItem2, BreadcrumbsProject,RichTextEditor, Select2,
         Modal, Loader,ModalNewLineItem,BoqProcessWorkFlow, DatePicker,
         BoqQuotationBoqSummary, BoqQuotationHeaderReadOnly,
         FigureCurrencyNote
    },
    mounted : async function(){
        const self = this
        const quotationId = self.$route.params.id

        this.sharepointToken = await GetTokenSharepointFromAuth() 

        let getQuotation = await GetQuotationOne(quotationId)
        if(getQuotation=='401'){
            return self.$router.push('/unauthorized')
        }
        const jsonQuotation = self.ReformatJson(getQuotation)
        self.quotationData = JSON.parse(JSON.stringify(jsonQuotation))
        self.quotationDataIntial = JSON.parse(JSON.stringify(jsonQuotation))

        self.ChangeActiveLink(LinkList.QUOTATIONS)
        await self.GetProjectInfo(getQuotation.projectId._id).catch()
        // self.summaryList = await GetQuotationSummary(quotationId)
        self.summaryByBoqList = await GetBoQsQuotationSummaries(quotationId)
        self.isSummaryLoading = false
        self.isLoading = false
        self.approvalProcessList = await SearchProjectProcessApproval(getQuotation.projectId._id,'')
        self.boqList = await SearchBoqNoQuotations({id : this.projectInfo._id,search : ''})
        self.sharepointFolders = await GetProjectSharepointFolders(this.projectInfo._id)
        self.systemSettings = await GetSystemSettings()

        if(getQuotation.approvalStage==ApprovalStageBoq.FOR_APPROVAL){
            const processData = await GetProcessItemQuotation(getQuotation._id).catch()
            self.processItemData = processData
        }

        self.isEditMode = this.$route.query.isEdit || false

        
        for(let x= 0; x< self.quotationData.submittedTo.length; x++){
            const getSubmitter = self.quotationData.submittedTo[x]
            const getSignature = await GetUserSignature({projectId : getQuotation.projectId._id, userId : getSubmitter.userId._id})
            if(getSignature && getSignature.message == "OK"){
                self.quotationData.submittedTo[x].signature = getSignature.result
                // Convert the decoded string to a Uint8Array
                let decodedBlobData = Buffer.from(getSignature.result.signature,'base64').toString('binary')
                let byteNumbers  = new Array(decodedBlobData.length)
                for (let i = 0; i < decodedBlobData.length; i++) {
                    byteNumbers[i] = decodedBlobData.charCodeAt(i)
                }
                const byteArray = new Uint8Array(byteNumbers);
                self.quotationData.submittedTo[x].signatureBlob = URL.createObjectURL(new Blob([byteArray] , { type: getSignature.result.mimeType }))
            }
        }
    },
    methods : {
        ...mapActions(['ChangeActiveLink', 'GetProjectInfo']),
        ReformatNumber  :ReformatNumber,
        ReformatDate : ReformatDate,
        SearchUserFromProjectDirectory (value){
            return SearchUserFromProjectDirectory(value,0,this.projectInfo._id)
        },
        SearchClientFromProjectDirectory (value){
            return SearchUserFromProjectDirectory(value,RoleListInt.CLIENT,this.projectInfo._id)
        },
        SearchBoqNoQuotations(value){
            return SearchBoqNoQuotations({id : this.projectInfo._id,search : value})
        },
        AddNewSubmittedLine(){
            const getLastItem = this.submittedTo[this.submittedTo.length -1]
            if(getLastItem){
                if(!getLastItem.directoryId){
                    return
                }
            }

            this.submittedTo.push({
                directoryId : '',
                name : ''
            })
        },
        AddNewAttentionClient(){
            const getLastItem = this.additionalAttention[this.submittedTo.length -1]
            if(getLastItem){
                if(!getLastItem.directoryId){
                    return
                }
            }

            this.additionalAttention.push({
                directoryId : '',
                name : ''
            })
        },
        RemoveSubmittedLine(id){
            if(this.submittedTo.length==1){
                return 
            }
            const getIndex =  this.submittedTo.findIndex(u=>u.directoryId==id)
            if(getIndex>-1){
                this.submittedTo.splice(getIndex,1)
            }
        },
        RemoveAdditionalClientLine(id){
            if(this.additionalAttention.length==1){
                return 
            }
            const getIndex =  this.additionalAttention.findIndex(u=>u.directoryId==id)
            if(getIndex>-1){
                this.additionalAttention.splice(getIndex,1)
            }
        },
        async UpdateQuotation(e){
            const self = this
            const btn = e.target
            if(self.isEditing){
                return
            }
            const updateData = self.quotationData
            if(!updateData.quotationName || !updateData.quotationNumber){
                return 
            }
            
            updateData.listSubmitted = self.submittedTo
            updateData.additionalAttentionData = self.additionalAttention
            updateData.attentionClientData = self.attentionTo
            
            btn.disabled = true
            self.isEditing = true
            self.isLoading = true
            const getFiles = self.attachmentChanges
            
            let getToken = getFiles.length!=0 || this.RemovedFiles.length != 0 ?  await self.OpenPopup() : null

            let processFiles = []
            if(getFiles.length!=0){
                getToken = await self.OpenPopup() 

                for(let f = 0; f < getFiles.length; f++){
                    const file = getFiles[f]
                    const reformated = ReformatFileName(file.name)
                    const isUploaded = await UploadFileToFolder(getToken,self.systemSettings.sharepointDriveId,
                        self.sharepointFolders.projectSharepointFolders.quotationFolderId, reformated,file)
                    if(isUploaded){
                        processFiles.push({
                            sharepointId : isUploaded.id,
                            attachmentName : file.name,
                            attachmentSharepointName : reformated,
                            sharepointLink : isUploaded.webUrl,
                            isAdd : true
                        })
                    }
                }
            }

            
            updateData.attachmentsChanges = processFiles
            if(updateData.attachments){
                updateData.attachmentsChanges.push(...updateData.attachments.filter(u=>u._id!=0))
            }

            if(self.RemovedFiles.length!=0){
                let removePromise = []
                for(let r= 0; r < self.RemovedFiles.length; r++){

                    removePromise.push(DeleteFileToFolder(getToken,self.systemSettings.sharepointDriveId,self.RemovedFiles[r].sharepointId))


                }

                await Promise.all(removePromise)
            }

            const swalTitle = self.$i18n.t('Quotation Module.Update Quotation')

            UpdateQuotation(updateData).then(res=>{
                btn.disabled = false
                self.isLoading = false
                self.isEditing = false
                if(res!="OK"){
                    self.$swal(swalTitle,res,'warning')
                }else{
                    self.isEditMode = false
                    self.$swal(swalTitle, self.$i18n.t('Updated Successfully'), 'success')
                    GetQuotationOne(updateData._id).then(data=>{
                        const jsonQuotation = self.ReformatJson(data)
                        self.quotationData = JSON.parse(JSON.stringify(jsonQuotation))
                        self.quotationDataIntial = JSON.parse(JSON.stringify(jsonQuotation))
                        self.haveChanges = false
                        self.RemovedFiles = []
                        self.attachmentChanges = []
                    })
                }
            })
        },
        async ProcessAttachments(token, file){
            const self = this

            await UploadFileToFolder(token,self.systemSettings.sharepointDriveId, self.sharepointFolders.projectSharepointFolders.quotationFolderId,ReformatFileName(file.name),file)
        },
        ReformatJson(data){
            const self = this
            data.layoutDate = ReformatDate(data.layoutDate,'YYYY-MM-DD')
            data.contractDate = ReformatDate(data.contractDate,'YYYY-MM-DD')

            if(data.attentionClient){
                self.attentionTo = {
                    roleName : data.attentionClient.roleInProject,
                    name : data.attentionClient.userId.fullname,
                    directoryId : data.attentionClient.userId._id
                }

            }else{
                data.attentionClient = {
                    userId : {fullname  :''},
                    projectDirectoryId : {_id : ''}
                }

                self.attentionTo = {
                    directoryId : '',
                    name : '',
                }
            }
            self.submittedTo = []
            if(data.submittedTo.length!=0){
                for(let x=0; x<data.submittedTo.length;x++){
                    const submitter = data.submittedTo[x]

                    self.submittedTo.push({
                        roleName : submitter.roleInProject,
                        name : submitter.userId.fullname,
                        directoryId : submitter.projectDirectoryId._id
                    })
                }
            }
            self.additionalAttention = []
            if(data.additionalAttention.length!=0){
                for(let x=0; x<data.additionalAttention.length;x++){
                    const submitter = data.additionalAttention[x]
                    self.additionalAttention.push({
                        roleName : submitter.roleInProject,
                        name : submitter.userId.fullname,
                        directoryId : submitter.projectDirectoryId
                    })
                }
            }

            self.additionalAttention.push({
                directoryId : '',
                name : ''
            })
            self.submittedTo.push({
                directoryId : '',
                name : ''
            })
            return data
        },
        CancelEdit(){
            const self = this
            if(self.haveChanges){
                self.$swal({
                    title: self.$i18n.t('Quotation Module.Discard Changes'),
                    text : self.$i18n.t('Quotation Module.You have unsaved changes, discard changes'),
                    showCancelButton: true,
                    icon: 'warning',
                    confirmButtonText: 'Discard',
                }).then((res)=>{
                    if(res.isConfirmed){
                        self.quotationData = JSON.parse(JSON.stringify(self.ReformatJson(self.quotationDataIntial)))
                        self.isEditMode = false
                        self.haveChanges = false
                        self.RemovedFiles = []
                        self.attachmentChanges = []
                    }
                })
            }else{
                self.isEditMode = false
                self.haveChanges = false
            }
        },
        OpenAddBoqToQuotation(){
            this.newBoq = {}
            // this.$refs.select2BoqToQuotation.ClearData()
        },
        AddBoqToQuotation(e){
            const btn = e.target
            const self = this
            if(self.isAddingToQuotation){
                return
            }

            if(!self.newBoq.boqId){
                return 
            }
            const quotationId = self.$route.params.id

            self.newBoq.quotationId = quotationId
            self.isAddingToQuotation = true

            btn.disabled = true
            AddBoQtoQuotation(self.newBoq).then(res=>{
                self.isAddingToQuotation = false
                btn.disabled = false
                if(res!='OK'){
                    self.$swal(self.$i18n.t('Add BoQ'),res,'warning')
                }else{
                    self.isSummaryLoading = true
                    self.$refs.closeModalAddBoqQuotation.click()
                    GetBoQsQuotationSummaries(quotationId).then(data=>{
                        self.isSummaryLoading = false
                        self.summaryByBoqList = data
                    })

                    SearchBoqNoQuotations({id : this.projectInfo._id,search : ''}).then(res=>{
                        self.boqList = res
                    })
                }
            })
        },
        OpenApproval(){
            this.quotationApprovalData = {}
        },
        OpenWorkFlowModal(){
            const self = this
            ValidateQuotationBeforeApproval({
                quotationId : self.$route.params.id
            }).then((res)=>{
                if(res!='OK'){
                    self.$swal('For Approval',res,'warning')
                }else{
                    self.$router.push({name : 'BoqReviewerSetup', query : { qtnId : self.quotationId} })
                }
            })
        },
        ForApprovalQuotation(e){
            const btn = e.target
            const self = this

            if(self.isMovingForApproval){
                return
            }

            const newData = self.quotationApprovalData
            self.isApprovalClicked = true
            if(!newData.dateEnd || !newData.processId){
                return
            }
            self.isApprovalClicked = false
            newData.quotationId = this.$route.params.id

            btn.disabled = true
            self.isMovingForApproval = true
            ForApprovalQuotation(newData).then(res=>{
                btn.disabled = false
                self.isMovingForApproval = false
                if(res!='OK'){
                    self.$swal('For Approval',res,'warning')
                }else{
                    self.quotationApprovalData = {}
                    self.$refs.closeQuotationApprovalModal.click()
                    GetQuotationOne(newData.quotationId).then(data=>{
                        const jsonQuotation = self.ReformatJson(data)
                        self.quotationData = JSON.parse(JSON.stringify(jsonQuotation))
                        self.quotationDataIntial = JSON.parse(JSON.stringify(jsonQuotation))
                        
                    })
                    GetProcessItemQuotation(newData.quotationId).then((data)=>{
                        self.processItemData = data
                    })
                }
            })

        },
        PublishQuotation(){
            const self = this

            if(self.isPublishing){
                return 
            }
            const swalTitle = self.$i18n.t('Contract Sign Quotation')

            self.$swal({
                title: swalTitle,
                text : self.$i18n.t('This quotation will be the final version, Proceed?'),
                showCancelButton: true,
                icon: 'warning',
                confirmButtonText: 'Proceed',
                showLoaderOnConfirm : true,
                allowOutsideClick: () => !self.$swal.isLoading(),
                preConfirm : ()=>{
                    self.isPublishing = true
                    PublishQuotation(self.quotationData._id).then((res)=>{
                        self.isPublishing = false
                        if(res!="OK"){
                            return self.$swal(swalTitle,res,'warning')
                        }else{
                            self.$swal(swalTitle,self.$i18n.t('Published Quotation Successfully'),'success')
                            GetQuotationOne(self.quotationData._id).then(data=>{
                                const jsonQuotation = self.ReformatJson(data)
                                self.quotationData = JSON.parse(JSON.stringify(jsonQuotation))
                                self.quotationDataIntial = JSON.parse(JSON.stringify(jsonQuotation))
                            })
                        }
                    })
                }
            }).then(()=>{
                // if(res.isConfirmed){
                //     self.$swal('Published Quotation Successfully',res,'success')
                // }
            })
        },
        ExportToPdf(){
            window.print()
        },
        ResetData(){
            const self = this
            const quotationId = self.$route.params.id
            self.isSummaryLoading = true
            GetBoQsQuotationSummaries(quotationId).then(data=>{
                self.isSummaryLoading = false
                self.summaryByBoqList = data
            })

        },
        CreateBoqNew(e){
            const self = this
            const btn = e.target

            if(!self.newlyCreatedBoq.name){
                return
            }

            btn.disabled = true

            CreateBoq({
                projectId : self.projectInfo._id,
                currencyId : self.projectInfo.currencyId._id,
                reference : self.quotationData._id,
                name  :self.newlyCreatedBoq.name
            }).then(res=>{
                if(res.status==200){
                    self.$refs.closeModalNewQuotationAdd.click()
                    self.$router.push({name : 'BoqBuilder', params : {id : res.data}})
                }else{
                    btn.disabled = false
                    self.$swal('Create BoQ',res.data,'warning')
                }
            })

        },
        GetProcessItemRun(){
            location.reload()
        },
        OpenPopup(){

            if(this.sharepointToken){
                return this.sharepointToken
            }

            window.localStorage.setItem('z_token_share_value', '') 
            return new Promise((resolve) => {
             
                window.open(process.env.VUE_APP_REST_MAIN_API + '/api/auth/token/email?type=2&returnUrl='+encodeURIComponent(process.env.VUE_APP_BOQ_URL + '/page/empty/'), "_blank", "width=400,height=300")
                
                this.intervalClock = setInterval(async () => {
                    let check = window.localStorage.getItem('z_token_share_value')
                    if(check != ''){
                        const getToken = await GetTokenSharepointFromAuth()
                        this.sharepointToken = getToken
                        clearInterval(this.intervalClock)
                        resolve(getToken)
                    }
                }, 200);
            })
        },
        OnChangeFiles(){
            const self = this
            let files = self.$refs.fileDatas.files
            // self.attachmentChanges
            for(let i = 0; i < files.length; i++){
                const attachment = files[i]
                const checkIndex = self.quotationData.attachments.findIndex(u=>u.attachmentName.toLowerCase()==attachment.name.trim().toLowerCase())
                if(checkIndex==-1){
                    self.quotationData.attachments.push({
                        attachmentName : attachment.name,
                        _id : 0
                    })
                    self.attachmentChanges.push(attachment)
                }
            }
            self.$refs.fileDatas.value = null
            
        },
        OnclickLink(url){
            window.open(url,'_blank')
        },
        RemoveAttachment(id,name){
            const getIndex = this.quotationData.attachments.findIndex(u=>u._id==id && u.attachmentName==name)
            if(getIndex !=-1 ){
                if(id!=0){
                    this.RemovedFiles.push(this.quotationData.attachments[getIndex])
                }else{
                    const getChangesIndex = this.attachmentChanges.findIndex(u=>u.name==name)
                    if(getChangesIndex!=-1){
                        this.attachmentChanges.splice(getChangesIndex,1)
                    }
                }
                
                this.quotationData.attachments.splice(getIndex,1)
            }

        }

    },
    computed : {
        ...mapGetters(['projectInfo','permission']),
        IsPublished(){
            return this.quotationDataIntial.approvalStage==ApprovalStageBoq.PUBLISHED
        },
        FormatLayoutDate(){
            const self = this
            if(self.quotationData.layoutDate){
                return ReformatDate(new Date(self.quotationData.layoutDate),"Do of MMMM, YYYY")
            }else{
                return '-'
            }
        },
        ReformatBoqSummary(){
            const self = this

            if(self.summaryByBoqList.length==0){
                return []
            }

            let parentLetter = 65

            let reformat = self.summaryByBoqList.map((u)=>{

                u.letter = String.fromCharCode(parentLetter++)
                let mainParentId = ''
                let mainParentCount = 1

                for(let x = 0; x < u.data.length; x++){
                    const getData = u.data[x]
                    let subCount = 1
                    if(getData.parents[0].costCodeId!=mainParentId){
                        mainParentId = getData.parents[0].costCodeId
                    
                        getData.mainCateg = {
                            label : u.letter + mainParentCount,
                            name : getData.parents[0].name
                        }
                    }
                    getData.label = u.letter +  mainParentCount + '.' + (subCount++)
                    getData.sellRate = ReformatNumber(u.data[x].sellRate)
                    getData.sellTotal = ReformatNumber(u.data[x].sellTotal)


                }


                return u
            })
            return reformat
        },
    //     SummaryRender(){
    //         const self = this

    //         if(self.summaryList.length==0){
    //             return []
    //         }

    //         const summaryData = JSON.parse(JSON.stringify(self.summaryList))

    //         let parent = ''
    //         let parentLetter = 65
    //         let subCount = 1
    //         let subTotal = 0

    //         const size = self.quotationData.projectId.size || 0

    //         let totalAll = 0

    //         summaryData.forEach((u)=>{
    //             totalAll += u.sellTotal
    //         })
    //         return summaryData.map((u,index)=>{
    //             u.willShowParent = false

    //             subTotal += u.sellTotal
                
    //             if(parent!=u.parent.costCodeId){
    //                 parent = u.parent.costCodeId
    //                 u.willShowParent = true
    //                 subCount = 1
    //                 u.label = String.fromCharCode(parentLetter++)
    //             }

    //             if(index==summaryData.length -1){
    //                 const subPerSize =subTotal / size
    //                 u.subTotal = {
    //                     label : String.fromCharCode(parentLetter - 1),
    //                     total : ReformatNumber(subTotal),
    //                     percentTotal :ReformatNumber((subTotal / totalAll) * 100,0),
    //                     perSize :  ReformatNumber(subPerSize)
    //                 }
    //                 u.overAllTotal = {
    //                     lastLabel : String.fromCharCode(parentLetter - 1),
    //                     percentTotal :totalAll ==0 ?'0' :'100',
    //                     perSize :  ReformatNumber(totalAll / size),
    //                     total : ReformatNumber(totalAll)
    //                 }
    //             }else if(u.parent.costCodeId!= summaryData[index + 1].parent.costCodeId){
    //                 const subPerSize =subTotal / size
    //                 const letter = parentLetter==65 ? 65 : parentLetter-1
    //                 u.subTotal = {
    //                     label : String.fromCharCode(letter),
    //                     total : ReformatNumber(subTotal),
    //                     percentTotal :ReformatNumber((subTotal / totalAll) * 100,0),
    //                     perSize :  ReformatNumber(subPerSize)
    //                 }
    //                 subTotal =0
    //             }


    //             u.index = subCount++
    //             u.perSize = ReformatNumber(u.sellTotal / size)
    //             u.percentTotal = ReformatNumber((u.sellTotal / totalAll) * 100,0)
    //             u.sellTotal = ReformatNumber(u.sellTotal)
    //             return u
    //         })
    //     }
    },
    watch : {
        quotationData: {
            handler() {
               const self = this
               if(self.isEditMode){
                self.haveChanges = true
               }
            },
            deep: true
        },
        attentionTo: {
            handler() {
               const self = this
               if(self.isEditMode){
                self.haveChanges = true
               }
            },
            deep: true
        },
        submittedTo: {
            handler() {
               const self = this
               if(self.isEditMode){
                self.haveChanges = true
               }
            },
            deep: true
        },
        additionalAttention: {
            handler() {
               const self = this
               if(self.isEditMode){
                self.haveChanges = true
               }
            },
            deep: true
        },
        attachmentChanges: {
            handler() {
               const self = this
               if(self.isEditMode){
                self.haveChanges = true
               }
            },
            deep: true
        },
        RemovedFiles : {
            handler() {
               const self = this
               if(self.isEditMode){
                self.haveChanges = true
               }
            },
            deep: true
        },
    },
    beforeUnmount() {
        if(this.intervalClock){
            clearInterval(this.intervalClock)
        }

        for(let x= 0; x< this.quotationData.submittedTo.length; x++){
            const blob = this.quotationData.submittedTo[x].signatureBlob
            if(blob){
                URL.revokeObjectURL(blob);
            }
        }

        // if(quotationData.submittedTo){

        // }
    },
    data(){
        return {
            projectUrl : process.env.VUE_APP_PROJECT_URL,
            boqUrl : process.env.VUE_APP_BOQ_URL,
            quotationData : {
                _id : 1,
                approvalStage : 1,
                attentionClient : {
                    userId : {fullname  :''}
                }
            },
            quotationDataIntial : {
                approvalStage : 1,
                attentionClient : {
                    userId : {fullname  :''}
                }
            },
            submittedTo : [],
            additionalAttention : [],
            attentionTo : {
                directoryId : '',
                name : ''
            },
            dateNow : new Date() ,
            quotationId : this.$route.params.id,
            consolidatedList : [],
            isLoading : true,
            isEditMode : false,
            isEditing : false,
            isSummaryLoading : true,
            haveChanges : false,
            summaryList : [],
            summaryByBoqList : [],
            newBoq : {},
            isAddingToQuotation : false,
            isMovingForApproval : false,
            approvalProcessList : [],
            quotationApprovalData : {},
            isApprovalClicked : false,
            ApprovalStageBoq : ApprovalStageBoq,
            ApprovalStageBoqObj : ApprovalStageBoqObj,
            isPublishing : false,
            ModuleActionList : ModuleActionList,
            boqList : [],
            newlyCreatedBoq : {},
            processItemData : {
                status : 1,
                internalApprovers : [],
                externalApprovers: []
            },
            returnUrl : location.pathname,
            intervalClock : null,
            attachmentChanges : [],
            RemovedFiles : [],
            sharepointToken : null,
            sharepointFolders : null,
            systemSettings : null,
        }
    }
}