<template>
    <button type="button" class="d-none"  ref="emailModalTrigger" data-bs-toggle="modal" data-bs-target="#SenderEmailModal"></button>
    <ul class="progressbar-custom ">
        <li v-for="(item,key) in listOfSteps" :key="key" :data-name="item" :class=" key==0 || ProcessItemStatusList.ENDED==status ? 'active ' + classLi : classLi" @click.stop="clickStepper(item)" >
            
            <div class="card floating-card shadow  text-black z-index-300" v-if="openStep==item && key==1" @click.stop >
                <div class="card-header bg-white">
                    <h6>{{item}}</h6>
                </div>
                <div class="card-body">
                    <div class="row " >
                        <template v-for="(child, cKey) in relatedList" :key="cKey">
                            <div class="col-md-12">
                                <div class="d-flex justify-content-end" >
                                    <small role="button" v-if="status==ProcessItemStatusList.PENDING" title="Send Email" 
                                    class="text-primary d-flex justify-content-end" @click="OpenSendEmail(child.relatedUserId)" >  
                                        <i class="fa fa-envelope d-flex align-items-center "></i> 
                                    </small>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="form-group">
                                    <label>Name</label>
                                    <input type="text" class="form-control-plaintext text-center text-capitalize h6" readonly :value="child.relatedUserId.fullname" />
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Viewed on Date</label>
                                    <input type="text" class="form-control-plaintext h6" readonly :value="ReformatDate(child.actionDate)"  />
                                </div>
                            </div>
                        </template>
                        <hr />
                        <template v-if=" ProcessItemStatusList.PENDING==status">
                            <div class="col-md-6 ">
                                <small role="button"
                                data-bs-toggle="modal" data-bs-target="#ProccessItemAddModalView"
                                @click="()=>{ this.newStepView.relatedUserId = ''}"
                                class="text-primary d-flex justify-content-start"> <i class="fa-solid fa-plus d-flex align-items-center "></i>  Add Viewer</small>
                            </div>
                            <div class="col-md-6 ">
                                <small role="button" @click="EndReviewRun" class="text-warning d-flex justify-content-end"> <i class="fa-solid fa-hourglass-end d-flex align-items-center "></i> End Review</small>
                            </div>
                        </template>
                        <div v-else>
                            <div class="col-md-12 ">
                                <small class="text-success d-flex justify-content-end"> <i class="fa-solid fa-file-circle-check d-flex align-items-center "></i>Review Ended</small>
                            </div>
                        </div>
                    </div>
                    
                    
                </div>
            </div>
        </li>
    </ul>

    <Modal idModal="ProccessItemAddModalView" headerTitle="Add User to Process"  >
        <form>
            <div class="form-group">
                <label class="required-style">User </label>
                <Select2 
                :filterQuery="GetUserFromDirectory"
                indexValue="_id"
                labelName="fullname"
                placeHolder="Search User From Project"
                v-model:optionValue="newStepView.relatedUserId"
                :onOptionClick="AppendRelatedUser"
                ></Select2>
                <small class="text-warning" v-if="!newStepView.relatedUserId">Required</small>
            </div>
        </form>
        <template v-slot:actions>
            <button type="button" ref="clseItemAddModalView" class="btn btn-outline-danger" data-bs-dismiss="modal">X Cancel</button>
            <button type="button" class="btn btn-primary"  @click="AddNewUserToProcess"><i class="fa-solid fa-save"></i> Save Changes</button> 
      </template>
    </Modal>
    <Modal idModal="SenderEmailModal" headerTitle="Send Email" modalSize="modal-lg"  >
            <div class="form-group">
                    <label>Send To </label>
                    <input type="text" class="form-control-plaintext" readonly :value="sendEmailData.sendToEmail" />
            </div>
            <div class="form-group">
                <label class="required-style">Subject </label>
                <input type="text" class="form-control" v-model="sendEmailData.subject" placeholder="Subject of the email" />
                <small class="text-warning" v-if="sendEmailClicked && !sendEmailData.subject">Required</small>
            </div>
            <div class="form-group">
                <label class="required-style">Email Body </label>
                <textarea class="form-control" v-model="sendEmailData.message" placeholder="Body of the email"></textarea>
                <!-- <RichTextEditor v-model="sendEmailData.message" /> -->
                <small class="text-warning" v-if="sendEmailClicked && !sendEmailData.message">Required</small>
            </div>
            <template v-slot:actions>
                <button type="button" ref="closeSenderEmailModal" class="btn btn-outline-danger" data-bs-dismiss="modal">X Cancel</button>
                <div>
                    <button type="button" class="btn btn-info me-1" v-if="!haveToken" @click="AuthorizeUser"><i class="fa-solid fa-user-check"></i> Authorize</button> 
                    <button type="button" class="btn btn-primary" v-if="haveToken" @click="SendEmail"><i class="fa fa-envelope"></i> Send Email</button> 
                </div>
            </template>
        </Modal>
</template>

<script>
import Select2 from '@/components/layout/Select2.vue'
import Modal from '@/components/layout/Modal.vue'
import{SearchUserFromProjectDirectory,AddUserToStepProcessLinked, UserSendEmailProcess} from '@/actions/projectActions.js'
import{EndBoqReview} from '@/actions/boqActions.js'
import {ReformatDate, ProcessItemStatusList} from '@/helpers/utilities.js'
import {CheckSessionMsal} from '@/actions/userActions.js' 
export default {
    name : 'ProcessViewStepListPreview',
    props : ['stepList','relatedList','projectId','processId', 'GetProcessData', 'status','emailPreText','headerPretext', 'emailLink'],
    data(){
        return {
            openStep : '',
            classLi : 'width-5-per',
            listOfSteps : ['Published', 'Viewers'],
            newStepView : {
                id : this.projectId,
                processId : this.processId,
            },
            ProcessItemStatusList : ProcessItemStatusList,
            sendEmailClicked : false,
            sendEmailData  : {
                id : '',
                message : '',
                subject : '',
                sendTo : ''
            },
            haveToken : false,
        }
    },
    components : {
        Select2,Modal
    },
    mounted :function(){
        this.classLi = this.stepList.length < 5 ? 'width-'+ this.stepList.length +'-per' : 'width-5-per'
        const self = this
        CheckSessionMsal().then(res=>{
            self.haveToken = res
        })
    },
    updated :function(){
        this.classLi = this.listOfSteps.length < 5 ? 'width-'+ this.listOfSteps.length +'-per' : 'width-5-per'
    },
    methods: {
        clickStepper(item){
            this.openStep = this.openStep==item ? '' : item
        },
        ReformatDate(date){
            if(!date){
                return '-'
            }
            return ReformatDate(date)
        },
        EndReviewRun(){
            const self = this
            self.$swal({
                title: 'End Review?',
                showCancelButton: true,
                icon: 'warning',
                confirmButtonText: 'End',
                showLoaderOnConfirm : true,
                allowOutsideClick: () => !self.$swal.isLoading(),
                preConfirm : ()=>{
                    return EndBoqReview(self.processId).then((res)=>{
                        if(res!="OK"){
                            self.$swal('Review Ended', res, 'warning')
                        }else{
                            self.$swal('Review Ended', '', 'success')
                            self.GetProcessData()
                        }
                    })
                }
            }).then(()=>{
            })
        },
        GetUserFromDirectory(search){
            return SearchUserFromProjectDirectory(search,'',this.projectId )
        },
        AppendRelatedUser(data){
            this.newStepView.roleId = data.roleId
        },
        AddNewUserToProcess(e){
            const btn = e.target
            const self = this
            
            self.newStepView.id = self.projectId,
            self.newStepView.processId = self.processId

            if(!self.newStepView.relatedUserId){
                return
            }

            btn.disabled = true
            AddUserToStepProcessLinked(self.newStepView).then((res)=>{
                btn.disabled = false
                if(res=="OK"){
                    self.$refs.clseItemAddModalView.click()
                    self.GetProcessData()
                }else{
                    self.$swal('Add User to Step', res, 'warning')
                }
            })
        },
        OpenSendEmail(user){
            const self = this
            self.sendEmailData.sendTo = user._id
            self.sendEmailData.sendToEmail = user.email
            self.sendEmailData.message = self.emailPreText || ''
            self.sendEmailData.subject = self.headerPretext || ''
            self.$refs.emailModalTrigger.click()
        },
        AuthorizeUser(){
            const self= this
            window.open(process.env.VUE_APP_REST_MAIN_API + '/api/auth/token/email','_blank')
            setTimeout(function(){
                self.haveToken = true
            },2000)
        },
        SendEmail(e){
            const self = this

            CheckSessionMsal().then(res=>{
                if(!res){
                    self.$swal('Authorization Required', 'Please click the authorize button', 'warning')
                    self.haveToken = false
                }else{
                    const emailData = self.sendEmailData
                    const btn = e.target
                    self.sendEmailClicked = true
                    btn.disabled = false
                    emailData.id = self.processId
                    if(!emailData.message || !emailData.subject || !emailData.sendTo ){
                        return
                    }
                    self.sendEmailData.link = self.emailLink || ''
                    
                    self.sendEmailClicked = false
                    

                    btn.disabled = true
                    UserSendEmailProcess(self.sendEmailData).then(send=>{
                        btn.disabled = false
                        if(send=='OK'){
                            self.$swal('Email sent successfully','','success')
                        }else{
                            self.$swal('Email Send',send,'warning')
                        }
                    })
                }

            })
            
        }

    },

}
</script>
<style scoped>
    .width-1-per{
        width:  50%;
    }
    .width-2-per{
        width:  50%;
    }
    .width-3-per{
        width:  30%;
    }
    .width-4-per{
        width:  25%;
    }
    .width-5-per{
        width:  20%;
    }
    .z-index-300 {
        z-index: 300;
    }
</style>
