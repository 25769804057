<template>
    <div>
        <div class="text-end d-none d-print-block">
            <a><img src="https://staff.mmoseronelink.com/mmoser-associates.svg" alt="" width="300" height="40"></a>
        </div>
        <div class="d-flex justify-content-between">
            <div>
                <div>
                    <span class="text-muted">{{ $t('Project') }} : </span>
                    <span class="fw-bold text-capitalize">{{ projectInfo.projectNumber }} {{ projectInfo.projectName }}</span>
                </div>
                <div>
                    <span class="text-muted">{{ $t('Bill Of Quantity') }} : </span>
                    <span class="fw-bold text-capitalize">{{ boqItem.name }}</span>
                </div>
            </div>
            <div>
                <div>
                    <span class="text-muted">{{ $t('Date') }} : </span>
                    <span class="fw-bold">{{ ReformatDate(new Date())}}</span>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-between pt-2 pb-2">
            <router-link :to="{name : 'BoqBuilder', params : {id : boqItem._id}}" class="btn btn-default text-black d-print-none"> 
                <i class="fa-solid fa-arrow-left-long"></i> {{ $t('Back') }}
            </router-link>
            <div>
                
                <button class="btn btn-default text-black d-print-none me-1" @click="print"><i class="fa-solid fa-print"></i> {{ $t('Print') }}</button>
                <button class="btn btn-default text-black d-print-none" :disabled="downloading" @click="downloadExcel"><i class="fa-solid fa-file-excel"></i> {{$t('Download')}}</button>
            </div>
        </div>
        <div class="bg-white p-2">
            <BoqCreatorPrintView  :sectionsList="sectionsList" :IsOpenBook="IsOpenBook" :currencySign="projectInfo.currencyId?.sign" />
        </div>
    </div>
</template>

<script>
import {GetItemsFromBoqByBits, BoQItemExportForClient, GetNewBoqCreatorSections} from '@/actions/boqActions.js'
import {ReformatDate, ContractTypeInt, LinkList} from '@/helpers/utilities.js'
import  {mapActions, mapGetters} from 'vuex'
import BoqCreatorPrintView from '@/components/boq/BoqCreatorPrintView.vue'
export default{
    name : 'HleBoqItemsPrint',
    components : {BoqCreatorPrintView},
    data(){
        return {
            boqId : this.$route.params.id,
            lineItems : [],
            boqItem : {
                status : 1,
                currencyId  : {
                    sign : '',
                }
            },
            sectionsList : {
                sections : [],
                overAllTotal : {}
            },
            boqListParams : {
                id : this.$route.params.id,
                costCode : '',
                area : '',
                page : 1,
                take : 50,
                skip : 0
            },
            downloading : false,
            dataReady : false
        }
    },
    mounted :async function(){
        const self = this
        self.ChangeActiveLink(LinkList.BILL_OF_QUANTITIES)
        const boqItem = await self.GetBoqItemRun(self.boqId)
        self.boqItem = boqItem
        await self.GetProjectInfo(boqItem.projectId)
        self.sectionsList = await GetNewBoqCreatorSections(self.boqId,1)
        self.dataReady = true
    },
    methods : {
        ...mapActions(['GetBoqItemRun', 'GetProjectInfo','ChangeActiveLink']),
        ReformatDate,
        print(){
            window.print()
        },
        async downloadExcel(){
            if(this.downloading){ return }
            this.downloading = true
            await BoQItemExportForClient(this.boqId,this.boqItem.name)
            this.downloading = false
        },
        async GetListByBits(){
            const self = this

            self.lineItems = []
            let willFetch = true
            self.boqListParams.skip = 0
            while(willFetch){
                const bits = await GetItemsFromBoqByBits(self.boqListParams)
                if(bits.length!=0){
                    self.lineItems.push(...bits)
                }
                if(bits.length==self.boqListParams.take){
                    self.boqListParams.skip += 50
                }else{
                    willFetch = false
                }
            }
        },
    },
    computed:{
        IsOpenBook(){
            return this.projectInfo.contractTypeInt==ContractTypeInt.OPEN_BOOK
        },
        ...mapGetters(['projectInfo']),
    }
}

</script>