<template>
    <div class="print-content">
        <div class="d-flex justify-content-between p-3 d-print-none bg-white">
            <!-- <router-link :to="{name : 'BoqQuotation', params : {id : quotationData._id}}" class="btn btn-default text-black">
                <i class="fa-solid fa-arrow-left-long"></i> Back To Quotation
            </router-link> -->
            <router-link :to="returnUrl" class="btn btn-default text-black">
                <i class="fa-solid fa-arrow-left-long"></i> {{$t('Back To Quotation')}}
            </router-link>
            <button class="btn btn-default text-black" :disabled="!isLoaded" @click="PrintPage"><i class="fa-solid fa-print"></i> {{$t('Print')}}</button>
        </div>
        <BoqQuotationHeaderReadOnly :quotationData="quotationData" />
        <div class="bg-white ">
            <table class="table table-bordered">
                <thead class="">
                    <tr class=" ">
                        <th>{{$t('S/N')}}</th>
                        <th style="min-width:600px;width:600px;" class="">
                            {{$t('Description')}}
                        </th>
                        <th class="" >{{$t('Unit')}}</th>
                        <th  style="min-width:100px;width:100px;" class="">{{$t('Space ID')}}</th>
                        <th class="text-center min-width-100" > {{$t('Quantity')}}</th>
                        <th class="text-center min-width-100">  {{$t('Rate')}}({{projectInfo.currencyId?.sign}})</th>
                        <th class="text-center min-width-100"> {{$t('Total')}}({{projectInfo.currencyId?.sign}})</th>
                    </tr>
                </thead>
                <tr  v-if="!isLoaded">
                    <td class="text-center" colspan="7">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">{{$t('Loading')}}...</span>
                        </div>
                    </td>
                </tr>
                <tbody v-else>
                    <tr v-if="boqSections.length==0">
                        <td class="text-center" colspan="7">{{$t('No data Found')}}</td>
                    </tr>
                    <template v-for="(item,key) in boqSections" :key="key">
                        <tr class="bg-light fw-bold" >
                            <td></td>
                            <td class="fw-bold text-capitalize">{{ item.name }}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <BoqCreatorPrintViewRow :sections="item.data.sections" :IsOpenBook="false" />
                        <tr v-for="(lineItem,lineItemkey) in item.lineItems" :key="lineItemkey">
                            <td>
                                {{ lineItemkey + 1 }}
                            </td>
                            <td>
                                <div >
                                    <div class="ps-5" >
                                        <small class="fw-bold text-capitalize" v-if="userInfo.language=='en'"> {{lineItem.name}}</small> 
                                        <small class="fw-bold " v-else> {{lineItem.nameCh}}</small> 
                                        <br />
                                        <p class="small mb-0 description-line-item">
                                            {{lineItem.description}}
                                        </p>
                                    </div>
                                </div>
                            </td>
                            <td>
                                {{lineItem.unit}}
                            </td>
                            <td>
                                {{lineItem.area}}
                            </td>
                            <td class="text-end">
                                <span v-if="!lineItem.rateOnly">
                                    {{ReformatNumber(lineItem.totalQuantity,0,2)}}
                                </span>
                                 <span class="text-danger" v-else>
                                    {{$t('RATE ONLY')}}
                                </span>
                            </td>
                            <td class="text-end">
                                    {{ReformatNumber(lineItem.sellRate)}}
                            </td>
                            <td class="text-end">
                                <span v-if="!lineItem.rateOnly">
                                    {{ReformatNumber(lineItem.sellTotal)}}
                                </span>
                                 <span class="text-danger" v-else>
                                    -
                                </span>
                            </td>
                        </tr>
                        
                        <tr class="bg-light-primary fw-bold" v-if="item.data.sections.length==0">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="fw-bold text-capitalize text-end">{{ $t('Sub Total Of') }} {{ item.name }}</td>
                            <td class="text-end">{{ ReformatNumber(item.data.overAllTotal.sellTotal) }}</td>
                        </tr>
                    </template>
                    <tr class="bg-light-primary fw-bold" >
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td class="fw-bold text-capitalize text-end">{{ $t('Overall Total') }}</td>
                        <td class="text-end">{{ ReformatNumber(overAllTotal) }}</td>
                    </tr>
                    <!-- <template v-for="item in LineItemList" :key="item._id">
                        <tr v-if="item.mainCateg!=null">
                            <td class="fw-bold " >
                                {{item.mainCateg.label}} {{item.mainCateg.name}}
                            </td>
                            <td ></td>
                            <td ></td>
                            <td ></td>
                            <td ></td>
                            <td class=" text-end fw-bold">{{ReformatNumber(item.mainCateg.sellTotal)}}</td>
                        </tr>
                        <tr v-if="item.subCateg!=null">
                            <td class="fw-bold   ps-4" >
                                {{item.subCateg.label}} {{item.subCateg.name}}
                            </td>
                            <td class=" "></td>
                            <td class=" "></td>
                            <td class=""></td>
                            <td class=""></td>
                            <td class=" text-end fw-bold">{{ReformatNumber(item.subCateg.sellTotal)}}</td>
                        </tr>
                        <tr >
                            <td>
                                <div >
                                    <div class="ps-5" >
                                        <small class="fw-bold ">{{item.label}} {{item.name}}</small> 
                                        <br />
                                        <p class="small mb-0 description-line-item">
                                            {{item.description}}
                                        </p>
                                    </div>
                                </div>
                            </td>
                            <td>
                                {{item.unit}}
                            </td>
                            <td>
                                {{item.area}}
                            </td>
                            <td class="text-end">
                                <span v-if="!item.rateOnly">
                                    {{ReformatNumber(item.totalQuantity,0,2)}}
                                </span>
                                 <span class="text-danger" v-else>
                                    RATE ONLY
                                </span>
                            </td>
                            <td class="text-end">
                                    {{projectInfo.currencyId.sign}}{{ReformatNumber(item.sellRate)}}
                            </td>
                            <td class="text-end">
                                <span v-if="!item.rateOnly">
                                    {{projectInfo.currencyId.sign}}{{ReformatNumber(item.sellTotal)}}
                                </span>
                                 <span class="text-danger" v-else>
                                    -
                                </span>
                            </td>
                        </tr>
                    </template> -->
                    
                </tbody>
            </table>
        </div>
    </div>
</template>

<style scoped>
.sticky-after-header {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 50px;
  background-color: white;
}
@media print {
    
    .print-content {
        font-size : 10px !important;
    }
    .print-body {
        margin-top : 190px;
    }
    .signature-padding{
        margin-top:50px
    }
    body {
        margin-top: 0.1mm;
        margin-bottom: 0.15mm;
        margin-right: 0.30mm;
        margin-left: 0.30mm;
    }

    .table>:not(caption)>*>* {
        padding: 0.2rem 0.2rem;
    }

    .bg-light{
        padding-top: 0px;
        padding-bottom: 0px;
        print-color-adjust: exact;
        -webkit-print-color-adjust: exact;
    }

    .bg-separator{
        font-weight: 600;
        padding-top  : 15px;
        background-color: #ffc000;
        print-color-adjust: exact;
        -webkit-print-color-adjust: exact;
        border-bottom: 1px solid #000;
    }

    .min-width-100{
        min-width: 100px;
    }
}
</style>

<script>
import {LinkList, ReformatNumber, ReformatDate} from '@/helpers/utilities.js'
import {mapActions, mapGetters} from 'vuex'
import {GetQuotationOne,GetQuotationOneLineItemsByBits, GetQuotationOneLineItemsSections} from '@/actions/boqActions.js'
import BoqQuotationHeaderReadOnly from '@/components/boq/BoqQuotationHeaderReadOnly.vue'
import BoqCreatorPrintViewRow from '@/components/boq/BoqCreatorPrintViewRow.vue'
import 'dotenv/config'
export default {
    name : 'BoqQuotationAttachedItems',
    components : {
        BoqQuotationHeaderReadOnly, BoqCreatorPrintViewRow
    },
    mounted : async function(){

        const self = this
        self.ChangeActiveLink(LinkList.QUOTATIONS)

        const quotationId = self.$route.params.id
        const  quotationData = await GetQuotationOne(quotationId)
        if(quotationData=='401'){
            return self.$router.push('/unauthorized')
        }
        await self.GetProjectInfo(quotationData.projectId._id)

        const revisionId = self.$route.query.revisionId
        if(revisionId){
            self.lineItemParams.showLive = false
            self.lineItemParams.revisionId = revisionId

            self.quotationData = quotationData.revisionList.find(u=>u._id==revisionId)
        }else{
            self.quotationData = quotationData
        }
        await self.GetDataByBits()
        self.boqSections =  await GetQuotationOneLineItemsSections({
            id : self.$route.params.id,
            revisionId :  this.$route.query.revisionId
        })
        let overAllTotal = 0

        const findTheChild = (childs, boqId) =>{
            for(let i = 0; i < childs.length; i++){
                const getChild = childs[i]
                if(getChild.subTotal?.sellTotal!=0 && getChild.level!=1){
                    getChild.lineItems = self.lineItems.filter(u=>u.boqId==boqId && u.boqSequenceParentId==getChild.id)
                }

                if(getChild.childs.length!=0){
                    findTheChild(getChild.childs,boqId)
                }
                
            }
        }
        //add the line items
        for(let x =0; x<self.boqSections.length;x++)
        {
            const boq = self.boqSections[x]
            if(boq.data.sections.length==0){
                boq.lineItems = self.lineItems.filter(u=>u.boqId==boq._id)
            }
            else{
                findTheChild(boq.data.sections,boq._id)
            }
            overAllTotal += boq.data.overAllTotal.sellTotal
        }

        self.overAllTotal = overAllTotal

    },
    methods : {
        ...mapActions(['ChangeActiveLink', 'GetProjectInfo']),
        ReformatNumber :ReformatNumber,
        ReformatDate :ReformatDate,
        async GetDataByBits(){
            const self = this

            self.lineItems = []
            let willFetch = true
            self.lineItemParams.skip = 0
            self.isLoaded = false
            while(willFetch){
                const bits = await GetQuotationOneLineItemsByBits(self.lineItemParams)
                if(bits.length!=0){
                    self.lineItems.push(...bits)
                }
                if(bits.length==self.lineItemParams.take){
                    self.lineItemParams.skip += 50
                }else{
                    willFetch = false
                }
            }

            self.isLoaded = true
        },
        PrintPage(){
            window.print()
        }
    },
    computed :{
        ...mapGetters(['projectInfo','permission','userInfo']),
        FormatLayoutDate(){
            const self = this
            if(self.quotationData.layoutDate){
                return ReformatDate(new Date(self.quotationData.layoutDate),"Do of MMMM, YYYY")
            }else{
                return '-'
            }
        },
        LineItemList(){
            const self = this 
            if(self.lineItems.length==0){
                 return []
            }

            let parentLetter = 65
            let subCount = 1
            let lineItemSubCount = 1
      
            let mainCateg = { costCodeId : '' }
            let subCateg = { costCodeId : '' }
      
            let fieldListMain = {
                 sellTotal : 0,
                 index : 0
             }
      
             let fieldListSub = {
                sellTotal : 0,
                index : 0
             }
      
             let totalList= []
             let totalListSub= []
      
            const arrayLength = self.lineItems.length
            let ReformatData = self.lineItems.map((u,index)=>{
                 let showMainCateg = false
                 let showSubCatag = false
                 const nextLineItem = self.lineItems[index + 1]
                 if(u.rateOnly!=true){
                    //main category sum 
                    fieldListMain.sellTotal += u.sellTotal
                    //sub category sum
                    fieldListSub.sellTotal += u.sellTotal
                 }
      
      
                 if(u.parents[0].costCodeId!=mainCateg.costCodeId){
                    showMainCateg = true
                    mainCateg = u.parents[0]
                    mainCateg.label  = String.fromCharCode(parentLetter++)
                    subCount = 1
                    fieldListMain.index = index
                 }
             
                 if(u.parents[1].costCodeId!=subCateg.costCodeId){
                    showSubCatag = true
                    subCateg = u.parents[1]
                    subCateg.label  = mainCateg.label + (subCount++)
                    fieldListSub.index = index
                    lineItemSubCount =  1
                 }
             
                 //main category
                 if(index + 1==arrayLength){
                    totalList.push({
                        sellTotal : fieldListMain.sellTotal  ,
                        index : fieldListMain.index,
                    })
                }else if(u.parents[0].costCodeId!=nextLineItem.parents[0].costCodeId){
                    totalList.push({
                        sellTotal : fieldListMain.sellTotal  ,
                        index : fieldListMain.index,
                    })
 
                    fieldListMain = {
                        sellTotal : 0  ,
                        index : 0,
                    }
                }
                //sub category
                if(index + 1==arrayLength){
                    totalListSub.push({
                        sellTotal : fieldListSub.sellTotal  ,
                        index : fieldListSub.index,
                    })
                }else if(u.parents[1].costCodeId!=nextLineItem.parents[1].costCodeId){
                    totalListSub.push({
                        sellTotal : fieldListSub.sellTotal  ,
                        index : fieldListSub.index,
                    })
 
                    fieldListSub = {
                        sellTotal : 0  ,
                        index : 0,
                    }
                }
                u.label = subCateg.label + '.' + (lineItemSubCount++)
                u.mainCateg = showMainCateg ? mainCateg : null
                u.subCateg = showSubCatag ? subCateg : null
                return u
            })
            //loop main totals
            for(let x=0;x<totalList.length;x++){
                const data = totalList[x]
                ReformatData[data.index].mainCateg
                ReformatData[data.index].mainCateg.sellTotal = data.sellTotal  
            }
            for(let x=0;x<totalListSub.length;x++){
                const data = totalListSub[x]
                ReformatData[data.index].subCateg.sellTotal = data.sellTotal  
            }
            return ReformatData
        }
    },
    data(){
        return {
            overAllTotal : 0,
            boqSections : [],
            quotationData: {
                _id : 1,
                approvalStage : 1,
                attentionClient : {
                    userId : {fullname  :''}
                }
            },
            quotationId : this.$route.params.id,
            projectUrl : process.env.VUE_APP_PROJECT_URL,
            boqUrl : process.env.VUE_APP_BOQ_URL,
            lineItemParams : {
                id : this.$route.params.id,
                take : 50,
                skip : 0,
                showLive : this.$route.query.revisionId===undefined,
                revisionId : this.$route.query.revisionId
            },
            returnUrl :  this.$route.query.returnUrl,
            lineItems : [],
            isLoaded : false
        }
    }
}
</script>