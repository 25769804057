<template>
    <div>
        <BreadcrumbsProject
        :breadcrumbs="[
            {
                link : projectUrl +'/',
                title : $t('Projects'),
                isRouterLink: false
            },
            {
                link : projectUrl +'/Project/' + projectInfo._id,
                title :  (projectInfo.projectNumber || '-') +' ' + (projectInfo.projectName || ''),
                isRouterLink: false
            },
            {
                link : '/boq/' + projectInfo._id+'/quotation/list',
                title : $t('Quotations'),
                isRouterLink: true
            },
            {
                link : '/boq/' + quotationData._id + '/quotation/history',
                title : (quotationData.quotationNumber || '') + ' ' + (quotationData.quotationName || '') + ' - ' +$t('History'),
                isRouterLink: true
             },
        ]"
        ></BreadcrumbsProject>
        <div class="d-flex justify-content-between">
            <div class="col-md-3 ">
                <button class="btn btn-primary m-1" v-if="currentRevision!=0" @click="ChangeMinusIndex">
                    <i class="fa-solid fa-arrow-left-long fa-2x"></i>
                </button>
            </div>
            <div class="col-md-6">
                <div class="d-flex justify-content-center">
                    <h3 class="text-capitalize fw-bold">
                        {{$t('Quotation')}} {{ $t('Revision') }} {{currentRevision+1}} 
                    </h3>
                </div>
            </div>
            <div class="col-md-3">
                <div class="d-flex justify-content-end" v-if="currentRevision < revisionCount - 1" @click="ChangeAddIndex">
                    <button class="btn btn-primary m-1">
                        <i class="fa-solid fa-arrow-right-long fa-2x"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class=" p-3 bg-white">
            <BoqQuotationReadOnly :quotationData="quotationData" :quotationId="quotationParams.id" />
        </div>
    </div>
</template>

<script>
import {GetQuotationHistory} from '@/actions/boqActions.js'
import {mapGetters, mapActions} from 'vuex'
import {LinkList} from '@/helpers/utilities.js'
import BreadcrumbsProject  from '@/components/projectInformation/BreadcrumbsProject.vue'
import BoqQuotationReadOnly  from '@/components/boq/BoqQuotationReadOnly.vue'
import {GetUserSignature} from '@/actions/userActions.js'
import 'dotenv/config'
export default {
    name : 'BoqQuotationHistory',
    mounted : async function(){
        const self = this
        // const quotationId = self.$route.params.id
        self.ChangeActiveLink(LinkList.QUOTATIONS)
        const data = await GetQuotationHistory(self.quotationParams)
        if(data==400){
            self.$router.push('/unauthorized')
        }
        await self.GetProjectInfo(data.projectId)
        self.quotationData = data.quotationData
        self.revisionCount = data.revisionCount
        self.currentRevision = self.quotationParams.index ? Number(self.quotationParams.index)  : data.revisionCount-1
        await self.GetSignatures()
        
    },
    components : {
        BreadcrumbsProject, BoqQuotationReadOnly
    },
    data() {
        return {
            quotationData :{
                _id : 1,
                projectId : {
                    projectAddress : ''
                },
                attentionClient : {
                    userId : {fullname  :''}
                },
                quotationSummary : []
            },
            revisionCount : 1,
            currentRevision : 1,
            projectUrl : process.env.VUE_APP_PROJECT_URL,
            quotationParams : {
                id : this.$route.params.id,
                index : this.$route.query.index ||'',
            },
        }
    },
    computed : {
        ...mapGetters(['projectInfo'])
    },
    methods : {
        ...mapActions(['GetProjectInfo', 'ChangeActiveLink']),
        async ChangeAddIndex(){
            if(this.currentRevision+1==this.revisionCount){
                return
            }
            this.GetRevisionInIndex(++this.currentRevision)
        },
        async ChangeMinusIndex(){
            if(this.currentRevision==0){
                return
            }
            this.GetRevisionInIndex(--this.currentRevision)
        },
        async GetRevisionInIndex(index){
            const self = this
            self.$router.push({name : 'BoqQuotationHistory', params : {id : self.quotationParams.id}, query : {index : index}})
            self.quotationParams.index = index
            const res = await GetQuotationHistory(self.quotationParams)

            self.quotationData = res.quotationData
            self.revisionCount = res.revisionCount
            await self.GetSignatures()
        },
        async GetSignatures(){
            const self = this
            for(let x= 0; x< self.quotationData.submittedTo.length; x++){
                const getSubmitter = self.quotationData.submittedTo[x]
                const getSignature = await GetUserSignature({projectId : self.projectInfo._id, userId : getSubmitter.userId._id,signatureId : getSubmitter.signatureIdFromDotNet})
                if(getSignature && getSignature.message == "OK"){
                    self.quotationData.submittedTo[x].signature = getSignature.result
                    // Convert the decoded string to a Uint8Array
                    let decodedBlobData = Buffer.from(getSignature.result.signature,'base64').toString('binary')
                    let byteNumbers  = new Array(decodedBlobData.length)
                    for (let i = 0; i < decodedBlobData.length; i++) {
                        byteNumbers[i] = decodedBlobData.charCodeAt(i)
                    }
                    const byteArray = new Uint8Array(byteNumbers)
                    self.quotationData.submittedTo[x].signatureBlob = URL.createObjectURL(new Blob([byteArray] , { type: getSignature.result.mimeType }))
                }
            }
        }
    },
    beforeUnmount() {
        for(let x= 0; x< this.quotationData.submittedTo.length; x++){
            const blob = this.quotationData.submittedTo[x].signatureBlob
            if(blob){
                URL.revokeObjectURL(blob);
            }
        }
    },

}
</script>