<template>
     <BreadcrumbsProject
        :breadcrumbs="[
            {
                link : projectUrl +'/',
                title : $t('Projects'),
                isRouterLink: false
            },
            {
                link : projectUrl +'/Project/' + projectInfo._id,
                title :  (projectInfo.projectNumber || '-') +' ' + (projectInfo.projectName || ''),
                isRouterLink: false
            },
            {
                link : '/boq/' + projectInfo._id+'/quotation/list',
                title : $t('Quotations'),
                isRouterLink: true
            },
        ]"
    ></BreadcrumbsProject>
    <div>
        <StepItem2></StepItem2>
    </div>
    <div class="border-top">
        <div class="card">
            <div class="card-header bg-white" v-if="permission.indexOf(ModuleActionList.BOQ_QUOTATION_CREATE)>-1">
                <div class="d-flex justify-content-end">
                    <button class="btn btn-primary"
                    data-bs-target="#BoqModalAddQuotation" data-bs-toggle="modal" @click="()=>{ newQuotation = {};}"
                    ><i class="fa-solid fa-plus"></i> {{$t('Add Quotation')}}</button>
                </div>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>{{ $t('Quotation No') }}</th>
                                <th>{{ $t('Description') }}</th>
                                <th>{{ $t('Amount') }}</th>
                                <th>{{ $t('Status') }}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody v-if="isLoading">
                            <tr>
                                <td colspan="5">
                                    <Loader />
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr v-if="quotationList.docs.length==0">
                                <td class="text-center" colspan="5">
                                    {{ $t('No data Found') }}
                                </td>
                            </tr>
                            <tr v-for="(item, key) in quotationList.docs" :key="key">
                                <td>
                                    <router-link :to="{name : 'BoqQuotation', params : {id : item._id} }" class="link-item-route text-capitalize"> 
                                        {{item.quotationNumber}} {{item.quotationName}}
                                    </router-link>
                                </td>
                                <td>
                                <small>
                                        {{ item.remark || '-' }}
                                    </small>
                                </td>
                                <td>
                                    {{ projectInfo.currencyId.sign }}{{ ReformatNumber(item.amount) }}
                                </td>
                                <td>
                                    <span :class="'badge ' + ApprovalStageBoqObj[item.approvalStage -1].color">{{ApprovalStageBoqObj[item.approvalStage-1].name}}</span>
                                </td>
                                <td>
                                    <router-link :to="{name : 'BoqQuotation', params : {id : item._id} }" class="btn btn-outline-primary btn-action me-1"> 
                                        <i class="fa-solid fa-magnifying-glass"></i>
                                    </router-link>
                                    <router-link v-if="item.approvalStage!=1"
                                    :to="{name : 'BoqQuotationHistory', params : {id : item._id} }" class="btn btn-outline-primary btn-action me-1"> 
                                        <i class="fa-solid fa-clock-rotate-left"></i>
                                    </router-link>
                                    <button class="btn btn-outline-danger btn-action" @click="DeleteQuotation(item._id,item.quotationName,item.quotationNumber)" title="Delete Quotation"> <i class="fa-solid fa-trash-can"></i></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>   
                </div>
            </div>
        </div>
    </div>
    <Modal idModal="BoqModalAddQuotation" :headerTitle="$t('Add Quotation')" modalSize="modal-md" v-if="permission.indexOf(ModuleActionList.BOQ_QUOTATION_CREATE)>-1">
        <div class="form-group">
            <label class="required-style"> {{$t('Quotation Module.Quotation Number')}} </label>
            <input class="form-control" :placeholder="$t('Quotation Module.Quotation Number')" v-model="newQuotation.qtnNumber" />
            <small class="text-warning" v-if="saveClicked && !newQuotation.qtnNumber">{{$t('Required')}}</small>
        </div>
         <div class="form-group">
            <label class="required-style">  {{ $t('Name') }} </label>
            <input class="form-control" :placeholder="$t('Name')" v-model="newQuotation.qtnName" />
            <small class="text-warning" v-if="saveClicked && !newQuotation.qtnName">{{$t('Required')}}</small>
        </div>
        <template v-slot:actions>
            <button type="button" ref="closeBoqModalAddQuotation" class="btn btn-outline-danger" data-bs-dismiss="modal">X {{$t('Cancel')}}</button>
            <button type="button" class="btn btn-primary" @click="CreateQuotation" ><i class="fas fa-save"></i> {{$t('Save Changes')}}</button>
        </template>
    </Modal>
    
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {GetQuotationList, AddQuotation,DeleteQuotation} from '@/actions/boqActions.js'
import { LinkList, ReformatNumber, ApprovalStageBoqObj,ModuleActionList } from '@/helpers/utilities.js'
import StepItem2 from '@/components/layout/StepItem2.vue'
import Loader from '@/components/layout/Loader.vue'
import Modal from '@/components/layout/Modal.vue'
import BreadcrumbsProject  from '@/components/projectInformation/BreadcrumbsProject.vue'
import 'dotenv/config'
export default {
    name : 'ConsolidatedList',
    components : {
        StepItem2, BreadcrumbsProject, Loader, Modal
    },
    mounted : async function(){
        const self = this
        const projectId = self.$route.params.id
        self.ChangeActiveLink(LinkList.QUOTATIONS)
        await self.GetProjectInfo(projectId).catch()

        const list = await GetQuotationList(self.pageParams)
        if(list=='401'){
            return self.$router.push('/unauthorized')
        }
        self.quotationList = list 
        self.isLoading = false
    },
    methods : {
        ...mapActions(['ChangeActiveLink', 'GetProjectInfo']),
        ReformatNumber(value){
            return ReformatNumber(value)
        },
        OpenModalAdd(){
            this.newQuotation.qtnName = ''
            this.newQuotation.qtnNumber = ''
            this.saveClicked = false
        },
        CreateQuotation(e){
            const btn = e.target
            const self = this
            self.saveClicked = true

            if(self.isAdding){
                return
            }
            const data = self.newQuotation
            data.projectId = this.$route.params.id

            if(!data.qtnName || !data.qtnNumber){
                return
            }
            self.saveClicked = false
            btn.disabled = true
            self.isAdding = true
            AddQuotation(data).then((res)=>{
                if(res.status!=200){
                    self.isAdding = false
                    btn.disabled = false
                    self.$swal(self.$i18n.t('Add Quotation'),res,'warning')
                }else{
                    self.$refs.closeBoqModalAddQuotation.click()
                    self.$router.push({name : 'BoqQuotation', params :{id : res.data}, query : {isEdit : true}})
                    // GetQuotationList(self.pageParams).then(data=>{
                    //     self.quotationList = data
                    // })
                }
            })
        },
        DeleteQuotation(id,name, number){
            const self = this

            const swalTitle = self.$i18n.t('Add Quotation')

            self.$swal({
                title: swalTitle,
                text : `"${number} ${name} ${self.$i18n.t('will be Deleted Permanently, Proceed?')}`,
                showCancelButton: true,
                icon: 'warning',
                confirmButtonText: 'Delete',
                showLoaderOnConfirm : true,
                allowOutsideClick: () => !self.$swal.isLoading(),
                preConfirm : ()=>{
                    return DeleteQuotation(id).then((res)=>{
                        return res
                    })
                }
            }).then((res)=>{
                if(res.isConfirmed){
                    if(res.value=='OK'){
                        self.$swal(swalTitle,self.$i18n.t('Deleted Successfully'),'success')
                        const docs = self.quotationList.docs
                        const getIndex = docs.findIndex(u=>u._id==id)
                        if(getIndex!=-1){
                            docs.splice(getIndex,1)
                        }
                    }else{
                        self.$swal(swalTitle,res.value,'warning')
                    }
                
                }
            })
        }
    },
    computed : {
        ...mapGetters(['projectInfo','permission'])
    },
    data(){
        return {
            projectUrl : process.env.VUE_APP_PROJECT_URL,
            quotationList : {
                docs: [],
                totalPages : 0,
                page : 1
            },
            pageParams : {
                id : this.$route.params.id,
                search : '',
                page : 1,
            },
            newQuotation : {
                qtnName : '',
                qtnNumber : '',
                projectId  : this.$route.params.id
            },
            isLoading : true,
            saveClicked : false,
            isAdding : false,
            ApprovalStageBoqObj : ApprovalStageBoqObj,
            ModuleActionList : ModuleActionList
        }
    }
}
</script>