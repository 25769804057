<template>
    <div class="">
        <BreadcrumbsProject
        :breadcrumbs="[
            {
                link : '/high-level-estimates',
                title : $t('Bidding'),
                isRouterLink: true
            },
            {
                link : '/high-level-estimates/' + hleProject._id + '/quotation',
                title : (hleProject.projectName || '-') + ' - ' + $t('Bidding Module.High Level Estimates'),
                isRouterLink: true
            },
            {
                link : '/high-level-estimates/' + hleProject._id  + '/quotation/' + quotationId,
                title : quotationItem.name,
                isRouterLink: true
            },
        ]"
        />
        <fieldset :disabled="isDataLoading">
            <div class="row p-3 bg-white print-content">
                <div class="col-md-4 col-sm-4 d-print-none">
                    <div class="">
                        <span :class="'badge ' + ApprovalStageBoqObj[quotationItem.status - 1].color">{{ApprovalStageBoqObj[quotationItem.status - 1].name}}</span>
                    </div>
                </div>
                <div class="col-md-4 col-sm-4 d-print-none">
                    <div class="d-flex justify-content-center">
                        <h5 class="fw-bold"> {{ $t('Bidding Module.High Level Estimates') }}</h5>
                    </div>
                </div>
                <div class="col-md-4 col-sm-4 d-print-none text-end">
                    <button @click="PrintPage"  class="btn btn-default text-black d-print-none me-3"> <i class="fa-solid fa-print"></i> {{ $t('Print') }}</button>
                </div> 
                <div class="col-md-12 col-sm-12 d-print-none">
                    <div class="alert alert-outline-info d-flex align-items-center " role="alert" v-if="processItem">
                        <i class="fas fa-info-circle fa-2x me-2"></i>  
                        <div>
                             {{ $t('Bidding Module.This High Level Estimate is ongoing approval') }} <router-link class="link-style-custom" :to="{name : 'HleQuotationApproval', params : {id : processItem._id}}"> {{ $t('Go to Approval') }}</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 d-none d-print-block">
                    <div class="float-end">
                        <a><img src="https://staff.mmoseronelink.com/mmoser-associates.svg"  alt="" width="300" height="40"></a>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6">
                    <div class="form-group d-print-none">
                        <label> {{ $t('Office in Charge') }}</label>
                        <Select2
                            :disabled="true"
                            :dataOptions="officeList"
                            indexValue="_id"
                            labelName="officeName"
                            :placeHolder="$t('Please Select')"
                            v-model:optionValue="quotationUpdate.officeId"
                        >
                        </Select2>
                    </div>
                    <div class="d-none d-print-block">
                        <span class="fw-bold">{{ $t('Office in Charge') }} :</span>  
                        <span class="text-capitalize" > {{officeList.find(u=>u._id==quotationUpdate.officeId)?.officeName || '-'}}</span>
                    </div>
                </div>
                <div class="offset-md-4 col-md-4 col-sm-6">
                    <div class="form-group d-print-none">
                        <label> {{ $t('Bidding Module.Issue Date') }}</label>
                        <DatePicker  v-model="quotationUpdate.dateIssued" elemId="quotation-date-issue" />
                    </div>
                    <div class="d-none d-print-block text-end">
                        <span class="fw-bold">{{ $t('Bidding Module.Issue Date') }}:</span>  
                        <span > {{ReformatDate(quotationUpdate.dateIssued)}}</span>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6">
                    <div class="form-group d-print-none">
                        <label> {{ $t('Bidding Module.Attached to Project') }}</label>
                        <!-- <Select2
                            :filterQuery="SearchProjectsOrganisation"
                            indexValue="_id"
                            labelName="projectName"
                            placeHolder="Search Projects here "
                            v-model:optionValue="quotationUpdate.projectId"
                        >
                        </Select2> -->
                        <div v-if="quotationItem.projectId">
                            <a class="fw-bold text-primary small" v-if="quotationItem.projectId" :href="projectUrl + '/project/' + quotationItem.projectId._id">
                                {{ quotationItem.projectId.projectNumber}} {{quotationItem.projectId.projectName}}
                            </a>
                        </div>
                        <input v-else type="text" class="form-control-plaintext fw-bold" disabled  value="-"/>
                    </div>
                    <div class=" d-none d-print-block">
                        <span class="fw-bold"> {{ $t('Project') }}:</span>  
                        <span v-if="quotationItem.projectId"> {{ quotationItem.projectId.projectNumber}} {{quotationItem.projectId.projectName}}</span>
                        <span v-else></span>
                    </div>
                </div>
                <div class="offset-md-4 col-md-4 col-sm-6">
                    <div class="d-flex d-print-none">
                        <div class="form-group" style="width:70%" >
                            <label> {{ $t('Bidding Module.Quotation Number') }}</label>
                            <div class="input-group ">
                                <span class="input-group-text bg-white" id="basic-addon2">QTN</span>
                                <input type="text" v-model="quotationUpdate.quotationNumber" class="form-control" />
                            </div>
                        </div>
                        <div class="form-group" style="width:30%">
                            <label> {{ $t('Revision') }}</label>
                            <div class="input-group ">
                                <input type="number" v-model="quotationUpdate.revisionCount" class="form-control border-start-0" />
                            </div>
                        </div>
                    </div>
                    <div class="d-none d-print-block text-end">
                        <span class="fw-bold"> {{ $t('Bidding Module.QTN No') }}:</span>  
                        <span>{{ quotationUpdate.quotationNumber }} - Revision {{ quotationUpdate.revisionCount }}</span>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6">
                    <div class="form-group d-print-none">
                        <label>{{ $t('Bidding Module.Project Location') }}</label>
                        <Select2
                            :dataOptions="officeList"
                            indexValue="_id"
                            labelName="officeName"
                            :placeHolder="$t('Please Select')"
                            v-model:optionValue="quotationUpdate.projectLocation"
                        >
                        </Select2>
                    </div>
                    <div class="d-none d-print-block">
                        <span class="fw-bold">{{ $t('Bidding Module.Project Location') }}:</span>  
                        <span class="text-capitalize" > {{officeList.find(u=>u._id==quotationUpdate.projectLocation)?.officeName || '-'}}</span>
                    </div>
                </div>
                <div class="offset-md-4 col-md-4 col-sm-6">
                    <div class="form-group d-print-none" >
                        <label> {{ $t('Bidding Module.Estimate Total Floor area') }}</label>
                        <div class="d-flex">
                            <input type="number" class="form-control" style="width:70%" v-model="quotationUpdate.floorArea" />
                            <select class="form-select border-start-0" style="width:30%" v-model="quotationUpdate.unitOfMeasure">
                                <option v-for="(item,key) in LandMeasurements" :key="key" :value="item">{{item}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="d-none d-print-block text-end">
                        <span class="fw-bold">{{ $t('Bidding Module.Estimate Total Floor area') }}:</span>
                        <span>{{ quotationUpdate.floorArea }} {{ quotationUpdate.unitOfMeasure }}</span>
                    </div> 
                </div>
                <div class="col-md-4 col-sm-6">
                    <div class="form-group d-print-none">
                        <label> {{ $t('Bidding Module.High Level Estimate Name') }}</label>
                        <input type="text" v-model="quotationUpdate.name" class="form-control" />
                    </div>
                    <div class="d-none d-print-block">
                        <span class="fw-bold">{{ $t('Bidding Module.High Level Estimate Name') }}:</span>
                        <span>{{ quotationUpdate.name }}</span>
                    </div> 
                </div>
                <div class="offset-md-4 col-md-4 col-sm-6">
                    <div class="form-group d-print-none">
                        <label> {{ $t('Currency') }}</label>
                        <input type="text" class="form-control-plaintext" :value="quotationItem.currencyId?.sign + ' ' +  quotationItem.currencyId?.name" />
                    </div>
                    <div class="d-none d-print-block text-end">
                        <span class="fw-bold">{{ $t('Currency') }}:</span>
                        <span>{{ quotationItem.currencyId?.name }}</span>
                    </div> 
                </div>
                <div class="col-md-4 d-print-none">
                    <div class="form-group">
                        <label> {{ $t('Bidding Module.Reference Drawing for the budget') }}</label>
                        <input type="file" ref="fileDatas" multiple  accept="application/pdf"  class="form-control"  @change="OnChangeFiles"/>
                    </div>
                </div>
                <div class="col-md-8 d-print-none">
                </div>
                <div class="col-md-12 d-print-none" >
                    <div class="row  pt-3">
                        <div class="col-md-4" v-for="(item,key) in quotationUpdate.attachments" :key="key">
                            <div class="input-group mb-3" v-if="item._id!=0" @click="OnclickLink(item.sharepointLink)">
                                <span class="input-group-text bg-white" ><i class="fa-solid fa-paperclip"></i></span>
                                <input type="text" role="button"  
                                data-bs-toggle="tooltip" data-bs-placement="top" title="Click to open in Sharepoint"
                                class="form-control text-decoration-underline text-primary" readonly :value="item.attachmentName">
                                <span class="text-danger ps-2 pt-2 fw-bold" role="button" v-if="allowActions"  @click="RemoveAttachment(item._id,item.attachmentName)">
                                    <i class="fa-solid fa-times"></i> {{ $t('Remove') }} 
                                </span>
                            </div>
                            <div class="input-group mb-3  " v-else>
                                <span class="input-group-text bg-white text-success" ><i class="fa-solid fa-link-slash"></i></span>
                                <input type="text" 
                                data-bs-toggle="tooltip" data-bs-placement="top" :title="$t('File to be Uploaded')"
                                class="form-control text-success" readonly :value="item.attachmentName">
                                <span class="text-danger ps-2 pt-2 fw-bold" role="button" @click="RemoveAttachment(item._id,item.attachmentName)">
                                    <i class="fa-solid fa-times"></i> {{ $t('Remove') }}  
                                </span>
                            </div>
                            
                            <!-- <a class="text-primary fw-bold" role="button" :href="item.sharepointLink" target="_blank"> <i class="fa-solid fa-paperclip"></i> {{item.attachmentName}}</a> 
                            <span class="text-danger" v-if="isEditMode"><i class="fa-solid fa-times"></i> Remove </span> -->
                        </div>
                    </div>
                </div>

                <div class="col-md-12 col-sm-12">
                    <h6 class="bg-separator"> {{ $t('Bidding Module.Summary') }} </h6>
                    <div class="table-responsive">
                        <HleQuotationBoqSummary :dataRow="summaryBoqData" :unitOfMeasure="quotationItem.unitOfMeasure" :sectionScopeOfWorkList="sectionScopeOfWorkList" :OpenRemoveBoQ="DeleteAttachedBoq"
                        :projectSize="quotationItem.floorArea" :currencySign="quotationItem.currencyId?.sign" :projectId="hleProjectId" :OpenSectionScopeOfWork="OpenSectionScopeOfWork" />
                    </div>
                    <div class="d-flex justify-content-between d-print-none">
                        <div>
                            <button class="btn btn-primary"
                                data-bs-toggle="modal" data-bs-target="#ModalAttachBoq"
                            ><i class="fa-solid fa-plus"></i> {{$t('Add Bill of Quantities')}}</button>
                        </div>
                        <div>
                            <router-link  class="small text-primary fw-bold" :to="{name : 'HleQuotationDetailedSummary', params : {id : hleProject._id, qtnId : quotationId}, query : {backUrl : backUrl}}">{{ $t('Bidding Module.View Detailed Bill of Quantity')}}</router-link>
                        </div>
                    </div>
                </div>
                <div class="break-after"></div>
                <HleHeaderReadOnly :officeList="officeList" :quotationItem="quotationItem" />
                <div class="col-md-12 col-sm-12 d-print-none" v-if="allowActions" >
                    <h6 class="fw-bold"> {{ $t('Bidding Module.Inclusions/Exclusions')}}</h6>
                    <RichTextEditor v-model:modelValue="quotationUpdate.inclusionsAndExclusions" :placeHolder="'Inclusions/Exclusions'" />
                </div>
                <div class="col-md-12 col-sm-12 d-print-none" v-else>
                    <div class="form-group">
                        <label>{{ $t('Bidding Module.Inclusions/Exclusions')}}</label>
                        <p v-html="quotationItem.inclusionsAndExclusions || '-' "></p>
                    </div>
                </div>
                <div class="col-md-12 col-sm-12 d-none d-print-block">
                    <h6 class="fw-bold bg-separator">{{ $t('Bidding Module.Inclusions/Exclusions')}}</h6>
                    <p v-html="quotationItem.inclusionsAndExclusions || '-' "></p>
                </div>
                <div class="break-after"></div>
                <HleHeaderReadOnly :officeList="officeList" :quotationItem="quotationItem" />
                <div class="col-md-12 col-sm-12 d-print-none" v-if="allowActions"  >
                    <h6 class="fw-bold r"> {{ $t('Terms & Conditions')}}</h6>
                    <RichTextEditor v-model:modelValue="quotationUpdate.termsAndConditions" :placeHolder="'Terms & Conditions'" />
                </div>
                <div class="col-md-12 col-sm-12 d-print-none"  v-else>
                    <div class="form-group">
                        <label>{{ $t('Terms & Conditions')}}</label>
                        <p v-html="quotationItem.termsAndConditions || '-' "></p>
                    </div>
                </div>
                <div class="col-md-12 col-sm-12 d-none d-print-block">
                    <h6 class="fw-bold bg-separator">{{ $t('Terms & Conditions')}}</h6>
                    <p v-html="quotationItem.termsAndConditions || '-' "></p>
                </div>
                <div class="col-md-4 pt-4 col-sm-6">
                    <h6 class="fw-bold"> {{ $t('Submitted By')}}:</h6>
                    <div class=" pt-2" v-for="(item, key) in quotationUpdate.submittedBy" :key="key">
                        <div v-if="item.signatureBlob">
                            <img :src="item.signatureBlob" />
                        </div>
                        <div class="d-print-none d-flex">
                            <Select2
                            :filterQuery="SearchMMoserUserFromProjectOrOrganization"
                            indexValue="_id"
                            labelName="fullname"
                            :placeHolder="$t('Please Select')"
                            :iniLabel="item.name"
                            v-model:optionValue="item.id"
                            :onOptionClick="AppendSubmittedBy"
                            :isDropdown="false"
                            >
                            </Select2>
                            <small class="text-warning pt-2 ps-2"  v-if="allowActions" role="button" @click="RemoveData(item.id,1)"><i class="fa-solid fa-times"></i> {{$t('Remove')}}</small>
                        </div>
                        <div class="border-top width-50 d-none d-print-block signature-padding"  v-if="item.name"></div>
                        <div class="d-none d-print-block">
                            <span class="text-capitalize">{{ item.name }}</span>
                        </div>
                    </div>
                </div>
                <div class="offset-md-4 col-md-4  pt-4 col-sm-6">
                    <h6 class="fw-bold">{{ $t('Bidding Module.Approved By') }}:</h6>
                        <div class=" pt-2" v-for="(item, key) in quotationUpdate.approvedBy" :key="key">
                            <div class="d-print-none d-flex">
                                <Select2
                                :filterQuery="SearchExternalUserFromProjectOrOrganization"
                                indexValue="_id"
                                labelName="fullname"
                                :placeHolder="$t('Please Select')"
                                :iniLabel="item.name"
                                v-model:optionValue="item.id"
                                :onOptionClick="AppendApprovedBy"
                                :isDropdown="false"
                                >
                                </Select2>
                                <small class="text-warning pt-2 ps-2" v-if="allowActions" @click="RemoveData(item.id,2)" role="button"><i class="fa-solid fa-times"></i> {{$t('Remove')}}</small>
                            </div>
                            <div class="border-top width-50 d-none d-print-block signature-padding"  v-if="item.name"></div>
                            <div class="d-none d-print-block " >
                                <span class="text-capitalize">{{ item.name }}</span>
                            </div>
                        </div>
                </div>
                <div class="offset-md-6 col-md-6 mt-5 d-print-none ">
                    <div class="row" v-if="ApprovalStageBoq.APPROVED!=quotationItem.status && allowActions">
                        <div class="col-md-6">
                            <select class="form-select" v-model="quotationUpdate.saveType" >
                                <option value="1">{{ $t('Save') }}</option>
                                <option value="2" v-if="ApprovalStageBoq.FOR_APPROVAL!=quotationItem.status">{{ $t('Send For Approval') }}</option>
                            </select>
                        </div>
                        <div class="col-md-6 d-flex justify-content-end">
                            <button class="btn btn-primary" :disabled="isQuotationUpdating" @click="UpdateQuotation"><i class="fa-solid fa-save"></i> {{ $t('Save Changes')}} </button>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end" v-else-if="ApprovalStageBoq.APPROVED==quotationItem.status">
                        <button class="btn btn-success" 
                         data-bs-toggle="modal" data-bs-target="#ModalAttachProject"
                        ><i class="fa-solid fa-paperclip"></i>  </button>
                    </div>
                </div>

            </div>
        </fieldset>
        <Modal idModal="ModalAttachBoq" :headerTitle="$t('Bidding Module.Attach BoQ')" modalSize="modal-md">
            <div class="form-group">
                <label class="required-style">{{ $t('Bill Of Quantity') }} </label>
                <Autocomplete
                    :filterQuery="SearchForReferenceHighLevelBoq"
                    indexValue="name"
                    v-model:optionValue="newAttachedBoq"
                    :placeHolder="$t('Bidding Module.Search Bill of Quantity here')"
                />
                <small class="text-warning" v-if="!newAttachedBoq">{{$t('Required')}}</small>
                <small v-if="creatingNew" class="text-primary">{{ $t('Bidding Module.New Bill of Quantity Detected, You will be redirected after Attached') }}</small>
            </div>
            <template v-slot:actions>
                <button type="button" ref="closeModalAttachBoq" class="btn btn-outline-danger" data-bs-dismiss="modal">X {{ $t('Cancel') }}</button>
                <button type="button" class="btn btn-primary" @click="AttachBoq"><i class="fa-solid fa-save"></i>  {{ $t('Bidding Module.Attach BoQ') }} </button>
            </template>
        </Modal>
        <Modal idModal="ModalAttachProject" :headerTitle="$t('Bidding Module.Attach To Project')" modalSize="modal-md">
            <div class="form-group">
                <label class="required-style"> {{ $t('Bidding Module.Search Project To Attach') }} </label>
                <Select2
                    :filterQuery="SearchProjectsOrganisation"
                    indexValue="_id"
                    labelName="projectName"
                    :placeHolder="$t('Please Select')"
                    v-model:optionValue="attachProject.projectId"
                >
                </Select2>
                <small class="text-warning" v-if="!newAttachedBoq">{{$t('Required')}}</small>
            </div>
            <template v-slot:actions>
                <button type="button" ref="closeModalAttachProject" class="btn btn-outline-danger" data-bs-dismiss="modal">X {{ $t('Cancel') }}</button>
                <button type="button" class="btn btn-primary" @click="AttachProject"><i class="fa-solid fa-paperclip"></i> {{ $t('Bidding Module.Attach To Project') }}</button>
            </template>
        </Modal>
        <Modal idModal="ModalAddScopeOfWorkSection" :headerTitle="$t('Bidding Module.Edit Scope of Work')" modalSize="modal-md">
            <div class="form-group">
                <label class="required-style"> {{ $t('Bidding Module.Scope of Work') }} </label>
                <textarea class="form-control" v-model="sectionScopeOfWork.description"></textarea>
                <small class="text-warning" v-if="!sectionScopeOfWork.description">{{ $t('Required') }}</small>
            </div>
            <template v-slot:actions>
                <button type="button" ref="ModalAddScopeOfWorkSection" class="btn btn-outline-danger" data-bs-dismiss="modal">X  {{ $t('Cancel') }}</button>
                <button type="button" class="btn btn-primary" @click="SaveSectionScopeOfWork"><i class="fa-solid fa-save"></i> {{ $t('Save') }}</button>
            </template>
        </Modal>
    </div>
</template>

<style scoped>
    .width-50{
        width: 50%;
    }
    .signature-padding{
        margin-top:5px
    }
    @media print {
        .border-top {
            border-top:1px solid black !important
        }
        h6{
            font-size: 12px !important;
        }
        .print-content {
            font-size : 10px !important;
        }
        .print-body {
            margin-top : 190px;
        }
        .signature-padding{
            margin-top:50px
        }
        body {
            margin-top: 0.1mm;
            margin-bottom: 0.15mm;
            margin-right: 0.30mm;
            margin-left: 0.30mm;
        }

        .table>:not(caption)>*>* {
            padding: 0.2rem 0.2rem;
        }

        .bg-light{
            print-color-adjust: exact;
            -webkit-print-color-adjust: exact;
        }

        .bg-separator{
            font-weight: 600;
            padding-top  : 15px;
            background-color: #ffc000;
            print-color-adjust: exact;
            -webkit-print-color-adjust: exact;
            border-bottom: 1px solid #000;
        }
       
    }
    
</style>
<script>
import RichTextEditor from '@/components/layout/RichTextEditor.vue'
import BreadcrumbsProject  from '@/components/projectInformation/BreadcrumbsProject.vue'
import Select2 from '@/components/layout/Select2.vue'
import Modal from '@/components/layout/Modal.vue'
import Autocomplete from '@/components/layout/Autocomplete.vue'
import HleQuotationBoqSummary from '@/components/boq/HleQuotationBoqSummary.vue'
import HleHeaderReadOnly from '@/components/boq/HleHeaderReadOnly.vue'
import {GetHighLevelQuotationForUpdate, SearchForReferenceHighLevelBoq, UpdateHighLevelQuotation, AttachHighLevelBoqToQuotation, GetApprovalItemHighLevelEstimates,
    GetHighLevelQuotationBoQSummary, DeleteAttachHighLevelBoqToQuotation, CheckAttachHighLevelBoqToQuotation, UpdateLineItemAttachHighLevelBoqToQuotation,
AttachHighLevelQuotationToProject,GetHighLevelProject, UpsertSectionScopeOfWorkHighLevelQuotation} from '@/actions/boqActions.js'
import {GetOffices, GetOffice, GetUserSignatureOffice} from '@/actions/userActions.js'
import {SearchMMoserUserFromProjectOrOrganization, SearchExternalUserFromProjectOrOrganization,
 SearchProjectsOrganisation ,GetSystemSettings, GetTokenSharepointFromAuth} from '@/actions/projectActions.js'
import DatePicker from '@/components/layout/DatePicker.vue'
import {LandMeasurements, LinkList, ApprovalStageBoqObj, ReformatFileName, ApprovalStageBoq, ReformatDate, ConvertDataToBlob} from '@/helpers/utilities.js'
import  {mapActions,mapGetters} from 'vuex'
import 'dotenv/config'
import {UploadFileToFolder, CreateFolderToDrive ,DeleteFileToFolder} from '@/helpers/msalHelpers.js'
export default {
    name : 'HleQuotation',
    components : {
        BreadcrumbsProject, Select2, DatePicker, Modal, Autocomplete, RichTextEditor, HleQuotationBoqSummary,
        HleHeaderReadOnly
    },
    methods : {
        ...mapActions(['ChangeActiveLink','GetCurrencyListRun']),
        ReformatDate,
        async GetSummaryBoqData(){
            this.summaryBoqData = await GetHighLevelQuotationBoQSummary(this.quotationId)
        },
        SearchExternalUserFromProjectOrOrganization (value){
            return SearchExternalUserFromProjectOrOrganization(value,'', 1)
        },
        SearchMMoserUserFromProjectOrOrganization(search){
            return SearchMMoserUserFromProjectOrOrganization(search, '', 1)
        },
        SearchProjectsOrganisation(search){
            return SearchProjectsOrganisation(search)
        },
        SearchForReferenceHighLevelBoq(search){
            return SearchForReferenceHighLevelBoq(search,this.hleProjectId)
        },
        async EditLineItemDescription(data){

            const response = await UpdateLineItemAttachHighLevelBoqToQuotation({
                quotationId : this.quotationId,boqId : data.boqId,
                costCodeId : data.costCodeId,description : data.description
            })

            if(response=='OK'){
                const getIndex = this.editedLineItems.findIndex(u=>u.costCodeId==data.costCodeId && u.boqId==data.boqId)
                if(getIndex==-1){
                    this.editedLineItems.push(data)
                }else{
                    this.editedLineItems[getIndex].description = data.description
                }
            }

            return response
        },
        async AttachBoq(){
            const self = this

            if(self.isOnProcess){ return }

            if(!self.newAttachedBoq){
                return
            }

            self.isOnProcess = true

            const isAttached = await AttachHighLevelBoqToQuotation({boqName : self.newAttachedBoq, quotationId : self.quotationItem._id})
            self.isOnProcess = false
            if(isAttached.status){
                self.$refs.closeModalAttachBoq.click()
                if(isAttached.boqId){
                    self.$refs.closeModalAttachBoq.click()
                    self.$router.push({name : 'HleBoQBuilder', params : {id : self.hleProjectId, boqId : isAttached.boqId}})
                }else{
                    self.newAttachedBoq = ''
                    self.GetSummaryBoqData()
                    self.$swal(self.$i18n.t('Bidding Module.Attach BoQ'),self.$i18n.t('Added Successfully'),'success')
                }
            }else{
                self.$swal(self.$i18n.t('Bidding Module.Attach BoQ'),isAttached,'warning')
            }
        },
        DeleteAttachedBoq (id,name){
            const self = this
            self.$swal({
                title: self.$i18n.t('Remove Bill of Quantity') + ' ' + name,
                text : `"${name}" ${self.$i18n.t('Bidding Module.will be removed to this High Level Estimate , Proceed?')}`,
                showCancelButton: true,
                icon: 'warning',
                showLoaderOnConfirm : true,
                allowOutsideClick: () => !self.$swal.isLoading(),
                preConfirm : ()=>{
                    return DeleteAttachHighLevelBoqToQuotation({quotationId : self.quotationId, boqId : id})
                }
            }).then(async (res)=>{
                if(res.isConfirmed){
                    if(res.value=='OK'){
                        self.editedLineItems = self.editedLineItems.filter(u=>u.boqId!=id)
                        self.GetSummaryBoqData()
                        self.$swal(self.$i18n.t('Remove Bill of Quantity') ,self.$i18n.t('Removed Successfully'),'success')
                    }else{
                        self.$swal(self.$i18n.t('Remove Bill of Quantity'),res.value,'warning')
                    }
                
                }
            })
        },
        TimerSetterSearch(callback){
            const self = this
            
            if(self.timerSearchBoq){
                clearTimeout(self.timerSearchBoq)
                self.timerSearchBoq = null
            }
            self.timerSearchBoq = setTimeout(callback,1000)
        },
        Mapdata(){
            const self = this
            const data = self.quotationItem
            self.quotationUpdate = {
                id : data._id,
                name : data.name,
                quotationNumber : data.quotationNumber || '001',
                revisionCount : data.revisionCount || 1,
                officeId : data.officeId,
                projectLocation : data.projectLocation,
                projectId : data.projectId,
                currencyId : data.currencyId._id,
                dateIssued : data.dateIssued,
                inclusionsAndExclusions : data.inclusionsAndExclusions,
                termsAndConditions : data.termsAndConditions,
                floorArea : data.floorArea || 0,
                unitOfMeasure : data.unitOfMeasure || 'm2',
                submittedBy : [],
                approvedBy : [],
                attachments : data.attachments || [],
                saveType : 1
            }

            self.editedLineItems = data.lineItemRenamedData || []

            if(data.submittedBy.length!=0){
                self.quotationUpdate.submittedBy.push(...data.submittedBy)
            }
            if(data.approvedBy.length!=0){
                self.quotationUpdate.approvedBy.push(...data.approvedBy)
            }

            self.quotationUpdate.submittedBy.push({id : '', name : ''})
            self.quotationUpdate.approvedBy.push({id : '', name : ''})

            

            if(self.quotationItem.status!=ApprovalStageBoq.PUBLISHED){
                self.isDataLoading = false
            }
        },
        RemoveData(id, type){
            const self = this

            let data = []

            if(type==1){
                data= self.quotationUpdate.submittedBy
            }else{
                data = self.quotationUpdate.approvedBy
            }
            const getIndex = data.findIndex(u=>u.id==id)
            if(getIndex>-1){
                data.splice(getIndex,1)
            }

            if(data.length==0){
                data.push([{id : '', name : ''}])
            }
        },
        AppendSubmittedBy(){
            const self = this
            let data = []
            data = self.quotationUpdate.submittedBy
            data.push({id : '', name : ''})
        },
        AppendApprovedBy(){
            const self = this
            let data = []
            data = self.quotationUpdate.approvedBy
            data.push({id : '', name : ''})
        },
        async UpdateQuotation(){
            const self = this

            if(self.isOnProcess){
                return
            }

            self.isOnProcess = true
            self.isQuotationUpdating = true

            const getFiles = self.attachmentChanges
            let getToken = getFiles.length!=0 || self.RemovedFiles.length != 0 || !self.officeItem.sharepointFolder ?  await self.OpenPopup() : null

            if(!self.officeItem.sharepointFolder){
                const uploadToDriveOfficeFolder = await CreateFolderToDrive(getToken,self.systemSettings.sharepointDriveId,self.officeItem.officeName)
                if(!uploadToDriveOfficeFolder){
                    return 
                }
                self.quotationUpdate.sharepointOfficeId = uploadToDriveOfficeFolder.id
                self.officeItem.sharepointFolder = uploadToDriveOfficeFolder.id
            }

            let processFiles = []
            if(getFiles.length!=0){
                getToken = await self.OpenPopup() 

                for(let f = 0; f < getFiles.length; f++){
                    const file = getFiles[f]
                    const reformated = ReformatFileName(file.name)
                    const isUploaded = await UploadFileToFolder(getToken,self.systemSettings.sharepointDriveId,
                        self.officeItem.sharepointFolder, reformated,file)
                    if(isUploaded){
                        processFiles.push({
                            sharepointId : isUploaded.id,
                            attachmentName : file.name,
                            attachmentSharepointName : reformated,
                            sharepointLink : isUploaded.webUrl,
                            isAdd : true
                        })
                    }
                }
            }
            
            self.quotationUpdate.attachmentsChanges = processFiles
            if(self.quotationUpdate.attachments){
                self.quotationUpdate.attachmentsChanges.push(...self.quotationUpdate.attachments.filter(u=>u._id!=0))
            }

            if(self.RemovedFiles.length!=0){
                let removePromise = []
                for(let r= 0; r < self.RemovedFiles.length; r++){
                    removePromise.push(DeleteFileToFolder(getToken,self.systemSettings.sharepointDriveId,self.RemovedFiles[r].sharepointId))
                }

                await Promise.all(removePromise)
            }

            const isUpdated = await UpdateHighLevelQuotation(self.quotationUpdate)
            self.isOnProcess = false

            if(isUpdated=='OK')
            {
                if(self.quotationUpdate.saveType==1){
                    self.$swal(self.$i18n.t('Bidding Module.High Level Estimate Update'),self.$i18n.t('Please wait Page is reloading'),'success')
                     setTimeout(function(){
                        location.reload()
                    },700)
                }
                else{
                    self.$router.push({name : 'HleApprovalSetup', query : { qtnId : this.quotationItem._id}})
                }
               
            }
            else
            {
                self.$swal(self.$i18n.t('Bidding Module.High Level Estimate Update'),isUpdated,'warning')
                self.isQuotationUpdating = false
            }
        },
        OpenPopup(){

            if(this.sharepointToken){
                return this.sharepointToken
            }

            window.localStorage.setItem('z_token_share_value', '') 
            return new Promise((resolve) => {
             
                window.open(process.env.VUE_APP_REST_MAIN_API + '/api/auth/token/email?type=2&returnUrl='+encodeURIComponent(process.env.VUE_APP_BOQ_URL + '/page/empty/'), "_blank", "width=400,height=300")
                
                this.intervalClock = setInterval(async () => {
                    let check = window.localStorage.getItem('z_token_share_value')
                    if(check != ''){
                        const getToken = await GetTokenSharepointFromAuth()
                        this.sharepointToken = getToken
                        clearInterval(this.intervalClock)
                        resolve(getToken)
                    }
                }, 200);
            })
        },
        OnChangeFiles(){
            const self = this
            let files = self.$refs.fileDatas.files

            for(let i = 0; i < files.length; i++){
                const attachment = files[i]
                const checkIndex = self.quotationItem.attachments.findIndex(u=>u.attachmentName.toLowerCase()==attachment.name.trim().toLowerCase())
                if(checkIndex==-1){
                    self.quotationUpdate.attachments.push({
                        attachmentName : attachment.name,
                        _id : 0
                    })
                    self.attachmentChanges.push(attachment)
                }
            }
            self.$refs.fileDatas.value = null
            
        },
        OnclickLink(url){
            window.open(url,'_blank') 
        },
        RemoveAttachment(id,name){
            const getIndex = this.quotationItem.attachments.findIndex(u=>u._id==id && u.attachmentName==name)
            if(getIndex !=-1 ){
                if(id!=0){
                    this.RemovedFiles.push(this.quotationItem.attachments[getIndex])
                }else{
                    const getChangesIndex = this.attachmentChanges.findIndex(u=>u.name==name)
                    if(getChangesIndex!=-1){
                        this.attachmentChanges.splice(getChangesIndex,1)
                    }
                }
                
                this.quotationUpdate.attachments.splice(getIndex,1)
            }

        },
        async AttachProject(){
            const self = this
            
            const data = self.attachProject

            if(!data.projectId){
                return
            }

            const response = await AttachHighLevelQuotationToProject({id : self.quotationId, projectId : data.projectId})
            if(response=='OK'){
                self.$swal(self.$i18n.t('Bidding Module.Attached to Project'),`${self.$i18n.t('Updated Successfully')}. ${self.$i18n.t('Please wait Page is reloading')}`,'success')
                setTimeout(function(){
                    location.reload()
                },1200)
                // self.$refs.closeModalAttachProject.click()
                // self.quotationItem = await GetHighLevelQuotationForUpdate(self.quotationId)
            }else{
                self.$swal(self.$i18n.t('Bidding Module.Attached to Project'),self.$i18n.t('Updated Successfully'),'warning')
            }

        },
        PrintPage(){
            window.print()
        },
        OpenSectionScopeOfWork(data, boqItem){
            const getIndex = this.sectionScopeOfWorkList.findIndex(u=>u.sectionId==data.id)
            this.sectionScopeOfWork ={
                sectionId : data.id,
                boqId : boqItem._id,
                id : this.quotationId,
                description : getIndex!=-1 ? this.sectionScopeOfWorkList[getIndex].scopeOfWork : ''
            }
        },
        async SaveSectionScopeOfWork(e){
            const self = this

            const btn = e.target

            const data = self.sectionScopeOfWork
            if(!data.description){
                return
            }

            btn.disabled = true

            const result = await UpsertSectionScopeOfWorkHighLevelQuotation(data)
            if(result!='OK'){
                self.$swal(self.$i18n.t('Edit Scope of Work'),result,'warning')
            }else{
                const getIndex = self.sectionScopeOfWorkList.findIndex(u=>u.sectionId==data.sectionId)
                if(getIndex==-1){
                    self.sectionScopeOfWorkList.push({
                        sectionId : data.sectionId,
                        scopeOfWork : data.description
                    })
                }else{
                    self.sectionScopeOfWorkList[getIndex].scopeOfWork = data.description
                }


                self.$refs.ModalAddScopeOfWorkSection.click()
            }
            
            btn.disabled = false
        },
    },
    computed : {
        ...mapGetters(['boqCurrency'])
    },
    mounted : async function(){
        const self = this
        self.ChangeActiveLink(LinkList.HIGH_LEVEL_ESTIMATES)
        self.officeList = await GetOffices()
        self.quotationItem = await GetHighLevelQuotationForUpdate(self.quotationId)
      
        
        self.sectionScopeOfWorkList = self.quotationItem.sectionScopeOfWork || []

        self.hleProject = await GetHighLevelProject(self.quotationItem.hleProjectId)
        if(!self.hleProject){
            self.$router.push('/unauthorized')
            return
        }
        self.officeItem = await GetOffice(self.quotationItem.officeId)
        self.systemSettings = await GetSystemSettings()
        self.sharepointToken = await GetTokenSharepointFromAuth() 
        self.Mapdata()
        self.processItem = await GetApprovalItemHighLevelEstimates({qtnId : self.quotationId})
        await self.GetSummaryBoqData()
        for(let x= 0; x< self.quotationItem.submittedBy.length; x++){
            const getSubmitter = self.quotationItem.submittedBy[x]
            const getSignature = await GetUserSignatureOffice({officeId : self.quotationItem.officeId, userId : getSubmitter._id})
            if(getSignature && getSignature.message == "OK"){
                getSubmitter.signatureBlob = ConvertDataToBlob(getSignature.result.signature,getSignature.result.mimeType )
                
            }
        }

        self.allowActions = ApprovalStageBoq.PUBLISHED!=self.quotationItem.status
    },
    watch : {
        newAttachedBoq(val){
            const self = this
            if(val){
                self.TimerSetterSearch(async function(){
                    const trySearch = await CheckAttachHighLevelBoqToQuotation({name : val, quotationId : self.quotationId})
                    self.creatingNew = trySearch
                })

            }else{
                self.creatingNew = false
            }
        }
    },
    beforeUnmount() {

        for(let x= 0; x< this.quotationItem.submittedBy.length; x++){
            const blob = this.quotationItem.submittedBy[x].signatureBlob
            if(blob){
                URL.revokeObjectURL(blob);
            }
        }
    },
    data(){
        return {
            backUrl : this.$route.href,
            hleProjectId : this.$route.params.id,
            quotationId : this.$route.params.qtnId,
            quotationItem : {
                status : 1
            },
            projectUrl : process.env.VUE_APP_PROJECT_URL,
            officeItem : null,
            systemSettings : null,
            sharepointToken : null,
            quotationUpdate : {},
            officeList : [],
            LandMeasurements,
            ApprovalStageBoqObj,
            ApprovalStageBoq,
            searchedBoqs : [],
            newAttachedBoq : '',
            timerSearchBoq : null,
            creatingNew : false,
            isOnProcess : false,
            isQuotationUpdating : false,
            intervalClock : null,
            attachmentChanges : [],
            RemovedFiles : [],
            summaryBoqData : [],
            editedLineItems : [],
            isDataLoading : true,
            processItem : null,
            attachProject : {},
            allowActions : false,
            hleProject : {},
            sectionScopeOfWork : {},
            sectionScopeOfWorkList : []
        }
    }
}
</script>