<template>
    <table class="table table-bordered">
        <thead>
            <tr class="">
                <th>{{ $t('S/N') }}</th>
                <th> {{ $t('Description') }}</th>
                <th> {{ $t('Space ID') }}</th>
                <th> {{ $t('Unit') }}</th>
                <th class="text-end"> {{ $t('Quantity') }}</th>
                <th class="text-end"> {{ $t('Rate') }}({{currencySign}})</th>
                <th class="text-end"> {{ $t('Total') }}({{currencySign}})</th>
            </tr>
        </thead> 
        <tbody>
            <BoqCreatorPrintViewRow :sections="sectionsList.sections" :IsOpenBook="IsOpenBook" />
            <tr class="fw-bold" >
                <td ></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="text-end" >{{$t('Overall Total')}}</td>
                <td class="text-end">{{ ReformatNumber(sectionsList.overAllTotal.sellTotal) }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import {ReformatNumber} from '@/helpers/utilities.js'
import BoqCreatorPrintViewRow from '../boq/BoqCreatorPrintViewRow.vue'
export default{
    name : 'BoqCreatorPrintView',
    props : ['IsOpenBook','lineItems','currencySign','sectionsList'],
    components : {
        BoqCreatorPrintViewRow
    },
    methods : {
        ReformatNumber
    },
}
</script>