<template>
    <div class="row">
        <div class="col-md-12">
            <div>
                <span class="fw-bold">{{$t('Date')}}:</span>  {{ReformatDate(quotationData.contractDate)}}
            </div>
        </div>
        <div class="col-md-12">
            <div >
                <span class="fw-bold">{{$t('Quotation')}}: </span>  
                <span >{{quotationData.quotationNumber}}</span>
            </div>
        </div>
        <div class="col-md-12">
            <div class=" pt-1">
                <div class="d-flex">
                    <span class="fw-bold">{{$t('To')}}:</span>
                    <div style="white-space: pre-line" >
                        {{quotationData.address}}
                    </div>
                </div>
                
            </div>
        </div>
        <div class="col-md-12">
            <div class="mt-3">
                <div class="">
                    <span class="fw-bold">{{$t('Attention')}}:</span>  
                    <span  class="text-capitalize">{{quotationData.attentionClient ? quotationData.attentionClient.userId.fullname : 'N/A'}}</span>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="mt-3 ms-3">
                <div >{{quotationData.quotationName}}</div>
                <div >{{$t('Quotation Module.Based on confirmed layout dated')}} {{FormatLayoutDate}}</div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="mt-3">
                <div>
                    <span class="fw-bold">{{$t('Remark')}}:</span>  
                    <span >{{quotationData.remark || 'N/A'}}</span>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <FigureCurrencyNote :currency="projectInfo.currencyId" />
            <div class="table-responsive">
                <BoqQuotationBoqSummary
                :dataRow="quotationData.boqs"
                :unitOfMeasure="quotationData.unit_of_measure"
                :isRevision="true"
                />
            </div>
            <div class="d-print-none">
                <a @click="BackToRoute" class="btn btn-outline-primary">
                    <i class="fa-solid fa-paperclip"></i> {{$t('View Attached Bill of Quantities')}}
                </a>
            </div>
        </div>
        <div class="col-md-12">
            <div class="pt-2" >
                <div class="fw-bold">{{$t('Notes/Remark(Scope of Work)')}}:</div>
                <p v-html="quotationData.scopeOfWork  || '-' "></p>
            </div>
        </div>
        <div class="col-md-12">
            <div class="pt-2" >
                <div class="fw-bold">{{$t('Terms & Conditions')}}</div>
                <p v-html="quotationData.termAndConditions || '-' "></p>
            </div>
        </div>
        <div class="col-md-12 d-print-none" >
            <div class="row  pt-3">
                <div class="col-md-4" v-for="(item,key) in quotationData.attachments" :key="key">
                    <div class="input-group mb-3">
                        <span class="input-group-text bg-white" ><i class="fa-solid fa-paperclip"></i></span>
                        <input type="text" role="button" @click="OnclickLink(item.sharepointLink)" 
                        data-bs-toggle="tooltip" data-bs-placement="top" title="Click to open in Sharepoint"
                        class="form-control text-decoration-underline text-primary" readonly :value="item.attachmentName">
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="pt-2" >
                <div class="fw-bold">{{$t('Construction Schedule')}}</div>
                <div v-if="quotationData.constructionSchedule">
                    <a :href="quotationData.constructionSchedule" target="_blank"><i class="fa-solid fa-link"></i>{{quotationData.constructionSchedule}}</a>
                </div>
                <div v-else>-</div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="pt-2" >
                <div class="fw-bold">{{$t('Construction Drawings')}}</div>
                <div v-if="quotationData.constructionDrawings">
                    <a :href="quotationData.constructionDrawings" target="_blank"><i class="fa-solid fa-link"></i>{{quotationData.constructionDrawings}}</a>
                </div>
                <div v-else>
                    -
                </div>
            </div>
        </div>
        <div class="col-md-6 pt-5">
            <div class="fw-bold">{{$t('Submitted By')}} : </div>
            <div class="">M. Moser Associates (S) Pte Limited</div>
            <div >
                <div class=" mt-2" v-for="(item, key) in quotationData.submittedTo" :key="key">
                    <div v-if="item.signatureBlob">
                        <img :src="item.signatureBlob" />
                    </div>
                    <div class="text-capitalize">
                        {{item.userId.fullname}}
                    </div>
                    <span class="small text-capitalize">{{item.roleInProject}}</span>
                </div>
            </div>
        </div>
        <div class="col-md-6 pt-5">
            <div class="fw-bold">{{$t('Submitted To')}} : </div>
            <div >
                <div v-if="quotationData.attentionClient">
                    <div v-if="quotationData.attentionClient.userId.company">
                        {{quotationData.attentionClient.userId.company.companyName || '-'}}
                    </div>
                </div>
            </div>
            <div >
                <!-- <div  class="text-capitalize pt-2">{{quotationData.attentionClient ? quotationData.attentionClient.userId.fullname : 'N/A'}}</div> -->
                <div class=" mt-2" v-for="(item, key) in quotationData.additionalAttention" :key="key">
                    <div class="text-capitalize">
                        {{item.userId.fullname}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {ReformatDate, ReformatNumber} from '@/helpers/utilities.js'
import BoqQuotationBoqSummary from '@/components/boq/BoqQuotationBoqSummary.vue'
import {mapGetters} from 'vuex'
import FigureCurrencyNote from '@/components/layout/FigureCurrencyNote.vue'
export default {
    name : 'BoqQuotationReadOnly',
    props : ['quotationData', 'quotationId'],
    data(){
        return {
            ReformatDate : ReformatDate,
        }
    },
    components : {
        BoqQuotationBoqSummary,FigureCurrencyNote
    },
    methods : {
        BackToRoute(){
            const self = this
            self.$router.push({name : 'BoqQuotationAttachedItems', params : {id : self.quotationId}, query : {revisionId : self.quotationData._id, returnUrl : location.pathname + location.search}})
        },
        OnclickLink(url){
            window.open(url,'_blank')
        }
    },
    computed : {
        ...mapGetters(['projectInfo']),
        FormatLayoutDate(){
            const self = this
            if(self.quotationData.layoutDate){
                return ReformatDate(new Date(self.quotationData.layoutDate),"Do of MMMM, YYYY")
            }else{
                return '-'
            }
        },
        SummaryRender(){
            const self = this
            if(self.quotationData.quotationSummary.length==0){
                return []
            }

            const summaryData = self.quotationData.quotationSummary
            let parent = ''
            let parentLetter = 65
            let subCount = 1
            let subTotal = 0

            const size = self.quotationData.size || 0

            let totalAll = 0

            summaryData.forEach((u)=>{
                totalAll += u.sellTotal
            })
            return summaryData.map((u,index)=>{
                u.willShowParent = false

                subTotal += u.sellTotal

                if(parent!=u.parent.costCodeId){
                    parent = u.parent.costCodeId
                    u.willShowParent = true
                    subCount = 1
                    u.label = String.fromCharCode(parentLetter++)
                }

                if(index==summaryData.length -1){
                    const subPerSize =subTotal / size
                    u.subTotal = {
                        label : String.fromCharCode(parentLetter - 1),
                        total : ReformatNumber(subTotal),
                        percentTotal :ReformatNumber((subTotal / totalAll) * 100,0),
                        perSize :  ReformatNumber(subPerSize)
                    }
                    u.overAllTotal = {
                        lastLabel : String.fromCharCode(parentLetter - 1),
                        percentTotal :totalAll ==0 ?'0' :'100',
                        perSize :  ReformatNumber(totalAll / size),
                        total : ReformatNumber(totalAll)
                    }
                }else if(u.parent.costCodeId!= summaryData[index + 1].parent.costCodeId){
                    const subPerSize =subTotal / size
                    const letter = parentLetter==65 ? 65 : parentLetter-1
                    u.subTotal = {
                        label : String.fromCharCode(letter),
                        total : ReformatNumber(subTotal),
                        percentTotal :ReformatNumber((subTotal / totalAll) * 100,0),
                        perSize :  ReformatNumber(subPerSize)
                    }
                    subTotal =0
                }

                u.index = subCount++
                u.perSize = ReformatNumber(u.sellTotal / size)
                u.percentTotal = ReformatNumber((u.sellTotal / totalAll) * 100,0)
                u.sellTotal = ReformatNumber(u.sellTotal)
                return u
            })
        }
    }
}
</script>

<style scoped>
    .width-50{
        width: 50%;
    }
    .signature-padding{
        margin-top:5px
    }
    @media print {
        .border-top {
            border-top:1px solid black !important
        }
        .print-content {
            font-size : 10px !important;
        }
        .print-body {
            margin-top : 190px;
        }
        .signature-padding{
            margin-top:50px
        }
        body {
            margin-top: 0.1mm;
            margin-bottom: 0.15mm;
            margin-right: 0.30mm;
            margin-left: 0.30mm;
        }

        .table>:not(caption)>*>* {
            padding: 0.2rem 0.2rem;
        }

        .bg-light{
            -webkit-print-color-adjust: exact;
            print-color-adjust :exact
        }

        .bg-separator{
            font-weight: 600;
            padding-top  : 15px;
            background-color: #ffc000;
            -webkit-print-color-adjust: exact;
            print-color-adjust :exact;
            border-bottom: 1px solid #000;
        }
       
    }
    
</style>