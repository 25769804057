<template>
    <div>
        <BreadcrumbsProject
        :breadcrumbs="[
            {
                link : projectUrl +'/',
                title : $t('Projects'),
                isRouterLink: false
            },
            {
                link : projectUrl +'/Project/' + projectInfo._id,
                title :  (projectInfo.projectNumber || '-') +' ' + (projectInfo.projectName || ''),
                isRouterLink: false
            },
            {
                link : '/boq/' + projectInfo._id+'/quotation/list',
                title : $t('Quotations'),
                isRouterLink: true
            },
            {
                link : '/boq/' + processItemData.relatedId +'/quotation/item',
                title : (quotationData.quotationNumber || '') + ' ' + (quotationData.quotationName || '')  ,
                isRouterLink: true
            },
            {
                link : '/boq/' + processItemData._id + '/quotation/approval',
                title : 'Approval',
                isRouterLink: true
            },
        ]"
        ></BreadcrumbsProject>
        <div class="row mt-3">
            <BoqProcessWorkFlow
            v-if="processItemData._id"
            :processItemData="processItemData" 
            :showApprovalDisapprove="true"
            />
        </div>
        <div class=" card">
            <div class="card-body">
                <div class="d-flex justify-content-end pb-1">
                </div>
                <!-- <div class="alert alert-success d-flex align-items-center " role="alert" v-if="processItemData.status==ProcessItemStatusList.ENDED">
                    <i class="fa-solid fa-file-circle-check fa-2x me-2"></i>  
                    <div>
                        Ended on <b>{{ReformatDate(processItemData.endedDate)}}</b> <span v-if="processItemData.endedBy" class="text-capitalize"> By {{processItemData.endedBy.fullname}}</span>
                        
                    </div>
                </div> -->
                <BoqQuotationReadOnly :quotationData="quotationData" :quotationId="processItemData.relatedId" />
            </div>
            
            
            
        </div>
    </div>
    <div class="mt-1">
        <ProcessCommentList 
        :PageChangeComment="PageChangeComment"
        :processItem="processItemData"
        :commentList="commentList"
        />
    </div>
    <div v-if="CanApprove">
        <div class="d-flex justify-content-end fixed-bottom pb-3 pe-3">
            <button class="btn btn-success me-1" data-bs-toggle="modal" data-bs-target="#BoqReviewApprove"> <i class="fa-solid fa-thumbs-up"></i> {{$t('Approval Module.Approve')}}</button>
            <button class="btn btn-danger" data-bs-toggle="modal" data-bs-target="#BoqReviewReject"><i class="fa-solid fa-thumbs-down"></i> {{$t('Approval Module.Ask For Revision')}}</button>
        </div>
    </div>

    <Modal idModal="BoqReviewApprove" :headerTitle="$t('Approval Module.Approve')"  >
      <div>
         <div class="form-group">
            <label>{{ $t('Approval Module.Notes (optional)') }}: </label>
            <textarea v-model="approveNotes" class="form-control"></textarea>
        </div>
          
      </div>
      <template v-slot:actions>
        <button type="button" ref="closeBoqReviewApprove" class="btn btn-outline-danger" data-bs-dismiss="modal">X {{$t('Cancel')}}</button>
        <button type="button" class="btn btn-primary" @click="Approve" >
            <i class="fas fa-thumbs-up"></i> {{$t('Approval Module.Approve')}}
        </button>
      </template>
    </Modal>
    <Modal idModal="BoqReviewReject" :headerTitle="$t('Approval Module.Ask For Revision')"  >
      <div>
         <div class="form-group">
            <label class="required-style">Notes{{ $t('Approval Module.Notes') }}: </label>
            <textarea v-model="rejectNotes" class="form-control"></textarea>
            <small class="text-danger" v-if="!rejectNotes">{{$t('Required')}}</small>
        </div>
          
      </div>
      <template v-slot:actions>
        <button type="button" ref="closeBoqReviewReject" class="btn btn-outline-danger" data-bs-dismiss="modal">X {{ $t('Cancel') }}</button>
        <button type="button" class="btn btn-primary" @click="Disapprove" >
            <i class="fas fa-thumbs-down"></i> {{$t('Approval Module.Ask For Revision')}}
        </button>
      </template>
    </Modal>
</template>

<script>

import BoqProcessWorkFlow  from '@/components/projectInformation/BoqProcessWorkFlow.vue'
import {GetWorkflowItem,GetCommentsList,UserApproveWorkFlow, UserDisapproveWorkFlow} from '@/actions/projectActions.js'
import {GetApprovalQuotationRevison} from '@/actions/boqActions.js'
import {mapGetters, mapActions} from 'vuex'
import {LinkList, ProcessStepType, ReformatDate,ProcessAction, ProcessItemStatusList ,ProcessActionTypes} from '@/helpers/utilities.js'
import BreadcrumbsProject  from '@/components/projectInformation/BreadcrumbsProject.vue'
import ProcessCommentList  from '@/components/projectInformation/ProcessCommentList.vue'
import BoqQuotationReadOnly  from '@/components/boq/BoqQuotationReadOnly.vue'
import Modal  from '@/components/layout/Modal.vue'
import {GetUserSignature} from '@/actions/userActions.js'
import 'dotenv/config'
export default {
    name : 'BoqQuotationReview',
    mounted : async function(){
        const self = this
        const processId = self.$route.params.id
        self.ChangeActiveLink(LinkList.APPROVAL_LIST)

        const processItem = await GetWorkflowItem(self.$route.params.id).catch()
        await self.GetProjectInfo(processItem.projectId)
        self.processItemData = processItem
        self.quotationData = await GetApprovalQuotationRevison(processId)

        for(let x= 0; x< self.quotationData.submittedTo.length; x++){
            const getSubmitter = self.quotationData.submittedTo[x]
            const getSignature = await GetUserSignature({projectId : processItem.projectId, userId : getSubmitter.userId._id,signatureId : getSubmitter.signatureIdFromDotNet})
            if(getSignature && getSignature.message == "OK"){
                self.quotationData.submittedTo[x].signature = getSignature.result
                // Convert the decoded string to a Uint8Array
                let decodedBlobData = Buffer.from(getSignature.result.signature,'base64').toString('binary')
                let byteNumbers  = new Array(decodedBlobData.length)
                for (let i = 0; i < decodedBlobData.length; i++) {
                    byteNumbers[i] = decodedBlobData.charCodeAt(i)
                }
                const byteArray = new Uint8Array(byteNumbers);
                self.quotationData.submittedTo[x].signatureBlob = URL.createObjectURL(new Blob([byteArray] , { type: getSignature.result.mimeType }))
            }
        }

        self.commentList = await GetCommentsList(self.commentFilterParam).catch()
    },
    components : {
        BoqProcessWorkFlow, BreadcrumbsProject, ProcessCommentList, BoqQuotationReadOnly, Modal
    },
    data() {
        return {
            processItemData : {
                status : 1,
                internalApprovers : [],
            },
            quotationData :{
                projectId : {
                    projectAddress : ''
                },
                attentionClient : {
                    userId : {fullname  :''}
                },
                quotationSummary : []
            },
            projectUrl : process.env.VUE_APP_PROJECT_URL,
            boqUrl : process.env.VUE_APP_BOQ_URL,
            ProcessStepType : ProcessStepType,
            currentStep : {
                action : 1,
                roleId : { _id : 1}
            },
            ReformatDate : ReformatDate,
            commentFilterParam : {
                page : 1,
                id : this.$route.params.id,
            },
            commentList : {
                docs : [],
                hasNextPage : false,
                totalPages : 0,
                page : 1
            },
            ProcessAction : ProcessAction,
            ProcessItemStatusList : ProcessItemStatusList,
            userAccess : {
                userId : '',
                roleId : {
                    _id : ''
                }
            },
            approveNotes : '',
            rejectNotes : ''
        }
    },
    computed : {
        ...mapGetters(['projectInfo','userInfo']),
        CanApprove(){
            const self = this
            const approvers = self.processItemData.internalApprovers

            if(approvers.findIndex(u=>u.relatedUserId._id==self.userInfo._id && u.action==ProcessActionTypes.PENDING&& u.actionType==2) >-1){
                return true
            }

            return false
        },
    },
    methods : {
        ...mapActions(['GetProjectInfo', 'ChangeActiveLink']),
        GetProcessItemRun(){
            const self = this
            GetWorkflowItem(self.$route.params.id).then(res=>{
                self.processItemData = res
            })
        },
        PageChangeComment(page){
            const self = this
            self.commentFilterParam.page = page
            GetCommentsList(self.commentFilterParam).then((res)=>{
                self.commentList = res
            }).catch()
        },
        Approve(e){
            const self = this
            const btn = e.target


            if(self.isApproveRunning){
                return
            }

            btn.disabled = true
            self.isApproveRunning = true
            UserApproveWorkFlow(self.processItemData._id,self.approveNotes)
            .then((res)=>{
                self.isApproveRunning = false
                btn.disabled = false
                if(res!="OK"){
                    self.$swal(self.$i18n.t('Approve'),res.value,'warning')
                }else{
                    self.$swal({
                        icon: 'success',
                        title: self.$i18n.t('Approved successfully'),
                        showConfirmButton: false,
                        timer : 1000
                    })
                    self.GetProcessItemRun()
                    GetCommentsList(self.commentFilterParam).then((res)=>{
                        self.commentList = res
                    })
                    self.$refs.closeBoqReviewApprove.click()
                }
            })
        },
        Disapprove(e){
            const self = this
            const btn = e.target

            if(self.isDisapproveRunning){
                return
            }

            if(!self.rejectNotes){
                return
            }

            self.isDisapproveRunning = true
            btn.disabled = true
            UserDisapproveWorkFlow(self.processItemData._id, self.rejectNotes)
            .then((res)=>{
                btn.disabled = false
                self.isDisapproveRunning = false
                if(res!="OK"){
                    self.$swal(self.$i18n.t('Ask for Revision'),res.value,'warning')
                }else{
                    self.$swal({
                        icon: 'success',
                        title: self.$i18n.t('Item marked Asked for Revision successfully'),
                        showConfirmButton: false,
                        timer : 1000
                    })
                    self.GetProcessItemRun()
                    GetCommentsList(self.commentFilterParam).then((res)=>{
                        self.commentList = res
                    })
                    self.$refs.closeBoqReviewReject.click()
                }
            })
        }
    },
    beforeUnmount() {
        for(let x= 0; x< this.quotationData.submittedTo.length; x++){
            const blob = this.quotationData.submittedTo[x].signatureBlob
            if(blob){
                URL.revokeObjectURL(blob);
            }
        }
    },
}
</script>