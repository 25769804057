<template>
    <div class="card border-0 border-bottom d-print-none ">
        <div class="row m-2" >
            <div class="col-md-12">
                <div class="d-flex justify-content-start">
                    <a @click="GoBack" href="#">
                        <i title="Go Back to Previous Page" class="fa-solid fa-square-caret-left text-primary fa-2x"></i>
                    </a>
                     <nav class=" ps-3 text-capitalize" aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 mt-1">
                            <li 
                            :class="key==breadcrumbs.length -1 ? 'breadcrumb-item active' :'breadcrumb-item'" 
                            v-for="(item, key) in breadcrumbs" :key="key"
                            >
                                 <router-link :to="item.link" :title="item.title" v-if="item.isRouterLink" class="text-decoration-none">
                                    <span 
                                    :class="key!=breadcrumbs.length -1 ? 'text-info small' :'text-primary small fw-bold'">
                                        {{item.title}}
                                    </span>
                                </router-link>
                                <a :href="item.link" :title="item.title" class="text-decoration-none" v-else>
                                    <span 
                                    :class="key!=breadcrumbs.length -1 ? 'text-info small' :'text-primary small fw-bold'">
                                        {{item.title}}
                                    </span>
                                </a>
                                
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : 'BreadcrumbsProject',
    props : {
        breadcrumbs : {
            type :Array,
        },
    },
    methods :{
        GoBack(){
            window.history.back()
        }
    }
}
</script>