<template>
    <div class="stepper-wrapper">
        <div :class="InternalApproverStatus">
            <div class="step-counter" role="button" @click="()=>{isOpen = !isOpen}"><i></i> {{$t('Approval Module.Internal Approvers')}}</div>
            <div class="step-name card p-2"  v-if="isOpen">
                <div class="approver-list">
                    <div class="row" v-for="(item, key) in  InternalApprovers" :key="key">
                        <div class="col-md-6 text-capitalize small">{{item.fullname}}</div>
                        <div :class="'col-md-6 small ' + item.textColor">{{item.actionLabel}}</div>
                        <div class="col-md-12" v-if="item.doneBy">
                            <div class="row">
                                <div class="col-md-6" >
                                </div>
                                <div class="col-md-6 small">
                                    <span :class="item.textColor">
                                        Done by {{item.doneBy.fullname}}
                                    </span> 
                                </div>
                            </div>
                        </div>
                            
                    </div>
                </div>
                <div class="pt-2 ">
                    <div class="d-flex justify-content-between" v-if="InternalComplete">
                        <div class="mt-2">
                            <span class="fw-bold small text-info " v-if="showApprovalDisapprove && ShowSkip" @click="ActionSkip" role="button"><i class="fa-solid fa-forward"></i>  {{$t('Approval Module.Internal Approvers')}}</span>
                        </div>
                        <!-- <div>
                            <div v-if="WillAllowToInternalApprove && showApprovalDisapprove">
                                <button class="btn btn-success me-1" @click="ActionApprove"><i class="fa-solid fa-thumbs-up"></i> Approve</button>
                                <button class="btn btn-danger" @click="ActionDisapprove"><i class="fa-solid fa-thumbs-down"></i> For Revision</button>
                            </div>
                        </div> -->
                    </div>
                    <div v-else  class="pt-2 ">
                        <div class="d-flex justify-content-end">
                            <span class="fw-bold small text-success "><i class="fa-solid fa-circle-check"></i> {{$t('Approval Module.Completed')}}</span>
                        </div>
                    </div>
                    <div class="pt-2 " v-if="showGoToApproval">
                        <div class="d-flex justify-content-end">
                            <span class="fw-bold small text-primary " @click="GoToApproval"  role="button" ><i class="fa-solid fa-up-right-from-square"></i> {{$t('Go to Approval')}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div :class="ExternalApproverStatus">
            <div class="step-counter" role="button" @click="()=>{isOpen = !isOpen}">  {{$t('Approval Module.External Approvers')}}</div>
            <div class="step-name card p-2" v-if="isOpen">
                <div class="text-center" v-if="InternalComplete">
                    <span class="" > {{$t('Approval Module.Complete internal approval to proceed to External Approvers')}}</span>
                </div>
                <div  v-else-if="processItemData.status==2  || processItemData.status==3">
                    <div v-if="processItemData.externalApprovers.length==0 && processItemData.status==2 " class="pt-2 pb-2 text-center" >
                        <!-- <button class="btn btn-outline-success me-1" 
                        data-bs-toggle="modal" data-bs-target="#BoqReviewSkipExternal" v-if="processItemData.onModel =='bill_of_quantities'" ><i class="fa-solid fa-hourglass-end"></i> Proceed without approval</button> -->
                        <button class="btn btn-outline-primary" @click="MoveToAddApproverExternal"><i class="fa-solid fa-plus"></i> {{$t('Approval Module.Add External Approvers')}}</button>
                    </div>
                    <div class="approver-list">
                        <div class="row" v-for="(item, key) in  ExternalApprovers" :key="key">
                            <div class="col-md-6 text-capitalize small">{{item.fullname}}</div>
                            <div :class="'col-md-6 small ' + item.textColor">
                                {{item.actionLabel}} 
                            </div>
                            <div class="col-md-12" v-if="item.doneBy">
                                <div class="row">
                                    <div class="col-md-6" >
                                    </div>
                                    <div class="col-md-6 small">
                                        <span :class="item.textColor">
                                            {{$t('Approval Module.Done by')}} {{item.doneBy.fullname}}
                                        </span> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-between mt-2">
                    <span class="fw-bold small text-info " 
                    data-bs-toggle="modal" data-bs-target="#BoqReviewSkipExternal"
                    v-if="processItemData.status==2 && showApprovalDisapprove" role="button">
                        <i class="fa-solid fa-forward"></i> {{$t('Approval Module.Proceed without approval')}} 
                    </span>
                    <span class="fw-bold small text-success "  v-if="processItemData.status==3"><i class="fa-solid fa-circle-check"></i> {{$t('Approval Module.Completed')}}</span>
                </div>
            </div>
        </div>
    </div>

    <Modal idModal="BoqReviewSkipExternal" :headerTitle="$t('Approval Module.Proceed without approval')"  >
      <div>
         <div class="form-group">
            <label class="required-style">{{$t('Approval Module.Notes')}}: </label>
            <textarea v-model="skipNotes" class="form-control"></textarea>
            <small class="text-danger" v-if="!skipNotes">{{$t('Required')}}</small>
        </div>
          
      </div>
      <template v-slot:actions>
        <button type="button" ref="closeBoqReviewSkipExternal" class="btn btn-outline-danger" data-bs-dismiss="modal">X {{$t('Cancel')}}</button>
        <button type="button" class="btn btn-primary" @click="ActionSkipExternal" >
            <i class="fa-solid fa-forward"></i> {{$t('Proceed')}}
        </button>
      </template>
    </Modal>
</template>

<script>
import { ProcessActionTypes,ReformatDate} from '@/helpers/utilities.js'
import {UserApproveWorkFlow, UserDisapproveWorkFlow, UserSkipWorkFlow, EndWorkflow, ReviewWorkFlow, AnswerBehalfExternalUserWorkFlow, UserExternalSkipWorkFlow} from '@/actions/projectActions.js'
import { mapGetters } from 'vuex'
import Modal  from '@/components/layout/Modal.vue'
export default {
    name : 'BoqProcessWorkFlow',
    props : {
        processItemData : {
            type : Object
        },
        ResetData : {
            type : Function
        },
        showApprovalDisapprove : {
            type : Boolean,
            default : true
        },
        showGoToApproval : {
            type : Boolean,
            default : false
        }
    },
    components : {Modal},
    mounted : async function(){
        await ReviewWorkFlow(this.processItemData._id)
    },
    computed : {
        ...mapGetters(['userInfo']),
        InternalComplete(){
            return this.processItemData.status ==1
        },
        ShowSkip(){
            const self =this
            return self.processItemData.internalApprovers.findIndex(u=>u.actionType==1)!=-1
        },
        InternalApprovers(){
            const self = this

            if(!self.processItemData){
                return []
            }

            return self.processItemData.internalApprovers.map(u=>{
                let actionLabel = ''
                if(u.action==ProcessActionTypes.PENDING){
                    actionLabel = self.actionTypeList[u.actionType - 1]
                }else{
                    actionLabel = self.actionList[u.action - 1] + ' on ' + ReformatDate(u.actionDate)
                }
                return {
                    fullname : u.relatedUserId.fullname,
                    actionLabel : actionLabel ,
                    action  : u.action,
                    textColor : self.actionColor[u.action - 1],
                    doneBy : u.createdBy,
                }
            })
        },
        ExternalApprovers(){
            const self = this

            if(!self.processItemData){
                return []
            }

            return self.processItemData.externalApprovers.map(u=>{
                let actionLabel = ''
                if(u.action==ProcessActionTypes.PENDING){
                    actionLabel = self.actionTypeList[u.actionType - 1]
                }else{
                    actionLabel = self.actionList[u.action - 1] + ' on ' + ReformatDate(u.actionDate)
                }
                return {
                    _id : u.relatedUserId._id,
                    fullname : u.relatedUserId.fullname,
                    actionLabel : actionLabel ,
                    action  : u.action,
                    doneBy : u.createdBy,
                    textColor : self.actionColor[u.action - 1]
                }
            })
        },
        InternalApproverStatus(){
            const self = this

            const approvers = self.processItemData.internalApprovers

            if(approvers.findIndex(u=>u.action==ProcessActionTypes.DISAPPROVED)>-1){
                return 'stepper-item disapproved'
            }
            else if(approvers.findIndex(u=>u.action==ProcessActionTypes.PENDING)>-1){
                return 'stepper-item ongoing'
            }
            else if(approvers.findIndex(u=>u.action==ProcessActionTypes.PENDING)==-1){
                return 'stepper-item completed'
            }

            return 'stepper-item'
        },
        ExternalApproverStatus(){
            const self = this

            const approvers = self.processItemData.externalApprovers

            if(approvers.length==0){
                return 'stepper-item'
            }

            if(approvers.findIndex(u=>u.action==ProcessActionTypes.DISAPPROVED)>-1){
                return 'stepper-item disapproved'
            }
            else if(approvers.findIndex(u=>u.action==ProcessActionTypes.PENDING)>-1){
                return 'stepper-item ongoing'
            }
            else if(approvers.findIndex(u=>u.action==ProcessActionTypes.PENDING)==-1){
                return 'stepper-item completed'
            }

            return 'stepper-item'
        },
        WillAllowToInternalApprove(){
            const self = this
            const approvers = self.processItemData.internalApprovers


            if(approvers.findIndex(u=>u.relatedUserId._id==self.userInfo._id && u.action==ProcessActionTypes.PENDING&& u.actionType==2) >-1){
                return true
            }

            return false
        },
        isTheCreator(){
            return this.userInfo._id==this.processItemData.createdBy
        },
    },
    data(){
        const self = this
        return {
            isOpen :true,
            actionTypeList : [self.$i18n.t('Approval Module.Waiting for Review'),self.$i18n.t('Approval Module.Waiting for Approval')],
            actionColor : ['text-muted','text-success','text-success','text-danger','text-warning'],
            actionList : [self.$i18n.t('Approval Module.Pending'),self.$i18n.t('Approval Module.Reviewed'),self.$i18n.t('Approval Module.Approved'),self.$i18n.t('Approval Module.Asked for Revision'),self.$i18n.t('Approval Module.Skipped')],
            isApproveRunning : false,
            skipNotes : ''
        }
    },
    methods : {
        ActionApprove(){
            const self = this

            if(self.isApproveRunning){
                return
            }

            self.isApproveRunning = true
            UserApproveWorkFlow(self.processItemData._id)
            .then((res)=>{
                self.isApproveRunning = false
                if(res!="OK"){
                    self.$swal(self.$i18n.t('Approval Module.Approve'),res.value,'warning')
                }else{
                    self.$swal({
                        icon: 'success',
                        title: self.$i18n.t('Approved successfully'),
                        showConfirmButton: false,
                        timer : 1000
                    })
                    setTimeout(function(){
                        location.reload()
                    },1000)
                }
            })
        },
        ActionDisapprove(){
            const self = this

            if(self.isDisapproveRunning){
                return
            }

            self.isDisapproveRunning = true
            UserDisapproveWorkFlow(self.processItemData._id)
            .then((res)=>{
                self.isDisapproveRunning = false
                if(res!="OK"){
                    self.$swal(self.$i18n.t('Approval Module.Ask for Revision'),res.value,'warning')
                }else{
                    self.$swal({
                        icon: 'success',
                        title: self.$i18n.t('Approval Module.Item marked Asked for Revision successfully'),
                        showConfirmButton: false,
                        timer : 1000
                    })
                    setTimeout(function(){
                        location.reload()
                    },1000)
                }
            })
        },
        ActionSkip(){
            const self = this

            self.$swal({
                title: self.$i18n.t('Approval Module.Skip'),
                text : self.$i18n.t('Approval Module.Proceed on skipping Internal Reviewers') ,
                showCancelButton: true,
                icon: 'warning',
                showLoaderOnConfirm : true,
                allowOutsideClick: () => !self.$swal.isLoading(),
                preConfirm : ()=>{
                    self.isSkipRunning = true
                    return UserSkipWorkFlow(self.processItemData._id)
                    .then((res)=>{
                        self.isSkipRunning = false
                        return res
                    })
                }
            }).then((res)=>{
                if(res.isConfirmed){
                    if(res.value!="OK"){
                        self.$swal(self.$i18n.t('Approval Module.Skip'),res.value,'warning')
                    }else{
                        location.reload()
                    }
                }
            })
        },
        ActionSkipExternal(e){
            const self = this
            const btn = e.target

            if(!self.skipNotes){
                return 
            }

            if(self.isApproveRunning){
                return
            }

            btn.disabled = true
            self.isApproveRunning = true
            UserExternalSkipWorkFlow(self.processItemData._id,self.skipNotes)
            .then((res)=>{
                self.isApproveRunning = false
                btn.disabled = false
                if(res!="OK"){
                    self.$swal(self.$i18n.t('Approval Module.Skip'),res.value,'warning')
                }else{
                    self.$swal({
                        icon: 'success',
                        title: self.$i18n.t('Skipped successfully'),
                        showConfirmButton: false,
                        timer : 1000
                    })
                    setTimeout(function(){
                        window.location.reload()
                    },900)
                }
            })
        },
        EndWorkflow(){
            const self = this

            if(self.workFlowEnding){
                return
            }

            self.workFlowEnding = true

            EndWorkflow(self.processItemData._id).then((res)=>{
                self.workFlowEnding = false
                if(res=='OK'){
                    location.reload()
                }else{
                    self.$swal('End Approval',res,'warning')
                }
            })

        },
        MoveToAddApproverExternal(){
            const self = this
            if(self.processItemData.onModel =='project_quotation'){
                self.$router.push({name : 'BoqReviewerSetup', query : { qtnId : self.processItemData.relatedId, isExternal : true } })
            }
            else if(self.processItemData.onModel =='bill_of_quantities'){
                self.$router.push({name : 'BoqReviewerSetup', query : { boqId : self.processItemData.relatedId, isExternal : true } })
            }
            else if(self.processItemData.onModel =='high_level_boqs'){
                self.$router.push({name : 'HleApprovalSetup', query : { boqId : self.processItemData.relatedId, isExternal : true } })
            }
            else if(self.processItemData.onModel =='high_level_quotations'){
                self.$router.push({name : 'HleApprovalSetup', query : { qtnId : self.processItemData.relatedId, isExternal : true } })
            }
        },
        AnswerBehalfExternalUserWorkFlow(externalUser,type, name){
            const self = this

            if(self.workFlowBehalf){
                return
            }

            let message = ''

            if(type==1){
                message = 'Approve on behalf'
            }else{
                message = 'Ask For Revision on behalf'
            }

            self.$swal({
                title: message,
                text : message + ' of ' +name ,
                showCancelButton: true,
                icon: 'warning',
                confirmButtonText: 'Answer',
                showLoaderOnConfirm : true,
                allowOutsideClick: () => !self.$swal.isLoading(),
                preConfirm : ()=>{
                    self.workFlowBehalf = true
                    return AnswerBehalfExternalUserWorkFlow(self.processItemData._id,externalUser,type)
                    .then((res)=>{
                        self.workFlowBehalf = false
                        return res
                    })
                }
            }).then((res)=>{
                if(res.isConfirmed){
                    if(res.value!="OK"){
                        self.$swal(message,res.value,'warning')
                    }else{
                        location.reload()
                    }
                }
            })
        
        },
        GoToApproval(){
            const self = this
            if(self.processItemData.onModel =='project_quotation'){
                self.$router.push({name : 'BoqQuotationReview', params : { id : self.processItemData._id } })
            }
            else if(self.processItemData.onModel =='bill_of_quantities'){
                self.$router.push({name : 'Review', params : { id : self.processItemData._id } })
            }
        }
    }
}
</script>