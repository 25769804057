<template>
    <div class="table-responsive" style="max-height:550px" >
        <table class="table table-header-solid table-bordered" >
            <thead class="sticky-top">
                <tr>
                    <th style="min-width:75px;" class="sticky-left">S/N</th>
                    <th style="min-width:400px;" class="sticky-left">Description</th>
                    <th style="min-width:200px;">Space ID</th>
                    <th>Unit</th>
                    <BoqLineItemRowThead 
                    :selectedColumns="selectedColumns" />
                    
                </tr>
            </thead>
            <tbody  class="table-group-divider">
                <tr v-if="rowDatas.length==0">
                    <td 
                    colspan="22" class="text-center">No Data Found
                    </td>
                </tr>
                <template v-for="(item, key) in CostCodeList" :key="key"> 
                    <tr v-if="item.mainCateg!=null">
                        <td class="fw-bold bg-light-primary text-center ">
                            {{item.mainCateg.label}} 
                        </td>
                        <td class="fw-bold bg-light-primary sticky-left" >
                            {{item.mainCateg.name}}
                        </td>
                        <td class="bg-light-primary "></td>
                        <td class="bg-light-primary "></td>
                        <BoqLineItemRowHeader
                        :data="item.mainCateg.totals"
                        :selectedColumns="selectedColumns"
                        :ReformatNumber="ReformatNumber" />
                    </tr>
                    <tr v-if="item.subCateg!=null">
                        <td class="fw-bold bg-light-primary text-center ">
                            {{item.subCateg.label}} 
                        </td>
                        <td class="fw-bold bg-light-primary sticky-left ps-4" >
                            {{item.subCateg.name}}
                        </td>
                        <td class="bg-light-primary "></td>
                        <td class="bg-light-primary "></td>
                        <BoqLineItemRowHeader
                        :data="item.subCateg.totals"
                        :selectedColumns="selectedColumns"
                        :ReformatNumber="ReformatNumber" />
                    </tr>  
                    <tr>
                        <td class="text-center">
                            {{item.lineItem.label}} 
                        </td>
                        <td class="bg-white sticky-left ps-3">
                            <div class="ps-3">
                                <span class="fw-bold small">{{item.lineItem.name}}</span> 
                                <span v-if="item.lineItem.rateOnly" class="ms-1 badge border-danger text-danger pb-1 pt-1">RATE ONLY</span>
                                 <br />
                                <p class="small mb-0 description-line-item">
                                    {{item.lineItem.description}}
                                </p>
                            </div>
                        </td>
                        <td  >{{item.lineItem.area}}</td>
                        <td>{{item.lineItem.unit}}</td>
                        <BoqLineItemRow 
                        :data="item.lineItem" :ReformatNumber="ReformatNumber" :selectedColumns="selectedColumns" />
                    </tr>
                </template>
                
            </tbody>
        </table>
    </div>
</template>

<script>
import { ReformatNumber} from '@/helpers/utilities.js'
import BoqLineItemRow from '@/components/boq/BoqLineItemRow.vue'
import BoqLineItemRowThead from '@/components/boq/BoqLineItemRowThead.vue'
import BoqLineItemRowHeader from '@/components/boq/BoqLineItemRowHeader.vue'
export default {
    name : 'BoqLineItemTable',
    props : ['rowDatas','selectedColumns'],
    components : {
        BoqLineItemRow,BoqLineItemRowThead, BoqLineItemRowHeader
    },
    computed : {
        CostCodeList(){
            const self = this 
      
            if(self.rowDatas.length==0){
                 return []
             }
      
            let mainCateg = { costCodeId : '' }
            let subCateg = { costCodeId : '' }
      
            let fieldListMain = {
                 costTotal : 0,
                 supplyTotal : 0,
                 installTotal : 0,
                 costTotalBuffer : 0,
                 supplyAndInstallTotal : 0,
                 costTotalTotal : 0,
                 sellTotal : 0,
                 sellTotalBuffer : 0,
                 sellTotalTotal : 0,
                 gp : 0,
                 gpBuffer : 0,
                 gpTotal : 0,
                 index : 0
             }
      
             let fieldListSub = {
                 costTotal : 0,
                 supplyTotal : 0,
                 installTotal : 0,
                 costTotalBuffer : 0,
                 supplyAndInstallTotal : 0,
                 costTotalTotal : 0,
                 sellTotal : 0,
                 sellTotalBuffer : 0,
                 sellTotalTotal : 0,
                 gp : 0,
                 gpBuffer : 0,
                 gpTotal : 0,
                 index : 0
             }
      
             let totalList= []
             let totalListSub= []
             let parentLetter = 65
             let subCount = 1
             let lineItemSubCount = 1
      
            const arrayLength = self.rowDatas.length
      
            let ReformatData = self.rowDatas.map((u,index)=>{
                 let showMainCateg = false
                 let showSubCatag = false
                 const nextLineItem = self.rowDatas[index + 1]
                 const supplyAndInstallCombine = u.supplyAndInstall ? u.supplyAndInstall : (u.costRate || 0) + (u.installRate || 0)
                 const  supplyAndInstallTotal = supplyAndInstallCombine * u.quantity
                 //main category sum 
                 fieldListMain.costTotal += u.costTotalRaw
                 fieldListMain.supplyTotal += u.supplyTotal
                 fieldListMain.installTotal += u.installTotal
                 fieldListMain.costTotalBuffer += u.costTotal - u.costTotalRaw
                 fieldListMain.supplyAndInstallTotal += supplyAndInstallTotal
                 fieldListMain.costTotalTotal += u.costTotal
                 fieldListMain.sellTotal += u.sellTotalRaw
                 fieldListMain.sellTotalBuffer += u.sellTotal - u.sellTotalRaw
                 fieldListMain.sellTotalTotal += u.sellTotal
                 fieldListMain.gp += u.gpRaw
                 fieldListMain.gpBuffer +=  u.gp - u.gpRaw
                 fieldListMain.gpTotal += u.gp
             
                 //sub category sum
                 fieldListSub.costTotal += u.costTotalRaw
                 fieldListSub.supplyTotal += u.supplyTotal
                 fieldListSub.installTotal += u.installTotal
                 fieldListSub.costTotalBuffer += u.costTotal - u.costTotalRaw
                 fieldListSub.supplyAndInstallTotal += supplyAndInstallTotal
                 fieldListSub.costTotalTotal += u.costTotal
                 fieldListSub.sellTotal += u.sellTotalRaw
                 fieldListSub.sellTotalBuffer += u.sellTotal - u.sellTotalRaw
                 fieldListSub.sellTotalTotal += u.sellTotal
                 fieldListSub.gp += u.gpRaw
                 fieldListSub.gpBuffer +=  u.gp - u.gpRaw
                 fieldListSub.gpTotal += u.gp
      
      
                 if(u.parents[0].costCodeId!=mainCateg.costCodeId){
                     showMainCateg = true
                     mainCateg = u.parents[0]
                     mainCateg.label = String.fromCharCode(parentLetter)
                     ++parentLetter
                     subCount = 1
                     fieldListMain.index = index
                 }
             
                 if(u.parents[1].costCodeId!=subCateg.costCodeId){
                     showSubCatag = true
                     subCateg = u.parents[1]
                     subCateg.label = mainCateg.label + subCount
                     ++subCount
                     lineItemSubCount = 1
                     fieldListSub.index = index
                 }
                 u.label = subCateg.label +'.'+ lineItemSubCount
                 ++lineItemSubCount
                 u.bufferCostTotal = u.costTotal - u.costTotalRaw
                 u.bufferSellRate = u.sellRate - u.sellRateRaw
                 u.bufferSellTotal = u.sellTotal - u.sellTotalRaw
                 u.bufferGp = u.gp - u.gpRaw
                 u.supplyAndInstallTotal = supplyAndInstallTotal
             
                 //main category
                 if(index + 1==arrayLength){
                    totalList.push({
                        costTotal : fieldListMain.costTotal ,
                        supplyTotal : fieldListMain.supplyTotal ,
                        installTotal : fieldListMain.installTotal ,
                        costTotalBuffer : fieldListMain.costTotalBuffer,
                        supplyAndInstallTotal : fieldListMain.supplyAndInstallTotal,
                        costTotalTotal : fieldListMain.costTotalTotal ,
                        sellTotal : fieldListMain.sellTotal  ,
                        sellTotalBuffer : fieldListMain.sellTotalBuffer ,
                        sellTotalTotal : fieldListMain.sellTotalTotal ,
                        gp : fieldListMain.gp ,
                        gpBuffer : fieldListMain.gpBuffer ,
                        gpTotal : fieldListMain.gpTotal ,
                        index : fieldListMain.index,
                    })
                }else if(u.parents[0].costCodeId!=nextLineItem.parents[0].costCodeId){
                    totalList.push({
                        costTotal : fieldListMain.costTotal ,
                        supplyTotal : fieldListMain.supplyTotal ,
                        installTotal : fieldListMain.installTotal ,
                        costTotalBuffer : fieldListMain.costTotalBuffer,
                        supplyAndInstallTotal : fieldListMain.supplyAndInstallTotal,
                        costTotalTotal : fieldListMain.costTotalTotal ,
                        sellTotal : fieldListMain.sellTotal  ,
                        sellTotalBuffer : fieldListMain.sellTotalBuffer ,
                        sellTotalTotal : fieldListMain.sellTotalTotal ,
                        gp : fieldListMain.gp ,
                        gpBuffer : fieldListMain.gpBuffer ,
                        gpTotal : fieldListMain.gpTotal ,
                        index : fieldListMain.index,
                    })
 
                    fieldListMain = {
                        costTotal : 0 ,
                        supplyTotal : 0 ,
                        installTotal : 0,
                        costTotalBuffer : 0,
                        supplyAndInstallTotal : 0,
                        costTotalTotal : 0,
                        sellTotal : 0  ,
                        sellTotalBuffer : 0,
                        sellTotalTotal : 0 ,
                        gp : 0 ,
                        gpBuffer : 0 ,
                        gpTotal : 0 ,
                        index : 0,
                    }
                }
                //sub category
                if(index + 1==arrayLength){
                    totalListSub.push({
                        costTotal : fieldListSub.costTotal ,
                        supplyTotal : fieldListSub.supplyTotal ,
                        installTotal : fieldListSub.installTotal ,
                        costTotalBuffer : fieldListSub.costTotalBuffer,
                        supplyAndInstallTotal : fieldListSub.supplyAndInstallTotal,
                        costTotalTotal : fieldListSub.costTotalTotal ,
                        sellTotal : fieldListSub.sellTotal  ,
                        sellTotalBuffer : fieldListSub.sellTotalBuffer ,
                        sellTotalTotal : fieldListSub.sellTotalTotal ,
                        gp : fieldListSub.gp ,
                        gpBuffer : fieldListSub.gpBuffer ,
                        gpTotal : fieldListSub.gpTotal ,
                        index : fieldListSub.index,
                    })
                }else if(u.parents[1].costCodeId!=nextLineItem.parents[1].costCodeId){
                    totalListSub.push({
                        costTotal : fieldListSub.costTotal ,
                        supplyTotal : fieldListSub.supplyTotal ,
                        installTotal : fieldListSub.installTotal ,
                        costTotalBuffer : fieldListSub.costTotalBuffer,
                        supplyAndInstallTotal : fieldListSub.supplyAndInstallTotal,
                        costTotalTotal : fieldListSub.costTotalTotal ,
                        sellTotal : fieldListSub.sellTotal  ,
                        sellTotalBuffer : fieldListSub.sellTotalBuffer ,
                        sellTotalTotal : fieldListSub.sellTotalTotal ,
                        gp : fieldListSub.gp ,
                        gpBuffer : fieldListSub.gpBuffer ,
                        gpTotal : fieldListSub.gpTotal ,
                        index : fieldListSub.index,
                    })
 
                    fieldListSub = {
                        costTotal : 0 ,
                        supplyTotal : 0 ,
                        installTotal : 0,
                        costTotalBuffer : 0,
                        supplyAndInstallTotal : 0,
                        costTotalTotal : 0,
                        sellTotal : 0  ,
                        sellTotalBuffer : 0,
                        sellTotalTotal : 0 ,
                        gp : 0 ,
                        gpBuffer : 0 ,
                        gpTotal : 0 ,
                        index : 0,
                    }
                }
             
                 return {
                     lineItem : u,
                     mainCateg : showMainCateg ? mainCateg : null,
                     subCateg : showSubCatag ? subCateg : null
                 }
            })

            //loop main totals
            for(let x=0;x<totalList.length;x++){
                 const data = totalList[x]
                 ReformatData[data.index].mainCateg.totals = {}
                 ReformatData[data.index].mainCateg.totals.costTotal = data.costTotal 
                 ReformatData[data.index].mainCateg.totals.supplyTotal = data.supplyTotal 
                 ReformatData[data.index].mainCateg.totals.installTotal= data.installTotal 
                 ReformatData[data.index].mainCateg.totals.costTotalBuffer = data.costTotalBuffer
                 ReformatData[data.index].mainCateg.totals.supplyAndInstallTotal = data.supplyAndInstallTotal
                 ReformatData[data.index].mainCateg.totals.costTotalTotal = data.costTotalTotal 
                 ReformatData[data.index].mainCateg.totals.sellTotal = data.sellTotal  
                 ReformatData[data.index].mainCateg.totals.sellTotalBuffer = data.sellTotalBuffer 
                 ReformatData[data.index].mainCateg.totals.sellTotalTotal = data.sellTotalTotal 
                 ReformatData[data.index].mainCateg.totals.gp =data.gp 
                 ReformatData[data.index].mainCateg.totals.gpBuffer = data.gpBuffer 
                 ReformatData[data.index].mainCateg.totals.gpTotal = data.gpTotal 
            }
            for(let x=0;x<totalListSub.length;x++){
                 const data = totalListSub[x]
                 ReformatData[data.index].subCateg.totals = {}
                 ReformatData[data.index].subCateg.totals.costTotal = data.costTotal 
                 ReformatData[data.index].subCateg.totals.supplyTotal = data.supplyTotal 
                 ReformatData[data.index].subCateg.totals.installTotal= data.installTotal 
                 ReformatData[data.index].subCateg.totals.costTotalBuffer = data.costTotalBuffer
                 ReformatData[data.index].subCateg.totals.supplyAndInstallTotal = data.supplyAndInstallTotal
                 ReformatData[data.index].subCateg.totals.costTotalTotal = data.costTotalTotal 
                 ReformatData[data.index].subCateg.totals.sellTotal = data.sellTotal  
                 ReformatData[data.index].subCateg.totals.sellTotalBuffer = data.sellTotalBuffer 
                 ReformatData[data.index].subCateg.totals.sellTotalTotal = data.sellTotalTotal 
                 ReformatData[data.index].subCateg.totals.gp =data.gp 
                 ReformatData[data.index].subCateg.totals.gpBuffer = data.gpBuffer 
                 ReformatData[data.index].subCateg.totals.gpTotal = data.gpTotal 
             }
      
      
            return ReformatData
        }
    },
    methods : {
        ReformatNumber : ReformatNumber
    }
}
</script>
<style scoped>
 .sticky-top{
    position : sticky;
    top:0;
    z-index: 100;
 }
 .sticky-left{
    position : sticky;
    left:0;
    z-index: 50;
 }
 .line-item-100{
    min-width: 100px;
 }
</style>