<template>
    <div class="table-responsive" style="height:75vh" >
        <table class="table table-bordered" >
            <thead class="sticky-top">
                <tr >
                    <th style="min-width:75px;" valign="middle" class="sticky-left" v-if="!IsQuotationPublished"></th>
                    <th style="min-width:75px;" valign="middle" class="sticky-left">{{ $t('S/N') }}</th>
                    <th style="min-width:600px;" valign="middle" class="sticky-left">{{ $t('Description') }}</th>
                    <th style="min-width:200px;" valign="middle">{{ $t('Space ID') }}</th>
                    <th valign="middle">{{ $t('Unit') }}</th>
                    <BoqLineItemRowThead 
                    :selectedColumns="selectedColumns" />
                    <th class="sticky-right"></th>
                </tr>
            </thead>
               <tbody class="table-group-divider"  v-if="rowDatas.length==0">
                 <tr>
                    <td 
                     colspan="24">{{ $t('No data Found') }}
                    </td>
                </tr>
                </tbody>
                <Draggable itemKey="_id" v-model="LineItemData" tag="tbody" v-else
                :animation="150" ghostClass="opacity-50" handle=".handle" :onEnd="onEndDrag" >
                    <template #item="{element}" >
                        <tr  >
                            <td class="text-center handle" v-if="!IsQuotationPublished"><i class="fa-solid fa-up-down-left-right"></i></td>
                            <td class="text-center">
                                {{element.label}}
                            </td>
                            <td class="sticky-left bg-white" @click="AddItemToMovables(element._id)" role="button"> 
                                <div   >
                                    <div class="d-flex justify-content-between">
                                        <small class="fw-bold text-capitalize"><input type="checkbox" v-if="!IsQuotationPublished" v-model="toBeMoved" :value="element._id" /> 
                                            <span class="fw-bold text-capitalize" v-if="userInfo.language=='en'">{{element.name}}</span>
                                            <span class="fw-bold" v-else>{{element.nameCh || element.name}}</span>
                                        </small> 
                                        <span>
                                            <small v-if="element.remark" class="text-info me-1 bg-light px-1 rounded">{{ $t('BillOfQuantityRemark.' + element.remark) }}</small>
                                            <small v-if="element.rateOnly" class="text-danger bg-light px-1 rounded">{{$t('RATE ONLY')}}</small>
                                        </span>
                                    </div>
                                    <div>
                                    </div>
                                    <p class="small mb-0 description-line-item ps-3">
                                        {{element.description}}
                                    </p>
                                </div>
                            </td>
                            <td  >{{element.area}}</td>
                            <td>{{element.unit}}</td>
                            <BoqLineItemRow 
                            :data="element" :ReformatNumber="ReformatNumber" :selectedColumns="selectedColumns" />
                            <td style="width : 50px" class="sticky-right bg-white shadow-lg"  >
                                <div v-if="!IsQuotationPublished">
                                    <button class="btn btn-outline-primary btn-action mb-1" @click="OpenEditModal(element)"
                                    ref="ModalUpdateLineItemBtn"
                                    v-if="permissionList.indexOf(ModuleActionList.BOQ_EDIT) >-1"
                                    data-bs-toggle="modal" data-bs-target="#ModalUpdateLineItem"
                                    >
                                        <i class="fa-solid fa-edit"></i>
                                    </button>
                            
                                    <button v-if="permissionList.indexOf(ModuleActionList.BOQ_ITEM_REMOVE) > -1" class="btn btn-outline-warning btn-action me-1" 
                                        @click="OpenDelete(element._id)" > <i class="fa-solid fa-trash-can"></i> </button> 
                                </div>
                            </td>
                        </tr>
                    </template>
                </Draggable>
                <tfoot>
                    <tr class="bg-light-primary fw-bold sticky-bottom">
                        <td v-if="!IsQuotationPublished"></td>
                        <td style="min-width:75px;" ></td>
                        <td style="min-width:600px;" class="bg-light-primary sticky-left" >{{ $t('Totals') }}</td>
                        <td style="min-width:200px;"></td>
                        <td></td>
                        <BoqLineItemRowFooter 
                            :data="totalObj" :ReformatNumber="ReformatNumber" :selectedColumns="selectedColumns" />
                        <td></td>
                    </tr>
               </tfoot>
        </table>
    </div>
    
</template>
<style scoped>
.handle{
    cursor: move;
}
</style>

<script>
import {GetBoqItemDelete} from '@/actions/boqActions.js'
import { ApprovalStageBoq, ReformatNumber, ModuleActionList, BoQColumnList, LineItemRemarks} from '@/helpers/utilities.js'
import { mapGetters } from 'vuex'
import BoqLineItemRow from '@/components/boq/BoqLineItemRow.vue'
import BoqLineItemRowThead from '@/components/boq/BoqLineItemRowThead.vue'
import BoqLineItemRowFooter from '@/components/boq/BoqLineItemRowFooter.vue'
import Draggable from 'vuedraggable'
export default {
    name : 'BoqItemTableBreakdown',
    props : ['rowDatas', 'OpenEditModal','UpdateEdited','selectedColumns','selectedRows', 'GetSequence', 'IsQuotationPublished'
    ,'updateSelected', 'reloadData', 'BoqCategoryList', 'GetChanges','customPermission','DeleteItemFunc','SyncSelectedLineItem'],
    data(){
        return {
            ApprovalStageBoq : ApprovalStageBoq,
            ModuleActionList : ModuleActionList,
            selectedLineItems : [],
            selectedLineItemsId : [],
            LineItemData : [],
            permissionList : [],
            dataReady :false,
            toBeMoved : [],
            totalObj : {},
            LineItemRemarks
        }
    },
    components : {
        BoqLineItemRow,BoqLineItemRowThead, Draggable, BoqLineItemRowFooter
    },
    mounted : async function(){

        if(this.customPermission){
            this.permissionList = this.customPermission
        }
        else{
            this.permissionList = this.permission
        }
        this.ReformatRows()


    },
    watch : {
        permission(val){
            if(!this.customPermission){
                this.permissionList = val
            }
        },
    },
    computed : {
        ...mapGetters(['permission','userInfo']),
        CostCodeList(){
            const self = this 
            if(self.rowDatas.length==0){
                 return []
             }
      
            let mainCateg = { costCodeId : '' }
            let subCateg = { costCodeId : '' }
      
            let fieldListMain = {
                 costTotal : 0,
                 supplyTotal : 0,
                 installTotal : 0,
                 costTotalBuffer : 0,
                 supplyAndInstallTotal : 0,
                 costTotalTotal : 0,
                 sellTotal : 0,
                 sellTotalBuffer : 0,
                 sellTotalTotal : 0,
                 gp : 0,
                 gpBuffer : 0,
                 gpTotal : 0,
                 index : 0
             }
      
             let fieldListSub = {
                 costTotal : 0,
                 supplyTotal : 0,
                 installTotal : 0,
                 costTotalBuffer : 0,
                 supplyAndInstallTotal : 0,
                 costTotalTotal : 0,
                 sellTotal : 0,
                 sellTotalBuffer : 0,
                 sellTotalTotal : 0,
                 gp : 0,
                 gpBuffer : 0,
                 gpTotal : 0,
                 index : 0
             }
      
             let totalList= []
             let totalListSub= []
             let parentLetter = 65
             let subCount = 1
             let lineItemSubCount = 1
      
            const arrayLength = self.rowDatas.length
            let ReformatData = self.rowDatas.map((u,index)=>{
                 let showMainCateg = false
                 let showSubCatag = false
                 const nextLineItem = self.rowDatas[index + 1]
                 const supplyAndInstallCombine = u.supplyAndInstall ? u.supplyAndInstall : (u.costRate || 0) + (u.installRate || 0)
                 const  supplyAndInstallTotal = supplyAndInstallCombine * u.quantity
                 //main category sum 
                 fieldListMain.costTotal += u.costTotalRaw
                 fieldListMain.supplyTotal += u.supplyTotal
                 fieldListMain.installTotal += u.installTotal
                 fieldListMain.costTotalBuffer += u.costTotal - u.costTotalRaw
                 fieldListMain.supplyAndInstallTotal += supplyAndInstallTotal
                 fieldListMain.costTotalTotal += u.costTotal
                 fieldListMain.sellTotal += u.sellTotalRaw
                 fieldListMain.sellTotalBuffer += u.sellTotal - u.sellTotalRaw
                 fieldListMain.sellTotalTotal += u.sellTotal
                 fieldListMain.gp += u.gpRaw
                 fieldListMain.gpBuffer +=  u.gp - u.gpRaw
                 fieldListMain.gpTotal += u.gp
                 
                 //sub category sum
                 fieldListSub.costTotal += u.costTotalRaw
                 fieldListSub.supplyTotal += u.supplyTotal
                 fieldListSub.installTotal += u.installTotal
                 fieldListSub.costTotalBuffer += u.costTotal - u.costTotalRaw
                 fieldListSub.supplyAndInstallTotal += supplyAndInstallTotal
                 fieldListSub.costTotalTotal += u.costTotal
                 fieldListSub.sellTotal += u.sellTotalRaw
                 fieldListSub.sellTotalBuffer += u.sellTotal - u.sellTotalRaw
                 fieldListSub.sellTotalTotal += u.sellTotal
                 fieldListSub.gp += u.gpRaw
                 fieldListSub.gpBuffer +=  u.gp - u.gpRaw
                 fieldListSub.gpTotal += u.gp
      
      
                 if(u.parents[0].costCodeId!=mainCateg.costCodeId){
                     showMainCateg = true
                     mainCateg = u.parents[0]
                     mainCateg.label = String.fromCharCode(parentLetter)
                     ++parentLetter
                     subCount = 1
                     fieldListMain.index = index
                 }
             
                 if(u.parents[1].costCodeId!=subCateg.costCodeId){
                     showSubCatag = true
                     subCateg = u.parents[1]
                     subCateg.label = mainCateg.label + subCount
                     ++subCount
                     lineItemSubCount = 1
                     fieldListSub.index = index
                 }
                 u.label = subCateg.label +'.'+ lineItemSubCount
                 ++lineItemSubCount
                 u.bufferCostTotal = u.costTotal - u.costTotalRaw
                 u.bufferSellRate = u.sellRate - u.sellRateRaw
                 u.bufferSellTotal = u.sellTotal - u.sellTotalRaw
                 u.bufferGp = u.gp - u.gpRaw
                 u.supplyAndInstallTotal = supplyAndInstallTotal
             
                 //main category
                 if(index + 1==arrayLength){
                    totalList.push({
                        costTotal : fieldListMain.costTotal ,
                        supplyTotal : fieldListMain.supplyTotal ,
                        installTotal : fieldListMain.installTotal ,
                        costTotalBuffer : fieldListMain.costTotalBuffer,
                        supplyAndInstallTotal : fieldListMain.supplyAndInstallTotal,
                        costTotalTotal : fieldListMain.costTotalTotal ,
                        sellTotal : fieldListMain.sellTotal  ,
                        sellTotalBuffer : fieldListMain.sellTotalBuffer ,
                        sellTotalTotal : fieldListMain.sellTotalTotal ,
                        gp : fieldListMain.gp ,
                        gpBuffer : fieldListMain.gpBuffer ,
                        gpTotal : fieldListMain.gpTotal ,
                        index : fieldListMain.index,
                    })
                }else if(u.parents[0].costCodeId!=nextLineItem.parents[0].costCodeId){
                    totalList.push({
                        costTotal : fieldListMain.costTotal ,
                        supplyTotal : fieldListMain.supplyTotal ,
                        installTotal : fieldListMain.installTotal ,
                        costTotalBuffer : fieldListMain.costTotalBuffer,
                        supplyAndInstallTotal : fieldListMain.supplyAndInstallTotal,
                        costTotalTotal : fieldListMain.costTotalTotal ,
                        sellTotal : fieldListMain.sellTotal  ,
                        sellTotalBuffer : fieldListMain.sellTotalBuffer ,
                        sellTotalTotal : fieldListMain.sellTotalTotal ,
                        gp : fieldListMain.gp ,
                        gpBuffer : fieldListMain.gpBuffer ,
                        gpTotal : fieldListMain.gpTotal ,
                        index : fieldListMain.index,
                    })
 
                    fieldListMain = {
                        costTotal : 0 ,
                        supplyTotal : 0 ,
                        installTotal : 0,
                        costTotalBuffer : 0,
                        supplyAndInstallTotal : 0,
                        costTotalTotal : 0,
                        sellTotal : 0  ,
                        sellTotalBuffer : 0,
                        sellTotalTotal : 0 ,
                        gp : 0 ,
                        gpBuffer : 0 ,
                        gpTotal : 0 ,
                        index : 0,
                    }
                }
                //sub category
                if(index + 1==arrayLength){
                    totalListSub.push({
                        costTotal : fieldListSub.costTotal ,
                        supplyTotal : fieldListSub.supplyTotal ,
                        installTotal : fieldListSub.installTotal ,
                        costTotalBuffer : fieldListSub.costTotalBuffer,
                        supplyAndInstallTotal : fieldListSub.supplyAndInstallTotal,
                        costTotalTotal : fieldListSub.costTotalTotal ,
                        sellTotal : fieldListSub.sellTotal  ,
                        sellTotalBuffer : fieldListSub.sellTotalBuffer ,
                        sellTotalTotal : fieldListSub.sellTotalTotal ,
                        gp : fieldListSub.gp ,
                        gpBuffer : fieldListSub.gpBuffer ,
                        gpTotal : fieldListSub.gpTotal ,
                        index : fieldListSub.index,
                    })
                }else if(u.parents[1].costCodeId!=nextLineItem.parents[1].costCodeId){
                    totalListSub.push({
                        costTotal : fieldListSub.costTotal ,
                        supplyTotal : fieldListSub.supplyTotal ,
                        installTotal : fieldListSub.installTotal ,
                        costTotalBuffer : fieldListSub.costTotalBuffer,
                        supplyAndInstallTotal : fieldListSub.supplyAndInstallTotal,
                        costTotalTotal : fieldListSub.costTotalTotal ,
                        sellTotal : fieldListSub.sellTotal  ,
                        sellTotalBuffer : fieldListSub.sellTotalBuffer ,
                        sellTotalTotal : fieldListSub.sellTotalTotal ,
                        gp : fieldListSub.gp ,
                        gpBuffer : fieldListSub.gpBuffer ,
                        gpTotal : fieldListSub.gpTotal ,
                        index : fieldListSub.index,
                    })
 
                    fieldListSub = {
                        costTotal : 0 ,
                        supplyTotal : 0 ,
                        installTotal : 0,
                        costTotalBuffer : 0,
                        supplyAndInstallTotal : 0,
                        costTotalTotal : 0,
                        sellTotal : 0  ,
                        sellTotalBuffer : 0,
                        sellTotalTotal : 0 ,
                        gp : 0 ,
                        gpBuffer : 0 ,
                        gpTotal : 0 ,
                        index : 0,
                    }
                }
             
                 return {
                    _id : u._id,
                     lineItem : u,
                     mainCateg : showMainCateg ? mainCateg : null,
                     subCateg : showSubCatag ? subCateg : null
                 }
            })
            //loop main totals
            for(let x=0;x<totalList.length;x++){
                 const data = totalList[x]
                 ReformatData[data.index].mainCateg.totals = {}
                 ReformatData[data.index].mainCateg.totals.costTotal = data.costTotal 
                 ReformatData[data.index].mainCateg.totals.supplyTotal = data.supplyTotal 
                 ReformatData[data.index].mainCateg.totals.installTotal= data.installTotal 
                 ReformatData[data.index].mainCateg.totals.costTotalBuffer = data.costTotalBuffer
                 ReformatData[data.index].mainCateg.totals.supplyAndInstallTotal = data.supplyAndInstallTotal
                 ReformatData[data.index].mainCateg.totals.costTotalTotal = data.costTotalTotal 
                 ReformatData[data.index].mainCateg.totals.sellTotal = data.sellTotal  
                 ReformatData[data.index].mainCateg.totals.sellTotalBuffer = data.sellTotalBuffer 
                 ReformatData[data.index].mainCateg.totals.sellTotalTotal = data.sellTotalTotal 
                 ReformatData[data.index].mainCateg.totals.gp =data.gp 
                 ReformatData[data.index].mainCateg.totals.gpBuffer = data.gpBuffer 
                 ReformatData[data.index].mainCateg.totals.gpTotal = data.gpTotal 
            }
            for(let x=0;x<totalListSub.length;x++){
                 const data = totalListSub[x]
                 ReformatData[data.index].subCateg.totals = {}
                 ReformatData[data.index].subCateg.totals.costTotal = data.costTotal 
                 ReformatData[data.index].subCateg.totals.supplyTotal = data.supplyTotal 
                 ReformatData[data.index].subCateg.totals.installTotal= data.installTotal 
                 ReformatData[data.index].subCateg.totals.costTotalBuffer = data.costTotalBuffer
                 ReformatData[data.index].subCateg.totals.supplyAndInstallTotal = data.supplyAndInstallTotal
                 ReformatData[data.index].subCateg.totals.costTotalTotal = data.costTotalTotal 
                 ReformatData[data.index].subCateg.totals.sellTotal = data.sellTotal  
                 ReformatData[data.index].subCateg.totals.sellTotalBuffer = data.sellTotalBuffer 
                 ReformatData[data.index].subCateg.totals.sellTotalTotal = data.sellTotalTotal 
                 ReformatData[data.index].subCateg.totals.gp =data.gp 
                 ReformatData[data.index].subCateg.totals.gpBuffer = data.gpBuffer 
                 ReformatData[data.index].subCateg.totals.gpTotal = data.gpTotal 
             }
      
            return ReformatData
        }
    },
    methods : {
        AddItemToMovables(id){
            const self = this
            const getIndex = self.toBeMoved.indexOf(id)
            if(getIndex==-1){
                self.toBeMoved.push(id)
            }else{
                self.toBeMoved.splice(getIndex,1)
            }
            this.SyncSelectedLineItem(self.toBeMoved)
        },
        ReformatRows(){
            const self = this 
            if(self.rowDatas.length==0){
                return []
            }
            self.dataReady = false

            self.LineItemData = []
            function TotalBasedOnTheSelectedColumn(item)
            {
                for(let x =0 ; x < BoQColumnList.length; x++)
                {
                    const selectedColumnDataIndex = BoQColumnList[x].lineItemIndex
                    let data = self.totalObj[selectedColumnDataIndex]
                    if(!data)
                    {
                        self.totalObj[selectedColumnDataIndex] = item[selectedColumnDataIndex]
                    }
                    else
                    {
                        self.totalObj[selectedColumnDataIndex] += item[selectedColumnDataIndex]
                    }
                }
            }
      
            let lineItemSubCount = 1
            self.rowDatas.map((u)=>{
                const supplyAndInstallCombine = u.supplyAndInstall ? u.supplyAndInstall : (u.costRate || 0) + (u.installRate || 0)
                const  supplyAndInstallTotal = supplyAndInstallCombine * u.quantity


                u.label = lineItemSubCount++
                u.bufferCostTotal = u.costTotal - u.costTotalRaw
                u.bufferSellRate = u.sellRate - u.sellRateRaw
                u.bufferSellTotal = u.sellTotal - u.sellTotalRaw
                u.bufferGp = u.gp - u.gpRaw
                u.supplyAndInstallTotal = supplyAndInstallTotal

                TotalBasedOnTheSelectedColumn(u)
             
                self.LineItemData.push(u)
                return u
            })
            self.dataReady =true
        },
        PassData(){
            this.GetSequence(this.LineItemData)
        },
        onEndDrag(e){
            if(e.newIndex!=e.oldIndex){
                this.GetChanges()
            }
        },
        ReformatNumber : ReformatNumber,
        OpenEditOnDblClick(key){
            this.$refs.ModalUpdateLineItemBtn[key].click()
        },
        OnClickTr(data){
            const self = this
            
            const getIndex=  self.selectedLineItems.findIndex(u=>u._id==data._id)
            if(getIndex==-1){
                self.selectedLineItems.push({
                    _id : data._id,
                    costCode : data.costCode,
                    name : data.name
                })
                self.selectedLineItemsId.push(data._id)
            }else{
                self.selectedLineItems.splice(getIndex,1)
                self.selectedLineItemsId.splice(getIndex,1)
            }


            self.updateSelected(self.selectedLineItems)
        },
        OpenDelete(id){
            const self = this

            let deleteFunc = GetBoqItemDelete
            if(self.DeleteItemFunc){
                deleteFunc = self.DeleteItemFunc
            }

            const swalTitle = self.$i18n.t('Delete')

            self.$swal({
                title: swalTitle,
                text : self.$i18n.t('Bill of Quantity Module.Remove Item from Bill of Quantities') + '?',
                showCancelButton: true,
                icon: 'warning',
                showLoaderOnConfirm : true,
                allowOutsideClick: () => !self.$swal.isLoading(),
                preConfirm : ()=>{
                    return deleteFunc(id)
                }
            }).then((res)=>{
                if(res.isConfirmed){
                    if(res.value=='OK'){
                        self.$swal(swalTitle, self.$i18n.t('Deleted Successfully'), 'success')
                        self.reloadData()
                    }else{
                        self.$swal(swalTitle,res.value,'warning')
                    }
                }
            })
        },
    }

}
</script>
<style scoped>
    .sticky-top{
        position : sticky;
        top:0;
        z-index: 100;
    }
    .sticky-left{
        position : sticky;
        left:0;
        z-index: 50;
    }
    .sticky-right{
        position : sticky;
        right:0;
        z-index: 50;
    }
    .line-item-100{
        min-width: 100px;
    }
    .cursor-move{
        cursor:  move;
    }
</style>
