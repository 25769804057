import { createStore } from 'vuex'
import { GetMyUserInfo } from '@/actions/userActions.js'
import boqInfo from './modules/boqInfo.js'
import boqList from './modules/boqList.js'
import ProjectInformation from './modules/ProjectInformation.js'
import 'dotenv/config'
export default createStore({
  state:{
    activeLink : 1,
    userInfo : {},
    projectUrl : process.env.VUE_APP_PROJECT_URL
  },
  getters : {
    activeLink :state =>  state.activeLink,
    userInfo :state =>  state.userInfo,
    projectUrl :state =>  state.projectUrl,
  },
  actions:{
    ChangeActiveLink :({commit},id) => commit('setActiveLink',id),
    GetMyInfo :({commit}) => {
      GetMyUserInfo().then((res)=>{
        commit('setUserInfo',res)
      })
    },
  },
  mutations : {
    setActiveLink :  (state,data) => (state.activeLink = data),
    setUserInfo :(state,data) => (state.userInfo = data),
  },
  modules: {
    boqInfo,
    boqList,
    ProjectInformation
  }
})
