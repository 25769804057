<template>
    <BreadcrumbsProject
        :breadcrumbs="[
            {
                link : projectUrl +'/',
                title : 'Projects',
                isRouterLink: false
            },
            {
                link : projectUrl +'/Project/' + projectInfo._id,
                title : (projectInfo.projectNumber || '-') +' ' + (projectInfo.projectName || ''),
                isRouterLink: false
            },
            {
                link : '/boq/' + projectInfo._id+'/packages',
                title : 'Trade Packages',
                isRouterLink: true
            },
        ]"
    ></BreadcrumbsProject>
    <div>
        <StepItem2></StepItem2>
    </div>
    <div class="card border-top border-bottom">
        <div class="card-header bg-white">
            <div class="d-flex  justify-content-between">
                <h6 class="fw-bold">Trade Packages</h6>
                <div>
                    <router-link class="btn btn-primary me-1" :to="'/boq/' + projectInfo._id + '/packages/consolidated'" v-if="permission.indexOf(ModuleActionList.TRADE_PACKAGE_CREATE) > -1">
                        <i class="fa-solid fa-plus"></i> Add Trade Package
                    </router-link>
                    <button class="btn btn-outline-primary"><i class="fa-solid fa-file-arrow-down"></i> Export</button>
                </div>
                
            </div>
        </div>
        <div class="card-body">
            <div class="mb-2">
                <input type="search" class="form-control" v-model="tradeParams.search" placeholder="Search Trade Packages" @keyup="SearchTradePackages" @input="SearchTradePackages" />
            </div>
            <div>
                <Pagination
                :changePage="PageChange" 
                :paginationDetails="{totalPages : tradePackages.totalPages, page : tradePackages.page}" 
                :havePageCount="true"
                />
            </div>
            
            <div v-if="isLoading">
                <Loader />
            </div>
            <div class="alert alert-primary d-flex align-items-center " role="alert" v-else-if="TradePackageDocs.length==0">
                <i class="fa-solid fa-cubes fa-2x me-2"></i>  
                <div>
                    No Trade Packages Found
                    
                </div>
            </div>
            <div class="mb-4" v-else>
                <div class="accordion pt-2" :id="'accordionGroupings'+key"  v-for="(packageItem, key) in TradePackageDocs" :key="key" >
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button fw-bold text-capitalize" type="button" :data-bs-toggle="editingPackage!=packageItem._id ? 'collapse' : ''" :data-bs-target="'#collapseOne' + key" aria-expanded="true">
                                <i class="fa-solid fa-cubes pe-3"></i> 
                                <div class="d-flex" v-if="editingPackage!=packageItem._id">
                                    {{packageItem.name}} 
                                </div>
                                <input type="text" class="form-control" @keyup="(e)=>{ChangePackageName(e,packageItem.name)}" @click.stop v-model="packageItem.name" placeholder="" v-else />

                            </button>
                        </h2>
                        <div :id="'collapseOne' + key"   class="accordion-collapse collapse show" aria-labelledby="headingOne" :data-bs-parent="'#accordionGroupings'+key">
                            <div class="accordion-body p-0">
                                <div class="d-flex justify-content-between ">
                                    <div class="">
                                        <button class="btn btn-outline-warning m-1" @click="RemoveItemFromPackage(packageItem)" v-if="selectedRemove.length!=0&&editingPackage==packageItem._id" ><i class="fa-solid fa-trash"></i> Remove</button>
                                    </div>
                                    <div>
                                        <div v-if="editingPackage!=packageItem._id">
                                            <button class="btn btn-outline-primary m-1" @click="OpenEditPackage(packageItem,key)" v-if="permission.indexOf(ModuleActionList.TRADE_PACKAGE_EDIT) > -1" ><i class="fa-solid fa-pencil"></i> Edit</button>
                                        </div>
                                        <div  v-if="editingPackage==packageItem._id"> 
                                            <button class="btn btn-primary m-1" @click="(e)=>{UpdateItemFromTradePackage(packageItem,e,key)}" ><i class="fa-solid fa-floppy-disk"></i> Save Changes</button>
                                            <button class="btn btn-outline-primary m-1"  @click="OpenEditPackage(packageItem,key)" ><i class="fa-solid fa-times"></i> Cancel</button>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="table-responsive">
                                    <table class="table table-striped" >
                                        <thead>
                                            <tr>
                                                <th v-if="editingPackage==packageItem._id"></th>
                                                <th>Cost Code</th>
                                                <th>Description</th>
                                                <th>Qty</th>
                                                <th>Unit</th>
                                                <th>Rate</th>
                                                <th>Cost</th>
                                                <th>Mark Up%</th>
                                                <th>Price</th>
                                                <th>GP</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, itemKey) in packageItem.bill_of_quantities_consolidated_item" :key="itemKey" @click="ClickTr(item._id,packageItem._id )">
                                                
                                                <td style="width:10px" v-if="editingPackage==packageItem._id" >
                                                    <input type="checkbox" v-model="selectedRemove" :value="item._id" tabindex="-1" /> 
                                                </td>
                                                <td style="width:100px" >{{item.boqItemId.costCode}}</td>
                                                <td style="width:400px" >
                                                     <p>
                                                        <span class="fw-bold text-capitalize">{{item.boqItemId.parents[1].costCode}} {{item.boqItemId.parents[1].name}} </span> <br/>
                                                        <span class=" text-capitalize">{{item.boqItemId.costCode}} {{item.boqItemId.name}} </span> <br/>
                                                        <small>
                                                            {{item.boqItemId.description}}
                                                        </small>
                                                    </p>
                                                </td>
                                                <td style="width:100px" >
                                                    <span>{{ ReformatNumber(item.boqItemId.quantity)}}</span>
                                                </td>
                                                <td >{{item.boqItemId.unit}}</td>
                                                <td  style="width:150px"> 
                                                    <span v-if="editingPackage!=packageItem._id">{{ ReformatNumber(item.rate)}}</span>
                                                    <input v-if="editingPackage==packageItem._id"  @click.stop class="form-control" type="number" v-model="item.rate" /> 
                                                </td>
                                                <td >{{(item.cost || '0.00').toLocaleString(undefined, {minimumFractionDigits: 2})}}</td>
                                                <td  style="width:120px"> 
                                                    <span v-if="editingPackage!=packageItem._id">{{item.markUp || '0'}}%</span>
                                                    <input v-if="editingPackage==packageItem._id"  @click.stop class="form-control" type="number"  v-model="item.markUp" max=100 min="-100" /> 
                                                </td>
                                                <td> {{ ReformatNumber(item.price) }}</td>
                                                <td>{{ ReformatNumber(item.gp)}}</td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr >
                                                <th v-if="editingPackage==packageItem._id"></th>
                                                <th colspan="2" class="text-end fw-bold"> Total</th>
                                                <th>{{ ReformatNumber(packageItem.total.qtyTotal) }}</th>
                                                <th></th>
                                                <th>{{ ReformatNumber(packageItem.total.rateTotal) }}</th>
                                                <th>{{ ReformatNumber(packageItem.total.costTotal) }}</th>
                                                <th></th>
                                                <th>{{ ReformatNumber(packageItem.total.priceTotal)}}</th>
                                                <th>{{ ReformatNumber(packageItem.total.gpTotal) }}</th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Pagination
                :changePage="PageChange" 
                :paginationDetails="{totalPages : tradePackages.totalPages, page : tradePackages.page}" 
                :havePageCount="true"
                />
            </div>
        </div>
    </div>
</template>

<script src="@/assets/boq/TradePackageList.js" />