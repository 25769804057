<template>
    <td class="text-end"  v-for="(item, key) in ColumnNeeded" :key="key">
        <span v-if="NoTotalBoQColumnList.findIndex(u=>u.lineItemIndex==item.lineItemIndex) >- 1">
            
        </span>
        <span v-else :class="{'text-danger' : data[item.lineItemIndex] < 0 }">
            {{ReformatNumber(data[item.lineItemIndex],item.minDecimal,item.maxDecimal)}}
        </span>
    </td>
</template>

<script>
import {BoQColumnList, NoTotalBoQColumnList} from '@/helpers/utilities.js'
export default {
    name : 'BoqLineItemRow',
    props : ['data','selectedColumns','ReformatNumber'],
    data (){
        return {
            NoTotalBoQColumnList 
        }
    },
    computed: {
        ColumnNeeded(){
            return BoQColumnList.filter(u=>this.selectedColumns.indexOf(u.value) > -1)
        }
    }
}
</script>