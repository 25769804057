<template>
    <div class="table-responsive" style="height:64vh" >
        <table class="table table-bordered" >
            <thead class="sticky-top">
                <tr >
                    <th style="min-width:75px;" valign="middle" class="sticky-left">{{ $t('S/N') }}</th>
                    <th style="min-width:600px;" valign="middle" class="sticky-left">{{ $t('Description') }}</th>
                    <th style="min-width:200px;" valign="middle">{{ $t('Space ID') }}</th>
                    <th valign="middle">{{ $t('Unit') }}</th>
                    <BoqLineItemRowThead 
                    :selectedColumns="selectedColumns" />
                </tr>
            </thead>
               <tbody class="table-group-divider"  v-if="rowDatas.length==0">
                 <tr>
                    <td 
                     colspan="23">{{ $t('No data Found') }}
                    </td>
                </tr>
                </tbody>
                <tbody v-else >
                    <template v-for="(element,key) in LineItemData" :key="key">
                        <tr  >
                            <td class="text-center">
                                {{element.label}}
                            </td>
                            <td class="sticky-left bg-white">
                                <div >
                                    <div  class="d-flex justify-content-between" >
                                        <span class="fw-bold text-capitalize" v-if="userInfo?.language=='en'">{{element.name}}</span>
                                        <span class="fw-bold" v-else>{{element.nameCh || element.name}}</span>
                                        <span>
                                            <small v-if="element.remark" class="text-info me-1 bg-light px-1 rounded">{{ $t('BillOfQuantityRemark.' + element.remark) }}</small>
                                            <small v-if="element.rateOnly" class="text-danger bg-light px-1 rounded">{{$t('RATE ONLY')}}</small>
                                        </span>
                                    </div>
                                    <p class="small mb-0 description-line-item">
                                            {{element.description}}
                                        </p>
                                </div>
                            </td>
                            <td  >{{element.area}}</td>
                            <td>{{element.unit}}</td>
                            <BoqLineItemRow 
                            :data="element" :ReformatNumber="ReformatNumber" :selectedColumns="selectedColumns" />
                        </tr>
                    </template>
                </tbody>
                <tfoot>
                    <tr class="bg-light-primary fw-bold sticky-bottom">
                        <td style="min-width:75px;" ></td>
                        <td style="min-width:600px;" class="bg-light-primary sticky-left" >{{$t('Totals')}}</td>
                        <td style="min-width:200px;"></td>
                        <td></td>
                        <BoqLineItemRowFooter 
                            :data="totalObj" :ReformatNumber="ReformatNumber" :selectedColumns="selectedColumns" />
                    </tr>
                </tfoot>
        </table>
    </div>
</template>

<script>
import { ReformatNumber, BoQColumnList, LineItemRemarks} from '@/helpers/utilities.js'
import BoqLineItemRow from '@/components/boq/BoqLineItemRow.vue'
import BoqLineItemRowThead from '@/components/boq/BoqLineItemRowThead.vue'
import BoqLineItemRowFooter from '@/components/boq/BoqLineItemRowFooter.vue'
import { mapGetters } from 'vuex'
export default {
    name : 'BoqItemTableBreakdown',
    props : ['rowDatas','selectedColumns'],
    data(){
        return {
            selectedLineItems : [],
            selectedLineItemsId : [],
            LineItemData : [],
            dataChanges : [],
            permissionList : [],
            dataReady :false,
            totalObj : {},
            LineItemRemarks
        }
    },
    components : {
        BoqLineItemRow,BoqLineItemRowThead, BoqLineItemRowFooter
    },
    mounted : async function(){
        this.ReformatRows()
    },
    methods : {
        ReformatNumber,
        ReformatRows(){
            const self = this 
            if(self.rowDatas.length==0){
                return []
            }
            self.dataReady = false

            self.LineItemData = []
      
            let lineItemSubCount = 1

            function TotalBasedOnTheSelectedColumn(item)
            {
                for(let x =0 ; x < BoQColumnList.length; x++)
                {
                    const selectedColumnDataIndex = BoQColumnList[x].lineItemIndex
                    let data = self.totalObj[selectedColumnDataIndex]
                    if(!data)
                    {
                        self.totalObj[selectedColumnDataIndex] = item[selectedColumnDataIndex]
                    }
                    else
                    {
                        self.totalObj[selectedColumnDataIndex] += item[selectedColumnDataIndex]
                    }
                }
            }
      
            self.rowDatas.map((u)=>{
                const supplyAndInstallCombine = u.supplyAndInstall ? u.supplyAndInstall : (u.costRate || 0) + (u.installRate || 0)
                const  supplyAndInstallTotal = supplyAndInstallCombine * u.quantity

                u.label = lineItemSubCount++
                u.bufferCostTotal = u.costTotal - u.costTotalRaw
                u.bufferSellRate = u.sellRate - u.sellRateRaw
                u.bufferSellTotal = u.sellTotal - u.sellTotalRaw
                u.bufferGp = u.gp - u.gpRaw
                u.supplyAndInstallTotal = supplyAndInstallTotal

                
                TotalBasedOnTheSelectedColumn(u)
             
                self.LineItemData.push(u)
                return u
            })
            self.dataReady =true
        },
    },
    computed : {
        ...mapGetters(['userInfo'])
    }

}
</script>
<style scoped>
    .sticky-top{
        position : sticky;
        top:0;
        z-index: 100;
    }
    .sticky-left{
        position : sticky;
        left:0;
        z-index: 50;
    }
    .sticky-right{
        position : sticky;
        right:0;
        z-index: 50;
    }
    .line-item-100{
        min-width: 100px;
    }
</style>
