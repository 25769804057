<template>
<BreadcrumbsProject
        :breadcrumbs="[
            {
                link : '/high-level-estimates',
                title : $t('Bidding'),
                isRouterLink: true
            },
        ]"
/>

<div class="card">
    <div class="card-header bg-white">
        <div class="d-flex justify-content-between">
            <h6>{{ $t('Bidding Module.Bidding')}}</h6>
            <div>
                <button class="btn btn-primary"  data-bs-toggle="modal" data-bs-target="#HleProjectAdd" @click="OpenCreateModal" ><i class="fa-solid fa-plus"></i> {{ $t('Add') }}</button>
            </div>
        </div>
    </div>
    <div class="card-body">
        <div class="row pb-2">
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{ $t('Search')}}</label>
                    <input type="search" class="form-control" v-model="searchBar" :placeholder="$t('Bidding Module.Search Name of Biddings here')" />
                </div>
            </div>
            <div class="col-md-6">
                <!-- <div class="form-group">
                    <label>List By</label>
                    <Multiselect 
                    :sort="false" :options="creatorOptions"
                    v-model="selectedCreatedBy" :searchable="true" 
                    placeholder="Type Here to select creator"
                    :clear-on-select="false" :max-height="300" :internalSearch="true"
                    label="value" track-by="id"/>
                </div> -->
            </div>
        </div>
        <div class="table-responsive">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>{{ $t('Bidding Module.Bidding Name') }}</th>
                        <th>{{ $t('Currency') }}</th>
                        <th>{{ $t('Office') }}</th>
                        <th>{{ $t('Status') }}</th>
                        <th>{{ $t('Created By') }}</th>
                        <th>{{ $t('Created At') }}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody  v-if="isLoading">
                    <tr>
                        <td  colspan="7">
                            <Loader />
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr v-if="projectList.docs.length==0">
                        <td class="text-center" colspan="7" >{{$t('No data Found')}}</td>
                    </tr>
                    <tr v-for="(item,key) in projectList.docs" :key="key">
                        <td>
                            
                            <router-link :to="{name : 'HleList', params : {id : item._id}}" class="text-capitalize link-item-route"> 
                                {{item.projectName}} 
                            </router-link>
                        </td>
                        <td>
                            {{ item.currencyId.sign }} {{ item.currencyId.name }}
                        </td>
                        <td class="text-capitalize">
                            {{ item.officeId.officeName }}
                        </td>
                        <td class="text-center"><span :class="'badge ' + ApprovalStageBoqObj[item.status - 1].color">{{ApprovalStageBoqObj[item.status - 1].name}}</span></td>
                        <td class="text-capitalize">
                            {{ item.createdBy.fullname }}
                        </td>
                        <td>
                            {{ ReformatDate(item.createdAt)}}
                        </td>
                        <td>
                            <router-link :to="{name : 'HleList', params : {id :  item._id} }" class="btn btn-outline-primary btn-action me-1"> 
                                <i class="fa-solid fa-magnifying-glass"></i>
                            </router-link>
                            <button class="btn btn-outline-primary btn-action me-1" @click="OpenUpdateModal(item)"
                            data-bs-toggle="modal" data-bs-target="#HleProjectUpdate"
                            > <i class="fa-solid fa-edit"></i></button>
                            <button class="btn btn-outline-danger btn-action me-1" @click="DeleteProject(item._id,item.projectName)"> <i class="fa-solid fa-trash-can"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        
        <Pagination
        :changePage="PageChange" 
            :paginationDetails="{totalPages : projectList.totalPages, page : projectList.page}" 
            :havePageCount="true"
        />
    </div>
</div>

<Modal idModal="HleProjectAdd" :headerTitle="$t('Bidding Module.Create Bidding')" modalSize="modal-md" >
    <div class="form-group">
        <label class="required-style"> {{$t('Bidding Module.Bidding Name') }} </label>
        <input class="form-control" :placeholder="$t('Bidding Module.Bidding Name') " v-model="newProject.name" />
        <small class="text-warning" v-if="isSaveProjectClicked && !newProject.name">{{ $t('Required') }}</small>
    </div>
    <div class="form-group">
        <label class="required-style"> {{ $t('Currency') }}  </label>
        <select class="form-select" v-model="newProject.currencyId" >
            <option value="">{{ $t('Please Select') }}</option>
            <option v-for="(item, key) in boqCurrency" :key="key" :value="item._id">{{item.sign}} {{item.name}} </option>
        </select>
        <small class="text-warning" v-if="isSaveProjectClicked && !newProject.currencyId">{{ $t('Required') }}</small>
    </div>
    <div class="form-group">
        <label class="required-style">{{ $t('Office in Charge') }}  </label>
        <Select2
            :dataOptions="officeList"
            indexValue="_id"
            labelName="officeName"
            :placeHolder="$t('Please Select')"
            v-model:optionValue="newProject.officeId"
        >
        </Select2>
        <small class="text-warning" v-if="isSaveProjectClicked && !newProject.officeId">{{ $t('Required') }}</small>
    </div>
    <template v-slot:actions>
        <button type="button" ref="closeHleProjectAdd" class="btn btn-outline-danger" data-bs-dismiss="modal">X  {{ $t('Cancel') }}</button>
        <button type="button" class="btn btn-primary" :disabled="isOnProcess" @click="CreateProject($event.target)" ><i class="fas fa-save"></i>  {{ $t('Save Changes') }}</button>
    </template>
</Modal>
<Modal idModal="HleProjectUpdate" :headerTitle="$t('Bidding Module.Update Bidding')" modalSize="modal-md" >
    <div class="form-group">
        <label class="required-style">{{$t('Bidding Module.Bidding Name')}} </label>
        <input class="form-control" :placeholder="$t('Bidding Module.Bidding Name') " v-model="updateProject.name" />
        <small class="text-warning" v-if="isUpdateProjectClicked && !updateProject.name">{{ $t('Required') }}</small>
    </div>
    <div class="form-group">
        <label class="required-style">  {{$t('Currency') }} </label>
        <select class="form-select" v-model="updateProject.currencyId" >
            <option value="">{{ $t('Please Select') }}</option>
            <option v-for="(item, key) in boqCurrency" :key="key" :value="item._id">{{item.sign}} {{item.name}} </option>
        </select>
        <small class="text-warning" v-if="isUpdateProjectClicked && !updateProject.currencyId">{{ $t('Required') }}</small>
    </div>
    <div class="form-group">
        <label class="required-style">{{$t('Office in Charge') }} </label>
        <Select2
            :dataOptions="officeList"
            indexValue="_id"
            labelName="officeName"
            :placeHolder="$t('Please Select')"
            v-model:optionValue="updateProject.officeId"
        >
        </Select2>
        <small class="text-warning" v-if="isUpdateProjectClicked && !updateProject.officeId">{{ $t('Required') }}</small>
    </div>
    <template v-slot:actions>
        <button type="button" ref="closeHleProjectUpdate" class="btn btn-outline-danger" data-bs-dismiss="modal">X  </button>
        <button type="button" class="btn btn-primary" :disabled="isOnProcess" @click="UpdateProject($event.target)" ><i class="fas fa-save"></i> {{ $t('Save Changes') }}</button>
    </template>
</Modal>
</template>

<script>
import 'dotenv/config'
import Loader from '@/components/layout/Loader.vue'
import BreadcrumbsProject  from '@/components/projectInformation/BreadcrumbsProject.vue'
import  {mapActions,mapGetters} from 'vuex'
import {AddHighLevelProject, UpdateHighLevelProject,GetHighLevelProjectList ,DeleteHighLevelProject} from '@/actions/boqActions.js'
import {GetOffices} from '@/actions/userActions.js'
import {LinkList,ReformatDate, ApprovalStageBoqObj} from '@/helpers/utilities.js'
import Pagination from '@/components/layout/Pagination.vue'
import Select2 from '@/components/layout/Select2.vue'


import Modal from '@/components/layout/Modal.vue'
export default {
    name : 'HleProjectList',
    components : {
        BreadcrumbsProject, Modal, Loader,
        Pagination,Select2
    },
    data (){
        return {
            projectList : {
                docs : []
            },
            pagedParams : {
                listBy : 1,
                page : 1,
                search : ''
            },
            newProject : {},
            isSaveProjectClicked :false,
            updateProject : {},
            isUpdateProjectClicked :false,
            isLoading : true,
            isOnProcess : false,
            selectedCreatedBy : { id : 1, value : 'My Biddings'},
            searchBar : '',
            creatorOptions  : [ 
                { id : 1, value : 'My Biddings'},
                { id : 2, value : 'Office Biddings'},
            ],
            timerSearch  : null,
            ApprovalStageBoqObj,
            officeList : [],
        }
    },
    mounted : async function(){
        const self = this
        self.ChangeActiveLink(LinkList.HIGH_LEVEL_ESTIMATES)
        await self.GetCurrencyListRun()
        await self.ReloadData()
        self.officeList = await GetOffices()
    },  
    computed :{
        ...mapGetters(['boqCurrency'])
    },
    methods : {
        ...mapActions(['ChangeActiveLink','GetCurrencyListRun']),
        ReformatDate,
        async PageChange(page){
            const self = this
            self.pagedParams.page = page
            await self.ReloadData()
        },
        timerSetterSearch(callback){
            const self = this
            
            if(self.timerSearch){
                clearTimeout(self.timerSearch)
                self.timerSearch = null
            }

            self.timerSearch = setTimeout(callback,700)
        },
        async ReloadData(){
            const self = this

            self.isLoading = true
            self.projectList = await GetHighLevelProjectList(self.pagedParams)
            self.isLoading = false
        },
        OpenCreateModal(){
            this.newProject  ={
                currencyId :''
            }
        },
        async CreateProject(){

            const self = this

            if(self.isOnProcess){ return }

            let data = self.newProject

            self.isSaveProjectClicked = true
            if(!data.name  || !data.currencyId){
                return
            }
            self.isSaveProjectClicked = false
            self.isOnProcess = true
            const result = await AddHighLevelProject(data)
            self.isOnProcess = false
            if(result=='OK'){
                self.$swal(self.$i18n.t('Bidding Module.Create Bidding'),self.$i18n.t('Bidding Module.Added Successfully'),'success')
                self.$refs.closeHleProjectAdd.click()
                self.ReloadData()
            }
            else{
                self.$swal(self.$i18n.t('Bidding Module.Create Bidding'),result,'warning')
            }
        },
        OpenUpdateModal(item){
            const self = this
            self.updateProject.id = item._id
            self.updateProject.currencyId = item.currencyId._id
            self.updateProject.name = item.projectName
            self.updateProject.officeId = item.officeId._id
        },
        async UpdateProject(){
            const self = this

            if(self.isOnProcess){ return }

            let data = self.updateProject

            self.isUpdateProjectClicked = true
            if(!data.name  || !data.currencyId){
                return
            }
            self.isUpdateProjectClicked = false
            self.isOnProcess = true
            const result = await UpdateHighLevelProject(data)
            self.isOnProcess = false
            if(result=='OK'){
                self.$swal(self.$i18n.t('Bidding Module.Update Bidding'),self.$i18n.t('Updated Successfully'),'success')
                self.$refs.closeHleProjectUpdate.click()
                self.ReloadData()
            }
            else{
                self.$swal(self.$i18n.t('Bidding Module.Update Bidding'),result,'warning')
            }
        },
        DeleteProject(id,name){
            const self = this
            self.$swal({
                title: self.$i18n.t('Bidding Module.Delete Bidding'),
                text : `"${name}" ${self.$i18n.t('Bidding Module.will be Deleted Permanently, Proceed?')}'`,
                showCancelButton: true,
                icon: 'warning',
                confirmButtonText: self.$i18n.t('Delete'),
                cancelButtonText : self.$i18n.t('Cancel'),
                showLoaderOnConfirm : true,
                allowOutsideClick: () => !self.$swal.isLoading(),
                preConfirm : ()=>{
                    return DeleteHighLevelProject({id : id})
                }
            }).then(async (res)=>{
                if(res.isConfirmed){
                    if(res.value=='OK'){
                        await self.ReloadData()
                        self.$swal( self.$i18n.t('Bidding Module.Delete Bidding'),'','success')
                    }else{
                        self.$swal(self.$i18n.t('Bidding Module.Delete Bidding'),res.value,'warning')
                    }
                
                }
            })
        },
    },
    watch : {
        searchBar(val) {
            const self = this
            self.pagedParams.page = 1
            self.pagedParams.search = val
            self.timerSetterSearch(async function(){
                await self.ReloadData()
            })
        },
        selectedCreatedBy(val) {
            const self = this
            self.pagedParams.page = 1
            if(val)
                self.pagedParams.listBy = val.id
            else 
                self.pagedParams.listBy = undefined
            self.timerSetterSearch(async function(){
                await self.ReloadData()
            })
        },
    }


}


</script>