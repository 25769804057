<template>
    <div class="">
        <Pagination
        :changePage="PageChange" 
        :paginationDetails="{totalPages : boqList.totalPages, page : boqList.page}" 
        :havePageCount="true"
        ></Pagination>
    </div>
    <div class="clearfix"></div>
    <table class="table table-striped table-bordered">
            <thead>
                <tr>
                    <!-- <th class="text-center" @click="()=>{ this.toggler = !this.toggler; this.ToggleAll() }">
                        <input type="checkbox" ref="checkAll" v-model="toggler" @change="ToggleAll" />
                    </th> -->
                    <th>{{$t('Name')}}</th>
                    <th>{{$t('Reference')}}</th>
                    <th > {{$t('Contract Sum')}}({{ projectInfo.currencyId.sign }})</th>
                    <th >{{$t('Total Cost')}} ({{ projectInfo.currencyId.sign }})</th>
                    <th > {{$t('Total Sell')}}({{ projectInfo.currencyId.sign }})</th>
                    <th > {{$t('GP Total')}}({{ projectInfo.currencyId.sign }})</th>
                    <th > {{$t('GP')}}%</th>
                    <th>{{$t('Status')}}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody class="text-capitalize table-group-divider" >
                <tr v-if="boqList.docs==0">
                    <td colspan="10" class="text-center" >
                        {{$t('No data Found')}}
                    </td>
                </tr>
                <tr  v-for="(item, key) in boqList.docs" :key="key" @click="ClickTd(item._id)" >
                    <!-- <td  class="text-center" >
                        <input type="checkbox" v-model="selected"  :value="item._id" />
                    </td> -->
                    <td title="Click to View Items">
                        <router-link :to="{name : 'BoqBuilder', params : {id : item._id}}" class="link-item-route"> 
                            {{item.name}} 
                        </router-link>
                        
                    </td>
                    <td>{{item.reference?.quotationNumber || '-'}}</td>
                    <td class="text-end">{{ReformatNumber(item.targetBudget)}}</td>
                    <template v-if="item.summary">
                        <td class="text-end">{{ReformatNumber(item.summary.costTotal)}}</td>
                        <td class="text-end">{{ReformatNumber(item.summary.sellTotal)}}</td>
                        <td class="text-end">{{ReformatNumber(item.summary.gp)}}</td>
                                <td class="text-end">{{ReformatNumber((item.summary.gp / item.summary.sellTotal) * 100)}}%</td>
                    </template>
                    <template v-else>
                        <td class="text-end">0.00</td>
                        <td class="text-end">0.00</td>
                        <td class="text-end">0.00</td>
                        <td class="text-end">0%</td>
                    </template>
                    <td>
                        <span :class="'badge ' + ApprovalStageBoqObj[item.approvalStage -1].color">{{ApprovalStageBoqObj[item.approvalStage-1].name}}</span>
                    </td>
                    <td>

                        <router-link :to="{name : 'BoqBuilder', params : {id : item._id}}" class="btn btn-outline-primary btn-action me-1" title="View BoQ"> 
                            <i class="fa-solid fa-magnifying-glass"></i>
                        </router-link>
                        <button 
                        class="btn btn-outline-primary btn-action me-1" 
                        data-bs-toggle="modal" 
                        data-bs-target="#BoqModalUpdate" 
                        v-if="permission.indexOf(ModuleActionList.BOQ_EDIT)>-1"
                        @click.stop="GetBoq(item._id, item.name, item.currencyId._id, item.reference)"
                        :title="$t('Update BoQ')"
                        ><i class="fa-solid fa-edit"></i></button>

                        <button 
                        class="btn btn-outline-primary btn-action me-1" 
                        v-if="permission.indexOf(ModuleActionList.BOQ_EDIT)>-1"
                        @click.stop="CreateDuplicate(item._id, item.name, item.currencyId._id)"
                        data-bs-toggle="modal" 
                        data-bs-target="#BoqModalCreateDuplicate" 
                        :title="$t('Duplicate BoQ')"
                        ><i class="fa-solid fa-copy"></i></button>

                        <button 
                        v-if="item.approvalStage > 1"
                        class="btn btn-outline-primary btn-action me-1" 
                        @click.stop="GoToHistory(item._id)"
                        :title="$t('History')"
                        ><i class="fa-solid fa-clock-rotate-left"></i></button>

                        <button 
                        class="btn btn-outline-danger btn-action " 
                        v-if="permission.indexOf(ModuleActionList.BOQ_ITEM_REMOVE)>-1 && item.approvalStage==1"
                        @click.stop="DeleteBoq(item._id, item.name)"
                        :title="$t('Delete BoQ')"
                        ><i class="fa-solid fa-trash-can"></i></button>
                    </td>
                </tr>
            </tbody>
    </table> 
</template>


<script>
import  {mapGetters, mapActions} from 'vuex'
import {ReformatDate, ReformatNumber,ApprovalStageBoqObj, ModuleActionList} from '@/helpers/utilities.js'
import Pagination from '@/components/layout/Pagination.vue'

export default {
    name : 'TableBoq',
    props: {
        GetBoq : Function,
        CreateDuplicate : Function,
        search : String,
        UpdateSelectedValue : Function,
        DeleteBoq :Function
    },
    components :{
        Pagination
    },
    data(){
        return {      
            toggler : false,
            selected : [],
            ApprovalStageBoqObj : ApprovalStageBoqObj,
            ModuleActionList : ModuleActionList
        }
    },
    methods : {
        ...mapActions(['GetBoqListRun']),
        formatDate(value){
            return ReformatDate(value)
        },
        ReformatNumber: ReformatNumber,
        PageChange(page){
            const self = this
            const projectId = self.$route.params.id
            self.GetBoqListRun({id : projectId,page : page, search : self.search}).catch()
        },
        GoToHistory(id){
            this.$router.push('/boq/history/'+id)
        },
        ToggleAll(){
            const self = this
            const ids = this.boqList.docs.map(u=>u._id)
            if(self.toggler){
                const toBePushed = ids.filter(u=>!self.selected.includes(u))
                if(toBePushed.length>0){
                    self.selected.push(...toBePushed)
                }
            }
            else{
                this.selected = self.selected.filter(u=>!ids.includes(u))
            }
        },
        ClickTd(id){
            const index = this.selected.indexOf(id)
            if(index == -1 ){
                this.selected.push(id)
            }else{
                this.selected.splice(index,1)
            }
        },
        
    },
    computed : mapGetters(['boqList','permission','projectInfo']),
    watch : {
        selected :{
            handler(newValue){
                this.UpdateSelectedValue(newValue)
            },
            deep : true
        }
        
    }

}
</script>
