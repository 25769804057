<template>
    <Modal idModal="BoqColumnFilter" :headerTitle="$t('Manage Columns')" modalSize="modal-lg" >
    <div>
        <div  class="border p-2">
            <i class="fa-solid fa-circle-info text-primary"></i>
            <small class="">
            {{ $t('Tick a Checkbox to Hide and Show Columns')}}
        </small>
        </div>
        <div class="pt-2">
            <input type="checkbox" @change="CheckAll" id="checker-col-checkall" role="button" class="me-1" v-model="checkedAll" />  
            <label for="checker-col-checkall" class="fw-bold"> {{checkedAll ? $t('Uncheck') : $t('Check')}} {{ $t('All') }}</label>
        </div>
        <div class="row pt-2 pb-2" v-for="(item, key) in ColumnGroupings" :key="key" >
            <div>
                <input type="checkbox" @change="(e)=>{CheckGroup(e,item.columns)}" :id="'checker-col-group-' + key" class="me-1" role="button" />
                <label class="fw-bold" :for="'checker-col-group-' + key" >{{item.name}}</label>
            </div>

            <div class="col-md-4" v-for="(itemCol, keyCol) in item.columns" :key="keyCol" role="button" @click="ClickTr(itemCol.value)">
                <input type="checkbox" :value="itemCol.value" v-model="selectedColumn" /> <label role="button" >{{itemCol.name}}</label>
            </div>
        </div>
        
    </div>
    <template v-slot:actions>
        <button type="button" ref="closeModalImportBoq" class="btn btn-outline-danger" data-bs-dismiss="modal">X {{ $t('Cancel') }}</button>
        <button type="button" class="btn btn-primary" @click="ShowColumns"  > <i class="fa-solid fa-table-columns"></i> {{ $t('Show Selected Columns') }}</button>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/layout/Modal.vue'
import {BoQColumnList} from '@/helpers/utilities.js'
export default {
    name : 'ModalLineItemColumnFilter',
    props : ['UpdateSelectedColumn'],
    components : {
        Modal
    },
    data (){
        const self = this
        return {
            columnSet : BoQColumnList,
            selectedColumn :  [],
            checkedAll : false,
            groupCheck : [],
            ColumnGroupings : [
                {
                    name : self.$i18n.t('Quantity Columns'),
                    columns : BoQColumnList.filter(u=>u.group==1)
                },
                {
                    name : self.$i18n.t('Cost Rate Columns'),
                    columns : BoQColumnList.filter(u=>u.group==2)
                },
                {
                    name : self.$i18n.t('Total Cost Columns'),
                    columns : BoQColumnList.filter(u=>u.group==3)
                },
                {
                    name : self.$i18n.t('Mark Up'),
                    columns : BoQColumnList.filter(u=>u.group==4)
                },
                {
                    name : self.$i18n.t('Sell Rate Columns'),
                    columns : BoQColumnList.filter(u=>u.group==5)
                },
                {
                    name : self.$i18n.t('Total Sell Columns'),
                    columns : BoQColumnList.filter(u=>u.group==6)
                },
                {
                    name : self.$i18n.t('Gross Profit Columns'),
                    columns : BoQColumnList.filter(u=>u.group==7)
                }
            ]
        }
    },
    mounted() {
        const cols = window.localStorage.getItem('lineItemColumns')
        if(cols){
            this.selectedColumn = cols.split(',').map(Number)
        }else{
            this.selectedColumn = [3,10,13,14,17,20,23]
            window.localStorage.setItem('lineItemColumns',this.selectedColumn)
        }
    },
    // watch : {
    //     selectedColumn(value){
    //         console.log(value)
    //         // this.UpdateSelectedColumn(value)
    //         // window.localStorage.setItem('lineItemColumns',value)
    //     }
    // },
    methods :{
        CheckAll(e){
            const checked = e.target.checked
            const all = BoQColumnList.map(u=>Number(u.value))
            this.groupCheck = []
            if(checked)
                this.selectedColumn = all
            else
                this.selectedColumn = []
            
        },
        ClickTr(value){
            const getIndex = this.selectedColumn.indexOf(value)

            if(getIndex==-1)
                this.selectedColumn.push(Number(value))
            else{
                this.selectedColumn.splice(getIndex,1)
            }

            // this.UpdateSelectedColumn(this.selectedColumn)
            // window.localStorage.setItem('lineItemColumns',this.selectedColumn)
        },
        ShowColumns(){
            const sorted = this.selectedColumn.sort(function(a, b) {
            return a - b;
            })
            this.UpdateSelectedColumn(JSON.parse(JSON.stringify(sorted)))
            window.localStorage.setItem('lineItemColumns',sorted)
            this.$refs.closeModalImportBoq.click()
        },
        CheckGroup(e,columns){
            const checked = e.target.checked

            let data = columns.map(u=>u.value)

            if(checked){
                this.selectedColumn.push(...data.filter(u=>this.selectedColumn.indexOf(u)== -1))
            }else{
                this.selectedColumn = this.selectedColumn.filter(u=>data.indexOf(u) == -1)
            }
        }
    }
}
</script>