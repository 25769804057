
import {GetProject,SearchUomDirectory} from '@/actions/projectActions.js'
import { stageIcons,statusIcons,ContractType, ReformatDate } from '@/helpers/utilities.js'
import router from '@/router'

const state = {
    projectInfo : {
        stage : 1,
        statusIcon :{
            name : '',
            icon : ''
        },
        stageProcess : []
    },
    permission : [],
    unitOfMeasures : []
}
const getters ={
    projectInfo : state => state.projectInfo,
    permission : state => state.permission,
    unitOfMeasures : state => state.unitOfMeasures,
}
const actions = {
    async GetProjectInfo({commit}, id){
        let projectInfoAll = await GetProject(id)
        if([401,400].indexOf(projectInfoAll)>-1){
            router.push('/unauthorized')
        }

        let projectInfo = projectInfoAll.project
        commit('setPermission',projectInfoAll.permission.accessList)

        let project = {
            _id : projectInfo._id,
            officeId : projectInfo.officeId,
            projectName : projectInfo.projectName,
            projectAddress : projectInfo.projectAddress,
            projectNumber : projectInfo.projectNumber,
            contractType : ContractType[projectInfo.contractType],
            contractTypeInt : projectInfo.contractType,
            startDate : ReformatDate(projectInfo.startDate),
            stageIcon : stageIcons[projectInfo.stage],
            stage : Number(projectInfo.stage),
            statusIcon : statusIcons[projectInfo.status],
            stageProcess : projectInfo.stageProcess,
            currencyId : projectInfo.currencyId,
            projectOldId :projectInfo.projectOldId,
            squareMeter : projectInfo.squareMeter,
            squareFoot : projectInfo.squareFoot
        }

        commit('setProjectInfo',project)
    },
    async GetUnitOfMeasures({commit}){
        const uom = this.getters.unitOfMeasures
        if(uom.length==0){
            const data = await SearchUomDirectory()
            commit('setUnitOfMeasures',data)
        }
    }
}
const mutations ={
    setProjectInfo : (state, data) => (state.projectInfo = data),
    setPermission : (state, data) => (state.permission = data),
    setUnitOfMeasures : (state, data) => (state.unitOfMeasures = data),
}

export default {
    state,
    getters,
    actions,
    mutations
}
