<template>
    <div class="card">
        <div class="card-header"><h6>{{$t('Approval Module.Setup')}} {{isExternal ? $t('Approval Module.External') : $t('Approval Module.Internal')}} {{ $t('Approval Module.Approval') }}</h6>
        </div>
        <div class="card-body">
            <div class="form-group">
                <label>{{ $t('Project') }} </label>
                <input type="text" disabled class="form-control-plaintext" :value="workflowData.projectName" />
            </div>
            <div class="form-group">
                <label>{{boqId ? $t('Bill Of Quantity') : $t('Quotation')}} </label>
                <input type="text" disabled class="form-control-plaintext" :value="workflowData.itemName" />
            </div>
            <div class="form-group">
                <label class="required-style">{{$t('Approval Module.Date End')}} </label>
                <DatePicker v-model="dateEnd" elemId="boqreview-date-dateEnd" />
                <small class="text-primary"><i class="fa-solid fa-circle-info"></i> {{$t('Approval Module.This date is only for a soft deadline this will not affect the approval process')}}.</small>
            </div>
            <div class="form-group">
                <label class="required-style" >{{$t('Approval Module.Message')}} </label>
                <RichTextEditor v-model:modelValue="emailMessage" :placeHolder="'Email Message'" :height="'200'" />
            </div>

            <div class="pt-5">
                <div v-if="WillAllowAddExtenalApproves">
                    <div class="form-group" >
                        <div v-if="!isExternal" class="mb-3">
                            <div class="input-group " style="" >
                                <span class="input-group-text"> {{$t('Approval Module.Project Team Search')}}</span>
                                <Select2 
                                style="width:50%"
                                indexValue="_id"
                                labelName="fullname"
                                :placeHolder="$t('Search Here')"
                                v-model:optionValue="newTypeName"
                                :onOptionClick="AddApprover"
                                :filterQuery="SearchMMoserUserFromProjectOrOrganization"
                                ref="select2Approver"
                                >
                                </Select2>
                            </div>
                            <div>
                                <input type="checkbox" id="setup-checkbox-userwide" v-model="setupUserWide" /> <label for="setup-checkbox-userwide">{{$t('Approval Module.Search Organization Wide')}}</label>
                            </div>
                        </div>
                        <div class="input-group mb-3" style="" v-else>
                            <div class="input-group ">
                                <span class="input-group-text"> External Users Search</span>
                                <Select2 
                                style="width:50%"
                                    indexValue="_id"
                                    labelName="fullname"
                                    placeHolder="Type name here"
                                    v-model:optionValue="newTypeName"
                                    :onOptionClick="AddApproverExternal"
                                    :filterQuery="SearchExternalUserFromProjectOrOrganization"
                                    ref="select2Approver"
                                >
                                </Select2>
                            </div>
                            <div>
                                <input type="checkbox" id="setup-checkbox-userwide" v-model="setupUserWide" /> <label for="setup-checkbox-userwide">Search From Master List</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>{{$t('Approval Module.Approver/Reviewer') }}</th>
                                <th> {{$t('Role') }}</th>
                                <th> {{$t('Actions') }}</th>
                                <th v-if="!isExternal || boqId">{{$t('Status')}}</th>
                            </tr>
                        </thead>
                        <tbody v-if="approversList.length==0">
                            <tr>
                                <td colspan="4" class="text-center">{{$t('No data Found')}}</td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr v-for="(item,key) in approversList" :key="key">
                                <td>
                                    <b class="text-capitalize">{{item.fullname}}</b>
                                </td>
                                <td>{{item.roleId.roleName}}</td>
                                <td>
                                    <select class="form-select" v-model="item.actionType" v-if="!isExternal">
                                        <option value="1">{{ $t('Approval Module.Review') }}</option>
                                        <option value="2">{{ $t('Approval Module.Approve') }}</option>
                                    </select>
                                    <div v-else>
                                        {{ $t('Approval Module.Approve') }}
                                    </div>
                                </td>
                                <td v-if="!isExternal || boqId">
                                    <button class="btn btn-outline-danger" @click="RemoveApprover(item._id)"><i class="fa-solid fa-xmark"></i> {{ $t('Remove') }}</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <div class="d-flex justify-content-between">
                <button class="btn btn-outline-danger" @click="BackToItem"><i class="fa-solid fa-xmark"></i> {{$t('Cancel')}}</button>
                <button class="btn btn-primary" @click="SendForApproval" :disabled="isSending"><i class="fa-solid fa-envelope"></i> {{sendButtonName}}</button>
            </div>
        </div>
    </div>
</template>
<script>
import {ForWorkFlowSetup, ForApprovalQuotation, ForWorkFlowSetupExternal, ForApprovalBoq} from '@/actions/boqActions.js'
import RichTextEditor from '@/components/layout/RichTextEditor.vue'
import {SearchMMoserUserFromProjectOrOrganization, UserSendEmailBulk, SearchExternalUserFromProjectOrOrganization} from '@/actions/projectActions.js'
import {CheckSessionMsal} from '@/actions/userActions.js'
import Select2  from '@/components/layout/Select2.vue'
import  {mapActions, mapGetters} from 'vuex'
import {LinkList, ReformatDate,TextCapitalize} from '@/helpers/utilities.js'
import 'dotenv/config'
import DatePicker from '@/components/layout/DatePicker.vue'
import {LocaleNameVariable} from '@/translations/i18n.js'
export default {
    name : 'BoqReviewerSetup',
    data(){

        const d = new Date()
        d.setDate(d.getDate() + 7)
        const self = this

        return {
            quotationId : this.$route.query.qtnId,
            isExternal : this.$route.query.isExternal,
            boqId : this.$route.query.boqId,
            workflowData : {
                projectName : '-',
                itemName : '-',
                workflow : []
            },
            approversList :[],
            emailMessage : '',
            newTypeName : '',
            isSending :false,
            sendButtonName : self.$i18n.t('Send For Approval'),
            setupUserWide : false,
            isSession : false,
            dateEnd : ReformatDate(d,'YYYY-MM-DD'),
            presetDate : ReformatDate(d),
            dotnetUrl : process.env.VUE_APP_DOTNET_URL,
            
        }
    },
    computed: {
        ...mapGetters(['projectInfo']),
        WillAllowAddExtenalApproves(){
            const self = this
            if(!self.isExternal){
                return true
            }

            return self.boqId
        }
    },
    mounted : async function (){
        const self = this
        let type = self.quotationId ? 3 : 1

        const checkEmailSession = await CheckSessionMsal(type)
        self.isSession = checkEmailSession
        if(!checkEmailSession){
            if(self.quotationId){
                window.location.href = process.env.VUE_APP_REST_MAIN_API + '/api/auth/token/email?type=' + type+'&returnUrl='+encodeURIComponent(window.location.href)
            }else{
                window.location.href = process.env.VUE_APP_REST_MAIN_API + '/api/auth/token/email?type=' + type+'&returnUrl='+encodeURIComponent(window.location.href)
            }
        }

        self.workflowData = await ForWorkFlowSetup({qtnId : self.quotationId, boqId : self.boqId, isExternal : self.isExternal })

        if(self.workflowData.workflow.length!=0){
            self.workflowData.workflow.forEach(value => {
                self.approversList.push({
                    _id : value._id,
                    fullname : value.fullname,
                    roleId :{
                        roleName : value.roleId.roleName
                    }, 
                    actionType : 1,
                })
            })
        }
        await self.GetProjectInfo(self.workflowData.projectId)

        let nameOfItem = ''
        if(self.quotationId){
            nameOfItem = `${self.$i18n.t('Quotation')} <b>${TextCapitalize(self.workflowData.itemName)}</b> `
            self.ChangeActiveLink(LinkList.QUOTATIONS)
        }else if(self.boqId){
            nameOfItem = `${self.$i18n.t('Bill Of Quantity')} <b>${TextCapitalize(self.workflowData.itemName)}</b>`
            self.ChangeActiveLink(LinkList.BILL_OF_QUANTITIES)

        }
        const getLanguage = window.localStorage.getItem(LocaleNameVariable) || 'en'

        
        if(getLanguage=='en')
        {
            self.emailMessage = `
            We hope this message finds you well. ${nameOfItem} for the project <b>${this.workflowData.projectName}</b> is now ready for your review and approval. Your approval is required by <b>${ReformatDate(self.dateEnd)}</b>. 
            <br/><br/>
            Please note that timely approval is vital to maintaining our project's momentum and avoiding potential delays. We appreciate your cooperation and await your positive response by <b>${ReformatDate(self.dateEnd)}</b>.
            <br/><br/>
            Please let us know if you have any questions or concerns. We would be happy to provide any additional information you may need. Once we receive your approval, we will proceed with the project as outlined. Thank you for your time and consideration.
            `
        }
        else
        {
            self.emailMessage = `
            希望此消息能找到您。${nameOfItem} 项目 <b>${this.workflowData.projectName}</b> 现已准备好供您审核和批准。您的批准需要在 <b>${ReformatDate(self.dateEnd)}</b> 之前完成。
            <br/><br/>
            请注意，及时批准对维持我们项目的进度和避免潜在的延误至关重要。我们感谢您的合作，并期待您在 <b>${ReformatDate(self.dateEnd)}</b> 之前给予积极回应。
            <br/><br/>
            如果您有任何问题或疑虑，请告诉我们。我们很乐意提供您可能需要的任何额外信息。一旦收到您的批准，我们将按照计划继续项目。感谢您的时间和考虑。
            `
        }
    },
    components : {RichTextEditor, Select2, DatePicker},
    watch :{
        dateEnd(val){
            const self = this
            self.emailMessage = self.emailMessage.replaceAll(self.presetDate, ReformatDate(val))
            self.presetDate = ReformatDate(val)
        },  
    },
    methods : {
        
        ...mapActions(['GetProjectInfo','ChangeActiveLink']),
        SearchExternalUserFromProjectOrOrganization (value){
            return SearchExternalUserFromProjectOrOrganization(value,this.workflowData.projectId, this.setupUserWide ? 1 : 0)
        },
        SearchMMoserUserFromProjectOrOrganization(search){
            return SearchMMoserUserFromProjectOrOrganization(search, this.workflowData.projectId, this.setupUserWide ? 1 : 0)
        },
        SendForApproval(){
            const self = this
            if(self.isSending){
                return
            }
            const swalTitleSendForApproval = self.$i18n.t('Send For Approval')
            const swalTitleSendEmail = self.$i18n.t('Approval Module.Sending Email')

            if(self.approversList.length==0){
                self.$swal(swalTitleSendForApproval,self.$i18n.t('Approval Module.Please put atleast one Approver/Reviewer'),'warning')
                return
            }

            if(!self.dateEnd){
                
                return
            }
            let appendDateMessage = self.emailMessage

            self.isSending = true
            if(self.isExternal){


                ForWorkFlowSetupExternal({quotationId : self.quotationId,boqId : self.boqId, approverList : self.approversList,dateEnd : self.dateEnd }).then((res)=>{
                    if(!res.processId){
                        self.sendButtonName = swalTitleSendForApproval
                        self.$swal(swalTitleSendForApproval,res,'warning')
                    }else{
                        self.sendButtonName = swalTitleSendEmail + '...'
                        
                        let urlLink = ''
                        if(self.quotationId){
                            urlLink = `${self.dotnetUrl}/client/project/${self.projectInfo.projectOldId}/quotation/${res.processId}/approval`
                        }else if(self.boqId){
                            urlLink = `${self.dotnetUrl}/client/project/${self.projectInfo.projectOldId}/boq/${res.processId}/approval`
                        }
                        UserSendEmailBulk({
                            subject : `Project : ${self.workflowData.projectName} -  ${self.workflowData.itemName}`,
                            message : appendDateMessage,
                            link : urlLink,
                            sendTos : self.approversList
                        }).then((resEmail)=>{
                            if(resEmail!='OK'){
                                self.isSending = false
                                self.sendButtonName = swalTitleSendForApproval
                                self.$swal(swalTitleSendForApproval,'Email Sending Failed','warning')
                            }else{
                                if(self.quotationId){
                                    self.$router.push(`/boq/${self.quotationId}/quotation/item`)
                                }
                                else if(self.boqId){
                                    self.$router.push({name : 'BoqBuilder', params : { id : self.boqId} })
                                }
                            }
                        })
                    }

                })

            }else{
                self.sendButtonName = swalTitleSendEmail + '...'
                if(self.quotationId){
                    ForApprovalQuotation({
                        quotationId : self.quotationId,
                        approverList : self.approversList,
                        isExternal : self.isExternal,
                        dateEnd : self.dateEnd
                    }).then((res)=>{
                        if(!res.processId){
                            self.isSending = false
                            self.sendButtonName = swalTitleSendForApproval
                            self.$swal(swalTitleSendForApproval,res,'warning')
                        }else{
                            self.sendButtonName = swalTitleSendEmail + '...'
                            UserSendEmailBulk({
                                subject : `Project : ${self.workflowData.projectName} -  ${self.workflowData.itemName}`,
                                message : appendDateMessage,
                                link : `${window.location.origin}/boq/${res.processId}/quotation/approval`,
                                sendTos : self.approversList
                            }).then((resEmail)=>{
                                if(resEmail!='OK'){
                                    self.isSending = false
                                    self.sendButtonName = swalTitleSendForApproval
                                    self.$swal(swalTitleSendForApproval,'Email Sending Failed','warning')
                                }else{
                                    self.$router.push(`/boq/${self.quotationId}/quotation/item`)
                                }
                            })
                        }

                    })
                }else if(self.boqId){
                    ForApprovalBoq({
                        boqId : self.boqId,
                        approverList : self.approversList,
                        dateEnd : self.dateEnd
                    }).then((res)=>{
                        if(!res.processId){
                            self.isSending = false
                            self.sendButtonName = swalTitleSendForApproval
                            self.$swal(swalTitleSendForApproval,res,'warning')
                        }else{
                            self.sendButtonName = swalTitleSendEmail + '...'
                            UserSendEmailBulk({
                                subject : `Project : ${self.workflowData.projectName} -  ${self.workflowData.itemName}`,
                                message : appendDateMessage,
                                link : `${window.location.origin}/boq/Review/${res.processId}`,
                                sendTos : self.approversList
                            }).then((resEmail)=>{
                                if(resEmail!='OK'){
                                    self.isSending = false
                                    self.sendButtonName = swalTitleSendForApproval
                                    self.$swal(swalTitleSendForApproval,self.$i18n.t('Approval Module.Email Sending Failed'),'warning')
                                }else{
                                    self.$router.push({name : 'BoqBuilder', params : { id : self.boqId} })
                                }
                            })
                        }

                    })
                }
            }
            
        },
        AddApprover(value){
            const self = this
            const getIndex = self.approversList.findIndex(u=>u._id==value._id)
            if(getIndex==-1){
                self.approversList.push({
                    _id : value._id,
                    fullname : value.fullname,
                    roleId :{
                        roleName : value.roleId.roleName
                    }, 
                    actionType : 1,
                })
            }
            setTimeout(function(){
                self.newTypeName = ''
            },300)
        },
        AddApproverExternal(value){
            const self = this
            const getIndex = self.approversList.findIndex(u=>u._id==value._id)
            if(getIndex==-1){
                self.approversList.push({
                    _id : value._id,
                    fullname : value.fullname,
                    roleId :{
                        roleName : value.roleId.roleName
                    }, 
                    actionType : 2,
                })
            }
            setTimeout(function(){
                self.newTypeName = ''
            },300)
        },
        RemoveApprover(id){
            const self = this
            const getIndex = self.approversList.findIndex(u=>u._id==id)
            if(getIndex>-1){
                self.approversList.splice(getIndex,1)
            }
        },
        BackToItem(){
            if(this.quotationId){
                this.$router.push(`/boq/${this.quotationId}/quotation/item`)
            }else{
                this.$router.push({name : 'BoqBuilder', params : { id : this.boqId} })
            }
        }
    }
}
</script>