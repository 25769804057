<template>
    <div>
        <BreadcrumbsProject
        :breadcrumbs="[
            {
                link : projectUrl +'/',
                title : 'Projects',
                isRouterLink: false
            },
            {
                link : projectUrl +'/Project/' + projectInfo._id,
                title :  (projectInfo.projectNumber || '-') +' ' + (projectInfo.projectName || ''),
                isRouterLink: false
            },
            {
                link : '/boq/' + projectInfo._id + '/rates',
                title : 'Rate List',
                isRouterLink: true
            },
            {
                link : '/boq/' + projectInfo._id + '/rates/items',
                title : rateTemplate.name || '-',
                isRouterLink: true
            }
        ]"
        ></BreadcrumbsProject>
        <StepItem2></StepItem2>
        <div>
            <div class="card">
                <div class="card-header bg-white border-top d-flex justify-content-between">
                    <h6 class="fw-bold">Rate List Items</h6>
                    <div>
                        <button class="btn btn-primary me-1  pe-2 ps-2"
                        v-if="permission.indexOf(ModuleActionList.RATE_CREATE)>-1"
                        data-bs-toggle="modal" data-bs-target="#ModalAddRate" @click="OpenAddRate(item)"
                        title="Add Rate"><i class="fa-solid fa-plus"></i> Add Rate  </button>
                        <button class="btn btn-outline-primary me-1  pe-2 ps-2"
                        v-if="permission.indexOf(ModuleActionList.RATE_CREATE)>-1"
                        data-bs-toggle="modal" data-bs-target="#BoqImportRateTemplate" @click="OpenRateImport"
                        title="Import File"><i class="fa-solid fa-upload"></i>  </button>
                        <button class="btn btn-outline-primary pe-2 ps-2" title="Export to File" 
                        data-bs-toggle="modal" data-bs-target="#BoqExportRateTemplate"
                        @click="()=>{ templateType= 0}"
                        >
                            <i class="fa-solid fa-file-arrow-down"></i> 
                        </button>
                    </div>
                </div>
                <div class="card-body">
                    <div class="input-group mb-2">
                        <input type="text" class="form-control" placeholder="Search Rate here" @keyup="SearchTrigger"/>
                        <span class="input-group-text"><i class="fa-solid fa-search"></i></span>
                    </div>
                    <Pagination
                    :changePage="PageChange" 
                    :paginationDetails="{totalPages : RateList.totalPages, page : RateList.page}" 
                    :havePageCount="true" />
                    <div class="table-responsive">
                        <table class="table table-bordered table-no-header">
                            <thead>
                                <tr>
                                    <th>Cost Code</th>
                                    <th>Main Category</th>
                                    <th>Sub Category</th>
                                    <th>Name</th>
                                    <th>Rate</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="RateList.docs.length==0">
                                    <td class="text-center" colspan="4">No Data Found</td>
                                </tr>
                                <tr v-for="(item,key) in RateList.docs" :key="key">
                                    <td>{{item.costCode}}</td>
                                    <td >{{item.name}}</td>
                                    <td >{{item.parents[0].name}}</td>
                                    <td >{{item.parents[1].name}}</td>
                                    <td class="text-end">{{ReformatNumber(item.rate)}}</td>
                                    <td>
                                        <button class="btn btn-outline-primary btn-action me-1"
                                        v-if="permission.indexOf(ModuleActionList.RATE_EDIT)>-1"
                                        data-bs-toggle="modal" data-bs-target="#ModalEditRate" @click="OpenEditRate(item)">
                                        <i class="fa-solid fa-edit"></i>
                                        </button>
                                        <button 
                                        v-if="permission.indexOf(ModuleActionList.RATE_REMOVE)>-1"
                                        class="btn btn-outline-danger btn-action" @click="(e)=>{ DeleteItem(e,item) }">
                                            <i class="fa-solid fa-trash-can"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Modal idModal="ModalAddRate" headerTitle="Add Rate"  >
        <div>
            <div class="form-group">
                <label class="required-style" >Cost Code </label>
                <Select2
                :filterQuery="GetCostCodes"
                indexValue="_id"
                labelName="costName"
                placeHolder="Search Cost Code Here"
                v-model:optionValue="newRate.costCodeId"
                ref="selectCostCode"
                />
                <small class="text-warning" v-if="isNewRateClicked && !newRate.costCodeId">Required</small>
            </div>
            <div class="form-group">
                <label class="required-style">Rate</label>
                <input type="number" v-model="newRate.rate" placeholder="Rate" class="form-control"/> 
                <small class="text-warning" v-if="isNewRateClicked && !newRate.rate">Required</small>
            </div>
        </div>
        
        <template v-slot:actions>
            <button type="button" ref="closeModalAddRate" class="btn btn-outline-danger" data-bs-dismiss="modal" ><i class="fa-solid fa-xmark"></i> Cancel</button>
            <button type="button" class="btn btn-primary" @click="AddNewRate" ><i class="fas fa-save"></i> Add Rate</button>
        </template>
    </Modal>
    <Modal idModal="ModalEditRate" headerTitle="Edit Rate"  >
        <div>
            <div class="form-group">
                <label >Cost Code </label>
                <input type="text" :value="updateRate.name" class="form-control-plaintext text-capitalize" /> 
            </div>
            <div class="form-group">
                <label class="required-style">Rate</label>
                <input type="number" v-model="updateRate.rate" placeholder="Rate" class="form-control"/> 
                <small class="text-warning" v-if="isUpdateRateClicked && !updateRate.rate">Required</small>
            </div>
        </div>
        
        <template v-slot:actions>
            <button type="button" ref="closeModalEditRate" class="btn btn-outline-danger" data-bs-dismiss="modal" ><i class="fa-solid fa-xmark"></i> Cancel</button>
            <button type="button" class="btn btn-primary" @click="EditNewRate" ><i class="fas fa-save"></i> Save Changes</button>
        </template>
    </Modal>
    <Modal idModal="BoqImportRateTemplate" headerTitle="Import Rate Template"  >
        <div>
            <div class="form-group">
                <label class="required-style">File</label>
                <input type="file" class="form-control" ref="uploadFile" />
                <small class="text-warning" v-if="!uploadTemplate.file&&isUploadTemplateClicked">Required</small>
            </div>
            <div class="form-group">
                <label class="required-style">Template </label>
                <select class="form-select" v-model="uploadTemplate.templateType"> 
                    <option value="">Please Select</option>
                    <option v-for="(item,key) in ExportTemplates" :key="key" :value="key">{{item}}</option>
                </select>
                <small class="text-warning" v-if="uploadTemplate.templateType===''&&isUploadTemplateClicked">Required</small>
                <div class="text-end">
                    <a href="#" class="small" role="button" @click="DownloadTemplate">Download selected template</a>
                </div>
            </div>
        </div>
        <template v-slot:actions>
            <button type="button" ref="closeBoqImportRateTemplate" class="btn btn-outline-danger" data-bs-dismiss="modal" ><i class="fa-solid fa-xmark"></i> Cancel</button>
            <button type="button" class="btn btn-primary" @click="ImportTemplate" ><i class="fas fa-save"></i> Import</button>
        </template>
    </Modal>
    <Modal idModal="BoqExportRateTemplate" headerTitle="Export Rate Template"  >
        <div>
            <div class="form-group">
                <label class="required-style">Template </label>
                <select class="form-select" v-model="templateType"> 
                    <option v-for="(item,key) in ExportTemplates" :key="key" :value="key">{{item}}</option>
                </select>
            </div>
        </div>
        <template v-slot:actions>
            <div></div>
            <button type="button" class="btn btn-primary" @click="ExportRateList" >
                <i class="fa-solid fa-file-arrow-down"></i>  Export
            </button>
        </template>
    </Modal>
</template>

<script src="@/assets/boq/BoqRateItems.js" />
