import { GetBoqList, CreateBoq, UpdateBoq, GetBoqItem, GetCurrencyList,DuplicateBoq, GetBoqHistoryList } from '@/actions/boqActions.js'

const state ={
    boqList :{
        docs: [],
        totalPages : 0,
    },
    boqItemData :{
        tabs : []
    },
    boqCurrency : []
}
const getters = {
    boqList  : state => state.boqList,
    boqItemData  : state => state.boqItemData,
    boqCurrency  : state => state.boqCurrency,
}
const actions = {
    async GetBoqItemRun(obj,id){
        const data = await GetBoqItem(id).catch()
        return data
    },
    async GetBoqListRun({commit},{id, page, search}){
        const data = await GetBoqList(id, page, search).catch()
        commit('setBoqList',data)

        return data
    },
    async CreateBoqRun({dispatch},{data, pager}){
        const create = await CreateBoq(data).catch()
        if(create.status==200){
            dispatch('GetBoqListRun',{id : pager.id, page : pager.page, search : pager.search})
        }

        return create
    },
    async DuplicateBoqRun({dispatch},{data, pager}){
        const create = await DuplicateBoq(data).catch()
        if(create=="OK"){
            dispatch('GetBoqListRun',{id : pager.id, page : pager.page, search : pager.search})
        }

        return create
    },
    async UpdateBoqRun({dispatch},{data, pager}){
        const update = await UpdateBoq(data).catch()
        if(update=="OK"){
            dispatch('GetBoqListRun',{id : pager.id, page : pager.page, search : pager.search})
            // const list = this.getters.boqList.docs
            // const index = list.findIndex((u)=>u._id===data.id)
            // const currencyList = this.getters.boqCurrency
            // const currencyIndex= currencyList.findIndex((u)=>u._id===data.currencyId)
            // list[index].name = data.name
            // list[index].reference = data.reference
            // list[index].isPrimary = data.isPrimary=="true" ? true : false
            // list[index].currencyId = {
            //     _id : currencyList[currencyIndex]._id,
            //     name : currencyList[currencyIndex].name ,
            //     sign : currencyList[currencyIndex].sign ,
            //     shortName : currencyList[currencyIndex].shortName ,
            // }
        }
        return update
    },
    async GetCurrencyListRun({commit}){
        const data = await GetCurrencyList().catch()
        commit('setBoqCurrency',data)
    },
    async GetBoqHistoryListRun(obj,data){
        const history = await GetBoqHistoryList(data).catch()
        return history
    },
    DeleteBoqPermanent(obj,id){
        const self = this
        const docs = self.getters.boqList.docs
        const getIndex = docs.findIndex(u=>u._id==id)
        if(getIndex!=-1){
            docs.splice(getIndex,1)
        }

    }
}
const mutations = {
    setBoqList : (state,data) => (state.boqList = data),
    setBoqItemData : (state,data) => (state.boqItemData = data),
    setBoqCurrency : (state,data) => (state.boqCurrency = data),
}

export default {
    state,
    getters,
    actions,
    mutations
}