<template>
    <div>
        <BreadcrumbsProject
        :breadcrumbs="[
            {
                link : '/high-level-estimates',
                title : $t('Bidding'),
                isRouterLink: true
            },
            {
                link : '/high-level-estimates/' + hleProjectId + '/quotation',
                title : (hleProject.projectName || '-' )+ ' - ' + $t('Bidding Module.High Level Estimates'),
                isRouterLink: true
            },
        ]"
        />
        <div class="card">
            <div class="card-header bg-white">
                <div class="d-flex justify-content-between">
                    <div>
                        <h6 class="fw-bold mb-0">{{ $t('Bidding Module.High Level Estimates') }}</h6>
                    </div>
                    <div class="d-flex">
                        <div>
                            <input type="radio" value="1" v-model="viewMode" id="view-mode-1" @input="ChangeView" /> <label for="view-mode-1" role="button" class="fw-bold">{{ $t('Bidding Module.High Level Estimates') }}</label>
                        </div>
                        <div class="ms-3">
                            <input type="radio" value="2" v-model="viewMode" id="view-mode-2" @input="ChangeView" /> <label for="view-mode-2" role="button" class="fw-bold">{{ $t('Bidding Module.Detailed Estimates')}}</label>
                        </div>
                    </div> 
                </div>
            </div>
            <div class="card-body">
                <div class="d-flex justify-content-end">
                    <button class="btn btn-primary"  data-bs-toggle="modal" data-bs-target="#QuotationModalAdd"  @click="OpenCreateQuotation"><i class="fa-solid fa-plus"></i> {{ $t('Add')}} </button>
                </div>
                <div class="row pb-2">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ $t('Search')}}</label>
                            <input type="search" class="form-control" v-model="searchBar" :placeholder="$t('Bidding Module.Search Name of High Level Estimates here')" />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <!-- <div class="form-group">
                            <label>List By</label>
                            <Multiselect 
                            :allow-empty="false"
                            :sort="false" :options="creatorOptions"
                            v-model="selectedCreatedBy" :searchable="true" 
                            placeholder="Type Here to select creator"
                            :clear-on-select="false" :max-height="300" :internalSearch="true"
                            label="value" track-by="id"/>
                        </div> -->
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th> {{ $t('Name')}}</th>
                                <th> {{ $t('Total')}}</th>
                                <th> {{ $t('Created By')}}</th>
                                <th> {{ $t('Created At')}}</th>
                                <th class="text-center"> {{ $t('Status')}}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody v-if="!isLoaded">
                            <tr>
                                <td colspan="6">
                                    <Loader />
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr v-if="quotationList.docs.length==0">
                                <td colspan="6" class="text-center">
                                    {{ $t('No data Found')}}
                                </td>
                            </tr>
                            <tr v-for="(item,key) in quotationList.docs" :key="key" >
                                <td class="text-capitalize">
                                    <router-link :to="{name : 'HleQuotation', params : {id : hleProjectId, qtnId : item._id} }" class="text-capitalize link-item-route"> 
                                        {{item.name}}
                                    </router-link>
                                </td>
                                <td :title="'Quotation Currency is '+ item.currencyId.name">{{item.currencyId.sign}}{{ReformatNumber(item.amountTotal)}}</td>
                                <td class="text-capitalize">{{item.createdBy.fullname}}</td>
                                <td>{{ReformatDate(item.createdAt)}}</td>
                                <td class="text-center"><span :class="'badge ' + ApprovalStageBoqObj[item.status - 1].color">{{ApprovalStageBoqObj[item.status - 1].name}}</span></td>
                                <td>
                                    <router-link :to="{name : 'HleQuotation', params : {id : hleProjectId, qtnId : item._id}  }" class="btn btn-outline-primary btn-action me-1"> 
                                        <i class="fa-solid fa-magnifying-glass"></i>
                                    </router-link>
                                    <router-link v-if="item.status!=ApprovalStageBoq.DRAFT" :to="{name : 'HleQuotationHistory', params : {id : hleProjectId, qtnId : item._id } }" class="btn btn-outline-primary btn-action me-1"> 
                                        <i class="fa-solid fa-clock-rotate-left"></i>
                                    </router-link>
                                    <button v-if="item.status==ApprovalStageBoq.DRAFT" class="btn btn-outline-danger btn-action me-1" @click="DeleteQuotation(item._id,item.name)"> <i class="fa-solid fa-trash-can"></i></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>   
                </div>
                <Pagination
                    :changePage="PageChange" 
                    :paginationDetails="{totalPages : quotationList.totalPages, page : quotationList.page}" 
                    :havePageCount="true"
                />
            </div>
        </div>
        <Modal idModal="QuotationModalAdd" :headerTitle="$t('Bidding Module.Create High Level Estimate')" modalSize="modal-md" >
            <div class="form-group">
                <label class="required-style"> {{ $t('Name') }} </label>
                <input class="form-control" :placeholder="$t('Bidding Module.Name of the High Level Estimate')" v-model="newQuotation.name" />
                <small class="text-warning" v-if="isSaveQuotationClicked && !newQuotation.name">{{ $t('Required') }}</small>
            </div>
            <!-- <div class="form-group">
                <label class="required-style"> Currency  </label>
                <select class="form-select" v-model="newQuotation.currencyId" >
                    <option value="">Select a Currency</option>
                    <option v-for="(item, key) in boqCurrency" :key="key" :value="item._id">{{item.sign}} {{item.name}} </option>
                </select>
                <small class="text-warning" v-if="isSaveQuotationClicked && !newQuotation.currencyId">Required</small>
            </div> -->
            <template v-slot:actions>
                <button type="button" ref="closeQuotationModalAdd" class="btn btn-outline-danger" data-bs-dismiss="modal">X {{$t('Cancel')}}</button>
                <button type="button" class="btn btn-primary" :disabled="isOnProcess" @click="CreateQuotation($event.target)" ><i class="fas fa-save"></i> {{ $t('Save Changes') }}</button>
            </template>
        </Modal>
    </div>
</template>


<script>
import 'dotenv/config'
import BreadcrumbsProject  from '@/components/projectInformation/BreadcrumbsProject.vue'
import Pagination from '@/components/layout/Pagination.vue'
import Modal from '@/components/layout/Modal.vue'
import Loader from '@/components/layout/Loader.vue'
import  {mapActions,mapGetters} from 'vuex'
import {LinkList, ApprovalStageBoqObj,ReformatDate, ReformatNumber, ApprovalStageBoq,LocalStorageNameOfListByHLE} from '@/helpers/utilities.js'
import {GetHighLevelQuotationsList,AddHighLevelQuotation,DeleteHighLevelQuotation, GetHighLevelProject} from '@/actions/boqActions.js'
export default {
    name : 'HleList',
    components : {
        BreadcrumbsProject, Pagination, Loader, Modal
    },
    computed : {
        ...mapGetters(['boqCurrency'])
    },
    mounted : async function(){
        const self = this
        self.ChangeActiveLink(LinkList.HIGH_LEVEL_ESTIMATES)
        const listBy = window.localStorage.getItem(LocalStorageNameOfListByHLE) || 1
        self.quotationListParams.listBy = listBy
        self.selectedCreatedBy = self.creatorOptions.find(u=>u.id==listBy) 
        self.quotationList = await GetHighLevelQuotationsList(self.quotationListParams) 
        await self.GetCurrencyListRun()
        self.hleProject = await GetHighLevelProject(self.hleProjectId)
        if(!self.hleProject){
            self.$router.push('/unauthorized')
            return
        }
        self.isLoaded = true
    },
    methods : {
        ...mapActions(['ChangeActiveLink','GetCurrencyListRun']),
        async PageChange(page){
            const self = this
            self.quotationListParams.page = page
            self.quotationList = await GetHighLevelQuotationsList(self.quotationListParams) 
        },
        OpenCreateQuotation(){
            this.newQuotation = {
                name : ''
            }
        },
        async CreateQuotation(){
            const self = this

            if(self.isOnProcess){
                return
            }
            self.isSaveQuotationClicked = true
            const data = self.newQuotation
            data.hleProjectId = self.hleProjectId
            if(!data.name ){
                return
            }
            self.isSaveQuotationClicked = true
            self.isOnProcess = true

            const created = await AddHighLevelQuotation(data)
            self.isOnProcess = false
            if(created.quotationId)
            {
                self.$router.push({name : 'HleQuotation', params : { id : self.hleProjectId,  qtnId :created.quotationId }})
                self.$refs.closeQuotationModalAdd.click()
            }
            else
            {
                self.$swal(self.$i18n.t('Bidding Module.Create High Level Estimate'),created,'warning')
            }
        },
        DeleteQuotation (id,name){
            const self = this
            self.$swal({
                title: self.$i18n.t('Bidding Module.Delete High Level Estimates'),
                text : `"${name}" ${self.$i18n.t('Bidding Module.will be Deleted Permanently, Proceed?')}`,
                showCancelButton: true,
                icon: 'warning',
                confirmButtonText: self.$i18n.t('Delete'),
                cancelButtonText : self.$i18n.t('Cancel'),
                showLoaderOnConfirm : true,
                allowOutsideClick: () => !self.$swal.isLoading(),
                preConfirm : ()=>{
                    return DeleteHighLevelQuotation(id)
                }
            }).then(async (res)=>{
                if(res.isConfirmed){
                    if(res.value=='OK'){
                        self.quotationList = await GetHighLevelQuotationsList(self.quotationListParams) 
                        self.$swal(self.$i18n.t('Bidding Module.Delete High Level Estimates'),'','success')
                    }else{
                        self.$swal(self.$i18n.t('Bidding Module.Delete High Level Estimates'),res.value,'warning')
                    }
                
                }
            })
        },
        timerSetterSearch(callback){
            const self = this
            
            if(self.timerSearch){
                clearTimeout(self.timerSearch)
                self.timerSearch = null
            }

            self.timerSearch = setTimeout(callback,700)
        },
    },
    watch : {
        searchBar(val) {
            const self = this
            self.quotationListParams.page = 1
            self.quotationListParams.search = val
            self.timerSetterSearch(async function(){
                self.quotationList = await GetHighLevelQuotationsList(self.quotationListParams) 
            })
        },
        selectedCreatedBy(val) {
            const self = this
            if(!val){ 
                return 
            }
            self.quotationListParams.page = 1
            if(val)
                self.quotationListParams.listBy = val.id
            else 
                self.quotationListParams.listBy = undefined

            window.localStorage.setItem(LocalStorageNameOfListByHLE,val.id || 1)
            self.timerSetterSearch(async function(){
                self.quotationList = await GetHighLevelQuotationsList(self.quotationListParams) 
            })
        },
        viewMode(val){
            let viewMode = val
            if(viewMode==1)
                this.$router.push({name : 'HleList', params : {id : this.hleProjectId}})
            else
                this.$router.push({name : 'HleBoqs', params : {id : this.hleProjectId}})
        }
        
    },
    data(){
        return {
            viewMode : 1,
            hleProject : {},
            hleProjectId : this.$route.params.id,
            boqUrl : process.env.VUE_APP_BOQ_URL,
            quotationList : {
                docs : [],
            },
            quotationListParams : {
                listBy : 1,
                page : 1,
                search : '',
                hleProjectId : this.$route.params.id
            },
            searchBar : '',
            selectedCreatedBy : { id : 1, value : 'My High Level Estimates'},
            creatorOptions  : [ 
                { id : 1, value : 'My High Level Estimates'},
                { id : 2, value : 'Bidding High Level Estimates'},
            ],
            timerSearch :null,
            ApprovalStageBoqObj,
            ApprovalStageBoq,
            ReformatDate,
            ReformatNumber,
            isLoaded : false,
            isOnProcess : false,
            newQuotation : {},
            isSaveQuotationClicked : false,
            UpdateQuotation : {},
            UpdateQuotationClicked : false,
        }
    },
}
</script>