<template>
    <div class="card mt-1">
        <div class="card-header bg-white">
            <h6>{{$t('Approval Module.Comments')}}</h6>
        </div>
        <div class="card-body">
            <!-- <div v-if="processItem.status==ProcessItemStatusList.PENDING">
                <textarea v-model="comment" class="form-control" :class="{'border-warning' : isAddClicked && !comment}" placeholder="Add a comment here.."></textarea>
                <div class="d-flex justify-content-end pt-2">
                    <button class="btn btn-info" @click="AddComment"><i class="fa-solid fa-message"></i> Add Comment</button>
                </div>
            </div>  -->
            <div class="pt-3">
                <Pagination
                :changePage="PageChangeComment" 
                :paginationDetails="{totalPages : commentList.totalPages, page : commentList.page}" 
                :havePageCount="true"
                ></Pagination>
                <div v-if="commentList.docs.length==0">
                    <div class="alert alert-outline-primary d-flex align-items-center " role="alert" >
                        <i class="fa-regular fa-comment fa-2x me-2"></i>  
                        <div>
                            {{$t('No data Found')}}
                            
                        </div>
                    </div>
                </div> 
                
                <div class="d-flex" v-for="(item, key) in commentList.docs" :key="key">
                    <div class=" pe-3 pt-1">
                        <img :src=" item.createdBy.photoUrl!='' && (typeof item.createdBy.photoUrl !=='undefined') ? item.createdBy.photoUrl : '/images/placeholder-user.png'"
                        @error="(e)=>{e.target.src = '/images/placeholder-user.png';}"
                        class="img-circle img-xs" >
                    </div>
                    <div class="">
                        <span class="fw-bold text-capitalize">{{item.createdBy.fullname}}</span> <br />
                        <span class="text-muted">{{formatDate(item.createdAt, 'DD-MMM-YYYY h:mm a')}}</span> <br />
                        <p style="white-space: pre">{{item.comment}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {ProcessItemStatusList, ReformatDate} from '@/helpers/utilities.js'
import {AddCommentToList} from '@/actions/projectActions.js'
import Pagination from '@/components/layout/Pagination.vue'
export default {
    name : 'ProcessCommentList',
    props :['commentList','PageChangeComment','processItem'],
    components : {
        Pagination
    },
    methods : {
        AddComment(e){
            const self = this
            const btn = e.target
            self.isAddClicked = true
            if(!self.comment){
                return
            }
            btn.disabled = true
            self.isAddClicked = false

            AddCommentToList({processId : self.processItem._id, comment : self.comment }).then((res)=>{
                btn.disabled = false
                self.comment = ''
                if(res=="OK"){
                    self.PageChangeComment(1)
                }else{
                    self.$swal('Add Comment',res,'warning')
                }
            })
        },
        formatDate : ReformatDate
    },
    data(){
        return {
            ProcessItemStatusList : ProcessItemStatusList,
            comment : '',
            isAddClicked : false,
        }
    }
}
</script>