import { createRouter, createWebHistory } from 'vue-router'
import BoqItems from '../views/BoqItems.vue'
import BoqList from '../views/BoqList.vue'
import NotFound from '../views/NotFound.vue'
import Home from '../views/Home.vue'
import BoqHistory from '../views/BoqHistory.vue'
import BoqHistoryBreakdown from '../views/BoqHistoryBreakdown.vue'
import BoqMasterList from '../views/BoqMasterList.vue'
import BoqReview from '../views/BoqReview.vue'
import BoqReviewBreakdown from '../views/BoqReviewBreakdown.vue'
import ConsolidatedList from '../views/ConsolidatedList.vue'
import BoqQuotation from '../views/BoqQuotation.vue'
import BoqQuotationAttachedItems from '../views/BoqQuotationAttachedItems.vue'
import TradePackageList from '../views/TradePackageList.vue'
import BoqConsolidated from '../views/BoqConsolidated.vue'
import Unauthorized from '../views/Unauthorized.vue'
import BoqRates from '../views/BoqRates.vue'
import BoqRatesItems from '../views/BoqRatesItems.vue'
import BoqListQuotation from '../views/BoqListQuotation.vue'
import BoqQuotationReview from '../views/BoqQuotationReview.vue'
import BoqQuotationHistory from '../views/BoqQuotationHistory.vue'
import BoqReviewerSetup from '../views/BoqReviewerSetup.vue'
import BoqItemsPreview from '../views/BoqItemsPreview.vue'
import BoqItemsPrint from '../views/BoqItemsPrint.vue'
import AuthPage from '../views/AuthPage.vue'
import BoqItemsSummary from '../views/BoqItemsSummary.vue'
import HleList from '../views/HleList.vue'
import HleProjectList from '../views/HleProjectList.vue'
import HleQuotation from '../views/HleQuotation.vue'
import HleQuotationApproval from '../views/HleQuotationApproval.vue'
import HleBoqs from '../views/HleBoqs.vue'
import HleBoQBuilder from '../views/HleBoqBuilder.vue'
import HleBoqBuilderBreakdown from '../views/HleBoqBuilderBreakdown.vue'
import HleBoQBuilderUnassigneds from '../views/HleBoqBuilderUnassigneds.vue'
import HleBoqItems from '../views/HleBoqItems.vue'
import HleBoqItemsSummary from '../views/HleBoqItemsSummary.vue'
import HleBoqItemsPrint from '../views/HleBoqItemsPrint.vue'
import HleApprovalSetup from '../views/HleApprovalSetup.vue'
import HleBoqItemsApproval from '../views/HleBoqItemsApproval.vue'
import HleBoqItemsApprovalBreakdown from '../views/HleBoqItemsApprovalBreakdown.vue'
import HleBoqItemsHistory from '../views/HleBoqItemsHistory.vue'
import HleBoqItemsHistoryBreakdown from '../views/HleBoqItemsHistoryBreakdown.vue'
import HleQuotationHistory from '../views/HleQuotationHistory.vue'
import HleQuotationDetailedSummary from '../views/HleQuotationDetailedSummary.vue'
import HleQuotationApprovalDetailedSummary from '../views/HleQuotationApprovalDetailedSummary.vue'
import BoqBuilder from '../views/BoqBuilder.vue'
import BoqBuilderBreakdown from '../views/BoqBuilderBreakdown.vue'
import BoqBuilderUnassigneds from '../views/BoqBuilderUnassigneds.vue'

import Auth from '../middleware/auth.js'

const routes = [
  {
    path: '/boq/:id',
    name: 'Boq',
    component: BoqList,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/boq/:id/master',
    name: 'boqMasterList',
    component: BoqMasterList,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/boq/:id/quotation/item',
    name: 'BoqQuotation',
    component: BoqQuotation,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/boq/:id/quotation/item/attachments',
    name: 'BoqQuotationAttachedItems',
    component: BoqQuotationAttachedItems,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/boq/:id/quotation/list',
    name: 'BoqListQuotation',
    component: BoqListQuotation,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/boq/:id/quotation/approval',
    name: 'BoqQuotationReview',
    component: BoqQuotationReview,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/boq/:id/quotation/history',
    name: 'BoqQuotationHistory',
    component: BoqQuotationHistory,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/boq/:id/consolidated',
    name: 'BoqConsolidated',
    component: BoqConsolidated,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/boq/:id/packages',
    name: 'PackageList',
    component: TradePackageList,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/boq/:id/packages/consolidated',
    name: 'ConsolidatedList',
    component: ConsolidatedList,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/boq/items/:id',
    name: 'BoqItems',
    component: BoqItems,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/boq/items/:id/summary',
    name: 'BoqItemsSummary',
    component: BoqItemsSummary,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/boq/items/:id/preview',
    name: 'BoqItemsPreview',
    component: BoqItemsPreview,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/boq/items/:id/print',
    name: 'BoqItemsPrint',
    component: BoqItemsPrint,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/boq/history/:id',
    name: 'History',
    component: BoqHistory,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/boq/history/:id/breakdown/:parent',
    name: 'BoqHistoryBreakdown',
    component: BoqHistoryBreakdown,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/boq/Review/:id',
    name: 'Review',
    component: BoqReview,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/boq/Review/:id/breakdown/:parent',
    name: 'BoqReviewBreakdown',
    component: BoqReviewBreakdown,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/boq/:id/rates',
    name: 'BoqRates',
    component: BoqRates,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/boq/:id/rates/items',
    name: 'BoqRatesItems',
    component: BoqRatesItems,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/boq/setup/review',
    name: 'BoqReviewerSetup',
    component: BoqReviewerSetup,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/high-level-estimates',
    name: 'HleProjectList',
    component: HleProjectList,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/high-level-estimates/:id/quotation',
    name: 'HleList',
    component: HleList,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/high-level-estimates/:id/quotation/:qtnId',
    name: 'HleQuotation',
    component: HleQuotation,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/high-level-estimates/:id/quotation/:qtnId/detailed-summary',
    name: 'HleQuotationDetailedSummary',
    component: HleQuotationDetailedSummary,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/high-level-estimates/quotation/:id/approval',
    name: 'HleQuotationApproval',
    component: HleQuotationApproval,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/high-level-estimates/quotation/:id/approval/detailed-summary',
    name: 'HleQuotationApprovalDetailedSummary',
    component: HleQuotationApprovalDetailedSummary,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/high-level-estimates/:id/quotation/:qtnId/history',
    name: 'HleQuotationHistory',
    component: HleQuotationHistory,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/high-level-estimates/:id/bill-of-quantities/',
    name: 'HleBoqs',
    component: HleBoqs,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/high-level-estimates/:id/bill-of-quantities/:boqId',
    name: 'HleBoqItems',
    component: HleBoqItems,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/high-level-estimates/:id/bill-of-quantities-builder/:boqId',
    name: 'HleBoQBuilder',
    component: HleBoQBuilder,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/high-level-estimates/:id/bill-of-quantities-builder/:boqId/breakdown/:parent',
    name: 'HleBoqBuilderBreakdown',
    component: HleBoqBuilderBreakdown,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/high-level-estimates/:id/bill-of-quantities-builder/:boqId/unassigneds',
    name: 'HleBoQBuilderUnassigneds',
    component: HleBoQBuilderUnassigneds,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/high-level-estimates/:id/bill-of-quantities/:boqId/print',
    name: 'HleBoqItemsPrint',
    component: HleBoqItemsPrint,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/high-level-estimates/:id/bill-of-quantities/:boqId/summary',
    name: 'HleBoqItemsSummary',
    component: HleBoqItemsSummary,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/high-level-estimates/approval/setup',
    name: 'HleApprovalSetup',
    component: HleApprovalSetup,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/high-level-estimates/approval/bill-of-quantities/:id',
    name: 'HleBoqItemsApproval',
    component: HleBoqItemsApproval,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/high-level-estimates/approval/bill-of-quantities/:id/breakdown/:parent',
    name: 'HleBoqItemsApprovalBreakdown',
    component: HleBoqItemsApprovalBreakdown,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/high-level-estimates/:id/bill-of-quantities/:boqId/history',
    name: 'HleBoqItemsHistory',
    component: HleBoqItemsHistory,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/high-level-estimates/:id/bill-of-quantities/:boqId/history/breakdown/:parent',
    name: 'HleBoqItemsHistoryBreakdown',
    component: HleBoqItemsHistoryBreakdown,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: NotFound
  },
  {
    path : '/',
    name: 'Home',
    component:Home,
    meta : {
      middleware : Auth
    }
  },
  {
    path : '/boq/builder/:id',
    name: 'BoqBuilder',
    component:BoqBuilder,
    meta : {
      middleware : Auth
    }
  },
  {
    path : '/boq/builder/:id/breakdown/:parent',
    name: 'BoqBuilderBreakdown',
    component:BoqBuilderBreakdown,
    meta : {
      middleware : Auth
    }
  },
  {
    path : '/boq/builder/:id/unassigned',
    name: 'BoqBuilderUnassigneds',
    component:BoqBuilderUnassigneds,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/page/empty',
    name: 'AuthPage',
    component: AuthPage,
  },
  {
    path: '/Unauthorized',
    name: 'unauthorized',
    component: Unauthorized,
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

function nextFactory (context, middleware, index){
  const subsequentMiddleware = middleware[index]

  if(!subsequentMiddleware){
    return context.next
  }

  return(...parameters) => {
    context.next(...parameters)
    const nextMiddleware = nextFactory(context, middleware, index + 1)
    subsequentMiddleware({...context, nextMiddleware})
  }
}

router.beforeEach((to,from,next) =>{
  if(to.meta.middleware){
    const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware]
    const context = {from, next, router, to}
    const nextMiddleware = nextFactory(context,middleware,1)
    return middleware[0]({...context, nextMiddleware})
  }

  return next()
})

export default router
