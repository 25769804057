import {mapActions, mapGetters} from 'vuex'
import { LinkList, ReformatNumber, ModuleActionList } from '@/helpers/utilities.js'
import {GetProjectTradePackages, UpdateTradePackage} from '@/actions/boqActions.js'
import BreadcrumbsProject  from '@/components/projectInformation/BreadcrumbsProject.vue'
import StepItem2  from '@/components/layout/StepItem2.vue'
import Loader  from '@/components/layout/Loader.vue'
import Pagination  from '@/components/layout/Pagination.vue'
import 'dotenv/config'

export default {
    name : 'TradePackageList',
    components : {
        BreadcrumbsProject,StepItem2, Loader, Pagination
    },
    methods : {
        ...mapActions(['ChangeActiveLink', 'GetProjectInfo']),
        ReformatNumber(value){
            return ReformatNumber(value)
        },
        PageChange(page){
            const self = this
            self.tradeParams.page = page
            self.isLoading = true
            GetProjectTradePackages(self.tradeParams).then(res=>{
                self.isLoading = false
                self.tradePackages = res
            })
        },
        OpenEditPackage(data,index){
            this.selectedRemove = []
            if(this.editingPackage==data._id){
                //turn back to original 
                this.tradePackages.docs[this.editingIndex] = JSON.parse(JSON.stringify(this.editingObject))
                this.editingPackage = ''
                this.editingObject = {}
                this.editingIndex = -1
                return 
            }
            //turn back to original 
            if(this.editingIndex!=-1){
                this.tradePackages.docs[this.editingIndex] = JSON.parse(JSON.stringify(this.editingObject))
                this.editingObject =JSON.parse(JSON.stringify(data))
            }else{
                this.editingObject =JSON.parse(JSON.stringify(data))
            }
            this.editingPackage = data._id
            this.editingIndex = index
        },
        ClickTr(id, packageItem){
            if(this.editingPackage!=packageItem){
                return
            }
            const getIndex = this.selectedRemove.indexOf(id)
            if(getIndex==-1){
                this.selectedRemove.push(id)
            }else{
                this.selectedRemove.splice(getIndex,1)
            }
        },
        RemoveItemFromPackage(item){
            const getIndex = this.tradePackages.docs.findIndex(u=>u._id===item._id)
            if(getIndex==-1){
                return
            }

            this.tradePackages.docs[getIndex].bill_of_quantities_consolidated_item = item.bill_of_quantities_consolidated_item.filter(u=>this.selectedRemove.indexOf(u._id)<0)
        },
        UpdateItemFromTradePackage(data, e, index ){
            const self = this
            const btn = e.target

            if(!data.name){
                return
            }
            
            
            btn.disabled =true
            UpdateTradePackage({id : data._id, remove : self.selectedRemove, data : data.bill_of_quantities_consolidated_item, name : data.name}).then((res)=>{
                btn.disabled = false
                if(res=='OK'){
                    self.editingObject =JSON.parse(JSON.stringify(data))
                    self.OpenEditPackage(data,index)
                }else{
                    self.$swal('Edit Trade Package',res,'warning')
                }

            })
        },
        SearchTradePackages(){
            const self = this

            if(self.timer){
                clearTimeout(self.timer)
                self.timer = null
            }
            
            self.isLoading = true
            self.timer = setTimeout(function(){
                GetProjectTradePackages(self.tradeParams).then(res=>{
                    self.isLoading = false
                    self.tradePackages = res
                })
            },700)
        },
        ChangePackageName(e,name){
            const input = e.target
            if(name){
                input.classList.remove('border-warning')
            }else{
                input.classList.add('border-warning')
            }
        }
    },
    computed : {
        ...mapGetters(['projectInfo', 'permission']),
        TradePackageDocs(){
            const self = this

            const data = self.tradePackages.docs

            return data.map(u=>{
            
                let rateTotal = 0
                let priceTotal = 0
                let costTotal = 0
                let qtyTotal = 0
                let gpTotal = 0

                for(let x=0;x<u.bill_of_quantities_consolidated_item.length;x++){
                    const getU = u.bill_of_quantities_consolidated_item[x]
                    getU.price = (((getU.boqItemId.quantity * getU.rate) * (getU.markUp / 100)) + (getU.boqItemId.quantity * getU.rate)) || 0
                    getU.cost = (getU.boqItemId.quantity * getU.rate) || 0
                    getU.gp = (getU.boqItemId.quantity * getU.rate) * (getU.markUp / 100) || 0
                    
                    rateTotal += getU.rate || 0
                    priceTotal += getU.price || 0
                    costTotal += getU.cost || 0
                    qtyTotal += getU.boqItemId.quantity || 0
                    gpTotal += getU.gp || 0

                }
                return {
                    _id : u._id,
                    name : u.name,
                    bill_of_quantities_consolidated_item : u.bill_of_quantities_consolidated_item,
                    total : {
                        rateTotal : rateTotal,
                        priceTotal :priceTotal,
                        qtyTotal :qtyTotal,
                        costTotal :costTotal,
                        gpTotal :gpTotal,
                    }
                }
            })
        }
    },
    mounted : async function(){
        const self = this
        const projectId = self.$route.params.id
        self.ChangeActiveLink(LinkList.TRADE_PACKAGE_LIST)
        await self.GetProjectInfo(projectId).catch()
        self.tradePackages = await GetProjectTradePackages(self.tradeParams).catch()
        if(self.tradePackages==401){
            self.$router.push('/unauthorized')
            return
        }
        self.isLoading = false
    },
    data(){
        return {
            projectUrl : process.env.VUE_APP_PROJECT_URL,
            tradePackages : {
                docs : [],
                totalPages : 1,
                page : 1
            },
            tradeParams : {
                id : this.$route.params.id,
                page : 1,
                search  : ''
            },
            editingObject : {},
            editingIndex : -1,
            editingPackage : '',
            isLoading : true,
            selectedRemove : [],
            timer : null,
            ModuleActionList : ModuleActionList
        }
    }
        
}