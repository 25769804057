import dayjs from "dayjs"
import utc  from 'dayjs/plugin/utc'
import advancedFormat  from 'dayjs/plugin/advancedFormat'

dayjs.extend(advancedFormat)
dayjs.extend(utc)


import  i18n from '../translations/i18n.js'
import {LocaleNameVariable} from '@/translations/i18n.js'
const i18nTranslate = i18n.global.t


export const statusIcons = [
    {},
    {name : 'Ongoing', icon : 'fas fa-cog fa-spin', color : 'primary'},
    {name : 'Complete', icon : 'fas fa-check-circle', color : 'success'},
    {name : 'Cancelled', icon : 'fas fa-times-circle', color : 'danger'}
]


export const stageIcons = [
    {name : i18nTranslate('Project Information'), icon : 'fas fa-lightbulb'},
    {name : i18nTranslate('Bill of Quantities'), icon : 'fas fa-lightbulb'},
    {name : i18nTranslate('Quotation'), icon : 'fas fa-hard-hat'},
    {name : i18nTranslate('Contract'), icon : 'fas fa-hard-hat'},
    {name : i18nTranslate('Procurement'), icon : 'fas fa-handshake'},
    {name : i18nTranslate('Contingency'), icon : 'fas fa-hard-hat'},
    {name : i18nTranslate('Purchase Order'), icon : 'fas fa-handshake'},
    {name : i18nTranslate('Progress Claims'), icon : 'fas fa-handshake'},
    {name : i18nTranslate('Client Invoice'), icon : 'fas fa-handshake'},
    {name : i18nTranslate('Change Order'), icon : 'fas fa-handshake'},
    {name : i18nTranslate('Final Account'), icon : 'fas fa-handshake'},
]


export const LandMeasurements = [
    'm2', 'ft2', 'ping'
]

export const ApprovalStageBoq = {
    DRAFT : 1,
    PENDING : 2,
    REVIEWED : 3,
    FOR_APPROVAL : 4,
    APPROVED :5,
    DECLINED : 6,
    SKIPPED : 7,
    PUBLISHED : 8
}

export const ApprovalStageBoqString = [
    'DRAFT', 
    'PENDING', 
    'REVIEWED', 
    'FOR APPROVAL', 
    'APPROVED',
    'FOR REVISION',
    'SKIPPED',
    'PUBLISHED'
]

export const ProcessStepType =  {
    VIEW : 1,
    APPROVAL :2
}
export const ProcessStepTypeString =  [
    'VIEWING',
    'APPROVAL'
]

export const ProcessItemStatusList = {
    PENDING : 1,
    ENDED  :2
}

export const ProcessStepsActionTypes = {
    PENDING : 1,
    COMPLETED : 2,
    SKIPPED : 3,
}

export const ProcessActionTypes = {
    PENDING :1,
    VIEW :2,
    APPROVED :3,
    DISAPPROVED :4,
    SKIPPED : 5
}

export const ProcessStepsActionString = [
    'PENDING',
    'COMPLETED',
    'SKIPPED',
]

export const ApprovalStageBoqObj = [
    {
        name : i18nTranslate('DRAFT'), 
        color : 'bg-primary text-white'
    },
    {
        name : i18nTranslate('PENDING'), 
        color : 'bg-info text-white'
    },
    {
        name : i18nTranslate('REVIEWED'), 
        color : 'bg-warning text-white'
    },
    {
        name : i18nTranslate('FOR APPROVAL'), 
        color : 'bg-info text-white'
    },
    {
        name : i18nTranslate('APPROVED'), 
        color : 'bg-success text-white'
    },
    {
        name : i18nTranslate('FOR REVISION'), 
        color : 'bg-danger text-white'
    },
    {
        name : i18nTranslate('SKIPPED'), 
        color : 'bg-light text-black'
    },
    {
        name : i18nTranslate('PUBLISHED'), 
        color : 'bg-success text-white'
    },
]
export const LinkList = {
    SUMMARY : 1,
    DIRECTORY : 2,
    QUOTATIONS : 3,
    PO : 4,
    DOCUMENTS : 5,
    MAIN_DASHBOARD : 6,
    MAIN_COMPANY : 7,
    MAIN_COMPANY_USER : 8,
    MAIN_COMPANY_OFFICE : 9,
    MAIN_COMPANY_REGION : 10,
    PROJECT_DETAILS : 11,
    PROJECT_DIRECTORY : 12,
    PROJECT_PROCESS : 13,
    BOQ_MASTER_LIST : 14,
    COST_CODES : 15,
    APPROVAL_LIST : 16,
    CONSOLIDATED_LIST : 17,
    TRADE_PACKAGE_LIST : 18,
    BILL_OF_QUANTITIES : 19,
    BOQ_RATE_LIST : 20,
    HIGH_LEVEL_ESTIMATES : 21
}

// export const RoleList = {
//     OWNER :'Owner',
//     ADMIN : 'Admin',
//     GLOBAL: 'Global',
//     REGIONAL_MANAGER : 'Regional Manager',
//     PROJECT_MANAGER : 'Project Manager',//BUSINESS_UNIT_MANAGER
//     PROJECT_LEADER : 'Project Leader',
//     CONSTRUCTION_MANAGER : 'Construction Manager',
//     COMMERCIAL_MANAGER : 'Commercial Manager',
//     CONTRACT_MANAGER : 'Contract Manager',
//     ACCOUNTS : 'Accounts',
//     FINANCE : 'Finance',
//     DESIGN : 'Design',
//     CLIENT : 'Client'
// }

export const RoleList = {
    ADMIN : 'Administrator',
    PROJECT_DIRECTOR : 'Project Director',
    PROJECT_LEADER: 'Project Leader',
    COMMERCIAL_MANAGER : 'Commercial Manager',
    CONTRACT_MANAGER : 'Contract Manager',
    DESIGNER : 'Designer',
    CONSTRUCTION_MANAGER : 'Construction Manager',
    ACCOUNTING_TEAM : 'Accounting Team',
    CONTRACT_ACCOUNTANT : 'Contract Accountant',
    ENGINEER : 'Engineer',
    CLIENT : 'Client',
    CONSULTANT : 'Consultant',
    VENDOR : 'Vendor',
    SUBCONTRACTOR : 'Subcontractor',
    CONTRACTOR : 'Contractor',
    OTHER : 'Other',
}


export const RoleListInt = {
    ADMIN :1,
    PROJECT_DIRECTOR : 2,
    PROJECT_LEADER: 3,
    COMMERCIAL_MANAGER : 4,//BUSINESS_UNIT_MANAGER
    CONTRACT_MANAGER : 5,
    DESIGNER : 6,
    CONSTRUCTION_MANAGER : 7,
    ACCOUNTING_TEAM : 8,
    CONTRACT_ACCOUNTANT : 9,
    ENGINEER : 10,
    CLIENT : 11,
    CONSULTANT : 12,
    VENDOR : 13,
    SUBCONTRACTOR : 14,
    CONTRACTOR : 15,
    OTHER : 16,
}

export const OfficeList = [
    'Amsterdam','Bangalore',
    'Beijing','Chengdu','Delhi',
    'Guangzhou','Hong Kong','Kuala Lumpur',
    'London','Manila',
    'Melbourne','Mumbai',
    'New York','Paris',
    'San Francisco','Shanghai',
    'Shenzhen','Singapore',
    'Taipei','Toronto','Vancouver'
]

export const CountryList = [
    'Australia','China',
    'France','Hong Kong',
    'India','Malaysia',
    'Netherlands','North America',
    'Philippines','Singapore',
    'Taipei','UK'
]



export const LogActionIcons = {
    'LOG IN' : {icon : 'fa-solid fa-right-to-bracket', color : 'info'},
    'LOG OUT' : {icon : 'fa-solid fa-right-from-bracket', color : 'info'},
    'CREATE' : {icon : 'fa-solid fa-floppy-disk', color : 'success'},
    'UPDATE': {icon : 'fa-solid fa-pen-to-square', color : 'primary'},
    'DELETE' : {icon : 'fa-solid fa-trash', color : 'primary'},
    'READ' : {icon : 'fa-solid fa-eye', color : 'info'},
    'ARCHIVE' : {icon : 'fa-solid fa-box-archive', color : 'danger'},
    'ADD TO DIRECTORY' : {icon : 'fa-solid fa-solid fa-floppy-disk', color : 'success'},
    'REMOVE TO DIRECTORY' : {icon : 'fa-solid fa-trash', color : 'danger'}, 
    'GRANT ADMIN ACCESS' : {icon : 'fa-solid fa-lock-open', color : 'danger'}, 
    'REVOKE ADMIN ACCESS' : {icon : 'fa-solid fa-lock', color : 'success'} 
}

export const ContractType = ['',i18nTranslate("Closed Book"),i18nTranslate('Open Book'), i18nTranslate('Consultancy')]
export const ContractTypeInt = {
    CLOSED_BOOK : 1,
    OPEN_BOOK : 2,
    CONSULTANCY : 3
}

export const MeasurementsUnits = ['cm','ft','ft2','in','km','m','m2','mm','no']

export const ProcessActionText = ['Ongoing', 'Viewed', 'Approved', 'For Revision', 'Skipped']

export const ExportTemplates = ['System ' + i18nTranslate('Template'),'5D ' + i18nTranslate('Template'), 'Cost X ' + i18nTranslate('Template')]

export const CostCodeTypeNames = ['Default','Internal Fee', 'Internal Fee Percentage Based', 'External Fee', 'Vendor Fee','Discount','External Discount']
export const CostCodeTypes = {
    DEFAULT : 1,
    INTERNAL_FEE : 2,
    INTERNAL_FEE_PERCENTAGE : 3,
    EXTERNAL_FEE : 4,
    VENDOR_FEE : 5,
    DISCOUNT : 6,
    EXTERNAL_DISCOUNT : 7
}
export const ApplyToTypes = {
    ALL : 1,
    SPECIFIC : 2,
}
export const ProcessAction = {
    PENDING :1,
    VIEW :2,
    APPROVED :3,
    DISAPPROVED :4,
    SKIPPED : 5
}

export const ModuleActionList =  {
    NONE : 0,
    PROJECT_LOGS_VIEW : 1,
    PROJECT_DETAILS_EDIT : 2,
    DIRECTORY_VIEW : 3,
    DIRECTORY_CREATE :4,
    DIRECTORY_EDIT :5,
    DIRECTORY_REMOVE : 6,
    DIRECTORY_PERMISSION : 7,
    PROCESS_VIEW : 8,
    PROCESS_CREATE : 9,
    PROCESS_EDIT : 10,
    ITEM_MASTER_LIST_VIEW : 11,
    ITEM_MASTER_LIST_CREATE : 12,
    ITEM_MASTER_LIST_EDIT :13,
    ITEM_MASTER_LIST_REMOVE :14,
    BOQ_VIEW : 15,
    BOQ_CREATE : 16,
    BOQ_EDIT : 17,
    BOQ_ITEM_REMOVE : 18,
    BOQ_DUPLIDATE : 19,
    BOQ_HISTORY_VIEW : 20,
    BOQ_PUBLISH : 21,
    CONSOLIDATED_LIST_VIEW : 22,
    TRADE_PACKAGE_VIEW : 23,
    TRADE_PACKAGE_CREATE : 24,
    TRADE_PACKAGE_EDIT : 25,
    BOQ_QUOTATION_VIEW : 26,
    BOQ_QUOTATION_CREATE : 27,
    BOQ_QUOTATION_EDIT : 28,
    BOQ_QUOTATION_DELETE : 34,
    BOQ_QUOTATION_PUBLISH : 29,
    RATE_VIEW : 30,
    RATE_CREATE : 31,
    RATE_EDIT : 32,
    RATE_REMOVE : 33
}

export const LineItemRemarks  ={
    INCLUDED : 'Included',
    EXCLUDED : 'Excluded',
    NOT_APPLICABLE : 'Not Applicable',
    BY_OTHERS : 'By Others',
    BY_CLIENTS : 'By Client',
    BY_BUILDING_MANAGEMENT : 'By Building Management',
}

export const TabulatorSettings = {
    money : {
        editor : 'number',
        formatter : function(cell){

            const cellValue = cell.getValue()
            if(!cellValue){
                return 0
            }

            const absoluteValue = Math.abs(cellValue)
            const formatted = Number(parseFloat(absoluteValue).toFixed(2)).toLocaleString('en',{minimumFractionDigits: 2})

            if(cellValue > 0){
                cell._cell.element.classList.remove("text-danger")
                return formatted
            }else{
                cell._cell.element.classList.add("text-danger")
                return  "(" + formatted + ")"
            }
        },
        formatterWholeNumber : function(cell){
            const cellValue = cell.getValue()
            if(!cellValue){
                return 0
            }

            const absoluteValue = Math.abs(cellValue)
            const formatted = Math.round(absoluteValue).toLocaleString('en')

            if(cellValue > 0){
                cell._cell.element.classList.remove("text-danger")
                return formatted
            }else{
                cell._cell.element.classList.add("text-danger")
                return  "(" + formatted + ")"
            }
        }
    },
    numberMax100 :{
        editor : 'number',
        formatter : function(cell){
            const cellValue = cell.getValue()
            if(!cellValue){
                return 0
            }
            return cell.getValue() + '%'
        },
        formatterNone : function(cell){
            return cell.getValue()
        },
        mutator : function(value){
            
            if(!value){
                return 0
            }
            if(value>100){
                return 100
            }
            else if(value<-100){
                return 0
            }else{
                return value
            }
        },
        editorParams : {
            min:-100,
            max:100,
            elementAttributes:{
              maxlength:"3", 
            }
        }
    },
    numberOnly : {
        editor : 'number',
        editorParams : {
            min:0,
            elementAttributes:{
              maxlength:"3",
            }
        },
        mutator : function(value){
            if(!value){
                return 0
            }
            if(value<0){
                return 0
            }else{
                return value
            }
        }
    }
}

export const NumberFormatter = (value, wholeNumber = true) =>{
    if(!value){
        return 0
    }
    const absoluteValue = Math.abs(value)
    let returnValue = 0
    if(wholeNumber){
        returnValue = Math.round(absoluteValue).toLocaleString('en')
    }
    else{
        returnValue =  Number(parseFloat(absoluteValue).toFixed(2)).toLocaleString('en',{minimumFractionDigits: 2})
    }

    return value > 0 ? returnValue : "(" + returnValue + ")"
     
        
}

export const NumberFormatterWidget = (value,sign = "") =>{
    if(!value){
        return 0
    }

    const absoluteValue = Math.abs(value)
    let returnValue = 0
    returnValue =  Number(parseFloat(absoluteValue).toFixed(2)).toLocaleString('en',{minimumFractionDigits: 2})
    return value > 0 ? sign + returnValue : "-" + sign + returnValue
     
        
}

export const TextCapitalize = (strParam)=>{
    let str = ''
    str += strParam[0].toUpperCase()
    let willCapitalizeNext = false
    for(let x = 1; x <strParam.length;x++){
        if(willCapitalizeNext){
            willCapitalizeNext = false
            str += strParam[x].toUpperCase()
        }else{
            str += strParam[x]
        }

        if(strParam[x]===' '){
            willCapitalizeNext = true
        }
    }
    return str
}

export const SortFunc = (a,b) =>{
    if (a < b) {
        return -1;
    }
    if (a > b) {
        return 1;
    }
    return 0;
}

export const LevelSort = (a,b) => a.level.localeCompare(b.level, undefined, {
    numeric: true,
    sensitivity: 'base'
})


export const ReformatDate = (value, format = "DD-MMMM-YYYY", utc = false) => {
    if(!value){
        return ""
    }
    let getLocale = window.localStorage.getItem(LocaleNameVariable) || 'en'
    if(!utc){
        if (getLocale != 'en') {
            if (format == "DD-MMMM-YYYY") {
                return dayjs(value).format("YYYY年M月D日")
            } else {
                return dayjs(value).format(format)
            }
        }
        else {
            return dayjs(value).format(format)
        }
    }else{
        return dayjs.utc(value).format(format)
    }
    
}
// export const ReformatDateUtc = (value, format = "DD-MMM-YYYY") => dayjs.utc(value).format(format)

export const ReformatNumber = (value, min =2, max =2) => {
    if(!value){
        if(min==0){
            return '0'
        }
        return '0.00'
    }
    const turnToPositive = value < 0 ? Math.abs(value) : value
    const data = turnToPositive.toLocaleString(undefined,{minimumFractionDigits : min, maximumFractionDigits : max}) 

    if(value<0)
        return '(' + data + ')'
    else
        return data

}
export const ReformatNumberHtml = (value, min =2, max =2) => {
    if(!value){
        if(min==0){
            return '0'
        }
        return '0.00'
    }

    const data = value.toLocaleString(undefined,{minimumFractionDigits : min, maximumFractionDigits : max}) 

    if(value<0)
        return `<span class="text-danger">(${data})<span>`
    else
        return data

}

export const ComputeLineItem = (quantity = 0, bufferQuantity = 0, 
    supplyRate = 0, installRate  =0, supplyAndInstallRate = 0,
    supplyBuffer = 0, installBuffer = 0, supplyAndInstallBuffer = 0,
    markUp = 0,
    //new fields

    ) =>{
    const quantityTotal = quantity + bufferQuantity
    const supplyAndInstall =  supplyAndInstallRate ? supplyAndInstallRate :  (supplyRate + installRate)
    const bufferRateAndInstall = supplyAndInstallBuffer ? supplyAndInstallBuffer : (supplyBuffer + installBuffer)
    const costRateTotal = supplyAndInstall + bufferRateAndInstall


    const sellRateRaw =  (supplyAndInstall * (markUp / 100)) + supplyAndInstall
    const sellRate =  (costRateTotal * (markUp / 100)) + costRateTotal

    const costRaw = quantity * supplyAndInstall
    const cost = quantityTotal * costRateTotal

    const sellTotalRaw = sellRateRaw * quantity
    const sellTotal = sellRate * quantityTotal
        
    const gpRaw = sellTotalRaw - costRaw
    const gp = sellTotal - cost

    return {
        quantityTotal : ReformatNumberHtml(quantityTotal,0),
        costRateTotal : ReformatNumberHtml(costRateTotal),

        supplyAndInstallTotal : ReformatNumberHtml(supplyAndInstall * quantity),
        supplyAndInstallBufferTotal : ReformatNumberHtml(supplyAndInstall * quantity),

        supplyTotal : ReformatNumberHtml(supplyRate * quantity),
        installTotal : ReformatNumberHtml(installRate * quantity),

        sellRateRaw : ReformatNumberHtml(sellRateRaw),
        sellRate : ReformatNumberHtml(sellRate),
        bufferEffectRate :  ReformatNumberHtml(sellRate - sellRateRaw),
        
        costTotal : ReformatNumberHtml(cost),
        costTotalRaw : ReformatNumberHtml(costRaw),
        bufferEffectCostTotal :  ReformatNumberHtml(cost - costRaw),

        sellTotalRaw : ReformatNumberHtml(sellTotalRaw),
        sellTotal : ReformatNumberHtml(sellTotal),
        bufferEffectSellTotal :  ReformatNumberHtml(sellTotal - sellTotalRaw),

        gpRaw : ReformatNumberHtml(gpRaw),
        gp : ReformatNumberHtml(gp),
        bufferEffectGp :  ReformatNumberHtml(gp - gpRaw)
    }
}


//start of showing all the columns for the boq

export const BoQColumnList = [
    {
        name : i18nTranslate('Quantity (Raw)'),
        value : 1,
        lineItemIndex : 'quantity',
        minDecimal : 0,
        group : 1
    },
    {
        name : i18nTranslate('Quantity (Buffer)'),
        value : 2,
        lineItemIndex : 'bufferQuantity',
        group : 1
    },
    {
        name : i18nTranslate('Quantity (Adjusted)'),
        value : 3,
        lineItemIndex : 'totalQuantity',
        group : 1
    },
    {
        name : i18nTranslate('Supply Rate (Raw)'),
        value : 4,
        lineItemIndex : 'costRate',
        group : 2
    },
    {
        name : i18nTranslate('Install Rate (Raw)'),
        value : 5,
        lineItemIndex : 'installRate',
        group : 2
    },
    {
        name : i18nTranslate('Supply&Install (Raw)'),
        value : 6,
        lineItemIndex : 'supplyAndInstall',
        group : 2
    },
    {
        name : i18nTranslate('Supply Rate (Buffer)'),
        value : 7,
        lineItemIndex : 'supplyBuffer',
        group : 2
    },
    {
        name : i18nTranslate('Install Rate (Buffer)'),
        value : 8,
        lineItemIndex : 'installBuffer',
        group : 2
    },
    {
        name : i18nTranslate('Supply&Install (Buffer)'),
        value : 9,
        lineItemIndex : 'bufferCostRate',
        group : 2
    },
    {
        name : i18nTranslate('Cost Rate (Adjusted)'),
        value : 10,
        lineItemIndex : 'costRateTotal',
        group : 2
    },
    {
        name : i18nTranslate('Total Cost (Raw)'),
        value : 11,
        lineItemIndex : 'supplyAndInstallTotal',
        headerIndex : 'supplyAndInstallTotal',
        group : 3
    },
    {
        name : i18nTranslate('Total Cost (Buffer)'),
        value : 12,
        lineItemIndex : 'bufferCostTotal',
        headerIndex : 'costTotalBuffer',
        group : 3
    },
    {
        name : i18nTranslate('Total Cost (Adjusted)'),
        value : 13,
        lineItemIndex : 'costTotal',
        headerIndex : 'costTotalTotal',
        group : 3
    },
    {
        name : i18nTranslate('Mark Up%'),
        lineItemIndex : 'markUp',
        minDecimal : 0,
        maxDecimal : 2,
        value : 14,
        group : 4
    },
    {
        name : i18nTranslate('Sell Rate (Raw)'),
        lineItemIndex : 'sellRateRaw',
        value : 15,
        group : 5
    },
    {
        name : i18nTranslate('Sell Rate (Buffer)'),
        lineItemIndex : 'bufferSellRate',
        value : 16,
        group : 5
    },
    {
        name : i18nTranslate('Sell Rate (Adjusted)'),
        lineItemIndex : 'sellRate',
        value : 17,
        group : 5
    },
    {
        name : i18nTranslate('Total Sell (Raw)'),
        value : 18,
        lineItemIndex : 'sellTotalRaw',
        headerIndex : 'sellTotal',
        group : 6
    },
    {
        name : i18nTranslate('Total Sell (Buffer)'),
        value : 19,
        lineItemIndex : 'bufferSellTotal',
        headerIndex : 'sellTotalBuffer',
        group : 6
    },
    {
        name : i18nTranslate('Total Sell (Adjusted)'),
        value : 20,
        lineItemIndex : 'sellTotal',
        headerIndex : 'sellTotalTotal',
        group : 6
    },
    {
        name : i18nTranslate('GP Value (Raw)'),
        value : 21,
        lineItemIndex : 'gpRaw',
        headerIndex : 'gp',
        group : 7
    },
    {
        name : i18nTranslate('GP Value (Buffer)'),
        value : 22,
        lineItemIndex : 'bufferGp',
        headerIndex : 'gpBuffer',
        group : 7
    },
    {
        name : i18nTranslate('GP Value (Adjusted)'),
        value : 23,
        lineItemIndex : 'gp',
        headerIndex : 'gpTotal',
        group : 7
    },
]
export const NoTotalBoQColumnList = [
    {
        name : 'Supply Rate \n(Raw)',
        value : 4,
        lineItemIndex : 'costRate',
        group : 2
    },
    {
        name : 'Install Rate \n(Raw)',
        value : 5,
        lineItemIndex : 'installRate',
        group : 2
    },
    {
        name : 'Supply&Install \n(Raw)',
        value : 6,
        lineItemIndex : 'supplyAndInstall',
        group : 2
    },
    {
        name : 'Supply Rate \n(Buffer)',
        value : 7,
        lineItemIndex : 'supplyBuffer',
        group : 2
    },
    {
        name : 'Install Rate \n(Buffer)',
        value : 8,
        lineItemIndex : 'installBuffer',
        group : 2
    },
    {
        name : 'Supply&Install \n(Buffer)',
        value : 9,
        lineItemIndex : 'bufferCostRate',
        group : 2
    },
    {
        name : 'Cost Rate \n(Adjusted)',
        value : 10,
        lineItemIndex : 'costRateTotal',
        group : 2
    },
    {
        name : 'Mark Up%',
        lineItemIndex : 'markUp',
        minDecimal : 0,
        maxDecimal : 2,
        value : 14,
        group : 4
    },
    {
        name : 'Sell Rate \n(Raw)',
        lineItemIndex : 'sellRateRaw',
        value : 15,
        group : 5
    },
    {
        name : 'Sell Rate \n(Buffer)',
        lineItemIndex : 'bufferSellRate',
        value : 16,
        group : 5
    },
    {
        name : 'Sell Rate \n(Adjusted)',
        lineItemIndex : 'sellRate',
        value : 17,
        group : 5
    },
]
export const ReformatFileName = (originalFileName, suffix = '')=>{

    const fileExtension = originalFileName.split('.').pop();
    const baseName = originalFileName.slice(0, originalFileName.length - fileExtension.length - 1);
  
    const currentDate = new Date();
    const formattedDate = currentDate.getFullYear() + 
                         ("0" + (currentDate.getMonth() + 1)).slice(-2) +
                         ("0" + currentDate.getDate()).slice(-2) +
                         ("0" + currentDate.getHours()).slice(-2) +
                         ("0" + currentDate.getMinutes()).slice(-2) +
                         ("0" + currentDate.getSeconds()).slice(-2) +
                         ("00" + currentDate.getMilliseconds()).slice(-3);
    return `${baseName}_${formattedDate}${suffix}.${fileExtension}`
}

export const GetDistinctValues = (arr) => {
    const distinctValues = [...new Set(arr)]; // Use the Set data structure to remove duplicates
    return distinctValues;
}

export const GetDistinctValuesObject = (arr) => {
    const distinct = arr.filter((v,i,a) => a.findIndex(t => t._id === v._id) === i);
    return distinct;
}

export const ConvertDataToBlob = (data,mimeType) =>{
    let decodedBlobData = Buffer.from(data,'base64').toString('binary')
    let byteNumbers  = new Array(decodedBlobData.length)
    for (let i = 0; i < decodedBlobData.length; i++) {
        byteNumbers[i] = decodedBlobData.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    return URL.createObjectURL(new Blob([byteArray] , { type: mimeType }))
}

export const LocalStorageNameOfListByHLE = 'ath_hle_list_by_id'