import TableBoq from '@/components/boq/TableBoq.vue'
import Loader from '@/components/layout/Loader.vue'
import Modal  from '@/components/layout/Modal.vue'
import StepItem2  from '@/components/layout/StepItem2.vue'
import Select2  from '@/components/layout/Select2.vue'
import BreadcrumbsProject  from '@/components/projectInformation/BreadcrumbsProject.vue'
import { LinkList, ModuleActionList } from '@/helpers/utilities.js'
import 'dotenv/config'
import {mapActions, mapGetters} from 'vuex'
import {CombineBoq, SearchQuotationList,DeleteBoq, UploadOfficeTemplateToMasterList} from '@/actions/boqActions.js'

export default {
    name : 'BoqList',
    components : {
        TableBoq,
        Loader,
        Modal,
        BreadcrumbsProject,
        StepItem2,
        Select2
    },
    mounted : async function(){
        const self = this
        const projectId = self.$route.params.id
        self.ChangeActiveLink(LinkList.BILL_OF_QUANTITIES)
        await self.GetProjectInfo(projectId).catch()
        const getListBoq = await self.GetBoqListRun({id : projectId,page : 1, search : ""}).catch()
        if(getListBoq==401){
            self.$router.push('/unauthorized')
            return
        }
        self.quotationList = await SearchQuotationList({search : '', id : projectId})
        await self.GetCurrencyListRun().catch()
        self.tableIsLoading = false
    },
    methods : {
        ...mapActions(['GetBoqListRun','CreateBoqRun','UpdateBoqRun','GetProjectInfo','GetCurrencyListRun','DuplicateBoqRun','ChangeActiveLink', 'DeleteBoqPermanent']),
        timerSetter(typed, callback, seachEmpty = false){
            const self = this
            if(!seachEmpty){
                if(!typed){
                return
                }
            }
            
            if(self.timer){
                clearTimeout(self.timer)
                self.timer = null
            }

            self.timer = setTimeout(callback,700)
        },
        CreateBoq(elem){
            const self = this
            const projectId = self.$route.params.id
            const btn = elem
            self.saveClicked = true
            if(!self.boqName){
                return
            }
            btn.disabled = true
            self.tableIsLoading = true
            self.CreateBoqRun({
                    data : {projectId : projectId, name : self.boqName, reference : self.boqRef},
                    pager : {id : projectId ,page : 1, search : ""}
            })
            .then((res)=>{
                self.tableIsLoading = false
                btn.disabled = false
                if(res.status!=200){
                    self.$swal(self.$i18n.t('Add BoQ'), res.data, 'warning')
                }else{
                    self.$refs.closeAddModal.click()
                    self.saveClicked = false
                }
            }).catch()
        },
        GetBoq(id, name, currency, reference){
            const self = this
            self.boqUpdateId = id
            self.boqNameUpdate = name
            self.boqCurrencyIdUpdate = currency
            if(reference){
                self.boqRefUpdate = reference._id
            }else{
                self.boqRefUpdate = ''
            }
            
        },
        UpdateBoq(elem){
            const btn = elem
            const self = this
            const boqId = self.boqUpdateId
            const name = self.boqNameUpdate
            const projectId = self.$route.params.id
            self.updateClicked = true
            if(!name){
                return
            }

            btn.disabled = true

            self.UpdateBoqRun({
                data : {id : boqId,name:name, reference : self.boqRefUpdate, isPrimary : self.boqIsPrimaryUpdate},
                pager : {id : projectId ,page : 1, search : ""}
            })
            .then((res)=>{
                btn.disabled = false
                if(res=="OK"){
                    self.$refs.closeUpdateModal.click()
                    self.updateClicked = false
                }
                else{
                    self.$swal(self.$i18n.t('Update BoQ'), res, 'warning')
                }
            })

        },
        DuplicateBoq(elem){
            const self = this
            const projectId = self.$route.params.id
            const btn = elem
            const data = self.boqDuplicate
            self.duplicateClicked = true
            if(!data.boqName){
                return
            }
            btn.disabled = true
            self.tableIsLoading = true
            self.duplicateClicked = false
            self.DuplicateBoqRun({
                    data : {projectId : projectId, name : data.boqName, reference : data.boqRef, isPrimary : data.boqIsPrimary, duplicatedId : data.duplicatedId},
                    pager : {id : projectId ,page : 1, search : ""}
            })
            .then((res)=>{
                self.tableIsLoading = false
                btn.disabled = false
                if(res!="OK"){
                    self.$swal(self.$i18n.t('Duplicate BoQ'), res, 'warning')
                }else{
                    self.$refs.closeDuplicateModal.click()

                }
            }).catch()
        },
        CombineBoqRun(e){
            const self = this
            let btn = e.target
            const newData = self.boqCombine

            self.combineClicked = true
            if(!newData.boqName || newData.selectedCount==0){
                return
            }

            btn.disabled = true
            self.combineClicked = false
            CombineBoq(newData).then((res)=>{
                btn.disabled = false
                if(res=='OK'){
                    self.$refs.closeCombineModal.click()
                    self.tableIsLoading = true
                    self.GetBoqListRun({id : self.$route.params.id, page : 1, search : ''}).then(()=>{
                        self.tableIsLoading = false
                    }).catch()
                }else{
                    self.$swal('Combine BoQ',res,'warning')
                }
            })
        },
        searchRun(value){
            const self = this
            const projectId = self.$route.params.id
            self.tableIsLoading = true
            self.timerSetter(value,function(){
                self.GetBoqListRun({id : projectId, page : 1, search : value}).then(()=>{
                    self.tableIsLoading = false
                }).catch()
            },true)
            
        },
        OpenOfficeImport(){
            this.boqOfficeTemplate.templateType = ''
            this.boqOfficeTemplate.boqRef = ''
            this.$refs.uploadFileOfficeTemplate.value = ''
            this.uploadPercentage = 0
            this.uploadMessage = ''
        },
        OpenCreate(){
            this.boqName = ''
            this.boqRef =''
            this.boqCurrencyId = ''
        },
        OpenDuplicateModal(id, name, currency, reference, isPrimary){
            this.boqDuplicatedName = name 
            this.boqDuplicate.duplicatedId = id
            this.boqDuplicate.boqName = name 
            // this.boqDuplicate.boqRef = reference
            this.boqDuplicate.boqCurrencyId = currency
            this.boqDuplicate.boqIsPrimary = isPrimary
        },
        OpenCombineModal(){
            this.boqCombine = {
                currencyId : '',
                selected : this.selected,
                selectedCount : this.selected.length,
                id : this.$route.params.id
            }
        },
        UpdateSelectedValue (value){
            this.selected = value
        },
        DeleteBoq(id,name){
            const self = this

            const swalTitle = self.$i18n.t('Delete BoQ')

            self.$swal({
                title: swalTitle,
                text : `"${name}" ${self.$i18n.t('will be Deleted Permanently, Proceed?')}'`,
                showCancelButton: true,
                icon: 'warning',
                showLoaderOnConfirm : true,
                allowOutsideClick: () => !self.$swal.isLoading(),
                preConfirm : ()=>{
                    return DeleteBoq(id).then((res)=>{
                        return res
                    })
                }
            }).then((res)=>{
                if(res.isConfirmed){
                    if(res.value=='OK'){
                        self.DeleteBoqPermanent(id)
                        self.$swal(swalTitle,self.$i18n.t('Deleted Successfully'),'success')
                    }else{
                        self.$swal(swalTitle,res.value,'warning')
                    }
                
                }
            })
        },
        UploadProcess(progressEvent){
            this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 90 ) );
            if(this.uploadPercentage >=90){
                this.uploadMessage = 'Processing your file'
            }else{
                this.uploadMessage = 'Uploading your file'
            }
        },
        UploadOfficeTemplate(e){
            let data = new FormData()
            const self = this
            this.uploadPercentage = 0
            if(self.isImportRunning){
                return
            }
            const file = self.$refs.uploadFileOfficeTemplate.files[0]
            const newData = self.boqOfficeTemplate
            const btn = e.target
            self.isImportClicked = true
            newData.file = ''
            if(!file ){
                return
            }
            newData.file = 'new file'

            if(newData.templateType===''){
                return
            }


            data.append('id',self.projectInfo._id)
            data.append('templateType',newData.templateType)
            data.append('template',file)

            self.isImportClicked = false
            btn.disabled = true

            const swalTitle = self.$i18n.t('Import')

            if(newData.templateType==1 || newData.templateType==2|| newData.templateType==3){
                self.isImportRunning = true
                UploadOfficeTemplateToMasterList(data,this.UploadProcess).then((res)=>{
                    self.isImportRunning = false
                    btn.disabled = false
                    this.uploadPercentage = 100
                    this.uploadMessage = 'File Processed'
                    if(res=='OK'){
                        self.$refs.closeBoqImportOfficeTemplates.click()
                        self.tableIsLoading = true
                        self.GetBoqListRun({id : self.$route.params.id, page : 1, search : ''}).then(()=>{
                            self.tableIsLoading = false
                        }).catch()
                    }
                    else{
                        self.$swal(swalTitle,res,'warning')
                    }
                })
            }else{
                btn.disabled = false
                this.$swal(swalTitle,self.$i18n.t('Sorry not yet implemented'),'warning')
            }
            
        },

    },
    computed : mapGetters(['projectInfo', 'boqCurrency','permission']),
    data(){
        return {
            tableIsLoading :true,
            searchBar : '',
            boqName : '',
            boqRef : '',
            boqCurrencyId : '',
            boqNameUpdate : '',
            boqRefUpdate : '',
            boqCurrencyIdUpdate : '',
            boqIsPrimaryUpdate : false,
            boqUpdateId : '',
            boqCombine : {
                selected : '',
                id : this.$route.params.id,
                selectedCount : 0
            },
            selected : [],
            saveClicked : false,
            updateClicked : false,
            boqDuplicate : {},
            boqDuplicatedName : '',
            boqOfficeTemplate : {},
            isImportClicked : false,
            duplicateClicked : false,
            combineClicked : false,
            timer : null,
            projectUrl : process.env.VUE_APP_PROJECT_URL,
            quotationList : [],
            ModuleActionList : ModuleActionList,
            isImportRunning : false,
            uploadPercentage : 0,
            uploadMessage : ''
        }
    }

    
}