<template>
    <div v-for="(element,key) in SectionList" :key="key">
        <div class="d-flex small"  >
            <div class=" p-2 border border-end-0 border-top-0 " :class="{'ps-4' : element.level==2,'ps-5' : element.level==3}" style="width: 25%;">
                <span class="text-primary fw-bold text-capitalize" 
                > {{ element.name }}</span> 
            </div>
            <div class=" p-2 border border-end-0 border-top-0 text-end " :title="element.expectedAmount ? 'Estimate Amount' : ''" :class="{'text-info fw-light' : element.expectedAmount, 'text-danger' : element.sum?.costTotal < 0}" style="width: 15%;">
                {{ element.sum  ? ReformatNumber(element.sum.costTotal) : ''}}
            </div>
            <div class=" p-2 border border-end-0 border-top-0 text-end " :title="element.expectedAmount ? 'Estimate Amount' : ''" :class="{'text-info fw-light' : element.expectedAmount, 'text-danger' : element.sum?.sellTotal < 0}" style="width: 15%;">
                {{ element.sum  ? ReformatNumber(element.sum.sellTotal) : ''}}
            </div>
            <div class=" p-2 border border-end-0 border-top-0 text-end " :title="element.expectedAmount ? 'Estimate Amount' : ''" :class="{'text-info fw-light' : element.expectedAmount, 'text-danger' : element.sum?.gp < 0}" style="width: 15%;">
                {{ element.sum  ? ReformatNumber(element.sum.gp) : ''}}
            </div>
            <div class="p-2 border border-end-0 border-top-0 text-end " :title="element.expectedAmount ? 'Estimate Amount' : ''" :class="{'text-info fw-light' : element.expectedAmount, 'text-danger' : element.sum?.gpPercentage < 0}" style="width: 15%;">
                {{ element.sum  ? ReformatNumber(element.sum.gpPercentage,0,2) + '%' : ''}} 
            </div> 
            <div class="p-2 border border-top-0" style="width: 15%;" v-if="approvalId && !breakdownLink">
                <router-link v-if="element.level==3 || (element.level==2 && element.childs.length==0)" 
                :to="{name : 'BoqReviewBreakdown',params : {id : approvalId, parent : element.id}}"
                 class="fw-bold text-primary text-decoration-none small me-1">
                    <i class="fa-solid fa-list"></i> {{$t('Breakdown')}}
                </router-link> 
            </div>
            <div class="p-2 border border-top-0" style="width: 15%;" v-if="revisionId && !breakdownLink">
                <router-link v-if="element.level==3 || (element.level==2 && element.childs.length==0)" 
                :to="{name : 'BoqHistoryBreakdown',params : {id : BoqItem._id, parent : element.id}, query : {revisionId : revisionId}}"
                 class="fw-bold text-primary text-decoration-none small me-1">
                    <i class="fa-solid fa-list"></i> {{$t('Breakdown')}}
                </router-link> 
            </div>
            <div class="p-2 border border-top-0" style="width: 15%;" v-if="breakdownLink">
                <router-link v-if="element.level==3 || (element.level==2 && element.childs.length==0)" 
                :to="{name : breakdownLink.name,params : {id : breakdownLink.params.id, boqId : breakdownLink.params.boqId, parent : element.id}, query : {revisionId : breakdownLink.query.revisionId}}"
                 class="fw-bold text-primary text-decoration-none small me-1">
                    <i class="fa-solid fa-list"></i> {{$t('Breakdown')}}
                </router-link> 
            </div>
        </div>
        <BoqSectionRowReadOnly v-if="element.level!=0" :SectionList="element.childs" :Level="Level + 1"
        :Parent="element"  :BoqItem="BoqItem" :approvalId="approvalId" :revisionId="revisionId" :breakdownLink="breakdownLink"  />
        <div class="d-flex  small fw-bold bg-light" v-if="element.childs.length!=0">
            <div class="text-end p-2 border border-end-0 border-top-0" style="width: 25%;">
                <span class="fw-bold text-capitalize " :class="{'pe-5' : Level!=1}">{{ $t('Sub Total Of') }} {{ element.name }}</span>
            </div>
            <div class=" p-2 border border-end-0 border-top-0 text-end" style="width: 15%;" :class="{'text-danger' : element.subTotal?.costTotal < 0}">
                {{ ReformatNumber(element.subTotal?.costTotal) }}
            </div>
            <div class="1 p-2 border border-end-0 border-top-0 text-end" style="width: 15%;" :class="{'text-danger' : element.subTotal?.sellTotal < 0}">
                {{ ReformatNumber(element.subTotal?.sellTotal) }}
            </div>
            <div class=" p-2 border border-end-0 border-top-0 text-end" style="width: 15%;" :class="{'text-danger' : element.subTotal?.gp < 0}">
                {{ ReformatNumber(element.subTotal?.gp) }}
            </div>
            <div class=" p-2 border border-end-0 border-top-0 text-end" style="width: 15%;" :class="{'text-danger' : element.subTotal?.gpPercentage < 0}">
                {{ ReformatNumber(element.subTotal?.gpPercentage,0,2) }}%
            </div>
            <div class=" p-2 border  border-top-0 text-end" style="width: 15%;">
                
            </div>
        </div>
    </div>  

</template>
<script>
import BoqSectionRowReadOnly from '@/components/boq/BoqSectionRowReadOnly.vue'
import {ReformatNumber} from '@/helpers/utilities.js'
export default{
    name : 'BoqSectionRowReadOnly',
    components : {
        BoqSectionRowReadOnly
    },  
    props : {
        Parent : {
            type : Object,
            default : ()=>{}
        },
        SectionList : {
            type : Array,
        },
        Level : {
            type : Number,
        },
        BoqItem : {
            type : Object
        },
        approvalId : {
            type : String,
        },
        revisionId : {
            type : String,
        },
        breakdownLink : {
            type :Object
        }
    },
    methods : {
        ReformatNumber,
    },
}

</script>