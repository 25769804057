<template>
    <div>
        <BreadcrumbsProject
        :breadcrumbs="[
            {
                link : '/high-level-estimates',
                title : 'Projects Estimates',
                isRouterLink: true
            },
            {
                link : '/high-level-estimates/' + hleProjectId + '/bill-of-quantities',
                title : (hleProject.projectName || '-' )+ ' - Bill of Quantities',
                isRouterLink: true
            },
            {
                link : '/high-level-estimates/' + hleProjectId + '/bill-of-quantities/' + boqId,
                title :boqUrlname,
                isRouterLink: true
            },
        ]"
        />
        <div class="card">
            <div class="card-header bg-white">
                <div class="d-flex justify-content-between">
                    <div>
                        <span> Currency : {{ boqItem.currencyId.shortName || '' }} {{ boqItem.currencyId.name || ''  }}</span>
                    </div>
                    <div>
                        <span :class="'badge ' + ApprovalStageBoqObj[boqItem.status - 1].color">{{ ApprovalStageBoqObj[boqItem.status - 1].name}}</span>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="d-flex justify-content-between mb-2">
                    <div>
                        <button class="btn btn-default pe-2 ps-2 me-1"
                        @click="()=>{ OpenFilter = !OpenFilter}" title="Filter Line Items"
                        >
                        <i class="fa-solid fa-filter"></i>  
                        </button>
                        <button class="btn btn-default pe-2 ps-2" title="Filter Columns"
                        data-bs-toggle="modal" data-bs-target="#BoqColumnFilter"
                        >
                        <i class="fa-solid fa-table-columns"></i>  
                        </button>
                    </div>
                    <div>
                        <button class="btn btn-primary me-1"
                        @click="OpenAddItemModal"
                        data-bs-toggle="modal" data-bs-target="#ModalNewLineItem"
                        >
                            <i class="fa-solid fa-plus"></i> Add
                        </button>
                        <button class="btn btn-primary me-1" v-if="lineItems.length!=0 && !approvalItem" @click="SendForApproval"><i class="fa-solid fa-file-pen"></i> Send for Approval</button>
                        <button class="btn btn-default me-1  pe-2 ps-2"
                        data-bs-toggle="modal" data-bs-target="#ModalImportBoq"
                        @click="OpenImportModal" 
                        title="Import File"><i class="fa-solid fa-upload"></i>  </button>
                        <button class="btn btn-default pe-2 ps-2" title="Export to File"
                        data-bs-toggle="modal" data-bs-target="#BoqExportBoqList"
                        @click="()=>{ exportTemplateType= 0; }"><i class="fa-solid fa-file-arrow-down"></i> </button>
                        <router-link  title="Print Bill of Quantity" :to="{name : 'HleBoqItemsPrint', params : {id : hleProjectId, boqId : boqItem._id} }"  class="btn btn-default pe-2 ps-2 ms-1"><i class="fa-solid fa-print"></i></router-link>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 pb-2" v-if="OpenFilter">
                        <div class="form-group ">
                            <label class="d-flex justify-content-between">
                                <a>Cost Category</a>
                                <span role="button" class="text-danger text-decoration-underline" @click="ClearField(2)"><i class="fa-solid fa-xmark "></i> Clear Field</span>
                            </label>
                            <Multiselect 
                            :multiple="true" :options="searchableCostCodes" 
                            placeholder="Type Here to search by Cost Category"
                            v-model="selectedCostCode" :max-height="300"
                            label="costName" track-by="_id"/>
                        </div>
                    </div>
                    <div class="col-md-6 pb-2" v-if="OpenFilter">
                        <div class="form-group ">
                            <label class="d-flex justify-content-between">
                                <a>Space ID</a>
                                <span role="button" class="text-danger text-decoration-underline" @click="ClearField(1)"><i class="fa-solid fa-xmark "></i> Clear Field</span>
                            </label>
                            <Multiselect 
                            :multiple="true" :options="searchableAreas" 
                            placeholder="Type Here to search by Space ID"
                            v-model="selectedArea"  :max-height="300" />
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-between">
                    <div>
                        <div v-if="boqItem.reference">
                            <small class="text-muted">Reference: </small>
                            <router-link :to="{name : 'HleQuotation', params : { id : hleProjectId, qtnId : boqItem.reference._id} }" class="text-capitalize small text-primary fw-bold"> 
                                {{boqItem.reference.name}}
                            </router-link>
                        </div>
                    </div>
                    <div>
                        <router-link :to="{name : 'HleBoqItemsSummary', params : { id : hleProjectId,boqId : boqItem._id} }"  class="small text-primary fw-bold">View Summary</router-link>
                    </div>
                </div>
                <div class="alert alert-outline-info d-flex align-items-center " role="alert" v-if="approvalItem">
                    <i class="fas fa-info-circle fa-2x me-2"></i>  
                    <div>
                        This Bill of Quantity is ongoing approval . <router-link class="link-style-custom" :to="{name : 'HleBoqItemsApproval', params : {id : approvalItem._id}}">Go to Approval</router-link>
                    </div>
                </div>
                <BoqItemTable  :rowDatas="lineItems" :reloadData="GetListByBits" :stage="boqItem.status"  :OpenEditModal="OpenEditLineItem"
                :selectedColumns="selectedColumns" :customPermission="[17,18]" :DeleteItemFunc="DeleteHighLevelBoqLineItem" />
            </div>
        </div>
        <ModalLineItemColumnFilter :UpdateSelectedColumn="ChangeSelectecColumnValue"/>
        <ModalNewLineItem
        ref="ModalNewLineItem"
        :AddLineItemFunc="AddHighLevelBoqLineItem"
        :boqList="lineItems" :ResetData="GetListByBits" :boqId="boqItem._id" :officeId="boqItem.officeId" />
        <ModalUpdateLineItem
        ref="ModalUpdateLineItem"
        :selectedLineItem="updateLineItem"
        :UpdateLineItemFunc="UpdateHighLevelBoqLineItem" 
        :boqList="lineItems" :ResetData="GetListByBits" />
        <Modal idModal="BoqExportBoqList" headerTitle="Export BoQ"  >
            <div>
                <div class="form-group">
                    <label class="required-style">Template </label>
                    <select class="form-select" v-model="exportTemplateType"> 
                        <option value="0">Please Select</option>
                        <option value="1">System Template</option>
                    </select>
                </div>
            </div>
            <template v-slot:actions>
                <button type="button" ref="closeModalExportBoq" class="btn btn-outline-danger" data-bs-dismiss="modal">X Cancel</button>
                <button type="button" class="btn btn-primary" @click="ExportBoqList" >
                    <i class="fa-solid fa-file-arrow-down"></i>  Export
                </button>
            </template>
        </Modal>
        <Modal idModal="ModalImportBoq" headerTitle="Import BoQ"  >
            <div>
                <div class="form-group">
                    <label class="required-style">File </label>
                    <input type="file" class="form-control" ref="uploadFile" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"  />
         
                    <small class="text-warning" v-if="isImportClicked && !newBoqImport.file">Required</small>   
                </div>
                <div class="form-group">
                    <label class="required-style">Template </label>
                    <select class="form-select" v-model="newBoqImport.importTemplateType">  
                        <option value="0">Please Select</option>
                        <option value="1">System Template</option>
                    </select>
                    <div class="text-end">
                        <a href="#" class="small" role="button" @click="DownloadTemplate">Download selected template</a>
                    </div>
                    <small class="text-warning" v-if="isImportClicked && !newBoqImport.importTemplateType">Required</small>   
                </div>
                
            </div>
            <template v-slot:actions>
                <button type="button" ref="closeModalImportBoq" class="btn btn-outline-danger" data-bs-dismiss="modal">X Cancel</button>
                <button type="button" class="btn btn-primary" @click="ImportBoqList" > 
                    <i class="fa-solid fa-file-arrow-down"></i>  Import
                </button>
            </template>
        </Modal>
    </div>
</template>


<script>
import BreadcrumbsProject  from '@/components/projectInformation/BreadcrumbsProject.vue'
import {GetHighLevelBoq, GetHighLevelBoqLineItemsByBits, AddHighLevelBoqLineItem, ExportHighLevelBoqLineItems, ImportHighLevelBoqLineItems,DownloadTemplate1,
UpdateHighLevelBoqLineItem, DeleteHighLevelBoqLineItem, GetApprovalItemHighLevelEstimates, GetHighLevelProject} from '@/actions/boqActions.js'
import {LinkList, ApprovalStageBoqObj, ExportTemplates, ApprovalStageBoq, GetDistinctValues, GetDistinctValuesObject} from '@/helpers/utilities.js'
import  {mapActions, mapGetters} from 'vuex'
import BoqItemTable from '@/components/boq/BoqItemTable.vue'
import ModalUpdateLineItem from '@/components/boq/ModalUpdateLineItem.vue'
import ModalLineItemColumnFilter from '@/components/boq/ModalLineItemColumnFilter.vue'
import ModalNewLineItem from '@/components/boq/ModalNewLineItem.vue'
import Modal from '@/components/layout/Modal.vue'
import Multiselect from 'vue-multiselect'
export default {
    name : 'HleBoqItems',
    mounted :async function(){
        const self = this
        const boqItem = await GetHighLevelBoq(self.boqId)
        self.boqItem = boqItem
        self.boqUrlname = boqItem.name  + ' - (' + boqItem.currencyId.sign +') '  + boqItem.currencyId.shortName  +' '+ boqItem.currencyId.name
        self.ChangeActiveLink(LinkList.HIGH_LEVEL_ESTIMATES)
        self.hleProject = await GetHighLevelProject(self.hleProjectId)
        await self.GetListByBits()
        const lineItemCols =  window.localStorage.getItem('lineItemColumns')
        if(lineItemCols){
            this.selectedColumns = lineItemCols.split(',').map(Number)
        }

        self.searchableAreas = GetDistinctValues(self.lineItems.map(u=>u.area).filter(value => value !== null && value !== undefined && value !== ''))
        self.searchableAreas.push('NO SPACE ID')

        const getMainSections = self.lineItems.map(u=>{
            return {
                _id : u.parents[0].costCodeId,
                costName : u.parents[0].costCode + ' ' +u.parents[0].name
            }
        }) 
        const getSubSections = self.lineItems.map(u=>{
            return {
                _id : u.parents[1].costCodeId,
                costName : u.parents[1].costCode + ' ' +u.parents[1].name
            }
        }) 
        const getLineItemCostCode = self.lineItems.map(u=>{
            return {
                _id : u.costCodeId._id,
                costName : u.costCode + ' ' +u.name
            }
        })
        
        let combined = [...getMainSections,...getSubSections,...getLineItemCostCode]
        self.searchableCostCodes = GetDistinctValuesObject(combined)

        if(boqItem.status==ApprovalStageBoq.FOR_APPROVAL){
            self.approvalItem = await GetApprovalItemHighLevelEstimates({boqId : boqItem._id})
        }
    },
    components : {
        BreadcrumbsProject, BoqItemTable, ModalUpdateLineItem, ModalLineItemColumnFilter, ModalNewLineItem, Modal, Multiselect
    },
    computed : {
        ...mapGetters(['boqCurrency'])
    },
    methods :{
        ...mapActions(['ChangeActiveLink', 'GetCurrencyListRun']),
        AddHighLevelBoqLineItem : AddHighLevelBoqLineItem,
        UpdateHighLevelBoqLineItem : UpdateHighLevelBoqLineItem,
        DeleteHighLevelBoqLineItem : DeleteHighLevelBoqLineItem,
        async GetListByBits(){
            const self = this

            self.lineItems = []
            let willFetch = true
            self.boqListParams.skip = 0
            while(willFetch){
                const bits = await GetHighLevelBoqLineItemsByBits(self.boqListParams)
                if(bits.length!=0){
                    self.lineItems.push(...bits)
                }
                if(bits.length==self.boqListParams.take){
                    self.boqListParams.skip += 50
                }else{
                    willFetch = false
                }
            }
        },
        OpenEditLineItem(data){
            this.updateLineItem = JSON.parse(JSON.stringify(data))
            this.updateLineItem.id = data._id
            this.updateLineItem.markUp = this.updateLineItem.markUp * 100
        },
        ChangeSelectecColumnValue(value){
            this.selectedColumns = value
        },
        UpdateSelected(data){
            this.selectedLineItems = data
        },
        OpenImportModal(){
            this.newBoqImport = {
                importTemplateType : 1,
            }
            this.$refs.uploadFile.value = ''
        },
        async ExportBoqList(e){
            const btn = e.target
            const self = this
            btn.disabled = true
            if(this.exportTemplateType==1)
            {
                await ExportHighLevelBoqLineItems(self.boqItem._id,self.exportTemplateType,self.boqItem.name)
                self.$refs.closeModalImportBoq.click()
                btn.disabled = false
            }
            else
            {
                this.$swal('Export Template','Template not yet implemeted','warning')
                btn.disabled = false
            }
        },
        async ImportBoqList(e){
            let data = new FormData()
            const self = this

            if(self.isImportRunning){
                return
            }

            const file = self.$refs.uploadFile.files[0]
            const newData = self.newBoqImport
            const btn = e.target

            self.isImportClicked = true
            newData.file = ''
            if(!file ){
                return
            }
            newData.file = 'new file'

            if(newData.importTemplateType===''){
                return
            }

            data.append('id',self.boqItem._id)
            data.append('type',newData.importTemplateType)
            data.append('template',file)

            self.isImportRunning = true
            self.isImportClicked = false
            btn.disabled = true
            if(newData.importTemplateType==1){
                const uploaded = await ImportHighLevelBoqLineItems(data)
                self.isImportRunning = false
                btn.disabled = false
                if(uploaded=='OK'){
                    self.GetListByBits()
                    self.$refs.closeModalImportBoq.click()
                }
                else{
                    self.$swal('Import File',uploaded,'warning')
                }
            }else{
                self.isImportRunning = false
                btn.disabled = false
                this.$swal('Import Template','Sorry not yet implemented','warning')
            }
        },
        OpenAddItemModal(){
            this.$refs.ModalNewLineItem.ClearData()
        },
        DownloadTemplate(){
            if(this.newBoqImport.importTemplateType==1)
                DownloadTemplate1()
            else
                this.$swal('Download Template','Sorry not yet implemented','warning')
        },
        SendForApproval(){
            this.$router.push({name : 'HleApprovalSetup', query : { boqId : this.boqItem._id}})
        },
        ClearField(type){
            if(type==1){
                this.selectedArea = []
            }
            else{
                this.selectedCostCode = []
            }
        },
    },
    data(){
        return {
            hleProject : {},
            hleProjectId : this.$route.params.id ,
            lineItems : [],
            boqItem : {
                status : 1,
                currencyId  : {
                    sign : '',
                }
            },
            boqUrlname : '',
            boqId : this.$route.params.boqId,
            ApprovalStageBoqObj : ApprovalStageBoqObj,
            selectedColumns : [],
            updateLineItem : {},
            boqListParams : {
                id : this.$route.params.boqId,
                costCode : '',
                area : '',
                page : 1,
                take : 50,
                skip : 0
            },
            ExportTemplates : ExportTemplates,
            exportTemplateType : 0,
            OpenFilter : false,
            newBoqImport: {
                importTemplateType : 0
            },
            isImportRunning : false,
            isImportClicked : false,
            approvalItem : null,
            selectedCostCode : [],
            selectedArea : [],
            searchableCostCodes : [],
            searchableAreas : [],
            timerSearch : null
        }
    }
}
</script>