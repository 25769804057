<template>
    <div>
        <BreadcrumbsProject
        :breadcrumbs="[
            {
                link : '/high-level-estimates',
                title : $t('Bidding'),
                isRouterLink: true
            },
            {
                link : '/high-level-estimates/' + hleProjectId + '/bill-of-quantities',
                title : (hleProject.projectName || '-' )+ ' - ' + $t('Bidding Module.Detailed Estimates'),
                isRouterLink: true
            },
            {
                link : '/high-level-estimates/' + hleProjectId + '/bill-of-quantities-builder/' + boqId,
                title :boqUrlname,
                isRouterLink: true
            },
        ]"
        />
        <div class="card">
            <div class="card-header bg-white">
                <div class="d-flex justify-content-between">
                    <div class="d-flex justify-content-center align-items-center">
                        <span class="text-capitalize fw-bold"> {{ $t('Bidding Module.Detailed Estimate') }} : {{ boqItem.name }}</span>
                    </div>
                    <div class="btn-group">
                        <router-link :to="{name : 'HleApprovalSetup', query : { boqId : this.boqItem._id}}" class="btn btn-primary" v-if="sectionList.length!=0 && !approvalItem" @click="SendForApproval">
                            <i class="fa-solid fa-file-pen"></i> 
                            {{ $t('Send For Approval') }}
                        </router-link>
                        <ButtonTemplateDownloader />
                        <button class="btn btn-default"
                        data-bs-toggle="modal" data-bs-target="#ModalImportBoq"
                        @click="OpenImportModal" ><i class="fa-solid fa-file-import"></i> {{$t('Import')}}  </button>
                        <button class="btn btn-default" title="Export to File"
                        data-bs-toggle="modal" data-bs-target="#BoqExportBoqList"
                        @click="()=>{ exportTemplateType= 0; }"><i class="fa-solid fa-file-export"></i> {{$t('Export')}} </button>
                        <router-link  title="Print Bill of Quantity" :to="{name : 'HleBoqItemsPrint', params : {id : hleProjectId, boqId : boqItem._id} }"  
                        class="btn btn-default"><i class="fa-solid fa-print"></i>  {{$t('Print')}}</router-link>
                    </div>
                    <!--  -->
                </div>
            </div>
            <div class="card-body">
                <div class="alert alert-outline-info d-flex align-items-center " role="alert" v-if="approvalItem">
                    <i class="fas fa-info-circle fa-2x me-2"></i>  
                    <div>
                        {{ $t('Bidding Module.This Detailed Estimate is awaiting approval') }}. <router-link class="link-style-custom" :to="{name : 'HleBoqItemsApproval', params : {id : approvalItem._id}}">{{ $t('Go to Approval') }}</router-link>
                    </div>
                </div>
                <div class="alert alert-outline-info d-flex align-items-center " role="alert" v-if="haveUnassignedLineItems">
                    <i class="fas fa-info-circle fa-2x me-2"></i>  
                    <div>
                        {{ $t('Bidding Module.This Detailed Estimate has an unassigned Line items') }}. <router-link class="link-style-custom" :to="{name : 'HleBoQBuilderUnassigneds', params : {id : hleProjectId, boqId : boqId}}">{{ $t('Assign Line Items') }}</router-link>
                    </div>
                </div>
                <div class="d-flex justify-content-between mb-2">
                    <div>
                        <span v-if="boqItem.reference">
                            <small class="text-muted">{{ $t('Reference')}}: </small> 
                            <router-link :to="{name : 'HleQuotation', params : { id : hleProjectId, qtnId : boqItem.reference._id} }" class="text-capitalize small text-primary fw-bold"> 
                                {{boqItem.reference.name}}
                            </router-link>
                        </span>
                    </div>
                    <div class="btn-group">
                        <span :class="'badge ' + ApprovalStageBoqObj[boqItem.status - 1].color">{{ ApprovalStageBoqObj[boqItem.status - 1].name}}</span>
                    </div>
                </div>
                <FigureCurrencyNote :currency="boqItem.currencyId" />
                <!-- <div class="d-flex justify-content-center fw-light">
                    <i>All monetary figures shown below are in {{ boqItem.currencyId?.name }} ({{ boqItem.currencyId?.sign }}) and are exclusive of tax</i>
                </div> -->
                <div class="d-flex justify-content-between mb-1">
                    <div>
                        
                    </div>
                    <div class="" v-if="haveMoved">
                        <button class=" btn btn-default text-black me-1" @click="CancelSaveSequence" ><i class="fa-solid fa-ban"></i> Cancel</button>
                        <button class=" btn btn-outline-primary " @click="SaveSequences" ><i class="fa-solid fa-list-ol"></i> Save Sequence</button>
                    </div>
                </div>
                <div>
                <div class="d-flex fw-bold" style="background-color: #F5F6FA;" >
                    <div class=" p-2 border border-end-0 " style="width: 5%;"></div>
                        <div class="p-2 border border-end-0" style="width: 30%;">{{ $t('Section') }}</div>
                        <div class="p-2 border border-end-0 " style="width: 12%;"> {{ $t('Total Cost') }} ({{boqItem.currencyId?.sign}})</div>
                        <div class="p-2 border border-end-0" style="width: 12%;">  {{ $t('Total Sell') }} ({{boqItem.currencyId?.sign}})</div>
                        <div class="p-2 border border-end-0" style="width: 12%;">{{ $t('GP Total') }}  ({{boqItem.currencyId?.sign}})</div>
                        <div class="p-2 border border-end-0" style="width: 12%;"> {{ $t('GP') }} %</div>
                        <div class="p-2 border" style="width: 17%;"></div>
                        
                    </div>
                    <div class=" small p-2 border border-top-0" style="min-height: 37.8px;" >
                        <div class=" text-end "  >
                            <small class="fw-bold text-success " @click="OpenAddNewSection" role="button" data-bs-toggle="modal" data-bs-target="#BoQAddMainSectionModal">
                                <i class="fa-solid fa-plus"></i> {{ $t('Add Main Section') }}
                            </small>
                        </div>
                    </div>
                    <div class="" v-if="sectionList.length==0">
                        <div class="text-center p-2 border border-top-0">
                            <span class="fw-bold">{{ $t('No data Found') }}</span>
                        </div>
                    </div>
                    <div class="parent ">
                        <BoqSectionRow :OpenRename="OpenRename" :BoqItem="boqItem" :OpenAddNewSubSection="OpenAddNewSubSection" :DeleteMain="DeleteMain" :SectionList="sectionList" :Level="1"
                        :OnEnd="OnEnd" :OpenMarkUp="OpenMarkUp" :IsQuotationPublished="IsQuotationPublished" :ToObject="{ name : 'HleBoqBuilderBreakdown', params : { id : hleProjectId, boqId : boqId} }" >
                        
                        </BoqSectionRow>
                    </div>
                    <div class="d-flex small fw-bold bg-light-primary" >
                        <div class="  p-2 border border-end-0 border-top-0" style="width: 5%;">
                        </div>
                        <div class=" text-end p-2 border border-end-0 border-top-0 " style="width: 30%;">
                            <span class="fw-bold text-capitalize">{{ $t('Overall Total') }}</span>
                        </div>
                        <div class=" p-2 border border-end-0 border-top-0 text-end" style="width: 12%;">
                            {{ ReformatNumber(overAllTotal.costTotal) }}
                        </div>
                        <div class=" p-2 border border-end-0 border-top-0 text-end" style="width: 12%;">
                            {{ ReformatNumber(overAllTotal.sellTotal) }}
                        </div>
                        <div class=" p-2 border border-end-0 border-top-0 text-end" style="width: 12%;">
                            {{ ReformatNumber(overAllTotal.gp) }}
                        </div>
                        <div class=" p-2 border border-end-0 border-top-0 text-end" style="width: 12%;">
                            {{ ReformatNumber(overAllTotal.gpPercentage,0,2)  }}%
                        </div>
                        <div class="p-2 border border-top-0" style="width: 17%;">
                        </div>
                    </div>


                </div>
            </div>
        </div>
        <Modal idModal="BoqExportBoqList" :headerTitle="$t('Export')"  >
            <div>
                <div class="form-group">
                    <label class="required-style"> {{ $t('Template') }} </label>
                    <select class="form-select" v-model="exportTemplateType"> 
                        <option value="0">{{ $t('Please Select') }}</option>
                        <option value="1">{{ $t('System Template') }}</option>
                        <option value="2">{{ $t('System Template (For Presentation)') }}</option>
                    </select>
                </div>
            </div>
            <template v-slot:actions>
                <button type="button" ref="closeModalExportBoq" class="btn btn-outline-danger" data-bs-dismiss="modal">X {{ $t('Cancel') }}</button>
                <button type="button" class="btn btn-primary" @click="ExportBoqList" >
                    <i class="fa-solid fa-file-arrow-down"></i>  {{ $t('Export') }}
                </button>
            </template>
        </Modal>
        <Modal idModal="ModalImportBoq" :headerTitle="$t('Import')"  >
            <div>
                <div class="form-group">
                    <label class="required-style">{{$t('File')}} </label>
                    <input type="file" class="form-control" ref="uploadFile" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"  />
         
                    <small class="text-warning" v-if="isImportClicked && !newBoqImport.file">{{ $t('Required') }} </small>   
                </div>
                <div class="form-group">
                    <label class="required-style">{{ $t('Template') }} </label>
                    <select class="form-select" v-model="newBoqImport.importTemplateType">  
                        <option value="0">{{ $t('Please Select') }}</option>
                        <option value="1">{{ $t('System Template') }}</option>
                    </select>
                    <div class="text-end">
                        <a href="#" class="small" role="button" @click="DownloadTemplate">{{$t('Download selected template')}}</a>
                    </div>
                    <small class="text-warning" v-if="isImportClicked && !newBoqImport.importTemplateType">{{ $t('Required') }}</small>   
                </div>
                
            </div>
            <template v-slot:actions>
                <button type="button" ref="closeModalImportBoq" class="btn btn-outline-danger" data-bs-dismiss="modal">X {{ $t('Cancel') }}</button>
                <button type="button" class="btn btn-primary" @click="ImportBoqList" > 
                    <i class="fa-solid fa-file-arrow-down"></i> {{ $t('Import') }}
                </button>
            </template>
        </Modal>
        <Modal idModal="RenameSectionModal" :headerTitle="$t('Update Section')" modalSize="modal-md" >
            <div class="form-group">
                <label class="required-style">{{ $t('Section Name') }} </label>
                <input class="form-control" placeholder="Section" v-model="renameSection.name" />
                <small class="text-warning" v-if="isActionClicked && !renameSection.name">{{ $t('Required') }}</small>
            </div>
            <div class="form-group" v-if="renameSection.level > 1 && renameSection.object?.childs.length ==0">
                <label > {{ $t('Estimate Amount') }} </label>
                <input type="number" class="form-control" :placeholder="$t('Estimate Amount')" v-model="renameSection.expectedAmount" />
                <small class="text-warning" v-if="isActionClicked && !newSubSection.expectedAmount">{{ $t('Required') }}</small>
            </div>
            <template v-slot:actions>
                <button type="button" ref="closeRenameSectionModal" class="btn btn-outline-danger" data-bs-dismiss="modal">X {{$t('Cancel')}}</button>
                <button type="button" class="btn btn-primary" @click="RenameSection"><i class="fa-solid fa-save"></i> {{$t('Update')}}</button>
            </template>
        </Modal>
        <Modal idModal="BoQAddMainSectionModal" :headerTitle="$t('Add Main Section')" modalSize="modal-md" >
            <div class="form-group">
                <label class="required-style">{{ $t('Section Name') }} </label>
                <input class="form-control" :placeholder="$t('Section Name')" v-model="newSection.name" />
                <small class="text-warning" v-if="isActionClicked && !newSection.name">{{ $t('Required') }}</small>
            </div>
            <template v-slot:actions>
                <button type="button" ref="closeBoQAddMainSectionModal" class="btn btn-outline-danger" data-bs-dismiss="modal">X {{$t('Cancel')}}</button>
                <button type="button" class="btn btn-primary" @click="AddMainSection"><i class="fa-solid fa-save"></i> {{$t('Add')}}</button>
            </template>
        </Modal>
        <Modal idModal="BoQAddSubSectionModal" :headerTitle="$t('Add Sub Section')" modalSize="modal-md" >
            <div class="form-group">
                <label>{{ $t('Main Section Name') }} </label>
                <input class="form-control-plaintext text-capitalize" disabled :placeholder="$t('Main Section Name')" :value="newSubSection.mainName" />
            </div>
            <div class="form-group">
                <label class="required-style">{{ $t('Sub Section Name') }} </label>
                <input class="form-control" :placeholder="$t('Section Name')" v-model="newSubSection.name" />
                <small class="text-warning" v-if="isActionClicked && !newSubSection.name">{{ $t('Required') }}</small>
            </div>
            <div class="form-group">
                <label >{{ $t('Estimate Amount') }} </label>
                <input type="number" class="form-control" :placeholder="$t('Estimate Amount')" v-model="newSubSection.estimatedAmount" />
                <small class="text-warning" v-if="isActionClicked && !newSubSection.estimatedAmount">{{ $t('Required') }}</small>
            </div>
            <template v-slot:actions>
                <button type="button" ref="closeBoQAddSubSectionModal" class="btn btn-outline-danger" data-bs-dismiss="modal">X {{$t('Cancel')}}</button>
                <button type="button" class="btn btn-primary" @click="AddNewSubSection"><i class="fa-solid fa-save"></i> {{$t('Add')}}</button>
            </template>
        </Modal>
        <Modal idModal="BoQMarkUpUpdateModal" :headerTitle="$t('Update Section Mark Up')" modalSize="modal-md" >
            <div class="form-group">
                <label>{{ $t('Section Name') }}</label>
                <input class="form-control-plaintext text-capitalize" disabled placeholder="Section" :value="markUpUpdate.name" />
            </div>
            <div class="form-group">
                <label>{{$t('Mark Up')}} </label>
                <div class="input-group ">
                    <input type="number" class="form-control"  v-model="markUpUpdate.markUp" max="100" min="0"/>
                    <span class="input-group-text">%</span>
                </div>
                <small class="text-warning" v-if="isActionClicked && ( markUpUpdate.markUp < 0 || markUpUpdate.markUp > 100)">{{ $t('Required') }}</small>
            </div>
            <template v-slot:actions>
                <button type="button" ref="closeBoQMarkUpUpdateModal" class="btn btn-outline-danger" data-bs-dismiss="modal">X {{$t('Cancel')}}</button>
                <button type="button" class="btn btn-primary" @click="UpdateMarkUp"><i class="fa-solid fa-save"></i> {{$t('Save')}}</button>
            </template>
        </Modal>
    </div>
</template>


<script>
import BreadcrumbsProject  from '@/components/projectInformation/BreadcrumbsProject.vue'
import {GetHighLevelBoq, ExportHighLevelBoqLineItems, ImportHighLevelBoqLineItems,DownloadTemplate1,
     GetApprovalItemHighLevelEstimates, GetHighLevelProject, GetHighLevelBoqLineItemsByBits,
     DeleteHleNewBoqCreatorSection, UpdateHleNewBoqCreatorSectionSequence, UpdateHleNewBoqCreatorSectionMarkUp,
     GetHleNewBoqCreatorSections, AddHleNewBoqCreatorSection, UpdateHleNewBoqCreatorSectionName} from '@/actions/boqActions.js'
import {LinkList, ApprovalStageBoqObj, ExportTemplates, ApprovalStageBoq, ReformatNumber} from '@/helpers/utilities.js'
import  {mapActions, mapGetters} from 'vuex'
import ButtonTemplateDownloader from '@/components/boq/ButtonTemplateDownloader.vue'
import Modal from '@/components/layout/Modal.vue'
import BoqSectionRow from '@/components/boq/BoqSectionRow.vue'
import FigureCurrencyNote from '@/components/layout/FigureCurrencyNote.vue'
export default {
    name : 'HleBoqBuilder',
    mounted :async function(){
        const self = this
        const boqItem = await GetHighLevelBoq(self.boqId)
        self.boqItem = boqItem
        self.boqUrlname = boqItem.name  + ' - (' + boqItem.currencyId.sign +') '  + boqItem.currencyId.shortName  +' '+ boqItem.currencyId.name
        self.ChangeActiveLink(LinkList.HIGH_LEVEL_ESTIMATES)
        self.hleProject = await GetHighLevelProject(self.hleProjectId)
        await self.ReloadData()

        await self.CheckIfHaveUnassigned()
       
        if(boqItem.status==ApprovalStageBoq.FOR_APPROVAL){
            self.approvalItem = await GetApprovalItemHighLevelEstimates({boqId : boqItem._id})
        }
    },
    components : {
        BreadcrumbsProject,  Modal, ButtonTemplateDownloader, BoqSectionRow,
        FigureCurrencyNote
    },
    computed : {
        ...mapGetters(['boqCurrency']),
        IsQuotationPublished(){
            return false
        }
    },
    methods :{
        async CheckIfHaveUnassigned(){
            const self = this
            const getUnassingedLineItem = await GetHighLevelBoqLineItemsByBits({
                page : 1,
                id :  self.boqId,
                costCode : '',
                area : '',
                skip : 0,
                take : 1
            })
            
            if(getUnassingedLineItem.length!=0){
                self.haveUnassignedLineItems = true
            }
        },
        ...mapActions(['ChangeActiveLink', 'GetCurrencyListRun']),
        ReformatNumber,
        OpenImportModal(){
            this.newBoqImport = {
                importTemplateType : 1,
            }
            this.$refs.uploadFile.value = ''
        },
        async ExportBoqList(e){
            const btn = e.target
            const self = this
            btn.disabled = true
            if(self.exportTemplateType <=2 && self.exportTemplateType>=1)
            {
                const status = await ExportHighLevelBoqLineItems(self.boqItem._id,self.exportTemplateType,self.boqItem.name)
                if(status==400 || status==500){
                    self.$swal(self.$i18n.t('Export Template'),self.$i18n.t('Error in Exporting Template'),'warning')
                }
                self.$refs.closeModalImportBoq.click()
                btn.disabled = false
            }
            else
            {
                self.$swal(self.$i18n.t('Export Template'),self.$i18n.t('Template not yet implemented'),'warning')
                btn.disabled = false
            }
        },
        async ImportBoqList(e){
            let data = new FormData()
            const self = this

            if(self.isImportRunning){
                return
            }

            const file = self.$refs.uploadFile.files[0]
            const newData = self.newBoqImport
            const btn = e.target

            self.isImportClicked = true
            newData.file = ''
            if(!file ){
                return
            }
            newData.file = 'new file'

            if(newData.importTemplateType===''){
                return
            }

            data.append('id',self.boqItem._id)
            data.append('type',newData.importTemplateType)
            data.append('template',file)

            self.isImportRunning = true
            self.isImportClicked = false
            btn.disabled = true
            if(newData.importTemplateType==1){
                const uploaded = await ImportHighLevelBoqLineItems(data)
                self.isImportRunning = false
                btn.disabled = false
                if(uploaded=='OK'){
                    self.ReloadData()
                    self.$refs.closeModalImportBoq.click()
                    await self.CheckIfHaveUnassigned()
                }
                else{
                    self.$swal(self.$i18n.t('Import Template'),uploaded,'warning')
                }
            }else{
                self.isImportRunning = false
                btn.disabled = false
                this.$swal(self.$i18n.t('Import Template'),self.$i18n.t('Sorry not yet implemented'),'warning')
            }
        },
        OpenAddItemModal(){
            this.$refs.ModalNewLineItem.ClearData()
        },
        DownloadTemplate(){
            const self = this
            if(self.newBoqImport.importTemplateType==1)
                DownloadTemplate1()
            else
                self.$swal(self.$i18n.t('Download Template'),self.$i18n.t('Sorry not yet implemented'),'warning')
        },
        SendForApproval(){
            this.$router.push({name : 'HleApprovalSetup', query : { boqId : this.boqItem._id}})
        },
        async ReloadData(){
            const self = this
            const dataSections = await GetHleNewBoqCreatorSections(self.boqId) 
            self.sectionList = dataSections.sections
            self.overAllTotal = dataSections.overAllTotal
        },
        OpenAddNewSection(){
            this.newSection = {} 
        },
        async AddMainSection(){
            const self = this
            const data = self.newSection
            
            self.isActionClicked = true
            if(!data.name){
                return
            }
            self.isActionClicked = false

            const created = await AddHleNewBoqCreatorSection({
                boqId : self.boqId,
                name : data.name,
                level : 1,
                sequence : self.sectionList.length, 
            })
            if(typeof created!=='object'){
                self.$swal(self.$i18n.t('Add Main Section'),created,'warning')
                return
            }

            self.sectionList.push({
                id : created._id,
                name : data.name,
                level : 1,
                childs : []
            })
            self.$refs.closeBoQAddMainSectionModal.click()
        },
        OpenAddNewSubSection(element){
            this.newSubSection = {
                parentId : element.id,
                mainName : element.name,
                parent : element,
                level : element.level + 1
            }
        },
        async AddNewSubSection(){
            const self = this
            const data = self.newSubSection
            
            const created = await AddHleNewBoqCreatorSection({
                boqId : self.boqId,
                name : data.name,
                level : data.level,
                sequence : data.parent.childs.length,
                estimatedAmount : data.estimatedAmount,
                parent :  data.parent.id
            })
            if(typeof created!=='object'){
                self.$swal(self.$i18n.t('Add Sub Section'),created,'warning')
                return
            }

            data.parent.childs.push(
                {
                    id : created._id,
                    name : data.name,
                    level : data.level,
                    childs : []
                }
            )
            await self.ReloadData()
            
            self.$refs.closeBoQAddSubSectionModal.click()
        },
        DeleteMain(element,parent){
            const self = this
            self.$swal({
                title: self.$i18n.t('Delete Section'),
                text : element.name + ' ' + self.$i18n.t('Will be deleted'),
                showCancelButton: true,
                icon: 'warning',
                showLoaderOnConfirm : true,
                allowOutsideClick: () => !self.$swal.isLoading(),
                preConfirm : async ()=>{
                    return await DeleteHleNewBoqCreatorSection({id : element.id, boqId : self.boqId, level :element.level })
                }
            }).then(async (res)=>{
                if(res.isConfirmed){
                    if(res.value=='OK'){
                        self.$swal(self.$i18n.t('Delete Section'),self.$i18n.t('Deleted Successfully'),'success')
                        if(parent){
                            const getIndex = parent.childs.findIndex(u=>u.id==element.id)
                            if(getIndex!=-1){
                                parent.childs.splice(getIndex,1)
                            }
                        }else{
                            const getIndex = self.sectionList.findIndex(u=>u.id==element.id)
                            if(getIndex!=-1){
                                self.sectionList.splice(getIndex,1)
                            }
                        }

                        const getUnassingedLineItem = await GetHighLevelBoqLineItemsByBits({
                            page : 1,
                            id :  self.boqId,
                            costCode : '',
                            area : '',
                            skip : 0,
                            take : 1
                        })
                        if(getUnassingedLineItem.length!=0){
                            self.haveUnassignedLineItems = true
                        }

                    }else{
                        self.$swal(self.$i18n.t('Delete Section'),res.value,'warning')
                    }
                }
            })
        },
        OpenRename(element){
            this.renameSection = {
                id : element.id,
                level : element.level,
                name : element.name,
                expectedAmount : element.expectedAmount,
                object : element
            }
        },
        async RenameSection(){
            const self = this
            const data = self.renameSection
            self.isActionClicked = true
            if(!data.name){
                return
            }
            self.isActionClicked = false

            const updated = await UpdateHleNewBoqCreatorSectionName({
                id : data.id,
                boqId : self.boqId,
                level : data.level,
                expectedAmount : data.expectedAmount,
                name : data.name
            })
            
            if(updated!='OK'){
                self.$swal(self.$i18n.t('Update Section'),updated,'warning')
                return
            }

            await self.ReloadData()

            self.$refs.closeRenameSectionModal.click()
        },
        OnDragEnd(e){
            // Check if the target is a parent or child element
            let isParent = e.to.classList.contains("parent")
            if (isParent) {
                return false
            }
        },
        OnEnd(){
            this.haveMoved = true
        },
        CancelSaveSequence(){
            window.location.reload()
        },
        async SaveSequences(){
            const self = this

            //get the rows
            const data = JSON.parse(JSON.stringify(self.sectionList))
            let toBeUpdated = []

            let duplicateList = []

            const LoopData = async (rows, parentId)=>{
                let promise  = []
                for(let x = 0; x < rows.length; x++ ){
                    let row = rows[x]

                    const haveDuplicates = rows.find(u=>u.name.toLowerCase().trim()==row.name.toLowerCase().trim() && u.id!=row.id)
                    if(haveDuplicates){
                        if(duplicateList.findIndex(u=>u.toLowerCase().trim()==row.name.toLowerCase().trim())==-1){
                            duplicateList.push(row.name)
                        }
                    }

                    if(row.sequence!=x || parentId!=row.parent){
                        toBeUpdated.push({
                            id : row.id,
                            name : row.name,
                            parent : parentId,
                            level : row.level,
                            sequence : x
                        })
                    }
                    row.sequence = x
                    row.parent = parentId
                    if(row.childs.length!=0){
                        promise.push(LoopData(row.childs,row.id))
                    }
                }
                await Promise.all(promise)
            }
            await LoopData(data)
            const titleSwal = self.$i18n.t('Save Sequence')
            if(duplicateList.length!=0){
                
                self.$swal(titleSwal,  self.$i18n.t('Section(s)') + ' ' + duplicateList.join(',') 
                + '  ' + self.$i18n.t('have identical names within their parent section'),'warning')
                return
            }

            if(toBeUpdated.length!=0){
                const updated = await UpdateHleNewBoqCreatorSectionSequence({
                    boqId : self.boqId,
                    sections : toBeUpdated
                })

                if(updated!='OK'){
                    self.$swal(titleSwal, updated,'warning')
                }else{
                    self.$swal(titleSwal, self.$i18n.t('Updated Successfully'),'success')
                    self.sectionList = data
                    self.haveMoved = false
                }
            }
            

        },
        OpenMarkUp(element){
            this.markUpUpdate = {
                id : element.id,
                name : element.name,
                markUp : (element?.expectedMarkUp * 100) || 0,
            }
        },
        async UpdateMarkUp(){
            const self = this
            const data = self.markUpUpdate
            
            self.isActionClicked = true
            if(data.markUp < 0 || data.markUp > 100){
                return
            }
            self.isActionClicked = false

            const updated = await UpdateHleNewBoqCreatorSectionMarkUp({
                id : data.id,
                boqId : self.boqId,
                markUp : data.markUp
            })
            const swalTitle = self.$i18n.t('Update Section Mark Up')
            if(updated!='OK'){
                self.$swal(swalTitle,updated,'warning')
                return
            }
            self.$swal(swalTitle, self.$i18n.t('Updated Successfully'),'success')
            await self.ReloadData()


            self.$refs.closeBoQMarkUpUpdateModal.click()
        },
    },
    data(){
        return {
            hleProject : {},
            hleProjectId : this.$route.params.id ,
            lineItems : [],
            boqItem : {
                status : 1,
                currencyId  : {
                    sign : '',
                }
            },
            sectionList : [],
            boqUrlname : '',
            boqId : this.$route.params.boqId,
            ApprovalStageBoqObj : ApprovalStageBoqObj,
            ExportTemplates : ExportTemplates,
            exportTemplateType : 0,
            newBoqImport: {
                importTemplateType : 0
            },
            isImportRunning : false,
            isImportClicked : false,
            approvalItem : null,
            haveUnassignedLineItems : false,
            haveMoved : false,
            overAllTotal : {},
            isActionClicked : false,
            renameSection : {},
            newSection : {},
            newSubSection : {},
            markUpUpdate : {},
        }
    }
}
</script>