import {mapActions, mapGetters} from 'vuex'
import {measurementUnits} from '@/assets/dataJson/commercial_management.json'
import { LinkList, ReformatNumber,ComputeLineItem, SortFunc,LevelSort,ExportTemplates, ModuleActionList ,BoQColumnList, GetDistinctValuesObject} from '@/helpers/utilities.js'
import StepItem2 from '@/components/layout/StepItem2.vue'
import ModalNewLineItem from '@/components/boq/ModalNewLineItem.vue'
import ModalUpdateLineItem from '@/components/boq/ModalUpdateLineItem.vue'
import ModalImportLineItems from '@/components/boq/ModalImportLineItems.vue'
import ModalLineItemColumnFilter from '@/components/boq/ModalLineItemColumnFilter.vue'
import BoqLineItemRow from '@/components/boq/BoqLineItemRow.vue'
import BoqLineItemRowHeader from '@/components/boq/BoqLineItemRowHeader.vue'
import BoqLineItemRowThead from '@/components/boq/BoqLineItemRowThead.vue'
import BreadcrumbsProject  from '@/components/projectInformation/BreadcrumbsProject.vue'
import Modal  from '@/components/layout/Modal.vue'
import Select2 from '@/components/layout/Select2.vue'
import Loader from '@/components/layout/Loader.vue'
import {GetCostCodeChilds,GetConsolidatedLineItems, 
    SearchAreaFromProject,GetOfficeIncrementals, DownloadTemplate1, SearchBoqFromProject, SearchQuotationList,DownloadConsolidatedBoqExport, GetConsolidatedLineItemsBits,
    ExportBoqConsolidatedCostXLayout, ExportBoqConsolidatedFiveDLayout, ExportBoqConsolidatedRolledUpLayout, AddLineItemChilds } from '@/actions/boqActions.js'
import Multiselect from 'vue-multiselect'
import {SearchAllActiveCostCodes} from '@/actions/projectActions.js'
import FigureCurrencyNote from '@/components/layout/FigureCurrencyNote.vue'
export default {
    name : 'BoqConsolidated',
    mounted : async function(){
        const self = this
        const projectId = self.$route.params.id
        self.ChangeActiveLink(LinkList.CONSOLIDATED_LIST)
        if(!self.projectInfo._id){
            await self.GetProjectInfo(projectId).catch()
        }
        // self.lineItems = await GetConsolidatedLineItems(self.lineItemsParams)
        self.lineItemsParams.skip = 0
        self.lineItems = []
        await self.GetListByBites()
        self.areaList = (await SearchAreaFromProject({id : projectId , type: 2})).sort(SortFunc)
        self.subCategList = (await GetOfficeIncrementals(self.projectInfo.officeId)).sort(LevelSort)
        self.boqList = await SearchBoqFromProject({search : '', id : projectId})
        self.quotationList = await SearchQuotationList({search : '', id : projectId})
        self.SearchCostCodeAll('')
        const lineItemCols =  window.localStorage.getItem('lineItemColumns')
        if(lineItemCols){
            this.selectedColumns = lineItemCols.split(',').map(Number)
        }

        const getMainSections = self.lineItems.map(u=>{
            return {
                _id : u.parents[0].costCodeId,
                costName : u.parents[0].costCode + ' ' +u.parents[0].name
            }
        }) 
        const getSubSections = self.lineItems.map(u=>{
            return {
                _id : u.parents[1].costCodeId,
                costName : u.parents[1].costCode + ' ' +u.parents[1].name
            }
        }) 
        // const getLineItemCostCode = self.lineItems.map(u=>{
        //     return {
        //         _id : u.costCodeId._id,
        //         costName : u.costCode + ' ' +u.name
        //     }
        // })
        
        let combined = [...getMainSections,...getSubSections]
        let mainAndSubCostCodes = GetDistinctValuesObject(combined)
        self.toggledCategories.push(mainAndSubCostCodes.map(u=>u._id))
        
        self.isLoadingLineItems = false
    },
    computed : {
        ...mapGetters(['projectInfo', 'projectUrl','permission','userInfo']),
        UpdateLineItemCompute(){
            const self =this
            const u = self.updateLineItem

            return ComputeLineItem(u.quantity, u.bufferQuantity, u.costRate, u.bufferCostRate, u.markUp, u.margin)
        },
        SearchedArealist(){
            const self = this
            if(!self.searchArea){
                return self.areaList
            }
            return self.areaList.filter(u=>u.indexOf(self.searchArea.trim().toLowerCase())>-1)
        },
        SearchCostCodeList(){
            const self =this
            let parent  = {
                costCodeId : ''
            } 
            if(!self.searchCostCode){
                return self.subCategList.map(u=>{
                    let newParent = false
                    if(parent.costCodeId!=u.parent.costCodeId){
                        parent = u.parent
                        newParent = true
                    }
                    return {
                        u,
                        parent :newParent ? parent : null
                    }
                })
            }
            const copySearch = self.searchCostCode.toLowerCase().trim()
            
            return self.subCategList.filter(u=>u.costCode.toLowerCase().indexOf(copySearch)>-1 || u.name.toLowerCase().indexOf(copySearch)>-1).map(u=>{
                let newParent = false
                if(parent.costCodeId!=u.parent.costCodeId){
                    parent = u.parent
                    newParent = true
                }
                return {
                    u,
                    parent :newParent ? parent : null
                }
            })
        },
        CostCodeList(){
            const self = this 
      
            if(self.lineItems.length==0){
                 return []
             }
      
            let mainCateg = { costCodeId : '' }
            let subCateg = { costCodeId : '' }
      
            let fieldListMain = {
                 costTotal : 0,
                 supplyTotal : 0,
                 installTotal : 0,
                 costTotalBuffer : 0,
                 supplyAndInstallTotal : 0,
                 costTotalTotal : 0,
                 sellTotal : 0,
                 sellTotalBuffer : 0,
                 sellTotalTotal : 0,
                 gp : 0,
                 gpBuffer : 0,
                 gpTotal : 0,
                 index : 0
             }
      
             let fieldListSub = {
                 costTotal : 0,
                 supplyTotal : 0,
                 installTotal : 0,
                 costTotalBuffer : 0,
                 supplyAndInstallTotal : 0,
                 costTotalTotal : 0,
                 sellTotal : 0,
                 sellTotalBuffer : 0,
                 sellTotalTotal : 0,
                 gp : 0,
                 gpBuffer : 0,
                 gpTotal : 0,
                 index : 0
             }
      
            //  let totalList= []
            //  let totalListSub= []
             let parentLetter = 65
             let subCount = 1
             let lineItemSubCount = 1
      
            const arrayLength = self.lineItems.length
            
            let isEnglish = self.userInfo.language=='en'
      
            let ReformatData = self.lineItems.map((u,index)=>{
                 let showMainCateg = false
                 let showSubCatag = false
                 const nextLineItem = self.lineItems[index + 1]
                 const supplyAndInstallCombine = u.supplyAndInstall ? u.supplyAndInstall : (u.costRate || 0) + (u.installRate || 0)
                 const  supplyAndInstallTotal = supplyAndInstallCombine * u.quantity

                 let totalSub = null
                 let totalMain = null

                 if(!isEnglish){
                    u.parents[0].name = u.parents[0].nameCh
                    u.parents[1].name = u.parents[0].nameCh
                    u.name = u.nameCh
                }

                 //main category sum 
                 fieldListMain.costTotal += u.costTotalRaw
                 fieldListMain.supplyTotal += u.supplyTotal
                 fieldListMain.installTotal += u.installTotal
                 fieldListMain.costTotalBuffer += u.costTotal - u.costTotalRaw
                 fieldListMain.supplyAndInstallTotal += supplyAndInstallTotal
                 fieldListMain.costTotalTotal += u.costTotal
                 fieldListMain.sellTotal += u.sellTotalRaw
                 fieldListMain.sellTotalBuffer += u.sellTotal - u.sellTotalRaw
                 fieldListMain.sellTotalTotal += u.sellTotal
                 fieldListMain.gp += u.gpRaw
                 fieldListMain.gpBuffer +=  u.gp - u.gpRaw
                 fieldListMain.gpTotal += u.gp
             
                 //sub category sum
                 fieldListSub.costTotal += u.costTotalRaw
                 fieldListSub.supplyTotal += u.supplyTotal
                 fieldListSub.installTotal += u.installTotal
                 fieldListSub.costTotalBuffer += u.costTotal - u.costTotalRaw
                 fieldListSub.supplyAndInstallTotal += supplyAndInstallTotal
                 fieldListSub.costTotalTotal += u.costTotal
                 fieldListSub.sellTotal += u.sellTotalRaw
                 fieldListSub.sellTotalBuffer += u.sellTotal - u.sellTotalRaw
                 fieldListSub.sellTotalTotal += u.sellTotal
                 fieldListSub.gp += u.gpRaw
                 fieldListSub.gpBuffer +=  u.gp - u.gpRaw
                 fieldListSub.gpTotal += u.gp
      
      
                 if(u.parents[0].costCodeId!=mainCateg.costCodeId){
                     showMainCateg = true
                     mainCateg = u.parents[0]
                     mainCateg.label = String.fromCharCode(parentLetter)
                     ++parentLetter
                     subCount = 1
                     fieldListMain.index = index
                 }
             
                 if(u.parents[1].costCodeId!=subCateg.costCodeId){
                     showSubCatag = true
                     subCateg = u.parents[1]
                     subCateg.label = mainCateg.label + subCount
                     ++subCount
                     lineItemSubCount = 1
                     fieldListSub.index = index
                 }
                 u.label = subCateg.label +'.'+ lineItemSubCount
                 ++lineItemSubCount
                 u.bufferCostTotal = u.costTotal - u.costTotalRaw
                 u.bufferSellRate = u.sellRate - u.sellRateRaw
                 u.bufferSellTotal = u.sellTotal - u.sellTotalRaw
                 u.bufferGp = u.gp - u.gpRaw
                 u.supplyAndInstallTotal = supplyAndInstallTotal
             
                 //main category
                 if(index + 1==arrayLength){
                    totalMain = {
                        label : mainCateg.label,
                        costTotal : fieldListMain.costTotal ,
                        supplyTotal : fieldListMain.supplyTotal ,
                        installTotal : fieldListMain.installTotal ,
                        costTotalBuffer : fieldListMain.costTotalBuffer,
                        supplyAndInstallTotal : fieldListMain.supplyAndInstallTotal,
                        costTotalTotal : fieldListMain.costTotalTotal ,
                        sellTotal : fieldListMain.sellTotal  ,
                        sellTotalBuffer : fieldListMain.sellTotalBuffer ,
                        sellTotalTotal : fieldListMain.sellTotalTotal ,
                        gp : fieldListMain.gp ,
                        gpBuffer : fieldListMain.gpBuffer ,
                        gpTotal : fieldListMain.gpTotal ,
                        index : fieldListMain.index,
                    }
                    // totalList.push({
                    //     costTotal : fieldListMain.costTotal ,
                    //     supplyTotal : fieldListMain.supplyTotal ,
                    //     installTotal : fieldListMain.installTotal ,
                    //     costTotalBuffer : fieldListMain.costTotalBuffer,
                    //     supplyAndInstallTotal : fieldListMain.supplyAndInstallTotal,
                    //     costTotalTotal : fieldListMain.costTotalTotal ,
                    //     sellTotal : fieldListMain.sellTotal  ,
                    //     sellTotalBuffer : fieldListMain.sellTotalBuffer ,
                    //     sellTotalTotal : fieldListMain.sellTotalTotal ,
                    //     gp : fieldListMain.gp ,
                    //     gpBuffer : fieldListMain.gpBuffer ,
                    //     gpTotal : fieldListMain.gpTotal ,
                    //     index : fieldListMain.index,
                    // })
                }else if(u.parents[0].costCodeId!=nextLineItem.parents[0].costCodeId){
                    totalMain = {
                        label : mainCateg.label,
                        costTotal : fieldListMain.costTotal ,
                        supplyTotal : fieldListMain.supplyTotal ,
                        installTotal : fieldListMain.installTotal ,
                        costTotalBuffer : fieldListMain.costTotalBuffer,
                        supplyAndInstallTotal : fieldListMain.supplyAndInstallTotal,
                        costTotalTotal : fieldListMain.costTotalTotal ,
                        sellTotal : fieldListMain.sellTotal  ,
                        sellTotalBuffer : fieldListMain.sellTotalBuffer ,
                        sellTotalTotal : fieldListMain.sellTotalTotal ,
                        gp : fieldListMain.gp ,
                        gpBuffer : fieldListMain.gpBuffer ,
                        gpTotal : fieldListMain.gpTotal ,
                        index : fieldListMain.index,
                    }
 
                    fieldListMain = {
                        costTotal : 0 ,
                        supplyTotal : 0 ,
                        installTotal : 0,
                        costTotalBuffer : 0,
                        supplyAndInstallTotal : 0,
                        costTotalTotal : 0,
                        sellTotal : 0  ,
                        sellTotalBuffer : 0,
                        sellTotalTotal : 0 ,
                        gp : 0 ,
                        gpBuffer : 0 ,
                        gpTotal : 0 ,
                        index : 0,
                    }
                }
                //sub category
                if(index + 1==arrayLength){
                    totalSub = {
                        label : subCateg.label ,
                        costTotal : fieldListSub.costTotal ,
                        supplyTotal : fieldListSub.supplyTotal ,
                        installTotal : fieldListSub.installTotal ,
                        costTotalBuffer : fieldListSub.costTotalBuffer,
                        supplyAndInstallTotal : fieldListSub.supplyAndInstallTotal,
                        costTotalTotal : fieldListSub.costTotalTotal ,
                        sellTotal : fieldListSub.sellTotal  ,
                        sellTotalBuffer : fieldListSub.sellTotalBuffer ,
                        sellTotalTotal : fieldListSub.sellTotalTotal ,
                        gp : fieldListSub.gp ,
                        gpBuffer : fieldListSub.gpBuffer ,
                        gpTotal : fieldListSub.gpTotal ,
                        index : fieldListSub.index,
                    }
                    // totalListSub.push({
                    //     costTotal : fieldListSub.costTotal ,
                    //     supplyTotal : fieldListSub.supplyTotal ,
                    //     installTotal : fieldListSub.installTotal ,
                    //     costTotalBuffer : fieldListSub.costTotalBuffer,
                    //     supplyAndInstallTotal : fieldListSub.supplyAndInstallTotal,
                    //     costTotalTotal : fieldListSub.costTotalTotal ,
                    //     sellTotal : fieldListSub.sellTotal  ,
                    //     sellTotalBuffer : fieldListSub.sellTotalBuffer ,
                    //     sellTotalTotal : fieldListSub.sellTotalTotal ,
                    //     gp : fieldListSub.gp ,
                    //     gpBuffer : fieldListSub.gpBuffer ,
                    //     gpTotal : fieldListSub.gpTotal ,
                    //     index : fieldListSub.index,
                    // })
                }else if(u.parents[1].costCodeId!=nextLineItem.parents[1].costCodeId){
                    totalSub = {
                        label : subCateg.label ,
                        costTotal : fieldListSub.costTotal ,
                        supplyTotal : fieldListSub.supplyTotal ,
                        installTotal : fieldListSub.installTotal ,
                        costTotalBuffer : fieldListSub.costTotalBuffer,
                        supplyAndInstallTotal : fieldListSub.supplyAndInstallTotal,
                        costTotalTotal : fieldListSub.costTotalTotal ,
                        sellTotal : fieldListSub.sellTotal  ,
                        sellTotalBuffer : fieldListSub.sellTotalBuffer ,
                        sellTotalTotal : fieldListSub.sellTotalTotal ,
                        gp : fieldListSub.gp ,
                        gpBuffer : fieldListSub.gpBuffer ,
                        gpTotal : fieldListSub.gpTotal ,
                        index : fieldListSub.index,
                    }
 
                    fieldListSub = {
                        costTotal : 0 ,
                        supplyTotal : 0 ,
                        installTotal : 0,
                        costTotalBuffer : 0,
                        supplyAndInstallTotal : 0,
                        costTotalTotal : 0,
                        sellTotal : 0  ,
                        sellTotalBuffer : 0,
                        sellTotalTotal : 0 ,
                        gp : 0 ,
                        gpBuffer : 0 ,
                        gpTotal : 0 ,
                        index : 0,
                    }
                }
             
                 return {
                    lineItem : u,
                    mainCateg : showMainCateg ? mainCateg : null,
                    subCateg : showSubCatag ? subCateg : null,
                    totalMain : totalMain,
                    totalSub : totalSub,
                 }
            })

            //loop main totals
            // for(let x=0;x<totalList.length;x++){
            //      const data = totalList[x]
            //      ReformatData[data.index].mainCateg.totals = {}
            //      ReformatData[data.index].mainCateg.totals.costTotal = data.costTotal 
            //      ReformatData[data.index].mainCateg.totals.supplyTotal = data.supplyTotal 
            //      ReformatData[data.index].mainCateg.totals.installTotal= data.installTotal 
            //      ReformatData[data.index].mainCateg.totals.costTotalBuffer = data.costTotalBuffer
            //      ReformatData[data.index].mainCateg.totals.supplyAndInstallTotal = data.supplyAndInstallTotal
            //      ReformatData[data.index].mainCateg.totals.costTotalTotal = data.costTotalTotal 
            //      ReformatData[data.index].mainCateg.totals.sellTotal = data.sellTotal  
            //      ReformatData[data.index].mainCateg.totals.sellTotalBuffer = data.sellTotalBuffer 
            //      ReformatData[data.index].mainCateg.totals.sellTotalTotal = data.sellTotalTotal 
            //      ReformatData[data.index].mainCateg.totals.gp =data.gp 
            //      ReformatData[data.index].mainCateg.totals.gpBuffer = data.gpBuffer 
            //      ReformatData[data.index].mainCateg.totals.gpTotal = data.gpTotal 
            // }
            // for(let x=0;x<totalListSub.length;x++){
            //      const data = totalListSub[x]
            //      ReformatData[data.index].subCateg.totals = {}
            //      ReformatData[data.index].subCateg.totals.costTotal = data.costTotal 
            //      ReformatData[data.index].subCateg.totals.supplyTotal = data.supplyTotal 
            //      ReformatData[data.index].subCateg.totals.installTotal= data.installTotal 
            //      ReformatData[data.index].subCateg.totals.costTotalBuffer = data.costTotalBuffer
            //      ReformatData[data.index].subCateg.totals.supplyAndInstallTotal = data.supplyAndInstallTotal
            //      ReformatData[data.index].subCateg.totals.costTotalTotal = data.costTotalTotal 
            //      ReformatData[data.index].subCateg.totals.sellTotal = data.sellTotal  
            //      ReformatData[data.index].subCateg.totals.sellTotalBuffer = data.sellTotalBuffer 
            //      ReformatData[data.index].subCateg.totals.sellTotalTotal = data.sellTotalTotal 
            //      ReformatData[data.index].subCateg.totals.gp =data.gp 
            //      ReformatData[data.index].subCateg.totals.gpBuffer = data.gpBuffer 
            //      ReformatData[data.index].subCateg.totals.gpTotal = data.gpTotal 
            //  }
      
      
            return ReformatData
        },
        ClientViewList(){
            const self = this 
            self.refreshComputed
            const arrayLength = self.lineItems.length

            if(arrayLength==0){
                return []
            }
 
            let mainCateg = { costCodeId : '' }
            let subCateg = { costCodeId : '' }

            let fieldListMain = {
                total : 0,
                index : 0
            }

            let fieldListSub = {
                total : 0,
                index : 0
            }

            let parentLetter = 65
            let subCount = 1
            let lineItemSubCount = 1

            let newFormat =[]
            let newFormatIndex = 0

            
            const remapJson = JSON.parse(JSON.stringify(self.lineItems))

            let isEnglish = self.userInfo.language=='en'

            remapJson.map((u,index)=>{

                
                let showMainCateg = false
                let showSubCatag = false
                fieldListMain.total += u.sellTotal
                fieldListSub.total += u.sellTotal

                let totalMain = null
                let totalSub = null

                if(!isEnglish){
                    u.parents[0].name = u.parents[0].nameCh
                    u.parents[1].name = u.parents[0].nameCh
                    u.name = u.nameCh
                }

                if(u.parents[0].costCodeId!=mainCateg.costCodeId&&!u.lineItemParent){
                    showMainCateg = true
                    mainCateg = u.parents[0]
                    mainCateg.label = String.fromCharCode(parentLetter)
                    ++parentLetter
                    subCount = 1
                    fieldListMain.index = newFormatIndex
                }

                if(u.parents[1].costCodeId!=subCateg.costCodeId&&!u.lineItemParent){
                    showSubCatag = true
                    subCateg = u.parents[1]
                    subCateg.label = mainCateg.label + subCount
                    ++subCount
                    lineItemSubCount = 1
                    fieldListSub.index = newFormatIndex
                }
                u.label = subCateg.label +'.'+ lineItemSubCount
                lineItemSubCount++

                //for main category
                if(index + 1==arrayLength){
                    totalMain = {
                        label : u.parents[0].name,
                        total : fieldListMain.total,
                    }
                    fieldListMain.total = 0
                }else if(u.parents[0].costCodeId!=self.lineItems[index +1].parents[0].costCodeId){
                    totalMain = {
                        label : u.parents[0].name,
                        total : fieldListMain.total,
                    }
                    fieldListMain.total = 0
                }
                //for sup category
                if(index + 1==arrayLength){
                    totalSub = {
                        label : u.parents[1].name,
                        total : fieldListSub.total,
                    }
                    fieldListSub.total = 0
                }else if(u.parents[1].costCodeId!=self.lineItems[index +1].parents[1].costCodeId){
                    
                    totalSub = {
                        label : u.parents[1].name,
                        total : fieldListSub.total,
                    }
                    fieldListSub.total = 0
                }
                

                newFormat.push({
                    lineItem : u,
                    mainCateg : showMainCateg ? mainCateg : null,
                    subCateg : showSubCatag ? subCateg : null,
                    totalMain : totalMain,
                    totalSub : totalSub,
                })
                newFormatIndex++
                
 
            })

 
            return newFormat
        }
    },
    methods : {
        ...mapActions(['ChangeActiveLink', 'GetProjectInfo']),
        ToggleCategory(id){

            const getIndex = this.toggledCategories.indexOf(id)
            if(getIndex==-1){
                this.toggledCategories.push(id)
            }else{
                this.toggledCategories.splice(getIndex,1)
            }
        },
        GetCostCodes(search){
            return GetCostCodeChilds(search, this.projectInfo.officeId)
        },
        GetBoqsProjects(search){
            return SearchBoqFromProject({search : search, id : this.$route.params.id})
        },
        ClearField(type){
            if(type==1){
                this.selectedArea = []
            }
            else{
                this.selectedCostCode = []
            }
        },
        ReformatNumber : ReformatNumber,
        DownloadTemplate1 : DownloadTemplate1,
        async GetLineItemList(){
            const self = this

            SearchBoqFromProject({search : '', id : this.$route.params.id}).then((res)=>{
                self.boqList = res
            })

            SearchAreaFromProject({id : self.projectInfo._id , type: 2}).then(res=>{
                self.areaList = res.sort(SortFunc)
            })
            GetOfficeIncrementals(self.projectInfo.officeId).then(res=>{
                self.subCategList = res.sort(LevelSort)
            })
            // self.isLoadingLineItems = true
            // GetConsolidatedLineItems(self.lineItemsParams).then((res)=>{
            //     self.isLoadingLineItems = false
            //     self.lineItems = res
            // })
            self.lineItemsParams.skip = 0
            self.lineItems = []
            await self.GetListByBites()
        },
        async GetListByBites(){
            const self = this

            self.lineItems = []
            let willFetch = true
            self.lineItemsParams.skip = 0
            while(willFetch){
                const bits = await GetConsolidatedLineItemsBits(self.lineItemsParams)
                if(bits.length!=0){
                    self.lineItems.push(...bits)
                }
                if(bits.length==self.lineItemsParams.take){
                    self.lineItemsParams.skip += 50
                }else{
                    willFetch = false
                }
            }
        },
        OpenAddItemModal(){
            this.$refs.modalNewLineItem.ClearData()
        },
        //start of edit line item
        OpenEditLineItem(data){
            this.updateLineItem = JSON.parse(JSON.stringify(data))
            this.updateLineItem.id = data._id
            this.updateLineItem.markUp = this.updateLineItem.markUp * 100 
        },
        //start of import boq
        OpenImportModal(){
            this.$refs.modalImportLineItem.ClearData()

        },
        SelectParent(e, parentId){
            const isChecked = e.target.checked
            const self = this
            const getChilds = self.subCategList.filter(u=>u.parent.costCodeId==parentId).map(u=>u.costCodeId)
            
            self.selectedCostCode = self.selectedCostCode.filter(u=>getChilds.indexOf(u)<0)
            if(isChecked){
                self.selectedCostCode.push(...getChilds)
            }

            self.SearchChange()
        },
        SearchChange(){
            const self = this
            self.lineItemsParams.area = encodeURIComponent(JSON.stringify(self.selectedArea))
            self.lineItemsParams.costCode = encodeURIComponent(JSON.stringify(self.selectedCostCode))
            
            self.timerSetter(async function(){
                // self.isLoadingLineItems = true

                self.lineItemsParams.skip = 0
                self.lineItems = []
                await self.GetListByBites()
                // GetConsolidatedLineItems(self.lineItemsParams).then((res)=>{
                //     self.isLoadingLineItems = false
                //     self.lineItems = res
                // })
            })
            
        },
        PageChange(page){
            const self= this
            self.lineItemsParams.page = page
            self.isLoadingLineItems = true
            GetConsolidatedLineItems(self.lineItemsParams).then((res)=>{
                self.isLoadingLineItems = false
                self.lineItems = res
            })
        },
        timerSetter(callback){
            const self = this
            
            if(self.timer){
                clearTimeout(self.timer)
                self.timer = null
            }

            self.timer = setTimeout(callback,700)
        },
        timerSetterSearch(callback){
            const self = this
            
            if(self.timerSearch){
                clearTimeout(self.timerSearch)
                self.timerSearch = null
            }

            self.timerSearch = setTimeout(callback,700)
        },
        OpenEditDoubleClick(key){
            const btn = this.$refs.ModalUpdateLineItemBtn[key]
            btn.click()
        },
        DownloadConsolidatedBoq(){
            DownloadConsolidatedBoqExport(this.$route.params.id,this.projectInfo.projectNumber +' ' + this.projectInfo.projectName + ' Consolidated BoQ')
        },
        ChangeSelectecColumnValue(value){
            this.selectedColumns = value
        },
        ExportBoqList(e){
            const self = this
            const btn = e.target
            const projectId = this.$route.params.id
            btn.disabled = true
            if(self.exportTemplateType===0){
                DownloadConsolidatedBoqExport(projectId,this.projectInfo.projectNumber +' ' + this.projectInfo.projectName + ' Consolidated BoQ export').then(()=>{
                    btn.disabled = false
                })
            }else if(self.exportTemplateType==1){
                ExportBoqConsolidatedFiveDLayout(projectId, this.projectInfo.projectNumber +' ' + this.projectInfo.projectName + ' fiveD-export').then(()=>{
                    btn.disabled = false
                })
            }else if(self.exportTemplateType===2){
                ExportBoqConsolidatedCostXLayout(projectId, this.projectInfo.projectNumber +' ' + this.projectInfo.projectName + ' costX-export').then(()=>{
                    btn.disabled = false
                })
            }else{
                self.$swal(self.$i18n.t('Export'),self.$i18n.t('Sorry not yet implemented'),'warning')
                btn.disabled = false
            }
        },
        ExportForClient(e){
            const self = this
            const btn = e.target
            const projectId = self.$route.params.id
            btn.disabled = true
            ExportBoqConsolidatedRolledUpLayout(projectId, self.projectInfo.projectNumber +' ' + self.projectInfo.projectName + ' export').then(()=>{
                btn.disabled = false
            })
        },
        OnClickTr(data){
            const self = this
            const getIndex=  self.selectedLineItems.findIndex(u=>u._id==data._id)
            if(getIndex==-1){
                self.selectedLineItems.push({
                    _id : data._id,
                    costCode : data.costCode,
                    name : data.name,
                    description : data.description
                })
                self.selectedLineItemsId.push(data._id)
            }else{
                self.selectedLineItems.splice(getIndex,1)
                self.selectedLineItemsId.splice(getIndex,1)
            }
        },
        OpenGroupingParent(){
            this.selectedParent = this.selectedLineItemsId[0]
        },
        AddChildToParents(e){
            const self = this
            const btn = e.target

            if(self.isGroupExe){
                return
            }

            if(!self.selectedParent ||!self.selectedLineItemsId){
                return
            }

            if(self.selectedLineItemsId.length==0){
                return
            }

            self.isGroupExe = true
            btn.disabled = true
            AddLineItemChilds({parent : self.selectedParent, childs : self.selectedLineItemsId}).then(res=>{
                self.isGroupExe = false
                btn.disabled = false
                if(res!='OK'){
                    self.$swal('Group Line Item',res,'warning')
                }else{
                    const getIndexParent = self.lineItems.findIndex(u=>u._id==self.selectedParent)
                    if(getIndexParent>-1){
                        self.lineItems[getIndexParent].lineItemChilds = self.selectedLineItemsId
                    }


                    for(let x =0;x<self.selectedLineItemsId.length;x++){
                        let c = self.selectedLineItemsId[x]
                        if(c==self.selectedParent){
                            continue
                        }

                        const getIndexChild = self.lineItems.findIndex(u=>u._id==c)
                        if(getIndexChild!=-1){
                            self.lineItems[getIndexChild].lineItemParent = self.selectedParent
                        }
                    }

                    self.selectedParent = ""
                    self.selectedLineItemsId = []
                    self.selectedLineItems = []
                    self.refreshComputed++
                    self.$refs.closeGroupLineItems.click()
                    self.$swal('Group Line Item','Line Items Grouped Successfully','success')
                }
            })
        },
        RemoveParentData(id){
            const self = this
            const getIndexChild = self.lineItems.findIndex(u=>u._id==id)
            if(getIndexChild!=-1){
                self.lineItems[getIndexChild].lineItemParent = ''
                self.refreshComputed++
            }
        },
        SearchCostCodeAll(query){
            const self =  this
            self.searchCostCodeLoading = true
            self.timerSetter(function(){
                self.searchCostCodeLoading = false
                SearchAllActiveCostCodes(query).then((res)=>{
                    self.dataCostCodes = res
                })
            })
        }
    },
    watch: {
        selectedCostCode(val) {
            const self = this
            self.lineItemsParams.costCode = encodeURIComponent(JSON.stringify(val.map(u=>u._id)))
            self.lineItemsParams.area = encodeURIComponent(JSON.stringify(self.selectedArea))
            self.timerSetterSearch(async function(){
                self.lineItemsParams.skip = 0
                self.lineItems = []
                await self.GetListByBites()
            })

            return val.map(u=>u._id)
        },
        selectedArea(val){
            const self = this
            self.lineItemsParams.costCode = encodeURIComponent(JSON.stringify(self.selectedCostCode))
            self.lineItemsParams.area = encodeURIComponent(JSON.stringify(val))
            self.timerSetterSearch(async function(){
                self.lineItemsParams.skip = 0
                self.lineItems = []
                await self.GetListByBites()
            })

            return val
        }
    },
    components :{
        BreadcrumbsProject , StepItem2, Modal, Select2, Loader, ModalNewLineItem, 
        ModalUpdateLineItem, ModalImportLineItems, ModalLineItemColumnFilter,
        BoqLineItemRow,BoqLineItemRowHeader,BoqLineItemRowThead, Multiselect,FigureCurrencyNote
    },
    data() {
        return {
            toggledCategories : [],
            BoQColumnList ,
            timer : null,
            timerSearch : null,
            newLineItem : {
                unit : ''
            },
            isAddItemClicked : false,
            updateLineItem : {
                unit : '',
                parents : [
                    {
                        name : ''
                    },
                    {
                        name : ''
                    }
                ]
            },
            isUpdateItemClicked : false,
            newBoqImport : {},
            isImportClicked : false,
            // lineItems : {
            //     docs: [],
            //     totalPages : 0,
            // },
            lineItems : [],
            lineItemsParams : {
                id : this.$route.params.id,
                costCode : '',
                area : '',
                page  :1,
                skip : 0,
                take : 50
            },
            isLoadingLineItems : true, 
            measurementUnits : measurementUnits,
            searchArea : '',
            areaList : [],
            selectedArea : [],
            subCategList : [],
            selectedCostCode : [],
            searchCostCodeLoading : false,
            dataCostCodes : [],
            searchCostCode : '',
            openFilter : false,
            boqList : [],
            quotationList : [],
            selectedColumns : [],
            exportTemplateType : 0,
            ExportTemplates : ExportTemplates,
            selectedLineItems : [],
            selectedLineItemsId : [],
            selectedParent : "",
            isGroupExe : false,
            clientView : 1,
            refreshComputed : 0,
            ModuleActionList : ModuleActionList,
        } 
    }
}