<template>
    <div>
        <div>
            <BreadcrumbsProject
                :breadcrumbs="[
                    {
                        link : projectUrl +'/',
                        title : 'Projects',
                        isRouterLink: false
                    },
                    {
                        link : projectUrl +'/Project/' + projectInfo._id,
                        title : (projectInfo.projectNumber || '-') +' ' + (projectInfo.projectName || ''),
                        isRouterLink: false
                    },
                    {
                        link : '/boq/' + projectInfo._id,
                        title : 'Bill of Quantities',
                        isRouterLink: true
                    },
                    {
                        link : '/boq/items/' + boqItemData._id,
                        title :(boqItemData.name || '-')  + ' - (' + (boqItemData.currencyId?.sign || '') +') '  + ( boqItemData.currencyId?.shortName || '')  +' '+ (boqItemData.currencyId?.name || ''),
                        isRouterLink: true
                    },
                    
                    {
                        link : '/boq/items/' + boqItemData._id + '/preview',
                        title : 'Preview',
                        isRouterLink: true
                    }
                ]"
            ></BreadcrumbsProject>
        </div>
        <div class="card">
            <div class="card-header bg-white">
                <div class="d-flex justify-content-between mb-1">
                    <div class=" ps-1 p-1">
                    <small> Showing Preview :</small> <router-link :to="{name : 'BoqItems', params : {id : boqItemData._id} }"  class="small text-primary fw-bold">Go to Default</router-link>
                    </div>
                    <div>
                        <button class="btn btn-primary me-1" data-bs-toggle="modal" data-bs-target="#BoqMoveLineItemModal" @click="OpenModalMoveLineItems"
                        v-if="selectedLineItem.length!=0"><i class="fa-solid fa-up-down-left-right"></i> Move Line Items</button>
                        <button class="btn btn-default text-black me-1" @click="ReloadPage" v-if="movedLineItem.length!=0"><i class="fas fa-save"></i> Cancel </button>
                        <button class="btn btn-success me-1" @click="UpdateSequence" :disabled="movedLineItem.length==0" ><i class="fas fa-save"></i> Save Sequence </button>
                       
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="table-responsive" style="height:75vh">
                    <table class="table table-header-solid table-bordered" >
                        <thead class="sticky-top">
                            <tr >
                                <th class="sticky-left"></th>
                                <th class="sticky-left text-center">S/N</th>
                                <th style="min-width:500px;" class="sticky-left">Description</th>
                                <th style="min-width:180px;">Space ID</th>
                                <th>Unit</th>
                                <BoqLineItemRowThead 
                                :selectedColumns="selectedColumns" />
                                <th class="sticky-right"></th>
                            </tr>
                        </thead>
                        <Draggable itemKey="_id" v-model="LineItemData" tag="tbody" 
                        :animation="150" ghostClass="opacity-50"
                        :move="OnMove" handle=".handle" :onEnd="OnDragEnd">
                            <template #item="{element}" >
                                <tr v-if="element.totals">
                                    <td class="text-center bg-light-primary">
                                        <input type="checkbox" @click="CheckLineitemFromSubCategory" v-if="element.totals.isSub" :value="element._id" /> 
                                    </td>
                                    <td class="fw-bold bg-light-primary sticky-left text-center">
                                        {{element.sequence}}
                                    </td>
                                    <td class="fw-bold bg-light-primary sticky-left"  >
                                        <div class="d-flex justify-content-between">
                                            <span> 
                                                {{element.label}} 
                                            </span>
                                            <div>
                                                <small class="text-success" v-if="element.rowType==1" 
                                                @click="OpenSectionModal(element._id,element.label)" role="button" data-bs-toggle="modal" 
                                                data-bs-target="#BoqAddSubSectionModal"><i class="fa-solid fa-plus"></i> Add Section</small>
                                                <small class="text-success" v-if="element.rowType==2" role="button"
                                                @click="OpenAddItemModal(element._id)"
                                                data-bs-toggle="modal" data-bs-target="#ModalNewLineItem"
                                                ><i class="fa-solid fa-plus"></i> Add Item</small>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="bg-light-primary "></td>
                                    <td class="bg-light-primary "></td>
                                    <BoqLineItemRowHeader
                                    :data="element.totals"
                                    :selectedColumns="selectedColumns"
                                    :ReformatNumber="ReformatNumber" />
                                    <td class="bg-light-primary sticky-right fw-bold text-end" style="min-width:140px">
                                        

                                        <small class="text-info pe-1" v-if="element.rowType==2" role="button" data-bs-toggle="modal" data-bs-target="#BoqMoveSubSectionModal" 
                                        @click="OpenSubSectionModal(element._id,element.label)" ><i class="fa-solid fa-up-down-left-right"></i> Move</small>

                                        <small class="text-danger" v-if="!element.haveChilds" role="button" 
                                        @click="DeleteCategory(element._id,element.label,element.rowType)"><i class="fa-solid fa-trash-can"></i> Delete</small>
                                    </td>
                                </tr>
                                <tr v-else>
                                    <td class="sticky-left text-center pt-3" style="min-width:50px">
                                        <input type="checkbox" v-model="selectedLineItem" :value="element._id"/>
                                    </td>
                                    <td class=" fw-bold sticky-left  text-center">
                                        <span :class="{'d-none' : hideSeries}">{{element.sequence}}</span>
                                    </td>
                                    <td class="sticky-left bg-white handle cursor-move">
                                        <div>
                                            <div class="d-flex" >
                                                <div class="pt-2 ">
                                                </div>
                                                <div>
                                                    <small class="fw-bold ">  {{element.label}}</small> <br />
                                                    <span class="small mb-0 description-line-item">
                                                        {{element.description}}
                                                    </span>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td  >{{element.area}}</td>
                                    <td>{{element.unit}}</td>
                                    <BoqLineItemRow 
                                    :data="element" :ReformatNumber="ReformatNumber" :selectedColumns="selectedColumns" />
                                    <td class="bg-white sticky-right">
                                        
                                    </td>
                                </tr>
                            </template>
                        </Draggable>
                    </table>
                </div>
            </div>

        </div>
    </div>
    <Modal idModal="BoqMoveLineItemModal" headerTitle="Move Line Items" >
        <div>
            <div class="form-group">
                <label class="required-style">Select Sub Section </label>
                <Multiselect
                :options="subActiveCategories"
                v-model="moveLineItems.newSection"
                placeholder="Select Sub Section"
                label='costName'
                track-by='_id'
                :clear-on-select="false" 
                :max-height="300" 
                :internalSearch="true"
                />
                <small class="text-warning" v-if="clickedMoveLineItem && moveLineItems.newSection==null">Required</small> 
            </div>
            <div class="form-group">
                <label>Selected Items Count</label>
                <input class="form-control-plaintext" disabled :value="selectedLineItem.length"  />
            </div>
            <!-- <div>
                <small class="text-primary"><input type="checkbox" value="true" id="boq-seq-check-sub-to-delete" /> <label for="boq-seq-check-sub-to-delete">Delete left out Sub Section if empty</label> </small>
            </div> -->
        </div>
        <template v-slot:actions>
            <button type="button" ref="closeBoqPublishModal" class="btn btn-outline-danger" data-bs-dismiss="modal">X Cancel</button>
            <button type="button" class="btn btn-primary" @click="SaveMovedLineItems"  ><i class="fas fa-save"></i> Move Line Items</button>
        </template>
    </Modal>
    <Modal idModal="BoqAddSubSectionModal" headerTitle="Add Sub Section" >
        <div>
            <div class="form-group">
                <label>Main Section</label>
                <input class="form-control-plaintext" disabled :value="newSubSection.mainSectionName"  />
            </div>
            <div class="form-group">
                <label class="required-style">Select Sub Section </label>
                <Multiselect
                :options="subActiveCategories"
                v-model="newSubSection.newSection"
                placeholder="Select Sub Section"
                label='costName'
                track-by='_id'
                :clear-on-select="false" 
                :max-height="300" 
                :internalSearch="true"
                />
                <small class="text-warning" v-if="clickedSubSection && newSubSection.newSection==null">Required</small> 
            </div>
        </div>
        <template v-slot:actions>
            <button type="button" ref="closeBoqPublishModal" class="btn btn-outline-danger" data-bs-dismiss="modal">X Cancel</button>
            <button type="button" class="btn btn-primary" @click="SaveNewSection" ><i class="fas fa-save"></i> Add Sub Section</button>
        </template>
    </Modal>
    <Modal idModal="BoqMoveSubSectionModal" headerTitle="Move Sub Section" >
        <div>
            <div class="form-group">
                <label>Sub Section to Move</label>
                <input class="form-control-plaintext" disabled :value="moveSubSection.subSection"  />
            </div>
            <div class="form-group">
                <label class="required-style">Select Sub Section </label>
                <Multiselect
                :options="mainActiveCategories"
                v-model="moveSubSection.newMainSection"
                placeholder="Select Main Section"
                label='costName'
                track-by='_id'
                :clear-on-select="false" 
                :max-height="300" 
                :internalSearch="true"
                />
                <small class="text-warning" v-if="clickedMoveSubSection && moveSubSection.newMainSection==null">Required</small>
            </div>
            <div v-if="moveSubSection.newMainSection!=null">
                <small class="text-primary" v-if="BoqCategoryList.MainCategories.findIndex(u=>u.costCodeId==moveSubSection.newMainSection._id)==-1"><i class="fa-sharp fa-solid fa-circle-info"></i> Main Section will be Created</small>
            </div>
            <!-- <div>
                <small class="text-primary"><input type="checkbox" value="true" id="boq-seq-check-main-to-delete" /> <label for="boq-seq-check-main-to-delete">Delete left out Main Section if empty</label> </small>
            </div> -->
        </div>
        <template v-slot:actions>
            <button type="button" ref="closeBoqPublishModal" class="btn btn-outline-danger" data-bs-dismiss="modal">X Cancel</button>
            <button type="button" class="btn btn-primary" @click="MoveSubSection" ><i class="fas fa-save"></i> Move Sub Section</button>
        </template>
    </Modal>
    <ModalNewLineItem
     ref="modalNewLineItem" :parentSequence="seletedParentSequence"
    :ResetData="GetDataList" :boqId="boqItemData._id" />
</template>
<style scoped>
    .sticky-top{
        position : sticky;
        top:0;
        z-index: 100;
    }
    .sticky-left{
        position : sticky;
        left:0;
        z-index: 50;
    }
    .sticky-right{
        position : sticky;
        right:0;
        z-index: 50;
    }
    .line-item-100{
        min-width: 100px;
    }
    .cursor-move{
        cursor:  move;
    }
    .opacity-50{
        opacity: .5 !important;
    }
</style>

<script>
import ModalNewLineItem from '@/components/boq/ModalNewLineItem.vue'
import Modal from '@/components/layout/Modal.vue'
import 'dotenv/config'
import BreadcrumbsProject  from '@/components/projectInformation/BreadcrumbsProject.vue'
import {LinkList,ApprovalStageBoqObj, ReformatNumber} from '@/helpers/utilities.js'
import  {mapActions, mapGetters} from 'vuex'
import {GetItemsFromBoqByBits, GetCategoriesOfItemsFromBoq, UpdateLineItemSequence, GetCostCodeMainActives, UpdateMoveLineItemSequence,
 GetCostCodeSubActives, AddNewBoqSequence, MoveBoqSequence, DeleteSubSectionSequence,DeleteMainSectionSequence} from '@/actions/boqActions.js'
import BoqLineItemRow from '@/components/boq/BoqLineItemRow.vue'
import BoqLineItemRowThead from '@/components/boq/BoqLineItemRowThead.vue'
import BoqLineItemRowHeader from '@/components/boq/BoqLineItemRowHeader.vue'
import Draggable from 'vuedraggable'
import Multiselect from 'vue-multiselect'
export default {
    name : 'BoqItemsPreview',
    components : {BreadcrumbsProject ,BoqLineItemRow, BoqLineItemRowThead, ModalNewLineItem,
     BoqLineItemRowHeader, Draggable, Modal, Multiselect },
    data(){
        return {
            boqListParams : {
                id : this.$route.params.id,
                costCode : '',
                area : '',
                page : 1,
                take : 50,
                skip : 0,
                sort  :1,
            },
            seletedParentSequence : '',
            rowDatas : [],
            boqItemData : {
                _id : '1',
            },
            dataReady : false,
            projectUrl : process.env.VUE_APP_PROJECT_URL,
            boqUrl : process.env.VUE_APP_BOQ_URL,
            ApprovalStageBoqObj : ApprovalStageBoqObj,
            ApprovalStage :{},
            BoqCategoryList : {
                MainCategories : [],
                SubCategories : [],
            },
            LineItemData : [],
            selectedColumns  :[],
            movedLineItem : [],
            subActiveCategories : [],
            mainActiveCategories : [],
            newSubSection : {
                id : '',
                mainSectionName : '',
                newSection :null
            },
            clickedSubSection : false,
            moveSubSection : {
                id : '',
                subSection : '',
                newMainSection : null
            },
            clickedMoveSubSection : false,
            selectedLineItem : [],
            moveLineItems : {
                newSection : null,
            },
            clickedMoveLineItem : false,
            hideSeries : false
        }
    },
    watch : {
        movedLineItem: {
            handler() {
                this.hideSeries = true
            },
            deep: true
        },
    },
    mounted : async function(){
        const self = this
        self.ChangeActiveLink(LinkList.BILL_OF_QUANTITIES)
        const boqId = self.$route.params.id
        const boqItem = await self.GetBoqItemRun(boqId)
        if(boqItem==401){
            self.$router.push('/unauthorized')
            return
        }
        self.boqItemData = boqItem
        self.ApprovalStage = ApprovalStageBoqObj[boqItem.approvalStage - 1]
        if(!self.projectInfo._id){
            await self.GetProjectInfo(boqItem.projectId).catch()
        }
        self.BoqCategoryList = await GetCategoriesOfItemsFromBoq(boqId)
        self.GetListByBites()

        const lineItemCols =  window.localStorage.getItem('lineItemColumns')
        if(lineItemCols){
            this.selectedColumns = lineItemCols.split(',').map(Number)
        }

        self.subActiveCategories = await GetCostCodeSubActives()
        self.mainActiveCategories = await GetCostCodeMainActives()
        
    },
    computed : {
        ...mapGetters(['projectInfo'])
    },
    methods : {
        ...mapActions(['GetProjectInfo','ChangeActiveLink','GetBoqItemRun']),
        ReformatNumber : ReformatNumber,
        ReloadPage(){
            location.reload()
        },
        OpenAddItemModal(id){
            this.seletedParentSequence = id
            this.$refs.modalNewLineItem.ClearData()
        },
        OpenSectionModal(id,name){
            this.newSubSection = {
                id : id,
                mainSectionName : name,
                newSection : null
            }
        },
        OpenModalMoveLineItems(){
            const self = this

            self.moveLineItems = {
                newSection : null,
            }
        },
        SaveMovedLineItems(e){
            const self = this
            const newSection = self.moveLineItems.newSection
            const btn  = e.target
            if(!newSection){
                return
            }
            const newSectionId = newSection._id
            if(!newSectionId){
                return
            }
            const lineItems = self.selectedLineItem
            if(!lineItems.length){
                return
            }

            self.clickedMoveLineItem = true
            btn.disabled = false
            UpdateMoveLineItemSequence({
                boqId : self.boqListParams.id,
                newSectionId : newSectionId,
                lineItemIds : lineItems
            }).then((res)=>{
                self.clickedMoveLineItem = false
                if(res!='OK'){
                    self.$swal('Bill Of Quantites Sequence', res,'warning')
                }else{
                    self.$swal({
                            icon: 'success',
                            title: 'Bill Of Quantites Sequence',
                            text : 'Line items Moved, reloading please wait...',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    setTimeout(function(){
                        location.reload()
                    },1000)
                }
            })
        },
        SaveNewSection(e){
            const self = this
            const btn  = e.target
            self.clickedSubSection = true
            if(!self.newSubSection.newSection){
                return
            }
            const newSection = self.newSubSection.newSection._id
            if(!newSection){
                return
            }

            btn.disabled = true
            self.clickedSubSection = false
            //boqId, mainParent,costCodeId

            AddNewBoqSequence({
                boqId : self.boqListParams.id,
                mainParent : self.newSubSection.id,
                costCodeId : newSection
            }).then((res)=>{
                btn.disabled = false
                if(res!='OK'){
                    self.$swal('Bill Of Quantites Sequence', res,'warning')
                }else{
                    self.$swal({
                            icon: 'success',
                            title: 'Bill Of Quantites Sequence',
                            text : 'New Sub Section Added, reloading please wait...',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    setTimeout(function(){
                        location.reload()
                    },1000)
                }
            })

        },
        OpenSubSectionModal(id,name){
            this.moveSubSection  = {
                id : id,
                subSection : name,
                newMainSection : null
            }
        },
        MoveSubSection(e){
            const self = this
            const btn  = e.target
            self.clickedMoveSubSection = true
            if(!self.moveSubSection.newMainSection){
                return
            }
            const newSection = self.moveSubSection.newMainSection._id
            if(!newSection){
                return
            }

            btn.disabled = true
            self.clickedMoveSubSection = false
            //boqId, mainParent,costCodeId

            MoveBoqSequence({
                boqId : self.boqListParams.id,
                moveToMainCostCode : newSection,
                costCodeId : self.moveSubSection.id
            }).then((res)=>{
                btn.disabled = false
                if(res!='OK'){
                    self.$swal('Bill Of Quantites Sequence', res,'warning')
                }else{
                    self.$swal({
                            icon: 'success',
                            title: 'Bill Of Quantites Sequence',
                            text : 'Sub Section Moved, reloading please wait...',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    setTimeout(function(){
                        location.reload()
                    },1000)
                }
            })

        },
        SetupData(){
            let fieldListMain = {}
            let fieldListSub = {}
            let testData = []
            
            const mainCategs = this.BoqCategoryList.MainCategories
            const subCategsList = this.BoqCategoryList.SubCategories
            let parentLetter = 65
            let subCount = 1
            let lineItemSubCount = 1
            //loop out the main categories
            for(let i = 0;i<mainCategs.length;++i){
                let mainHaveSub = false
                //set the sum object
                fieldListMain = {
                    costTotal : 0,
                    supplyTotal : 0,
                    installTotal : 0,
                    costTotalBuffer : 0,
                    supplyAndInstallTotal : 0,
                    costTotalTotal : 0,
                    sellTotal : 0,
                    sellTotalBuffer : 0,
                    sellTotalTotal : 0,
                    gp : 0,
                    gpBuffer : 0,
                    gpTotal : 0,
                    index : testData.length
                }

                const mainCateg = mainCategs[i]
                //set the letter
                let currentLetter = String.fromCharCode(parentLetter)
                ++parentLetter
                testData.push({ _id: mainCateg.costCodeId, label : mainCateg.name,  rowType : 1, sequence : currentLetter })
                subCount = 1
                const subCategs = subCategsList.filter(u=>u.parentCostCodeId==mainCateg.costCodeId)
                for(let j =0; j< subCategs.length ;++j){
                    mainHaveSub = true
                    //set the object of sum for the sub category

                    fieldListSub = {
                        costTotal : 0,
                        supplyTotal : 0,
                        installTotal : 0,
                        costTotalBuffer : 0,
                        supplyAndInstallTotal : 0,
                        costTotalTotal : 0,
                        sellTotal : 0,
                        sellTotalBuffer : 0,
                        sellTotalTotal : 0,
                        gp : 0,
                        gpBuffer : 0,
                        gpTotal : 0,
                        index : testData.length,
                        isSub :true,
                    }
                    const subCateg = subCategs[j]
                    //push the data 
                    testData.push({ label : subCateg.name,  rowType : 2, _id : subCateg.costCodeId, sequence : currentLetter + subCount  })
                    const lineItems = this.rowDatas.filter(u=>u.sequenceParentCostCodeId ? u.sequenceParentCostCodeId==subCateg.costCodeId : u.parentCostCodeId==subCateg.costCodeId)
                    lineItemSubCount = 1
                    let haveChilds = false
                    for(let x = 0; x<lineItems.length ;x ++){
                        const lineItem = lineItems[x]
                        
                        const supplyAndInstallCombine = lineItem.supplyAndInstall ? lineItem.supplyAndInstall : (lineItem.costRate || 0) + (lineItem.installRate || 0)
                        const  supplyAndInstallTotal = supplyAndInstallCombine * lineItem.quantity
                        lineItem.label =  lineItem.name
                        lineItem.sequence = currentLetter + subCount + '.'+ lineItemSubCount
                        lineItem.bufferCostTotal = lineItem.costTotal - lineItem.costTotalRaw
                        lineItem.bufferSellRate = lineItem.sellRate - lineItem.sellRateRaw
                        lineItem.bufferSellTotal = lineItem.sellTotal - lineItem.sellTotalRaw
                        lineItem.bufferGp = lineItem.gp - lineItem.gpRaw
                        lineItem.supplyAndInstallTotal = supplyAndInstallTotal
                        lineItem.rowType = 3
                        testData.push(lineItem)
                        ++lineItemSubCount

                        //update the data of sub category sum object

                        fieldListSub.costTotal += lineItem.costTotalRaw
                        fieldListSub.supplyTotal += lineItem.supplyTotal
                        fieldListSub.installTotal += lineItem.installTotal
                        fieldListSub.costTotalBuffer += lineItem.costTotal - lineItem.costTotalRaw
                        fieldListSub.supplyAndInstallTotal += lineItem.supplyAndInstallTotal
                        fieldListSub.costTotalTotal += lineItem.costTotal
                        fieldListSub.sellTotal += lineItem.sellTotalRaw
                        fieldListSub.sellTotalBuffer += lineItem.sellTotal - lineItem.sellTotalRaw
                        fieldListSub.sellTotalTotal += lineItem.sellTotal
                        fieldListSub.gp += lineItem.gpRaw
                        fieldListSub.gpBuffer +=  lineItem.gp - lineItem.gpRaw
                        fieldListSub.gpTotal += lineItem.gp
                        haveChilds = true
                    }

                    // to total of the object 
                    testData[fieldListSub.index].totals = JSON.parse(JSON.stringify(fieldListSub))
                    testData[fieldListSub.index].haveChilds = haveChilds

                    fieldListMain.costTotal += fieldListSub.costTotal
                    fieldListMain.supplyTotal += fieldListSub.supplyTotal
                    fieldListMain.installTotal += fieldListSub.installTotal
                    fieldListMain.costTotalBuffer += fieldListSub.costTotalBuffer
                    fieldListMain.supplyAndInstallTotal += fieldListSub.supplyAndInstallTotal
                    fieldListMain.costTotalTotal += fieldListSub.costTotalTotal
                    fieldListMain.sellTotal += fieldListSub.sellTotal
                    fieldListMain.sellTotalBuffer += fieldListSub.sellTotalBuffer
                    fieldListMain.sellTotalTotal += fieldListSub.sellTotalTotal
                    fieldListMain.gp += fieldListSub.gp
                    fieldListMain.gpBuffer +=  fieldListSub.gpBuffer
                    fieldListMain.gpTotal += fieldListSub.gpTotal

                    ++subCount
                }
                testData[fieldListMain.index].totals = JSON.parse(JSON.stringify(fieldListMain))
                testData[fieldListMain.index].haveChilds = mainHaveSub

            }

            this.LineItemData = testData
            this.dataReady = true
        },
        GetListByBites(){
            const self = this
            GetItemsFromBoqByBits(self.boqListParams).then((res)=>{
                if(res.length!=0){
                    self.rowDatas.push(...res)
                }
                if(res.length == self.boqListParams.take){
                    self.boqListParams.skip += 50
                    self.GetListByBites()
                }else{
                    self.SetupData()
                }
            })
        },
        GetDataList(){
            this.dataReady = false
            this.rowDatas = []
            this.GetListByBites()
        },
        OnMove(e){
            const context = e.draggedContext
            if(context.futureIndex==0){
                return false
            }

            if(context.futureIndex==this.LineItemData.length-1){
                return true
            }

            const siblingFront = this.LineItemData[context.futureIndex + 1]
            const siblingBehind = this.LineItemData[context.futureIndex - 1]
            const moveTo = this.LineItemData[context.futureIndex]
            if(moveTo.rowType==1 && siblingBehind.rowType==2){
                return true
            }
            if((siblingFront.rowType==1 && moveTo.rowType==2) && siblingBehind.rowType!=1){
                return true
            }
            if(( siblingBehind.rowType==1 && moveTo.rowType==2) ||  moveTo.rowType==1){
                return false
            }
            //const movingItem = e.draggedContext.element
            // const getIndexOfMovingItem = this.dataChanges.findIndex(u=>u._id==movingItem._id)
            // if(getIndexOfMovingItem==-1){
            //     this.dataChanges.push({
            //         _id :movingItem._id,
                    
            //     })
            // }else{

            // }

            // this.GetChanges(true,)
            return true
        },
        OnDragEnd(e){
            if(e.newIndex==e.oldIndex){
                return
            }
            let newIndex = e.newIndex
            const getLastItem = this.LineItemData[e.newIndex - 1]
            if(getLastItem.rowType==1){
                const copy = this.LineItemData[e.newIndex]
                this.LineItemData[e.newIndex] = getLastItem
                this.LineItemData[e.newIndex - 1] = copy
                newIndex = newIndex - 1
            }

            const getBehindItem = this.LineItemData[newIndex - 1]
            const getFrontItem = this.LineItemData[newIndex + 1]
            const getItem = this.LineItemData[newIndex]
            if(getBehindItem.rowType==1){
                return false
            }
            let parentSequence = ''
            if(getBehindItem.rowType==2){
                parentSequence = getBehindItem._id
            }else{
                parentSequence = getBehindItem.sequenceParentCostCodeId ? getBehindItem.sequenceParentCostCodeId : getBehindItem.parentCostCodeId
            }

            let indexStack = 1

            if(getFrontItem && getBehindItem){
                if(getFrontItem.rowType==3){
                    indexStack = getFrontItem.sequenceStack - 1
                }else if(getBehindItem.rowType==3){
                    indexStack = getBehindItem.sequenceStack + 1
                }
            }else if(getBehindItem && !getFrontItem){
                if(getBehindItem.rowType==3){
                    indexStack = getBehindItem.sequenceStack + 1
                }
            }

            const getIndex = this.movedLineItem.findIndex(u=>u.id==getItem._id)
            if(getIndex==-1){
                this.movedLineItem.push({
                    id : getItem._id,
                    parentSequence : parentSequence,
                    index : indexStack,
                })
            }else{
                this.movedLineItem[getIndex].parentSequence =parentSequence
                this.movedLineItem[getIndex].index = indexStack
            }
            getItem.sequenceStack = indexStack
            console.log(indexStack)
            this.LineItemData[newIndex].sequenceParentCostCodeId = parentSequence

        },
        CheckLineitemFromSubCategory(e){

            let item = e.target.value

            const getLineItems = this.rowDatas.filter(u=>u.sequenceParentCostCodeId ? u.sequenceParentCostCodeId==item : u.parentCostCodeId==item).map(u=>u._id)
            this.selectedLineItem = this.selectedLineItem.filter(u=>getLineItems.indexOf(u)==-1)
            if(getLineItems.length!=0){

                if(e.target.checked){
                    this.selectedLineItem.push(...getLineItems)
                }
            }
        },
        AddToBeMoved(item){
            const getIndex = this.selectedLineItem.findIndex(u=>u==item)
            if(getIndex==-1){
                this.selectedLineItem.push(item)
            }else{
                this.selectedLineItem.splice(getIndex,1)
            }
        },
        DeleteCategory(id, name, type){
            if(type==1){
                this.DeleteMainCategory(id, name)
            }else{
                this.DeleteSubCategory(id, name)
            }
        },
        DeleteSubCategory(id, name){
            const self = this

            const checkOneLineItem = self.rowDatas.findIndex(u=>u.sequenceParentCostCodeId ? u.sequenceParentCostCodeId==id : u.parentCostCodeId==id)
            if(checkOneLineItem!=-1){
                self.$swal('Delete Sub Section','Sub Section with line items, cannot be deleted','warning')
            }

            self.$swal({
                title: 'Delete Sub Category',
                text : name + ' will be deleted',
                showCancelButton: true,
                icon: 'warning',
                confirmButtonText: 'Delete',
                showLoaderOnConfirm : true,
                allowOutsideClick: () => !self.$swal.isLoading(),
                preConfirm : ()=>{
                    return DeleteSubSectionSequence({boqId  : self.boqItemData._id, subSectionId : id })
                    .then((res)=>{
                        return res
                    })
                }
            }).then((res)=>{
                if(res.isConfirmed){
                    if(res.value=='OK'){
                        self.$swal('Delete Sub Section','Sub Section deleted Successfully','success')
                        const getIndex = self.LineItemData.findIndex(u=>u._id==id && u.rowType==2)
                        if(getIndex!=-1){
                            self.LineItemData.splice(getIndex,1)
                        }
                    }else{
                        self.$swal('Delete Sub Section',res.value,'warning')
                    }
                }
            })
        },
        DeleteMainCategory(id, name){
            const self = this

            self.$swal({
                title: 'Delete Main Category',
                text : name + ' will be deleted',
                showCancelButton: true,
                icon: 'warning',
                confirmButtonText: 'Delete',
                showLoaderOnConfirm : true,
                allowOutsideClick: () => !self.$swal.isLoading(),
                preConfirm : ()=>{
                    return DeleteMainSectionSequence({boqId  : self.boqItemData._id, mainSectionId : id })
                    .then((res)=>{
                        return res
                    })
                }
            }).then((res)=>{
                if(res.isConfirmed){
                    if(res.value=='OK'){
                        self.$swal('Delete Main Section','Main Section deleted Successfully','success')
                        const getIndex = self.LineItemData.findIndex(u=>u._id==id && u.rowType==1)
                        if(getIndex!=-1){
                            self.LineItemData.splice(getIndex,1)
                        }
                    }else{
                        self.$swal('Delete Main Section',res.value,'warning')
                    }
                }
            })
        },
        UpdateSequence(e){
            const self = this

            const btn  = e.target

            if(self.movedLineItem.length==0){ return }

            btn.disabled = true

            UpdateLineItemSequence({data : self.movedLineItem, boqId : self.boqItemData._id})
            .then((res)=>{
                btn.disabled = false
                if(res!='OK'){
                    self.$swal('Bill Of Quantites Sequence', res,'warning')
                }else{
                    self.$swal({
                            icon: 'success',
                            title: 'Bill Of Quantites Sequence',
                            text : 'Sequence Saved, reloading please wait...',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    setTimeout(function(){
                        location.reload()
                    },1000)
                }
            })

        }
    }
    
}
</script>