<template>
<Draggable itemKey="id"  :list="SectionList" tag="div" handle=".handle" :group="{ name: 'g1' }"  :options="options" :onEnd="onEndDrag" :move="OnDragEnd" :class="ClassLevel" :animation="150" ghostClass="opacity-50">
    <template #item="{element}">
        <div class="position-relative" >
            <div class=" d-flex small"  >
                <div :class="{ 'handle' : !IsQuotationPublished }" class="p-2 border border-end-0 border-top-0 text-center" style="width: 5%;">
                    <i class="fa-solid fa-up-down-left-right" v-if="!IsQuotationPublished"></i>
                    <span v-else>{{ element.label }}</span>
                </div>
                <div class=" p-2 border border-end-0 border-top-0 " :class="{'ps-4' : element.level==2,'ps-5' : element.level==3}" style="width: 30%;">
                    <span class="text-primary fw-bold text-capitalize" role="button" v-if="!IsQuotationPublished"
                    data-bs-toggle="modal" data-bs-target="#RenameSectionModal" @click="OpenRename(element)"
                    ><i class="fa-solid fa-edit" ></i> {{ element.name }}</span> 
                    <span class="fw-bold text-capitalize" v-else> {{ element.name }}</span>
                </div>
                <div class=" p-2 border border-end-0 border-top-0 text-end " :title="element.expectedAmount ? $t('Estimate Amount') : ''" :class="{'text-info fw-light' : element.expectedAmount , 'text-danger' : element.sum?.costTotal < 0}" style="width: 12%;">
                    {{ element.sum  ? ReformatNumber(element.sum.costTotal) : ''}}
                </div>
                <div class=" p-2 border border-end-0 border-top-0 text-end " :title="element.expectedAmount ? $t('Estimate Amount') : ''" :class="{'text-info fw-light' : element.expectedAmount, 'text-danger' : element.sum?.sellTotal < 0}" style="width: 12%;">
                    {{ element.sum  ? ReformatNumber(element.sum.sellTotal) : ''}}
                </div>
                <div class=" p-2 border border-end-0 border-top-0 text-end " :title="element.expectedAmount ? $t('Estimate Amount') : ''" :class="{'text-info fw-light' : element.expectedAmount, 'text-danger' : element.sum?.gp < 0}" style="width: 12%;">
                    {{ element.sum  ? ReformatNumber(element.sum.gp) : ''}}
                </div>
                <div class=" p-2 border border-end-0 border-top-0 text-end " :title="element.expectedAmount ? $t('Estimate Amount') : ''" :class="{'text-info fw-light' : element.expectedAmount, 'text-danger' : element.sum?.gpPercentage < 0}" style="width: 12%;">
                    {{ element.sum  ? ReformatNumber(element.sum.gpPercentage,0,2) + '%' : ''}}
                </div>
                <div class=" p-2 border border-top-0" style="width: 17%;">
                    <router-link v-if="(element.level==2 && element.childs.length==0) || element.level==3" 
                    :to="{name : breakdownTo.name ,params : {id : breakdownTo.id, boqId : breakdownTo.boqId, parent : element.id}}" 
                    class="fw-bold text-primary text-decoration-none small me-1">
                        <i class="fa-solid fa-list"></i>  {{ $t('Breakdown') }}
                    </router-link> 
                    <span v-if="(element.level==1 || element.level==2) && !IsQuotationPublished">
                        <small class="fw-bold text-success me-1 now-wrap"  role="button"  v-if="(element.level==2 && !element.sum) || element.level==1"  
                         data-bs-toggle="modal" data-bs-target="#BoQAddSubSectionModal"
                        @click="OpenAddNewSubSection(element)">
                            <i class="fa-solid fa-plus"></i> {{ $t('Section') }} 
                        </small>
                        <small class="fw-bold text-primary now-wrap" v-if="element.level==2 && element.childs.length==0" @click="OpenMarkUp(element)" 
                        data-bs-toggle="modal" data-bs-target="#BoQMarkUpUpdateModal"
                        role="button">
                            <i class="fa-solid fa-money-bill-trend-up"></i> {{ $t('Mark Up') }} 
                        </small>
                        <small class="fw-bold text-danger now-wrap"  @click="DeleteMain(element,Parent)" role="button">
                            <i class="fa-solid fa-trash-can"></i> {{ $t('Delete') }} 
                        </small>
                    </span>
                    <span v-if="element.level==3 && !IsQuotationPublished">
                        <small class="fw-bold text-primary now-wrap" @click="OpenMarkUp(element)"
                        data-bs-toggle="modal" data-bs-target="#BoQMarkUpUpdateModal"
                        role="button">
                            <i class="fa-solid fa-money-bill-trend-up"></i> {{ $t('Mark Up') }} 
                        </small>
                        <small class="fw-bold text-danger now-wrap" @click="DeleteMain(element, Parent)"  role="button">
                            <i class="fa-solid fa-trash-can"></i> {{ $t('Delete') }}
                        </small>
                    </span>
                </div>
            </div>
            <BoqSectionRow v-if="element.level!=0" :SectionList="element.childs" :Level="Level + 1"
            :OpenRename="OpenRename" :OpenAddNewSubSection="OpenAddNewSubSection" :DeleteMain="DeleteMain" :ToObject="ToObject"
            :Parent="element" :OnEnd="OnEnd" :BoqItem="BoqItem" :OpenMarkUp="OpenMarkUp" :IsQuotationPublished="IsQuotationPublished"></BoqSectionRow>
            <div class="d-flex small fw-bold bg-light" v-if="element.childs.length!=0">
                <div class="  p-2 border border-end-0 border-top-0" style="width: 5%;">
                </div>
                <div class=" text-end p-2 border border-end-0 border-top-0" style="width: 30%;">
                    <span class="fw-bold text-capitalize" :class="{'pe-5' : Level!=1}">{{ $t('Sub Total Of') }} {{ element.name }}</span>
                </div>
                <div class=" p-2 border border-end-0 border-top-0 text-end" style="width: 12%;" :class="{'text-danger' : element.subTotal?.costTotal < 0}">
                    {{ ReformatNumber(element.subTotal?.costTotal) }}
                </div>
                <div class=" p-2 border border-end-0 border-top-0 text-end" style="width: 12%;" :class="{'text-danger' : element.subTotal?.sellTotal < 0}">
                    {{ ReformatNumber(element.subTotal?.sellTotal) }}
                </div>
                <div class=" p-2 border border-end-0 border-top-0 text-end" style="width: 12%;" :class="{'text-danger' : element.subTotal?.gp < 0}">
                    {{ ReformatNumber(element.subTotal?.gp) }}
                </div>
                <div class=" p-2 border border-end-0 border-top-0 text-end" style="width: 12%;" :class="{'text-danger' : element.subTotal?.gpPercentage < 0}">
                    {{ ReformatNumber(element.subTotal?.gpPercentage,0,2) }}%
                </div>
                <div class=" p-2 border border-top-0" style="width: 17%;">
                </div>
            </div>
        </div>
    </template>  
</Draggable>
</template>

<style scoped>
.handle{
    cursor: move;
}
.opacity-50{
    opacity: .5 !important;
}
.now-wrap{
    white-space: nowrap;
}
</style>
<script>
import Draggable from 'vuedraggable'
import BoqSectionRow from '@/components/boq/BoqSectionRow.vue'
import {ReformatNumber} from '@/helpers/utilities.js'
export default{
    name : 'BoqSectionRow',
    mounted(){
        const self = this
        if(self.ToObject){
            self.breakdownTo = {
                name : 'HleBoqBuilderBreakdown',
                params : {
                    id : self.ToObject.id,
                    boqId : self.ToObject.boqId,
                }
            }
        }
        else{
            self.breakdownTo = {name : 'BoqBuilderBreakdown',params : {id : self.BoqItem._id}}
        }
    },
    computed : {
        ClassLevel(){
            if(this.Level==1){
                return 'parent'
            }
            else if(this.Level==2){
                return 'child'
            }
            else if(this.Level==3){
                return 'last'
            }

            return ''
            
        }
    },
    components : {
        Draggable, BoqSectionRow
    },
    props : {
        Parent : {
            type : Object,
            default : ()=>{}
        },
        SectionList : {
            type : Array,
        },
        Level : {
            type : Number,
        },
        OpenRename : {
            type : Function,
        },
        OpenAddNewSubSection : {
            type : Function,
        },
        DeleteMain : {
            type : Function,
        },
        OnEnd : {
            type : Function,
        },
        OpenMarkUp : {
            type : Function,
        },
        BoqItem : {
            type : Object,
            default : ()=>{}
        },
        IsQuotationPublished :{
            type : Boolean,
            default : false
        },
        ToObject : {
            type : Object,
            default : ()=>null
        }, 
    },
    methods : {
        ReformatNumber,
        OnDragEnd(e){
            if(this.Level==3){
                // Check if the target is a parent or child element
                if (!e.to.classList.contains("last")) {
                    return false
                }
            }
            else if(this.Level==2){
                // Check if the target is a parent or child element
                if (!e.to.classList.contains("child")) {
                    return false
                }
            }
            else if(this.Level==1){
                // Check if the target is a parent or child element
                if (!e.to.classList.contains("parent")) {
                    return false
                }
            }
            
        },
        onEndDrag(){
            this.OnEnd()
            
        }
    },
    data(){
        return {
            options : {
                group: 'nested',
            },
            breakdownTo : {}
        }
    }
}

</script>