<template>
    <div class="row bg-white p-4">
        <div class="col-sm-12 d-none d-print-block">
            <div class="float-end">
                <a><img src="https://staff.mmoseronelink.com/mmoser-associates.svg"  alt="" width="300" height="40"></a>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="d-flex">
                <span class="fw-bold">{{ $t('To') }}:</span>
                <div style="white-space: pre-line" >
                    {{quotationData.address}}
                </div>
            </div>
        </div>
        <div class="col-sm-6 ">
                <div class="float-end">
                <div>
                    <span class="fw-bold">{{ $t('Date') }}:</span> {{quotationData.contractDate ? ReformatDate(quotationData.contractDate) : '-'}}
                </div>
                <div >
                    <span class="fw-bold">{{$t('Quotation Module.QTN No')}}:</span>  
                    <span > {{quotationData.quotationNumber}}</span>
                </div>
            </div>
        </div>
        <div class="col-sm-12  ">
            <span class="fw-bold">{{$t('Attention')}}:</span>  
            <span class="text-capitalize" v-if="quotationData.attentionClient">{{quotationData.attentionClient.userId.fullname || '-' }}</span>
        </div>
        <div class="col-md-12 col-sm-12">
            <div class="mt-3 ms-3">
                <div >{{quotationData.quotationName}}</div>
                <div >{{$t('Quotation Module.Based on confirmed layout dated')}}{{FormatLayoutDate}}</div>
            </div>
        </div>
        <div class="col-md-12 col-sm-12">
            <div class="mt-3">
                <div>
                    <span class="fw-bold">{{$t('Quotation Module.RE')}}:</span>  
                    <span >{{quotationData.remark || 'N/A'}}</span>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import {ReformatDate} from '@/helpers/utilities.js'
    export default { 
        name : 'BoqQuotationHeaderReadOnly',
        props : ['quotationData'],
        methods :{
            ReformatDate,
        },
        computed :{
            FormatLayoutDate(){
                const self = this
                if(self.quotationData.layoutDate){
                    return ReformatDate(new Date(self.quotationData.layoutDate),"Do of MMMM, YYYY")
                }else{
                    return '-'
                }
            }
        }
    }
</script>