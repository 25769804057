import Modal from '@/components/layout/Modal.vue'
import Select2 from '@/components/layout/Select2.vue'
import RichTextEditor from '@/components/layout/RichTextEditor.vue'
import {ReformatDate, ProcessActionText, ProcessStepsActionTypes, ProcessActionTypes, ProcessItemStatusList} from '@/helpers/utilities.js'
import{SearchUserFromProjectDirectory,AddUserToStepProcessLinked,UserSkipProcess,StepSkipProcess,UserSendEmailProcess} from '@/actions/projectActions.js'
import {CheckSessionMsal} from '@/actions/userActions.js' 
import{EndApprovalBoq} from '@/actions/boqActions.js'
import 'dotenv/config'
export default {
    name : 'ProcessStepListView',
    props : ['stepList','relatedList','projectId','processId', 'GetProcessData', 'status','emailPreText','headerPretext', 'emailLink'],
    components : {
        Modal, Select2, RichTextEditor
    },
    mounted: function(){
        const self = this
        CheckSessionMsal().then(res=>{
            self.haveToken = res
        })
    },
    data(){
        return {
            openStep : '',
            ProcessActionText : ProcessActionText,
            ProcessStepsActionTypes : ProcessStepsActionTypes,
            newUser : {},
            ProcessActionTypes : ProcessActionTypes,
            ProcessItemStatusList : ProcessItemStatusList,
            sendEmailClicked : false,
            sendEmailData  : {
                id : '',
                message : '',
                subject : '',
                sendTo : ''
            },
            haveToken : false,
        }
    },
    computed : {
        classLi(){
            return this.stepList.length < 5 ? 'width-'+ this.stepList.length +'-per' : 'width-5-per'
        },
        GetStepList(){
            const self = this
            let currentStep = false
            const steps = self.stepList.map((u, index)=>{
                const isCompleted = u.action==ProcessStepsActionTypes.COMPLETED
                let myStep = false
                if(u.action==ProcessStepsActionTypes.PENDING&&currentStep==false){
                    currentStep = true
                    myStep = true
                }else{
                    myStep = false
                }
                return {
                    step : u,
                    class : isCompleted ? 'active ' + self.classLi : self.classLi ,
                    isCompleted : isCompleted,
                    userList : self.relatedList.filter(z=>z.roleId==u.roleId._id),
                    isCurrentStep : myStep,
                    isLast : self.stepList.length -1 ==index
                }
            })

            return steps
        }
    },
    methods: {
        clickStepper(item){
            this.openStep = this.openStep==item._id ? '' : item._id
        },
        ReformatDate(date){
            return ReformatDate(date)
        },
        GetUserFromDirectory(search){
           return SearchUserFromProjectDirectory(search,this.newUser.roleInt,this.projectId )
        },
        SkipStep(role,e){
            const self = this
            const btn = e.target
            self.$swal({
                title: 'Skip Step?',
                text : 'Proceed skipping '+ role.roleName + ' step?',
                showCancelButton: true,
                icon: 'warning',
                confirmButtonText: 'Skip',
                showLoaderOnConfirm : true,
                allowOutsideClick: () => !self.$swal.isLoading(),
                preConfirm : ()=>{
                    btn.disabled = true
                    StepSkipProcess(self.processId,role._id).then((res)=>{
                        btn.disabled = false
                        if(res!="OK"){
                            self.$swal('Skip Step',res,'warning')
                        }else{
                            self.GetProcessData()
                            self.$swal(role.roleName + ' skipped!', '', 'success')
                        }
                    })
                }
            }).then(()=>{
            })
        },
        EndApproval(e){
            const self = this
            const btn = e.target
            self.$swal({
                title: 'End Approval?',
                text : 'Proceed ending the approval of this BoQ?',
                showCancelButton: true,
                icon: 'warning',
                confirmButtonText: 'End',
                showLoaderOnConfirm : true,
                allowOutsideClick: () => !self.$swal.isLoading(),
                preConfirm : ()=>{
                    btn.disabled = true
                    EndApprovalBoq(self.processId).then((res)=>{
                        btn.disabled = false
                        if(res!="OK"){
                            self.$swal('End Approval',res,'warning')
                        }else{
                            self.GetProcessData()
                            self.$swal('Approval Ended', '', 'success')
                        }
                    })
                }
            }).then(()=>{
            })
        },
        SkipUser(user){
            const self = this
            self.$swal({
                title: 'Skip Step?',
                text : 'Proceed skipping '+ user.fullname + '?',
                showCancelButton: true,
                icon: 'warning',
                confirmButtonText: 'Skip',
                showLoaderOnConfirm : true,
                allowOutsideClick: () => !self.$swal.isLoading(),
                preConfirm : ()=>{
                    return UserSkipProcess(self.processId, user._id).then((res)=>{
                        console.log(res)
                        return res
                    })
                }
            }).then((res)=>{
                if(res.isConfirmed){
                    console.log(res)
                    if(res.value!="OK"){
                        self.$swal('Skip User',res,'warning')
                    }else{
                        self.GetProcessData()
                    }
                }
            })
        },
        ClickAddApprover(roleId){
            this.newUser.roleId = roleId._id
            this.newUser.roleInt = roleId.roleInt
            this.newUser.relatedUserId = ''
            this.$refs.UserFromDirectory.ClearData()
        },
        AddNewUserToProcess(e){
             const btn = e.target
            const self = this
            
            self.newUser.id = self.projectId,
            self.newUser.processId = self.processId

            if(!self.newUser.relatedUserId){
                return
            }

            btn.disabled = true
            AddUserToStepProcessLinked(self.newUser).then((res)=>{
                btn.disabled = false
                if(res=="OK"){
                    self.$refs.closeItemAddModalView.click()
                    self.GetProcessData()
                }else{
                    self.$swal('Add User to Step', res, 'warning')
                }
            })
        },
        OpenSendEmail(user){
            const self = this
            self.sendEmailData.sendTo = user._id
            self.sendEmailData.sendToEmail = user.email
            self.sendEmailData.message = self.emailPreText || ''
            self.sendEmailData.subject = self.headerPretext || ''
            self.$refs.emailModalTrigger.click()
            

            // let check = window.localStorage.getItem('z_emailz_check')
            // if(!check){
            //     window.open(process.env.VUE_APP_REST_MAIN_API + '/api/auth/token/email','_blank')
            //     window.localStorage.setItem('z_emailz_check',new Date().getTime() + 3600000)
            // }else{
            //     if(Number(check) < new Date().getTime()){
            //         window.open(process.env.VUE_APP_REST_MAIN_API + '/api/auth/token/email','_blank')
            //         window.localStorage.setItem('z_emailz_check',new Date().getTime() + 3600000)
            //     }else{
            //         self.$refs.emailModalTrigger.click()
            //     }
            // }
            // CheckSessionMsal().then(res=>{
            //     if(!res){
            //         window.open(process.env.VUE_APP_REST_MAIN_API + '/api/auth/token/email','_blank')
            //     }else{
            //         self.$refs.emailModalTrigger.click()
            //     }
            // })
        },
        AuthorizeUser(){
            const self= this
            window.open(process.env.VUE_APP_REST_MAIN_API + '/api/auth/token/email','_blank')
            setTimeout(function(){
                self.haveToken = true
            },2000)
        },
        SendEmail(e){
            const self = this

            CheckSessionMsal().then(res=>{
                if(!res){
                    self.$swal('Authorization Required', 'Please click the authorize button', 'warning')
                    self.haveToken = false
                }else{
                    const emailData = self.sendEmailData
                    const btn = e.target
                    self.sendEmailClicked = true
                    btn.disabled = false
                    emailData.id = self.processId
                    if(!emailData.message || !emailData.subject || !emailData.sendTo ){
                        return
                    }

                    self.sendEmailData.link = self.emailLink || ''
                    
                    self.sendEmailClicked = false
                    

                    btn.disabled = true
                    UserSendEmailProcess(self.sendEmailData).then(send=>{
                        btn.disabled = false
                        if(send=='OK'){
                            self.$swal('Email sent successfully','','success')
                        }else{
                            self.$swal('Email Send',send,'warning')
                        }
                    })
                }
            })

            
        }
    },

}