<template>
    <div class="bg-white" >
        <div class="row pt-2 d-print-none">
            <div class="col-md-3 ">
                <router-link :to="{name : 'HleQuotationApproval', params : {id : processItem._id}}" class="btn btn-default text-black d-print-none ms-3"> <i class="fa-solid fa-arrow-left-long"></i> Back To High Level Estimate</router-link>
            </div>
            <div class="col-md-6 text-center">
                <h5 class="fw-bold">{{quotationItem.name}} {{ $t('Bidding Module.Consolidated Detailed Estimates') }}</h5>
            </div>
            <div class="col-md-3 d-flex justify-content-end">
                <button @click="PrintPage"  class="btn btn-default text-black d-print-none me-3"> <i class="fa-solid fa-print"></i> {{ $t('Print') }}</button>
            </div>
        </div>
        <HleHeaderReadOnly :quotationItem="quotationItem" :officeList="officeList" />
        <div class="d-none d-print-block">
            <h6 class="bg-separator">{{ $t('Bidding Module.Consolidated Detailed Estimates') }}</h6>
        </div>
        <table class="table table-primary-bordered">
            <thead class="border-bottom-0">
                <tr class=" ">
                    <th>{{ $t('S/N') }}</th>
                    <th style="min-width:600px;width:600px;" class="">
                        {{ $t('Description') }}
                    </th>
                    <th class="" >{{ $t('Unit') }}</th>
                    <th  style="min-width:100px;width:100px;" class="">{{ $t('Space ID') }}</th>
                    <th class="text-center min-width-100" >{{ $t('Quantity') }}</th>
                    <th class="text-center min-width-100">{{ $t('Rate') }} ({{ currency.sign }})</th>
                    <th class="text-center min-width-100">{{ $t('Total') }} ({{currency.sign}})</th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="boqSections.length==0">
                    <td class="text-center" colspan="7">{{$t('No data Found')}}</td>
                </tr>
                <template v-for="(item,key) in boqSections" :key="key">
                    <tr class="bg-light fw-bold" >
                        <td></td>
                        <td class="fw-bold text-capitalize">{{ item.name }}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <BoqCreatorPrintViewRow :sections="item.data.sections" :IsOpenBook="false" />
                    <tr v-for="(lineItem,lineItemkey) in item.lineItems" :key="lineItemkey">
                        <td>
                            {{ lineItemkey + 1 }}
                        </td>
                        <td>
                            <div >
                                <div class="ps-5" >
                                    <span class="text-capitalize fw-bold" v-if="userInfo.language=='en'">{{lineItem.name}}</span>
                                    <span v-else>{{lineItem.nameCh}}</span>
                                    <br />
                                    <p class="small mb-0 description-line-item">
                                        {{lineItem.description}}
                                    </p>
                                </div>
                            </div>
                        </td>
                        <td>
                            {{lineItem.unit}}
                        </td>
                        <td>
                            {{lineItem.area}}
                        </td>
                        <td class="text-end">
                            <span v-if="!lineItem.rateOnly">
                                {{ReformatNumber(lineItem.totalQuantity,0,2)}}
                            </span>
                                <span class="text-danger" v-else>
                                RATE ONLY
                            </span>
                        </td>
                        <td class="text-end">
                                {{ReformatNumber(lineItem.sellRate)}}
                        </td>
                        <td class="text-end">
                            <span v-if="!lineItem.rateOnly">
                                {{ReformatNumber(lineItem.sellTotal)}}
                            </span>
                                <span class="text-danger" v-else>
                                -
                            </span>
                        </td>
                    </tr>
                    
                    <tr class="bg-light-primary fw-bold" v-if="item.data.sections.length==0">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td class="fw-bold text-capitalize text-end">{{ $t('Sub Total Of') }} {{ item.name }}</td>
                        <td class="text-end">{{ ReformatNumber(item.data.overAllTotal.sellTotal) }}</td>
                    </tr>
                </template>
                <tr class="bg-light-primary fw-bold" >
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="fw-bold text-capitalize text-end">{{ $t('Overall Total') }}</td>
                    <td class="text-end">{{ ReformatNumber(overAllTotal) }}</td>
                </tr>
                
            </tbody>
        </table>
    </div>
</template>
<style scoped>
    @media print {
        .bg-separator{
            font-weight: 600;
            padding-top  : 15px;
            background-color: #ffc000;
            print-color-adjust: exact;
            -webkit-print-color-adjust: exact;
            border-bottom: 1px solid #000;
        }
       
    }
    
</style>


<script>
import {GetHighLevelQuotationForApprovalProcess, GetHighLevelApprovalQuotationLineItemAttachmentsByBits, GetHighLevelQuotationBoQDetailed} from '@/actions/boqActions.js'
import {LinkList, ReformatNumber} from '@/helpers/utilities.js'
import  {mapActions,mapGetters} from 'vuex'
import {GetOffices} from '@/actions/userActions.js'
import HleHeaderReadOnly from '@/components/boq/HleHeaderReadOnly.vue'
import BoqCreatorPrintViewRow from '@/components/boq/BoqCreatorPrintViewRow.vue'
export default {
    name : 'HleQuotationDetailedSummary',
    mounted : async function(){
        const self = this

        self.ChangeActiveLink(LinkList.HIGH_LEVEL_ESTIMATES)
        const process = await GetHighLevelQuotationForApprovalProcess(self.approvalId)

        self.officeList = await GetOffices()
        self.processItem =  process.processItem
        self.quotationItem =  process.quotation
        self.boqListParams.id = self.approvalId
        await self.GetListByBits()

        self.boqSections = await GetHighLevelQuotationBoQDetailed(self.processItem.relatedId,self.processItem.relatedRevisionId)
        let overAllTotal = 0
        const findTheChild = (childs, boqId) =>{
            for(let i = 0; i < childs.length; i++){
                const getChild = childs[i]
                if(getChild.subTotal?.sellTotal!=0 && getChild.level!=1){
                    getChild.lineItems = self.lineItems.filter(u=>u.boqId==boqId && u.boqSequenceParentId==getChild.id)
                }

                if(getChild.childs.length!=0){
                    findTheChild(getChild.childs,boqId)
                }
                
            }
        }

        //add the line items
        for(let x =0; x<self.boqSections.length;x++)
        {
            const boq = self.boqSections[x]
            if(boq.data.sections.length==0){
                boq.lineItems = self.lineItems.filter(u=>u.boqId==boq._id)
            }
            else{
                findTheChild(boq.data.sections,boq._id)
            }
            overAllTotal += boq.data.overAllTotal.sellTotal
        }

        self.overAllTotal = overAllTotal
        self.currency = self.quotationItem.currencyId
       
    },
    components : {HleHeaderReadOnly, BoqCreatorPrintViewRow},
    methods : {
        ...mapActions(['ChangeActiveLink']),
        PrintPage(){
            window.print()
        },
        ReformatNumber,
        async GetListByBits(){
            const self = this

            self.lineItems = []
            let willFetch = true
            self.boqListParams.skip = 0

            while(willFetch){
                const bits = await GetHighLevelApprovalQuotationLineItemAttachmentsByBits(self.boqListParams)
                if(bits.length!=0){
                    self.lineItems.push(...bits)
                }
                if(bits.length==self.boqListParams.take){
                    self.boqListParams.skip += 50
                }else{
                    willFetch = false
                }
            }
        },
    },
    computed : {
        ...mapGetters(['userInfo'])
    },
    data(){
        return {
            boqSections : [],
            overAllTotal : 0,
            officeList : [],
            approvalId : this.$route.params.id,
            processItem : {},
            quotationItem : {},
            lineItems : [],
            boqListParams : {
                take : 50,
                skip : 0,
            },
            currency: { sign : ''}
        }
    }

}
</script>