<template>
    <div>
        <BreadcrumbsProject
          :breadcrumbs="[
            {
                link : '/high-level-estimates/approval/bill-of-quantities/' + approvalId,
                title : $t('Approval Module.Approval of') +' '+ (boqItem.name || '-' ) ,
                isRouterLink: true
            }
          ]"
      ></BreadcrumbsProject>
        <div class="pt-3">
            <BoqProcessWorkFlow
            v-if="approvalItem._id"
            :processItemData="approvalItem" 
            :showApprovalDisapprove="true"
            />
        </div>
        <div class="card ">
            <div class="card-header bg-white">
                <div class="row">
                    <div class="col-md-6">
                        <div >
                            <div >
                                <small class="text-muted">{{$t('Bidding Module.Detailed Estimates')}} : </small> 
                                <router-link :to="{name : 'HleBoQBuilder', params : {id : boqItem.hleProjectId, boqId : boqItem._id} }" class="text-capitalize text-primary fw-bold small"> 
                                    {{boqItem.name}}
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="text-end">
                            <div>
                                <small class="text-muted">{{ $t('Approval Module.Sent Date') }} :  </small> <small>{{ReformatDate(approvalItem.createdAt)}}</small>
                            </div>
                            <div>
                                <small class="text-muted">{{ $t('Approval Module.Expected End Date') }} :  </small> <small>{{ReformatDate(approvalItem.dateEnd)}}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <FigureCurrencyNote :currency="boqItem.currencyId" />
                <BoqSectionTableReadOnly
                    v-if="boqItem"
                    :rawSections="rawSections"
                    :boqItem="boqItem"
                    :approvalId="approvalItem._id"
                    :breakdownLink="breakdownLink"
                />
            </div>
        </div>
        
        <ProcessCommentList 
        :PageChangeComment="PageChangeComment"
        :processItem="approvalItem"
        :commentList="commentList"
        />
        <div v-if="CanApprove && approvalItem">
            <div class="p-4"></div>
            <div class="d-flex justify-content-end fixed-bottom pb-3 pe-3">
                <button class="btn btn-success me-1" data-bs-toggle="modal" data-bs-target="#BoqReviewApprove"> <i class="fa-solid fa-thumbs-up"></i> {{$t('Approve')}}</button>
                <button class="btn btn-danger" data-bs-toggle="modal" data-bs-target="#BoqReviewReject"><i class="fa-solid fa-thumbs-down"></i> {{$t('Approval Module.Approve')}}</button>
            </div>
        </div>
        <Modal idModal="BoqReviewApprove" :headerTitle="$t('Approve')"  >
            <div>
                <div class="form-group">
                    <label>{{ $t('Approval Module.Notes (optional)') }}: </label>
                    <textarea v-model="approveNotes" class="form-control"></textarea>
                </div>
                
            </div>
            <template v-slot:actions>
                <button type="button" ref="closeBoqReviewApprove" class="btn btn-outline-danger" data-bs-dismiss="modal">X {{$t('Cancel')}}</button>
                <button type="button" class="btn btn-primary" @click="Approve" >
                    <i class="fas fa-thumbs-up"></i> {{$t('Approve')}}
                </button>
            </template>
        </Modal>
        <Modal idModal="BoqReviewReject" :headerTitle="$t('Approval Module.Ask For Revision')"  >
            <div>
                <div class="form-group">
                    <label class="required-style">{{$t('Approval Module.Notes')}}: </label>
                    <textarea v-model="rejectNotes" class="form-control"></textarea>
                    <small class="text-danger" v-if="!rejectNotes">{{$t('Required')}}</small>
                </div>
                
            </div>
            <template v-slot:actions>
                <button type="button" ref="closeBoqReviewReject" class="btn btn-outline-danger" data-bs-dismiss="modal">X Cancel</button>
                <button type="button" class="btn btn-primary" @click="Disapprove" >
                    <i class="fas fa-thumbs-down"></i> {{$t('Approval Module.Ask For Revision')}}
                </button>
            </template>
        </Modal>
    </div>
</template>

<script>
import BreadcrumbsProject  from '@/components/projectInformation/BreadcrumbsProject.vue'
import {GetHighLevelBoq, GetHleNewBoqCreatorSectionsRevisions} from '@/actions/boqActions.js'  
import {GetWorkflowItem, GetCommentsList, UserApproveWorkFlow, UserDisapproveWorkFlow} from '@/actions/projectActions.js'
import {ReformatDate} from '@/helpers/utilities.js'
import ProcessCommentList  from '@/components/projectInformation/ProcessCommentList.vue'
import BoqProcessWorkFlow  from '@/components/projectInformation/BoqProcessWorkFlow.vue'
import Modal  from '@/components/layout/Modal.vue'
import {ProcessActionTypes} from '@/helpers/utilities.js'
import {mapGetters} from 'vuex'
import BoqSectionTableReadOnly  from '@/components/boq/BoqSectionTableReadOnly.vue'
import FigureCurrencyNote from '@/components/layout/FigureCurrencyNote.vue'
export default {
    name : 'HleBoqItemsApproval',
    computed : {
        ...mapGetters(['userInfo']),
        CanApprove(){
            const self = this
            const approvers = self.approvalItem.internalApprovers
            if(approvers.findIndex(u=>u.relatedUserId._id==self.userInfo._id && u.action==ProcessActionTypes.PENDING&& u.actionType==2) >-1){
                return true
            }

            return false
        }
    },
    components : {
        ProcessCommentList, BoqProcessWorkFlow, Modal
        , BoqSectionTableReadOnly, BreadcrumbsProject,FigureCurrencyNote
    },
    methods : {
        ReformatDate,
        Approve(e){
            const self = this
            const btn = e.target


            if(self.isApproveRunning){
                return
            }

            btn.disabled = true
            self.isApproveRunning = true
            UserApproveWorkFlow(self.approvalItem._id,self.approveNotes)
            .then((res)=>{
                self.isApproveRunning = false
                btn.disabled = false
                if(res!="OK"){
                    self.$swal(self.$i18n.t('Approval Module.Approve'),res.value,'warning')
                }else{
                    self.$swal({
                        icon: 'success',
                        title: self.$i18n.t('Approved successfully'),
                        showConfirmButton: false,
                        timer : 1000
                    })
                    location.reload()
                }
            })
        },
        Disapprove(e){
            const self = this
            const btn = e.target

            if(self.isDisapproveRunning){
                return
            }

            if(!self.rejectNotes){
                return
            }

            self.isDisapproveRunning = true
            btn.disabled = true
            UserDisapproveWorkFlow(self.approvalItem._id, self.rejectNotes)
            .then((res)=>{
                btn.disabled = false
                self.isDisapproveRunning = false
                if(res!="OK"){
                    self.$swal(self.$i18n.t('Approval Module.Ask For Revision'),res.value,'warning')
                }else{
                    self.$swal({
                        icon: 'success',
                        title: self.$i18n.t('Item marked Asked for Revision successfully'),
                        showConfirmButton: false,
                        timer : 1000
                    })
                    location.reload()
                }
            })
        },
        async PageChangeComment(page){
            const self = this
            self.commentFilterParam.page = page
            self.commentList = await GetCommentsList(self.commentFilterParam)
        },
    },
    mounted : async function(){
        const self = this

        self.approvalItem = await GetWorkflowItem(self.approvalId)
        self.rawSections = await GetHleNewBoqCreatorSectionsRevisions(self.approvalItem.relatedId,self.approvalItem.relatedRevisionId)
        
        self.isDataReadyToLoad = true
        self.boqItem = await GetHighLevelBoq(self.approvalItem.relatedId)
        self.commentList = await GetCommentsList(self.commentFilterParam)
        self.breakdownLink.params.id = self.approvalItem._id
        self.breakdownLink.query.revisionId = self.approvalItem.relatedRevisionId
    },
    data(){
        return {
            rawSections : {
                sectionList : [],
                overAllTotal :{}
            },
            approvalItem : {
                internalApprovers : [],
                createdBy : {}
            },
            approvalId : this.$route.params.id,
            boqItem : {
                _id : 1,
                currencyId : {}
            },
            selectedColumns : [],
            commentList : {
                docs : [],
                hasNextPage : false
            },
            commentFilterParam : {
                page : 1,
                id : this.$route.params.id,
            },
            isDataReadyToLoad : false,
            rejectNotes : '',
            approveNotes : '',
            isApproveRunning : false,
            isDisapproveRunning : false,
            breakdownLink : {name : 'HleBoqItemsApprovalBreakdown',params : {}, query : {}}
        }
    },

}
</script>