<template>
  <div>
        <button type="button" class="d-none"  ref="emailModalTrigger" data-bs-toggle="modal" data-bs-target="#SenderEmailModal"></button>
        <ul class="progressbar-custom ">
            <li v-for="(item,key) in GetStepList" :key="key" 
            :data-name="item.step.roleId.roleName"
            :class="item.class" 
            @click="clickStepper(item.step)" >
                <div class="card floating-card shadow  text-black z-index-300" v-if="openStep==item.step._id" @click.stop >
                    <div class="card-header bg-white">
                        <h6>Approvers</h6>
                    </div>
                    <div class="card-body">
                        <div class="row text-capitalize ">
                            <template v-for="(child, key) in item.userList" :key="key">
                                <div class="col-md-12">
                                    <div class="d-flex justify-content-end" >
                                    <!-- <div class="d-flex justify-content-end" > -->
                                        <small role="button" class="text-warning d-flex justify-content-end me-2"
                                        v-if="child.action<=ProcessActionTypes.VIEW && item.isCurrentStep && status==ProcessItemStatusList.PENDING"
                                         @click="SkipUser(child.relatedUserId)">  
                                            <i class="fa-solid fa-forward d-flex align-items-center "></i> 
                                        </small>
                                        <small role="button" v-if="status==ProcessItemStatusList.PENDING" class="text-primary d-flex justify-content-end" 
                                        title="Send Email" @click="OpenSendEmail(child.relatedUserId)" >  
                                            <i class="fa fa-envelope d-flex align-items-center "></i> 
                                        </small>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="d-flex justify-content-end fs-6 fw-normal">Name :</div>
                                </div>
                                <div class="col-md-8">
                                    <div class="d-flex align-items-center fs-6">{{child.relatedUserId.fullname}}</div>
                                </div>
                                <div class="col-md-4">
                                    <div class="d-flex justify-content-end fs-6 fw-normal">Action :</div>
                                </div>
                                <div class="col-md-8">
                                    <div class="d-flex align-items-center fs-6">{{ProcessActionText[child.action-1]}}</div>
                                </div>
                                <div class="col-md-4">
                                    <div class="d-flex justify-content-end fs-6 fw-normal">Date :</div>
                                </div>
                                <div class="col-md-8">
                                    <div class="d-flex align-items-center fs-6">{{ReformatDate(child.actionDate)}}</div>
                                </div>
                                <div class="col-md-4" v-if="child.action==ProcessActionTypes.SKIPPED">
                                    <div class="d-flex justify-content-end fs-6 fw-normal">Skipped By</div>
                                </div>
                                <div class="col-md-8" v-if="child.action==ProcessActionTypes.SKIPPED">
                                    <div class="d-flex align-items-center fs-6">{{child.createdBy.fullname}}</div>
                                </div>
                                <hr class="mt-3" />
                            </template>
                        </div>
                        <div v-if="status==ProcessItemStatusList.PENDING">
                            <div class="d-flex justify-content-between" v-if="!item.isCompleted">
                                <div>
                                    <small role="button" 
                                    @click="ClickAddApprover(item.step.roleId)"
                                    data-bs-toggle="modal" data-bs-target="#ProccessItemAddModalView"
                                    class="text-primary d-flex justify-content-end">  <i class="fa-solid fa-plus d-flex align-items-center "></i> Add Approvers</small>
                                </div>
                                <div v-if="item.isCurrentStep && !item.isLast">
                                    <small role="button" class="text-warning d-flex justify-content-end" @click="(e)=>SkipStep(item.step.roleId,e)" >  
                                        <i class="fa-solid fa-forward d-flex align-items-center "></i> Skip Step
                                    </small>
                                </div>
                            </div>
                            <div class="d-flex justify-content-end" v-else-if="item.isCompleted">
                                <small role="button" class="text-success d-flex justify-content-end" >  
                                    <i class="fa-solid fa-file-circle-check d-flex align-items-center "></i> COMPLETED
                                </small>
                            </div>
                            <div class="d-flex justify-content-end pt-3" v-if="item.isLast">
                                <small role="button" @click="EndApproval" class="text-warning d-flex align-items-center">
                                    <i class="fa-solid fa-hourglass-end"></i> End Approval
                                </small>
                            </div>
                            
                        </div> 
                        <div v-else>
                            <small class="text-success d-flex justify-content-end" >  
                                <i class="fa-solid fa-file-circle-check d-flex align-items-center "></i> Approval Ended
                            </small>
                        </div>
                        
                    </div>
                </div>
            </li>
        </ul>

        <Modal idModal="ProccessItemAddModalView" headerTitle="Add User to Process"  >
            <form>
                <div class="form-group">
                    <label class="required-style">User </label>
                    <Select2 
                    :filterQuery="GetUserFromDirectory"
                    indexValue="_id"
                    labelName="fullname"
                    placeHolder="Search User From Project"
                    v-model:optionValue="newUser.relatedUserId"
                    ref="UserFromDirectory"
                    ></Select2>
                    <small class="text-warning" v-if="!newUser.relatedUserId">Required</small>
                </div>
            </form>
            <template v-slot:actions>
                <button type="button" ref="closeItemAddModalView" class="btn btn-outline-danger" data-bs-dismiss="modal">X Cancel</button>
                <button type="button" class="btn btn-primary"  @click="AddNewUserToProcess"><i class="fa-solid fa-save"></i> Save Changes</button> 
        </template>
        </Modal>

        <Modal idModal="SenderEmailModal" headerTitle="Send Email" modalSize="modal-lg"  >
            <div class="form-group">
                    <label>Send To </label>
                    <input type="text" class="form-control-plaintext" readonly :value="sendEmailData.sendToEmail" />
            </div>
            <div class="form-group">
                <label class="required-style">Subject </label>
                <input type="text" class="form-control" v-model="sendEmailData.subject" placeholder="Subject of the email" />
                <small class="text-warning" v-if="sendEmailClicked && !sendEmailData.subject">Required</small>
            </div>
            <div class="form-group">
                <label class="required-style">Email Body </label>
                <textarea class="form-control" v-model="sendEmailData.message" placeholder="Body of the email"></textarea>
                <!-- <RichTextEditor v-model="sendEmailData.message" /> -->
                <small class="text-warning" v-if="sendEmailClicked && !sendEmailData.message">Required</small>
            </div>
            <template v-slot:actions>
                <button type="button" ref="closeSenderEmailModal" class="btn btn-outline-danger" data-bs-dismiss="modal">X Cancel</button>
                <div>
                    <button type="button" class="btn btn-info me-1" v-if="!haveToken" @click="AuthorizeUser"><i class="fa-solid fa-user-check"></i> Authorize</button> 
                    <button type="button" class="btn btn-primary" v-if="haveToken" @click="SendEmail"><i class="fa fa-envelope"></i> Send Email</button> 
                </div>
                
            </template>
        </Modal>
  </div>
</template>

<script src="@/assets/boq/components/ProcessStepList.js" />
<style scoped>
    .width-1-per{
        width:  50%;
    }
    .width-2-per{
        width:  50%;
    }
    .width-3-per{
        width:  30%;
    }
    .width-4-per{
        width:  25%;
    }
    .width-5-per{
        width:  20%;
    }
    .z-index-300 {
        z-index: 300;
    }
</style>
