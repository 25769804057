<template>
    <div>
        <BreadcrumbsProject
        :breadcrumbs="[
             {
                link : '/high-level-estimates',
                title : $t('Bidding'),
                isRouterLink: true
            },
            {
                link : '/high-level-estimates/' + hleProjectId + '/bill-of-quantities',
                title : (hleProject.projectName || '-' )+ ' - ' + $t('Bidding Module.Detailed Estimates'),
                isRouterLink: true
            },
            {
                link : '/high-level-estimates/' + hleProjectId + '/bill-of-quantities/' + boqId + '/history',
                title :boqUrlname + ' - ' + $t('History'),
                isRouterLink: true
            },
        ]"
        />
        <div class="row pt-2 pb-2">
            <div class="col-md-4">
                <button class="btn btn-primary ms-3" v-if="currentIndex!=0" @click="ChangeMinusIndex">
                    <i class="fa-solid fa-arrow-left-long fa-2x"></i>
                </button>
            </div>
            <div class="col-md-4">
                <div class="d-flex justify-content-center" >
                    <h4 class="fw-bold">{{$t('Revision')}} {{currentIndex}}</h4>
                </div>
            </div>
            <div class="col-md-4">
                <div class="d-flex justify-content-end" v-if="currentIndex < revisionList.length - 1" @click="ChangeAddIndex"> 
                    <button class="btn btn-primary me-3">
                        <i class="fa-solid fa-arrow-right-long fa-2x"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="card ">
            <div class="card-header bg-white">
                <div class="row">
                    <div class="col-md-6">
                        <div >
                            <div >
                                <small class="text-muted">{{ $t('Bidding Module.Detailed Estimate') }} : </small> 
                                <router-link :to="{name : 'HleBoQBuilder', params : {id : hleProjectId, boqId : boqItem._id} }"  class="small text-primary fw-bold text-capitalize"> {{boqItem.name}}</router-link>
                            </div>
                            <small class="text-muted">{{$t('Currency')}} :  </small> <small>{{boqItem.currencyId.sign}} {{boqItem.currencyId.name}}</small>
                        </div>
                    </div>
                    <div class="col-md-6">
                        
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div>
                    <FigureCurrencyNote :currency="boqItem.currencyId" />
                </div>
                <BoqSectionTableReadOnly
                    v-if="boqItem"
                    :rawSections="rawSections"
                    :boqItem="boqItem"
                    :breakdownLink="breakdownLink"
                />
            </div>
        </div>
    </div>
</template>

<script>
import BreadcrumbsProject  from '@/components/projectInformation/BreadcrumbsProject.vue'
import {GetHighLevelBoq, GetHighLevelProject,GetHleNewBoqCreatorSectionsRevisions} from '@/actions/boqActions.js' 
import {ReformatDate} from '@/helpers/utilities.js'
import { LinkList} from '@/helpers/utilities.js'
import {mapActions} from 'vuex'
import BoqSectionTableReadOnly  from '@/components/boq/BoqSectionTableReadOnly.vue'
import FigureCurrencyNote from '@/components/layout/FigureCurrencyNote.vue'
export default {
    name : 'HleBoqItemsHistory',
    components : {
        BreadcrumbsProject, BoqSectionTableReadOnly, FigureCurrencyNote
    },
    methods : {
        ...mapActions(['ChangeActiveLink']),
        async ChangeAddIndex(){
            const self = this
            self.boqListParams.skip = 0
            self.boqListParams.id = self.revisionList[++this.currentIndex]._id
            self.rawSections = await GetHleNewBoqCreatorSectionsRevisions(self.boqId,self.boqListParams.id)
            self.breakdownLink.query.revisionId = self.boqListParams.id
        },
        async ChangeMinusIndex(){
            const self = this
            self.boqListParams.skip = 0
            self.boqListParams.id = self.revisionList[--this.currentIndex]._id
            self.rawSections = await GetHleNewBoqCreatorSectionsRevisions(self.boqId,self.boqListParams.id)
            self.breakdownLink.query.revisionId = self.boqListParams.id
        }
    },
    mounted : async function(){
        const self = this

        self.boqItem = await GetHighLevelBoq(self.boqId)
        self.hleProject = await GetHighLevelProject(self.hleProjectId)
        self.revisionList  = self.boqItem.revisionList
        self.currentIndex = self.revisionList.length - 1
        const lastRevision = self.boqItem.revisionList[self.revisionList.length - 1]
        self.boqListParams.id = lastRevision._id
        self.boqListParams.boqId = self.boqId
        self.ChangeActiveLink(LinkList.HIGH_LEVEL_ESTIMATES)
        self.boqUrlname = self.boqItem.name  + ' - (' + self.boqItem.currencyId.sign +') '  + self.boqItem.currencyId.shortName  +' '+ self.boqItem.currencyId.name
        self.rawSections = await GetHleNewBoqCreatorSectionsRevisions(self.boqId,lastRevision._id)

        self.breakdownLink.params.id = self.hleProjectId
        self.breakdownLink.params.boqId = self.boqId
        self.breakdownLink.query.revisionId = lastRevision._id

    },
    data(){
        return {
            rawSections : {
                sectionList : [],
                overAllTotal :{}
            },
            hleProjectId : this.$route.params.id,
            hleProject : {},
            boqItem : {
                currencyId : {}
            },
            boqListParams : {},
            ReformatDate : ReformatDate,
            boqId : this.$route.params.boqId,
            isDataReadyToLoad : false,
            revisionList : [],
            boqUrlname : '',
            currentIndex : 0,
            breakdownLink : {name : 'HleBoqItemsHistoryBreakdown',params : {}, query : {}}
        }
    },

}
</script>