import  {mapActions, mapGetters} from 'vuex'
import StepItem2 from '@/components/layout/StepItem2.vue'
import {GetBoqRateTemplatesItems, GetCostCodeChilds, AddBoqRateTemplateItem, DownloadTemplateRateSystem,
UpdateBoqRateTemplateItem, DeleteBoqRateTemplateItem, AddBoqRateTemplateItemImport, ExportBoqRateTemplatesItems} 
from '@/actions/boqActions.js' 
import BreadcrumbsProject  from '@/components/projectInformation/BreadcrumbsProject.vue'
import Pagination from '@/components/layout/Pagination.vue'
import Select2 from '@/components/layout/Select2.vue'
import Modal  from '@/components/layout/Modal.vue'
import { LinkList, ReformatNumber, ExportTemplates, ModuleActionList } from '@/helpers/utilities.js'
import 'dotenv/config'

export default {
    name : 'BoqRatesItems',
    components : {
        StepItem2 , BreadcrumbsProject, Pagination, Modal, Select2
    },
    mounted : async function(){
        const self = this
        // const rateTemplateId = self.$route.params.id
        self.ChangeActiveLink(LinkList.BOQ_RATE_LIST)
        self.rateTemplate = await GetBoqRateTemplatesItems(self.filterParams)
        self.itemPagination = {
            page : 1,
        }

        await self.GetProjectInfo(self.rateTemplate.projectId).catch()
        self.isTableLoading = false
    },
    methods : {
        ...mapActions(['ChangeActiveLink','GetProjectInfo']),
        ReformatNumber : ReformatNumber,
        TimerSetter(callback){
            const self = this
            
            if(self.timer){
                clearTimeout(self.timer)
                self.timer = null
            }

            self.timer = setTimeout(callback,700)
        },
        PageChange(page){
            this.itemPagination.page = page
        },
        SearchTrigger(e){
            const self= this
            self.TimerSetter(function(){
                self.itemPagination.page = 1
                self.itemPagination.searchBar = e.target.value
            })
        },
        OpenEditRate(item){
            this.updateRate.name = item.costCode + ' '+ item.name
            this.updateRate.rateId = item._id
            this.updateRate.rate = item.rate
        },
        OpenAddRate(){
            this.newRate = {}
            this.$refs.selectCostCode.ClearData()
        },
        GetCostCodes(search){
            return GetCostCodeChilds(search)
        },
        AddNewRate(e){
            const self= this
            const btn = e.target

            self.isNewRateClicked = true
            self.newRate.id =  self.rateTemplateId
            if(!self.newRate.costCodeId || !self.newRate.rate){
                return
            }
            self.isNewRateClicked = false

            btn.disabled = true
            AddBoqRateTemplateItem(self.newRate).then(res=>{
                btn.disabled = false
                if(res=='OK'){
                    self.$refs.closeModalAddRate.click()
                    GetBoqRateTemplatesItems(self.filterParams).then((data)=>{
                        self.rateTemplate = data
                    })
                }else{
                    self.$swal('Add Rate',res,'warning')
                }
            })
        },
        EditNewRate(e){
            const self= this
            const btn = e.target

            self.isUpdateRateClicked = true
            self.updateRate.id =  self.rateTemplateId
            if(!self.updateRate.rate){
                return
            }

            self.isUpdateRateClicked = false
            btn.disabled = true

            UpdateBoqRateTemplateItem(self.updateRate).then(res=>{
                btn.disabled = false
                if(res=="OK"){
                    const getIndex = self.rateTemplate.rateList.findIndex(u=>u._id==self.updateRate.rateId)
                    if(getIndex!=-1){
                        self.rateTemplate.rateList[getIndex].rate = self.updateRate.rate
                    } 
                    self.$refs.closeModalEditRate.click()
                }else{
                    self.$swal('Edit Rate',res,'warning')
                }
            })
        },
        DeleteItem(e,item){
            const self = this
            const btn = e.target

            self.$swal({
                title: 'Delete Rate',
                text : 'Delete Rate from template?',
                showCancelButton: true,
                icon: 'warning',
                confirmButtonText: 'Delete',
                showLoaderOnConfirm : true,
                allowOutsideClick: () => !self.$swal.isLoading(),
                preConfirm : ()=>{
                    btn.disabled = true
                    return DeleteBoqRateTemplateItem({ id : self.rateTemplateId ,rateId : item._id }).then((res)=>{
                        btn.disabled = false
                        if(res!='OK'){
                            self.$swal('Delete Item',res,'warning')
                        }else{
                            const getIndex = self.rateTemplate.rateList.findIndex(u=>u._id==item._id)
                            if(getIndex!=-1){
                                self.rateTemplate.rateList.splice(getIndex,1)
                            }
                        }
                    }).catch()
                }
            }).then((res)=>{
                if(res.isConfirmed){
                    self.$swal('Rate Deleted', '', 'success')
                }
            })
        },
        ImportTemplate(e){
            const self = this
            const btn = e.target

            self.uploadTemplate.file = ''
            self.isUploadTemplateClicked = true
            

            const file = self.$refs.uploadFile.files[0]
            if(!file ){
                return
            }

            self.uploadTemplate.file = 'new file'

            if(self.uploadTemplate.templateType===''){
                return
            }

            self.isUploadTemplateClicked = false
            let data = new FormData()

            data.append('templateId',self.rateTemplateId)
            data.append('template',file)

            btn.disabled = true
            if(self.uploadTemplate.templateType===0){
                AddBoqRateTemplateItemImport(data).then((res)=>{
                    btn.disabled = false
                    if(res=="OK"){
                        self.$refs.closeBoqImportRateTemplate.click()
                        GetBoqRateTemplatesItems(self.filterParams).then((data)=>{
                            self.rateTemplate = data
                        })
                    }else{
                        self.$swal('Add Template',res,'warning')
                    }
                })
            }else{
                btn.disabled = false
                self.$swal('Import Template','Sorry not yet implemented','warning')
            }
            
        },
        OpenRateImport(){
            this.uploadTemplate = {
                templateType : ''
            }
            this.$refs.uploadFile.value = ''
        },
        ExportRateList(e){
            const self = this
            const btn = e.target
            btn.disabled = true
            if(self.templateType===0){
                ExportBoqRateTemplatesItems(self.rateTemplateId,self.rateTemplate.name).then((res)=>{
                    btn.disabled = false
                    if(res!='OK'){
                        self.$swal('Export Template',res,'warning')
                    }
                })
            }else{
                self.$swal('Export Template','Sorry not yet implemented','warning')
                btn.disabled = false
            }
            
        },
        DownloadTemplate(){
            if(this.uploadTemplate.templateType===0)
                DownloadTemplateRateSystem()
            else
                this.$swal('Download Template','Sorry not yet implemented','warning')
        }
        
    },
    computed : {
        ...mapGetters(['projectInfo','permission']),
        RateList(){
            const limit = 10
            const {page, searchBar} = this.itemPagination
            let slice = Math.abs(page-1)
            let list = this.rateTemplate.rateList

            if(this.rateTemplate.rateList.length==0){
                return {
                    docs : [],
                    page : 1,
                    totalPages : 1
                }
            }
            if(searchBar){
                list = list.filter(u=>u.name.indexOf(searchBar.trim().toLowerCase())>-1 || u.costCode.indexOf(searchBar.trim().toUpperCase())>-1 )

            }
            return {
                docs : list.slice(slice * limit,limit  * page),
                page : page,
                totalPages :  Math.ceil(list.length / 10) || 0
            }
        }
    },
    data (){
        return {
            isTableLoading : true,
            projectUrl : process.env.VUE_APP_PROJECT_URL,
            rateTemplateId : this.$route.params.id,
            rateTemplate : {
                rateList : []
            },
            timer : null,
            filterParams : {
                id : this.$route.params.id,
            },
            itemPagination : {
                searchBar : '',
                page : 1,
            },
            updateRate : {},
            isUpdateRateClicked : false,
            newRate : {},
            isNewRateClicked : false,
            uploadTemplate : {},
            isUploadTemplateClicked : false,
            ExportTemplates : ExportTemplates,
            templateType : 0,
            ModuleActionList : ModuleActionList
        }
    }
}
