import axios from 'axios'
import { CreateToken } from '../helpers/jwtHelpers.js'
import 'dotenv/config'

const MAIN_API = process.env.VUE_APP_REST_MAIN_API
// const TOKEN_INDEX_REST = 1
const TOKEN_INDEX_MAIN = 0

axios.defaults.withCredentials = true



export const GetCostCodes = async (search, parentId = "") =>{
    return axios.get(`${MAIN_API}/api/boq/costcodes?search=${search}&parentId=${parentId}`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>[])
}


export const GetBoqList = async (projectId, page = 1, search ="") =>{
    return axios.get(`${MAIN_API}/api/boq/list/${projectId}?page=${page}&search=${search}`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.status)
}


export const GetBoqItem = async (id) =>{
    return axios.get(`${MAIN_API}/api/boq/${id}`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.status)
}

export const GetBoqItemSummary = async (id) =>{
    return axios.get(`${MAIN_API}/api/boq/summary/${id}`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.status)
}

export const GetBoqItemDelete = async (id) =>{
    return axios.delete(`${MAIN_API}/api/boq/item/delete`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        },
        data : {
            id : id
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>null)
}

export const CreateBoq = async (data) =>{
    return axios.post(`${MAIN_API}/api/boq`,
    data
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return {
            status : res.status,
            data : res.data
        }
    }).catch((err)=>{
        return {
            status : err.response.status,
            data : err.response.data
        }
    })
}

export const DuplicateBoq = async (data) =>{
    return axios.post(`${MAIN_API}/api/boq/duplicate`,
    data
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const UpdateBoq = async (data) =>{
    return axios.put(`${MAIN_API}/api/boq/${data.id}`,
    data
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const DeleteBoq = async (id) =>{
    return axios.delete(`${MAIN_API}/api/boq/${id}`,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        },
        data : {
            id : id
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const CombineBoq = async (data) =>{
    return axios.put(`${MAIN_API}/api/boq/${data.id}/combine`,
    data
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const ReviewBoq = async (data) =>{
    return axios.put(`${MAIN_API}/api/boq/${data.boqId}/review`,
    data
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const PublishBoq = async (data) =>{
    return axios.put(`${MAIN_API}/api/boq/${data}/publish`,
    data
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const ForApprovalBoq = async (data) =>{
    return axios.put(`${MAIN_API}/api/boq/${data.boqId}/approval`,
    data
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const ForWorkFlowSetup = async (data) =>{
    return axios.get(`${MAIN_API}/api/boq/quotation/workflow/setup`
    ,{
        params : data,
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const ForWorkFlowSetupExternal = async (data) =>{
    return axios.post(`${MAIN_API}/api/boq/quotation/workflow/external/setup`,
    data
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}


export const EndApprovalBoq = async (data) =>{
    return axios.put(`${MAIN_API}/api/boq/${data}/approval/end`,
    {},
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}


export const UpsertBoqListItems = async (data) =>{
    return axios.post(`${MAIN_API}/api/boq/list/items`,
    {data : data}
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}




export const GetBoqHistoryList = async (data) =>{
    return axios.get(`${MAIN_API}/api/boq/list/history/${data.id}`
    ,{
        params : data,
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const GetBoqHistoryListByBits = async (data) =>{
    return axios.get(`${MAIN_API}/api/boq/list/history/${data.id}/bits`
    ,{
        params : data,
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}



export const GetBoqReviewList = async (data) =>{
    return axios.get(`${MAIN_API}/api/boq/list/review/${data.id}`
    ,{
        params : data,
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const GetBoqReviewListByBits = async (data) =>{
    return axios.get(`${MAIN_API}/api/boq/list/review/${data.id}/bits`
    ,{
        params : data,
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const EndBoqReview = async (id) =>{
    return axios.put(`${MAIN_API}/api/boq/list/review/end/${id}`,
    {}
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}




export const GetCurrencyList = async () =>{
    return axios.get(`${MAIN_API}/api/boq/currency`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const GetCostCodeSummary = async (id) =>{
    return axios.get(`${MAIN_API}/api/boq/list/costcode/summary/${id}`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const CalculateCostCodeSummary = async (id) =>{
    return axios.post(`${MAIN_API}/api/boq/list/costcode/summary/${id}/calculate`,
    {}
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const UpdateCostCodeSummary = async (data) =>{
    return axios.post(`${MAIN_API}/api/boq/list/costcode/summary`,
    {data : data}
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const CreateNewTabBoq = async (data) =>{
    return axios.post(`${MAIN_API}/api/boq/list/tabs`,
    data
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const CalculateTabsBoq = async (data) =>{
    return axios.put(`${MAIN_API}/api/boq/list/tabs/${data}/calculate`,
    data
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const RemoveTabBoq = async (data) =>{
    return axios.put(`${MAIN_API}/api/boq/list/tabs/${data.id}`,
    data
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

//#region Boq Master List 
export const GetBoqListItems = async (data) =>{
    return axios.get(`${MAIN_API}/api/boq/list/items/${data.id}`
    ,{
        params: data,
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    })
    .catch((err)=>err.response.status)
}

//system template
export const DownloadTemplate1 = async () =>{
    return axios.get(`${MAIN_API}/api/boq/template/0`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        },
        responseType : 'blob'
    })
    .then((res)=>{
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'System_Template.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
    }).catch((err)=>err.response.data)
}



export const GetOfficeIncrementals = async (id) =>{
    return axios.get(`${MAIN_API}/api/boq/incrementals/${id}`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        },
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}


export const AddNewItemToMasterList = async (data) =>{
    return axios.post(`${MAIN_API}/api/boq/list/items/${data.id}`,
    data
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const EditItemToMasterList = async (data) =>{
    return axios.put(`${MAIN_API}/api/boq/list/items/${data.id}/${data._id}`,
    data
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const DeleteItemToMasterList = async (id) =>{
    return axios.delete(`${MAIN_API}/api/boq/list/items`,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        },
        data : {
            id : id
        }
    },
    )
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const UploadTemplate1ToMasterList = async (data) => {
    return axios.post(`${MAIN_API}/api/boq/list/upload/`,
    data
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}


export const UploadOfficeTemplateToMasterList = async (data, uploadProgressCallback) => {
    return axios.post(`${MAIN_API}/api/boq/list/upload/officetemplates`,
    data
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        },
        onUploadProgress: function( progressEvent ) {
            uploadProgressCallback(progressEvent)
        }.bind(this)
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}


//#endregion
//#region boq creation
export const InsertItemsToBoq = async (data) =>{
    return axios.post(`${MAIN_API}/api/boq/list/items/${data.id}/boq`,
    data
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const UpdateItemsFromBoq = async (data) =>{ 
    return axios.put(`${MAIN_API}/api/boq/item/update/${data._id}`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>[])
}

export const UpdateItemsMultipleFromBoq = async (data) =>{ 
    return axios.put(`${MAIN_API}/api/boq/item/update/${data.id}/multiple`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>[])
}

export const GetItemsFromBoq = async (data) =>{
    return axios.get(`${MAIN_API}/api/boq/list/items/${data.id}/boq`,
    {
        params : data,
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>[])
}
export const GetItemsFromBoqByBits = async (data) =>{
    return axios.get(`${MAIN_API}/api/boq/list/items/${data.id}/boq/bits`,
    {
        params : data,
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>[])
}


export const DownloadBoqExport = async (id, name = "export") =>{
    return axios.get(`${MAIN_API}/api/boq/item/export/${id}` 
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        },
        responseType : 'blob'
    })
    .then((res)=>{
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name + '.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
    }).catch((err)=>err.response.data)
}


//#endregion

//#region sequencing

export const GetCategoriesOfItemsFromBoq= async (id) =>{
    return axios.get(`${MAIN_API}/api/boq/list/items/${id}/boq/categories`,
    {
        params : {id : id},
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>[])
}


export const UpdateLineItemSequence = async ({data, boqId}) =>{
    return axios.put(`${MAIN_API}/api/boq/sequence/items`,
    {
        newSequence : data,
        boqId : boqId
    },
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const UpdateMoveLineItemSequence = async ({lineItemIds, newSectionId, boqId}) =>{
    return axios.put(`${MAIN_API}/api/boq/sequence/items/move`,
    {
        lineItemIds : lineItemIds,
        boqId : boqId,
        newSectionId : newSectionId
    },
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const AddNewBoqSequence = async (data) =>{
    return axios.post(`${MAIN_API}/api/boq/sequence/section/add`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const MoveBoqSequence = async (data) =>{
    return axios.put(`${MAIN_API}/api/boq/sequence/section/move`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const DeleteSubSectionSequence = async (data) =>{
    return axios.put(`${MAIN_API}/api/boq/sequence/section/sub/delete`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const DeleteMainSectionSequence = async (data) =>{
    return axios.put(`${MAIN_API}/api/boq/sequence/section/main/delete`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
//#endregion sequencing


export const SearchCostCodeFromProject = async (data) =>{
    return axios.get(`${MAIN_API}/api/boq/search/costcode/${data.id}`
    ,{
        params : data,
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const SearchAreaFromProject = async (data) =>{
    return axios.get(`${MAIN_API}/api/boq/search/area/${data.id}`
    ,{
        params : data,
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const SearchBoqFromProject = async (data) =>{
    return axios.get(`${MAIN_API}/api/boq/search/boq/${data.id}`
    ,{
        params : data,
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}


export const GetProjectConsolidatedList = async ({id, page = 1 , search =''}) =>{
    return axios.get(`${MAIN_API}/api/boq/list/consolidated/${id}`
    ,{
        params : {
            page : page,
            search : search
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.status) 
}
//might be removed
export const GetProjectConsolidatedListView = async (id) =>{
    return axios.get(`${MAIN_API}/api/boq/list/consolidated/view/${id}`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.status) 
}

export const SearchTradePackageOnConsolidatedList = async ({id, search =''}) =>{
    return axios.get(`${MAIN_API}/api/boq/packages/search/`
    ,{
        params : {
            projectId : id,
            search : search
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data) 
}

//#region Trade packages
export const AddItemsToTradePackage = async (data) =>{
    return axios.post(`${MAIN_API}/api/boq/packages/items`,
    data
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return {
            data : res.data,
            status : res.status
        }
    }).catch((err)=>err.response.data)
}

export const GetProjectTradePackages = async (data) =>{
    return axios.get(`${MAIN_API}/api/boq/packages/items/${data.id}`,
    {
        params : {
            search : data.search || '',
            page : data.page || 1,
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.status)
}

export const UpdateTradePackage = async (data) =>{
    return axios.put(`${MAIN_API}/api/boq/packages/items/${data.id}`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}


//#endregion


//remove old consolidated functions 
//new consolidated functions
//#region consolidated boq items management
export const AddConsolidatedLineItem = async (data) =>{
    return axios.post(`${MAIN_API}/api/boq/consolidated`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const UpdateConsolidatedLineItem = async (data) =>{
    return axios.put(`${MAIN_API}/api/boq/consolidated/${data.id}`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const GetConsolidatedLineItems = async (data) =>{
    return axios.get(`${MAIN_API}/api/boq/consolidated/list/${data.id}`,
    {
        params : {
            costCode : data.costCode,
            area : data.area,
            page : data.page || 1,
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const GetConsolidatedLineItemsBits = async (data) =>{
    return axios.get(`${MAIN_API}/api/boq/consolidated/list/${data.id}/bits`,
    {
        params : {
            costCode : data.costCode,
            area : data.area,
            skip : data.skip,
            take : data.take,
            page : data.page || 1,
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.status)
}


export const DownloadConsolidatedBoqExport = async (id,name = "export") =>{
    return axios.get(`${MAIN_API}/api/boq/consolidated/export/${id}` 
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        },
        responseType : 'blob'
    })
    .then((res)=>{
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name+'.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
    }).catch((err)=>err.response.data)
}


//#endregion consolidated boq items management
//#region Line Item Fee 
export const AddProjectFeeLineItem = async (data) =>{
    return axios.post(`${MAIN_API}/api/boq/line-item/fee-item`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const UpdateProjectFeeLineItem = async (data) =>{
    return axios.put(`${MAIN_API}/api/boq/line-item/fee-item/${data.id}`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const GetProjectFeeLineItem = async (id) =>{
    return axios.get(`${MAIN_API}/api/boq/line-item/fee-item/${id}`,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
//#endregion Line Item fee

//#region Boq Rate Module

export const GetBoqRateTemplates = async (data) =>{
    return axios.get(`${MAIN_API}/api/boq/rate/templates/${data.id}`,
    {
        params : {
            search : data.search,
            page : data.page || 1,
            isSelect : data.isSelect
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const AddBoqRateTemplate = async (data) =>{
    return axios.post(`${MAIN_API}/api/boq/rate/templates`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const UpdateBoqRateTemplate = async (data) =>{
    return axios.put(`${MAIN_API}/api/boq/rate/templates/${data.id}`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const DeleteBoqRateTemplate = async (id) =>{
    return axios.delete(`${MAIN_API}/api/boq/rate/templates/`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        },
        data : {
            id : id
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>null)
}
//rate items
export const GetBoqRateTemplatesItems = async (data) =>{
    return axios.get(`${MAIN_API}/api/boq/rate/templates/items/${data.id}`,
    {
        params : {
            page : data.page || 1,
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}


export const AddBoqRateTemplateItem = async (data) =>{
    return axios.post(`${MAIN_API}/api/boq/rate/templates/items`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const AddBoqRateTemplateItemImport = async (data) =>{
    return axios.post(`${MAIN_API}/api/boq/rate/templates/items/import`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const UpdateBoqRateTemplateItem = async (data) =>{
    return axios.put(`${MAIN_API}/api/boq/rate/templates/items/${data.id}`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const DeleteBoqRateTemplateItem = async (data) =>{
    return axios.delete(`${MAIN_API}/api/boq/rate/templates/items`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        },
        data : data
    })
    .then((res)=>{
        return res.data
    }).catch(()=>null)
}

export const ExportBoqRateTemplatesItems = async (id, name = "export") =>{
    return axios.get(`${MAIN_API}/api/boq/rate/templates/items/export/${id}` 
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        },
        responseType : 'blob'
    })
    .then((res)=>{
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', name + '.xlsx') //or any other extension
        document.body.appendChild(link)
        link.click()
        return res.statusText
    }).catch((err)=>err.response.data)
}

export const DownloadTemplateRateSystem = async () =>{
    return axios.get(`${MAIN_API}/api/boq/template/rate/0`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        },
        responseType : 'blob'
    })
    .then((res)=>{
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Rate_Template.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
    }).catch((err)=>err.response.data)
}

//#endregion Boq Rate Module

//#region Roll up funcitonality management 

export const SearchLineItemChilds = async (data) =>{
    return axios.get(`${MAIN_API}/api/boq/search/lineitem/${data.id}`,
    {
        params : {
            search : data.search || ''
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const GetLineItemChilds = async (id) =>{
    return axios.get(`${MAIN_API}/api/boq/list/items/childs/${id}`,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const AddLineItemChilds = async (data) =>{
    return axios.post(`${MAIN_API}/api/boq/list/items/childs/add`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const RemoveLineItemChild = async (id) =>{
    return axios.delete(`${MAIN_API}/api/boq/list/items/childs/delete`,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        },
        data : {
            id : id
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}


//#endregion Roll up funcitonality management 

//#region External Exports

//cost x
export const ExportBoqCostXLayout = async (boqId, name = 'export') =>{
    return axios.get(`${MAIN_API}/api/boq/external/export/costx/${boqId}`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        },
        responseType : 'blob'
    })
    .then((res)=>{
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name + '.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
    }).catch((err)=>err.response.data)
}

export const ExportBoqConsolidatedCostXLayout = async (boqId, name = 'export') =>{
    return axios.get(`${MAIN_API}/api/boq/external/export/costx/${boqId}/consolidated`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        },
        responseType : 'blob'
    })
    .then((res)=>{
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name + '.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
    }).catch((err)=>err.response.data)
}

export const ExportCostXTemplate = async () =>{
    return axios.get(`${MAIN_API}/api/boq/external/template/costx`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        },
        responseType : 'blob'
    })
    .then((res)=>{
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download',  'CostX-Sample .xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
    }).catch((err)=>err.response.data)
}
//end cost x
//start five d
export const ExportBoqFiveDLayout = async (boqId, name = 'export') =>{
    return axios.get(`${MAIN_API}/api/boq/external/export/fived/${boqId}`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        },
        responseType : 'blob'
    })
    .then((res)=>{
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name + '.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
    }).catch((err)=>err.response.data)
}

export const ExportBoqConsolidatedFiveDLayout = async (boqId, name = 'export') =>{
    return axios.get(`${MAIN_API}/api/boq/external/export/fived/${boqId}/consolidated`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        },
        responseType : 'blob'
    })
    .then((res)=>{
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name + '.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
    }).catch((err)=>err.response.data)
}

export const ExportFiveDTemplate = async () =>{
    return axios.get(`${MAIN_API}/api/boq/external/template/fived`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        },
        responseType : 'blob'
    })
    .then((res)=>{
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download',  'FiveD-Sample .xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
    }).catch((err)=>err.response.data)
}
//end five d

//rolled up export
export const ExportBoqRolledUpLayout = async (boqId, name = 'export') =>{
    return axios.get(`${MAIN_API}/api/boq/external/export/rollup/${boqId}`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        },
        responseType : 'blob'
    })
    .then((res)=>{
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name + '.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
    }).catch((err)=>err.response.data)
}

export const ExportBoqConsolidatedRolledUpLayout = async (boqId, name = 'export') =>{
    return axios.get(`${MAIN_API}/api/boq/external/export/rollup/${boqId}/consolidated`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        },
        responseType : 'blob'
    })
    .then((res)=>{
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name + '.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
    }).catch((err)=>err.response.data)
}
//end rolled up export 

//#endregion External Exports

//#region Quotation list

export const GetQuotationList = async (data) =>{
    return axios.get(`${MAIN_API}/api/boq/quotation/list/${data.id}`,
    {
        params : {
            search : data.search || '',
            page : data.page || 1
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.status)
}
export const SearchQuotationList = async (data) =>{
    return axios.get(`${MAIN_API}/api/boq/search/quotation/${data.id}`,
    {
        params : {
            search : data.search || '',
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const SearchBoqNoQuotations = async (data) =>{
    return axios.get(`${MAIN_API}/api/boq/search/boq/${data.id}/noref`,
    {
        params : {
            search : data.search || '',
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const GetQuotationOne = async (data) =>{
    return axios.get(`${MAIN_API}/api/boq/quotation/list/${data}/item`,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.status)
}

export const GetQuotationOneLineItemsByBits = async (data) =>{
    return axios.get(`${MAIN_API}/api/boq/quotation/list/${data.id}/item/attachments`,
    {
        params : data,
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.status)
}

export const GetQuotationOneLineItemsSections = async (data) =>{
    return axios.get(`${MAIN_API}/api/boq/quotation/list/${data.id}/item/attachments/sections`,
    {
        params : data,
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.status)
}

export const GetQuotationSummary = async (data) =>{
    return axios.get(`${MAIN_API}/api/boq/quotation/list/${data}/summary`,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const GetBoQsQuotationSummaries = async (data) =>{
    return axios.get(`${MAIN_API}/api/boq/quotation/list/${data}/summary/boq`,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const AddQuotation = async (data) =>{
    return axios.post(`${MAIN_API}/api/boq/quotation`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return {
            status : res.status,
            data : res.data
        }
    }).catch((err)=>err.response.data)
}
export const AddBoQtoQuotation = async (data) =>{
    return axios.post(`${MAIN_API}/api/boq/quotation/add/boq`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const UpdateQuotation = async (data) =>{
    return axios.put(`${MAIN_API}/api/boq/quotation/${data._id}`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const DeleteQuotation = async (id) =>{
    return axios.delete(`${MAIN_API}/api/boq/quotation/${id}`,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        },
        data : {
            id : id
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const ValidateQuotationBeforeApproval = async (data) =>{
    // /quotation/validate
    return axios.post(`${MAIN_API}/api/boq/quotation/validate`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const ForApprovalQuotation = async (data) =>{
    return axios.put(`${MAIN_API}/api/boq/quotation/${data.quotationId}/approval`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const PublishQuotation = async (data) =>{
    return axios.put(`${MAIN_API}/api/boq/quotation/${data}/publish`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const GetApprovalQuotationRevison = async (data) =>{
    return axios.get(`${MAIN_API}/api/boq/quotation/${data}/approval/revision`,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const GetQuotationHistory = async (data) =>{
    return axios.get(`${MAIN_API}/api/boq/quotation/${data.id}/history`,
    {
        params : {
            index : data.index 
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.status)
}

//#endregion



//directory search for cost code childs
export const GetCostCodeChilds = async (data, officeId = '') =>{
    return axios.get(`${MAIN_API}/api/directory/search/costcode/items/`
    ,{
        params : {
            search : data || '',
            officeId : officeId
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const GetCostCodeActives = async (data) =>{
    return axios.get(`${MAIN_API}/api/directory/search/costcode/items/active`
    ,{
        params : {
            search : data || '',
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
 
    }).catch((err)=>err.response.data)
}

export const GetCostCodeSubActives = async () =>{
    return axios.get(`${MAIN_API}/api/directory/search/costCode/sub/all/active`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
 
    }).catch((err)=>err.response.data)
}
export const GetCostCodeMainActives = async () =>{
    return axios.get(`${MAIN_API}/api/directory/search/costCode/main/all/active`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
 
    }).catch((err)=>err.response.data)
}

export const SearchUserFromProjectDirectory = async (search ="", role ="", projectId = "") =>{
    return axios.get(`${MAIN_API}/api/directory/search/user/project`
    ,{
        params : {
            search : search,
            role : role,
            projectId : projectId
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>[])
}
export const GetBoqItemRemarks = async (data, officeId = '') =>{
    return axios.get(`${MAIN_API}/api/directory/search/item-remarks`
    ,{
        params : {
            search : data || '',
            officeId : officeId
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

//#region High Level Estimates
//#region High Level Estimate Project
export const GetHighLevelProject = async (id) =>{
    return axios.get(`${MAIN_API}/api/boq/high-level-estimate/project/${id}`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.status)
}
export const GetHighLevelProjectList = async (data) =>{
    return axios.get(`${MAIN_API}/api/boq/high-level-estimate/project`
    ,{
        params : data,
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.status)
}
export const AddHighLevelProject = async (data) =>{
    return axios.post(`${MAIN_API}/api/boq/high-level-estimate/project`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const UpdateHighLevelProject = async (data) =>{
    return axios.put(`${MAIN_API}/api/boq/high-level-estimate/project/${data.id}`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const DeleteHighLevelProject = async (data) =>{
    return axios.delete(`${MAIN_API}/api/boq/high-level-estimate/project/${data.id}`,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
//#endregion High Level Estimate Project


//start of High Level Estimates bill of quantities
export const GetHighLevelBoqList = async (data) =>{
    return axios.get(`${MAIN_API}/api/boq/high-level-estimate/boq`
    ,{
        params : data,
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.status)
}
export const GetHighLevelBoq = async (id) =>{
    return axios.get(`${MAIN_API}/api/boq/high-level-estimate/boq/${id}`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.status)
}
export const SearchForReferenceHighLevelBoq = async (search, projectId) =>{
    return axios.get(`${MAIN_API}/api/boq/high-level-estimate/for-reference/boq`
    ,{
        params : {
            search : search,
            projectId : projectId,
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.status)
}
export const AddHighLevelBoq = async (data) =>{
    return axios.post(`${MAIN_API}/api/boq/high-level-estimate/boq`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const UpdateHighLevelBoq = async (data) =>{
    return axios.put(`${MAIN_API}/api/boq/high-level-estimate/boq`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const DeleteHighLevelBoq = async (id) =>{
    return axios.delete(`${MAIN_API}/api/boq/high-level-estimate/boq/${id}`,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const DuplicateHighLevelBoq = async (data) =>{
    return axios.post(`${MAIN_API}/api/boq/high-level-estimate/boq/duplicate`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const CombineteHighLevelBoq = async (data) =>{
    return axios.post(`${MAIN_API}/api/boq/high-level-estimate/boq/combine`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const ForApprovalHighLevelBoq = async (data) =>{
    return axios.post(`${MAIN_API}/api/boq/high-level-estimate/boq/approval`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const GetApprovalItemHighLevelEstimates = async (data) =>{
    return axios.get(`${MAIN_API}/api/boq/high-level-estimate/boq/approval/item`,
    {
        params : data,
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
//end of High Level Estimates bill of quantities

//start of High Level Estimates bill of quantities line items
export const GetHighLevelBoqLineItemsByBits = async (data) =>{
    return axios.get(`${MAIN_API}/api/boq/high-level-estimate/boq/${data.id}/line-items`
    ,{
        params : data,
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.status)
}
export const GetHighLevelBoqHistoryLineItemsByBits = async (data) =>{
    return axios.get(`${MAIN_API}/api/boq/high-level-estimate/boq/${data.id}/revisions/line-items`
    ,{
        params : data,
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.status)
}
export const GetHighLevelBoqApprovalLineItemsByBits = async (data) =>{
    return axios.get(`${MAIN_API}/api/boq/high-level-estimate/boq/${data.id}/approval/line-items`
    ,{
        params : data,
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.status)
}
export const GetHighLevelBoqLineItemsSummary = async (id) =>{
    return axios.get(`${MAIN_API}/api/boq/high-level-estimate/boq/${id}/line-items/summary`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.status)
}
export const AddHighLevelBoqLineItem = async (data) =>{
    return axios.post(`${MAIN_API}/api/boq/high-level-estimate/boq/${data.boqId}/line-items`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const UpdateHighLevelBoqLineItem = async (data) =>{
    return axios.put(`${MAIN_API}/api/boq/high-level-estimate/boq/${data.boqId}/line-items/${data.id}`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const DeleteHighLevelBoqLineItem = async (id) =>{
    return axios.delete(`${MAIN_API}/api/boq/high-level-estimate/boq/line-items/${id}`,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const ImportHighLevelBoqLineItems  = async (data) => {
    return axios.post(`${MAIN_API}/api/boq/high-level-estimate/boq/line-items/import`,
    data
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const ExportHighLevelBoqLineItems  = async (id,type,name) => {
    return axios.get(`${MAIN_API}/api/boq/high-level-estimate/boq/line-items/export?id=${id}&type=${type}`,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        },
        responseType : 'blob'
    })
    .then((res)=>{
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url
        link.setAttribute('download', name + '.xlsx') //or any other extension
        document.body.appendChild(link)
        link.click()
    }).catch((err)=>err.response.status)
}
//end of High Level Estimates bill of quantities line items
//start of High Level Estimates Quotation
export const SearchHighLevelQuotations = async (search, hleProjectId) =>{
    return axios.get(`${MAIN_API}/api/boq/high-level-estimate/search/quotation`
    ,{
        params : {
            search : search,
            hleProjectId : hleProjectId,
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.status)
}
export const SearchOfficeHighLevelQuotations = async (search,hleProjectId) =>{
    return axios.get(`${MAIN_API}/api/boq/high-level-estimate/search-office/quotation`
    ,{
        params : {
            search : search,
            hleProjectId : hleProjectId,
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.status)
}
export const GetHighLevelQuotationForUpdate = async (id) =>{
    return axios.get(`${MAIN_API}/api/boq/high-level-estimate/for-update/quotation/${id}`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.status)
}
export const GetHighLevelQuotationForApproval = async (id) =>{
    return axios.get(`${MAIN_API}/api/boq/high-level-estimate/for-approval/quotation/${id}`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.status)
}
export const SendHighLevelQuotationForApproval = async (data) =>{
    return axios.post(`${MAIN_API}/api/boq/high-level-estimate/for-approval/quotation/${data.quotationId}`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>{ 
        return err.response.data
    })
}
export const GetHighLevelQuotationForApprovalProcess = async (id) =>{
    return axios.get(`${MAIN_API}/api/boq/high-level-estimate/for-approval/quotation/${id}/item`,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.status)
}
export const GetHighLevelApprovalQuotationLineItemAttachmentsByBits = async (data) =>{
    return axios.get(`${MAIN_API}/api/boq/high-level-estimate/for-approval/quotation/${data.id}/detailed-summary`,
    {
        params : data,
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.status)
}
export const GetHighLevelQuotationsList = async (data) =>{
    return axios.get(`${MAIN_API}/api/boq/high-level-estimate/quotation`
    ,{
        params : data,
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.status)
}
export const GetHighLevelQuotation = async (id) =>{
    return axios.get(`${MAIN_API}/api/boq/high-level-estimate/quotation/${id}`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.status)
}
export const GetHighLevelQuotationBoQSummary = async (id) =>{
    return axios.get(`${MAIN_API}/api/boq/high-level-estimate/quotation/${id}/summary-boq`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.status)
}
export const GetHighLevelQuotationBoQDetailed = async (id,revisionId) =>{
    return axios.get(`${MAIN_API}/api/boq/high-level-estimate/quotation/${id}/summary-boq-items`
    ,{
        params : {
            revisionId : revisionId
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.status)
}
export const GetHighLevelQuotationMini = async (id) =>{
    return axios.get(`${MAIN_API}/api/boq/high-level-estimate/quotation/${id}/mini`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.status)
}
export const GetHighLevelQuotationSummaryDetailed = async (data) =>{
    return axios.get(`${MAIN_API}/api/boq/high-level-estimate/quotation/${data.id}/detailed-summary`
    ,{
        params : data,
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.status)
}
export const GetHighLevelQuotationRevisions = async (id) =>{
    return axios.get(`${MAIN_API}/api/boq/high-level-estimate/quotation/${id}/history`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.status)
}
export const AddHighLevelQuotation = async (data) =>{
    return axios.post(`${MAIN_API}/api/boq/high-level-estimate/quotation`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const AttachHighLevelQuotationToProject = async (data) =>{
    return axios.post(`${MAIN_API}/api/boq/high-level-estimate/quotation/${data.id}/attach-to-project`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.status)
}
export const AttachHighLevelBoqToQuotation = async (data) =>{
    return axios.post(`${MAIN_API}/api/boq/high-level-estimate/quotation/attach-boq`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const DeleteAttachHighLevelBoqToQuotation = async (data) =>{
    return axios.put(`${MAIN_API}/api/boq/high-level-estimate/quotation/attach-boq`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const UpdateLineItemAttachHighLevelBoqToQuotation = async (data) =>{
    return axios.post(`${MAIN_API}/api/boq/high-level-estimate/quotation/attach-boq/edit-line-item`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const CheckAttachHighLevelBoqToQuotation = async (data) =>{
    return axios.post(`${MAIN_API}/api/boq/high-level-estimate/quotation/attach-boq/check`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const GetAttachedHighLevelBoqToQuotation = async (id) =>{
    return axios.get(`${MAIN_API}/api/boq/high-level-estimate/quotation/attach-boq/${id}`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const GetAttachedHighLevelBoqToQuotationHistory = async (id,revisionId) =>{
    return axios.get(`${MAIN_API}/api/boq/high-level-estimate/quotation/attach-boq/${id}/history`
    ,{
        params : {
            revisionId : revisionId
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const UpdateHighLevelQuotation = async (data) =>{
    return axios.put(`${MAIN_API}/api/boq/high-level-estimate/quotation/${data.id}`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const DeleteHighLevelQuotation = async (id) =>{
    return axios.delete(`${MAIN_API}/api/boq/high-level-estimate/quotation/${id}`,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const UpsertSectionScopeOfWorkHighLevelQuotation = async (data) =>{
    return axios.put(`${MAIN_API}/api/boq/high-level-estimate/quotation/${data.id}/upsert-section-scope-of-work`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
//start of High Level Estimates Quotation
//#endregion High Level Estimates


//#region export for presentation
//client exports

export const BoQItemExportForClient = async (id, name) =>{
    return axios.get(`${MAIN_API}/api/boq/external/export/client/boq/${id}`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        },
        responseType : 'blob'
    })
    .then((res)=>{
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name + '.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
    }).catch((err)=>err.response.data)
}
//internal exports 
export const BoQItemExportForInternal = async (id, name) =>{
    return axios.get(`${MAIN_API}/api/boq/internal/export/boq/${id}`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        },
        responseType : 'blob'
    })
    .then((res)=>{
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name + '.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
    }).catch((err)=>err.response.data)
}

//#endregion export for presentation

//#region New BoQ Creator
export const GetNewBoqCreatorSections = async (id, forPrint) =>{
    return axios.get(`${MAIN_API}/api/boq/new/creator/boq/${id}`
    ,{
        params : {
            forPrint : forPrint
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const GetNewBoqCreatorSectionsRaw = async (id, forPrint) =>{
    return axios.get(`${MAIN_API}/api/boq/new/creator/boq/${id}/raw`
    ,{
        params : {
            forPrint : forPrint
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const GetNewBoqCreatorSectionsRevisions = async (id, revisionId, forPrint) =>{
    return axios.get(`${MAIN_API}/api/boq/new/creator/boq/revisions/sections/${id}`
    ,{
        params : {
            forPrint : forPrint,
            revisionId : revisionId,
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const GetNewBoqCreatorSectionsRevisionsSection = async (id, boqId, revisionId ) =>{
    return axios.get(`${MAIN_API}/api/boq/new/creator/boq/revisions/sections/${id}/get-section`
    ,{
        params : {
            id : id,
            boqId : boqId,
            revisionId : revisionId,
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const GetNewBoqCreatorSectionItem = async (data) =>{
    return axios.get(`${MAIN_API}/api/boq/new/creator/boq/section/get-section`
    ,{
        params : {
            id : data.id,
            boqId : data.boqId
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const AddNewBoqCreatorSection = async (data) =>{
    return axios.post(`${MAIN_API}/api/boq/new/creator/boq/section`,
    data
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const UpdateNewBoqCreatorSectionName = async (data) =>{
    return axios.put(`${MAIN_API}/api/boq/new/creator/boq/section`,
    data
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const UpdateNewBoqCreatorSectionMarkUp = async (data) =>{
    return axios.put(`${MAIN_API}/api/boq/new/creator/boq/section/update-markup`,
    data
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const UpdateNewBoqCreatorSectionSequence = async (data) =>{
    return axios.put(`${MAIN_API}/api/boq/new/creator/boq/section/update-sequence`,
    data
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const UpdateNewBoqCreatorSectionBreakdownSequence = async (data) =>{
    return axios.put(`${MAIN_API}/api/boq/new/creator/boq/section/breakdown/update-sequence`,
    data
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const DeleteNewBoqCreatorSection = async (data) =>{
    return axios.delete(`${MAIN_API}/api/boq/new/creator/boq/section`,
    {
        data : data,
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const AddUnassignedToNewBoqCreatorSection = async (data) =>{
    return axios.put(`${MAIN_API}/api/boq/new/creator/boq/section/add-unassigned-line-items`,
    data
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const DeleteLineItemsNewBoqCreator = async (data) =>{
    return axios.put(`${MAIN_API}/api/boq/new/creator/boq/section/delete-line-items`,
    data
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
//#endregion New BoQ Creator

//#region New High Level BoQ Creator
export const GetHleNewBoqCreatorSections = async (id, forPrint) =>{
    return axios.get(`${MAIN_API}/api/boq/hle-new/creator/boq/${id}`
    ,{
        params : {
            forPrint : forPrint
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const GetHleNewBoqCreatorSectionsRaw = async (id, forPrint) =>{
    return axios.get(`${MAIN_API}/api/boq/hle-new/creator/boq/${id}/raw`
    ,{
        params : {
            forPrint : forPrint
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const GetHleNewBoqCreatorSectionsRevisions = async (id, revisionId, forPrint) =>{
    return axios.get(`${MAIN_API}/api/boq/hle-new/creator/boq/revisions/sections/${id}`
    ,{
        params : {
            forPrint : forPrint,
            revisionId : revisionId,
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const GetHleNewBoqCreatorSectionsRevisionsSection = async (id, boqId, revisionId ) =>{
    return axios.get(`${MAIN_API}/api/boq/hle-new/creator/boq/revisions/sections/${id}/get-section`
    ,{
        params : {
            id : id,
            boqId : boqId,
            revisionId : revisionId,
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const GetHleNewBoqCreatorSectionItem = async (data) =>{
    return axios.get(`${MAIN_API}/api/boq/hle-new/creator/boq/section/get-section`
    ,{
        params : {
            id : data.id,
            boqId : data.boqId
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const AddHleNewBoqCreatorSection = async (data) =>{
    return axios.post(`${MAIN_API}/api/boq/hle-new/creator/boq/section`,
    data
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const UpdateHleNewBoqCreatorSectionName = async (data) =>{
    return axios.put(`${MAIN_API}/api/boq/hle-new/creator/boq/section`,
    data
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const UpdateHleNewBoqCreatorSectionMarkUp = async (data) =>{
    return axios.put(`${MAIN_API}/api/boq/hle-new/creator/boq/section/update-markup`,
    data
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const UpdateHleNewBoqCreatorSectionSequence = async (data) =>{
    return axios.put(`${MAIN_API}/api/boq/hle-new/creator/boq/section/update-sequence`,
    data
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const UpdateHleNewBoqCreatorSectionBreakdownSequence = async (data) =>{
    return axios.put(`${MAIN_API}/api/boq/hle-new/creator/boq/section/breakdown/update-sequence`,
    data
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const DeleteHleNewBoqCreatorSection = async (data) =>{
    return axios.delete(`${MAIN_API}/api/boq/hle-new/creator/boq/section`,
    {
        data : data,
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const AddHleUnassignedToNewBoqCreatorSection = async (data) =>{
    return axios.put(`${MAIN_API}/api/boq/hle-new/creator/boq/section/add-unassigned-line-items`,
    data
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const DeleteHleLineItemsNewBoqCreator = async (data) =>{
    return axios.put(`${MAIN_API}/api/boq/hle-new/creator/boq/section/delete-line-items`,
    data
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const HleBoQItemExportForClient = async (id, name) =>{
    return axios.get(`${MAIN_API}/api/boq/hle/external/export/client/boq/${id}`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        },
        responseType : 'blob'
    })
    .then((res)=>{
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name + '.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
    }).catch((err)=>err.response.data)
}
//#endregion New High Level BoQ Creator
//#region Line Item Fee 
export const AddHighLevelBoqLineItemFee = async (data) =>{
    return axios.post(`${MAIN_API}/api/boq/high-level-estimate/line-item/fee-item`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const UpdateHighLevelBoqLineItemFee = async (data) =>{
    return axios.put(`${MAIN_API}/api/boq/high-level-estimate/line-item/fee-item/${data.id}`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const GetHighLevelBoqLineItemFee = async (id) =>{
    return axios.get(`${MAIN_API}/api/boq/high-level-estimate/line-item/fee-item/${id}`,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
//#endregion Line Item fee


















