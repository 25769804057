import  {mapActions, mapGetters} from 'vuex'
import StepItem2 from '@/components/layout/StepItem2.vue'
import BreadcrumbsProject  from '@/components/projectInformation/BreadcrumbsProject.vue'
import Modal from '@/components/layout/Modal.vue'
import Loader from '@/components/layout/Loader.vue'
import Pagination from '@/components/layout/Pagination.vue'
import { LinkList, ReformatDate, ExportTemplates, ModuleActionList } from '@/helpers/utilities.js'
import {AddBoqRateTemplate, GetBoqRateTemplates,UpdateBoqRateTemplate,DeleteBoqRateTemplate, DownloadTemplateRateSystem } from '@/actions/boqActions.js'
import 'dotenv/config'
export default {
    name : 'BoqRates',
    mounted : async function(){
        const self = this
        const projectId = self.$route.params.id
        self.ChangeActiveLink(LinkList.BOQ_RATE_LIST)
        await self.GetProjectInfo(projectId).catch()

        await self.GetData()
        
        self.isTableLoading = false
    },
    methods : {
        ...mapActions(['ChangeActiveLink','GetProjectInfo']),
        ReformatDate : ReformatDate,
        GetData(){
            const self = this
            return new Promise((resolve)=>{
                self.isTableLoading = true
                GetBoqRateTemplates(self.filterParams).then((res)=>{
                    self.isTableLoading = false
                    self.templateList = res
                    resolve(true)
                })
            })
        },
        AddTemplate(e){
            const self = this
            const btn = e.target
            self.isNewTemplateClicked = true
            if(!self.newTemplate.name){
                return
            }
            
            self.newTemplate.projectId = self.projectId
            self.isNewTemplateClicked = false

            btn.disabled = true
            AddBoqRateTemplate(self.newTemplate).then((res)=>{
                btn.disabled = false
                if(res=="OK"){
                    self.$refs.closeBoqAddRateTemplate.click()
                    self.GetData()
                }else{
                    self.$swal('Add Template',res,'warning')
                }
            })
        },
        OpenEditTemplate(data){
            this.updateTemplate = JSON.parse(JSON.stringify(data))
            this.updateTemplate.id = data._id
        },
        EditTemplate(e){
            const self = this
            const btn = e.target

            self.isUpdateTemplateClicked = true
            if(!self.updateTemplate.name || typeof self.updateTemplate.status ==='undefined'){
                return
            }

            self.updateTemplate.projectId = self.projectId
            self.isUpdateTemplateClicked = false

            btn.disabled = true
            UpdateBoqRateTemplate(self.updateTemplate).then((res)=>{
                btn.disabled = false
                if(res=='OK'){
                    const getIndex = self.templateList.docs.findIndex(u=>u._id==self.updateTemplate.id)
                    if(getIndex!=-1){
                        self.templateList.docs[getIndex].name = self.updateTemplate.name
                        self.templateList.docs[getIndex].status = self.updateTemplate.status=='true'
                    }
                    self.$refs.closeBoqEditRateTemplate.click()
                }else{
                    self.$swal('Edit Template',res,'warning')
                }
            })
            
        },
        ImportTemplate(e){
            const self = this
            const btn = e.target

            self.uploadTemplate.file = ''
            self.isUploadTemplateClicked = true

            const file = self.$refs.uploadFile.files[0]
            if(!file ){
                return
            }
            self.uploadTemplate.file = 'new file'
            

            if(!self.uploadTemplate.name || self.uploadTemplate.templateType===''){
                return
            }

       

            self.isUploadTemplateClicked = false
            let data = new FormData()

            data.append('projectId',self.projectId)
            data.append('name',self.uploadTemplate.name)
            data.append('template',file)

            self.uploadTemplate.projectId = self.projectId

            btn.disabled = true
            if(self.uploadTemplate.templateType===0){
                AddBoqRateTemplate(data).then((res)=>{
                    btn.disabled = false
                    if(res=="OK"){
                        self.$refs.closeBoqImportRateTemplate.click()
                        self.GetData()
                    }else{
                        self.$swal('Add Template',res,'warning')
                    }
                })
            }else{
                btn.disabled = false
                self.$swal('Add Template','Sorry not yet implemented','warning')
            }
            
        },
        DeleteTemplate(data,e){
            const btn = e.target
            const self = this

            self.$swal({
                title: 'Delete Template?',
                text : 'Proceed deleting '+ data.name + '?',
                showCancelButton: true,
                icon: 'warning',
                confirmButtonText: 'Delete',
                showLoaderOnConfirm : true,
                allowOutsideClick: () => !self.$swal.isLoading(),
                preConfirm : ()=>{
                    btn.disabled = true
                    DeleteBoqRateTemplate(data._id).then((res)=>{
                        btn.disabled = false
                        if(res!="OK"){
                            self.$swal('Delete Template',res,'warning')
                        }else{
                            const getIndex = self.templateList.docs.findIndex(u=>u._id==data._id)
                            if(getIndex!=-1){
                                self.templateList.docs.splice(getIndex,1)
                            } 
                        }
                    })
                }
            }).then((res)=>{
                if(res.isConfirmed){
                    self.$swal(data.name + ' Deleted!', '', 'success')
                }
            })
        },
        PageChange(page){
            this.filterParams.page = page
            this.GetData()
        },
        OpenRateImport(){
            this.uploadTemplate = {
                templateType : ''
            }
            this.$refs.uploadFile.value = ''
        },
        DownloadTemplate(){
            if(this.uploadTemplate.templateType===0)
                DownloadTemplateRateSystem()
            else
                this.$swal('Download Template','Sorry not yet implemented','warning')
        }
    },
    components : {
        StepItem2, BreadcrumbsProject, Modal, Loader, Pagination
    },
    computed : {
        ...mapGetters(['projectInfo','permission'])
    },
    data (){
        return {
            isTableLoading : true,
            projectUrl : process.env.VUE_APP_PROJECT_URL,
            newTemplate : {},
            isNewTemplateClicked : false,
            updateTemplate : {
                status : false
            },
            projectId : this.$route.params.id,
            isUpdateTemplateClicked : false,
            uploadTemplate : {},
            isUploadTemplateClicked : false,
            templateList :{
                docs : [],
                totalPages : 1,
                page : 1
            },
            filterParams : {
                search : '',
                page : 1,
                id : this.$route.params.id
            },
            ExportTemplates : ExportTemplates,
            ModuleActionList : ModuleActionList
        }
    }
}