<template>
    <div>
        <div class="pt-3">
            <BoqProcessWorkFlow
            v-if="processItem._id"
            :processItemData="processItem" 
            :showApprovalDisapprove="true"
            />
        </div>
        <HleQuotationReadOnly 
        :quotationItem="quotationItem" :summaryBoqData="summaryBoqData" :editedLineItems="editedLineItems"
        :processItem="processItem" :quotationId="processItem.relatedId" :quotationName="quotationItem.name"
        />
        
        
        <Modal idModal="BoqReviewApprove" headerTitle="Approve Budget Estimate"  >
            <div>
                <div class="form-group">
                    <label>Notes (optional): </label>
                    <textarea v-model="approveNotes" class="form-control"></textarea>
                </div>
                
            </div>
            <template v-slot:actions>
                <button type="button" ref="closeBoqReviewApprove" class="btn btn-outline-danger" data-bs-dismiss="modal">X Cancel</button>
                <button type="button" class="btn btn-primary" @click="Approve" >
                    <i class="fas fa-thumbs-up"></i> Approve
                </button>
            </template>
        </Modal>
        <Modal idModal="BoqReviewReject" headerTitle="Ask For Revision"  >
            <div>
                <div class="form-group">
                    <label class="required-style">Notes: </label>
                    <textarea v-model="rejectNotes" class="form-control"></textarea>
                    <small class="text-danger" v-if="!rejectNotes">Required</small>
                </div>
                
            </div>
            <template v-slot:actions>
                <button type="button" ref="closeBoqReviewReject" class="btn btn-outline-danger" data-bs-dismiss="modal">X Cancel</button>
                <button type="button" class="btn btn-primary" @click="Disapprove" >
                    <i class="fas fa-thumbs-down"></i> Ask For Revision
                </button>
            </template>
        </Modal>
        <div class="pt-1">
            <ProcessCommentList 
            :PageChangeComment="PageChangeComment"
            :processItem="processItem"
            :commentList="commentList"
            />
        </div>
        <div v-if="CanApprove && processItem">
            <div class="d-flex justify-content-end fixed-bottom pb-3 pe-3">
                <button class="btn btn-success me-1" data-bs-toggle="modal" data-bs-target="#BoqReviewApprove"> <i class="fa-solid fa-thumbs-up"></i> Approve</button>
                <button class="btn btn-danger" data-bs-toggle="modal" data-bs-target="#BoqReviewReject"><i class="fa-solid fa-thumbs-down"></i> Ask For Revision</button>
            </div>
        </div>
        <div class="bg-white p-5"></div>
    </div>
</template>

<script>
import {GetHighLevelQuotationForApprovalProcess, GetHighLevelQuotationBoQDetailed} from '@/actions/boqActions.js'
import { GetCommentsList, UserApproveWorkFlow, UserDisapproveWorkFlow} from '@/actions/projectActions.js'
import ProcessCommentList  from '@/components/projectInformation/ProcessCommentList.vue'
import BoqProcessWorkFlow  from '@/components/projectInformation/BoqProcessWorkFlow.vue'
import HleQuotationReadOnly  from '@/components/boq/HleQuotationReadOnly.vue'
import Modal  from '@/components/layout/Modal.vue'
import {ReformatDate, ProcessActionTypes, ConvertDataToBlob} from '@/helpers/utilities.js'
import {mapGetters} from 'vuex'
import {GetUserSignatureOffice} from '@/actions/userActions.js'
export default {
    name : 'HleQuotationApproval',
    methods : {
        ReformatDate,
        OnclickLink(url){
            window.open(url,'_blank') 
        },
        Approve(e){
            const self = this
            const btn = e.target


            if(self.isApproveRunning){
                return
            }

            btn.disabled = true
            self.isApproveRunning = true
            UserApproveWorkFlow(self.processItem._id,self.approveNotes)
            .then((res)=>{
                self.isApproveRunning = false
                btn.disabled = false
                if(res!="OK"){
                    self.$swal('Approve',res.value,'warning')
                }else{
                    self.$swal({
                        icon: 'success',
                        title: 'Approved successfully',
                        showConfirmButton: false,
                        timer : 1000
                    })
                    location.reload()
                }
            })
        },
        Disapprove(e){
            const self = this
            const btn = e.target

            if(self.isDisapproveRunning){
                return
            }

            if(!self.rejectNotes){
                return
            }

            self.isDisapproveRunning = true
            btn.disabled = true
            UserDisapproveWorkFlow(self.processItem._id, self.rejectNotes)
            .then((res)=>{
                btn.disabled = false
                self.isDisapproveRunning = false
                if(res!="OK"){
                    self.$swal('Ask for Revision',res.value,'warning')
                }else{
                    self.$swal({
                        icon: 'success',
                        title: 'Item marked Asked for Revision successfully',
                        showConfirmButton: false,
                        timer : 1000
                    })
                    location.reload()
                }
            })
        },
        async PageChangeComment(page){
            const self = this
            self.commentFilterParam.page = page
            self.commentList = await GetCommentsList(self.commentFilterParam)
        },
    },
    computed : {
        ...mapGetters(['userInfo']),
        CanApprove(){
            const self = this
            const approvers = self.processItem.internalApprovers
            if(approvers.findIndex(u=>u.relatedUserId._id==self.userInfo._id && u.action==ProcessActionTypes.PENDING&& u.actionType==2) >-1){
                return true
            }

            return false
        }
    },
    components : {ProcessCommentList ,BoqProcessWorkFlow, Modal, HleQuotationReadOnly},
    mounted : async function(){
        const self = this

        const getApprovalQuotation = await GetHighLevelQuotationForApprovalProcess(self.approvalId)

        self.quotationItem = getApprovalQuotation.quotation
        self.processItem = getApprovalQuotation.processItem
        for(let x= 0; x< self.quotationItem.submittedBy.length; x++){
            const getSubmitter = self.quotationItem.submittedBy[x]
            const getSignatureSubmitter = self.quotationItem.submittedBySignatures.find(u=>u.userId==getSubmitter._id)
            if(getSignatureSubmitter){
                const getSignature = await GetUserSignatureOffice({officeId : self.processItem.officeId, userId : getSubmitter._id, signatureId :  getSignatureSubmitter.signatureIdFromDotNet})
                if(getSignature && getSignature.message == "OK"){
                    getSubmitter.signatureBlob = ConvertDataToBlob(getSignature.result.signature,getSignature.result.mimeType )
                    
                }
            }
            
        }

        self.editedLineItems = self.quotationItem.lineItemRenamedData || []
        self.commentList = await GetCommentsList(self.commentFilterParam)

        self.summaryBoqData = await GetHighLevelQuotationBoQDetailed(self.processItem.relatedId,self.processItem.relatedRevisionId)

    },
    beforeUnmount() {
        for(let x= 0; x< this.quotationItem.submittedBy.length; x++){
            const blob = this.quotationItem.submittedBy[x].signatureBlob
            if(blob){
                URL.revokeObjectURL(blob);
            }
        }
    },
    data(){
        return {
            quotationItem : {
                officeId :{},
                projectLocation : {},
                submittedBy: [{
                    roleId : {}
                }]
            },
            processItem : {
                internalApprovers : [],
                createdBy : {}
            },
            approvalId : this.$route.params.id,
            commentList : {
                docs : [],
                hasNextPage : false
            },
            commentFilterParam : {
                page : 1,
                id : this.$route.params.id,
            },
            rejectNotes : '',
            approveNotes : '',
            isApproveRunning : false,
            isDisapproveRunning : false,
            editedLineItems : [],
            summaryBoqData : []
        }
    }
}
</script>