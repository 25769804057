<template>
    <div class="row bg-white p-3 border-top border-bottom">
        <div class="col-md-4">
            <div class="form-group">
                <label>{{$t('Office in Charge')}}</label>
                <input type="text" class="form-control-plaintext text-capitalize" :value="quotationItem.officeId.officeName" /> 
            </div>
        </div>
        <div class="offset-md-4 col-md-4">
            <div class="form-group">
                <label>{{$t('Bidding Module.Issue Date')}}</label>
                <input type="text" class="form-control-plaintext" :value="ReformatDate(quotationItem.dateIssued)" /> 
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label>{{$t('Bidding Module.Project Location')}}</label>
                <input type="text" class="form-control-plaintext text-capitalize" :value="quotationItem.projectLocation.officeName" /> 
            </div>
        </div>
        <div class="offset-md-4 col-md-4">
            <div class="d-flex">
                <div class="form-group" style="width:70%" >
                    <label>{{$t('Bidding Module.Quotation Number')}}</label>
                    <div class="input-group ">
                        <input type="text" :value="'QTN-' + quotationItem.quotationNumber" disabled class="form-control-plaintext" />
                    </div>
                </div>
                <div class="form-group" style="width:30%">
                    <label>{{ $t('Revision') }}</label>
                    <div class="input-group ">
                        <input type="number" :value="quotationItem.revisionCount" disabled class="form-control-plaintext border-start-0" />
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label>{{$t('Bidding Module.High Level Estimate Name')}}</label>
                <br />
                <router-link v-if="quotationItem && quotationId" class="text-primary text-capitalize fw-bold" :to="{name : 'HleQuotation' , params : {id : quotationItem.hleProjectId, qtnId : quotationId}}">{{quotationName}}</router-link>
            </div>
        </div>
        <div class="offset-md-4 col-md-4">
            <div class="form-group">
                <label>{{$t('Bidding Module.Estimate Total Floor area')}}</label>
                <input type="text" class="form-control-plaintext" :value="quotationItem.floorArea + ' ' + quotationItem.unitOfMeasure" /> 
            </div>
        </div>
        <div class="col-md-12" >
            <h6 class="fw-bold">{{$t('Bidding Module.Reference Drawing for the budget')}}</h6>
            <div class="row pt-3">
                <div class="col-md-4" v-for="(item,key) in quotationItem.attachments" :key="key">
                    <div class="input-group mb-3" >
                        <span class="input-group-text bg-white" ><i class="fa-solid fa-paperclip"></i></span>
                        <input type="text" role="button" @click="OnclickLink(item.sharepointLink)" 
                        data-bs-toggle="tooltip" data-bs-placement="top" :title="$t('Click to open in Sharepoint')"
                        class="form-control text-decoration-underline text-primary" readonly :value="item.attachmentName">
                    </div>
                    
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <!-- <HleQuotationSummaryBoqs :dataRow="summaryBoqData" :unitOfMeasure="quotationItem.unitOfMeasure" :isRevision="true"
            :floorArea="quotationItem.floorArea" :currency="quotationItem.currencyId" :editedLineItems="editedLineItems" /> -->
            <HleQuotationBoqSummary :dataRow="summaryBoqData" :unitOfMeasure="quotationItem.unitOfMeasure" :isRevision="true" :isReadOnly="true"
        :projectSize="quotationItem.floorArea" :currencySign="quotationItem.currencyId?.sign" :sectionScopeOfWorkList="quotationItem.sectionScopeOfWork"  />
        </div>
        <div class="d-flex justify-content-end">

            <div v-if="!processItem">
                <router-link  class="small text-primary fw-bold" :to="{name : 'HleQuotationDetailedSummary', params : {id : quotationId}, query : { revisionId : quotationItem._id, backUrl : backUrl}}">{{$t('Bidding Module.View Detailed Bill of Quantity')}}</router-link>
            </div>
            <div v-else>
                <router-link  class="small text-primary fw-bold" :to="{name : 'HleQuotationApprovalDetailedSummary', params : {id : processItem._id}, }">{{$t('Bidding Module.View Detailed Bill of Quantity')}}</router-link>
            </div>
        </div>
        <div class="col-md-12" >
            <h6 class="fw-bold">{{$t('Bidding Module.Inclusions/Exclusions')}}</h6>
            <p v-html="quotationItem.inclusionsAndExclusions || '-' "></p>
        </div>
        <div class="col-md-12" >
            <h6 class="fw-bold">{{$t('Terms & Conditions')}}</h6>
            <p v-html="quotationItem.termsAndConditions || '-' "></p>
        </div>
        <div class="col-md-4">
            <h6 class="fw-bold">{{$t('Submitted By')}}</h6>
            <div class=" pt-2" v-for="(item, key) in quotationItem.submittedBy" :key="key">
                <div v-if="item.signatureBlob">
                    <img :src="item.signatureBlob" />
                </div>
                <div><span class="text-capitalize">{{item.fullname}}</span></div>
                <div><small class="text-capitalize">{{item.roleId.roleName}}</small></div>
            </div>
        </div>
        <div class="offset-md-4 col-md-4">
            <h6 class="fw-bold">{{$t('Bidding Module.Approved By')}}</h6>
            <div class=" pt-2" v-for="(item, key) in quotationItem.approvedBy" :key="key">
                <div><span class="text-capitalize">{{item.fullname}}</span></div>
            </div>
        </div>
    </div>
</template>


<script>
import {ReformatDate} from '@/helpers/utilities.js'
import HleQuotationBoqSummary  from '@/components/boq/HleQuotationBoqSummary.vue'
export default {
    name : 'HleQuotationReadOnly',
    components : {HleQuotationBoqSummary},
    props : ['quotationItem','summaryBoqData','editedLineItems','processItem','quotationId','quotationName'],
    data(){
        return { 
            backUrl : this.$route.href
        }
    },
    methods :{
        ReformatDate,
        OnclickLink(url){
            window.open(url,'_blank') 
        }
    },
}
</script>