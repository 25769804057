<template>
    <nav id="sidebar" class="bg-white shadow-sm d-print-none" v-if="isBig">
        <div class="d-flex flex-column flex-shrink-0 sidebar-height ">
            <a href="#" class="d-flex align-items-center pb-3 mb-md-0  link-dark text-decoration-none  ps-3 pt-2 border-bottom">
                <img src="/favicon.png" style="height: 2rem;" class="">
                <span class="d-none d-lg-block d-xl-block "> Commercial Management</span>
            </a>
            <ul class="nav nav-pills flex-column mb-auto border-end" style="height:100%">
                
                <h6 class="dropdown-header text-capitalize">Main</h6>
                <NavLink
                icon="fa-solid fa-building"
                navName="Projects"
                :selected="activeLink"
                :linkId="LinkList.MAIN_DASHBOARD"
                @click="OnNavLinkClick(projectUrl + '/')"
                />
                <NavLink
                data-bs-toggle="collapse" href="#CompanySubMenu"
                icon="fa-solid fa-user-tie"
                navName="Orgnisation"
                :selected="activeLink"
                :linkId="LinkList.MAIN_COMPANY"
                />
                <div class="collapse ps-3" id="CompanySubMenu">
                    <NavLink
                    navName="User"
                    :selected="activeLink"
                    :linkId="LinkList.MAIN_COMPANY"
                    @click="OnNavLinkClick(directoryUrl + '/directory/user')"
                    />
                    <NavLink
                    navName="Office"
                    :selected="activeLink"
                    :linkId="LinkList.MAIN_COMPANY"
                    @click="OnNavLinkClick(directoryUrl + '/directory/office')"
                    />
                    <NavLink
                    navName="Region"
                    :selected="activeLink"
                    :linkId="LinkList.MAIN_COMPANY"
                    @click="OnNavLinkClick(directoryUrl + '/directory/region')"
                    />
                    <NavLink
                    navName="Company List"
                    :selected="activeLink"
                    :linkId="LinkList.MAIN_COMPANY"
                    @click="OnNavLinkClick(directoryUrl + '/directory/company')"
                    />
                </div>
                <NavLink
                icon="fa-solid fa-file-invoice-dollar"
                navName="Cost Codes"
                :selected="activeLink"
                :linkId="LinkList.COST_CODES"
                @click="OnNavLinkClick(directoryUrl + '/directory/costcode')"
                />
                <template v-if="projectInfo._id">
                    <h6 class="dropdown-header text-capitalize">Project</h6>
                    <NavLink
                    :isSub="true"
                    icon="fa-solid fa-chart-column"
                    navName="Dashboard"
                    :selected="activeLink"
                    :linkId="LinkList.SUMMARY"
                    @click="OnNavLinkClick(projectUrl + '/project/' + projectInfo._id)"
                    />
                    <NavLink
                    :isSub="true"
                    icon="fa-solid fa-list"
                    navName="Details"
                    :selected="activeLink"
                    :linkId="LinkList.PROJECT_DETAILS"
                    @click="OnNavLinkClick(projectUrl + '/project/' + projectInfo._id + '/details')"
                    />
                    <NavLink
                    v-if="permission.indexOf(ModuleActionList.DIRECTORY_VIEW) > -1"
                    :isSub="true"
                    icon="fa-solid fa-book-bookmark"
                    navName="Directory"
                    :selected="activeLink"
                    :linkId="LinkList.PROJECT_DIRECTORY"
                    @click="OnNavLinkClick(projectUrl + '/project/' + projectInfo._id + '/directory')"
                    />
                    <NavLink
                    v-if="permission.indexOf(ModuleActionList.PROCESS_VIEW) > -1"
                    :isSub="true"
                    icon="fa-solid fa-sitemap"
                    navName="Process List"
                    :selected="activeLink"
                    :linkId="LinkList.PROJECT_PROCESS"
                    @click="OnNavLinkClick(projectUrl + '/project/' + projectInfo._id + '/process')"
                    />
                    <NavLink
                    :isSub="true"
                    icon="fa-solid fa-file-circle-check"
                    navName="Approval List"
                    :selected="activeLink"
                    :linkId="LinkList.APPROVAL_LIST"
                    @click="OnNavLinkClick(projectUrl + '/project/' + projectInfo._id + '/approvals')"
                    />
                    <NavLink
                    v-if="permission.indexOf(ModuleActionList.ITEM_MASTER_LIST_VIEW) > -1"
                    :isSub="true"
                    icon="fa-solid fa-list-check"
                    navName="Item Master List"
                    :selected="activeLink"
                    :linkId="LinkList.BOQ_MASTER_LIST"
                    @click="OnNavLinkClick('/boq/' + projectInfo._id + '/master',true)"
                    />
                    <NavLink
                    v-if="permission.indexOf(ModuleActionList.BOQ_VIEW) > -1"
                    :isSub="true"
                    icon="fa-solid fa-file-signature"
                    navName="Bill Of Quantities"
                    :selected="activeLink"
                    :linkId="LinkList.BILL_OF_QUANTITIES"
                    @click="OnNavLinkClick('/boq/' + projectInfo._id,true)"
                    />
                    <NavLink
                    v-if="permission.indexOf(ModuleActionList.CONSOLIDATED_LIST_VIEW) > -1"
                    :isSub="true"
                    icon="fa-solid fa-copy" 
                    navName="Consolidated BoQ"
                    :selected="activeLink"
                    :linkId="LinkList.CONSOLIDATED_LIST_VIEW"
                    @click="OnNavLinkClick('/boq/' + projectInfo._id + '/consolidated',true)"
                    />
                    <NavLink
                    v-if="permission.indexOf(ModuleActionList.RATE_VIEW) > -1"
                    icon="fa-solid fa-comment-dollar" 
                    :isSub="true"
                    navName="Rate List"
                    :selected="activeLink"
                    :linkId="LinkList.BOQ_RATE_LIST"
                    @click="OnNavLinkClick('/boq/' + projectInfo._id + '/rates',true)"
                    />
                    <NavLink
                    :isSub="true"
                    v-if="permission.indexOf(ModuleActionList.BOQ_QUOTATION_VIEW) > -1"
                    icon="fa-solid fa-file-lines" 
                    navName="Quotation"
                    :selected="activeLink"
                    :linkId="LinkList.QUOTATIONS_LIST"
                    @click="OnNavLinkClick('/boq/' + projectInfo._id + '/quotation/list',true)"
                    />
                    <!-- <NavLink
                    v-if="permission.indexOf(ModuleActionList.TRADE_PACKAGE_VIEW) > -1"
                    :isSub="true"
                    icon="fa-solid fa-cubes" 
                    navName="Trade Packages"
                    :selected="activeLink"
                    :linkId="LinkList.TRADE_PACKAGE_LIST"
                    @click="OnNavLinkClick('/boq/' + projectInfo._id + '/packages',true)"
                    /> -->
                    <NavLink
                    icon="fa-solid fa-cubes" 
                    navName="Trade Packages"
                    :selected="activeLink"
                    :linkId="LinkList.TRADE_PACKAGE_LIST"
                    @click="OnNavLinkClick(dotNetUrl + '/tradepackage/'+projectInfo.projectOldId)"
                    />
                    <NavLink
                    icon="fa-solid fa-hand-holding-dollar" 
                    navName="Client Progress Claim"
                    :selected="activeLink"
                    @click="OnNavLinkClick(dotNetUrl + '/progressclaim/'+projectInfo.projectOldId)"
                    />
                    <!-- <h6 class="dropdown-header text-capitalize">Documents</h6>
                    <NavLink
                    icon="fa-solid fa-file-contract"
                    navName="Documents"
                    :selected="activeLink"
                    :linkId="LinkList.DOCUMENTS"
                    /> -->
                </template>
            </ul>
            <div class="sidebar-footer">
                <hr />
                <div class="d-flex justify-content-between pt-0 p-3" >
                    <div class="dropdown">
                        <a href="#" class="d-flex align-items-center link-dark text-decoration-none dropdown-toggle text-capitalize" id="dropdownUser2" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="fa-solid fa-user pe-2"></i>
                            <strong>{{userInfo?.firstName || 'User'}}</strong>
                        </a>
                        <ul class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser2">
                            <h6 class="dropdown-header text-capitalize">{{userInfo?.fullname || 'User'}}</h6>
                            <li><a class="dropdown-item" href="#">Settings</a></li>
                            <li><hr class="dropdown-divider"></li>
                            <li @click="LogoutUser"><a class="dropdown-item" href="#">Sign out</a></li>
                        </ul>
                    </div>
                    <div class="tooltip-custom" @click="changeView">
                        <span role="button" class="ms-3"><i class="fa-solid fa-angles-left fa-xl"></i></span>
                        <span class="tooltiptext-custom">Minimize</span>
                    </div>
                </div>
            </div>
            
        </div>
    </nav>

    <nav id="sidebar-mini" class="bg-white shadow-sm d-print-none" v-else>
        <div class="d-flex flex-column flex-shrink-0 sidebar-height" >
            <a href="#" class="d-flex align-items-center pb-2 mb-md-0  link-dark text-decoration-none p-3 pb-0 border-bottom">
                <img src="/lens.svg" style="height: 2rem;" class="">
                <span class="d-none d-lg-block d-xl-block "></span>
            </a>
            <ul class="nav nav-pills nav-flush flex-column mb-auto text-center mt-1">
                <NavLinkIcon
                icon="fa-solid fa-building"
                :navName="$t('Projects')"
                :selected="activeLink"
                :linkId="LinkList.MAIN_DASHBOARD"
                :link="projectUrl + '/'"
                :isRoute="false"
                />
                
                <div class="dropend">
                    <NavLinkIcon
                    data-bs-toggle="dropdown"
                    icon="fa-solid fa-user-tie"
                    :navName="$t('Organisation')"
                    :selected="activeLink"
                    :linkId="LinkList.MAIN_COMPANY"
                    />
                    <ul class="dropdown-menu text-small shadow" offset="5px">
                        <li><a class="dropdown-item" :href="directoryUrl + '/directory/user'"> {{ $t('User') }}</a></li>
                        <li><a class="dropdown-item" :href="directoryUrl + '/directory/office'"> {{ $t('Office') }}</a></li>
                        <li><a class="dropdown-item" :href="directoryUrl + '/directory/region'"> {{ $t('Region') }}</a></li>
                        <li><a class="dropdown-item" :href="directoryUrl + '/directory/company'"> {{ $t('Company List') }}</a></li>
                    </ul>
                </div>
                <NavLinkIcon
                icon="fa-solid fa-file-invoice-dollar"
                :navName="$t('Cost Code')"
                :selected="activeLink"
                :linkId="LinkList.COST_CODES"
                :link="directoryUrl + '/directory/costcode'"
                :isRoute="false"
                />
                <NavLinkIcon
                icon="fa-solid fa-laptop-file"
                :navName="$t('Bidding')"
                :selected="activeLink"
                :linkId="LinkList.HIGH_LEVEL_ESTIMATES"
                :link="'/high-level-estimates'"
                />
                <div class="dropend">
                    <NavLinkIcon
                    data-bs-toggle="dropdown"
                    icon="fa-solid fa-wrench" 
                    :navName="$t('Administrative Page')"
                    :selected="activeLink"
                    />
                    <ul class="dropdown-menu text-small shadow" offset="5px">
                        <li><a class="dropdown-item" :href="dotNetUrl +'/admin/taxrules'"> {{ $t('Tax Rules') }}</a></li>
                        <li><a class="dropdown-item" :href="dotNetUrl +'/admin/bankinformation'"> {{ $t('Bank Information') }}</a></li>
                        <li><a class="dropdown-item" :href="dotNetUrl +'/admin/currency/rates'"> {{ $t('Currency Rates') }}</a></li>
                        <li><a class="dropdown-item" :href="dotNetUrl +'/admin/signatures'"> {{ $t('Staff Signatures') }}</a></li>
                    </ul>
                </div>
                <template  v-if="projectInfo._id">
                    <NavLinkIcon
                    class="border-top"
                    icon="fa-solid fa-chart-column"
                    :navName="$t('Project Dashboard')"
                    :selected="activeLink"
                    :linkId="LinkList.SUMMARY"
                    :link="projectUrl + '/project/' + projectInfo._id"
                    :isRoute="false"
                    />
                    <NavLinkIcon
                    icon="fa-solid fa-chalkboard-user"
                    :navName="$t('Project Overview')"
                    :selected="activeLink"
                    :link="dotNetUrl + '/project/overview/'+projectInfo.projectOldId"
                    :isRoute="false"
                    />
                    <NavLinkIcon
                    icon="fa-solid fa-list"
                    :navName="$t('Project Details')"
                    :selected="activeLink"
                    :linkId="LinkList.PROJECT_DETAILS"
                    :link="projectUrl + '/project/' + projectInfo._id + '/details'"
                    :isRoute="false"
                    />
                    <NavLinkIcon
                    v-if="permission.indexOf(ModuleActionList.DIRECTORY_VIEW) > -1"
                    icon="fa-solid fa-book-bookmark"
                    :navName="$t('Directory')"
                    :selected="activeLink"
                    :linkId="LinkList.PROJECT_DETAILS"
                    :link="projectUrl + '/project/' + projectInfo._id + '/directory'"
                    :isRoute="false"
                    />
                    <!-- <NavLinkIcon
                    v-if="permission.indexOf(ModuleActionList.PROCESS_VIEW) > -1"
                    icon="fa-solid fa-sitemap"
                    navName="Process List"
                    :selected="activeLink"
                    :linkId="LinkList.PROJECT_PROCESS"
                    :link="projectUrl + '/project/' + projectInfo._id + '/process'"
                    :isRoute="false"
                    /> -->
                    <!-- <NavLinkIcon
                    icon="fa-solid fa-file-circle-check"
                    navName="Approval List"
                    :selected="activeLink"
                    :linkId="LinkList.APPROVAL_LIST"
                    :link="projectUrl + '/project/' + projectInfo._id + '/approvals'"
                    :isRoute="false"
                    /> -->
                    <NavLinkIcon
                    v-if="permission.indexOf(ModuleActionList.ITEM_MASTER_LIST_VIEW) > -1"
                    icon="fa-solid fa-list-check"
                    :navName="$t('Item Master List')"
                    :selected="activeLink"
                    :linkId="LinkList.BOQ_MASTER_LIST"
                    :link="'/boq/' + projectInfo._id + '/master'"
                    :isRoute="true"
                    />
                    <NavLinkIcon
                    v-if="permission.indexOf(ModuleActionList.BOQ_VIEW) > -1"
                    icon="fa-solid fa-file-signature"
                    :navName="$t('Bill of Quantities')"
                    :selected="activeLink"
                    :linkId="LinkList.BILL_OF_QUANTITIES"
                    :link="'/boq/' + projectInfo._id"
                    :isRoute="true"
                    />
                    <NavLinkIcon
                    v-if="permission.indexOf(ModuleActionList.CONSOLIDATED_LIST_VIEW) > -1"
                    icon="fa-solid fa-copy" 
                    :navName="$t('Consolidated Bill of Quantities')"
                    :selected="activeLink"
                    :linkId="LinkList.CONSOLIDATED_LIST"
                    :link="'/boq/' + projectInfo._id + '/consolidated'"
                    :isRoute="true"
                    />
                    <!-- <NavLinkIcon
                    v-if="permission.indexOf(ModuleActionList.RATE_VIEW) > -1"
                    icon="fa-solid fa-comment-dollar" 
                    navName="Rate List"
                    :selected="activeLink"
                    :linkId="LinkList.BOQ_RATE_LIST"
                    :link="'/boq/' + projectInfo._id + '/rates'"
                    :isRoute="true"
                    /> -->
                    <NavLinkIcon
                    v-if="permission.indexOf(ModuleActionList.BOQ_QUOTATION_VIEW) > -1"
                    icon="fa-solid fa-file-lines" 
                    :navName="$t('Quotation')"
                    :selected="activeLink"
                    :linkId="LinkList.QUOTATIONS"
                    :link="'/boq/' + projectInfo._id + '/quotation/list'"
                    :isRoute="true"
                    />
                    <NavLinkIcon
                    icon="fa-solid fa-cubes" 
                    :navName="$t('Trade Packages')"
                    :selected="activeLink"
                    :linkId="LinkList.TRADE_PACKAGE_LIST"
                    :link="dotNetUrl + '/tradepackage/'+projectInfo.projectOldId"
                    :isRoute="false"
                    />
                    <NavLinkIcon
                    icon="fa-solid fa-money-check-dollar" 
                    :navName="$t('Purchase Order')"
                    :selected="activeLink"
                    :link="dotNetUrl + '/PurchaseOrder/purchaseordersList?projectId='+projectInfo.projectOldId"
                    :isRoute="false"
                    />
                    <NavLinkIcon
                    icon="fa-solid fa-hand-holding-dollar" 
                    :navName="$t('Client Progress Claim')"
                    :selected="activeLink"
                    :link="dotNetUrl + '/progressclaim/'+projectInfo.projectOldId"
                    :isRoute="false"
                    />
                    <NavLinkIcon
                    icon="fa-solid fa-arrow-right-arrow-left" 
                    :navName="$t('Change Order')"
                    :selected="activeLink"
                    :link="dotNetUrl + '/change-request/'+projectInfo.projectOldId"
                    :isRoute="false"
                    />
                    <NavLinkIcon
                    icon="fa-solid fa-receipt" 
                    :navName="$t('Client Invoice')"
                    :selected="activeLink"
                    :link="dotNetUrl + '/clientinvoice/'+projectInfo.projectOldId"
                    :isRoute="false"
                    />
                    <NavLinkIcon
                    icon="fas fa-sync" 
                    :navName="$t('Contingency')"
                    :selected="activeLink"
                    :link="dotNetUrl + '/contingency/'+projectInfo.projectOldId"
                    :isRoute="false"
                    />
                    <NavLinkIcon
                    icon="fa-solid fa-helmet-safety" 
                    :navName="$t('Vendor List')"
                    :selected="activeLink"
                    :link="dotNetUrl + '/project/vendor-list/'+projectInfo.projectOldId"
                    :isRoute="false"
                    />
                    <!-- <NavLinkIcon
                    icon="fa-solid fa-user-lock" 
                    navName="Contact Password Manager"
                    :selected="activeLink"
                    :link="dotNetUrl + '/mmoserstaff/ContractManager/'+projectInfo.projectOldId"
                    :isRoute="false"
                    /> -->
                </template>
            </ul>
            <div class="sidebar-mini-footer">
                <div class="dropend border-top">
                    <a href="#" class="d-flex align-items-center justify-content-center p-3 link-dark text-decoration-none dropdown-toggle text-capitalize" 
                    data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="fa-solid fa-user"></i>
                    </a>
                    <ul class="dropdown-menu text-small shadow" >
                        <h6 class="dropdown-header text-capitalize">{{userInfo?.fullname || 'User'}}</h6>
                        <li><a class="dropdown-item" href="#">{{ $t('Settings') }}</a></li>
                        <li><hr class="dropdown-divider"></li>
                        <li @click="LogoutUser"><a class="dropdown-item" href="#"> {{ $t('Sign Out') }}</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import { LogOut } from '@/actions/userActions.js'
import NavLink from '@/components/layout/NavLink.vue'
import NavLinkIcon from '@/components/layout/NavLinkIcon.vue'
import { LinkList,ModuleActionList } from '@/helpers/utilities.js'
import {  useCookies } from "vue3-cookies"
import  { mapGetters, mapActions } from 'vuex'
import 'dotenv/config'
export default {
    name : "SideBar",
    components : {
        NavLink,
        NavLinkIcon
    },
    data(){

        return {
            isBig : false,
            directoryUrl : process.env.VUE_APP_DIRECTORY_URL,
            projectUrl : process.env.VUE_APP_PROJECT_URL,
            dotNetUrl : process.env.VUE_APP_DOTNET_URL,
            isHover : false,
            ModuleActionList : ModuleActionList
        }
    },
    mounted : function(){
        const self = this
        self.GetMyInfo()
    },
    methods : {
        ...mapActions(['GetMyInfo']),
        changeView(){
            this.isBig = !this.isBig
        },
        LogoutUser (){
            const self = this
            const { cookies } = useCookies()
            LogOut().then(()=>{
                cookies.remove('_auzer')
                window.location.href = self.projectUrl + '/signout?signout=yes'
            })
        },
        isActive(linkId, classList){
            if(this.activeLink==linkId)
                return classList + ' active'
            else
                return classList
        },
        OnNavLinkClick(url, isRouterLink = false){
            if(isRouterLink){
                this.$router.push(url)
            }else{
                window.location.href = url
            }
        }
    },
    computed : {
        ...mapGetters(['activeLink','projectInfo','userInfo','permission']),
        LinkList : ()=>LinkList
    }
}
</script>
<style >
</style>