<template>
    <div>
        <BreadcrumbsProject
        :breadcrumbs="[
            {
                link : projectUrl +'/',
                title : $t('Projects'),
                isRouterLink: false
            },
            {
                link : projectUrl +'/Project/' + projectInfo._id,
                title :  (projectInfo.projectNumber || '-') +' ' + (projectInfo.projectName || ''),
                isRouterLink: false
            },
            {
                link : '/boq/' + projectInfo._id,
                title : $t('Bill of Quantities'),
                isRouterLink: true
            }
        ]"
        ></BreadcrumbsProject>
    </div>
    <div >
        <StepItem2></StepItem2>
    </div>
    <div class="border-top border-bottom">
        <div class="card">
            <div class="card-header bg-white">
                <div class="d-flex justify-content-between">
                        <div >
                            <h6 class="fw-bold">{{ $t('Bill of Quantities')}}</h6>
                        </div>
                        <div class="">
                            <button class="btn btn-primary me-1" 
                            data-bs-toggle="modal" data-bs-target="#BoqModalAdd" @click="OpenCreate"
                            ><i class="fa-solid fa-plus"></i> {{ $t('Add BoQ') }}</button>

                            <button class="btn btn-primary me-1" v-if="selected.length>1&&permission.indexOf(ModuleActionList.BOQ_CREATE)>-1"
                            data-bs-toggle="modal" data-bs-target="#BoqCombineModal" @click="OpenCombineModal"
                            ><i class="fa-solid fa-file-circle-plus"></i> Combine BoQ</button>
                            <button class="btn btn-default me-1  pe-2 ps-2"
                            data-bs-toggle="modal" data-bs-target="#BoqImportOfficeTemplates"
                            v-if="permission.indexOf(ModuleActionList.BOQ_CREATE) >-1"
                            @click="OpenOfficeImport" 
                            title="Import Office Template"><i class="fa-solid fa-upload"></i>  </button>
                        </div>
                    </div>
            </div>            
            <div class="card-body">
                <div class="row mb-3">
                    
                    <div class="col-md-12 ps-2">
                        <input class="form-control" v-model="searchBar" :placeholder="$t('Search Here')" @keyup="searchRun($event.target.value)" />
                    </div>
                </div>
                
                <Loader v-if="tableIsLoading"></Loader>
                <div v-else class="table-responsive">
                    <TableBoq  :GetBoq="GetBoq" :search="searchBar" :DeleteBoq="DeleteBoq"
                    :CreateDuplicate="OpenDuplicateModal" :UpdateSelectedValue="UpdateSelectedValue"></TableBoq>
                </div>
            </div>
        </div>
    </div>
    <Modal idModal="BoqModalAdd" :headerTitle="$t('Add BoQ')" modalSize="modal-md" v-if="permission.indexOf(ModuleActionList.BOQ_CREATE)>-1">
        <div class="form-group">
            <label class="required-style"> {{$t('Name')}} </label>
            <input class="form-control" :placeholder="$t('Name')" v-model="boqName" />
            <small class="text-warning" v-if="saveClicked && !boqName">{{ $t('Required') }}</small>
        </div>
         <div class="form-group">
            <label> {{$t('Reference')}} </label>
            <Select2
            indexValue="_id"
            labelName="fullname"
            :placeHolder="$t('Search Here')"
            v-model:optionValue="boqRef"
            :dataOptions="quotationList"
            :willCapitalize="false"
            />
        </div>
        <!-- <div class="form-group">
            <label class="required-style"> Currency  </label>
            <select class="form-select" v-model="boqCurrencyId" >
                <option value="">Select a Currency</option>
                <option v-for="(item, key) in boqCurrency" :key="key" :value="item._id">{{item.sign}} {{item.name}} </option>
            </select>
            <small class="text-warning" v-if="saveClicked && !boqCurrencyId">Required</small>
        </div> -->
        <template v-slot:actions>
            <button type="button" ref="closeAddModal" class="btn btn-outline-danger" data-bs-dismiss="modal">X {{$t('Cancel')}}</button>
            <button type="button" class="btn btn-primary" @click="CreateBoq($event.target)" ><i class="fas fa-save"></i> {{ $t('Save Changes') }}</button>
        </template>
    </Modal>
    <Modal idModal="BoqModalUpdate" :headerTitle="$t('Update BoQ')" modalSize="modal-md" v-if="permission.indexOf(ModuleActionList.BOQ_EDIT)>-1">
        <div class="form-group">
            <label class="required-style"> {{$t('Name')}} </label>
            <input class="form-control" :placeholder="$t('Name')" v-model="boqNameUpdate" />
            <small class="text-warning" v-if="updateClicked && !boqNameUpdate">{{ $t('Required') }}</small>
        </div>
        <div class="form-group">
            <label> {{$t('Reference')}} </label>
            <Select2
            indexValue="_id"
            labelName="fullname"
            :placeHolder="$t('Search Here')"
            v-model:optionValue="boqRefUpdate"
            :dataOptions="quotationList"
            :willCapitalize="false"
            />
            <!-- <input class="form-control" placeholder="Example AB1-12345/Qtn01_01" v-model="boqRefUpdate" /> -->
            <!-- <small class="text-warning" v-if="updateClicked && !boqRefUpdate">Required</small> -->
        </div>
        
        <template v-slot:actions>
            <button type="button" ref="closeUpdateModal" class="btn btn-outline-danger" data-bs-dismiss="modal">X {{$t('Cancel')}}</button>
            <button type="button" class="btn btn-primary" @click="UpdateBoq($event.target)" ><i class="fas fa-save"></i> {{ $t('Save Changes') }}</button>
        </template>
    </Modal>
    <Modal idModal="BoqModalCreateDuplicate" :headerTitle="$t('Duplicate BoQ')" modalSize="modal-md" v-if="permission.indexOf(ModuleActionList.BOQ_DUPLIDATE)>-1">
        <div class="form-group">
            <label>{{$t('Parent Bill of Quantity')}}</label>
            <input class="form-control-plaintext text-capitalize" readonly v-model="boqDuplicatedName" />
        </div>
        <div class="form-group">
            <label class="required-style">{{$t('Name')}} </label>
            <input class="form-control" :placeholder="$t('Name')" v-model="boqDuplicate.boqName" />
            <small class="text-warning" v-if="duplicateClicked && !boqDuplicate.boqName">{{ $t('Required') }}</small>
        </div>
        <div class="form-group">
            <label >{{ $t('Reference') }} </label>
            <Select2
            indexValue="_id"
            labelName="fullname"
            :placeHolder="$t('Search Here')"
            v-model:optionValue="boqDuplicate.boqRef"
            :dataOptions="quotationList"
            :willCapitalize="false"
            />
        </div>
        <template v-slot:actions>
            <button type="button" ref="closeDuplicateModal" class="btn btn-outline-danger" data-bs-dismiss="modal">X {{$t('Cancel')}}</button>
            <button type="button" class="btn btn-primary" @click="DuplicateBoq($event.target)" ><i class="fa-solid fa-copy"></i> {{$t('Duplicate')}}</button>
        </template>
    </Modal>
    <Modal idModal="BoqCombineModal" headerTitle="Combine BoQ" modalSize="modal-md" v-if="permission.indexOf(ModuleActionList.BOQ_CREATE)>-1">
        <small class="text-primary">*Selected Boq will be Merged Into a New BoQ</small> <br/>
        <div class="form-group">
            <label class="required-style">Name </label>
            <input class="form-control" :placeholder="$t('Name')" v-model="boqCombine.boqName" />
            <small class="text-warning" v-if="combineClicked && !boqCombine.boqName">{{ $t('Required') }}</small>
        </div>
        <div class="form-group">
            <label >Reference </label>
            <Select2
            :filterQuery="()=>{}"
            indexValue="_id"
            labelName="fullname"
            placeHolder="Search Quotation here"
            v-model:optionValue="boqCombine.boqRef"
            :dataOptions="quotationList"
            :willCapitalize="false"
            />
        </div>
        <div class="form-group">
            <label>Selected Count</label>
            <input class="form-control-plaintext text-capitalize" readonly :value="boqCombine.selectedCount"/>
            <small class="text-warning" v-if="boqCombine.selectedCount==0">No Selected Boq</small>
        </div>
        <template v-slot:actions>
            <button type="button" ref="closeCombineModal" class="btn btn-outline-danger" data-bs-dismiss="modal">X Cancel</button>
            <button type="button" class="btn btn-primary" v-if="boqCombine.selectedCount!=0" @click="CombineBoqRun"><i class="fa-solid fa-file-circle-plus"></i> Combine</button>
        </template>
    </Modal>
    <Modal idModal="BoqImportOfficeTemplates" :headerTitle="$t('Import Office Template')" modalSize="modal-md" v-if="permission.indexOf(ModuleActionList.BOQ_CREATE)>-1">
        <div class="form-group">
            <label class="required-style">{{$t('File')}} </label>
            <input type="file" ref="uploadFileOfficeTemplate"  class="form-control"  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"   />
            <small class="text-warning" v-if="isImportClicked && !boqOfficeTemplate.file">{{ $t('Required') }}</small>
        </div>
        <div class="form-group">
            <label class="required-style">{{$t('Template Name')}} </label>
            <select class="form-select" v-model="boqOfficeTemplate.templateType">
                <option value="">{{ $t('Select Template') }}</option>
                <option value="1">Kuala Lumpur {{ $t('Office Template') }}</option>
                <option value="2">India {{ $t('Office Template') }}</option>
                <option value="3">Manila {{ $t('Office Template') }}</option>
            </select>
            <small class="text-warning" v-if="isImportClicked && !boqOfficeTemplate.templateType">{{ $t('Required') }}</small>
        </div>
        <div class="form-group">
            <label >{{ $t('Reference') }} </label>
            <Select2
            indexValue="_id"
            labelName="fullname"
            placeHolder="Search Quotation here"
            v-model:optionValue="boqOfficeTemplate.boqRef"
            :dataOptions="quotationList"
            :willCapitalize="false"
            />
        </div>
        <!-- <div class="form-group" >
            <label>Upload Progress</label>
            <div class="progress">
            <div class="progress-bar"
            :class="{ 'progress-bar-striped progress-bar-animated' : uploadPercentage < 100, 'bg-success' : uploadPercentage == 100 }"
             role="progressbar" :style="{ 'width' : uploadPercentage + '%' }" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{{uploadPercentage}}%</div>
            </div>
            <small class="text-info">{{uploadMessage}}</small>
        </div> -->
        <template v-slot:actions>
            <button type="button" ref="closeBoqImportOfficeTemplates" class="btn btn-outline-danger" data-bs-dismiss="modal">X {{$t('Cancel')}}</button>
            <button type="button" class="btn btn-primary" :disabled="isImportRunning"  @click="UploadOfficeTemplate" ><i class="fa-solid fa-upload"></i> {{$t('Import')}}</button>
        </template>
    </Modal>
</template>


<script src="@/assets/boq/BoqList.js" />
