<template>
     <BreadcrumbsProject
        :breadcrumbs="[
            {
                link : projectUrl +'/',
                title : 'Projects',
                isRouterLink: false
            },
            {
                link : projectUrl +'/Project/' + projectInfo._id,
                title : (projectInfo.projectNumber || '-') +' ' + (projectInfo.projectName || ''),
                isRouterLink: false
            },
            {
                link : '/boq/' + projectInfo._id+'/packages',
                title : 'Trade Packages',
                isRouterLink: true
            },
                {
                link : '/boq/' + projectInfo._id+'/packages/consolidated',
                title : 'Add Trade Package',
                isRouterLink: true
            },
        ]"
    ></BreadcrumbsProject>
    <div>
        <StepItem2></StepItem2>
    </div>

    <div class="card border-top border-bottom">
        <div class="card-header bg-white">
            <div class="d-flex justify-content-between">
                <div>
                    <h6 class="fw-bold">Add Trade Package</h6>
                </div>
                <div>
                    <button class="btn btn-primary me-1" v-if="selectedItems.length!=0"
                     data-bs-toggle="modal" data-bs-target="#AddTradePackageModal"
                     @click="OpenTradePackage"
                    ><i class="fa-solid fa-plus"></i> Create Trade Packages</button>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="mb-3">
                <Select2
                    :filterQuery="GetCostCodeActives"
                    indexValue="_id"
                    labelName="costName"
                    placeHolder="Search cost codes here"
                    v-model:optionValue="costCodeSearch"
                    ref="selectCostCode"
                >
                </Select2>
            </div>
            <Pagination
            :changePage="PageChange" 
            :paginationDetails="{totalPages : consolidatedList.totalPages, page : consolidatedList.page}" 
            :havePageCount="true"
            />
            <div class="table-responsive">

                <table class="table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Description</th>
                            <th>Qty</th>
                            <th>Unit</th>
                            <th>Space ID</th>
                        </tr>
                    </thead>
                    <tbody v-if="isLoading">
                        <tr>
                            <td colspan="5"  >
                                <Loader />
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr v-if="consolidatedList.docs.length==0">
                            <td colspan="5" class="text-center" >
                                No Data Found
                            </td>
                        </tr>
                        <template v-for="(item, key) in FormateConsolidatedList" :key="key">
                            <tr @click="OnTrParentClicked(item.data.parents[0].costCodeId)">
                                <td colspan="5" class="bg-light-primary text-capitalize fw-bold" v-if="item.parent" >
                                    <input type="checkbox" v-model="selectedParents" :value="item.data.parents[0].costCodeId" class="me-2" /> {{item.data.parents[0].costCode}} {{item.data.parents[0].name}}
                                </td>
                            </tr>
                            <tr @click="OnTrClicked(item.data._id)" >
                                <td style="width : 0px"><input type="checkbox" v-model="selectedItems" :value="item.data._id" /></td>
                                <td style="width : 700px">
                                    <p>
                                        <span class="fw-bold text-capitalize">{{item.data.parents[1].costCode}} {{item.data.parents[1].name}} </span> <br/>
                                        <span class=" text-capitalize">{{item.data.costCode}} {{item.data.name}} </span> <br/>
                                        <small>
                                            {{item.data.description}}
                                        </small>
                                    </p>
                                </td>
                                <td> {{item.data.quantity}} </td>
                                <td>{{item.data.unit}}</td>
                                <td class="text-capitalize">{{item.data.area}}</td>
                            </tr>
                        </template>
                        
                    </tbody>
                </table>
            </div>
            <Pagination
            :changePage="PageChange" 
            :paginationDetails="{totalPages : consolidatedList.totalPages, page : consolidatedList.page}" 
            :havePageCount="true"
            />
        </div>
    </div>
    <Modal idModal="AddTradePackageModal" headerTitle="Add Trade Package">
        <div>
            <div class="form-group">
                <label class="required-style"> Existing Trade Package? </label>
                <select class="form-select" v-model="newPackage.existing">
                    <option value="0">New</option>
                    <option value="1">Existing</option>
                </select> 
            </div>
            <div class="form-group" v-if="newPackage.existing=='1'">
                <label class="required-style">Trade Package </label>
                <Select2
                    :filterQuery="SearchTradePackage"
                    indexValue="_id"
                    labelName="name"
                    placeHolder="Search Trade Packages here"
                    v-model:optionValue="newPackage.tradePackageId"
                    ref="selectCostCode"
                >
                </Select2>
            </div>
            <div class="form-group" v-else>
                <label class="required-style">Trade Package Name </label>
                <input type="text" class="form-control" v-model="newPackage.name" /> 
            </div>
            <div class="form-group">
                <label >Selected Count </label>
                <input class="form-control-plaintext" readonly :value="selectedItems.length" />
            </div>
        </div>
        <template v-slot:actions>
            <button type="button" ref="closeAddTradeModal" class="btn btn-outline-danger" data-bs-dismiss="modal">X Cancel</button>
            <button type="button" class="btn btn-primary" @click="SaveTradePackage" ><i class="fas fa-save"></i> Add Trade Package</button>
        </template>
    </Modal>
</template>

<script src="@/assets/boq/ConsolidatedList.js" />