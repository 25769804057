import axios from 'axios'
import { CreateToken } from '../helpers/jwtHelpers.js'
import 'dotenv/config'

const MAIN_API = process.env.VUE_APP_REST_MAIN_API
const TOKEN_INDEX_MAIN = 0

axios.defaults.withCredentials = true


export const GetProject = async (id) =>{
    return axios.get(`${MAIN_API}/api/project/${id}`,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.status)
}

export const SearchProjectProcessView = async (projectId ="", search ="") =>{
    return axios.get(`${MAIN_API}/api/project/process/search/view`,
    {
        params : {
            search : search,
            projectId  :projectId
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>{ return {} })
}

export const SearchProjectProcessApproval = async (projectId ="", search ="") =>{
    return axios.get(`${MAIN_API}/api/project/process/search/approval`,
    {
        params : {
            search : search,
            projectId  :projectId
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>{ return {} })
}

export const GetProcessItem = async (id, isBoq = 0) =>{
    return axios.get(`${MAIN_API}/api/project/approval/item/${id}`,
    {
        params : {
            isBoq : isBoq
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>{ return {} })
}

export const GetProcessItemQuotation = async (id) =>{
    return axios.get(`${MAIN_API}/api/project/approval/item/${id}/quotation`,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>{ return {} })
}
export const GetProcessItemBoq = async (id) =>{
    return axios.get(`${MAIN_API}/api/project/approval/item/${id}/boq`,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>{ return {} })
}

export const UserSendEmailBulk = async (data) =>{
    return axios.post(`${MAIN_API}/api/project/process/email/bulk`,
    data
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const UserSendEmailProcess = async (data) =>{
    return axios.post(`${MAIN_API}/api/project/${data.id}/process/email`,
    data
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

//workflow new actions
export const UserApproveWorkFlow = async (id, notes) =>{
    return axios.put(`${MAIN_API}/api/project/workflow/approve/${id}`,
    {
        notes : notes
    }
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const AnswerBehalfExternalUserWorkFlow = async (id, externalUser, type) =>{
    return axios.put(`${MAIN_API}/api/project/workflow/approve/${id}/behalf`,
    {
        externalUser : externalUser,
        type : type
    }
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const UserDisapproveWorkFlow = async (id, notes) =>{
    return axios.put(`${MAIN_API}/api/project/workflow/disapprove/${id}`,
    {
        notes : notes
    }
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const UserSkipWorkFlow = async (id) =>{
    return axios.put(`${MAIN_API}/api/project/workflow/skip/${id}`,
    {}
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const UserExternalSkipWorkFlow = async (id,notes) =>{
    return axios.put(`${MAIN_API}/api/project/workflow/skip/external/${id}`,
    {
        notes : notes
    }
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const EndWorkflow = async (id) =>{
    return axios.put(`${MAIN_API}/api/project/workflow/end/${id}`,
    {}
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const ReviewWorkFlow = async (id) =>{
    return axios.put(`${MAIN_API}/api/project/workflow/review/${id}`,
    {}
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const GetWorkflowItem = async (id) =>{
    return axios.get(`${MAIN_API}/api/project/workflow/${id}`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>[])
}
//approve disapprove skip process

export const UserApproveProcess = async (id) =>{
    return axios.put(`${MAIN_API}/api/project/process/approve/${id}`,
    {}
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const UserDisapproveProcess = async (id) =>{
    return axios.put(`${MAIN_API}/api/project/process/disapprove/${id}`,
    {}
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const UserSkipProcess = async (id,userId) =>{
    return axios.put(`${MAIN_API}/api/project/process/skip/${id}`,
    {
        userId : userId
    }
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const StepSkipProcess = async (id,roleId) =>{
    return axios.put(`${MAIN_API}/api/project/process/step/skip/${id}`,
    {
        roleId : roleId
    },
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}



//end approve disapprove skip process

export const ReviewItemMakerView = async (id) =>{
    return axios.put(`${MAIN_API}/api/project/review/marker/view/${id}`,
    {}
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const SearchUserFromProjectDirectory = async (search ="", role ="", projectId = "") =>{
    return axios.get(`${MAIN_API}/api/directory/search/user/project`
    ,{
        params : {
            search : search,
            role : role,
            projectId : projectId
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>[])
}

export const SearchMMoserUserFromProjectOrOrganization= async (search, projectId,isOrganization = 1 , officeId = '') =>{
    return axios.get(`${MAIN_API}/api/project/userwide/search`
    ,{
        params : {
            search : search || "",
            projectId : projectId || "",
            isOrganization: isOrganization,
            officeId : officeId,
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>[])
}
export const SearchExternalUserFromProjectOrOrganization= async (search, projectId, isMaster) =>{
    return axios.get(`${MAIN_API}/api/project/externalwide/search`
    ,{
        params : {
            search : search || "",
            projectId : projectId || "",
            isMaster : isMaster
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>[])
}

export const AddUserToStepProcessLinked = async (data) =>{

    return axios.put(`${MAIN_API}/api/project/${data.id}/process/${data.processId}/items/user/linked`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

//#region Comment Section
export const GetCommentsList = async (data) =>{
    return axios.get(`${MAIN_API}/api/project/comment/list/${data.id}`,
    {
        params : {
            page : data.page || ''
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>{ return {} })
}

export const AddCommentToList = async (data) =>{
    return axios.post(`${MAIN_API}/api/project/comment`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>{ return {} })
}
//#endregion

//directory actions
export const SearchUomDirectory  = async () =>{
    return axios.get(`${MAIN_API}/api/directory/search/uom`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>console.log(err))
}

export const SearchAllActiveCostCodes =  async (search)=>{
    return axios.get(`${MAIN_API}/api/directory/search/costCode/all/active`
    ,{
        params : {
            search : search
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>console.log(err))
}

//sharepoint token access actions

export const GetTokenSharepointFromAuth = async () =>{
    return axios.get(`${MAIN_API}/api/auth/token/sharepoint/get`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>null)
}

export const GetSystemSettings = async () =>{
    return axios.get(`${MAIN_API}/api/project/get/system/settings`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>null)
}

export const GetProjectSharepointFolders = async (projectId) =>{
    return axios.get(`${MAIN_API}/api/project/me/sharepoint/folders`
    ,{
        params : {
            projectId : projectId,
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>null)
}

export const SearchProjectsOrganisation = async (search) =>{
    return axios.get(`${MAIN_API}/api/project/organisation/search`
    ,{
        params : {
            search : search,
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>null)
}