import { useCookies } from "vue3-cookies"
import 'dotenv/config'
export default function Auth({ next, router, to }) {
    const REST_API = process.env.VUE_APP_REST_MAIN_API
    const { cookies } = useCookies()
    const code = to.query._reqUzcode
    if(code){
        cookies.set('_auzer', 'checker','1d','/','',true,'Strict')
        router.push(cookies.get('_redirreq'))
    }else{
        if(!cookies.get('_auzer')){
            cookies.set('_redirreq', to.fullPath ,'15min','/','',true,'Strict')
            window.location.href = REST_API + "/api/auth/sso?origin="+window.location.origin
        }
    }
  
    return next()
}