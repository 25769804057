<template>
    <table class="table table-bordered">
        <thead>
            <tr>
                <th class="col-2">{{ $t('S/N') }}</th>
                <th class="col-4">{{ $t('Description') }}</th>
                <th class="text-end col-3"> {{$t('Amount')}} / {{ 'm2' }} ({{projectInfo.currencyId?.sign}})</th>
                <th class="text-end col-3">{{$t('Amount Total')}} ({{projectInfo.currencyId?.sign}})</th>
            </tr>
        </thead>
    </table>
    <table class="table table-bordered" v-for="(item, key) in dataRow" :key="key" >
        <tbody class="">
                <tr class="bg-light fw-bold " >
                    <td class="col-2"></td>
                    <td colspan="3" class="text-capitalize "  >
                        <router-link :to="{name : 'BoqBuilder', params : {id : item._id}}" class="link-item-route" v-if="!isRevision"> 
                            <i class="fa-solid fa-arrow-up-right-from-square d-print-none"></i> {{item.name}} 
                        </router-link>
                        <span v-else>{{item.name}} </span>
                    </td>
                </tr>
                <BoqQuotationSectionRows :boqItem="item" :sections="item.data.sections" :size="projectInfo.squareMeter" />
                <tr  class="fw-bold text-end "> 
                    <td class="col-2"></td>
                    <td class="text-end text-capitalize col-4">{{$t('Sub Total Of')}} {{item.name}}  </td>
                    <td class="col-3 text-end">{{ ReformatNumber(item.data.overAllTotal?.sellTotal / projectInfo.squareMeter) }}</td>
                    <td class="col-3 text-end">{{ ReformatNumber(item.data.overAllTotal?.sellTotal) }}</td>
                </tr>
            
        </tbody>
    </table>
    <table class="table table-bordered">
        <tbody>
            <tr  class="fw-bold text-end bg-light-primary"> 
                <td class="col-2"></td>
                <td class="text-end col-4">{{$t('Overall Total')}}</td>
                <td class="col-3 text-end">{{ ReformatNumber(totalAll.perSize) }}</td>
                <td class="col-3 text-end">{{ ReformatNumber(totalAll.total) }}</td>
            </tr>
            <tr  class="fw-bold text-end"> 
                <td colspan="3" class="text-end">{{$t('Approximate Area')}}: {{ projectInfo.squareMeter }}m2</td>
                <td class="col-3 text-end"></td>
            </tr>
        </tbody>
    </table>
</template>
<style scoped>
@media print{
    .table {
        font-size: 8px !important;
    }
}
</style>
<script>
import BoqQuotationSectionRows from '@/components/boq/BoqQuotationSectionRows.vue'
import {mapGetters} from 'vuex'
import {ReformatNumber} from '@/helpers/utilities.js' 
export default {
    name : 'BoqQuotationBoqSummary',
    props : {
        isRevision : {
            type : Boolean,
            default : false
        },
        dataRow : {
            type : Array,
            default : ()=>[]
        },
        unitOfMeasure : {
            type : String,
            default : ''
        },

    },
    components : {
        BoqQuotationSectionRows
    },
    methods : {
        ReformatNumber
    },
    data(){
        return {
            
        }
    },
    computed : {
        ...mapGetters(['projectInfo']),
        totalAll(){

            const self = this
            const data = self.dataRow

            let totals =  {
                    total : 0,
                    perSize :0
                }

            if(data.length==0){
                return   totals
            }

            for(let x = 0; x< data.length;x++){
                const getData = data[x].data.overAllTotal
                if(getData){
                    totals.total += getData.sellTotal
                }
            }


            totals.perSize = totals.total / self.projectInfo.squareMeter

            return totals

        },
        ReformatBoqSummary(){
            const self = this

            if(self.dataRow.length==0){
                return []
            }


            let parentLetter = 65
            let totalOverAll = {
                amount : 0,
                amountTotal : 0
            }

            let totalPerCategory= {
                amount : 0,
                amountTotal : 0
            }

            let totalPerSub = {
                amount : 0,
                amountTotal : 0
            }

            

            let reformat = self.dataRow.map((u)=>{

                u.letter = String.fromCharCode(parentLetter++)
                let mainParentId = ''
                let mainParentCount = 1
                let subCountLabel = 1

                const rowCount = u.data.length
                let subCount = 1
                for(let x = 0; x < rowCount; x++){
                    const getData = u.data[x]
                    if(getData.parents[0].costCodeId!=mainParentId){
                        mainParentId = getData.parents[0].costCodeId
                        subCountLabel = (mainParentCount++)
                        getData.mainCateg = {
                            label : u.letter + subCountLabel,
                            name : getData.parents[0].name
                        }
                        subCount= 1
                    }

                    getData.sellRate =getData.sellTotal / (self.projectInfo.squareMeter || 0) 

                    
                    totalPerSub.amount += getData.sellRate
                    totalPerSub.amountTotal += getData.sellTotal
                    
                    totalPerCategory.amount += getData.sellRate
                    totalPerCategory.amountTotal += getData.sellTotal


                    getData.label = u.letter +  subCountLabel + '.' + (subCount++)
                    getData.sellRate = ReformatNumber(u.data[x].sellRate)
                    getData.sellTotal = ReformatNumber(u.data[x].sellTotal)

                    if(x == rowCount - 1){
                        getData.totalCategory = {
                            label : getData.parents[0].name,
                            amount :  ReformatNumber(totalPerCategory.amount),
                            amountTotal :  ReformatNumber(totalPerCategory.amountTotal)
                        }
                        
                        totalPerCategory= {
                            amount : 0,
                            amountTotal : 0
                        }
                    }
                    else if (getData.parents[0].costCodeId!=u.data[x + 1].parents[0].costCodeId){
                        getData.totalCategory = {
                            label : getData.parents[0].name,
                            amount :  ReformatNumber(totalPerCategory.amount),
                            amountTotal :  ReformatNumber(totalPerCategory.amountTotal)
                        }
                        
                        totalPerCategory= {
                            amount : 0,
                            amountTotal : 0
                        }
                    }


                }

                
                totalOverAll.amount += totalPerSub.amount
                totalOverAll.amountTotal += totalPerSub.amountTotal

                u.subTotal = {
                    label : u.name,
                    amount :  ReformatNumber(totalPerSub.amount),
                    amountTotal :  ReformatNumber(totalPerSub.amountTotal)
                }


                totalPerSub = {
                    amount : 0,
                    amountTotal : 0
                }
                return u
            })

            reformat[self.dataRow.length - 1].totalOverAll = {
                amount : ReformatNumber(totalOverAll.amount),
                amountTotal : ReformatNumber(totalOverAll.amountTotal),
            }

            return {
                data : reformat,
                total : totalOverAll
            }
        }
    }
    
}
</script>