<template>
  <div class="wrapper">
    <Sidebar />    
    <div  id="content">
      <NavBar  />
      <div  >
        <router-view />
      </div>
    </div>
  </div>
  
</template>

<script>
import NavBar from './components/layout/NavBar.vue'
import Sidebar from './components/layout/Sidebar.vue'
import { CheckSession } from '@/actions/userActions.js'
import { useCookies } from "vue3-cookies"
import 'dotenv/config'
import { Tooltip } from 'bootstrap'

export default {
  name: 'App',
  components: {
    NavBar,
    Sidebar
  },
  mounted (){
    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    })
  },
  setup(){
    const { cookies } = useCookies()
    if(cookies.get('_auzer')){
      CheckSession().then((res)=>{
        if(!res){
          cookies.set('_redirreq', window.location.pathname ,'15min','/','',true,'Strict')
          cookies.remove('_auzer')
          window.location.href = process.env.VUE_APP_REST_MAIN_API + "/api/auth/sso?origin="+window.location.origin
        }
      })
    }
  }
}
</script>

<style scoped>
.sticky-top{
  position: sticky;
  top: 0;
}
</style>