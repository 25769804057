<template>
    <td :class="'text-end fw-bold ' + colorClass" v-for="(item, key) in ColumnNeeded" :key="key">
        <span v-if="item.headerIndex">
            {{ReformatNumber(data[item.headerIndex])}}
        </span>
    </td>
    <!-- Quantity-->
    <!-- <template v-if="selectedColumns.indexOf(0)>-1">
        <td class="text-end fw-bold first-cells"></td>
        <td class=" text-end fw-bold first-cells"></td>
        <td class=" text-end fw-bold first-cells"></td>
    </template>
    <td class=" text-end fw-bold first-cells" v-else></td> -->
    <!-- cost rate-->
    <!-- <template v-if="selectedColumns.indexOf(1)>-1">
        <td class=" text-end fw-bold second-cells"></td>
        <td class=" text-end fw-bold second-cells"></td>
        <td class=" text-end fw-bold second-cells"></td>
        <td class=" text-end fw-bold second-cells"></td>
        <td class=" text-end fw-bold second-cells"></td>
        <td class=" text-end fw-bold second-cells"></td>
        <td class=" text-end fw-bold second-cells"></td>
    </template>
    <td class=" text-end fw-bold second-cells" v-else></td> -->
    <!-- cost total-->
    <!-- <template v-if="selectedColumns.indexOf(2)>-1">
        <td class=" text-end fw-bold first-cells">{{ReformatNumber(data.supplyAndInstallTotal)}}</td>
        <td class=" text-end fw-bold first-cells">{{ReformatNumber(data.costTotalBuffer)}}</td>
        <td class=" text-end fw-bold first-cells">{{ReformatNumber(data.costTotalTotal)}}</td>
    </template>
    <td class=" text-end fw-bold first-cells" v-else>{{ReformatNumber(data.costTotalTotal)}}</td>
    <td class="bg-light-primary"></td> -->
    <!-- sell rate-->
    <!-- <template v-if="selectedColumns.indexOf(3)>-1">
        <td class=" text-end fw-bold second-cells"></td>
        <td class=" text-end fw-bold second-cells"></td>
        <td class=" text-end fw-bold second-cells"></td>
    </template>
    <td class=" text-end fw-bold second-cells" v-else></td> -->
    <!-- sell total-->
    <!-- <template v-if="selectedColumns.indexOf(4)>-1">
        <td class=" text-end fw-bold first-cells">{{ReformatNumber(data.sellTotal)}}</td>
        <td class=" text-end fw-bold first-cells">{{ReformatNumber(data.sellTotalBuffer)}}</td>
        <td class=" text-end fw-bold first-cells">{{ReformatNumber(data.sellTotalTotal)}}</td>
    </template>
    <td class=" text-end fw-bold first-cells" v-else>{{ReformatNumber(data.sellTotalTotal)}}</td> -->
    <!-- GP-->
    <!-- <template v-if="selectedColumns.indexOf(5)>-1">
        <td class=" text-end fw-bold second-cells">{{ReformatNumber(data.gp)}}</td>
        <td class=" text-end fw-bold second-cells">{{ReformatNumber(data.gpBuffer)}}</td>
        <td class=" text-end fw-bold second-cells">{{ReformatNumber(data.gpTotal)}}</td>
    </template>
    <td class=" text-end fw-bold second-cells" v-else>{{ReformatNumber(data.gpTotal)}}</td> -->
</template>

<script>
import {BoQColumnList} from '@/helpers/utilities.js'
export default {
    name : 'BoqLineItemRowHeader',
    props : ['data','selectedColumns','ReformatNumber','className'],
    data(){
        return {
            BoQColumnList : BoQColumnList
        }
    },
    computed: {
        colorClass(){
            return this.className || 'bg-light-primary'
        },
        ColumnNeeded(){
            return BoQColumnList.filter(u=>this.selectedColumns.indexOf(u.value) > -1)
        }
    }
}
</script>