<template>
    <template v-for="(item ,key) in sections" :key="key">
        <tr class="" >
            <td  class="col-2" >{{item.label}}</td>
            <td class="col-4 text-capitalize" :class="{'ps-4' : item.level==2,'ps-5' : item.level==3}">
                {{item.name}} 
            </td>
            <td class="col-3 text-end">
                {{ item.sum  ? ReformatNumber(item.sum.sellTotal / size) : ''}} 
            </td>
            <td class="col-3 text-end">
                {{ item.sum  ? ReformatNumber(item.sum.sellTotal) : ''}}
            </td>
        </tr>
        <BoqQuotationSectionRows :sections="item.childs" :boqItem="boqItem" :size="size" />
    </template>
</template>


<script>
import BoqQuotationSectionRows from '@/components/boq/BoqQuotationSectionRows.vue'
import {ReformatNumber } from '@/helpers/utilities.js'
export default {
    name : 'BoqQuotationSectionRows',
    props : ['boqItem','sections','size'],
    components : {
        BoqQuotationSectionRows
    },
    methods :{
        ReformatNumber
    }
}
</script>