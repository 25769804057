<template>
     <BreadcrumbsProject
        :breadcrumbs="[
            {
                link : projectUrl +'/',
                title : $t('Projects'),
                isRouterLink: false
            },
            {
                link : projectUrl +'/Project/' + projectInfo._id,
                title : (projectInfo.projectNumber || '-') +' ' + (projectInfo.projectName || ''),
                isRouterLink: false
            },
            {
                link : '/boq/' + projectInfo._id,
                title : $t('Bill of Quantities'),
                isRouterLink: true
            },
            {
                link : '/boq/builder/' + boqItem._id,
                title : (boqItem.name || '-') ,
                isRouterLink: true
            }
        ]"
    ></BreadcrumbsProject>
    <div class="card">
        <div class="card-header bg-white">
            <div class="d-flex justify-content-between">
                <div>
                    <h6 class="mb-0">{{ $t('Bill of Quantities') }}</h6>
                </div>
                <div class="btn-group">
                    <router-link class="btn btn-primary" :to="{name : 'BoqReviewerSetup', query : { boqId : boqItem._id} }"
                    v-if="boqItem.approvalStage !=ApprovalStageBoq.FOR_APPROVAL &&
                    permission.indexOf(ModuleActionList.BOQ_EDIT) >-1 && !IsQuotationPublished" 
                    ><i class="fa-solid fa-file-pen"></i> {{$t('Send For Approval')}}</router-link>

                    <ButtonTemplateDownloader v-if="!IsQuotationPublished" />
                    <button class="btn btn-default "
                    data-bs-toggle="modal" data-bs-target="#ModalImportBoq"
                    v-if="permission.indexOf(ModuleActionList.BOQ_EDIT) >-1 && !IsQuotationPublished"
                    @click="OpenImportModal"
                    title="Import File"><i class="fa-solid fa-file-import"></i> {{$t('Import')}}  </button>
                    <button class="btn btn-default " title="Export to File"
                    data-bs-toggle="modal" data-bs-target="#BoqExportBoqList"
                    @click="()=>{ exportTemplateType= 0}"
                    ><i class="fa-solid fa-file-export"></i> {{ $t('Export') }} </button>
                    
                    <router-link  title="Print Bill of Quantity" :to="{name : 'BoqItemsPrint' , params : {id : boqId}}"  class="btn btn-default"><i class="fa-solid fa-print"></i> {{ $t('Print') }}</router-link>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="alert alert-outline-info d-flex align-items-center " role="alert" v-if="processItemData">
                <i class="fas fa-info-circle fa-2x me-2"></i>  
                <div>
                    {{$t('Bill of Quantity Module.This Bill of Quantity is ongoing approval')}} . <router-link class="link-style-custom" :to="{name : 'Review', params : {id : processItemData._id}}">{{$t('Go to Approval')}}</router-link>
                </div>
            </div>
            <div class="alert alert-outline-info d-flex align-items-center " role="alert" v-if="haveUnassignedLineItems">
                <i class="fas fa-info-circle fa-2x me-2"></i>  
                <div>
                    {{ $t('This Bill of Quantity has an unassigned Line items') }}. <router-link class="link-style-custom" :to="{name : 'BoqBuilderUnassigneds', params : {id : boqItem._id}}">{{$t('Assign Line Items')}}</router-link>
                </div>
            </div>
            <div class="d-flex justify-content-between mb-1">
                <div>
                    <div v-if="boqItem.reference">
                        <small> {{ $t('Reference') }} :</small> 
                        <router-link :to="{name : 'BoqQuotation', params : {id : boqItem.reference._id} }" 
                         class="small text-primary fw-bold">
                         {{boqItem.reference.quotationNumber}} {{boqItem.reference.quotationName}} <span v-if="IsQuotationPublished" class="text-success">({{$t('Published')}})</span>
                        </router-link>
                    </div>
                </div>
                <div  class="">
                    <span :class="'badge ' + ApprovalStageBoqObj[boqItem.approvalStage -1].color">
                        {{ApprovalStageBoqObj[boqItem.approvalStage-1].name}}
                    </span>
                    
                </div>
            </div>
            <div class="d-flex justify-content-between mb-1">
                <div>
                    
                </div>
                <div>
                    
                    <button class=" btn btn-default text-black me-1" v-if="haveMoved" @click="CancelSaveSequence"><i class="fa-solid fa-ban"></i> {{ $t('Cancel') }}</button>
                    <button class=" btn btn-outline-primary " v-if="haveMoved" @click="SaveSequences"><i class="fa-solid fa-list-ol"></i> {{ $t('Save Sequence') }}</button>
                </div>
            </div>
            <div>
                <div class="d-flex fw-bold" style="background-color: #F5F6FA;" >
                    <div class=" p-2 border border-end-0 " style="width: 5%;"></div>
                    <div class=" p-2 border border-end-0" style="width: 30%;">{{$t('Section')}}</div>
                    <div class="p-2 border border-end-0 " style="width: 12%;">{{ $t('Total Cost') }} ({{boqItem.currencyId?.sign}})</div>
                    <div class=" p-2 border border-end-0" style="width: 12%;">{{$t('Total Sell')}} ({{boqItem.currencyId?.sign}})</div>
                    <div class=" p-2 border border-end-0" style="width: 12%;">{{ $t('GP Total') }} ({{boqItem.currencyId?.sign}})</div>
                    <div class=" p-2 border border-end-0" style="width: 12%;">{{$t('GP')}} %</div>
                    <div class=" p-2 border" style="width: 17%;"></div>
                    
                </div>
                <div class=" small p-2 border border-top-0" style="min-height: 37.8px;" v-if="!IsQuotationPublished">
                    <div class=" text-end "  >
                        <small class="fw-bold text-success " @click="OpenAddNewSection" role="button" data-bs-toggle="modal" data-bs-target="#BoQAddMainSectionModal">
                            <i class="fa-solid fa-plus"></i> {{$t('Add Main Section')}}
                        </small>
                    </div>
                </div>
                <div class="" v-if="sectionList.length==0">
                    <div class="text-center p-2 border border-top-0">
                        <span class="fw-bold">{{$t('No data Found')}}</span>
                    </div>
                </div>
                <div class="parent ">
                    <BoqSectionRow :OpenRename="OpenRename" :BoqItem="boqItem" :OpenAddNewSubSection="OpenAddNewSubSection" :DeleteMain="DeleteMain" :SectionList="sectionList" :Level="1"
                    :OnEnd="OnEnd" :OpenMarkUp="OpenMarkUp" :IsQuotationPublished="IsQuotationPublished" >
                    
                    </BoqSectionRow>
                </div>
                <div class="d-flex small fw-bold bg-light-primary" >
                    <div class="  p-2 border border-end-0 border-top-0" style="width: 5%;">
                    </div>
                    <div class=" text-end p-2 border border-end-0 border-top-0 " style="width: 30%;">
                        <span class="fw-bold text-capitalize">{{ $t('Overall Total') }}</span>
                    </div>
                    <div class=" p-2 border border-end-0 border-top-0 text-end" style="width: 12%;" v-html="ReformatNumberHtml(overAllTotal.costTotal)">
                    </div>
                    <div class=" p-2 border border-end-0 border-top-0 text-end" style="width: 12%;" v-html="ReformatNumberHtml(overAllTotal.sellTotal)">
                    </div>
                    <div class=" p-2 border border-end-0 border-top-0 text-end" style="width: 12%;" v-html="ReformatNumberHtml(overAllTotal.gp)">
                    </div>
                    <div class=" p-2 border border-end-0 border-top-0 text-end" style="width: 12%;" v-html="ReformatNumberHtml(overAllTotal.gpPercentage,0,2)">
                    </div>
                    <div class="p-2 border border-top-0" style="width: 17%;">
                    </div>
                </div>


            </div>
        </div>
    </div>
    <Modal idModal="RenameSectionModal" :headerTitle="$t('Update Section')" modalSize="modal-md" >
        <div class="form-group">
            <label class="required-style">{{ $t('Section Name') }} </label>
            <input class="form-control" :placeholder="$t('Section Name')" v-model="renameSection.name" />
            <small class="text-warning" v-if="isActionClicked && !renameSection.name">{{ $t('Required') }}</small>
        </div>
        <template v-slot:actions>
            <button type="button" ref="closeRenameSectionModal" class="btn btn-outline-danger" data-bs-dismiss="modal">X {{$t('Cancel')}}</button>
            <button type="button" class="btn btn-primary" @click="RenameSection"><i class="fa-solid fa-save"></i> {{$t('Update')}}</button>
        </template>
    </Modal>
    <Modal idModal="BoQAddMainSectionModal" :headerTitle="$t('Add Main Section')" modalSize="modal-md" >
        <div class="form-group">
            <label class="required-style">{{ $t('Section Name') }} </label>
            <input class="form-control" :placeholder="$t('Section Name')" v-model="newSection.name" />
            <small class="text-warning" v-if="isActionClicked && !newSection.name">{{ $t('Required') }}</small>
        </div>
        <template v-slot:actions>
            <button type="button" ref="closeBoQAddMainSectionModal" class="btn btn-outline-danger" data-bs-dismiss="modal">X {{$t('Cancel')}}</button>
            <button type="button" class="btn btn-primary" @click="AddMainSection"><i class="fa-solid fa-save"></i> {{$t('Add')}}</button>
        </template>
    </Modal>
    <Modal idModal="BoQAddSubSectionModal" :headerTitle="$t('Add Sub Section')" modalSize="modal-md" >
        <div class="form-group">
            <label>{{$t('Main Section Name')}} </label>
            <input class="form-control-plaintext text-capitalize" disabled :value="newSubSection.mainName" />
        </div>
        <div class="form-group">
            <label class="required-style">{{$t('Sub Section Name')}} </label>
            <input class="form-control" :placeholder="$t('Section Name')" v-model="newSubSection.name" />
            <small class="text-warning" v-if="isActionClicked && !newSubSection.name">{{ $t('Required') }}</small>
        </div>
        <template v-slot:actions>
            <button type="button" ref="closeBoQAddSubSectionModal" class="btn btn-outline-danger" data-bs-dismiss="modal">X {{$t('Cancel')}}</button>
            <button type="button" class="btn btn-primary" @click="AddNewSubSection"><i class="fa-solid fa-save"></i> {{$t('Add')}}</button>
        </template>
    </Modal>
    <Modal idModal="BoQMarkUpUpdateModal" :headerTitle="$t('Update Section Mark Up')" modalSize="modal-md" >
        <div class="form-group">
            <label>{{ $t('Section Name') }} </label>
            <input class="form-control-plaintext text-capitalize" disabled placeholder="Section" :value="markUpUpdate.name" />
        </div>
        <div class="form-group">
            <label>{{ $t('Mark Up') }} </label>
            <div class="input-group ">
                <input type="number" class="form-control"  v-model="markUpUpdate.markUp" max="100" min="0"/>
                <span class="input-group-text">%</span>
            </div>
            <small class="text-warning" v-if="isActionClicked && (markUpUpdate.markUp < 0 || markUpUpdate.markUp > 100)">{{ $t('Required') }}</small>
        </div>
        <template v-slot:actions>
            <button type="button" ref="closeBoQMarkUpUpdateModal" class="btn btn-outline-danger" data-bs-dismiss="modal">X {{$t('Cancel')}}</button>
            <button type="button" class="btn btn-primary" @click="UpdateMarkUp"><i class="fa-solid fa-save"></i> {{$t('Save')}}</button>
        </template>
    </Modal>
    <ModalImportLineItems
    v-if="boqItem.projectId && permission.indexOf(ModuleActionList.BOQ_EDIT) >-1"
    ref="modalImportLineItem"
    :boqList="[]" :ResetData="HaveUnassigned" :boqId="boqItem._id" :projectId="boqItem.projectId" />
    <Modal idModal="BoqExportBoqList" :headerTitle="$t('Export')"  >
        <div>
            <div class="form-group">
                <label class="required-style">{{$t('Template')}} </label>
                <select class="form-select" v-model="exportTemplateType"> 
                    <option v-for="(item,key) in ExportTemplatesForExport" :key="key" :value="key">{{item}}</option>
                </select>
            </div>
        </div>
        <template v-slot:actions>
            <div>
                <button type="button" ref="closeBoqExportBoqList" class="btn btn-outline-danger" data-bs-dismiss="modal">X {{$t('Cancel')}}</button>
            </div>
            <button type="button" class="btn btn-primary" @click="ExportBoqList" >
                <i class="fa-solid fa-file-arrow-down"></i>  {{ $t('Export') }}
            </button>
        </template>
    </Modal>
</template>


<script>
import Modal  from '@/components/layout/Modal.vue'
import BoqSectionRow from '@/components/boq/BoqSectionRow.vue'
import ModalImportLineItems from '@/components/boq/ModalImportLineItems.vue'
import ButtonTemplateDownloader from '@/components/boq/ButtonTemplateDownloader.vue'
import { LinkList,ReformatNumberHtml, ApprovalStageBoqObj, ApprovalStageBoq, ModuleActionList, ExportTemplates } from '@/helpers/utilities.js'
import 'dotenv/config'
import {GetNewBoqCreatorSections, AddNewBoqCreatorSection, UpdateNewBoqCreatorSectionName, GetItemsFromBoqByBits,
    DownloadBoqExport,ExportBoqFiveDLayout,ExportBoqCostXLayout,BoQItemExportForInternal,
     DeleteNewBoqCreatorSection, UpdateNewBoqCreatorSectionSequence, UpdateNewBoqCreatorSectionMarkUp} from '@/actions/boqActions.js'
import {GetProcessItemBoq} from '@/actions/projectActions.js'
import {mapActions, mapGetters} from 'vuex'
import BreadcrumbsProject  from '@/components/projectInformation/BreadcrumbsProject.vue'
export default { 
    name : 'BoqBuilder',
    components : {
        Modal, BoqSectionRow, BreadcrumbsProject,ModalImportLineItems, ButtonTemplateDownloader
    },
    mounted : async function(){
        const self = this

        self.boqItem = await self.GetBoqItemRun(self.boqId) 
        self.ChangeActiveLink(LinkList.BILL_OF_QUANTITIES)
        await self.GetProjectInfo(self.boqItem.projectId).catch()
        await self.ReloadData()
        if(self.boqItem.approvalStage==ApprovalStageBoq.FOR_APPROVAL){
            const processData = await GetProcessItemBoq(self.boqId).catch()
            self.processItemData = processData
        }
        self.ExportTemplatesForExport = [...ExportTemplates]
        self.ExportTemplatesForExport.push(self.$i18n.t('System Template (For Presentation)'))

        const getUnassingedLineItem = await GetItemsFromBoqByBits({
            page : 1,
            id :  self.boqId,
            costCode : '',
            area : '',
            skip : 0,
            take : 1
        })
        if(getUnassingedLineItem.length!=0){
            self.haveUnassignedLineItems = true
        }
    },
    computed :{
        ...mapGetters(['projectInfo','permission']),
        IsQuotationPublished(){
            if(!this.boqItem.reference)
                return false
            else{
                return this.boqItem.reference.approvalStage==ApprovalStageBoq.PUBLISHED
            }
                
        }
    },
    data(){
        return{
            ExportTemplatesForExport : [],
            exportTemplateType : 1,
            isExportRunning : false,
            haveUnassignedLineItems : false,
            processItemData : null,
            ApprovalStageBoqObj,
            ApprovalStageBoq,
            ModuleActionList,
            projectUrl : process.env.VUE_APP_PROJECT_URL,
            boqId : this.$route.params.id,
            boqItem : {
                approvalStage : 1
            },
            sectionList : [],
            overAllTotal : {},
            newSection : {},
            renameSection: {},
            newSubSection : {},
            markUpUpdate : {},
            isActionClicked :false,
            id : 3,
            subId : 2,
            draggableOptions: {
                group: 'nested',
            },
            showBreakdown : false,
            haveMoved : false,
        }
    },
    methods : {
        ...mapActions(['GetBoqItemRun','ChangeActiveLink', 'DeleteBoqPermanent','GetProjectInfo']),
        ReformatNumberHtml,
        async ReloadData(){
            const self = this
            const dataSections = await GetNewBoqCreatorSections(self.boqId) 
            self.sectionList = dataSections.sections
            self.overAllTotal = dataSections.overAllTotal
        },
        async HaveUnassigned(){
            const getUnassingedLineItem = await GetItemsFromBoqByBits({
                page : 1,
                id :  this.boqId,
                costCode : '',
                area : '',
                skip : 0,
                take : 1
            })
            if(getUnassingedLineItem.length!=0){
                this.haveUnassignedLineItems = true
            }
        },
        OpenImportModal(){
            this.$refs.modalImportLineItem.ClearData()
        },
        OpenAddNewSection(){
            this.newSection = {} 
        },
        async AddMainSection(){
            const self = this
            const data = self.newSection
            
            self.isActionClicked = true
            if(!data.name){
                return
            }
            self.isActionClicked = false

            const created = await AddNewBoqCreatorSection({
                boqId : self.boqId,
                name : data.name,
                level : 1,
                sequence : self.sectionList.length 
            })
            if(typeof created!=='object'){
                self.$swal(self.$i18n.t('Add Main Section'),created,'warning')
                return
            }

            self.sectionList.push({
                id : created._id,
                name : data.name,
                level : 1,
                childs : []
            })
            self.$refs.closeBoQAddMainSectionModal.click()
        },
        OpenAddNewSubSection(element){
            this.newSubSection = {
                parentId : element.id,
                mainName : element.name,
                parent : element,
                level : element.level + 1
            }
        },
        async AddNewSubSection(){
            const self = this
            const data = self.newSubSection
            
            const created = await AddNewBoqCreatorSection({
                boqId : self.boqId,
                name : data.name,
                level : data.level,
                sequence : data.parent.childs.length,
                parent :  data.parent.id
            })
            if(typeof created!=='object'){
                self.$swal(self.$i18n.t('Add Section'),created,'warning')
                return
            }

            data.parent.childs.push(
                {
                    id : created._id,
                    name : data.name,
                    level : data.level,
                    childs : []
                }
            )
            
            self.$refs.closeBoQAddSubSectionModal.click()
        },
        DeleteMain(element,parent){
            const self = this

            const swalTitle = self.$i18n.t('Delete Section')
            self.$swal({
                title: swalTitle,
                text : element.name + self.$i18n.t('Will be deleted'),
                showCancelButton: true,
                icon: 'warning',
                showLoaderOnConfirm : true,
                allowOutsideClick: () => !self.$swal.isLoading(),
                preConfirm : async ()=>{
                    return await DeleteNewBoqCreatorSection({id : element.id, boqId : self.boqId, level :element.level })
                }
            }).then(async (res)=>{
                if(res.isConfirmed){
                    if(res.value=='OK'){
                        self.$swal(swalTitle,self.$i18n.t('Removed Successfully'),'success')
                        if(parent){
                            const getIndex = parent.childs.findIndex(u=>u.id==element.id)
                            if(getIndex!=-1){
                                parent.childs.splice(getIndex,1)
                            }
                        }else{
                            const getIndex = self.sectionList.findIndex(u=>u.id==element.id)
                            if(getIndex!=-1){
                                self.sectionList.splice(getIndex,1)
                            }
                        }

                        const getUnassingedLineItem = await GetItemsFromBoqByBits({
                            page : 1,
                            id :  self.boqId,
                            costCode : '',
                            area : '',
                            skip : 0,
                            take : 1
                        })
                        if(getUnassingedLineItem.length!=0){
                            self.haveUnassignedLineItems = true
                        }

                    }else{
                        self.$swal(swalTitle,res.value,'warning')
                    }
                }
            })
        },
        OpenRename(element){
            this.renameSection = {
                id : element.id,
                level : element.level,
                name : element.name,
                object : element
            }
        },
        async RenameSection(){
            const self = this
            const data = self.renameSection
            self.isActionClicked = true
            if(!data.name){
                return
            }
            self.isActionClicked = false

            const updated = await UpdateNewBoqCreatorSectionName({
                id : data.id,
                boqId : self.boqId,
                level : data.level,
                name : data.name
            })
            
            
            if(updated!='OK'){
                self.$swal(self.$i18n.t('Update Section'),updated,'warning')
                return
            }

            data.object.name = data.name

            self.$refs.closeRenameSectionModal.click()
        },
        OnDragEnd(e){
            // Check if the target is a parent or child element
            let isParent = e.to.classList.contains("parent")
            if (isParent) {
                return false
            }
        },
        OnEnd(){
            this.haveMoved = true
        },
        CancelSaveSequence(){
            window.location.reload()
        },
        async SaveSequences(){
            const self = this

            //get the rows
            const data = JSON.parse(JSON.stringify(self.sectionList))
            let toBeUpdated = []

            let duplicateList = []

            const LoopData = async (rows, parentId)=>{
                let promise  = []
                for(let x = 0; x < rows.length; x++ ){
                    let row = rows[x]

                    const haveDuplicates = rows.find(u=>u.name.toLowerCase().trim()==row.name.toLowerCase().trim() && u.id!=row.id)
                    if(haveDuplicates){
                        if(duplicateList.findIndex(u=>u.toLowerCase().trim()==row.name.toLowerCase().trim())==-1){
                            duplicateList.push(row.name)
                        }
                    }

                    if(row.sequence!=x || parentId!=row.parent){
                        toBeUpdated.push({
                            id : row.id,
                            name : row.name,
                            parent : parentId,
                            level : row.level,
                            sequence : x
                        })
                    }
                    row.sequence = x
                    row.parent = parentId
                    if(row.childs.length!=0){
                        promise.push(LoopData(row.childs,row.id))
                    }
                }
                await Promise.all(promise)
            }
            await LoopData(data)
            const titleSwal = self.$i18n.t('Save Sequence')

            if(duplicateList.length!=0){
                
                self.$swal(titleSwal,  self.$i18n.t('Section(s)') + ' ' + duplicateList.join(',') 
                + '  ' + self.$i18n.t('have identical names within their parent section'),'warning')
                return
            }

            if(toBeUpdated.length!=0){
                const updated = await UpdateNewBoqCreatorSectionSequence({
                    boqId : self.boqId,
                    sections : toBeUpdated
                })

                if(updated!='OK'){
                    self.$swal(titleSwal, updated,'warning')
                }else{
                    self.$swal(titleSwal, self.$i18n.t('Updated Successfully'),'success')
                    self.sectionList = data
                    self.haveMoved = false
                }


            }

        },
        OpenMarkUp(element){
            this.markUpUpdate = {
                id : element.id,
                name : element.name,
                markUp : 0,
            }
        },
        async UpdateMarkUp(){
            const self = this
            const data = self.markUpUpdate
            
            self.isActionClicked = true
            if(data.markUp < 0 || data.markUp > 100){
                return
            }
            self.isActionClicked = false

            const updated = await UpdateNewBoqCreatorSectionMarkUp({
                id : data.id,
                boqId : self.boqId,
                markUp : data.markUp
            })
            
            const swalTitle = self.$i18n.t('Update Section Mark Up')
            if(updated!='OK'){
                self.$swal(swalTitle,updated,'warning')
                return
            }
            self.$swal(swalTitle, self.$i18n.t('Updated Successfully'),'success')
            await self.ReloadData()


            self.$refs.closeBoQMarkUpUpdateModal.click()
        },
        ExportBoqList(e){
        const self = this

        if(self.isExportRunning){
            return
        }
        
        const btn = e.target
        btn.disabled = true
        const boqId = this.$route.params.id
        self.isExportRunning = true
        if(self.exportTemplateType===0){
            DownloadBoqExport(boqId, this.boqItem.name +' export').then(()=>{
                btn.disabled = false
                self.isExportRunning = false
            })
        }else if(self.exportTemplateType===1){
            ExportBoqFiveDLayout(boqId, this.boqItem.name + ' FiveD-export').then(()=>{
                btn.disabled = false
                self.isExportRunning = false
            })
        }else if(self.exportTemplateType===2){
            ExportBoqCostXLayout(boqId, this.boqItem.name  + ' costX-export').then(()=>{
            btn.disabled = false
            self.isExportRunning = false
            })
        }else if(self.exportTemplateType===3){
            BoQItemExportForInternal(boqId, this.boqItem.name  + ' costX-export').then(()=>{
            btn.disabled = false
            self.isExportRunning = false
            })
        }
        
        else{
            self.$swal(self.$i18n.t('Export Template'),self.$i18n.t('Template not yet implemented'),'warning')
            btn.disabled = false
            self.isExportRunning = false
        }
        
        }
    }
}
</script>