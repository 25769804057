<template>
    <div>
       <BreadcrumbsProject
          :breadcrumbs="[
            {
                link : projectUrl +'/',
                title : $t('Projects'),
                isRouterLink: false
            },
            {
                link : projectUrl +'/Project/' + projectInfo._id,
                title : (projectInfo.projectNumber || '-') +' ' + (projectInfo.projectName || ''),
                isRouterLink: false
            },
            {
                link : '/boq/' + projectInfo._id,
                title : $t('Bill of Quantities'),
                isRouterLink: true
            },
            {
                link : '/boq/builder/' + boqItemData._id,
                title :(boqItemData.name || '-')  ,
                isRouterLink: true
            },
            {
                link : '/boq/builder/' + boqItemData._id + '/breakdown/' + parentId,
                title : parentObj.name ,
                isRouterLink: true
            }
          ]"
      ></BreadcrumbsProject>
    </div>

  
    <div class="">
      <div class="card">
        <div class="card-header bg-white">
          <div class="d-flex justify-content-between mb-1">
            <div class="">
              <h6 class="fw-bold text-capitalize mb-0" >{{ parentObj.name }}</h6>
            </div>
              
            <span :class="'badge pt-2 ' + ApprovalStageBoqObj[boqItemData.approvalStage -1].color">
              {{ApprovalStageBoqObj[boqItemData.approvalStage-1].name}}
            </span>
          </div>
        </div>
        <div class="card-body" >
          
          <div class="d-flex justify-content-between  mb-2">
              <div class="btn-group">
                <button class="btn btn-default"
                @click="()=>{ OpenFilter = !OpenFilter}" 
                >
                  <i class="fa-solid fa-filter"></i> {{$t('Filter')}}
                </button>
                <button class="btn btn-default " title="Filter Columns"
                data-bs-toggle="modal" data-bs-target="#BoqColumnFilter"
                >
                  <i class="fas fa-eye-slash"></i>  {{$t('Columns')}}
                </button>
              </div>
              <div >
                <div class="btn-group me-1" v-if="haveChanges" >
                  <button class=" btn btn-default text-black" @click="CancelSaveSequence"><i class="fa-solid fa-ban"></i> {{$t('Cancel')}}</button>
                  <button class=" btn btn-outline-success " @click="SaveSequences"><i class="fa-solid fa-list-ol"></i> {{$t('Save Sequence')}}</button>
                </div>
                <div class="btn-group" v-else-if="!haveChanges && !IsQuotationPublished">
                  <button class="btn btn-primary "
                  data-bs-toggle="modal" data-bs-target="#BoqMoveLineItemsModal"
                  v-if="permission.indexOf(ModuleActionList.BOQ_EDIT) >-1 && selectedToBeMoved.length!=0 "
                  ><i class="fa-solid fa-link"></i> {{ $t('Move Line Items') }}</button>

                  <button class="btn btn-primary "
                  @click="OpenAddItemModal"
                  data-bs-toggle="modal" data-bs-target="#ModalNewLineItem"
                  v-if="permission.indexOf(ModuleActionList.BOQ_EDIT) >-1 && selectedToBeMoved.length==0 "
                  ><i class="fa-solid fa-plus"></i> {{ $t('Add Line Item') }}</button>

                  <ButtonTemplateDownloader />

                  <button class="btn btn-default "
                    data-bs-toggle="modal" data-bs-target="#ModalImportBoq"
                    v-if="permission.indexOf(ModuleActionList.BOQ_EDIT) >-1 "
                    @click="OpenImportModal"
                    title="Import File"><i class="fa-solid fa-file-import"></i> {{$t('Import')}}  </button>
                </div>
                
                
            </div>
          </div>
          
          <div class="row">
            <div class="col-md-6 pb-2" v-if="OpenFilter">
              <div class="form-group ">
                <label class="d-flex justify-content-between">
                    <a>{{$t('Cost Category')}}</a>
                    <span role="button" class="text-danger text-decoration-underline" @click="ClearField(2)"><i class="fa-solid fa-xmark "></i> {{$t('Clear Field')}}</span>
                </label>
                <Multiselect 
                :multiple="true" :options="dataCostCodes" 
                :internalSearch="false"
                :placeholder="$t('Search Here')"
                v-model="selectedCostCode"  :loading="searchCostCodeLoading"
                 :max-height="300"
                label="costName" track-by="_id"/>
              </div>
            </div>
            <div class="col-md-6 pb-2" v-if="OpenFilter">
              <div class="form-group ">
                <label class="d-flex justify-content-between">
                  <a>{{ $t('Space ID') }}</a>
                  <span role="button" class="text-danger text-decoration-underline" @click="ClearField(1)"><i class="fa-solid fa-xmark "></i> {{$t('Clear Field')}}</span>
                </label>
                <Multiselect 
                :multiple="true" :options="areaList" 
                :placeholder="$t('Search Here')"
                v-model="selectedArea"  :max-height="300" />
              </div>
            </div>
            <FigureCurrencyNote :currency="boqItemData.currencyId" />
            <div class="col-md-12">
  
              <Loader v-if="tableLoading" />
              <div v-else-if="dataReady">
                <BoqItemTableBreakdown ref="tableBreakdown" :rowDatas="rowDatas" :reloadData="ReloadList" :stage="boqItemData.approvalStage"  
                :OpenEditModal="OpenEditLineItem" :SyncSelectedLineItem="SyncSelectedLineItem" :IsQuotationPublished="IsQuotationPublished"
                :measurementUnits="measurementUnits" :selectedColumns="selectedColumns" :updateSelected="UpdateSelected" :BoqCategoryList="BoqCategoryList" :GetChanges="GetChanges" :GetSequence="GetSequence" />
              </div>
  
            </div>
          </div>
          
         
        </div>
        
      </div>
    </div>
    <Modal idModal="BoqReviewModal" headerTitle="Send For Review BoQ" v-if="permission.indexOf(ModuleActionList.BOQ_PUBLISH) >-1">
      <div>
         <div class="form-group">
              <label class="required-style">Date End </label>
              <input type="date" v-model="boqPublishData.dateEnd" class="form-control"/> 
              <small class="text-warning" v-if="isPublishClicked && !boqPublishData.dateEnd">Required</small>
          </div>
         <div class="form-group">
              <label class="required-style">Process </label>
              <Select2
                  :dataOptions="reviewProcessList"
                  indexValue="_id"
                  labelName="processName"
                  placeHolder="Search Process Here"
                  v-model:optionValue="boqPublishData.processId"
                  ref="selectCostCode"
              >
              </Select2>
              <a class="text-primary small"  :href="projectUrl +'/Project/' + projectInfo._id+'/process'">No Process on the list? Click here to Create</a>
              <small class="text-warning" v-if="isPublishClicked && !boqPublishData.processId">Required</small>
          </div>
      </div>
      <template v-slot:actions>
        <button type="button" ref="closeBoqPublishModal" class="btn btn-outline-danger" data-bs-dismiss="modal">X Cancel</button>
        <button type="button" class="btn btn-primary"  @click="ReviewBoqRun"><i class="fas fa-save"></i> Send for Review</button>
      </template>
    </Modal>
    <Modal idModal="BoqApprovalModal" headerTitle="Send For Approval BoQ" v-if="permission.indexOf(ModuleActionList.BOQ_PUBLISH) >-1">
        <div>
         <div class="form-group">
              <label class="required-style">Date End </label>
              <input type="date" v-model="boqApprovalData.dateEnd" class="form-control"/> 
              <small class="text-warning" v-if="isApprovalClicked && !boqApprovalData.dateEnd">Required</small>
          </div>
         <div class="form-group">
              <label class="required-style">Process </label>
              <Select2
                  :dataOptions="approvalProcessList"
                  indexValue="_id"
                  labelName="processName"
                  placeHolder="Search Process Here"
                  v-model:optionValue="boqApprovalData.processId"
                  
              >
              </Select2>
              <a class="text-primary small"  :href="projectUrl +'/Project/' + projectInfo._id+'/process'">No Process on the list? Click here to Create</a>
              <small class="text-warning" v-if="isApprovalClicked && !boqApprovalData.processId">Required</small>
          </div>
        </div>
        <template v-slot:actions>
          <button type="button" ref="closeBoqApprovalModal" class="btn btn-outline-danger" data-bs-dismiss="modal">X Cancel</button>
          <button type="button" class="btn btn-primary"  @click="ForApprovalBoqRun"><i class="fas fa-save"></i> Send for Approval</button>
        </template>
    </Modal>
    <Modal idModal="BoqExportBoqList" headerTitle="Export BoQ"  >
        <div>
            <div class="form-group">
                <label class="required-style">Template </label>
                <select class="form-select" v-model="exportTemplateType"> 
                    <option v-for="(item,key) in ExportTemplatesForExport" :key="key" :value="key">{{item}}</option>
                </select>
            </div>
            <!-- <div class="form-group">
                <label >Filter </label>
                <Multiselect 
                :options="dataCostCodes" 
                placeholder="Type Here to search by Cost Category"
                v-model="exportTemplateFilter"  @search-change="SearchCostCodeAll" :searchable="true" 
                :internalSearch="false" :loading="searchCostCodeLoading"
                :clear-on-select="false" :max-height="300"
                label="costName" track-by="_id"/>
            </div> -->
        </div>
        <template v-slot:actions>
            <div>
                <!-- <button type="button" class="btn btn-outline-primary" @click="ExportForClient" >
                    <i class="fa-solid fa-user-tie"></i>  Export for Client
                </button> -->
            </div>
            <button type="button" class="btn btn-primary" @click="ExportBoqList" >
                <i class="fa-solid fa-file-arrow-down"></i>  Export
            </button>
        </template>
    </Modal>
    <Modal idModal="BoqGroupList" headerTitle="Select Line Item Parent"  v-if="permission.indexOf(ModuleActionList.BOQ_EDIT) >-1"  >
        <div>
            <div class="" v-for="(item,key) in selectedLineItems" :key="key">
              <input type="radio" :id="key + '-radio'" :value="item._id" v-model="selectedParent" />
              <label class="ps-3 h6 fw-normal" :for="key + '-radio'">{{item.costCode}} {{item.name}}</label>
            </div>
            
        </div>
        <template v-slot:actions>
            <div>
                
            </div>
            <button type="button" class="btn btn-primary"  >
                <i class="fas fa-save"></i> Group
            </button>
        </template>
    </Modal>
    <ModalLineItemColumnFilter :UpdateSelectedColumn="ChangeSelectecColumnValue"/>
  
    <ModalNewLineItem
    v-if="permission.indexOf(ModuleActionList.BOQ_EDIT) >-1 && boqItemData._id != 1"
    ref="modalNewLineItem"
    :boqList="boqList" :ResetData="GetDataList" :boqId="boqItemData._id" :parentId="parentId" />
  
    <ModalImportLineItems
    
    v-if="boqItemData.projectId && permission.indexOf(ModuleActionList.BOQ_EDIT) >-1"
    ref="modalImportLineItem"
    :boqList="boqList" :ResetData="GetDataList" :boqId="boqItemData._id" :projectId="boqItemData.projectId" :parentId="parentId" />
  
    <ModalUpdateLineItem
    v-if="permission.indexOf(ModuleActionList.BOQ_EDIT) >-1 && boqItemData._id != 1"
    :selectedLineItem="updateLineItem" :boqId="boqItemData._id" 
    :boqList="boqList" :ResetData="GetDataList" />
    <Modal idModal="BoqMoveLineItemsModal" headerTitle="Move to Another Section" modalSize="modal-md" >
        <div class="form-group">
            <label>Main Section</label>
            <select class="form-select" v-model="selectedFirstSection" @change="ChangeSection(1)">
                <option value="">Please Select</option>
                <option :value="item._id" v-for="(item,key) in firstSections" class="text-capitalize" :key="key"> {{ item.name }}</option>
            </select>
        </div>
        <div class="form-group" v-if="secondSections.length!=0">
            <label>Sub Section</label>
            <select class="form-select" v-model="selectedSecondSection" @change="ChangeSection(2)">
                <option value="">Please Select</option>
                <option :value="item._id" v-for="(item,key) in secondSections" class="text-capitalize" :key="key"> {{ item.name }}</option>
            </select>
        </div>
        <div class="form-group" v-if="thirdSections.length!=0">
            <label>Sub Section</label>
            <select class="form-select" v-model="selectedThirdSection">
                <option value="">Please Select</option>
                <option :value="item._id" v-for="(item,key) in thirdSections" class="text-capitalize" :key="key"> {{ item.name }}</option>
            </select>
        </div>
        <div class="form-group">
            <label>Selected Line Items To be Moved</label>
            <input class="form-control-plaintext text-capitalize" readonly :value="selectedToBeMoved.length"/>
            <small class="text-warning" v-if="selectedToBeMoved.length==0">No Selected Line Items</small>
        </div>
        <template v-slot:actions>
            <button type="button" ref="closeBoqMoveLineItemsModal" class="btn btn-outline-danger" data-bs-dismiss="modal">X Cancel</button>
            <button type="button" class="btn btn-primary" v-if="selectedToBeMoved.length!=0" @click="MoveToSection"><i class="fa-solid fa-link"></i> Move</button>
        </template>
    </Modal>
  </template>
  
<script>
import Modal from '@/components/layout/Modal.vue'
import Loader from '@/components/layout/Loader.vue'
import Select2 from '@/components/layout/Select2.vue'
import BoqItemTableBreakdown from '@/components/boq/BoqItemTableBreakdown.vue'
import ModalUpdateLineItem from '@/components/boq/ModalUpdateLineItem.vue'
import ModalImportLineItems from '@/components/boq/ModalImportLineItems.vue'
import ModalLineItemColumnFilter from '@/components/boq/ModalLineItemColumnFilter.vue'
import ButtonTemplateDownloader from '@/components/boq/ButtonTemplateDownloader.vue'
import ModalNewLineItem from '@/components/boq/ModalNewLineItem.vue'
import {NumberFormatterWidget, LinkList,LevelSort, ApprovalStageBoq, SortFunc, ModuleActionList ,ReformatDate, ExportTemplates, ApprovalStageBoqObj
,GetDistinctValues,GetDistinctValuesObject} from '@/helpers/utilities.js'
import  {mapActions, mapGetters} from 'vuex'
import BreadcrumbsProject  from '@/components/projectInformation/BreadcrumbsProject.vue'
import 'dotenv/config'
import {GetCostCodes,PublishBoq, SearchAreaFromProject,DownloadBoqExport, GetItemsFromBoqByBits, UpdateNewBoqCreatorSectionBreakdownSequence, GetNewBoqCreatorSectionItem,
   ForApprovalBoq, SearchBoqFromProject, ExportBoqCostXLayout, ExportBoqFiveDLayout, ExportBoqRolledUpLayout, BoQItemExportForInternal, GetNewBoqCreatorSectionsRaw,
   AddUnassignedToNewBoqCreatorSection} from '@/actions/boqActions.js'
import {GetProcessItem} from '@/actions/projectActions.js'
import {measurementUnits} from '@/assets/dataJson/commercial_management.json'
import {SearchAllActiveCostCodes} from '@/actions/projectActions.js'
import FigureCurrencyNote from '@/components/layout/FigureCurrencyNote.vue'
export default {
  name: 'BoqBuilderBreakdown',
  components: {
    BreadcrumbsProject,
    Modal,
    Select2,
    BoqItemTableBreakdown,
    Loader,
    ModalUpdateLineItem,
    ModalImportLineItems,
    ModalNewLineItem,
    ModalLineItemColumnFilter,
    ButtonTemplateDownloader,
    FigureCurrencyNote
  },
  methods:{
    ...mapActions(['SetCostCodes','GetProjectInfo', 'GetBoqItemRun','ChangeActiveLink']),
    ReformatDate : ReformatDate,
    SyncSelectedLineItem(data){
      this.selectedToBeMoved = data
    },
    async MoveToSection(){
      const self  = this
      if(self.isOnProcess){
          return
      }  
      const swalTitle = self.$i18n.t('Move to Section')

      const selected = self.selectedThirdSection ? self.selectedThirdSection : self.selectedSecondSection

      if(self.selectedToBeMoved.length==0){
          self.$swal(swalTitle, self.$i18n.t('No line item selected'), 'warning')
      }

      const result = await AddUnassignedToNewBoqCreatorSection({
          boqId : self.boqListParams.id,
          lineItems : self.selectedToBeMoved,
          parentId : selected
      })

      if(result!='OK'){
          self.$swal(swalTitle, result, 'warning')
          return
      }
      self.selectedToBeMoved = []

      self.$swal(swalTitle, self.$i18n.t('Line Items Moved Successfully'), 'success')
      setTimeout(function(){
        location.reload()
      },700)
    },
    ChangeSection(level){
        const self = this
        if(level==1){
            const selected = self.selectedFirstSection

            self.secondSections = self.rawSections.filter(u=>u.parentId==selected)
            self.thirdSections = []

            self.selectedSecondSection = ''
            self.selectedThirdSection = ''
        }else if(level==2){
            const selected = self.selectedSecondSection

            self.thirdSections = self.rawSections.filter(u=>u.parentId==selected)

            self.selectedThirdSection = ''
        }
    },
    CancelSaveSequence(){
      window.location.reload()
    },
    SaveSequences(){
      const self = this
      self.$refs.tableBreakdown.PassData()
    },
    async GetSequence(data){

      const self = this
      self.rowDataSequence = data

      let toBeSaved = []

      for(let x =0; x<data.length;x++){
        const getData = data[x]
        if(getData.sequenceStack!=x){
          toBeSaved.push({
            id : getData._id,
            sequence : x
          })
        }
      }

      if(toBeSaved.length==0){
        return
      }

      const result = await UpdateNewBoqCreatorSectionBreakdownSequence({
        boqId : self.boqListParams.id,
        parentId : self.parentId,
        lineItems : toBeSaved,
      }) 
      const swalTitle = self.$i18n.t('Save Sequence')

      if(result=='OK'){
        self.$swal(swalTitle, self.$i18n.t('Sequence Saved successfully, please wait Page is Reloading'),'success')
        setTimeout(function(){ location.reload() },1000)
      }else{
        self.$swal(swalTitle, result,'warning')
      }
    },
    GetChanges(){
      this.haveChanges = true
    },
    timerSetter(callback){
        const self = this
        if(self.timer){
            clearTimeout(self.timer)
            self.timer = null
        }
        self.timer = setTimeout(callback,700)
    },
    timerSetterSearch(callback){
      const self = this
      
      if(self.timerSearch){
          clearTimeout(self.timerSearch)
          self.timerSearch = null
      }

      self.timerSearch = setTimeout(callback,700)
    },
    GetCostCodeRun(search){
      return GetCostCodes(search,this.newTabData.parent)
    },
    formatNumber(value,sign= ""){
      return NumberFormatterWidget(value,sign)
    },
    
    SendForNewWorkFlow(){
      this.$router.push({name : 'BoqReviewerSetup', query : { boqId : this.$route.params.id} })
    },
    ForApprovalBoqRun(e){
      const self = this
      const btn = e.target
      const boqId = self.$route.params.id
      if(self.isApprovalRunning){
        return
      }
      self.isApprovalClicked =true

      const newData = self.boqApprovalData

      if(!newData.processId || !newData.dateEnd){
        return
      }
      btn.disabled = true
      newData.boqId  = boqId

      self.isApprovalClicked = false
      self.isApprovalRunning = true

      ForApprovalBoq(newData).then((res)=>{
        self.isApprovalRunning = false
        btn.disabled = false
        if(res!='OK'){
          self.$swal('Bill Of Quantites Approval', res,'warning')
        }else{
            self.GetBoqItemRun(boqId).then((res)=>{
                self.boqItemData = res
                GetProcessItem(boqId,1).then((process)=>{
                  self.processItemData = process
                  self.$refs.closeBoqApprovalModal.click()
                }).catch()
            })
            
        }
      }).catch()
    },
    SelectArea(area){
      const self = this
      const selected = self.selectedArea

      const getIndex = selected.indexOf(area)
      if(getIndex==-1){
        selected.push(area)
      }else{
        selected.splice(getIndex,1)
      }

      self.selectedCostCode = []
      if(selected.length==0){
        self.boqListParams = {
          id : self.$route.params.id,
          costCode : encodeURIComponent(JSON.stringify([self.boqItemData.subCategoryList[0].costCodeId])),
          area : '',
          page : 1
        }
        self.selectedCostCode.push(self.boqItemData.subCategoryList[0].costCodeId)
      }else{
        self.boqListParams = {
          id : self.$route.params.id,
          costCode : '',
          area : encodeURIComponent(JSON.stringify(selected)),
          page : 1
        }
      }

      self.tableLoading = true
      self.timerSetter(async function(){
        self.rowDatas = []
        self.boqListParams.skip = 0
        await self.GetListByBites()
        self.tableLoading = false
      })
    },
    SelectCostCode(costCode){
      const self = this

      const getIndex = self.selectedCostCode.findIndex(u=>u===costCode)
      if(getIndex==-1){
        self.selectedCostCode.push(costCode)
      }else{
        self.selectedCostCode.splice(getIndex,1)
      }

      self.boqListParams = {
        id : self.$route.params.id,
        costCode : encodeURIComponent(JSON.stringify(self.selectedCostCode)),
        area : '',
        page : 1
      }
      self.selectedArea = []
      self.tableLoading = true
      self.timerSetter(async function(){
        self.rowDatas = []
        self.boqListParams.skip = 0
        await self.GetListByBites()
        self.tableLoading = false
      })
    },
    ChangeView(val){
      this.filterView = val
    },
    async ReloadList(){
      const self = this
      self.tableLoading = true
      self.rowDatas = []
      self.boqListParams.skip = 0
      await self.GetListByBites()
      self.tableLoading = false
      
    },
    PageChange(page){
      const self = this
      self.boqListParams.page = page
      self.tableLoading = true
    },
    OpenEditModal(data){
      this.updateMasterItem = Object.assign({},data)
      this.updateMasterItem.costName = data.costCode + ' ' + data.name 
      this.updateMasterItem.subCateg = data.parents[1].name
      this.updateMasterItem.mainCateg = data.parents[0].name
    },
    SearchArea(e){
        const self = this

        self.timerSetter(function(){
            SearchAreaFromProject({id : self.projectInfo._id, search : e.target.value}).then((res)=>{
                self.areaList = res
            })
        })
    },
    GetProcessItemRun(){
      const self = this
      const boqId = self.$route.params.id
      self.GetBoqItemRun(boqId).then((res)=>{
          self.boqItemData = res
          GetProcessItem(boqId,1).then((process)=>{
            self.processItemData = process
          }).catch()
      })
    },
    OpenApproval(){
      this.boqApprovalData ={}
    },
    OpenReviewer(){
      this.boqPublishData ={}
    },
    PublishBoqItems(e){
      const self = this
      const btn = e.target
      const boqId =  self.$route.params.id


      self.$swal({
        title: 'Publish BoQ',
        text : 'BoQ will be locked for changed, Proceed?',
        showCancelButton: true,
        icon: 'warning',
        confirmButtonText: 'Proceed',
        showLoaderOnConfirm : true,
        allowOutsideClick: () => !self.$swal.isLoading(),
        preConfirm : ()=>{
          btn.disabled = true
          return PublishBoq(boqId).then((res)=>{
            btn.disabled = false
            if(res=="OK"){
              self.GetBoqItemRun(boqId).then((res)=>{
                  self.boqItemData = res
              })
            }else{
              self.$swal('Publish BoQ',res,'warning')
            }
          })
      }
      }).then((res)=>{
        if(res.isConfirmed){
          self.$swal('Boq Published Successfully','','success')
        }
      })
    },
    GetDataList(){
      const self= this
      self.tableLoading = true
      self.GetBoqItemRun(self.$route.params.id).then(async (res)=>{
        self.boqItemData = res
        self.boqItemData.subCategoryList.sort(LevelSort)
        self.boqItemData.areaList.sort(SortFunc)
        await self.GetListByBites()
        self.tableLoading = false
        // GetItemsFromBoq(self.boqListParams).then((data)=>{
        //   self.tableLoading = false
        //   self.rowDatas = data
        // })
      })
    },
    async GetListByBites(){
      const self = this

      self.rowDatas = []
      let willFetch = true
      self.boqListParams.skip = 0
      self.dataReady = false
      while(willFetch){
          const bits = await GetItemsFromBoqByBits(self.boqListParams)
          if(bits.length!=0){
              self.rowDatas.push(...bits)
          }
          if(bits.length==self.boqListParams.take){
              self.boqListParams.skip += 50
          }else{
              willFetch = false
          }
      }

      self.dataReady = true
    },
    OpenEditLineItem(data){
      this.updateLineItem = JSON.parse(JSON.stringify(data))
      this.updateLineItem.id = data._id
      this.updateLineItem.markUp = (this.updateLineItem.markUp * 100).toFixed(2)
    },
    OpenImportModal(){
      this.$refs.modalImportLineItem.ClearData()
    },
    OpenAddItemModal(){
      this.$refs.modalNewLineItem.ClearData()
    },
    ChangeSelectecColumnValue(value){
      this.selectedColumns = value
    },
    ExportBoqList(e){
      const self = this

      if(self.isExportRunning){
        return
      }

      const btn = e.target
      btn.disabled = true
      const boqId = this.$route.params.id
      self.isExportRunning = true
      if(self.exportTemplateType===0){
        DownloadBoqExport(boqId, this.boqItemData.name +' export').then(()=>{
          btn.disabled = false
          self.isExportRunning = false
        })
      }else if(self.exportTemplateType===1){
        ExportBoqFiveDLayout(boqId, this.boqItemData.name + ' FiveD-export').then(()=>{
          btn.disabled = false
          self.isExportRunning = false
        })
      }else if(self.exportTemplateType===2){
        ExportBoqCostXLayout(boqId, this.boqItemData.name  + ' costX-export').then(()=>{
          btn.disabled = false
          self.isExportRunning = false
        })
      }else if(self.exportTemplateType===3){
        BoQItemExportForInternal(boqId, this.boqItemData.name  + ' costX-export').then(()=>{
          btn.disabled = false
          self.isExportRunning = false
        })
      }
      
      else{
          self.$swal(self.$i18n.t('Export Template'),self.$i18n.t('Sorry not yet implemented'),'warning')
          btn.disabled = false
          self.isExportRunning = false
      }
      
    },
    ExportForClient(e){
      const self = this
      const btn = e.target
      const boqId = self.$route.params.id
      btn.disabled = true
      ExportBoqRolledUpLayout(boqId, self.boqItemData.name + ' export').then(()=>{
        btn.disabled = false
      })
    },
    UpdateSelected(data){
      this.selectedLineItems = data
    },
    SearchCostCodeAll(query){
      const self =  this
      self.searchCostCodeLoading = true
      self.timerSetter(function(){
          self.searchCostCodeLoading = false
          SearchAllActiveCostCodes(query).then((res)=>{
              self.dataCostCodes = res
          })
      })
    },
    ClearField(type){
      if(type==1){
          this.selectedArea = []
      }
      else{
          this.selectedCostCode = []
      }
    },
  },
  watch: {
    selectedCostCode(val) {
        const self = this
        self.boqListParams.costCode = encodeURIComponent(JSON.stringify(val.map(u=>u._id)))
        self.boqListParams.area = encodeURIComponent(JSON.stringify(self.selectedArea))
        self.timerSetterSearch(async function(){
          self.rowDatas = []
          self.boqListParams.skip = 0
          await self.GetListByBites()
          self.tableLoading = false
        })

        return val.map(u=>u._id)
    },
    selectedArea(val){
      const self = this
      self.boqListParams.costCode = encodeURIComponent(JSON.stringify(self.selectedCostCode))
      self.boqListParams.area = encodeURIComponent(JSON.stringify(val))
      self.timerSetterSearch(async function(){
        self.rowDatas = []
        self.boqListParams.skip = 0
        await self.GetListByBites()
        self.tableLoading = false
      })

      return val
    }
  },
  mounted : async function(){
    const self = this
    self.ChangeActiveLink(LinkList.BILL_OF_QUANTITIES)
    const boqId = self.$route.params.id
    const boqItem = await self.GetBoqItemRun(boqId)
    self.ExportTemplatesForExport = [...ExportTemplates]
    self.ExportTemplatesForExport.push('System Template(For Presentation)')
    if(boqItem==401){
      self.$router.push('/unauthorized')
      return
    }
    self.boqItemData = boqItem
    self.parentObj = await GetNewBoqCreatorSectionItem({id : self.parentId,boqId : boqId})
    await self.GetListByBites()
    
    if(!self.projectInfo._id){
      await self.GetProjectInfo(boqItem.projectId).catch()
    }
    // if(boqItem.approvalStage==ApprovalStageBoq.FOR_APPROVAL){
    //   const processData = await GetProcessItemBoq(boqId).catch()
    //   self.processItemData = processData
    // }

    const rawSections = await GetNewBoqCreatorSectionsRaw(boqItem._id)
    self.rawSections = rawSections.sections
    self.firstSections = rawSections.sections.filter(u=>u.level==1)

    self.boqList = await SearchBoqFromProject({search : '', id : boqItem.projectId})
    
    const lineItemCols =  window.localStorage.getItem('lineItemColumns')
    if(lineItemCols){
        this.selectedColumns = lineItemCols.split(',').map(Number)
    }
    // self.SearchCostCodeAll('')
    // self.areaList = await SearchAreaFromProject({id : self.projectInfo._id, search : ''})
    // self.areaList.push('NO SPACE ID')
    self.areaList = GetDistinctValues(self.rowDatas.map(u=>u.area).filter(value => value !== null && value !== undefined && value !== ''))
    self.areaList.push(self.$i18n.t('NO SPACE ID'))
    const getLineItemCostCode = 
        self.userInfo.language =='en' ? 
            self.rowDatas.map(u=>{
                return {
                    _id : u.costCodeId,
                    costName : u.costCode + ' ' +u.name
                }
            }) 
        :
            self.rowDatas.map(u=>{
                return {
                    _id : u.costCodeId,
                    costName : u.costCode + ' ' +u.nameCh
                }
            }) 
    self.dataCostCodes = GetDistinctValuesObject(getLineItemCostCode)
  },
  computed : {
    ...mapGetters(['projectInfo', 'permission','userInfo']),
    IsQuotationPublished(){
      return this.boqItemData.reference?.approvalStage==ApprovalStageBoq.PUBLISHED
    }
  },
  data(){
    return {
      selectedToBeMoved : [],
      rawSections : [],
      firstSections : [],
      secondSections : [],
      thirdSections : [],
      selectedFirstSection : '',
      selectedSecondSection : '',
      selectedThirdSection : '',
      rowDataSequence : [],
      ApprovalStageBoqObj : ApprovalStageBoqObj,
      OpenFilter : false,
      ModuleActionList : ModuleActionList,
      boqItemData :{
        _id : '1',
        subCategoryList : [],
        areaList : [],
        approvalStage : 1,
        reference : {}
      },
      areaList : [],
      selectedArea : [],
      selectedCostCode : [],
      dataCostCodes : [],
      parentId : this.$route.params.parent,
      parentObj : {},
      boqListParams : {
        id : this.$route.params.id,
        parent : this.$route.params.parent,
        costCode : '',
        area : '',
        page : 1,
        take : 50,
        skip : 0
      },
      boqPublishData : {},
      boqApprovalData : {
        boqId : this.$route.params.id
      },
      isPublishRunning : false,
      isPublishClicked : false,
      isApprovalRunning : false,
      isApprovalClicked : false,
      isExportRunning : false,
      timer : null,
      timerSearch : null,
      rowDatas:[],
      BoqCategoryList : [],
      dataReady : false,
      tableLoading : false,
      projectUrl : process.env.VUE_APP_PROJECT_URL,
      boqUrl : process.env.VUE_APP_BOQ_URL,
      filterView : 1,
      ApprovalStageBoq : ApprovalStageBoq,
      measurementUnits : measurementUnits,
      processItemData : {
        status : 1,
        internalApprovers : [],
        externalApprovers: []
      },
      boqList : [],
      updateLineItem : {
        unit : '',
        parents : [
            {
                name : ''
            },
            {
                name : ''
            }
        ]
      },
      selectedColumns : [],
      ExportTemplates : ExportTemplates,
      ExportTemplatesForExport : [],
      exportTemplateType : 0,
      exportTemplateFilter : null,
      selectedLineItems : [],
      selectedParent : "",
      approvalProcessList : [],
      reviewProcessList : [],
      searchCostCodeLoading : false,
      movedLineItems : [],
      haveChanges : false,
    }
  },
}
</script>