<template>
    <Modal idModal="ModalNewLineItem" :headerTitle="$t('Add Line Item')" modalSize="modal-xl">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label class="required-style">{{ $t('Cost Code') }} </label>
                    <Select2
                        :filterQuery="GetCostCodes"
                        indexValue="_id"
                        labelName="costName"
                        :placeHolder="$t('Search Here')"
                        v-model:optionValue="newLineItem.costCodeId"
                        :onOptionClick="AppendItemParents"
                        ref="selectCostCode"
                        subLabel='parentName'
                        :willCapitalize="false"
                    >
                    </Select2>
                    <small class="text-warning" v-if="!newLineItem.costCodeId && isAddItemClicked">{{ $t('Required') }}</small>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label>{{ $t('Sub Category') }}</label>
                    <input type="text" class="form-control-plaintext" v-model="newLineItem.subCateg" disabled />
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label>{{ $t('Main Category') }}</label>
                    <input type="text" class="form-control-plaintext" disabled v-model="newLineItem.mainCateg"/>
                </div>
            </div>
            <div class="col-md-12">
                <div class="form-group">
                    <label class="required-style">{{ $t('Description') }} </label>
                    <textarea class="form-control" :placeholder="$t('Description')" rows="4" v-model="newLineItem.description"></textarea>
                    <small class="text-warning" v-if="!newLineItem.description && isAddItemClicked">{{ $t('Required') }}</small>
                </div>
            </div>
            <div class="col-md-6" v-if="!feeMode">
                <div class="form-group">
                    <label class="required-style">{{ $t('Unit') }} </label>
                    <select class="form-select" v-model="newLineItem.unit" >
                        <option value="">{{$t('Please Select')}}</option>
                        <option v-for="(item, key) in measurementUnits" :key="key" :value="item.unitName">{{item.unitName}}</option>
                    </select>
                    <small class="text-warning" v-if="!newLineItem.unit && isAddItemClicked">{{ $t('Required') }}</small>
                </div>
            </div>
            
            <div class="col-md-6">
                <div class="form-group">
                    <label >{{ $t('Bidding Module.Remarks') }} </label>
                    <select class="form-select" v-model="newLineItem.remark" >
                        <option value="">{{ $t('Please Select') }}</option>   
                        <option v-for="(item, key) in remarkList" :key="key" :value="item.name">{{item.nameTranslated}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-6" v-if="!feeMode">
                <div class="form-group">
                    <label>{{ $t('Space ID') }}</label>
                    <input type="text" class="form-control" :placeholder="$t('Space ID')" v-model="newLineItem.area"/>
                </div>
            </div>
            
            <div class="col-md-12" v-if="!feeMode">
                <div class="mt-3" v-if="!advanceMode">
                    <div class="d-flex justify-content-between">
                        <small class="text-muted">{{ $t('Showing Basic Mode') }}</small>
                        <div>
                            <span data-bs-toggle="tooltip" data-bs-placement="top"  :title="$t('Tick if line item will not have quantity')" >
                                <input type="checkbox" style=" transform: scale(1.5)" id="new-line-is-rate" v-model="newLineItem.rateOnly" @change="OnRateOnlyChanged" /> 
                                <label for="new-line-is-rate" class="fw-bold ms-2" role="button">{{ $t('Rate Only') }}</label>
                                <small class="text-primary  me-3 ms-1"><i class="fa-solid fa-circle-question"></i> </small>
                            </span>
                            <button class="btn btn-default" @click="()=>{advanceMode=true}">{{ $t('Advance Mode') }}</button>
                        </div>
                    </div>
                    <table class="table ">
                        <tbody class="table-group-divider">
                            <tr>
                                <td>{{ $t('Quantity') }}</td>
                                <td>
                                    <input type="number" class="form-control text-end" :disabled="newLineItem.rateOnly"  min="0" :class="{'border-warning' : newLineItem.quantity < 0}"   v-model="newLineItem.quantity"/>
                                    <small class="text-warning" v-if="!newLineItem.quantity && isAddItemClicked && !newLineItem.rateOnly">{{ $t('Required') }}</small>
                                </td>
                            </tr>
                            <tr>
                                <td>{{$t('Cost Rate')}}</td>
                                <td>
                                    <input type="number" class="form-control text-end"  min="0" 
                                    @change="OnBasicCostRateChange" :disabled="disableRates" 
                                    :class="{'border-warning' : (!newLineItem.supplyAndInstallRate || newLineItem.supplyAndInstallRate < 0) 
                                    && newLineItem.installRate &&newLineItem.bufferCostRate && isAddItemClicked && !this.IsDiscount}"  v-model="newLineItem.supplyAndInstallRate"/>
                                </td>
                            </tr>
                            <tr>
                                <td> {{$t('Mark Up')}}</td>
                                <td>
                                    <div class="input-group ">
                                        <input type="number" class="form-control text-end" :class="{'border-warning' : newLineItem.markUp < 0}" :disabled="disableRates"   v-model="newLineItem.markUp" max="100" min="0"/>
                                        <span class="input-group-text">%</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="mt-3" v-else>
                    <div class="d-flex justify-content-between">
                        <small class="text-muted"> {{$t('Showing Advance Mode')}}</small>
                        <div>
                            <span data-bs-toggle="tooltip" data-bs-placement="top"  :title="$t('Tick if line item will not have quantity')" >
                                <input type="checkbox" style=" transform: scale(1.5)" id="new-line-is-rate-advance" v-model="newLineItem.rateOnly" @change="OnRateOnlyChanged" /> 
                                <label for="new-line-is-rate-advance" class="fw-bold ms-2" role="button">{{ $t('Rate Only') }}</label>
                                <small class="text-primary  me-3 ms-1"><i class="fa-solid fa-circle-question"></i> </small>
                            </span>
                            <button class="btn btn-default" @click="()=>{advanceMode=false}">{{ $t('Basic Mode') }}</button>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th style="width:120px"> </th>
                                    <th class="text-center" colspan="2">{{ $t('Raw') }}</th>
                                    <th class="text-center"  colspan="2"> {{ $t('Buffer') }}</th>
                                    <th class="text-center" style="width:180px"> {{ $t('Adjusted') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{{ $t('Quantity') }}</td>
                                    <td colspan="2">
                                        <input type="number" class="form-control text-end" :class="{'border-warning' : newLineItem.quantity < 0}"
                                         min="0" v-model="newLineItem.quantity" :disabled="newLineItem.rateOnly"/>
                                        <small class="text-warning" v-if="!newLineItem.quantity && isAddItemClicked && !newLineItem.rateOnly">{{ $t('Required') }}</small>
                                    </td>
                                    <td colspan="2">
                                        <input type="number" class="form-control text-end" :class="{'border-warning' : newLineItem.bufferQuantity < 0}" 
                                         min="0" v-model="newLineItem.bufferQuantity" :disabled="newLineItem.rateOnly" /></td>
                                    <td class="text-end bg-light" v-html="LineItemCompute.quantityTotal"></td>
                                </tr>
                                <tr>
                                    <td class="align-middle">{{ $t('Cost Rate') }}</td>
                                    <td colspan="2" style="width:330px"> 
                                        <div class="d-flex justify-content-end">
                                            <small class="fw-bold" role="button" @click="SplitOrCombineRate"><i class="fa-solid fa-screwdriver-wrench"></i> {{SupplyAndInstallDisplay}}</small>
                                        </div>
                                        <div class="d-flex" v-if="!supplyAndInstall">
                                            <div>
                                                <small > {{ $t('Supply Rate') }}</small>
                                                <input type="number"  min="0" class="form-control text-end"  :disabled="disableRates" 
                                                :class="{'border-warning' : (!newLineItem.costRate || newLineItem.costRate < 0) && newLineItem.installRate 
                                                &&newLineItem.bufferCostRate && isAddItemClicked  && !this.IsDiscount}"  
                                                v-model="newLineItem.costRate"/>
                                            </div>
                                            <div class="ps-1">
                                                <small> {{ $t('Install Rate') }}</small>
                                                <input type="number"  min="0" class="form-control text-end" :disabled="disableRates"  :class="{'border-warning' : newLineItem.installRate < 0 && !this.IsDiscount}"   v-model="newLineItem.installRate"/>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <small>{{ $t('Supply And Install Rate') }}</small>
                                            <input type="number"  min="0" class="form-control text-end" :disabled="disableRates"  :class="{'border-warning' : newLineItem.supplyAndInstallRate < 0 &&!this.IsDiscount}"   v-model="newLineItem.supplyAndInstallRate" />
                                        </div>

                                    </td>
                                    <td colspan="2" style="width:330px">
                                        
                                        <div class="d-flex justify-content-end">
                                            <small class="fw-bold text-success" role="button" @click="SplitOrCombineBuffer"><i class="fa-solid fa-screwdriver-wrench"></i> {{SupplyAndInstallBufferDisplay}}</small>
                                        </div>
                                        <div class="d-flex" v-if="!supplyAndInstallBuffer">
                                            <div>
                                                <small class="text-success">{{ $t('Supply Buffer') }}</small>
                                                <input type="number" class="form-control text-end" :disabled="disableRates"  :class="{'border-warning' : newLineItem.bufferCostRate < 0 &&!this.IsDiscount}" min="0"   v-model="newLineItem.bufferCostRate"/>
                                            </div>
                                            <div class="ms-1">
                                                <small class="text-success">{{ $t('Install Buffer') }}</small>
                                                <input type="number" class="form-control text-end" :disabled="disableRates"  :class="{'border-warning' : newLineItem.bufferInstall < 0 &&!this.IsDiscount}" min="0"   v-model="newLineItem.bufferInstall"/>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <small class="text-success">{{ $t('Supply And Install Buffer') }}</small>
                                            <input type="number"  min="0" class="form-control text-end" :disabled="disableRates"  :class="{'border-warning' : newLineItem.supplyAndInstallBuffer < 0 &&!this.IsDiscount}"   v-model="newLineItem.supplyAndInstallBuffer"/>
                                        </div>
                                    </td>
                                    <td class="align-bottom text-end bg-light" v-html="LineItemCompute.costRateTotal"></td>
                                </tr>
                                <tr>
                                    <td>{{ $t('Cost Total') }}</td>
                                    <td class="text-end bg-light td-value-line-item" v-if="!supplyAndInstall" v-html="LineItemCompute.supplyTotal" ></td>
                                    <td class="text-end bg-light td-value-line-item" v-if="!supplyAndInstall" v-html="LineItemCompute.installTotal"></td>
                                    <td class="text-end bg-light td-value-line-item"  colspan="2" v-if="supplyAndInstall" v-html="LineItemCompute.supplyAndInstallTotal"></td>
                                    <td class="text-end bg-light" colspan="2" v-html="LineItemCompute.bufferEffectCostTotal"></td>
                                    <td class="text-end bg-light" v-html="LineItemCompute.costTotal"></td>
                                </tr>
                                <tr>
                                    <td>{{ $t('Mark Up') }}</td>
                                    <td class="text-end bg-light" colspan="2">-</td>
                                    <td class="text-end bg-light" colspan="2">-</td>
                                    <td>
                                        <div class="input-group ">
                                            <input type="number" class="form-control text-end" :disabled="disableRates"   
                                            :class="{'border-warning' : newLineItem.markUp < 0}"
                                            v-model="newLineItem.markUp" max="100" min="0"/>
                                            <span class="input-group-text">%</span>
                                        </div>
                                    </td>
                                </tr>
                                
                                <tr>
                                    <td>{{ $t('Sell Rate') }}</td>
                                    <td class="text-end bg-light" colspan="2" v-html="LineItemCompute.sellRateRaw"></td>
                                    <td class="text-end bg-light"  colspan="2" v-html="LineItemCompute.bufferEffectRate"></td>
                                    <td class="text-end bg-light" v-html="LineItemCompute.sellRate"></td>
                                </tr>
                                <tr>
                                    <td>{{ $t('Sell Total') }}</td>
                                    <td class="text-end bg-light" colspan="2" v-html="LineItemCompute.sellTotalRaw"></td>
                                    <td class="text-end bg-light"  colspan="2" v-html="LineItemCompute.bufferEffectSellTotal"></td>
                                    <td class="text-end bg-light" v-html="LineItemCompute.sellTotal"></td>
                                </tr>
                                <tr>
                                    <td>{{ $t('Gross Profit') }}</td>
                                    <td class="text-end bg-light" colspan="2" v-html="LineItemCompute.gpRaw"></td>
                                    <td class="text-end bg-light"  colspan="2" v-html="LineItemCompute.bufferEffectGp"></td>
                                    <td class="text-end bg-light" v-html="LineItemCompute.gp"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- <div class="d-flex justify-content-end"><a href="#" class="small">How did we get these values?</a></div> -->
                </div>
            </div>
            <div class="col-md-6" v-if="feeMode">
                <div class="form-group">
                    <label>{{ $t('Quantity') }}</label>
                    <input type="number" class="form-control" :class="{'border-warning' : newLineItem.quantity < 0}"
                        min="0" v-model="newLineItem.quantity" :disabled="newLineItem.rateOnly"/>
                    <small class="text-warning" v-if="!newLineItem.quantity && isAddItemClicked && !newLineItem.rateOnly">{{ $t('Required') }}</small>
                </div>
            </div>
            <div class="col-md-6" v-if="feeMode">
                <div class="form-group">
                    <label class="required-style">{{ $t('Cost') }} </label>
                    <div class="input-group">
                        <input type="number" v-model="newLineItem.amountFee"  class="form-control" :disabled="disableRates" />
                        <span class="input-group-text fw-bold" v-if="newLineItem.typeId==CostCodeTypes.INTERNAL_FEE_PERCENTAGE" >%</span>
                    </div>
                    <small class="text-warning" v-if="((newLineItem.amountFee < 0 &&!this.IsDiscount)) && isAddItemClicked">{{ $t('Required') }}</small>
                
                </div>
            </div>
            <div class="col-md-6" v-if="feeMode">
                <div class="form-group">
                    <label>{{ $t('Mark Up') }}</label>
                    <div class="input-group ">
                        <input type="number" class="form-control"  v-model="newLineItem.markUp" :disabled="disableRates"  max="100" min="0"/>
                        <span class="input-group-text">%</span>
                    </div>
                </div>
            </div>
            <div class="col-md-6" v-if="feeMode" >
                <div class="form-group">
                    <label>{{$t('Type')}}</label>
                    <input class="form-control-plaintext" v-model="newLineItem.type" disabled />
                </div>
            </div>
            <div class="col-md-6" v-if="feeMode">
                <div class="form-group">
                    <label class="required-style">{{ $t('Apply to') }} </label>
                    <select class="form-select" :disabled="!isInternal" v-model="newLineItem.applyTo">
                        <option value="1">{{ $t('Apply To All') }}</option>
                        <option value="2">{{ $t('Apply To Specific Section') }}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-9" v-if="feeMode && isInternal && newLineItem.applyTo==ApplyToTypes.SPECIFIC">
                <div class="form-group" >
                    <label >{{ $t('Sections') }} </label>
                    <select class="form-select" v-model="selectedFirstSection" >
                        <option value="" >{{ $t('Please Select') }}</option>
                        <option :value="item.id" v-for="(item,key) in optionsSection" class="text-capitalize" :key="key"> {{ item.name }}</option>
                    </select>
                </div>
            </div>
            <!-- <div class="col-md-3" v-if="feeMode && isInternal && newLineItem.applyTo==ApplyToTypes.SPECIFIC">
                <div class="form-group" >
                    <label >Main Section </label>
                    <select class="form-select" v-model="selectedFirstSection" @change="ChangeSection(1)" >
                        <option value="" >Please Select</option>
                        <option :value="item._id" v-for="(item,key) in firstSections" class="text-capitalize" :key="key"> {{ item.name }}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3"  v-if="feeMode && isInternal && newLineItem.applyTo==ApplyToTypes.SPECIFIC" >
                <div class="form-group" v-if="secondSections.length != 0">
                    <label >Sub Section </label>
                    <div class="input-group">
                        <select class="form-select" v-model="selectedSecondSection" @change="ChangeSection(2)" >
                            <option value="" >Please Select</option>
                            <option :value="item._id" v-for="(item,key) in secondSections" class="text-capitalize" :key="key"> {{ item.name }}</option>
                        </select>
                        <span class="input-group-text text-danger bg-white border-0 pe-0 " @click="UnSelectSection(2)" role="button" > <i class="fas fa-times"></i></span>
                    </div>
                </div>
            </div>
            <div class="col-md-3" v-if="feeMode&& isInternal&& newLineItem.applyTo==ApplyToTypes.SPECIFIC">
                <div class="form-group" v-if="thirdSections.length != 0">
                    <label >Sub Section </label>
                    <div class="input-group">
                        <select class="form-select" v-model="selectedThirdSection" >
                            <option value="" >Please Select</option>
                            <option :value="item._id" v-for="(item,key) in thirdSections" class="text-capitalize" :key="key"> {{ item.name }}</option>
                        </select>
                        <span class="input-group-text text-danger bg-white border-0 pe-0 " @click="UnSelectSection(3)" role="button" > <i class="fas fa-times"></i></span>
                    </div>
                </div>
            </div> -->
            <div class="col-md-3" v-if="feeMode && isInternal && newLineItem.applyTo==ApplyToTypes.SPECIFIC">
                <button class="btn btn-primary w-100 mt-4" @click="AddSection"><i class="fa-solid fa-plus"></i> {{$t('Add Section')}}</button>
            </div>
            <div class="col-md-12 mt-2" v-if="feeMode && isInternal && newLineItem.applyTo==ApplyToTypes.SPECIFIC">
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th class="col-9">{{ $t('Section') }}</th>
                                <th class="col-3">{{ $t('Actions') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,key) in specificSections" :key="key">
                                <td class="col-9"><span class="text-capitalize" v-html="item.name"></span></td>
                                <td class="col-3">
                                    <span class="text-danger fw-bold" role="button" @click="RemoveSection(item.id)"><i class="fa-solid fa-times"></i> {{ $t('Remove') }}</span>
                                </td>
                            </tr>
                            <tr v-if="specificSections.length==0">
                                <td class="text-center" colspan="2">
                                    {{ $t('No data Found') }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <hr class="mt-2" v-if="!feeMode" />
            <div class="col-md-12" v-if="typeof boqId ==='undefined' && !feeMode">
                <div class="form-group">
                    <div class="d-flex justify-content-between">
                        <label class="required-style">Bill of Quantity </label>
                        <small class="text-success" v-if="!existingBoq" role="button" @click="()=>{ existingBoq =!existingBoq;newLineItem.boqId=''}"><i class="fa-solid fa-plus"></i> New Bill of Quantity</small>
                        <small class="text-primary" v-else-if="existingBoq&&createNewBoq==false" role="button" @click="()=>{ existingBoq =!existingBoq;newLineItem.newBoqName= ''}"><i class="fa-solid fa-plus"></i>  Select Existing Bill of Quantity</small>
                    </div>
                    <Select2
                    v-if="!existingBoq"
                    :filterQuery="GetBoqsProjects"
                    indexValue="_id"
                    labelName="name"
                    placeHolder="Search Boq here"
                    :dataOptions="boqList"
                    v-model:optionValue="newLineItem.boqId"
                    ref="selectBoqList"
                    >
                    </Select2>
                    <div v-else>
                        <input  type="text" class="form-control"  v-model="newLineItem.newBoqName" />
                        <small>  <i class="fa-solid fa-circle-info"></i> You are now adding a new Bill of Quantity</small>
                    </div>
                    <small class="text-warning" v-if="!newLineItem.boqId && isAddItemClicked">{{ $t('Required') }}</small>
                </div>
            </div>
        </div>
        <template v-slot:actions>
            <button type="button" ref="closeAddModalItemBoq" class="btn btn-outline-danger"  data-bs-dismiss="modal">X {{ $t('Cancel') }}</button>
            <div>
                <div class="input-group">
                    <button type="button" class="btn btn-primary" ref="btnItemSave" @click="AddLineItem(0)"><i class="fa-solid fa-save"></i> {{ $t('Save') }}</button>
                    <div class="dropdown" >
                        <button type="button" ref="btnItemSaveAndClose" class="btn btn-primary pe-1 ps-1 rounded-0 rounded-end"  data-bs-toggle="dropdown" ><i class="fa-solid fa-angle-down"></i></button>
                        <ul class="dropdown-menu text-small shadow">
                            <li @click="AddLineItem(1)"><a class="dropdown-item">{{ $t('Save and Close') }}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </template>
    </Modal>
</template>

<style scoped>
    .td-value-line-item{
        width: 201.5px;
        max-width: 201.5px;
        white-space: initial;
    }
</style>

<script>
import Modal from '@/components/layout/Modal.vue'
import Select2 from '@/components/layout/Select2.vue'
import { ReformatNumber,ComputeLineItem,CostCodeTypes,CostCodeTypeNames,ApplyToTypes } from '@/helpers/utilities.js'
import {GetCostCodeChilds, AddConsolidatedLineItem,  SearchBoqFromProject ,GetNewBoqCreatorSectionsRaw,GetHleNewBoqCreatorSectionsRaw,AddProjectFeeLineItem,
    AddHighLevelBoqLineItemFee, GetBoqItemRemarks} from '@/actions/boqActions.js'
import {SearchUomDirectory } from '@/actions/projectActions.js'
import {mapGetters} from 'vuex'
export default {
    name : 'ModalNewLineItem',
    components : {
        Modal, Select2
    },
    props : {
        ResetData : {
            type : Function
        },
        boqList : {
            type : Array
        },
        boqId : {
            type : String
        },
        createNewBoq :{
            type : Boolean,
            default : false
        },
        referenceQuotation  :{
            type : String
        },
        parentSequence : {
            type : String
        },
        AddLineItemFunc : {
            type : Function
        },
        officeId  :{
            type : String,
            default : ''
        },
        parentId : {
            type : String,
            default : ''
        }, 
        isHighLevelEstimate : {
            type :Boolean,
            default :false
        }
    },
    async mounted(){
        const self = this
        this.existingBoq = this.createNewBoq
        this.newLineItem = {
            quantity : 0,
            bufferCostRate : 0,
            costRate : 0,
            installRate : 0,
            supplyAndInstallRate : 0,
            supplyAndInstallBuffer : 0,
            bufferQuantity : 0,
            markUp : 0,
            unit : '',
            rateOnly : false,
        }
        self.measurementUnits = await SearchUomDirectory()
        self.remarkList = await GetBoqItemRemarks()
        this.supplyAndInstall = true
        this.supplyAndInstallBuffer =  true
        const rawSections =  !self.isHighLevelEstimate ? await GetNewBoqCreatorSectionsRaw(self.boqId) : await GetHleNewBoqCreatorSectionsRaw(self.boqId)
        self.rawSections = rawSections.sections
        self.firstSections = rawSections.sections.filter(u=>u.level==1)

        self.optionsSection = []

        function AddToSections(parentSection,preText){
        let objSection = {
            id : parentSection._id,
            name : preText + '/' + parentSection.name 
        }
        const getChilds = rawSections.sections.filter(u=>u.parentId && u.parentId==parentSection._id)

        if(parentSection.level==1){
            objSection.name = objSection.name.slice(1)
        }
        
        self.optionsSection.push(objSection)
        if(getChilds.length != 0)
        {
            for(let c = 0; c < getChilds.length; c++)
            {
                AddToSections(getChilds[c],objSection.name)
            }
        }
        }

        for(let c = 0; c < self.firstSections.length; c++)
        {
            AddToSections(self.firstSections[c],'')
        }

    },
    methods :{
        ReformatNumber : ReformatNumber,
        OnRateOnlyChanged (e){
            const checked = e.target.checked
            if(checked){
                this.newLineItem.quantity = 0
                this.newLineItem.bufferQuantity = 0
            }
        },
        OnBasicCostRateChange(){
            this.newLineItem.costRate = 0
            this.newLineItem.installRate = 0
            this.supplyAndInstall = true

            this.newLineItem.supplyAndInstallBuffer = 0
            this.newLineItem.bufferCostRate = 0
            this.newLineItem.bufferInstall = 0
            this.supplyAndInstallBuffer = true
        },
        AppendItemParents(data){
            this.newLineItem.costName = data.costName
            this.newLineItem.code = data.costCode
            this.newLineItem.subCateg = data.parents[1].name
            this.newLineItem.mainCateg = data.parents[0].name
            this.newLineItem.subCategObj = data.parents[1]
            this.newLineItem.Itemlevel = data.level
            this.newLineItem.unit = data.unit || ''
            this.newLineItem.description = data.description
            this.newLineItem.type = CostCodeTypeNames[data.type -1]
            this.newLineItem.typeId = data.type
            this.feeMode = data.type != CostCodeTypes.DEFAULT
            this.isInternal = data.type==CostCodeTypes.INTERNAL_FEE_PERCENTAGE
            this.newLineItem.applyTo = ApplyToTypes.ALL
        },
        async AddLineItem(close){
            const self = this

            if(self.isCreating){
                return
            }
            const swalTilte = self.$i18n.t('Add Line Item')
            
            const data = self.newLineItem
            self.isAddItemClicked = true
            if(data.typeId!=CostCodeTypes.DEFAULT)
            {
                data.boqId = self.boqId
                data.sectionList = self.specificSections.map(u=>u.id)
                if(!data.description  || !data.applyTo ){
                    return
                }

                if(data.typeId == CostCodeTypes.DISCOUNT && data.amountFee > 0)
                {
                    return
                }

                data.sectionId = self.parentId

                let addFunc = AddProjectFeeLineItem
                if(self.isHighLevelEstimate){
                    addFunc = AddHighLevelBoqLineItemFee
                }
                

                await addFunc(data).then((res)=>{
                    self.$refs.btnItemSave.disabled = false
                    self.$refs.btnItemSaveAndClose.disabled = false

                    self.isCreating = false
                    if(res=="OK"){
                        self.isAddItemClicked = false
                        self.ClearData()
                        self.ResetData()
                        if(close==1){
                            self.$refs.closeAddModalItemBoq.click()
                        }
                        return
                    }else{
                        self.$swal(swalTilte,res,'warning')
                        return
                    }
                })

                return

            }

            if(self.boqId){
                data.boqId = self.boqId
            }
            if(!data.description || (!data.quantity && !data.rateOnly) || !data.unit || !data.costCodeId || (!data.boqId && !data.newBoqName)){
                return
            }

            if((data.bufferCostRate || data.bufferInstall ||data.supplyAndInstallBuffer) && (!data.costRate && !data.installRate && !data.supplyAndInstallRate)){
                self.$swal(swalTilte,self.$i18n.t('Bill of Quantity Module.Supply/Install rates cannot be empty when there is buffer rate'),'warning')
                return
            }

            //checkValues
            if(data.bufferQuantity < 0 || data.quantity < 0 
            ||data.costRate < 0|| data.installRate < 0|| data.supplyAndInstallRate < 0 || data.bufferCostRate < 0
            || data.bufferInstall < 0|| data.supplyAndInstallBuffer < 0){
                self.$swal(swalTilte,self.$i18n.t('Values cannot be less than 0'),'warning')
            }
        //    if(data.typeId!=CostCodeTypes.DISCOUNT){
        //         if(data.costRate < 0|| data.installRate < 0|| data.supplyAndInstallRate < 0 || data.bufferCostRate < 0|| data.bufferInstall < 0|| data.supplyAndInstallBuffer < 0){
        //             self.$swal('Add Line Item','Values cannot be less than 0','warning')
        //             return
        //         }
        //    }

            if(!data.costRate&&data.bufferCostRate){
                return
            }

            if(!data.costRate&&data.installRate){
                return
            }
            
            self.isCreating = true
            self.isAddItemClicked = false
            self.$refs.btnItemSave.disabled = true
            self.$refs.btnItemSaveAndClose.disabled = true
            data.projectId  = self.projectInfo._id
            data.reference = self.referenceQuotation
            data.parentSequence = self.parentSequence
            data.builderParentId = self.parentId

            let addFunc = AddConsolidatedLineItem
            if(self.AddLineItemFunc){
                addFunc = self.AddLineItemFunc
            }
            addFunc(data).then((res)=>{
                self.$refs.btnItemSave.disabled = false
                self.$refs.btnItemSaveAndClose.disabled = false

                self.isCreating = false
                if(res=="OK"){
                    self.ClearData()
                    self.ResetData()
                    if(close==1){
                        self.$refs.closeAddModalItemBoq.click()
                    }
                    
                }else{
                    self.$swal(swalTilte,res,'warning')
                }
            })

        },
        GetCostCodes(search){
            return GetCostCodeChilds(search, this.projectInfo.officeId || this.officeId)
        },
        GetBoqsProjects(search){
            return SearchBoqFromProject({search : search, id : this.projectInfo._id})
        },
        ClearData(){
            this.isAddItemClicked = false
            this.newLineItem = {
                quantity : 0,
                bufferCostRate : 0,
                costRate : 0,
                installRate : 0,
                bufferQuantity : 0,
                supplyAndInstallRate : 0,
                supplyAndInstallBuffer : 0,
                markUp : 0,
                unit : '',
                rateOnly : false,
                type : '',
                amountFee : 0,
                applyTo : 1,
                remark : ''
            }
            this.ClearSectionSelector(true)
            if(this.createNewBoq==true){
                this.existingBoq = true
            }
            
        },
        SplitOrCombineRate(){
            this.newLineItem.supplyAndInstallRate = 0
            this.newLineItem.costRate = 0
            this.newLineItem.installRate = 0
            this.supplyAndInstall = !this.supplyAndInstall
        },
        SplitOrCombineBuffer(){
            this.newLineItem.supplyAndInstallBuffer = 0
            this.newLineItem.bufferCostRate = 0
            this.newLineItem.bufferInstall = 0
            this.supplyAndInstallBuffer = !this.supplyAndInstallBuffer
        },
        ChangeSection(level){
            const self = this
            if(level==1){
                const selected = self.selectedFirstSection

                self.secondSections = self.rawSections.filter(u=>u.parentId==selected)
                self.thirdSections = []

                self.selectedSecondSection = ''
                self.selectedThirdSection = ''
            }else if(level==2){
                const selected = self.selectedSecondSection
                self.thirdSections = self.rawSections.filter(u=>u.parentId==selected)
                self.selectedThirdSection = ''
            }
        },
        UnSelectSection(level){
            const self = this
            if(level==2){
                self.secondSections = []
                self.selectedSecondSection  = ''

                
                self.thirdSections = []
                self.selectedThirdSection  = ''
            } 
            else if(level==3){
                self.thirdSections = []
                self.selectedThirdSection  = ''
            }
        },
        GetParents(section, sectionSelected){
            const self = this
            const getParent =  self.rawSections.find(u=>u._id ==section.parentId)
            if(!getParent) { return }
            sectionSelected.name = getParent.name + ' / ' + sectionSelected.name
            sectionSelected.parents.push(getParent._id)
            self.GetParents(getParent,sectionSelected)
        },
        AddSection(){
            const self = this
            let selected = ''
            if(self.selectedThirdSection)
            {
                selected = self.selectedThirdSection
            }
            else if(self.selectedSecondSection){
                selected = self.selectedSecondSection
            }
            else {
                selected = self.selectedFirstSection
            }
            const swalTilte = self.$i18n.t('Add Line Item')

            if(!selected){
                self.$swal(swalTilte,self.$i18n.t('Bill of Quantity Module.Please Select A Section'),'warning')
                return
            }

            const getSection = self.rawSections.find(u=>u._id ==selected)
            if(!getSection){ return }


            let sectionSelected = {
                id : selected,
                name : `<b>${getSection.name}</b>`,
                parents : []
            }
            //recursion function for getting the parents of the section
            

            self.GetParents(getSection,sectionSelected)


            //if its not the final sub section or level 3
            //check if this section already have a sub section added 
            const getIndexParent = self.specificSections.findIndex(u=>u.parents.indexOf(getSection._id) > -1)
            if(getIndexParent > -1) 
            {
                self.$swal(swalTilte,self.$i18n.t('Bill of Quantity Module.Sub Section of this section is already added, please remove it first'),'warning')
                return
            }
            
            if(sectionSelected.parents[1]){
                const getIndexParent = self.specificSections.findIndex(u=>u.id==sectionSelected.parents[1])
                if(getIndexParent > - 1){
                    self.$swal(swalTilte,self.$i18n.t('Bill of Quantity Module.Parent Section Already Added'),'warning')
                    return
                }
            }

            if(sectionSelected.parents[0]){
                const getIndexParent = self.specificSections.findIndex(u=>u.id==sectionSelected.parents[0])
                if(getIndexParent > - 1){
                    self.$swal(swalTilte,self.$i18n.t('Bill of Quantity Module.Main Parent Section Already Added'),'warning')
                    return
                }
            }

            const getIndex = self.specificSections.findIndex(u=>u.id==selected)
            if(getIndex > - 1){
                self.$swal(swalTilte,self.$i18n.t('Bill of Quantity Module.Section Already Added'),'warning')
                return
            }

            self.specificSections.push(sectionSelected)
            self.ClearSectionSelector(false)

        },
        ClearSectionSelector(isAll = false){
            const self = this
            self.secondSections = []
            self.thirdSections = []
            self.selectedFirstSection = ''
            self.selectedSecondSection = ''
            self.selectedThirdSection = ''
            if(isAll){
                self.specificSections = []
            }
        },
        RemoveSection(id){
            const self = this
            const getIndex = self.specificSections.findIndex(u=>u.id==id)
            if(getIndex > - 1){
                self.specificSections.splice(getIndex,1)
            }
        },
        UpdateDiscountRates(){
            const lineItem = this.newLineItem
            if(lineItem.typeId==CostCodeTypes.DISCOUNT){
                lineItem.amountFee =  Math.abs(lineItem.amountFee) * -1
                // lineItem.supplyAndInstallRate =  Math.abs(lineItem.supplyAndInstallRate) * -1
                // lineItem.costRate =  Math.abs(lineItem.costRate) * -1
                // lineItem.installRate =  Math.abs(lineItem.installRate) * -1
                // lineItem.bufferCostRate =  Math.abs(lineItem.bufferCostRate) * -1
                // lineItem.bufferInstall =  Math.abs(lineItem.bufferInstall) * -1
                // lineItem.supplyAndInstallBuffer =  Math.abs(lineItem.supplyAndInstallBuffer) * -1
            }
            else
            {
                lineItem.amountFee =  Math.abs(lineItem.amountFee)
                // lineItem.supplyAndInstallRate =  Math.abs(lineItem.supplyAndInstallRate)
                // lineItem.costRate =  Math.abs(lineItem.costRate)
                // lineItem.installRate =  Math.abs(lineItem.installRate) 
                // lineItem.bufferCostRate =  Math.abs(lineItem.bufferCostRate) 
                // lineItem.bufferInstall =  Math.abs(lineItem.bufferInstall)
                // lineItem.supplyAndInstallBuffer =  Math.abs(lineItem.supplyAndInstallBuffer) 
            }
        },
        UpdateValueBasedOnTypeId(string){
            
            const lineItem = this.newLineItem
            const objValue = lineItem[string]
            if(this.IsDiscount && objValue > 0){
                lineItem[string] = Math.abs(objValue) * -1
            }
            else if(!this.IsDiscount && objValue < 0){
                lineItem[string] = Math.abs(objValue) 
            }
        }
    },
    computed :{
        ...mapGetters(['projectInfo']),
        IsDiscount(){
            return this.newLineItem.typeId == CostCodeTypes.DISCOUNT ||  this.newLineItem.typeId == CostCodeTypes.EXTERNAL_DISCOUNT
        },
        LineItemCompute(){
            const self =this
            const u = self.newLineItem
            return ComputeLineItem(u.quantity || 0, u.bufferQuantity|| 0,
            u.costRate|| 0, u.installRate|| 0, u.supplyAndInstallRate|| 0,
            u.bufferCostRate|| 0, u.bufferInstall|| 0, u.supplyAndInstallBuffer|| 0,
            u.markUp|| 0)
        },
        SupplyAndInstallDisplay(){
            const self = this
            if(self.supplyAndInstall)
                return self.$i18n.t('Click to Split Supply and Install')
            else
                return self.$i18n.t('Click to Combine Supply and Install')
        },
        SupplyAndInstallBufferDisplay(){
            const self = this
            if(self.supplyAndInstallBuffer)
                return self.$i18n.t('Click to Split Supply and Install')
            else
                return self.$i18n.t('Click to Combine Supply and Install')
        }
    },
    watch : {
        
        'newLineItem.applyTo': function () {
            this.ClearSectionSelector()
        },
        'newLineItem.typeId' : function() {
            this.UpdateDiscountRates()
        },
        'newLineItem.amountFee' : function() {
            this.UpdateValueBasedOnTypeId('amountFee')
        },
        'newLineItem.remark' : function(val) {

            if(val){
                this.newLineItem.amountFee = 0
                this.newLineItem.markUp = 0
                this.newLineItem.costRate = 0
                this.newLineItem.installRate = 0
                this.newLineItem.supplyAndInstallRate = 0
                this.newLineItem.bufferCostRate = 0
                this.newLineItem.bufferInstall = 0
                this.newLineItem.supplyAndInstallBuffer = 0

                this.disableRates = true
            }
            else
            {
                this.disableRates = false
            }
        },
        // 'newLineItem.supplyAndInstallRate' : function() {
        //     this.UpdateValueBasedOnTypeId('supplyAndInstallRate')
        // },
        // 'newLineItem.costRate' : function() {
        //     this.UpdateValueBasedOnTypeId('costRate')
        // },
        // 'newLineItem.installRate' : function() {
        //     this.UpdateValueBasedOnTypeId('installRate')
        // },
        // 'newLineItem.bufferCostRate' : function() {
        //     this.UpdateValueBasedOnTypeId('bufferCostRate')
        // },
        // 'newLineItem.bufferInstall' : function() {
        //     this.UpdateValueBasedOnTypeId('bufferInstall')
        // },
        // 'newLineItem.supplyAndInstallBuffer' : function() {
        //     this.UpdateValueBasedOnTypeId('supplyAndInstallBuffer')
        // },
    },
    data (){ 
        return {
            CostCodeTypes,
            ApplyToTypes,
            isCreating : false,
            newLineItem : {
                unit : ''
            },
            rawSections : [],
            isAddItemClicked : false,
            existingBoq : false,
            advanceMode : false,
            supplyAndInstall : true,
            supplyAndInstallBuffer : true,
            measurementUnits : [],
            feeMode : false,
            firstSections : [],
            secondSections : [],
            thirdSections : [],
            selectedFirstSection : '',
            selectedSecondSection : '',
            selectedThirdSection : '',
            isInternal : false,
            specificSections : [],
            optionsSection : [],
            remarkList : [],
            disableRates : true
        }
    }
}
</script>