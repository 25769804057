<template>
    <div>
        <div class="card">
            <div class="card-header">
                <h6>{{$t('Approval Module.Setup')}} {{isExternal ? $t('Approval Module.External') : $t('Approval Module.Internal')}} {{ $t('Approval Module.Approval') }}</h6>
            </div>
            <div class="card-body">
                <div class="form-group">
                    <label class="required-style">{{boqId ? $t('Bidding Module.Detailed Estimate') : $t('Bidding Module.High Level Estimate')}} </label>
                    <input class="form-control-plaintext text-capitalize" disabled :value="nameOfItem"  />
                </div>
                <div class="form-group">
                    <label class="required-style">{{$t('Approval Module.Date End')}} </label>
                    <DatePicker v-model="dateEnd"  elemId="boqreview-date-dateEnd" />
                    <small class="text-primary"><i class="fa-solid fa-circle-info"></i> {{$t('Approval Module.This date is only for a soft deadline this will not affect the approval process')}}.</small>
                </div>
                <div class="form-group">
                    <label class="required-style" >{{$t('Approval Module.Message')}} </label>
                    <RichTextEditor v-model:modelValue="emailMessage" :placeHolder="$t('Approval Module.Message')" :height="'200'" />
                </div>
                <div v-if="!isExternal" class="mt-3 mb-3">
                    <div class="input-group " style="" >
                        <span class="input-group-text"> {{$t('Approval Module.Project Team Search')}}</span>
                        <Select2 
                        style="width:50%"
                        indexValue="_id"
                        labelName="fullname"
                        :placeHolder="$t('Search Here')"
                        v-model:optionValue="newTypeName"
                        :onOptionClick="AddApprover"
                        :filterQuery="SearchMMoserUserFromProjectOrOrganization"
                        ref="select2Approver"
                        >
                        </Select2>
                    </div>
                </div>
                <div v-else class="mt-3 mb-3">
                    <div class="input-group ">
                        <span class="input-group-text"> {{$t('Approval Module.External Users Search')}}</span>
                        <Select2 
                        style="width:50%"
                            indexValue="_id"
                            labelName="fullname"
                            :placeHolder="$t('Search Here')"
                            v-model:optionValue="newTypeName"
                            :onOptionClick="AddApproverExternal"
                            :filterQuery="SearchExternalUserFromProjectOrOrganization"
                            ref="select2Approver"
                        >
                        </Select2>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>{{$t('Approval Module.Approver/Reviewer') }}</th>
                                <th> {{$t('Role') }}</th>
                                <th> {{$t('Actions') }}</th>
                                <th ></th>
                            </tr>
                        </thead>
                        <tbody v-if="approversList.length==0">
                            <tr>
                                <td colspan="4" class="text-center">{{$t('Approval Module.Please put atleast one Approver/Reviewer') }}</td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr v-for="(item,key) in approversList" :key="key">
                                <td>
                                    <b class="text-capitalize">{{item.fullname}}</b>
                                </td>
                                <td>{{item.roleId.roleName}}</td>
                                <td>
                                    <select class="form-select" v-model="item.actionType" :disabled="isApproversTypeDisabled">
                                        <option value="1">{{$t('Approval Module.Review')}}</option>
                                        <option value="2">{{$t('Approval Module.Approve')}}</option>
                                    </select>
                                </td>
                                <td  >
                                    <button class="btn btn-outline-danger" @click="RemoveApprover(item._id)"><i class="fa-solid fa-xmark"></i> {{$t('Remove')}}</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="card-footer">
                <div class="d-flex justify-content-between">
                    <div>
                        <button class="btn btn-outline-danger" @click="BackToItem"><i class="fa-solid fa-xmark"></i> {{ $t('Cancel') }}</button>
                    </div>
                    <div>
                        <button class="btn btn-primary" @click="SendForApproval" :disabled="isSending"><i class="fa-solid fa-envelope"></i> {{sendButtonName}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Select2  from '@/components/layout/Select2.vue'
import {LinkList, ReformatDate, TextCapitalize} from '@/helpers/utilities.js'
import {mapActions} from 'vuex' 
import DatePicker from '@/components/layout/DatePicker.vue'
import RichTextEditor from '@/components/layout/RichTextEditor.vue'
import {SearchMMoserUserFromProjectOrOrganization, SearchExternalUserFromProjectOrOrganization ,UserSendEmailBulk} from '@/actions/projectActions.js'
import {CheckSessionMsal} from '@/actions/userActions.js'
import {GetHighLevelBoq, ForApprovalHighLevelBoq, GetHighLevelQuotationForApproval, SendHighLevelQuotationForApproval} from '@/actions/boqActions.js'
import 'dotenv/config'
import {LocaleNameVariable} from '@/translations/i18n.js'
export default {
    name : 'HleApprovalSetup',
    mounted : async function(){
        const self = this

        self.isApproversTypeDisabled = self.isExternal
        self.ChangeActiveLink(LinkList.HIGH_LEVEL_ESTIMATES)
        let type = self.boqId ? 1 : 3
        const checkEmailSession = await CheckSessionMsal(type)
        if(!checkEmailSession){
            if(self.quotationId){
                window.location.href = process.env.VUE_APP_REST_MAIN_API + '/api/auth/token/email?type=' + type +'&returnUrl='+encodeURIComponent(window.location.href)
            }else{
                window.location.href = process.env.VUE_APP_REST_MAIN_API + '/api/auth/token/email?type=' + type +'&returnUrl='+encodeURIComponent(window.location.href)
            }
        }

        if(self.boqId){
            const getBoq = await GetHighLevelBoq(self.boqId)
            if(getBoq==400){
                self.$router.push('/unauthorized')
            }
            self.backUrl = {name : 'HleBoQBuilder', params : { id : getBoq.hleProjectId,boqId :self.boqId }}
            self.nameOfItem = getBoq.name

            
        }else if(self.qtnId){
            const getQtn = await GetHighLevelQuotationForApproval(self.qtnId)
            if(getQtn==400){
                self.$router.push('/unauthorized')
            }
            self.backUrl = {name : 'HleQuotation', params : { id: getQtn.hleProjectId, qtnId :self.qtnId }}
            self.nameOfItem = getQtn.name
            let arrayApprovers = []
            if(!self.isExternal)
            {
                arrayApprovers = getQtn.submittedBy
            }
            else
            {
                arrayApprovers = getQtn.approvedBy
            }
            self.approversList = arrayApprovers.map(u=> {
                return {
                    _id : u._id,
                    fullname : u.name,
                    roleId : u.roleId,
                    actionType : self.isExternal ? 2 : 1
                }
            })
        }

        const getLanguage = window.localStorage.getItem(LocaleNameVariable) || 'en'

        if(getLanguage=='en')
        {
            self.emailMessage = `
            We hope this email finds you well. <b>${TextCapitalize(self.nameOfItem)}</b> is now ready for your review and approval. Your approval is required by <b>${ReformatDate(self.dateEnd)}</b>. 
            <br/><br/>
            Please note that timely approval is vital to maintaining our project's momentum and avoiding potential delays. We appreciate your cooperation and await your positive response by <b>${ReformatDate(self.dateEnd)}</b>. 
            <br/><br/>
            If you have any questions or concerns, please do not hesitate to reach out. 
            `
        }
        else
        {
            self.emailMessage = `
            我们希望您一切都好。现在可以开始审查和批准<b>${TextCapitalize(self.nameOfItem)}</b>了。您需要在<b>${ReformatDate(self.dateEnd)}</b>之前批准。
            <br/><br/>
            请注意，及时批准对于维持项目的推进和避免潜在延误至关重要。我们感谢您的合作，并期待您在<b>${ReformatDate(self.dateEnd)}</b>之前给出积极的回复。 
            <br/><br/>
            如果您有任何疑问或顾虑，请随时联系我们。
            `
        }
        
    },
    computed : {},
    components : {
        DatePicker, RichTextEditor, Select2
    },
    methods : {
        ...mapActions(['ChangeActiveLink']),
        SearchExternalUserFromProjectOrOrganization (value){
            return SearchExternalUserFromProjectOrOrganization(value,'', 1)
        },
        SearchMMoserUserFromProjectOrOrganization(search){
            return SearchMMoserUserFromProjectOrOrganization(search, '', 1)
        },
        AddApprover(value){
            const self = this
            const getIndex = self.approversList.findIndex(u=>u._id==value._id)
            if(getIndex==-1){
                self.approversList.push({
                    _id : value._id,
                    fullname : value.fullname,
                    roleId :{
                        roleName : value.roleId.roleName
                    }, 
                    actionType : 1,
                })
            }
            setTimeout(function(){
                self.newTypeName = ''
            },300)
        },
        AddApproverExternal(value){
            const self = this
            const getIndex = self.approversList.findIndex(u=>u._id==value._id)
            if(getIndex==-1){
                self.approversList.push({
                    _id : value._id,
                    fullname : value.fullname,
                    roleId :{
                        roleName : value.roleId.roleName
                    }, 
                    actionType : 2,
                })
            }
            setTimeout(function(){
                self.newTypeName = ''
            },300)
        },
        RemoveApprover(id){
            const self = this
            const getIndex = self.approversList.findIndex(u=>u._id==id)
            if(getIndex>-1){
                self.approversList.splice(getIndex,1)
            }
        },
        async SendForApproval(){
            const self = this
            let willPass = true
            self.isSendClicked = true

            const swalTitleSendForApproval = self.$i18n.t('Send For Approval')
            const swalTitleSendEmail = self.$i18n.t('Approval Module.Sending Email')
            //validate first
            if(!self.emailMessage){
                willPass = false
            }

            if(!self.dateEnd){
                willPass = false
            }
            
            if(self.approversList.length==0){
                willPass = false
                self.$swal(swalTitleSendForApproval,self.$i18n.t('Approval Module.Please put atleast one Approver/Reviewer'),'warning')
            }

            if(!willPass){
                return
            }
            
            self.isSendClicked = false
            self.isSending = true
            let emailSubject = ''
            let approvalUrl = ''
            if(self.boqId){
                const response = await ForApprovalHighLevelBoq({ boqId : self.boqId, approvers : self.approversList, dateEnd : self.dateEnd })
                
                if(!response.processId){
                    self.$swal(swalTitleSendForApproval,response,'warning')
                    self.sendButtonName = swalTitleSendForApproval
                    self.isSending = false
                    return
                }

                self.sendButtonName = swalTitleSendEmail
                emailSubject = `Detailed Estimate Approval - ${self.nameOfItem}`
                if(!self.isExternal){
                    approvalUrl = `${window.location.origin}/high-level-estimates/approval/bill-of-quantities/${response.processId}`
                }else{
                    approvalUrl = `${self.dotnetUrl}/client/approval/hle-boq/${response.processId}`
                }
            }
            else if(self.qtnId){
                const response = await SendHighLevelQuotationForApproval({quotationId : self.qtnId,approvers : self.approversList, dateEnd : self.dateEnd })
                if(!response.processId){
                    self.$swal(swalTitleSendForApproval,response,'warning')
                    self.sendButtonName = swalTitleSendForApproval
                    self.isSending = false
                    return
                }

                self.sendButtonName = swalTitleSendEmail
                emailSubject = `High Level Estimate Approval - ${self.nameOfItem}`
                if(!self.isExternal){
                    approvalUrl = `${window.location.origin}/high-level-estimates/quotation/${response.processId}/approval`
                }else{
                    approvalUrl = `${self.dotnetUrl}/client/approval/hle-budget-estimate/${response.processId}`
                }
            }

            //send the email here
            await UserSendEmailBulk({
                subject : emailSubject,
                message : self.emailMessage,
                link : approvalUrl,
                sendTos : self.approversList
            })

            self.$router.push(self.backUrl)

        },
        BackToItem(){
            const self = this
            self.$router.push(self.backUrl)
        }
    },
    watch :{
        dateEnd(val){
            const self = this
            self.emailMessage = self.emailMessage.replaceAll(self.presetDate, ReformatDate(val))
            self.presetDate = ReformatDate(val)
        },  
    },
    data(){
        const d = new Date()
        d.setDate(d.getDate() + 7)
        const self = this
        return {
            presetReviewers : [],
            dotnetUrl : process.env.VUE_APP_DOTNET_URL,
            boqId : this.$route.query.boqId,
            qtnId : this.$route.query.qtnId,
            isExternal : this.$route.query.isExternal || false,
            dateEnd : ReformatDate(d,'YYYY-MM-DD'),
            presetDate : ReformatDate(d),
            emailMessage : '',
            approversList : [],
            newTypeName : '',
            isSendClicked :false,
            isSending : false,
            nameOfItem : '',
            backUrl : {},
            sendButtonName : self.$i18n.t('Send For Approval'),
            isApproversTypeDisabled : false
        }
    }
}
</script>