import {mapActions, mapGetters} from 'vuex'
import { LinkList, ModuleActionList } from '@/helpers/utilities.js'
import StepItem2 from '@/components/layout/StepItem2.vue'
import Loader from '@/components/layout/Loader.vue'
import Modal from '@/components/layout/Modal.vue'
import Select2 from '@/components/layout/Select2.vue'
import BreadcrumbsProject  from '@/components/projectInformation/BreadcrumbsProject.vue'
import {GetProjectConsolidatedList, AddItemsToTradePackage,GetCostCodeActives, SearchTradePackageOnConsolidatedList} from '@/actions/boqActions.js'
import 'dotenv/config'
import Pagination from '@/components/layout/Pagination.vue'
export default {
    name : 'ConsolidatedList',
    components : {
        StepItem2, BreadcrumbsProject, Loader,Pagination,Modal, Select2
    },
    mounted : async function(){
        const self = this
        const projectId = self.$route.params.id
        self.ChangeActiveLink(LinkList.TRADE_PACKAGE_LIST)
        await self.GetProjectInfo(projectId).catch()
        self.consolidatedList = await GetProjectConsolidatedList(self.pagerParams).catch()
        if(self.consolidatedList==401){
            self.$router.push('/unauthorized')
            return
        }
        self.isLoading = false
    },
    methods : {
        ...mapActions(['ChangeActiveLink', 'GetProjectInfo']),
        OnTrClicked(item){
            const getIndex = this.selectedItems.indexOf(item)
            if(getIndex==-1)
                this.selectedItems.push(item)
            else
                this.selectedItems.splice(getIndex,1)
        },
        OnTrParentClicked(parent){
            const getChilds = this.consolidatedList.docs.filter(u=>u.parents[0].costCodeId==parent).map(u=>u._id)
            const getIndex = this.selectedParents.indexOf(parent)

            this.selectedItems = this.selectedItems.filter(u=>getChilds.indexOf(u) < 0)
            if(getIndex==-1){
                this.selectedParents.push(parent)
                this.selectedItems.push(...getChilds)
            }
            else{
                this.selectedParents.splice(getIndex,1)
            }
        },
        PageChange(page){
            const self = this
            self.isLoading = true
            self.pagerParams.page = page
            GetProjectConsolidatedList(self.pagerParams).then(res=>{
                self.isLoading = false
                self.consolidatedList  = res
            }).catch()
        },
        OpenTradePackage(){
            const selectedLength = this.selectedItems.length
            if(selectedLength==0){
                return
            }
            const getIndexLast = this.selectedItems[selectedLength -1]
            this.newPackage.existing = '0'
            this.newPackage.tradePackageId = ''

            const getOne = this.consolidatedList.docs.filter(u=>u._id==getIndexLast)
            this.newPackage.name = getOne[0].parents[1].name
        },
        SaveTradePackage(e){
            const self = this
            const btn = e.target
            self.isClickNewPackage = true
            self.newPackage.items = self.selectedItems
            if(self.newPackage.items.length==0){
                return
            }
            if(self.existing==1&&!self.newPackage.tradePackageId){
                return
            }
            if(!self.newPackage.name && self.existing==1){
                return
            }

            self.isClickNewPackage = false
            btn.disabled = true

            AddItemsToTradePackage(self.newPackage).then((res)=>{
                btn.disabled = false
                if(res.status==200){
                    self.selectedItems =[]
                    self.selectedParents =[]
                    self.$refs.closeAddTradeModal.click()
                    self.$swal('Trade Package Created',res.data,'success')
                }else{
                    self.$swal('Trade Package',res,'warning')
                }
            })
        },
        GetCostCodeActives(search){
            return GetCostCodeActives(search)
        },
        SearchTradePackage(search){
            return SearchTradePackageOnConsolidatedList({id : this.$route.params.id, search : search })
        },
        ChangeMode(){
            this.isViewMode = !this.isViewMode
        }
    },
    computed : {
        ...mapGetters(['projectInfo', 'permission']),
        FormateConsolidatedList(){
            const self = this
            let currentMain = ''

            const data = self.consolidatedList.docs.map((u)=>{
                let parentShow  = false
                if(u.parents[0].costCodeId!=currentMain){
                    parentShow = true
                    currentMain = u.parents[0].costCodeId
                }else{
                    parentShow = false
                }
                return {
                    data : u,
                    parent : parentShow
                }
            })
            return data
        }
    },
    watch :{
        costCodeSearch (value){
            const self = this
            self.isLoading = true
            self.pagerParams.search =value
            GetProjectConsolidatedList(self.pagerParams).then(res=>{
                self.isLoading = false
                self.consolidatedList  = res
            }).catch()
        }
    },
    data(){
        return {
            projectUrl : process.env.VUE_APP_PROJECT_URL,
            consolidatedList : {
                docs : [],
                totalPages : 1,
                page : 1
            },
            pagerParams : {
                page :1,
                id : this.$route.params.id,
                search : ''
            },
            selectedItems : [],
            selectedParents : [],
            isLoading : true,
            costCodeSearch : '',
            newPackage : {
                name : '',
                id : this.$route.params.id,
                items : [],
                tradePackageId : '',
                existing : '0'
            },
            isClickNewPackage : false,
            isViewMode : false,
            ModuleActionList : ModuleActionList,
        }
    }
}