<template>
  <div>
     <BreadcrumbsProject
        :breadcrumbs="[
            {
                link : projectUrl +'/',
                title : 'Projects',
                isRouterLink: false
            },
            {
                link : projectUrl +'/Project/' + projectInfo._id,
                title : (projectInfo.projectNumber || '-') +' ' + (projectInfo.projectName || ''),
                isRouterLink: false
            },
            {
                link : '/boq/' + projectInfo._id,
                title : 'Bill of Quantities',
                isRouterLink: true
            },
            {
                link : '/boq/items/' + boqItemData._id,
                title :(boqItemData.name || '-')  + ' - (' + (boqItemData.currencyId?.sign || '') +') '  + ( boqItemData.currencyId?.shortName || '')  +' '+ (boqItemData.currencyId?.name || ''),
                isRouterLink: true
            }
        ]"
    ></BreadcrumbsProject>
  </div>
  <div class="row mt-1 pb-2" v-if="boqItemData.approvalStage==ApprovalStageBoq.FOR_APPROVAL">
    <BoqProcessWorkFlow
      v-if="processItemData._id"
      :processItemData="processItemData" 
      :showApprovalDisapprove="false"
      :showGoToApproval="true"
    />
  </div>

  <div class="">
    <div class="card">
      <div class="card-body" >
        <div class="alert alert-outline-success d-flex align-items-center " role="alert" v-if="boqItemData.approvalStage==ApprovalStageBoq.PUBLISHED">
                <i class="fa-solid fa-file-circle-check fa-2x me-2"></i>  
                <div>
                    Last published Date on <b>{{ReformatDate(boqItemData.publishedDate)}}</b> By  <b class="text-capitalize"> {{boqItemData.publishedBy.fullname}}</b>
                    
                </div>
            </div>
        <div class="d-flex justify-content-between mb-1">
          <div v-if="boqItemData.reference" class=" ps-1 p-1">
            <small> Reference :</small> <router-link :to="{name : 'BoqQuotation', params : {id : boqItemData.reference._id} }"  class="small text-primary fw-bold">{{boqItemData.reference.quotationNumber}} {{boqItemData.reference.quotationName}}</router-link>
          </div>
            
          <span :class="'badge pt-2 ' + ApprovalStageBoqObj[boqItemData.approvalStage -1].color">
            {{ApprovalStageBoqObj[boqItemData.approvalStage-1].name}}
          </span>
        </div>
        <div class="d-flex justify-content-between  mb-2">
          <div class="">
            <button class="btn btn-default pe-2 ps-2 me-1"
            @click="()=>{ OpenFilter = !OpenFilter}" title="Filter Line Items"
            >
              <i class="fa-solid fa-filter"></i>  
            </button>
            <button class="btn btn-default pe-2 ps-2" title="Filter Columns"
            data-bs-toggle="modal" data-bs-target="#BoqColumnFilter"
            >
              <i class="fa-solid fa-table-columns"></i>  
            </button>
            
          </div>
          
          <div>
            <button class="btn btn-primary me-1"
            @click="OpenAddItemModal"
             data-bs-toggle="modal" data-bs-target="#ModalNewLineItem"
             v-if="permission.indexOf(ModuleActionList.BOQ_EDIT) >-1"
            ><i class="fa-solid fa-plus"></i> Add Line Item</button>

            <button class="btn btn-primary me-1"
            v-if="boqItemData.approvalStage !=ApprovalStageBoq.FOR_APPROVAL &&
            permission.indexOf(ModuleActionList.BOQ_EDIT) >-1" @click="SendForNewWorkFlow"
            ><i class="fa-solid fa-file-pen"></i> Send For Approval</button>

            <!-- <div class="btn-group me-1" role="group" 
            v-if="[ApprovalStageBoq.FOR_APPROVAL, ApprovalStageBoq.PENDING].indexOf(boqItemData.approvalStage)==-1 &&
            permission.indexOf(ModuleActionList.BOQ_EDIT) >-1
            "
            >
              <button  type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" v-if="rowDatas.length!=0">
                <i class="fa-solid fa-file-pen"></i> Publish
              </button>
              <ul class="dropdown-menu" >
                <li role="button" @click="PublishBoqItems" v-if="[ApprovalStageBoq.APPROVED,ApprovalStageBoq.REVIEWED].indexOf(boqItemData.approvalStage)>-1" >
                  <a class="dropdown-item"  >Publish</a>
                </li>
                <li role="button" @click="SendForNewWorkFlow" ><a class="dropdown-item"  >Send For Approval</a></li>
                <li role="button" @click="OpenReviewer" data-bs-toggle="modal" data-bs-target="#BoqReviewModal"><a class="dropdown-item"  >Send For Review</a></li>
                <li role="button" @click="OpenApproval" data-bs-toggle="modal" data-bs-target="#BoqApprovalModal"><a class="dropdown-item" >Send For Approval</a></li>
              </ul>
            </div> -->
            <button class="btn btn-default me-1  pe-2 ps-2"
            data-bs-toggle="modal" data-bs-target="#ModalImportBoq"
            v-if="permission.indexOf(ModuleActionList.BOQ_EDIT) >-1"
            @click="OpenImportModal" 
            title="Import File"><i class="fa-solid fa-upload"></i>  </button>

            <button class="btn btn-default pe-2 ps-2" title="Export to File"
            data-bs-toggle="modal" data-bs-target="#BoqExportBoqList"
             @click="()=>{ exportTemplateType= 0; exportTemplateFilter = null}"><i class="fa-solid fa-file-arrow-down"></i> </button>
             <router-link  title="Print Bill of Quantity" :to="{name : 'BoqItemsPrint', params : {id : boqItemData._id} }"  class="btn btn-default pe-2 ps-2 ms-1"><i class="fa-solid fa-print"></i></router-link>
          </div>
        </div>
        
        <div class="d-flex justify-content-between">
          <div>
             <small> Showing Default View :</small> <router-link :to="{name : 'BoqItemsPreview', params : {id : boqItemData._id} }"  class="small text-primary fw-bold">Go to Preview</router-link>
          </div>
          <div>
            <router-link :to="{name : 'BoqItemsSummary', params : {id : boqItemData._id} }"  class="small text-primary fw-bold">View Summary</router-link>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 pb-2" v-if="OpenFilter">
            <div class="form-group ">
              <label class="d-flex justify-content-between">
                  <a>Cost Category</a>
                  <span role="button" class="text-danger text-decoration-underline" @click="ClearField(2)"><i class="fa-solid fa-xmark "></i> Clear Field</span>
              </label>
              <Multiselect 
              :multiple="true" :options="dataCostCodes" 
              :internalSearch="false"
              placeholder="Type Here to search by Cost Category"
              v-model="selectedCostCode"  @search-change="SearchCostCodeAll" :searchable="true" :loading="searchCostCodeLoading"
              :clear-on-select="false" :max-height="300"
              label="costName" track-by="_id"/>
            </div>
          </div>
          <div class="col-md-6 pb-2" v-if="OpenFilter">
            <div class="form-group ">
              <label class="d-flex justify-content-between">
                  <a>Space ID</a>
                  <span role="button" class="text-danger text-decoration-underline" @click="ClearField(1)"><i class="fa-solid fa-xmark "></i> Clear Field</span>
              </label>
              <Multiselect 
              :multiple="true" :options="areaList" 
              placeholder="Type Here to search by Space ID"
              v-model="selectedArea"  :max-height="300" />
            </div>
          </div>
          <div class="col-md-12">

            <Loader v-if="tableLoading" />
            <div v-else-if="dataReady">
              <BoqItemTable  :rowDatas="rowDatas" :reloadData="ReloadList" :stage="boqItemData.approvalStage"  :OpenEditModal="OpenEditLineItem"
              :measurementUnits="measurementUnits" :selectedColumns="selectedColumns" :updateSelected="UpdateSelected" :BoqCategoryList="BoqCategoryList" :GetChanges="GetChanges" />
            </div>

          </div>
        </div>
        
       
      </div>
      
    </div>
  </div>
  <Modal idModal="BoqReviewModal" headerTitle="Send For Review BoQ" v-if="permission.indexOf(ModuleActionList.BOQ_PUBLISH) >-1">
    <div>
       <div class="form-group">
            <label class="required-style">Date End </label>
            <input type="date" v-model="boqPublishData.dateEnd" class="form-control"/> 
            <small class="text-warning" v-if="isPublishClicked && !boqPublishData.dateEnd">Required</small>
        </div>
       <div class="form-group">
            <label class="required-style">Process </label>
            <Select2
                :dataOptions="reviewProcessList"
                indexValue="_id"
                labelName="processName"
                placeHolder="Search Process Here"
                v-model:optionValue="boqPublishData.processId"
                ref="selectCostCode"
            >
            </Select2>
            <a class="text-primary small"  :href="projectUrl +'/Project/' + projectInfo._id+'/process'">No Process on the list? Click here to Create</a>
            <small class="text-warning" v-if="isPublishClicked && !boqPublishData.processId">Required</small>
        </div>
    </div>
    <template v-slot:actions>
      <button type="button" ref="closeBoqPublishModal" class="btn btn-outline-danger" data-bs-dismiss="modal">X Cancel</button>
      <button type="button" class="btn btn-primary"  @click="ReviewBoqRun"><i class="fas fa-save"></i> Send for Review</button>
    </template>
  </Modal>
  <Modal idModal="BoqApprovalModal" headerTitle="Send For Approval BoQ" v-if="permission.indexOf(ModuleActionList.BOQ_PUBLISH) >-1">
      <div>
       <div class="form-group">
            <label class="required-style">Date End </label>
            <input type="date" v-model="boqApprovalData.dateEnd" class="form-control"/> 
            <small class="text-warning" v-if="isApprovalClicked && !boqApprovalData.dateEnd">Required</small>
        </div>
       <div class="form-group">
            <label class="required-style">Process </label>
            <Select2
                :dataOptions="approvalProcessList"
                indexValue="_id"
                labelName="processName"
                placeHolder="Search Process Here"
                v-model:optionValue="boqApprovalData.processId"
                
            >
            </Select2>
            <a class="text-primary small"  :href="projectUrl +'/Project/' + projectInfo._id+'/process'">No Process on the list? Click here to Create</a>
            <small class="text-warning" v-if="isApprovalClicked && !boqApprovalData.processId">Required</small>
        </div>
      </div>
      <template v-slot:actions>
        <button type="button" ref="closeBoqApprovalModal" class="btn btn-outline-danger" data-bs-dismiss="modal">X Cancel</button>
        <button type="button" class="btn btn-primary"  @click="ForApprovalBoqRun"><i class="fas fa-save"></i> Send for Approval</button>
      </template>
  </Modal>
  <Modal idModal="BoqExportBoqList" headerTitle="Export BoQ"  >
      <div>
          <div class="form-group">
              <label class="required-style">Template </label>
              <select class="form-select" v-model="exportTemplateType"> 
                  <option v-for="(item,key) in ExportTemplatesForExport" :key="key" :value="key">{{item}}</option>
              </select>
          </div>
          <!-- <div class="form-group">
              <label >Filter </label>
              <Multiselect 
              :options="dataCostCodes" 
              placeholder="Type Here to search by Cost Category"
              v-model="exportTemplateFilter"  @search-change="SearchCostCodeAll" :searchable="true" 
              :internalSearch="false" :loading="searchCostCodeLoading"
              :clear-on-select="false" :max-height="300"
              label="costName" track-by="_id"/>
          </div> -->
      </div>
      <template v-slot:actions>
          <div>
              <!-- <button type="button" class="btn btn-outline-primary" @click="ExportForClient" >
                  <i class="fa-solid fa-user-tie"></i>  Export for Client
              </button> -->
          </div>
          <button type="button" class="btn btn-primary" @click="ExportBoqList" >
              <i class="fa-solid fa-file-arrow-down"></i>  Export
          </button>
      </template>
  </Modal>
  <Modal idModal="BoqGroupList" headerTitle="Select Line Item Parent"  v-if="permission.indexOf(ModuleActionList.BOQ_EDIT) >-1"  >
      <div>
          <div class="" v-for="(item,key) in selectedLineItems" :key="key">
            <input type="radio" :id="key + '-radio'" :value="item._id" v-model="selectedParent" />
            <label class="ps-3 h6 fw-normal" :for="key + '-radio'">{{item.costCode}} {{item.name}}</label>
          </div>
          
      </div>
      <template v-slot:actions>
          <div>
              
          </div>
          <button type="button" class="btn btn-primary"  >
              <i class="fas fa-save"></i> Group
          </button>
      </template>
  </Modal>
  <ModalLineItemColumnFilter :UpdateSelectedColumn="ChangeSelectecColumnValue"/>

  <ModalNewLineItem
  v-if="permission.indexOf(ModuleActionList.BOQ_EDIT) >-1"
  ref="modalNewLineItem"
  :boqList="boqList" :ResetData="GetDataList" :boqId="boqItemData._id" />

  <ModalImportLineItems
  
  v-if="boqItemData.projectId && permission.indexOf(ModuleActionList.BOQ_EDIT) >-1"
  ref="modalImportLineItem"
  :boqList="boqList" :ResetData="GetDataList" :boqId="boqItemData._id" :projectId="boqItemData.projectId" />

  <ModalUpdateLineItem
  v-if="permission.indexOf(ModuleActionList.BOQ_EDIT) >-1"
  :selectedLineItem="updateLineItem"
  :boqList="boqList" :ResetData="GetDataList" />
</template>

<script src="@/assets/boq/BoqItems.js" />