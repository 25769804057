<template>
    <div>
        <BreadcrumbsProject
        :breadcrumbs="[
            {
                link : '/high-level-estimates',
                title : $t('Bidding'),
                isRouterLink: true
            },
            {
                link : '/high-level-estimates/' + hleProjectId + '/bill-of-quantities',
                title : (hleProject.projectName || '-' )+ ' - ' + $t('Bidding Module.Detailed Estimates'),
                isRouterLink: true
            },
        ]"
        />
        <div class="card">
            <div class="card-header bg-white">
                <div class="d-flex justify-content-between">
                    <div>
                        <h6 class="fw-bold mb-0">{{ $t('Bidding Module.Detailed Estimates') }}</h6>
                    </div>
                    <div class="d-flex" >
                        <div>
                            <input type="radio" value="1" v-model="viewMode" id="view-mode-1" @input="ChangeView" /> <label for="view-mode-1" role="button" class="fw-bold"> {{ $t('Bidding Module.High Level Estimates') }}</label>
                        </div>
                        <div class="ms-3">
                            <input type="radio" value="2" v-model="viewMode" id="view-mode-2" @input="ChangeView" /> <label for="view-mode-2" role="button" class="fw-bold"> {{ $t('Bidding Module.Detailed Estimates') }}</label>
                        </div>
                    </div> 
                </div>
            </div>
            <div class="card-body">
                <div class="d-flex justify-content-end">
                    <button class="btn btn-primary"  data-bs-toggle="modal" data-bs-target="#BoqModalAdd" @click="OpenCreateBoqModal" ><i class="fa-solid fa-plus"></i> {{ $t('Add') }} </button>                       
                </div>
                
                <div class="row pb-2">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label> {{ $t('Search') }}</label>
                            <input type="search" class="form-control" v-model="searchBar" :placeholder="$t('Bidding Module.Search Name of BoQs here')" />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label> {{ $t('Bidding Module.Search By High Level Estimate') }}</label>
                            <Multiselect 
                            :allow-empty="false"
                            :sort="false" :options="dataReference"
                            v-model="selectedReference"  @search-change="SearchHighLevelQuotationsTrigger" :searchable="true" :loading="selectedReferenceLoading"
                            :placeholder="$t('Bidding Module.Search By High Level Estimate')"
                            :clear-on-select="false" :max-height="300" :internalSearch="false"
                            label="name" track-by="_id"/>
                        </div>
                    </div>
                    <!-- <div class="col-md-4">
                        <div class="form-group">
                            <label>List By</label>
                            <Multiselect 
                            :sort="false" :options="creatorOptions"
                            v-model="selectedCreatedBy" :searchable="true" 
                            placeholder="Type Here to select creator"
                            :clear-on-select="false" :max-height="300" :internalSearch="true"
                            label="value" track-by="id"/>
                        </div>
                    </div> -->
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th> {{ $t('Name') }}</th>
                                <th> {{ $t('Reference') }}</th>
                                <th class="text-end"> {{ $t('Total Cost') }}</th>
                                <th class="text-end"> {{ $t('Total Sell') }}</th>
                                <th class="text-end"> {{ $t('GP Total') }}</th>
                                <th class="text-end"> {{ $t('GP') }} %</th>
                                <th class="text-center"> {{ $t('Status') }}</th>
                                <th > {{ $t('Created By') }}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody  v-if="isTableLoading">
                            <tr>
                                <td colspan="9">
                                    <Loader />
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr v-if="boqs.docs.length==0">
                                <td colspan="8" class="text-center">
                                    {{ $t('No data Found') }}
                                </td>
                            </tr>
                            <tr v-for="(item,key) in boqs.docs" :key="key">
                                <td>
                                    <router-link :to="{name : 'HleBoQBuilder', params : { id : hleProjectId, boqId :  item._id} }" class="text-capitalize link-item-route"> 
                                        {{item.name}} 
                                    </router-link>
                                </td>
                                <td class="text-capitalize">{{item.reference?.name || '-'}}</td>
                                <template v-if="item.summary">
                                    <td class="text-end">{{item.currencyId.sign}}{{ReformatNumber(item.summary.costTotal)}}</td>
                                    <td class="text-end">{{item.currencyId.sign}}{{ReformatNumber(item.summary.sellTotal)}}</td>
                                    <td class="text-end">{{item.currencyId.sign}}{{ReformatNumber(item.summary.gp)}}</td>
                                    <td class="text-end">{{ReformatNumber((item.summary.gp / item.summary.sellTotal) * 100)}}%</td>
                                </template>
                                <template v-else>
                                    <td class="text-end">{{item.currencyId.sign}}0.00</td>
                                    <td class="text-end">{{item.currencyId.sign}}0.00</td>
                                    <td class="text-end">{{item.currencyId.sign}}0.00</td>
                                    <td class="text-end">0%</td>
                                </template>
                                <td class="text-center"><span :class="'badge ' + ApprovalStageBoqObj[item.status - 1].color">{{ApprovalStageBoqObj[item.status - 1].name}}</span></td>
                                <td class="text-capitalize">{{item.createdBy.fullname}}</td>
                                <td>
                                    <router-link :to="{name : 'HleBoQBuilder', params : { id : hleProjectId, boqId :  item._id} }" class="btn btn-outline-primary btn-action me-1"> 
                                        <i class="fa-solid fa-magnifying-glass"></i>
                                    </router-link>
                                    <button class="btn btn-outline-primary btn-action me-1" @click="OpenUpdateBoqModal(item)"
                                    data-bs-toggle="modal" data-bs-target="#BoqModalUpdate"
                                    > <i class="fa-solid fa-edit"></i></button>
                                    <button class="btn btn-outline-primary btn-action me-1" 
                                    data-bs-toggle="modal" data-bs-target="#BoqModalDuplicate"
                                    title="Duplicate BoQ" @click="OpenDuplicateBoQ(item._id,item.name)"> <i class="fa-solid fa-copy"></i></button>
                                    <router-link v-if="item.status!=1" :to="{name : 'HleBoqItemsHistory', params : { id : hleProjectId,boqId :  item._id} }" class="btn btn-outline-primary btn-action me-1"> 
                                        <i class="fa-solid fa-clock-rotate-left"></i>
                                    </router-link>
                                    <button class="btn btn-outline-danger btn-action me-1" v-if="item.status==ApprovalStageBoq.DRAFT" @click="DeleteBoq(item._id,item.name)"> <i class="fa-solid fa-trash-can"></i></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>   
                </div>
                <Pagination
                :changePage="PageChange" 
                :paginationDetails="{totalPages : boqs.totalPages, page : boqs.page}" 
                :havePageCount="true"
                />
            </div>
        </div>
        <Modal idModal="BoqModalAdd" :headerTitle="$t('Add') + ' ' + $t('Bidding Module.Detailed Estimate')" modalSize="modal-md" >
            <div class="form-group">
                <label class="required-style"> {{ $t('Name') }} </label>
                <input class="form-control" :placeholder="$t('Name')" v-model="newBoq.name" />
                <small class="text-warning" v-if="isSavedClicked && !newBoq.name">{{ $t('Required') }}</small>
            </div>
            <!-- <div class="form-group">
                <label >Contract Sum </label>
                <input type="number" class="form-control" placeholder="Ex. 10,000" v-model="newBoq.targetBudget" />
            </div> -->
            <!-- <div class="form-group">
                <label> Reference High Level Estimate </label>
                <Select2
                indexValue="_id"
                labelName="name"
                placeHolder="Search High Level Estimate here"
                v-model:optionValue="newBoq.reference"
                :filterQuery="SearchHighLevelQuotations"
                :willCapitalize="true"
                />
            </div> -->
            <template v-slot:actions>
                <button type="button" ref="closeBoqModalAdd" class="btn btn-outline-danger" data-bs-dismiss="modal">X {{$t('Cancel')}}</button>
                <button type="button" class="btn btn-primary" :disabled="isOnProcess" @click="CreateBoq($event.target)" ><i class="fas fa-save"></i> {{$t('Save')}}</button>
            </template>
        </Modal>
        <Modal idModal="BoqModalUpdate" :headerTitle="$t('Update') + ' ' + $t('Bidding Module.Detailed Estimate')" modalSize="modal-md" >
            <div class="form-group">
                <label class="required-style"> {{ $t('Name') }} </label>
                <input class="form-control" :placeholder="$t('Name')" v-model="updateBoq.name" />
                <small class="text-warning" v-if="isUpdateClicked && !updateBoq.name">{{ $t('Required') }}</small>
            </div>
            <!-- <div class="form-group">
                <label >Contract Sum </label>
                <input type="number" class="form-control" placeholder="Ex. 10,000" v-model="updateBoq.targetBudget" />
            </div> -->
            <div class="form-group">
                <label> {{$t('Reference High Level Estimate')}} </label>
                <Select2
                indexValue="_id"
                labelName="name"
                :placeHolder="$t('Search Here')"
                :iniLabel="updateBoq.referenceName"
                v-model:optionValue="updateBoq.reference"
                :filterQuery="SearchHighLevelQuotations"
                :willCapitalize="true"
                />
            </div>
            <template v-slot:actions>
                <button type="button" ref="closeBoqModalUpdate" class="btn btn-outline-danger" data-bs-dismiss="modal">X {{ $t('Cancel') }}</button>
                <button type="button" class="btn btn-primary" @click="UpdateBoq($event.target)" ><i class="fas fa-save"></i> {{ $t('Save Changes') }}</button>
            </template>
        </Modal>
        <Modal idModal="BoqModalDuplicate" :headerTitle="$t('Duplicate')  + ' ' + $t('Bidding Module.Detailed Estimate')" modalSize="modal-md" >
            <div class="form-group">
                <label class="required-style">{{ $t('Bidding Module.Detailed Estimate to Duplicate') }} </label>
                <input class="form-control-plaintext" disabled v-model="duplicateBoq.copyBoQ" />
            </div>
            <div class="form-group">
                <label class="required-style"> {{ $t('Name') }} </label>
                <input class="form-control" :placeholder="$t('Name')" v-model="duplicateBoq.name" />
                <small class="text-warning" v-if="isDuplicateClicked && !duplicateBoq.name"> {{ $t('Required') }}</small>
            </div>
            <div class="form-group">
                <label>{{$t('Reference High Level Estimate')}} </label>
                <Select2
                indexValue="_id"
                labelName="name"
                :placeHolder="$t('Reference High Level Estimate')"
                v-model:optionValue="duplicateBoq.reference"
                :filterQuery="SearchHighLevelQuotations"
                :willCapitalize="true"
                />
            </div>
            <template v-slot:actions>
                <button type="button" ref="closeBoqModalDuplicate" class="btn btn-outline-danger" data-bs-dismiss="modal">X {{ $t('Cancel') }}</button>
                <button type="button" class="btn btn-primary" @click="DuplicateBoQ($event.target)" ><i class="fas fa-save"></i> {{ $t('Save Changes') }}</button>
            </template>
        </Modal>
        <!-- <Modal idModal="BoqModalCombine" headerTitle="Combine BoQ" modalSize="modal-md" >
            <small class="text-primary">*Selected Boq will be Merged Into a New BoQ</small> <br/>

            <div class="form-group">
                <label class="required-style"> Name </label>
                <input class="form-control" placeholder="Example Preliminaries" v-model="combineBoq.name" />
                <small class="text-warning" v-if="isCombineClicked && !combineBoq.name">Required</small>
            </div>
            <div class="form-group">
                <label> Reference </label>
                <Select2
                indexValue="_id"
                labelName="name"
                placeHolder="Search Quotation here"
                v-model:optionValue="combineBoq.reference"
                :filterQuery="SearchHighLevelQuotations"
                :willCapitalize="true"
                />
            </div>
            <div class="form-group">
                <label>Selected Count</label>
                <input class="form-control-plaintext text-capitalize" readonly :value="combineBoq.selectedCount"/>
                <small class="text-warning" v-if="combineBoq.selectedCount==0">No Selected Boq</small>
            </div>
            <template v-slot:actions>
                <button type="button" ref="closeBoqModalCombine" class="btn btn-outline-danger" data-bs-dismiss="modal">X Cancel</button>
                <button type="button" class="btn btn-primary" @click="CombineBoq($event.target)" ><i class="fas fa-save"></i> Combine</button>
            </template>
        </Modal> -->
    </div>
</template>


<script>
import 'dotenv/config'
import Modal  from '@/components/layout/Modal.vue'
import Loader from '@/components/layout/Loader.vue'
import Select2  from '@/components/layout/Select2.vue'
import BreadcrumbsProject  from '@/components/projectInformation/BreadcrumbsProject.vue'
import  {mapActions, mapGetters} from 'vuex'
import {LinkList, ApprovalStageBoqObj,ReformatNumber, ApprovalStageBoq,LocalStorageNameOfListByHLE} from '@/helpers/utilities.js'
import {GetHighLevelBoqList,AddHighLevelBoq,UpdateHighLevelBoq, DeleteHighLevelBoq, SearchHighLevelQuotations, SearchOfficeHighLevelQuotations,
    GetHighLevelProject, DuplicateHighLevelBoq, CombineteHighLevelBoq} from '@/actions/boqActions.js'
import Pagination from '@/components/layout/Pagination.vue'
import Multiselect from 'vue-multiselect'
export default {
    name : 'HleList',
    components : {
        BreadcrumbsProject, Modal, Loader, Select2, Pagination, Multiselect
    },
    mounted : async function(){
        const self = this
        const listBy = window.localStorage.getItem(LocalStorageNameOfListByHLE) || 1
        self.boqListParams.listBy = listBy
        self.selectedCreatedBy = self.creatorOptions.find(u=>u.id==listBy) 
        await self.ReloadData()
        self.hleProject = await GetHighLevelProject(self.hleProjectId)
        self.dataReference = await SearchOfficeHighLevelQuotations('', self.hleProjectId)
        self.dataReference.push({
            _id : 0,
            name : self.$i18n.t('No Reference')
        })
        self.ChangeActiveLink(LinkList.HIGH_LEVEL_ESTIMATES)
        await self.GetCurrencyListRun()
        self.isTableLoading = false
    },
    methods : {
        ...mapActions(['ChangeActiveLink', 'GetCurrencyListRun']),
        timerSetter(callback){
            const self = this
            
            if(self.timer){
                clearTimeout(self.timer)
                self.timer = null
            }

            self.timer = setTimeout(callback,700)
        },
        timerSetterSearch(callback){
            const self = this
            
            if(self.timerSearch){
                clearTimeout(self.timerSearch)
                self.timerSearch = null
            }

            self.timerSearch = setTimeout(callback,700)
        },
        OpenCreateBoqModal(){
            this.newBoq = {
                currencyId : ''
            }
        },
        async CreateBoq(){
            const self = this
            const data = self.newBoq
            self.isSavedClicked = true

            if(!data.name){
                return
            }
            data.hleProjectId = self.hleProjectId
            self.isSavedClicked = false
            self.isOnProcess = true
            const created = await AddHighLevelBoq(this.newBoq)
            self.isOnProcess = false
            if(created=='OK'){
                self.$refs.closeBoqModalAdd.click()
                await self.ReloadData()
                self.$swal(self.$i18n.t('Bidding Module.Create Detailed Estimate'),self.$i18n.t('Added Successfully'),'success')
            }else{
                self.$swal(self.$i18n.t('Bidding Module.Create Detailed Estimate'),created,'warning')
            }
        },
        OpenUpdateBoqModal(obj){
            this.updateBoq = {
                id : obj._id,
                currencyId : obj.currencyId._id,
                reference : obj.reference?._id,
                referenceName : obj.reference?.name,
                name : obj.name,
                targetBudget : obj.targetBudget
            }
        },
        async UpdateBoq(){
            const self = this
            const data = self.updateBoq
            let willPass = true 
            self.isUpdateClicked = true

            if(!data.name){
                willPass = false
            }

            if(!willPass){
                return false
            }

            self.isSavedClicked = false
            self.isOnProcess = true
            const updated = await UpdateHighLevelBoq(data)
            self.isOnProcess = false

            const swalTitle = self.$i18n.t('Update') + ' ' + self.$i18n.t('Bidding Module.Detailed Estimate')

            if(updated=='OK'){
                self.$refs.closeBoqModalUpdate.click()
                await self.ReloadData()
                self.$swal(swalTitle,self.$i18n.t('Updated Successfully'),'success')
            }else{
                self.$swal(swalTitle,updated,'warning')
            }
        },
        DeleteBoq(id,name){
            const self = this
            let swalTitle = `${self.$i18n.t('Delete')} ${self.$i18n.t('Bidding Module.Detailed Estimate')}`
            self.$swal({
                title: swalTitle,
                text : `"${name}" ${self.$i18n.t('Bidding Module.will be Deleted Permanently, Proceed?')}`,
                showCancelButton: true,
                icon: 'warning',
                showLoaderOnConfirm : true,
                allowOutsideClick: () => !self.$swal.isLoading(),
                preConfirm : ()=>{
                    return DeleteHighLevelBoq(id)
                }
            }).then(async (res)=>{
                if(res.isConfirmed){
                    if(res.value=='OK'){
                        await self.ReloadData()
                        self.$swal(swalTitle,'','success')
                    }else{
                        self.$swal(swalTitle,res.value,'warning')
                    }
                
                }
            })
        },
        OpenDuplicateBoQ(toBeCopiedId, toBeCopied){
            this.duplicateBoq = {
                duplicatedId : toBeCopiedId,
                copyBoQ : toBeCopied
            }
        },
        async DuplicateBoQ(){
            const self = this
            const data = self.duplicateBoq
            self.isDuplicateClicked = true
            let swalTitle = self.$i18n.t('Duplicate')  + ' ' + self.$i18n.t('Bidding Module.Detailed Estimate')
            if(!data.name){
                return
            }
            self.isDuplicateClicked = false
            self.isOnProcess = true
            const created = await DuplicateHighLevelBoq(data)
            self.isOnProcess = false
            if(created=='OK'){
                self.$refs.closeBoqModalDuplicate.click()
                await self.ReloadData()
                self.$swal(swalTitle,self.$i18n.t('Added Successfully'),'success')
            }else{
                self.$swal(swalTitle,created,'warning')
            }
        },
        OpenCombineModal(){
            if(this.selected.length==0) { return }

            this.combineBoq = {
                selectedCount : this.selected.length
            }

        },
        async CombineBoq(){
            const self = this
            const data = self.combineBoq
            self.isCombineClicked = true
            
            if(!data.name || self.selected.length==0){
                return
            }
            self.isCombineClicked = false
            self.isOnProcess = true
            data.hleProjectId = self.hleProjectId
            data.selected = self.selected
            const created = await CombineteHighLevelBoq(data)
            self.isOnProcess = false
            if(created=='OK'){
                self.$refs.closeBoqModalCombine.click()
                await self.ReloadData()
                self.$swal('Combine Estimate Bill of Quantity','Bill of Quantity Combined','success')
                self.selected = []
            }else{
                self.$swal('Combine Estimate Bill of Quantity',created,'warning')
            }
        },
        async PageChange(page){
            const self = this
            self.boqListParams.page = page
            await self.ReloadData()
        },
        async ReloadData(){
            this.isTableLoading = true
            this.boqs = await GetHighLevelBoqList(this.boqListParams)
            this.isTableLoading = false
        },
        SearchHighLevelQuotations(search){
            return SearchHighLevelQuotations(search, this.hleProjectId)
        },
        async SearchHighLevelQuotationsTrigger(search){
            const self = this
            self.selectedReferenceLoading = true
            self.timerSetter(async function(){
                self.selectedReferenceLoading = false
                self.dataReference = await SearchOfficeHighLevelQuotations(search, self.hleProjectId)
                if(self.dataReference.length!=0){
                    self.dataReference.push({
                        _id : 0,
                        name : 'NO REFERENCE'
                    })
                }
                
            })
        }
    },
    watch : {
        searchBar(val) {
            const self = this
            self.boqListParams.page = 1
            self.boqListParams.search = val
            self.timerSetterSearch(async function(){
                self.ReloadData()
            })
        },
        selectedReference(val) {
            const self = this
            self.boqListParams.page = 1
            if(val)
                self.boqListParams.reference = val._id
            else 
                self.boqListParams.reference = undefined
            self.timerSetterSearch(async function(){
                self.ReloadData()
            })
        },
        selectedCreatedBy(val) {
            const self = this
            if(!val){ 
                return
            }
            self.boqListParams.page = 1
            if(val)
                self.boqListParams.listBy = val.id
            else 
                self.boqListParams.listBy = undefined

                
            window.localStorage.setItem(LocalStorageNameOfListByHLE,val.id || 1)
            self.timerSetterSearch(async function(){
                self.ReloadData()
            })
        },
        viewMode(val){
            let viewMode = val
            if(viewMode==1)
                this.$router.push({name : 'HleList', params : {id : this.hleProjectId}})
            else
                this.$router.push({name : 'HleBoqs', params : {id : this.hleProjectId}})
        }
    },
    computed : {
        ...mapGetters(['boqCurrency'])
    },
    data(){
        return {
            ApprovalStageBoq,
            viewMode : 2,
            hleProject : {},
            hleProjectId : this.$route.params.id,
            boqUrl : process.env.VUE_APP_BOQ_URL,
            boqs : {
                docs : [],
            },
            searchBar : '',
            selectedReference : null,
            selectedReferenceLoading : false,
            selectedCreatedBy : { id : 1, value : 'My Bill of Quantities'},
            creatorOptions  : [ 
                { id : 1, value : 'My Bill of Quantities'},
                { id : 2, value : 'Bidding Bill of Quantities'},
            ],
            dataReference : [],
            newBoq : {},
            isSavedClicked : false,
            updateBoq : {},
            isUpdateClicked : false,
            isOnProcess : false,
            isTableLoading : true,
            quotationList : [],
            boqListParams : {
                listBy : 1,
                page : 1,
                search : '',
                hleProjectId : this.$route.params.id
            },
            ApprovalStageBoqObj : ApprovalStageBoqObj,
            ReformatNumber : ReformatNumber,
            timer : null,
            timerSearch : null,
            duplicateBoq : {},
            isDuplicateClicked : false,
            selected : [],
            combineBoq : {},
            isCombineClicked : false,
        }
    },
}
</script>