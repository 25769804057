<template>
    <div>
     <BreadcrumbsProject
        :breadcrumbs="[
            {
                link : projectUrl +'/',
                title : $t('Projects'),
                isRouterLink: false
            },
            {
                link : projectUrl +'/Project/' + projectInfo._id,
                title :  (projectInfo.projectNumber || '-') +' ' + (projectInfo.projectName || ''),
                isRouterLink: false
            },
            {
                 link : '/boq/' + projectInfo._id + '/master',
                title : $t('Item Master List'),
                isRouterLink: true
            }
        ]"
    ></BreadcrumbsProject>
    <div >
        <StepItem2></StepItem2>
    </div>
    <div class="card border-top border-bottom">
        <div class="card-header bg-white ">
            <div class="row">
                <div class="col-md-6 col-sm-6 col-xs-6">
                    <div class="d-flex justify-content-start">
                        <h6 class="fw-bold">{{ $t('Item Master List') }}</h6>
                    </div>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-6">
                    <div class="d-flex justify-content-end" v-if="permission.indexOf(ModuleActionList.BOQ_CREATE)>-1">
                        <!-- <button v-if="selected.length!=0" class="btn btn-primary me-1" data-bs-toggle="modal" data-bs-target="#ModalCreateBoq" @click="OpenNewBoqModal"><i class="fa-solid fa-file-arrow-up"></i> Create BoQ</button>                        -->
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="row pb-3">
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="d-flex justify-content-end" >
                        <button class="btn btn-outline-info me-1" data-bs-toggle="modal" data-bs-target="#AddModalItemBoq" @click="OpenAddModal" v-if="permission.indexOf(ModuleActionList.ITEM_MASTER_LIST_CREATE)>-1"><i class="fa-solid fa-plus "></i> {{ $t('Add') }}</button>
                        <!-- <button class="btn btn-outline-info" data-bs-toggle="modal" data-bs-target="#ModalImportBoq" @click="OpenImportModal" v-if="permission.indexOf(ModuleActionList.BOQ_CREATE)>-1"><i class="fa-solid fa-file-arrow-up"></i> Import</button> -->
                    </div>
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12 pt-2">
                    <div class="form-group ">
                        <label class="d-flex justify-content-between">
                            <a>{{$t('Cost Category')}}</a>
                            <span role="button" class="text-danger text-decoration-underline" @click="ClearCostCode"><i class="fa-solid fa-xmark "></i> {{$t('Clear Field')}}</span>
                        </label>
                        <Multiselect 
                        :sort="false"
                        :multiple="true" :options="dataCostCodes" 
                        v-model="selectedCostCode"  @search-change="SearchCostCodeAll" :searchable="true" :loading="searchCostCodeLoading"
                        :placeholder="$t('Search Here')"
                        :clear-on-select="false" :max-height="300" :internalSearch="false"
                        label="costName" track-by="_id"/>
                    </div> 
                </div>
            </div>
            <Pagination
            :changePage="PageChange" 
            :paginationDetails="{totalPages : masterListData.totalPages, page : masterListData.page}" 
            :havePageCount="true"
            ></Pagination> 
            <div class="table-responsive pt-3">
                <table class="table table-striped">
                    <thead>
                        <!-- <th class="text-center" >
                            <input type="checkbox" v-model="toggleAll" @change="TriggerToggle" ref="toggleAll"/> 
                        </th> -->
                        <th class="text-center">{{ $t('Cost Code') }}</th>
                        <th>{{ $t('Description') }}</th>
                        <th>{{$t('Main Category')}}</th>
                        <th>{{$t('Sub Category')}}</th>
                        <th>{{$t('Unit')}}</th>
                        <th
                        v-if="permission.indexOf(ModuleActionList.ITEM_MASTER_LIST_EDIT)>-1"
                        >{{ $t('Actions') }}</th>
                    </thead>
                    <tbody v-show="!loaderList">
                        <tr>
                            <td colspan="6">
                                <Loader />
                            </td>  
                        </tr>
                    </tbody>
                    <tbody v-show="loaderList">
                        <tr v-if="masterListData.docs.length==0">
                            <!-- <td class="text-center" :colspan="permission.indexOf(ModuleActionList.ITEM_MASTER_LIST_EDIT)>-1 ? 7 : 6"> No Data Found</td> -->
                            <td class="text-center" colspan="6"> {{$t('No data Found')}}</td>
                        </tr>
                        <tr v-for="(item, key) in masterListData.docs" :key="key" >
                            <!-- <td class="text-center"> 
                                <input type="checkbox" v-model="selected" :value="item._id" />
                            </td> -->
                            <td class="text-center">{{item.costCode}}</td>
                            <td style="width : 500px">
                                <p>
                                    <span v-if="userInfo.language=='en'" class="fw-bold text-capitalize">{{item.name}}</span> 
                                    <span v-else class="fw-bold text-capitalize">{{item.nameCh || item.parents[1].name}}</span> 
                                    <br />
                                    <small>
                                        {{item.description}}
                                    </small>
                                </p>
                            </td>
                            <td v-if="userInfo.language=='en'" class="text-capitalize">{{item.parents[0].name}}</td>
                            <td v-else class="text-capitalize">{{item.parents[0].nameCh || item.parents[1].name}}</td>
                            <td v-if="userInfo.language=='en'"  class="text-capitalize">{{item.parents[1].name}}</td>
                            <td v-else class="text-capitalize">{{item.parents[1].nameCh || item.parents[1].name}}</td>
                            <td >{{item.unit}}</td>
                            <td v-if="permission.indexOf(ModuleActionList.ITEM_MASTER_LIST_EDIT)>-1">
                               <button  class="btn btn-outline-primary btn-action me-1" @click.stop="OpenEditModal(item)" v-if="permission.indexOf(ModuleActionList.ITEM_MASTER_LIST_EDIT)>-1" data-bs-toggle="modal" data-bs-target="#EditModalItemBoq" > <i class="fa-solid fa-pencil"></i> </button> 
                               <!-- <button  class="btn btn-outline-warning btn-action" @click.stop="DeleteItem(item._id)"> <i class="fa-solid fa-trash-can"></i> </button>  -->
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <Pagination
            :changePage="PageChange" 
            :paginationDetails="{totalPages : masterListData.totalPages, page : masterListData.page}" 
            :havePageCount="true"
            ></Pagination> 
        </div>
    </div>
    <Modal idModal="AddModalItemBoq" :headerTitle="$t('Add Item to Master List')" modalSize="modal-lg" v-if="permission.indexOf(ModuleActionList.ITEM_MASTER_LIST_CREATE)>-1" >
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label class="required-style">{{$t('Cost Code')}} </label>
                    <Select2
                        :filterQuery="GetCostCodes"
                        indexValue="_id"
                        labelName="costName"
                        :placeholder="$t('Search Here')"
                        v-model:optionValue="newMasterItem.costCodeId"
                        :onOptionClick="AppendItemParents"
                        ref="selectCostCode"
                        subLabel='parentName'
                        :willCapitalize="false"
                    >
                    </Select2>
                    <small class="text-warning" v-if="!newMasterItem.costCodeId && isClicked">{{$t('Required')}}</small>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label>{{$t('Sub Category')}}</label>
                    <input type="text" class="form-control-plaintext text-capitalize" v-model="newMasterItem.subCateg" disabled />
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label>{{$t('Main Category')}}</label>
                    <input type="text" class="form-control-plaintext text-capitalize" disabled v-model="newMasterItem.mainCateg"/>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label class="required-style">{{ $t('Unit') }} </label>
                    <select class="form-select" v-model="newMasterItem.unit" >
                        <option value="">{{$t('Please Select')}}</option>
                        <option v-for="(item, key) in measurementUnits" :key="key" :value="item">{{item}}</option>
                    </select>
                    <small class="text-warning" v-if="!newMasterItem.unit && isClicked">{{$t('Required')}}</small>
                </div>
            </div>
            <div class="col-md-12">
                 <div class="form-group">
                    <label class="required-style">{{$t('Description')}} </label>
                    <textarea class="form-control" :placeholder="$t('Description')" v-model="newMasterItem.description"></textarea>
                    <small class="text-warning" v-if="!newMasterItem.description && isClicked">{{$t('Required')}}</small>
                </div>
            </div>
        </div>
        <template v-slot:actions>
            <button type="button" ref="closeAddModalItemBoq" class="btn btn-outline-danger" data-bs-dismiss="modal">X {{$t('Cancel')}}</button>
            <div>
                <button type="button" class="btn btn-primary me-1"  @click="SaveNewItemOpen" ><i class="fa-solid fa-save"></i> {{$t('Save')}}</button>
            <button type="button" class="btn btn-primary" @click="SaveNewItemClose" ><i class="fa-solid fa-save"></i> {{$t('Save and Close')}}</button>
            </div>
        </template>
    </Modal>
    <Modal idModal="EditModalItemBoq" :headerTitle="$t('Edit Item from Master List')" modalSize="modal-lg" v-if="permission.indexOf(ModuleActionList.ITEM_MASTER_LIST_EDIT)>-1">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label class="required-style">{{$t('Cost Code')}} </label>
                    <input type="text" class="form-control-plaintext text-capitalize" disabled :value="updateMasterItem.costName" />
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label>{{$t('Sub Category')}}</label>
                    <input type="text" class="form-control-plaintext text-capitalize" disabled :value="updateMasterItem.subCateg"  />
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label>{{$t('Main Category')}}</label>
                    <input type="text" class="form-control-plaintext text-capitalize" disabled :value="updateMasterItem.mainCateg" />
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label class="required-style">{{$t('Unit')}} </label>
                    <select class="form-select" v-model="updateMasterItem.unit" >
                        <option value="">{{$t('Please Select')}}</option>
                        <option v-for="(item, key) in measurementUnits" :key="key" :value="item">{{item}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
            </div>
            <div class="col-md-12">
                 <div class="form-group">
                    <label class="required-style">{{$t('Description')}} </label>
                    <textarea class="form-control" :placeholder="$t('Description')" v-model="updateMasterItem.description"></textarea>
                </div>
            </div>
        </div>
        <template v-slot:actions>
            <button type="button" ref="closeEditModalItemBoq" class="btn btn-outline-danger" data-bs-dismiss="modal">X{{$t('Cancel')}}</button>
            <button type="button" class="btn btn-primary"   @click="EditNewItem" ><i class="fa-solid fa-save"></i>  {{$t('Save Changes')}}</button>
        </template>
    </Modal>
    <Modal idModal="ModalCreateBoq" headerTitle="Create BoQ" modalSize="modal-md" v-if="permission.indexOf(ModuleActionList.BOQ_CREATE)>-1">
         <div class="form-group">
            <label >Existing ? </label>
            <select class="form-select" v-model="newBoqData.existing" @change="ChangeBoqType">
                <option value="false">No</option>
                <option value="true">Yes</option>
            </select>
        </div>
        <template v-if="newBoqData.existing=='false'">
            <div class="form-group">
                <label class="required-style">BoQ Name </label>
                <input type="text" class="form-control" v-model="newBoqData.name" placeholder="BoQ Name" />
                <small class="text-warning" v-if="!newBoqData.name&&isNewBoqClicked">Required</small>
            </div>
            <div class="form-group">
                <label class="required-style">Reference </label>
                <input type="text" class="form-control" placeholder="Reference" v-model="newBoqData.reference"  />
                <small class="text-warning" v-if="!newBoqData.reference&&isNewBoqClicked">Required</small>
            </div>
            <div class="form-group">
                <label class="required-style">Currency </label>
                <select class="form-select" v-model="newBoqData.currency" >
                    <option value="">Select Currency</option>
                    <option v-for="(item, key) in currencies" :key="key" :value="item._id">{{item.sign}} {{item.name}}</option>
                </select>
                <small class="text-warning" v-if="!newBoqData.currency&&isNewBoqClicked">Required</small>
            </div>
        </template>
        
        <div class="form-group" v-if="newBoqData.existing=='true'">
            <label class="required-style">Existing Boq </label>
            <Select2
            :filterQuery="GetBoqsProjects"
            indexValue="_id"
            labelName="fullName"
            placeHolder="Search Boq here"
            v-model:optionValue="newBoqData.boqId"
            ref="selectBoqList"
        >
        </Select2>
            <small class="text-warning" v-if="!newBoqData.boqId&&isNewBoqClicked">Required</small>
        </div>
        <div class="form-group">
            <label >Selected Count</label>
            <input type="number" class="form-control" disabled placeholder="" :value="newBoqData.selectedCount"/>
            <small class="text-warning" v-if="newBoqData.selectedCount==0">Please Select Item From Master List</small>
        </div>
        <template v-slot:actions>
            <button type="button" ref="closeModalCreateBoq" class="btn btn-outline-danger" data-bs-dismiss="modal">X Cancel</button>
            <button type="button" class="btn btn-primary" @click="SaveItemToBoq" :disabled="newBoqData.selectedCount==0" ><i class="fa-solid fa-save"></i> Proceed</button>
        </template>
    </Modal>

    <Modal idModal="ModalImportBoq" headerTitle="Import Items" modalSize="modal-md" v-if="permission.indexOf(ModuleActionList.BOQ_CREATE)>-1">

        <div class="form-group" >
            <label class="required-style">
                File
            </label>
            <input type="file" class="form-control" ref="uploadFile" />
            <small class="text-warning" v-if="!newBoqImport.file&&importClicked">Required <br /></small>
        </div>
        <div class="form-group">
            <label class="required-style">Template Type </label>
            <select class="form-select" v-model="newBoqImport.templateType">
                <option value="1">System Template </option>
                <option value="2">5D Template</option>
            </select>
            <small class="text-warning" v-if="!newBoqImport.templateType&&importClicked">Required</small>
            <small > <a href="#" @click="DownloadTemplate1Run">Click here</a> to Download template</small>
        </div>
        <div class="form-group">
            <label class="required-style">BoQ Name </label>
            <input type="text" class="form-control" v-model="newBoqImport.name" placeholder="BoQ Name" />
            <small class="text-warning" v-if="!newBoqImport.name&&importClicked">Required</small>
        </div>
        <div class="form-group">
            <label class="required-style">Reference </label>
            <input type="text" class="form-control" placeholder="Reference" v-model="newBoqImport.reference"  />
            <small class="text-warning" v-if="!newBoqImport.reference&&importClicked">Required</small>
        </div>
        <div class="form-group">
            <label class="required-style">Currency </label>
            <select class="form-select" v-model="newBoqImport.currencyId" >
                <option value="">Select Currency</option>
                <option v-for="(item, key) in currencies" :key="key" :value="item._id">{{item.sign}} {{item.name}}</option>
            </select>
            <small class="text-warning" v-if="!newBoqImport.currencyId&&importClicked">Required</small>
        </div>
        <template v-slot:actions>
            <button type="button" ref="closeModalImportBoq" class="btn btn-outline-danger" data-bs-dismiss="modal">X Cancel</button>
            <button type="button" class="btn btn-primary" @click="UploadTemplate1"  ><i class="fa-solid fa-save"></i> Upload</button>
        </template>
    </Modal>
  </div>
</template>

<script src="@/assets/boq/BoqMasterList.js" />