<template>
    <div class="d-flex justify-content-center fw-light">
        <i> {{ $t('All monetary figures shown below are in') }} {{ currency?.name }} ({{ currency?.sign }}) {{ $t('and are exclusive of tax.') }}</i>
    </div>
</template>
<script>
export default {
    name : 'FigureCurrencyNote',
    props : ['currency']
}
</script>