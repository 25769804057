<template>
    <input type="search"
    @keyup="KeyUp"
    @keydown="KeyUp"
    :id="elemId" 
    class="form-control" 
    :placeholder="localPlaceholder" 
    autocomplete="off"
    autocorrect="off"
    spellcheck="false" />

</template>

<script>
import datepicker from 'js-datepicker/dist/datepicker.min.js'
import {ReformatDate} from '@/helpers/utilities.js'
export default {
    mounted(){
        const self = this
        let currentDate = self.modelValue ? new Date(self.modelValue) : undefined
        self.datePickerComponent = datepicker(`#${self.elemId}`,
        {
            dateSelected: currentDate,
            formatter: (input, date) => {
                input.value = ReformatDate(date)
            },
            onSelect : (instance,date) =>{
                this.$emit('update:modelValue', ReformatDate(date, 'YYYY-MM-DD'))
            },
            noWeekends: false,
            customDays: 
            [
                self.$i18n.t('date picker component.Sunday'),
                self.$i18n.t('date picker component.Monday'),
                self.$i18n.t('date picker component.Tuesday'),
                self.$i18n.t('date picker component.Wednesday'),
                self.$i18n.t('date picker component.Thursday'),
                self.$i18n.t('date picker component.Friday'),
                self.$i18n.t('date picker component.Saturday'),
            ],
            customMonths: 
            [
                self.$i18n.t('date picker component.January'),
                self.$i18n.t('date picker component.February'),
                self.$i18n.t('date picker component.March'),
                self.$i18n.t('date picker component.April'),
                self.$i18n.t('date picker component.May'),
                self.$i18n.t('date picker component.June'),
                self.$i18n.t('date picker component.July'),
                self.$i18n.t('date picker component.August'),
                self.$i18n.t('date picker component.September'),
                self.$i18n.t('date picker component.October'),
                self.$i18n.t('date picker component.November'),
                self.$i18n.t('date picker component.December')
            ],
            overlayPlaceholder: self.$i18n.t('date picker component.Select Year'),
            overlayButton: self.$i18n.t('date picker component.Confirm'),
        })
    },
    methods : {
        KeyUp(e){
            e.preventDefault()
            return
        }
    },
    watch : {
        modelValue(val){
            if(!val){
                this.datePickerComponent.setDate()
                this.$emit('update:modelValue', '')
            }else{
                this.datePickerComponent.setDate(new Date(val))
                this.$emit('update:modelValue', val)
            }
            
        }
    },
    computed :{
        localPlaceholder() {
            return this.placeholder ==  'Please Select a Date'  ? this.$i18n.t('Please Select a Date') :this.placeholder ;
        }
    },
    props: {
        modelValue : {
            type : String,
        },
        elemId : {
            type : String,
        },
        placeholder : {
            type : String,
            default : 'Please Select a Date'
        }
    },
    data (){
        return {
            dateValue : '',
            datePickerComponent : null
        }
    }
}
</script>